const testData = [
  {a: 0.3 ,b: 1.09 ,c: 71.5 ,d: 2290.5 ,e: 10.47 ,f: 2262398.0 },
  {a: 0.3 ,b: 1.12 ,c: 72.0 ,d: 2289.6 ,e: 10.36 ,f: 2038227.0 },
  {a: 0.3 ,b: 1.16 ,c: 71.6 ,d: 2288.6 ,e: 10.43 ,f: 2153064.0 },
  {a: 0.3 ,b: 1.19 ,c: 71.2 ,d: 2287.6 ,e: 10.51 ,f: 2275424.0 },
  {a: 0.3 ,b: 1.22 ,c: 71.9 ,d: 2286.5 ,e: 10.38 ,f: 2001814.0 },
  {a: 0.3 ,b: 1.25 ,c: 71.6 ,d: 2285.4 ,e: 10.44 ,f: 2100954.0 },
  {a: 0.3 ,b: 1.29 ,c: 71.3 ,d: 2284.3 ,e: 10.51 ,f: 2207737.0 },
  {a: 0.3 ,b: 1.32 ,c: 71.0 ,d: 2283.2 ,e: 10.57 ,f: 2314001.0 },
  {a: 0.3 ,b: 1.35 ,c: 70.7 ,d: 2281.5 ,e: 10.64 ,f: 2419457.0 },
  {a: 0.3 ,b: 1.38 ,c: 71.5 ,d: 2277.9 ,e: 10.45 ,f: 2067464.0 },
  {a: 0.3 ,b: 1.41 ,c: 71.3 ,d: 2272.8 ,e: 10.51 ,f: 2153789.0 },
  {a: 0.3 ,b: 1.45 ,c: 71.1 ,d: 2266.1 ,e: 10.55 ,f: 2235545.0 },
  {a: 0.3 ,b: 1.48 ,c: 70.9 ,d: 2258.2 ,e: 10.59 ,f: 2311987.0 },
  {a: 0.3 ,b: 1.51 ,c: 70.7 ,d: 2249.0 ,e: 10.61 ,f: 2382444.0 },
  {a: 0.3 ,b: 1.54 ,c: 71.9 ,d: 2238.8 ,e: 10.37 ,f: 1950212.0 },
  {a: 0.3 ,b: 1.58 ,c: 71.8 ,d: 2226.6 ,e: 10.38 ,f: 1997563.0 },
  {a: 0.3 ,b: 1.61 ,c: 71.8 ,d: 2212.0 ,e: 10.4 ,f: 2045072.0 },
  {a: 0.3 ,b: 1.64 ,c: 71.8 ,d: 2195.6 ,e: 10.39 ,f: 2075438.0 },
  {a: 0.3 ,b: 1.67 ,c: 71.9 ,d: 2177.4 ,e: 10.37 ,f: 2096102.0 },
  {a: 0.3 ,b: 1.7 ,c: 71.9 ,d: 2164.1 ,e: 10.35 ,f: 2106698.0 },
  {a: 0.31 ,b: 1.09 ,c: 71.6 ,d: 2285.4 ,e: 10.43 ,f: 2229725.0 },
  {a: 0.31 ,b: 1.12 ,c: 71.3 ,d: 2284.4 ,e: 10.51 ,f: 2358315.0 },
  {a: 0.31 ,b: 1.16 ,c: 71.8 ,d: 2283.5 ,e: 10.4 ,f: 2121668.0 },
  {a: 0.31 ,b: 1.19 ,c: 71.4 ,d: 2282.4 ,e: 10.48 ,f: 2243183.0 },
  {a: 0.31 ,b: 1.22 ,c: 71.0 ,d: 2281.4 ,e: 10.55 ,f: 2364048.0 },
  {a: 0.31 ,b: 1.25 ,c: 70.8 ,d: 2280.3 ,e: 10.62 ,f: 2476092.0 },
  {a: 0.31 ,b: 1.29 ,c: 71.4 ,d: 2279.2 ,e: 10.47 ,f: 2176447.0 },
  {a: 0.31 ,b: 1.32 ,c: 71.1 ,d: 2278.0 ,e: 10.54 ,f: 2281955.0 },
  {a: 0.31 ,b: 1.35 ,c: 70.8 ,d: 2276.4 ,e: 10.6 ,f: 2386674.0 },
  {a: 0.31 ,b: 1.38 ,c: 70.6 ,d: 2272.9 ,e: 10.66 ,f: 2488964.0 },
  {a: 0.31 ,b: 1.41 ,c: 71.4 ,d: 2267.8 ,e: 10.47 ,f: 2123410.0 },
  {a: 0.31 ,b: 1.45 ,c: 71.2 ,d: 2261.1 ,e: 10.52 ,f: 2204568.0 },
  {a: 0.31 ,b: 1.48 ,c: 71.0 ,d: 2253.2 ,e: 10.55 ,f: 2280409.0 },
  {a: 0.31 ,b: 1.51 ,c: 70.9 ,d: 2244.0 ,e: 10.58 ,f: 2350280.0 },
  {a: 0.31 ,b: 1.54 ,c: 70.8 ,d: 2233.8 ,e: 10.6 ,f: 2413679.0 },
  {a: 0.31 ,b: 1.58 ,c: 70.7 ,d: 2221.9 ,e: 10.62 ,f: 2477940.0 },
  {a: 0.31 ,b: 1.61 ,c: 71.9 ,d: 2208.2 ,e: 10.36 ,f: 2016365.0 },
  {a: 0.31 ,b: 1.64 ,c: 71.9 ,d: 2192.1 ,e: 10.36 ,f: 2047221.0 },
  {a: 0.31 ,b: 1.67 ,c: 72.0 ,d: 2174.1 ,e: 10.34 ,f: 2068430.0 },
  {a: 0.31 ,b: 1.7 ,c: 72.1 ,d: 2160.8 ,e: 10.32 ,f: 2079365.0 },
  {a: 0.32 ,b: 1.09 ,c: 71.8 ,d: 2280.2 ,e: 10.4 ,f: 2196961.0 },
  {a: 0.32 ,b: 1.12 ,c: 71.4 ,d: 2279.3 ,e: 10.47 ,f: 2324619.0 },
  {a: 0.32 ,b: 1.16 ,c: 71.0 ,d: 2278.3 ,e: 10.56 ,f: 2459728.0 },
  {a: 0.32 ,b: 1.19 ,c: 71.6 ,d: 2277.3 ,e: 10.45 ,f: 2210857.0 },
  {a: 0.32 ,b: 1.22 ,c: 71.2 ,d: 2276.2 ,e: 10.52 ,f: 2330875.0 },
  {a: 0.32 ,b: 1.25 ,c: 70.9 ,d: 2275.1 ,e: 10.58 ,f: 2442074.0 },
  {a: 0.32 ,b: 1.29 ,c: 70.6 ,d: 2274.0 ,e: 10.65 ,f: 2560862.0 },
  {a: 0.32 ,b: 1.32 ,c: 71.3 ,d: 2272.9 ,e: 10.5 ,f: 2249824.0 },
  {a: 0.32 ,b: 1.35 ,c: 71.0 ,d: 2271.3 ,e: 10.57 ,f: 2353807.0 },
  {a: 0.32 ,b: 1.38 ,c: 70.7 ,d: 2267.8 ,e: 10.62 ,f: 2455394.0 },
  {a: 0.32 ,b: 1.41 ,c: 70.5 ,d: 2262.8 ,e: 10.67 ,f: 2553175.0 },
  {a: 0.32 ,b: 1.45 ,c: 71.4 ,d: 2256.1 ,e: 10.48 ,f: 2173511.0 },
  {a: 0.32 ,b: 1.48 ,c: 71.2 ,d: 2248.1 ,e: 10.52 ,f: 2248755.0 },
  {a: 0.32 ,b: 1.51 ,c: 71.1 ,d: 2239.0 ,e: 10.55 ,f: 2318036.0 },
  {a: 0.32 ,b: 1.54 ,c: 71.0 ,d: 2228.8 ,e: 10.57 ,f: 2380848.0 },
  {a: 0.32 ,b: 1.58 ,c: 70.9 ,d: 2217.0 ,e: 10.59 ,f: 2444551.0 },
  {a: 0.32 ,b: 1.61 ,c: 70.8 ,d: 2203.5 ,e: 10.59 ,f: 2491731.0 },
  {a: 0.32 ,b: 1.64 ,c: 72.1 ,d: 2188.2 ,e: 10.32 ,f: 2017820.0 },
  {a: 0.32 ,b: 1.67 ,c: 72.2 ,d: 2170.6 ,e: 10.31 ,f: 2039575.0 },
  {a: 0.32 ,b: 1.7 ,c: 72.2 ,d: 2157.5 ,e: 10.29 ,f: 2050923.0 },
  {a: 0.33 ,b: 1.09 ,c: 71.2 ,d: 2274.8 ,e: 10.52 ,f: 2502144.0 },
  {a: 0.33 ,b: 1.12 ,c: 70.8 ,d: 2273.9 ,e: 10.6 ,f: 2642598.0 },
  {a: 0.33 ,b: 1.16 ,c: 71.2 ,d: 2273.0 ,e: 10.52 ,f: 2424130.0 },
  {a: 0.33 ,b: 1.19 ,c: 70.9 ,d: 2272.0 ,e: 10.59 ,f: 2549355.0 },
  {a: 0.33 ,b: 1.22 ,c: 70.5 ,d: 2270.9 ,e: 10.66 ,f: 2682056.0 },
  {a: 0.33 ,b: 1.25 ,c: 71.1 ,d: 2269.8 ,e: 10.55 ,f: 2407103.0 },
  {a: 0.33 ,b: 1.29 ,c: 70.8 ,d: 2268.7 ,e: 10.61 ,f: 2525025.0 },
  {a: 0.33 ,b: 1.32 ,c: 70.5 ,d: 2267.6 ,e: 10.67 ,f: 2634175.0 },
  {a: 0.33 ,b: 1.35 ,c: 71.2 ,d: 2266.0 ,e: 10.53 ,f: 2320017.0 },
  {a: 0.33 ,b: 1.38 ,c: 70.9 ,d: 2262.6 ,e: 10.59 ,f: 2420879.0 },
  {a: 0.33 ,b: 1.41 ,c: 70.7 ,d: 2257.6 ,e: 10.64 ,f: 2517946.0 },
  {a: 0.33 ,b: 1.45 ,c: 70.4 ,d: 2250.9 ,e: 10.68 ,f: 2609931.0 },
  {a: 0.33 ,b: 1.48 ,c: 70.3 ,d: 2243.0 ,e: 10.71 ,f: 2687736.0 },
  {a: 0.33 ,b: 1.51 ,c: 71.2 ,d: 2233.8 ,e: 10.51 ,f: 2284878.0 },
  {a: 0.33 ,b: 1.54 ,c: 71.1 ,d: 2223.7 ,e: 10.53 ,f: 2347086.0 },
  {a: 0.33 ,b: 1.58 ,c: 71.0 ,d: 2211.9 ,e: 10.55 ,f: 2410210.0 },
  {a: 0.33 ,b: 1.61 ,c: 71.0 ,d: 2198.5 ,e: 10.55 ,f: 2456848.0 },
  {a: 0.33 ,b: 1.64 ,c: 71.0 ,d: 2183.3 ,e: 10.55 ,f: 2494200.0 },
  {a: 0.33 ,b: 1.67 ,c: 71.1 ,d: 2166.5 ,e: 10.53 ,f: 2521482.0 },
  {a: 0.33 ,b: 1.7 ,c: 72.4 ,d: 2153.9 ,e: 10.26 ,f: 2020424.0 },
  {a: 0.34 ,b: 1.09 ,c: 71.4 ,d: 2269.2 ,e: 10.49 ,f: 2463313.0 },
  {a: 0.34 ,b: 1.12 ,c: 71.0 ,d: 2268.3 ,e: 10.56 ,f: 2602671.0 },
  {a: 0.34 ,b: 1.16 ,c: 71.4 ,d: 2267.4 ,e: 10.48 ,f: 2386523.0 },
  {a: 0.34 ,b: 1.19 ,c: 71.1 ,d: 2266.3 ,e: 10.55 ,f: 2510740.0 },
  {a: 0.34 ,b: 1.22 ,c: 70.7 ,d: 2265.3 ,e: 10.62 ,f: 2642433.0 },
  {a: 0.34 ,b: 1.25 ,c: 70.4 ,d: 2264.2 ,e: 10.68 ,f: 2765246.0 },
  {a: 0.34 ,b: 1.29 ,c: 70.9 ,d: 2263.1 ,e: 10.57 ,f: 2487173.0 },
  {a: 0.34 ,b: 1.32 ,c: 70.7 ,d: 2262.0 ,e: 10.63 ,f: 2595412.0 },
  {a: 0.34 ,b: 1.35 ,c: 70.4 ,d: 2260.5 ,e: 10.69 ,f: 2711036.0 },
  {a: 0.34 ,b: 1.38 ,c: 71.1 ,d: 2257.0 ,e: 10.55 ,f: 2384425.0 },
  {a: 0.34 ,b: 1.41 ,c: 70.8 ,d: 2252.0 ,e: 10.6 ,f: 2480738.0 },
  {a: 0.34 ,b: 1.45 ,c: 70.6 ,d: 2245.4 ,e: 10.64 ,f: 2571986.0 },
  {a: 0.34 ,b: 1.48 ,c: 70.5 ,d: 2237.5 ,e: 10.67 ,f: 2649054.0 },
  {a: 0.34 ,b: 1.51 ,c: 70.4 ,d: 2228.4 ,e: 10.7 ,f: 2727630.0 },
  {a: 0.34 ,b: 1.54 ,c: 71.3 ,d: 2218.3 ,e: 10.49 ,f: 2311413.0 },
  {a: 0.34 ,b: 1.58 ,c: 71.2 ,d: 2206.5 ,e: 10.52 ,f: 2373923.0 },
  {a: 0.34 ,b: 1.61 ,c: 71.2 ,d: 2193.1 ,e: 10.52 ,f: 2419975.0 },
  {a: 0.34 ,b: 1.64 ,c: 71.2 ,d: 2178.0 ,e: 10.51 ,f: 2456807.0 },
  {a: 0.34 ,b: 1.67 ,c: 71.3 ,d: 2161.4 ,e: 10.49 ,f: 2483685.0 },
  {a: 0.34 ,b: 1.7 ,c: 71.3 ,d: 2149.2 ,e: 10.48 ,f: 2498202.0 },
  {a: 0.35 ,b: 1.09 ,c: 70.9 ,d: 2263.4 ,e: 10.59 ,f: 2755716.0 },
  {a: 0.35 ,b: 1.12 ,c: 71.2 ,d: 2262.5 ,e: 10.52 ,f: 2561641.0 },
  {a: 0.35 ,b: 1.16 ,c: 70.9 ,d: 2261.6 ,e: 10.59 ,f: 2699060.0 },
  {a: 0.35 ,b: 1.19 ,c: 71.2 ,d: 2260.6 ,e: 10.51 ,f: 2471064.0 },
  {a: 0.35 ,b: 1.22 ,c: 70.9 ,d: 2259.5 ,e: 10.58 ,f: 2601724.0 },
  {a: 0.35 ,b: 1.25 ,c: 70.6 ,d: 2258.5 ,e: 10.64 ,f: 2723504.0 },
  {a: 0.35 ,b: 1.29 ,c: 70.4 ,d: 2257.4 ,e: 10.7 ,f: 2844623.0 },
  {a: 0.35 ,b: 1.32 ,c: 70.9 ,d: 2256.2 ,e: 10.59 ,f: 2555584.0 },
  {a: 0.35 ,b: 1.35 ,c: 70.6 ,d: 2254.8 ,e: 10.65 ,f: 2670293.0 },
  {a: 0.35 ,b: 1.38 ,c: 70.3 ,d: 2251.4 ,e: 10.71 ,f: 2782460.0 },
  {a: 0.35 ,b: 1.41 ,c: 70.2 ,d: 2246.4 ,e: 10.75 ,f: 2882243.0 },
  {a: 0.35 ,b: 1.45 ,c: 70.8 ,d: 2239.8 ,e: 10.61 ,f: 2532999.0 },
  {a: 0.35 ,b: 1.48 ,c: 70.7 ,d: 2231.9 ,e: 10.63 ,f: 2609308.0 },
  {a: 0.35 ,b: 1.51 ,c: 70.5 ,d: 2222.8 ,e: 10.66 ,f: 2687135.0 },
  {a: 0.35 ,b: 1.54 ,c: 70.5 ,d: 2212.7 ,e: 10.68 ,f: 2757705.0 },
  {a: 0.35 ,b: 1.58 ,c: 70.4 ,d: 2201.0 ,e: 10.69 ,f: 2820147.0 },
  {a: 0.35 ,b: 1.61 ,c: 71.4 ,d: 2187.7 ,e: 10.48 ,f: 2382106.0 },
  {a: 0.35 ,b: 1.64 ,c: 71.4 ,d: 2172.8 ,e: 10.47 ,f: 2418491.0 },
  {a: 0.35 ,b: 1.67 ,c: 71.5 ,d: 2156.3 ,e: 10.46 ,f: 2445025.0 },
  {a: 0.35 ,b: 1.7 ,c: 71.5 ,d: 2144.2 ,e: 10.44 ,f: 2459402.0 },
  {a: 0.36 ,b: 1.09 ,c: 71.1 ,d: 2257.4 ,e: 10.55 ,f: 2711097.0 },
  {a: 0.36 ,b: 1.12 ,c: 70.7 ,d: 2256.6 ,e: 10.62 ,f: 2861628.0 },
  {a: 0.36 ,b: 1.16 ,c: 71.0 ,d: 2255.6 ,e: 10.55 ,f: 2655544.0 },
  {a: 0.36 ,b: 1.19 ,c: 70.7 ,d: 2254.6 ,e: 10.62 ,f: 2790997.0 },
  {a: 0.36 ,b: 1.22 ,c: 71.1 ,d: 2253.6 ,e: 10.54 ,f: 2559704.0 },
  {a: 0.36 ,b: 1.25 ,c: 70.8 ,d: 2252.5 ,e: 10.6 ,f: 2680419.0 },
  {a: 0.36 ,b: 1.29 ,c: 70.6 ,d: 2251.4 ,e: 10.66 ,f: 2800471.0 },
  {a: 0.36 ,b: 1.32 ,c: 70.3 ,d: 2250.3 ,e: 10.72 ,f: 2928084.0 },
  {a: 0.36 ,b: 1.35 ,c: 70.8 ,d: 2248.9 ,e: 10.61 ,f: 2628240.0 },
  {a: 0.36 ,b: 1.38 ,c: 70.5 ,d: 2245.5 ,e: 10.67 ,f: 2739498.0 },
  {a: 0.36 ,b: 1.41 ,c: 70.3 ,d: 2240.6 ,e: 10.71 ,f: 2838391.0 },
  {a: 0.36 ,b: 1.45 ,c: 70.1 ,d: 2234.0 ,e: 10.75 ,f: 2939887.0 },
  {a: 0.36 ,b: 1.48 ,c: 70.9 ,d: 2226.1 ,e: 10.59 ,f: 2568277.0 },
  {a: 0.36 ,b: 1.51 ,c: 70.7 ,d: 2217.0 ,e: 10.62 ,f: 2645326.0 },
  {a: 0.36 ,b: 1.54 ,c: 70.6 ,d: 2206.9 ,e: 10.64 ,f: 2715129.0 },
  {a: 0.36 ,b: 1.58 ,c: 70.6 ,d: 2195.2 ,e: 10.65 ,f: 2776829.0 },
  {a: 0.36 ,b: 1.61 ,c: 70.6 ,d: 2182.0 ,e: 10.65 ,f: 2829320.0 },
  {a: 0.36 ,b: 1.64 ,c: 71.6 ,d: 2167.3 ,e: 10.43 ,f: 2378874.0 },
  {a: 0.36 ,b: 1.67 ,c: 71.6 ,d: 2151.0 ,e: 10.42 ,f: 2405055.0 },
  {a: 0.36 ,b: 1.7 ,c: 71.7 ,d: 2139.0 ,e: 10.4 ,f: 2419270.0 },
  {a: 0.37 ,b: 1.09 ,c: 71.3 ,d: 2251.4 ,e: 10.51 ,f: 2665947.0 },
  {a: 0.37 ,b: 1.12 ,c: 70.9 ,d: 2250.5 ,e: 10.58 ,f: 2815187.0 },
  {a: 0.37 ,b: 1.16 ,c: 71.2 ,d: 2249.6 ,e: 10.51 ,f: 2611496.0 },
  {a: 0.37 ,b: 1.19 ,c: 70.9 ,d: 2248.6 ,e: 10.58 ,f: 2745777.0 },
  {a: 0.37 ,b: 1.22 ,c: 70.6 ,d: 2247.6 ,e: 10.65 ,f: 2887469.0 },
  {a: 0.37 ,b: 1.25 ,c: 71.0 ,d: 2246.5 ,e: 10.56 ,f: 2636813.0 },
  {a: 0.37 ,b: 1.29 ,c: 70.7 ,d: 2245.4 ,e: 10.62 ,f: 2755789.0 },
  {a: 0.37 ,b: 1.32 ,c: 70.5 ,d: 2244.3 ,e: 10.68 ,f: 2882324.0 },
  {a: 0.37 ,b: 1.35 ,c: 70.2 ,d: 2242.9 ,e: 10.73 ,f: 2999854.0 },
  {a: 0.37 ,b: 1.38 ,c: 70.7 ,d: 2239.6 ,e: 10.63 ,f: 2696014.0 },
  {a: 0.37 ,b: 1.41 ,c: 70.5 ,d: 2234.7 ,e: 10.67 ,f: 2794009.0 },
  {a: 0.37 ,b: 1.45 ,c: 70.3 ,d: 2228.1 ,e: 10.71 ,f: 2894621.0 },
  {a: 0.37 ,b: 1.48 ,c: 70.2 ,d: 2220.3 ,e: 10.73 ,f: 2980393.0 },
  {a: 0.37 ,b: 1.51 ,c: 70.9 ,d: 2211.2 ,e: 10.58 ,f: 2603010.0 },
  {a: 0.37 ,b: 1.54 ,c: 70.8 ,d: 2201.1 ,e: 10.6 ,f: 2672033.0 },
  {a: 0.37 ,b: 1.58 ,c: 70.8 ,d: 2189.5 ,e: 10.61 ,f: 2732982.0 },
  {a: 0.37 ,b: 1.61 ,c: 70.8 ,d: 2176.3 ,e: 10.61 ,f: 2784745.0 },
  {a: 0.37 ,b: 1.64 ,c: 70.8 ,d: 2161.7 ,e: 10.6 ,f: 2826559.0 },
  {a: 0.37 ,b: 1.67 ,c: 71.8 ,d: 2145.6 ,e: 10.38 ,f: 2364468.0 },
  {a: 0.37 ,b: 1.7 ,c: 71.9 ,d: 2133.8 ,e: 10.36 ,f: 2378501.0 },
  {a: 0.38 ,b: 1.09 ,c: 70.9 ,d: 2245.4 ,e: 10.59 ,f: 2943105.0 },
  {a: 0.38 ,b: 1.12 ,c: 71.1 ,d: 2244.5 ,e: 10.54 ,f: 2769099.0 },
  {a: 0.38 ,b: 1.16 ,c: 70.8 ,d: 2243.6 ,e: 10.61 ,f: 2916210.0 },
  {a: 0.38 ,b: 1.19 ,c: 71.1 ,d: 2242.6 ,e: 10.54 ,f: 2700892.0 },
  {a: 0.38 ,b: 1.22 ,c: 70.8 ,d: 2241.6 ,e: 10.61 ,f: 2841421.0 },
  {a: 0.38 ,b: 1.25 ,c: 70.5 ,d: 2240.5 ,e: 10.67 ,f: 2973006.0 },
  {a: 0.38 ,b: 1.29 ,c: 70.9 ,d: 2239.5 ,e: 10.58 ,f: 2711443.0 },
  {a: 0.38 ,b: 1.32 ,c: 70.6 ,d: 2238.4 ,e: 10.64 ,f: 2836909.0 },
  {a: 0.38 ,b: 1.35 ,c: 70.4 ,d: 2237.0 ,e: 10.69 ,f: 2953392.0 },
  {a: 0.38 ,b: 1.38 ,c: 70.2 ,d: 2233.7 ,e: 10.74 ,f: 3067198.0 },
  {a: 0.38 ,b: 1.41 ,c: 70.7 ,d: 2228.8 ,e: 10.63 ,f: 2749969.0 },
  {a: 0.38 ,b: 1.45 ,c: 70.5 ,d: 2222.3 ,e: 10.67 ,f: 2849704.0 },
  {a: 0.38 ,b: 1.48 ,c: 70.4 ,d: 2214.4 ,e: 10.69 ,f: 2934608.0 },
  {a: 0.38 ,b: 1.51 ,c: 70.3 ,d: 2205.4 ,e: 10.72 ,f: 3020303.0 },
  {a: 0.38 ,b: 1.54 ,c: 71.0 ,d: 2195.3 ,e: 10.56 ,f: 2629284.0 },
  {a: 0.38 ,b: 1.58 ,c: 71.0 ,d: 2183.7 ,e: 10.57 ,f: 2689492.0 },
  {a: 0.38 ,b: 1.61 ,c: 70.9 ,d: 2170.6 ,e: 10.57 ,f: 2740537.0 },
  {a: 0.38 ,b: 1.64 ,c: 71.0 ,d: 2156.1 ,e: 10.56 ,f: 2781710.0 },
  {a: 0.38 ,b: 1.67 ,c: 71.1 ,d: 2140.2 ,e: 10.53 ,f: 2804049.0 },
  {a: 0.38 ,b: 1.7 ,c: 71.1 ,d: 2128.5 ,e: 10.52 ,f: 2820622.0 },
  {a: 0.39 ,b: 1.09 ,c: 71.1 ,d: 2239.4 ,e: 10.55 ,f: 2895332.0 },
  {a: 0.39 ,b: 1.12 ,c: 70.7 ,d: 2238.6 ,e: 10.62 ,f: 3055167.0 },
  {a: 0.39 ,b: 1.16 ,c: 71.0 ,d: 2237.7 ,e: 10.57 ,f: 2869288.0 },
  {a: 0.39 ,b: 1.19 ,c: 70.7 ,d: 2236.7 ,e: 10.64 ,f: 3014290.0 },
  {a: 0.39 ,b: 1.22 ,c: 71.0 ,d: 2235.7 ,e: 10.57 ,f: 2795800.0 },
  {a: 0.39 ,b: 1.25 ,c: 70.7 ,d: 2234.6 ,e: 10.63 ,f: 2926234.0 },
  {a: 0.39 ,b: 1.29 ,c: 70.4 ,d: 2233.6 ,e: 10.69 ,f: 3055942.0 },
  {a: 0.39 ,b: 1.32 ,c: 70.2 ,d: 2232.5 ,e: 10.74 ,f: 3184963.0 },
  {a: 0.39 ,b: 1.35 ,c: 70.6 ,d: 2231.1 ,e: 10.65 ,f: 2907372.0 },
  {a: 0.39 ,b: 1.38 ,c: 70.4 ,d: 2227.9 ,e: 10.7 ,f: 3020183.0 },
  {a: 0.39 ,b: 1.41 ,c: 70.2 ,d: 2223.0 ,e: 10.75 ,f: 3136687.0 },
  {a: 0.39 ,b: 1.45 ,c: 70.7 ,d: 2216.5 ,e: 10.63 ,f: 2805222.0 },
  {a: 0.39 ,b: 1.48 ,c: 70.6 ,d: 2208.7 ,e: 10.65 ,f: 2889270.0 },
  {a: 0.39 ,b: 1.51 ,c: 70.5 ,d: 2199.7 ,e: 10.68 ,f: 2974115.0 },
  {a: 0.39 ,b: 1.54 ,c: 70.4 ,d: 2189.6 ,e: 10.69 ,f: 3050935.0 },
  {a: 0.39 ,b: 1.58 ,c: 70.4 ,d: 2178.1 ,e: 10.69 ,f: 3110647.0 },
  {a: 0.39 ,b: 1.61 ,c: 71.1 ,d: 2165.0 ,e: 10.53 ,f: 2696772.0 },
  {a: 0.39 ,b: 1.64 ,c: 71.2 ,d: 2150.6 ,e: 10.52 ,f: 2737313.0 },
  {a: 0.39 ,b: 1.67 ,c: 71.3 ,d: 2134.8 ,e: 10.49 ,f: 2759132.0 },
  {a: 0.39 ,b: 1.7 ,c: 71.3 ,d: 2123.2 ,e: 10.48 ,f: 2775423.0 },
  {a: 0.4 ,b: 1.09 ,c: 71.3 ,d: 2233.2 ,e: 10.5 ,f: 2845394.0 },
  {a: 0.4 ,b: 1.12 ,c: 70.9 ,d: 2232.4 ,e: 10.58 ,f: 3003781.0 },
  {a: 0.4 ,b: 1.16 ,c: 70.7 ,d: 2231.5 ,e: 10.64 ,f: 3153084.0 },
  {a: 0.4 ,b: 1.19 ,c: 70.9 ,d: 2230.5 ,e: 10.59 ,f: 2963903.0 },
  {a: 0.4 ,b: 1.22 ,c: 70.6 ,d: 2229.5 ,e: 10.65 ,f: 3098562.0 },
  {a: 0.4 ,b: 1.25 ,c: 70.9 ,d: 2228.5 ,e: 10.59 ,f: 2877307.0 },
  {a: 0.4 ,b: 1.29 ,c: 70.6 ,d: 2227.4 ,e: 10.64 ,f: 3005810.0 },
  {a: 0.4 ,b: 1.32 ,c: 70.4 ,d: 2226.3 ,e: 10.7 ,f: 3133622.0 },
  {a: 0.4 ,b: 1.35 ,c: 70.2 ,d: 2225.0 ,e: 10.75 ,f: 3260613.0 },
  {a: 0.4 ,b: 1.38 ,c: 70.6 ,d: 2221.8 ,e: 10.66 ,f: 2970987.0 },
  {a: 0.4 ,b: 1.41 ,c: 70.3 ,d: 2216.9 ,e: 10.7 ,f: 3086476.0 },
  {a: 0.4 ,b: 1.45 ,c: 70.2 ,d: 2210.5 ,e: 10.74 ,f: 3187676.0 },
  {a: 0.4 ,b: 1.48 ,c: 70.8 ,d: 2202.7 ,e: 10.61 ,f: 2841810.0 },
  {a: 0.4 ,b: 1.51 ,c: 70.6 ,d: 2193.6 ,e: 10.64 ,f: 2925758.0 },
  {a: 0.4 ,b: 1.54 ,c: 70.6 ,d: 2183.6 ,e: 10.65 ,f: 3001694.0 },
  {a: 0.4 ,b: 1.58 ,c: 70.5 ,d: 2172.1 ,e: 10.65 ,f: 3060554.0 },
  {a: 0.4 ,b: 1.61 ,c: 70.5 ,d: 2159.1 ,e: 10.65 ,f: 3117501.0 },
  {a: 0.4 ,b: 1.64 ,c: 71.4 ,d: 2144.7 ,e: 10.48 ,f: 2690827.0 },
  {a: 0.4 ,b: 1.67 ,c: 71.5 ,d: 2129.1 ,e: 10.45 ,f: 2712082.0 },
  {a: 0.4 ,b: 1.7 ,c: 71.5 ,d: 2117.6 ,e: 10.44 ,f: 2728053.0 },
  {a: 0.41 ,b: 1.09 ,c: 71.0 ,d: 2227.1 ,e: 10.57 ,f: 3108042.0 },
  {a: 0.41 ,b: 1.12 ,c: 71.1 ,d: 2226.3 ,e: 10.54 ,f: 2953525.0 },
  {a: 0.41 ,b: 1.16 ,c: 70.8 ,d: 2225.4 ,e: 10.6 ,f: 3101413.0 },
  {a: 0.41 ,b: 1.19 ,c: 71.1 ,d: 2224.4 ,e: 10.55 ,f: 2914615.0 },
  {a: 0.41 ,b: 1.22 ,c: 70.8 ,d: 2223.4 ,e: 10.61 ,f: 3047984.0 },
  {a: 0.41 ,b: 1.25 ,c: 70.5 ,d: 2222.4 ,e: 10.66 ,f: 3188752.0 },
  {a: 0.41 ,b: 1.29 ,c: 70.8 ,d: 2221.4 ,e: 10.6 ,f: 2956767.0 },
  {a: 0.41 ,b: 1.32 ,c: 70.6 ,d: 2220.3 ,e: 10.65 ,f: 3083402.0 },
  {a: 0.41 ,b: 1.35 ,c: 70.3 ,d: 2219.0 ,e: 10.7 ,f: 3209237.0 },
  {a: 0.41 ,b: 1.38 ,c: 70.1 ,d: 2215.8 ,e: 10.75 ,f: 3332283.0 },
  {a: 0.41 ,b: 1.41 ,c: 70.5 ,d: 2211.0 ,e: 10.66 ,f: 3037374.0 },
  {a: 0.41 ,b: 1.45 ,c: 70.4 ,d: 2204.6 ,e: 10.69 ,f: 3137598.0 },
  {a: 0.41 ,b: 1.48 ,c: 70.3 ,d: 2196.8 ,e: 10.72 ,f: 3230558.0 },
  {a: 0.41 ,b: 1.51 ,c: 70.8 ,d: 2187.8 ,e: 10.59 ,f: 2878486.0 },
  {a: 0.41 ,b: 1.54 ,c: 70.8 ,d: 2177.8 ,e: 10.61 ,f: 2953560.0 },
  {a: 0.41 ,b: 1.58 ,c: 70.7 ,d: 2166.3 ,e: 10.61 ,f: 3011602.0 },
  {a: 0.41 ,b: 1.61 ,c: 70.7 ,d: 2153.4 ,e: 10.61 ,f: 3067797.0 },
  {a: 0.41 ,b: 1.64 ,c: 70.8 ,d: 2139.1 ,e: 10.6 ,f: 3105020.0 },
  {a: 0.41 ,b: 1.67 ,c: 71.7 ,d: 2123.6 ,e: 10.41 ,f: 2666172.0 },
  {a: 0.41 ,b: 1.7 ,c: 71.7 ,d: 2112.1 ,e: 10.4 ,f: 2681830.0 },
  {a: 0.42 ,b: 1.09 ,c: 71.2 ,d: 2221.1 ,e: 10.53 ,f: 3056239.0 },
  {a: 0.42 ,b: 1.12 ,c: 70.9 ,d: 2220.2 ,e: 10.59 ,f: 3216477.0 },
  {a: 0.42 ,b: 1.16 ,c: 71.0 ,d: 2219.4 ,e: 10.56 ,f: 3050258.0 },
  {a: 0.42 ,b: 1.19 ,c: 70.7 ,d: 2218.4 ,e: 10.62 ,f: 3204079.0 },
  {a: 0.42 ,b: 1.22 ,c: 71.0 ,d: 2217.4 ,e: 10.56 ,f: 2997895.0 },
  {a: 0.42 ,b: 1.25 ,c: 70.7 ,d: 2216.4 ,e: 10.62 ,f: 3137388.0 },
  {a: 0.42 ,b: 1.29 ,c: 70.5 ,d: 2215.4 ,e: 10.68 ,f: 3276116.0 },
  {a: 0.42 ,b: 1.32 ,c: 70.8 ,d: 2214.3 ,e: 10.61 ,f: 3033665.0 },
  {a: 0.42 ,b: 1.35 ,c: 70.5 ,d: 2213.0 ,e: 10.66 ,f: 3158356.0 },
  {a: 0.42 ,b: 1.38 ,c: 70.3 ,d: 2209.9 ,e: 10.71 ,f: 3280309.0 },
  {a: 0.42 ,b: 1.41 ,c: 70.1 ,d: 2205.1 ,e: 10.75 ,f: 3397450.0 },
  {a: 0.42 ,b: 1.45 ,c: 70.6 ,d: 2198.7 ,e: 10.65 ,f: 3088015.0 },
  {a: 0.42 ,b: 1.48 ,c: 70.5 ,d: 2190.9 ,e: 10.68 ,f: 3180024.0 },
  {a: 0.42 ,b: 1.51 ,c: 70.3 ,d: 2182.0 ,e: 10.7 ,f: 3272110.0 },
  {a: 0.42 ,b: 1.54 ,c: 70.3 ,d: 2172.0 ,e: 10.71 ,f: 3347194.0 },
  {a: 0.42 ,b: 1.58 ,c: 70.9 ,d: 2160.6 ,e: 10.57 ,f: 2963160.0 },
  {a: 0.42 ,b: 1.61 ,c: 70.9 ,d: 2147.8 ,e: 10.57 ,f: 3018626.0 },
  {a: 0.42 ,b: 1.64 ,c: 71.0 ,d: 2133.6 ,e: 10.56 ,f: 3055197.0 },
  {a: 0.42 ,b: 1.67 ,c: 71.1 ,d: 2118.1 ,e: 10.54 ,f: 3088564.0 },
  {a: 0.42 ,b: 1.7 ,c: 71.2 ,d: 2106.8 ,e: 10.52 ,f: 3098333.0 },
  {a: 0.43 ,b: 1.09 ,c: 70.9 ,d: 2215.0 ,e: 10.58 ,f: 3309087.0 },
  {a: 0.43 ,b: 1.12 ,c: 71.1 ,d: 2214.2 ,e: 10.55 ,f: 3163232.0 },
  {a: 0.43 ,b: 1.16 ,c: 70.7 ,d: 2213.4 ,e: 10.62 ,f: 3329221.0 },
  {a: 0.43 ,b: 1.19 ,c: 70.9 ,d: 2212.4 ,e: 10.58 ,f: 3151594.0 },
  {a: 0.43 ,b: 1.22 ,c: 70.7 ,d: 2211.4 ,e: 10.63 ,f: 3294951.0 },
  {a: 0.43 ,b: 1.25 ,c: 70.9 ,d: 2210.4 ,e: 10.58 ,f: 3086080.0 },
  {a: 0.43 ,b: 1.29 ,c: 70.7 ,d: 2209.4 ,e: 10.64 ,f: 3223539.0 },
  {a: 0.43 ,b: 1.32 ,c: 70.4 ,d: 2208.3 ,e: 10.69 ,f: 3360258.0 },
  {a: 0.43 ,b: 1.35 ,c: 70.7 ,d: 2207.1 ,e: 10.62 ,f: 3107518.0 },
  {a: 0.43 ,b: 1.38 ,c: 70.5 ,d: 2204.0 ,e: 10.67 ,f: 3228374.0 },
  {a: 0.43 ,b: 1.41 ,c: 70.3 ,d: 2199.3 ,e: 10.71 ,f: 3344453.0 },
  {a: 0.43 ,b: 1.45 ,c: 70.2 ,d: 2192.9 ,e: 10.74 ,f: 3453923.0 },
  {a: 0.43 ,b: 1.48 ,c: 70.6 ,d: 2185.1 ,e: 10.64 ,f: 3129531.0 },
  {a: 0.43 ,b: 1.51 ,c: 70.5 ,d: 2176.2 ,e: 10.66 ,f: 3220674.0 },
  {a: 0.43 ,b: 1.54 ,c: 70.5 ,d: 2166.2 ,e: 10.67 ,f: 3294825.0 },
  {a: 0.43 ,b: 1.58 ,c: 70.4 ,d: 2154.9 ,e: 10.68 ,f: 3367586.0 },
  {a: 0.43 ,b: 1.61 ,c: 71.1 ,d: 2142.1 ,e: 10.53 ,f: 2969521.0 },
  {a: 0.43 ,b: 1.64 ,c: 71.2 ,d: 2128.0 ,e: 10.52 ,f: 3005436.0 },
  {a: 0.43 ,b: 1.67 ,c: 71.2 ,d: 2112.7 ,e: 10.5 ,f: 3038240.0 },
  {a: 0.43 ,b: 1.7 ,c: 71.3 ,d: 2101.4 ,e: 10.48 ,f: 3047664.0 },
  {a: 0.44 ,b: 1.09 ,c: 71.1 ,d: 2208.9 ,e: 10.54 ,f: 3252574.0 },
  {a: 0.44 ,b: 1.12 ,c: 71.3 ,d: 2208.1 ,e: 10.51 ,f: 3108704.0 },
  {a: 0.44 ,b: 1.16 ,c: 70.9 ,d: 2207.2 ,e: 10.58 ,f: 3273155.0 },
  {a: 0.44 ,b: 1.19 ,c: 70.7 ,d: 2206.3 ,e: 10.63 ,f: 3428492.0 },
  {a: 0.44 ,b: 1.22 ,c: 70.9 ,d: 2205.3 ,e: 10.59 ,f: 3239769.0 },
  {a: 0.44 ,b: 1.25 ,c: 70.6 ,d: 2204.3 ,e: 10.65 ,f: 3389085.0 },
  {a: 0.44 ,b: 1.29 ,c: 70.9 ,d: 2203.3 ,e: 10.59 ,f: 3169667.0 },
  {a: 0.44 ,b: 1.32 ,c: 70.6 ,d: 2202.2 ,e: 10.65 ,f: 3305091.0 },
  {a: 0.44 ,b: 1.35 ,c: 70.4 ,d: 2201.0 ,e: 10.7 ,f: 3439711.0 },
  {a: 0.44 ,b: 1.38 ,c: 70.7 ,d: 2198.0 ,e: 10.63 ,f: 3175159.0 },
  {a: 0.44 ,b: 1.41 ,c: 70.5 ,d: 2193.3 ,e: 10.66 ,f: 3290144.0 },
  {a: 0.44 ,b: 1.45 ,c: 70.4 ,d: 2186.9 ,e: 10.7 ,f: 3398538.0 },
  {a: 0.44 ,b: 1.48 ,c: 70.3 ,d: 2179.1 ,e: 10.72 ,f: 3499044.0 },
  {a: 0.44 ,b: 1.51 ,c: 70.7 ,d: 2170.2 ,e: 10.62 ,f: 3167959.0 },
  {a: 0.44 ,b: 1.54 ,c: 70.7 ,d: 2160.3 ,e: 10.63 ,f: 3241165.0 },
  {a: 0.44 ,b: 1.58 ,c: 70.6 ,d: 2149.1 ,e: 10.64 ,f: 3313059.0 },
  {a: 0.44 ,b: 1.61 ,c: 70.7 ,d: 2136.4 ,e: 10.63 ,f: 3365934.0 },
  {a: 0.44 ,b: 1.64 ,c: 71.4 ,d: 2122.3 ,e: 10.48 ,f: 2954466.0 },
  {a: 0.44 ,b: 1.67 ,c: 71.4 ,d: 2107.1 ,e: 10.46 ,f: 2986672.0 },
  {a: 0.44 ,b: 1.7 ,c: 71.5 ,d: 2095.9 ,e: 10.44 ,f: 2995745.0 },
  {a: 0.45 ,b: 1.09 ,c: 71.3 ,d: 2202.7 ,e: 10.49 ,f: 3196127.0 },
  {a: 0.45 ,b: 1.12 ,c: 71.0 ,d: 2201.9 ,e: 10.56 ,f: 3364329.0 },
  {a: 0.45 ,b: 1.16 ,c: 71.1 ,d: 2201.0 ,e: 10.53 ,f: 3217155.0 },
  {a: 0.45 ,b: 1.19 ,c: 70.9 ,d: 2200.1 ,e: 10.59 ,f: 3370963.0 },
  {a: 0.45 ,b: 1.22 ,c: 71.1 ,d: 2199.2 ,e: 10.55 ,f: 3184655.0 },
  {a: 0.45 ,b: 1.25 ,c: 70.8 ,d: 2198.2 ,e: 10.61 ,f: 3332552.0 },
  {a: 0.45 ,b: 1.29 ,c: 70.5 ,d: 2197.1 ,e: 10.66 ,f: 3479651.0 },
  {a: 0.45 ,b: 1.32 ,c: 70.8 ,d: 2196.1 ,e: 10.6 ,f: 3249981.0 },
  {a: 0.45 ,b: 1.35 ,c: 70.6 ,d: 2194.9 ,e: 10.65 ,f: 3383328.0 },
  {a: 0.45 ,b: 1.38 ,c: 70.4 ,d: 2191.9 ,e: 10.7 ,f: 3513881.0 },
  {a: 0.45 ,b: 1.41 ,c: 70.7 ,d: 2187.2 ,e: 10.62 ,f: 3235890.0 },
  {a: 0.45 ,b: 1.45 ,c: 70.6 ,d: 2180.9 ,e: 10.66 ,f: 3343211.0 },
  {a: 0.45 ,b: 1.48 ,c: 70.4 ,d: 2173.2 ,e: 10.68 ,f: 3442655.0 },
  {a: 0.45 ,b: 1.51 ,c: 70.3 ,d: 2164.3 ,e: 10.7 ,f: 3541449.0 },
  {a: 0.45 ,b: 1.54 ,c: 70.9 ,d: 2154.4 ,e: 10.59 ,f: 3187588.0 },
  {a: 0.45 ,b: 1.58 ,c: 70.8 ,d: 2143.3 ,e: 10.6 ,f: 3258641.0 },
  {a: 0.45 ,b: 1.61 ,c: 70.8 ,d: 2130.7 ,e: 10.59 ,f: 3310740.0 },
  {a: 0.45 ,b: 1.64 ,c: 70.9 ,d: 2116.7 ,e: 10.57 ,f: 3351252.0 },
  {a: 0.45 ,b: 1.67 ,c: 71.6 ,d: 2101.5 ,e: 10.42 ,f: 2935260.0 },
  {a: 0.45 ,b: 1.7 ,c: 71.7 ,d: 2090.4 ,e: 10.4 ,f: 2943975.0 },
  {a: 0.46 ,b: 1.09 ,c: 71.1 ,d: 2196.8 ,e: 10.53 ,f: 3435973.0 },
  {a: 0.46 ,b: 1.12 ,c: 71.2 ,d: 2196.0 ,e: 10.52 ,f: 3308465.0 },
  {a: 0.46 ,b: 1.16 ,c: 70.9 ,d: 2195.1 ,e: 10.58 ,f: 3474106.0 },
  {a: 0.46 ,b: 1.19 ,c: 71.1 ,d: 2194.2 ,e: 10.55 ,f: 3315640.0 },
  {a: 0.46 ,b: 1.22 ,c: 70.8 ,d: 2193.2 ,e: 10.6 ,f: 3467074.0 },
  {a: 0.46 ,b: 1.25 ,c: 71.0 ,d: 2192.3 ,e: 10.57 ,f: 3278182.0 },
  {a: 0.46 ,b: 1.29 ,c: 70.7 ,d: 2191.2 ,e: 10.62 ,f: 3423923.0 },
  {a: 0.46 ,b: 1.32 ,c: 70.5 ,d: 2190.2 ,e: 10.66 ,f: 3560699.0 },
  {a: 0.46 ,b: 1.35 ,c: 70.8 ,d: 2189.1 ,e: 10.61 ,f: 3329087.0 },
  {a: 0.46 ,b: 1.38 ,c: 70.6 ,d: 2186.2 ,e: 10.66 ,f: 3458480.0 },
  {a: 0.46 ,b: 1.41 ,c: 70.4 ,d: 2181.5 ,e: 10.7 ,f: 3582789.0 },
  {a: 0.46 ,b: 1.45 ,c: 70.8 ,d: 2175.2 ,e: 10.61 ,f: 3290012.0 },
  {a: 0.46 ,b: 1.48 ,c: 70.6 ,d: 2167.5 ,e: 10.64 ,f: 3388447.0 },
  {a: 0.46 ,b: 1.51 ,c: 70.5 ,d: 2158.6 ,e: 10.66 ,f: 3486249.0 },
  {a: 0.46 ,b: 1.54 ,c: 70.5 ,d: 2148.8 ,e: 10.67 ,f: 3566425.0 },
  {a: 0.46 ,b: 1.58 ,c: 71.0 ,d: 2137.7 ,e: 10.56 ,f: 3206419.0 },
  {a: 0.46 ,b: 1.61 ,c: 71.0 ,d: 2125.2 ,e: 10.55 ,f: 3257805.0 },
  {a: 0.46 ,b: 1.64 ,c: 71.1 ,d: 2111.3 ,e: 10.53 ,f: 3297663.0 },
  {a: 0.46 ,b: 1.67 ,c: 71.2 ,d: 2096.2 ,e: 10.51 ,f: 3333489.0 },
  {a: 0.46 ,b: 1.7 ,c: 71.9 ,d: 2085.1 ,e: 10.36 ,f: 2894404.0 },
  {a: 0.47 ,b: 1.09 ,c: 71.3 ,d: 2190.9 ,e: 10.49 ,f: 3378500.0 },
  {a: 0.47 ,b: 1.12 ,c: 71.4 ,d: 2190.1 ,e: 10.48 ,f: 3252804.0 },
  {a: 0.47 ,b: 1.16 ,c: 71.1 ,d: 2189.2 ,e: 10.54 ,f: 3416874.0 },
  {a: 0.47 ,b: 1.19 ,c: 70.9 ,d: 2188.3 ,e: 10.59 ,f: 3579985.0 },
  {a: 0.47 ,b: 1.22 ,c: 71.0 ,d: 2187.4 ,e: 10.56 ,f: 3410494.0 },
  {a: 0.47 ,b: 1.25 ,c: 70.7 ,d: 2186.4 ,e: 10.62 ,f: 3567793.0 },
  {a: 0.47 ,b: 1.29 ,c: 70.9 ,d: 2185.4 ,e: 10.58 ,f: 3368403.0 },
  {a: 0.47 ,b: 1.32 ,c: 70.7 ,d: 2184.3 ,e: 10.62 ,f: 3503829.0 },
  {a: 0.47 ,b: 1.35 ,c: 70.5 ,d: 2183.2 ,e: 10.67 ,f: 3646661.0 },
  {a: 0.47 ,b: 1.38 ,c: 70.7 ,d: 2180.4 ,e: 10.62 ,f: 3403272.0 },
  {a: 0.47 ,b: 1.41 ,c: 70.6 ,d: 2175.7 ,e: 10.66 ,f: 3526463.0 },
  {a: 0.47 ,b: 1.45 ,c: 70.4 ,d: 2169.4 ,e: 10.69 ,f: 3642565.0 },
  {a: 0.47 ,b: 1.48 ,c: 70.8 ,d: 2161.8 ,e: 10.6 ,f: 3334431.0 },
  {a: 0.47 ,b: 1.51 ,c: 70.7 ,d: 2152.9 ,e: 10.62 ,f: 3431248.0 },
  {a: 0.47 ,b: 1.54 ,c: 70.7 ,d: 2143.2 ,e: 10.63 ,f: 3510533.0 },
  {a: 0.47 ,b: 1.58 ,c: 70.6 ,d: 2132.2 ,e: 10.63 ,f: 3579695.0 },
  {a: 0.47 ,b: 1.61 ,c: 71.2 ,d: 2119.8 ,e: 10.51 ,f: 3205153.0 },
  {a: 0.47 ,b: 1.64 ,c: 71.3 ,d: 2106.0 ,e: 10.49 ,f: 3244389.0 },
  {a: 0.47 ,b: 1.67 ,c: 71.4 ,d: 2090.9 ,e: 10.48 ,f: 3279644.0 },
  {a: 0.47 ,b: 1.7 ,c: 71.5 ,d: 2079.9 ,e: 10.45 ,f: 3290176.0 },
  {a: 0.48 ,b: 1.09 ,c: 71.5 ,d: 2184.7 ,e: 10.45 ,f: 3318104.0 },
  {a: 0.48 ,b: 1.12 ,c: 71.2 ,d: 2183.9 ,e: 10.51 ,f: 3493765.0 },
  {a: 0.48 ,b: 1.16 ,c: 71.3 ,d: 2183.0 ,e: 10.49 ,f: 3356729.0 },
  {a: 0.48 ,b: 1.19 ,c: 71.1 ,d: 2182.1 ,e: 10.55 ,f: 3518188.0 },
  {a: 0.48 ,b: 1.22 ,c: 70.8 ,d: 2181.2 ,e: 10.6 ,f: 3678700.0 },
  {a: 0.48 ,b: 1.25 ,c: 70.9 ,d: 2180.2 ,e: 10.58 ,f: 3506783.0 },
  {a: 0.48 ,b: 1.29 ,c: 70.7 ,d: 2179.2 ,e: 10.62 ,f: 3653511.0 },
  {a: 0.48 ,b: 1.32 ,c: 70.9 ,d: 2178.2 ,e: 10.58 ,f: 3444034.0 },
  {a: 0.48 ,b: 1.35 ,c: 70.7 ,d: 2177.1 ,e: 10.63 ,f: 3585457.0 },
  {a: 0.48 ,b: 1.38 ,c: 70.5 ,d: 2174.3 ,e: 10.67 ,f: 3724114.0 },
  {a: 0.48 ,b: 1.41 ,c: 70.8 ,d: 2169.7 ,e: 10.61 ,f: 3467199.0 },
  {a: 0.48 ,b: 1.45 ,c: 70.6 ,d: 2163.4 ,e: 10.65 ,f: 3582135.0 },
  {a: 0.48 ,b: 1.48 ,c: 70.5 ,d: 2155.8 ,e: 10.67 ,f: 3688601.0 },
  {a: 0.48 ,b: 1.51 ,c: 70.9 ,d: 2147.0 ,e: 10.58 ,f: 3373433.0 },
  {a: 0.48 ,b: 1.54 ,c: 70.9 ,d: 2137.4 ,e: 10.59 ,f: 3451785.0 },
  {a: 0.48 ,b: 1.58 ,c: 70.8 ,d: 2126.5 ,e: 10.59 ,f: 3520137.0 },
  {a: 0.48 ,b: 1.61 ,c: 70.9 ,d: 2114.1 ,e: 10.58 ,f: 3576935.0 },
  {a: 0.48 ,b: 1.64 ,c: 71.5 ,d: 2100.4 ,e: 10.45 ,f: 3188475.0 },
  {a: 0.48 ,b: 1.67 ,c: 71.6 ,d: 2085.4 ,e: 10.43 ,f: 3223151.0 },
  {a: 0.48 ,b: 1.7 ,c: 71.7 ,d: 2074.5 ,e: 10.41 ,f: 3233326.0 },
  {a: 0.49 ,b: 1.09 ,c: 71.4 ,d: 2178.4 ,e: 10.48 ,f: 3540608.0 },
  {a: 0.49 ,b: 1.12 ,c: 71.4 ,d: 2177.6 ,e: 10.47 ,f: 3430895.0 },
  {a: 0.49 ,b: 1.16 ,c: 71.2 ,d: 2176.8 ,e: 10.52 ,f: 3595555.0 },
  {a: 0.49 ,b: 1.19 ,c: 71.3 ,d: 2175.9 ,e: 10.51 ,f: 3455692.0 },
  {a: 0.49 ,b: 1.22 ,c: 71.0 ,d: 2174.9 ,e: 10.56 ,f: 3614547.0 },
  {a: 0.49 ,b: 1.25 ,c: 71.1 ,d: 2174.0 ,e: 10.53 ,f: 3445090.0 },
  {a: 0.49 ,b: 1.29 ,c: 70.9 ,d: 2173.0 ,e: 10.58 ,f: 3590274.0 },
  {a: 0.49 ,b: 1.32 ,c: 70.7 ,d: 2171.9 ,e: 10.63 ,f: 3742844.0 },
  {a: 0.49 ,b: 1.35 ,c: 70.9 ,d: 2170.9 ,e: 10.59 ,f: 3523571.0 },
  {a: 0.49 ,b: 1.38 ,c: 70.7 ,d: 2168.2 ,e: 10.63 ,f: 3660924.0 },
  {a: 0.49 ,b: 1.41 ,c: 70.5 ,d: 2163.6 ,e: 10.66 ,f: 3784742.0 },
  {a: 0.49 ,b: 1.45 ,c: 70.8 ,d: 2157.4 ,e: 10.6 ,f: 3521180.0 },
  {a: 0.49 ,b: 1.48 ,c: 70.7 ,d: 2149.8 ,e: 10.63 ,f: 3626609.0 },
  {a: 0.49 ,b: 1.51 ,c: 70.6 ,d: 2141.1 ,e: 10.64 ,f: 3714453.0 },
  {a: 0.49 ,b: 1.54 ,c: 71.1 ,d: 2131.5 ,e: 10.55 ,f: 3392846.0 },
  {a: 0.49 ,b: 1.58 ,c: 71.0 ,d: 2120.8 ,e: 10.55 ,f: 3460451.0 },
  {a: 0.49 ,b: 1.61 ,c: 71.1 ,d: 2108.5 ,e: 10.54 ,f: 3516590.0 },
  {a: 0.49 ,b: 1.64 ,c: 71.1 ,d: 2094.8 ,e: 10.53 ,f: 3560328.0 },
  {a: 0.49 ,b: 1.67 ,c: 71.8 ,d: 2079.9 ,e: 10.39 ,f: 3166754.0 },
  {a: 0.49 ,b: 1.7 ,c: 71.8 ,d: 2069.0 ,e: 10.37 ,f: 3176617.0 },
  {a: 0.5 ,b: 1.09 ,c: 71.6 ,d: 2172.0 ,e: 10.44 ,f: 3474279.0 },
  {a: 0.5 ,b: 1.12 ,c: 71.3 ,d: 2171.2 ,e: 10.49 ,f: 3650213.0 },
  {a: 0.5 ,b: 1.16 ,c: 71.4 ,d: 2170.3 ,e: 10.48 ,f: 3529287.0 },
  {a: 0.5 ,b: 1.19 ,c: 71.1 ,d: 2169.4 ,e: 10.54 ,f: 3699315.0 },
  {a: 0.5 ,b: 1.22 ,c: 71.2 ,d: 2168.5 ,e: 10.52 ,f: 3548778.0 },
  {a: 0.5 ,b: 1.25 ,c: 71.0 ,d: 2167.6 ,e: 10.57 ,f: 3713218.0 },
  {a: 0.5 ,b: 1.29 ,c: 71.1 ,d: 2166.6 ,e: 10.54 ,f: 3525441.0 },
  {a: 0.5 ,b: 1.32 ,c: 70.9 ,d: 2165.6 ,e: 10.59 ,f: 3676430.0 },
  {a: 0.5 ,b: 1.35 ,c: 71.1 ,d: 2164.5 ,e: 10.54 ,f: 3460119.0 },
  {a: 0.5 ,b: 1.38 ,c: 70.9 ,d: 2162.2 ,e: 10.59 ,f: 3596531.0 },
  {a: 0.5 ,b: 1.41 ,c: 70.7 ,d: 2157.7 ,e: 10.62 ,f: 3719614.0 },
  {a: 0.5 ,b: 1.45 ,c: 71.0 ,d: 2151.6 ,e: 10.56 ,f: 3459990.0 },
  {a: 0.5 ,b: 1.48 ,c: 70.9 ,d: 2144.1 ,e: 10.58 ,f: 3564756.0 },
  {a: 0.5 ,b: 1.51 ,c: 70.8 ,d: 2135.4 ,e: 10.6 ,f: 3651964.0 },
  {a: 0.5 ,b: 1.54 ,c: 70.8 ,d: 2125.9 ,e: 10.6 ,f: 3737412.0 },
  {a: 0.5 ,b: 1.58 ,c: 71.2 ,d: 2115.2 ,e: 10.51 ,f: 3401723.0 },
  {a: 0.5 ,b: 1.61 ,c: 71.3 ,d: 2103.1 ,e: 10.5 ,f: 3457431.0 },
  {a: 0.5 ,b: 1.64 ,c: 71.3 ,d: 2089.5 ,e: 10.49 ,f: 3500799.0 },
  {a: 0.5 ,b: 1.67 ,c: 71.4 ,d: 2074.7 ,e: 10.46 ,f: 3530989.0 },
  {a: 0.5 ,b: 1.7 ,c: 72.0 ,d: 2063.8 ,e: 10.33 ,f: 3121524.0 },
  {a: 0.51 ,b: 1.09 ,c: 71.8 ,d: 2165.5 ,e: 10.39 ,f: 3407738.0 },
  {a: 0.51 ,b: 1.12 ,c: 71.5 ,d: 2164.7 ,e: 10.45 ,f: 3581749.0 },
  {a: 0.51 ,b: 1.16 ,c: 71.6 ,d: 2163.9 ,e: 10.44 ,f: 3462816.0 },
  {a: 0.51 ,b: 1.19 ,c: 71.3 ,d: 2163.0 ,e: 10.49 ,f: 3631037.0 },
  {a: 0.51 ,b: 1.22 ,c: 71.4 ,d: 2162.1 ,e: 10.47 ,f: 3482813.0 },
  {a: 0.51 ,b: 1.25 ,c: 71.2 ,d: 2161.1 ,e: 10.53 ,f: 3645554.0 },
  {a: 0.51 ,b: 1.29 ,c: 70.9 ,d: 2160.2 ,e: 10.57 ,f: 3799221.0 },
  {a: 0.51 ,b: 1.32 ,c: 71.1 ,d: 2159.1 ,e: 10.54 ,f: 3609825.0 },
  {a: 0.51 ,b: 1.35 ,c: 70.9 ,d: 2158.1 ,e: 10.58 ,f: 3750236.0 },
  {a: 0.51 ,b: 1.38 ,c: 71.1 ,d: 2156.0 ,e: 10.54 ,f: 3531677.0 },
  {a: 0.51 ,b: 1.41 ,c: 70.9 ,d: 2152.0 ,e: 10.58 ,f: 3654175.0 },
  {a: 0.51 ,b: 1.45 ,c: 70.8 ,d: 2145.9 ,e: 10.61 ,f: 3777425.0 },
  {a: 0.51 ,b: 1.48 ,c: 71.1 ,d: 2138.4 ,e: 10.54 ,f: 3503079.0 },
  {a: 0.51 ,b: 1.51 ,c: 71.0 ,d: 2129.9 ,e: 10.55 ,f: 3589847.0 },
  {a: 0.51 ,b: 1.54 ,c: 71.0 ,d: 2120.5 ,e: 10.56 ,f: 3674873.0 },
  {a: 0.51 ,b: 1.58 ,c: 71.0 ,d: 2109.9 ,e: 10.57 ,f: 3749217.0 },
  {a: 0.51 ,b: 1.61 ,c: 71.5 ,d: 2097.8 ,e: 10.46 ,f: 3399033.0 },
  {a: 0.51 ,b: 1.64 ,c: 71.5 ,d: 2084.3 ,e: 10.45 ,f: 3442164.0 },
  {a: 0.51 ,b: 1.67 ,c: 71.6 ,d: 2069.6 ,e: 10.42 ,f: 3472108.0 },
  {a: 0.51 ,b: 1.7 ,c: 71.7 ,d: 2058.8 ,e: 10.4 ,f: 3484035.0 },
  {a: 0.52 ,b: 1.09 ,c: 71.7 ,d: 2159.1 ,e: 10.41 ,f: 3606293.0 },
  {a: 0.52 ,b: 1.12 ,c: 71.8 ,d: 2158.4 ,e: 10.4 ,f: 3514036.0 },
  {a: 0.52 ,b: 1.16 ,c: 71.5 ,d: 2157.5 ,e: 10.46 ,f: 3693256.0 },
  {a: 0.52 ,b: 1.19 ,c: 71.5 ,d: 2156.6 ,e: 10.45 ,f: 3563508.0 },
  {a: 0.52 ,b: 1.22 ,c: 71.3 ,d: 2155.7 ,e: 10.5 ,f: 3728946.0 },
  {a: 0.52 ,b: 1.25 ,c: 71.4 ,d: 2154.8 ,e: 10.48 ,f: 3578644.0 },
  {a: 0.52 ,b: 1.29 ,c: 71.2 ,d: 2153.8 ,e: 10.53 ,f: 3730633.0 },
  {a: 0.52 ,b: 1.32 ,c: 70.9 ,d: 2152.8 ,e: 10.57 ,f: 3881775.0 },
  {a: 0.52 ,b: 1.35 ,c: 71.1 ,d: 2151.8 ,e: 10.54 ,f: 3682816.0 },
  {a: 0.52 ,b: 1.38 ,c: 70.9 ,d: 2149.8 ,e: 10.58 ,f: 3819718.0 },
  {a: 0.52 ,b: 1.41 ,c: 71.1 ,d: 2146.2 ,e: 10.53 ,f: 3589015.0 },
  {a: 0.52 ,b: 1.45 ,c: 71.0 ,d: 2140.4 ,e: 10.57 ,f: 3711902.0 },
  {a: 0.52 ,b: 1.48 ,c: 70.9 ,d: 2133.0 ,e: 10.59 ,f: 3825763.0 },
  {a: 0.52 ,b: 1.51 ,c: 71.2 ,d: 2124.5 ,e: 10.51 ,f: 3528400.0 },
  {a: 0.52 ,b: 1.54 ,c: 71.2 ,d: 2115.2 ,e: 10.52 ,f: 3613133.0 },
  {a: 0.52 ,b: 1.58 ,c: 71.2 ,d: 2104.6 ,e: 10.52 ,f: 3687237.0 },
  {a: 0.52 ,b: 1.61 ,c: 71.2 ,d: 2092.6 ,e: 10.52 ,f: 3749066.0 },
  {a: 0.52 ,b: 1.64 ,c: 71.7 ,d: 2079.2 ,e: 10.41 ,f: 3384577.0 },
  {a: 0.52 ,b: 1.67 ,c: 71.8 ,d: 2064.6 ,e: 10.38 ,f: 3414395.0 },
  {a: 0.52 ,b: 1.7 ,c: 71.9 ,d: 2053.9 ,e: 10.36 ,f: 3426266.0 }
]

export function getTestData() {
  let data = [];
  for (var i = 0; i < testData.length; i++) {
    var x = testData[i]["a"];
    var y = testData[i]["b"];
    var z = testData[i]["c"]*-1;

    data.push({ x: x, y: y, z: z });
  }
  return data
}

export function createData(graph, code, results) {
  let data = [];
  for (var i = 0; i < results.length; i++) {
    let x = results[i]['gcr']
    let y = results[i]['dcac']
    let z = 0;
    if (graph == 0) {
      // yield graph
      z = results[i]['YIELD']
    } else {
      // finance graph
      if (code == 151) {
        // LCOE
        z = Number(Math.round(results[i]['lcoe'] * 1000+'e'+2)+'e-'+2) * -1
      } else {
        // IRR
        z = results[i]['irr']
      }
    }
    data.push({ x: x, y: y, z: z, id: results[i]['id'] });
  }
  return data  
}

export const defaultOptions = {
  width: "500px",
  height: "370px",
  xCenter: '55%',
  yCenter: '40%',
  style: "dot-color",
  colormap: ['#B11717','#F31818', '#F36018', '#DBEC18', '#25C61E'],
  showPerspective: false,
  showGrid: true,
  keepAspectRatio: false,
  showLegend: false,
  xLabel: 'GCR',
  yLabel: 'DC:AC',
  zLabel: '$/MWh',
  axisFontSize: 30,
  tooltip: true,
  tooltipStyle:{
    content: {
      border: '1px solid #333',
      boxShadow: '0px'
    },
    line:{
      height:'20px'
    }
  },
  verticalRatio: 1.0,
  cameraPosition: {
    horizontal: -0.65,
    vertical: 0.55,
    distance: 2.0,
  },
  dotSizeRatio: 0.014,
  dotSizeMinFraction: 0.6,
  dotSizeMaxFraction: 0.6   
}