const TopographyDD = {
  0: {
    id: 0,
    data: {
      topo_id: undefined,
      topo_loading: false,
      topo_live: false,
      topo_url: undefined,
      topo_mode: 'Off',
      topo_source: 'USGS',
      topo_error: undefined,
      topo_scale_url: undefined,
      do_generate_layers: false,
      generate_ele: false,
      generate_ns: false,
      generate_ew: false,
      generate_u: false,

      has_layers: false,

      layers_generated: {
        ele: { avail: false, gavail: false },
        NS: { avail: false, gavail: false, limit: 10 },
        EW: { avail: false, gavail: false, limit: 20 },
        U: { avail: false, gavail: false, limit: 15 },
        CF: { avail: false, limit: 10 },
      },

      // adding grading
      grading: {
        enabled: false,
        generated: false,
        ns_grade_target: 0,
        ew_grade_target: 0,
        grade_target_type: 0,
        grade_target: 0,
        cut_amt: 0,
        fill_amt: 0,
      },

      topo_action: 'nothing', // flag, nothing

      doTopo: false,
      grade_limit: undefined,

      ele_use_graded_data: false,
      ns_grade_limit: 10,
      ns_grade_enabled: false,
      ns_grade_raw_enabled: false,
      ew_grade_limit: 20,
      ew_grade_enabled: false,
      ew_grade_raw_enabled: false,
      u_grade_limit: 15,
      u_grade_enabled: false,
      u_grade_raw_enabled: false,
    },
  },
};

export { TopographyDD };
