import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
0% { opacity: 0;}
100% { opacity: 1;}
`;

const fadeOut = keyframes`
0% { opacity: 1;}
100% { opacity: 0;}
`;

export const PageLayout = styled.section`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 1fr;
  position: relative;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Nav = styled.nav`
  display: grid;
  grid-column: 1;
  grid-row: 1;
  grid-template-rows: auto 1fr;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  background-color: var(--gutter-background-color);
  border-right: 2px solid #002bcb;
  padding: 0;
  position: relative;
  pointer-events: ${(props) => (props.isAuthed && !props.show_agreement_modal ? "all" : "none")};

  .ant-menu.nav-menu,
  .ant-menu.support-menu {
    background-color: var(--gutter-background-color) !important;
  }

  .brand-logo {
    grid-row: 1;
    height: 100px;
    margin-top: 12px;
    width: inherit;

    a > img {
      width: 45px;
      display: block;
      margin: -5px auto 0px auto;
    }
  }

  .mobile-background {
    display: none;
  }

  .nav-links {
    grid-row: 3;
    width: 100%;
    height: min-content;
    margin-top: auto;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, auto);

    & > button,
    a,
    img {
      height: auto;
      margin: 8px auto;
      border: none;
      font-size: 18px;
      background: transparent;
      color: var(--primary-brand-color);
      /* background-color: var(--gutter-background-color); */
      transition: transform 0.3s var(--easing-spring) 0s;

      &:hover,
      &:focus {
        cursor: pointer;
        transform: scale(1.3);
        /* background-color: var(--gutter-background-color); */
      }
    }

    button img {
      height: 20px;
    }
  }

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    left: 5px;
    top: 0px;
    width: 80px;
    height: min-content;
    background: transparent;
    border-right: none;
    z-index: 601;

    .mobile-background {
      display: block;
      position: absolute;
      z-index: -1;
      width: 100%;
      height: ${(props) => (props.toggleMobileNav ? "100%" : "0%")};
      top: 0px;
      border-radius: 0px 0px 3px 3px;
      background-color: rgba(0, 0, 0, 0.4);
      transition: height 0.25s var(--easing);
    }

    .ant-menu.nav-menu,
    .ant-menu.support-menu {
      background-color: transparent !important;
    }

    .brand-logo {
      height: 80px;
      width: 80px;
      margin: -6px auto;

      a img {
        width: 65px;
        padding: 5px 8px;
      }
      Button {
        width: 80px;
        height: 70px;
        padding: 0px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .nav-links {
      width: 100%;
      height: inherit;
      display: flex;
      flex-direction: column;
      transition: opacity 0.25s var(--easing);
      transition-delay: 0.25s;

      & > Button {
        height: 35px;
        font-size: 24px;
        margin: 0px auto 18px auto;
        border: none;
        color: var(--primary-brand-color);
        background-color: transparent;
      }
    }

    .open {
      visibility: visible;
      opacity: 1;
    }

    .closed {
      visibility: hidden;
      opacity: 0;
    }
  }

  /* .nav-links {
    grid-row: 2;
    height: min-content;
    width: 100%;
    justify-content: center;
    margin-top: auto;
    display: grid;

    .nav-icons {
      width: 70px;
      display: flex;
      align-items: center;
    }

    .ant-menu .nav-button {
      height: 65px !important;
      width: 90px !important;
      display: flex !important;
      margin: 0 auto !important;
      padding: 0px !important;
      line-height: 16px !important;
      text-align: center !important;
      align-items: center;
      justify-content: center;

      color: var(--primary-brand-color);
      background-color: var(--gutter-background-color);
    }
  }

  .active-link {
    background-color: rgba(255, 255, 255, 0.15);
  } */

  /* .support-container {
    grid-row: 3;
    height: 100%;
    width: 90px;
    display: grid;
    background-color: var(--gutter-background-color);
    align-content: end;

    span {
      font-size: 20px;
    }

    .ant-menu .nav-button {
      height: 38px !important;
      width: 90px !important;
      display: flex !important;
      margin: 0 auto !important;
      padding: 0px !important;
      text-align: center !important;
      align-items: center;
      justify-content: center;
      color: var(--primary-brand-color);
      background-color: var(--gutter-background-color);
    }
  } */ ;
`;
