import React from "react";
import { Tag, Tooltip } from "antd";
import { CloudOutlined, CloudUploadOutlined, CloudDownloadOutlined } from "@ant-design/icons";

export const LockedByYou = (
  <span>
    <Tag color="#f3f3f3">
      <CloudDownloadOutlined style={{ color: "#002bcb", marginRight: "5px" }} />
      <Tooltip
        className="collaborate"
        title={
          <div>
            <div>Project Checked Out by me.</div>
            <div>Project is Read/Write. Check in when finished.</div>
          </div>
        }
      >
        <u style={{ color: "#333" }}>out by me</u>
      </Tooltip>
    </Tag>
  </span>
);
export const LockedBySomeone = (email, short = false) => (
  <span>
    <Tag color="#f3f3f3">
      <CloudDownloadOutlined style={{ color: "#002bcb", marginRight: "5px" }} />
      <Tooltip
        className="collaborate"
        title={
          <div>
            <div>Project Checked Out by {email}</div>
            <div>Project is Read Only.</div>
          </div>
        }
      >
        <u style={{ color: "#333" }}>out by other</u>
      </Tooltip>
    </Tag>
  </span>
);
export const NotLocked = (
  <span>
    <Tag color="#f3f3f3">
      <CloudOutlined style={{ color: "#002bcb", marginRight: "5px" }} />
      <Tooltip
        className="collaborate"
        title={
          <div>
            <div>Project Checked In.</div>
            <div>Project is Read Only. Check Out for Read/Write.</div>
          </div>
        }
      >
        <u style={{ color: "#333" }}>checked in</u>
      </Tooltip>
    </Tag>
  </span>
);

export const collab_columns = [
  {
    title: "Email",
    dataIndex: "email",
    width: "275px",
    // filters: [],
    // specify the condition of filtering result
    // here is that finding the name started with `value`
    // onFilter: (value, record) => record.email.indexOf(value) === 0,
    sorter: (a, b) => a.email.length - b.email.length,
    sortDirections: ["descend"],
  },
  {
    title: "Owner",
    dataIndex: "creator",
    width: "275px",
    render: (text, record) => <span>{text == 1 ? "Yes" : "No"}</span>,
  },
  // {
  // 	title: 'Roles',
  // 	dataIndex: 'roles',
  // 	width: '100px',
  // 	// render: (text, record) => <span>{text==1?'Yes':'No'}</span>
  // },
  {
    title: "Status",
    dataIndex: "locked",
    width: "200px",
    render: (text, record) => <span>{text == 1 && "Checked-Out"}</span>,
  },
];

function getTime(editDate) {
  var date = new Date(editDate * 1000);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;

  let format_d = `${strTime} - ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  return format_d.toString();
}
