import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { store, history } from './components/Redux/_helpers';

import { Routes } from './Routes';

// import Leaflet from 'leaflet';
// Leaflet.Icon.Default.imagePath = '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/';
import './app.less';
import './app.css';
// import 'leaflet/dist/leaflet.css';
// import 'leaflet-draw/dist/leaflet.draw.css';

// import L from 'leaflet'; // Same as `import * as L from 'leaflet'` with webpack
// L.Icon.Default.imagePath = '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/';
// import 'leaflet/dist/leaflet.css';

import LogRocket from 'logrocket';
import ReactGA from 'react-ga';
// import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('ppao5i/sift');
  ReactGA.initialize('UA-106909670-1');
}
if (process.env.NODE_ENV !== 'production') {
  console.log(`Looks like we are in ${process.env.NODE_ENV} mode!`);
  ReactGA.initialize('UA-165577939-1');
  // const {whyDidYouUpdate} = require('why-did-you-update')
  // whyDidYouUpdate(React)
}

history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

ReactDOM.render(
  // specify basename below if running
  // in a subdirectory or set as "/" if app runs in root
  <Provider store={store}>
    <Router basename={'/'} history={history}>
      <Routes />
    </Router>
  </Provider>,
  document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// serviceWorker.register();
