import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { siftActions } from "../../Redux";

import { Input, Spin, Button, Switch, Tooltip, Alert } from "antd";
import { MenuFoldOutlined, MinusCircleOutlined } from "@ant-design/icons";
import ReactDataSheet from "react-datasheet";
import { SheetRenderer } from "../SheetRenderer";

const height_adder = 20;
const columns = [
  { label: "", width: "20%" },
  { label: "", width: "20%" },
  { label: "", width: "0%" },
];
const renderSheet = (props) => {
  return <SheetRenderer columns={columns} {...props} />;
};

const InverterInputs = () => {
  const inputs = useSelector((state) => state.sift.ioManager.inputs.inverter.data);
  const derate_maxoutput = useSelector((state) => state.sift.ioManager.inputs.inverter.data.derate_maxoutput);
  const derate_temps = useSelector((state) => state.sift.ioManager.inputs.inverter.data.derate_temps);
  const account_loading = useSelector((state) => state.account.account_loading);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const saving = account_loading || input_loading;
  const dispatch = useDispatch();

  const [disable_temp_derate, set_disable_temp_derate] = useState(false);

  useEffect(() => {
    if (derate_maxoutput.length < 2 && derate_temps.length < 2) {
      set_disable_temp_derate(true);
      updateInput("derate_toggle", 0);
    }
  }, [derate_maxoutput.length, derate_temps.length]);

  useEffect(() => {
    if (inputs.derate_toggle == true && disable_temp_derate == true) {
      onInsertRow("enable_temp_derate", "temp");
      set_disable_temp_derate(false);
    }
  }, [inputs.derate_toggle]);

  // const updateInput = debounce((key, value) => {
  //   console.log(key, value);
  //   dispatch(siftActions.updateInput(key, value))

  // }, 200);
  function updateInput(key, value) {
    dispatch(siftActions.updateInput(key, value));
  }

  let eff_data = [];
  let eff_height = 0;
  let temp_data = [];
  let temp_height = 0;
  let dc_max_pwr = 0;

  // ////////////////////////////////////////////
  // DATA TABLE FUNCTION
  function handleCreateEffData() {
    let data = inputs;
    if (!data.inv_pd_eff_pout || !data.inv_pd_efficiency) return;
    var def = data.default == 1;
    eff_data = [
      [
        { value: "P Out kWac", readOnly: true },
        { value: "Efficiency(%)", readOnly: true },
      ],
    ];
    eff_height = 78;
    let minLoop = Math.min(data.inv_pd_eff_pout.length, data.inv_pd_efficiency.length);
    // Some inverters ended up having different sized arrays, so we're going with the min length
    for (var index = 0; index < minLoop; index++) {
      if (def) {
        eff_data.splice(eff_data.length, 0, [
          { readOnly: def, value: (data.inv_pd_eff_pout[index] / 100).toFixed(2) },
          { readOnly: def, value: data.inv_pd_efficiency[index].toFixed(2) },
        ]);
      } else {
        eff_data.splice(eff_data.length, 0, [
          { readOnly: def, value: (data.inv_pd_eff_pout[index] / 100).toFixed(2) },
          { readOnly: def, value: data.inv_pd_efficiency[index].toFixed(2) },
          {
            readOnly: true,
            value: "",
            component: (
              <Tooltip placement="right" title="(Insert Below) / (Delete)" mouseEnterDelay={0.5}>
                <div style={{ width: 60 }}>
                  <Button
                    style={{ marginRight: 2, borderColor: "#ffffff" }}
                    shape="circle"
                    id={index}
                    size="small"
                    type="primary"
                    ghost
                    onClick={(e) => {
                      onInsertRow(e, "eff");
                    }}
                  >
                    <MenuFoldOutlined />
                  </Button>
                  <Button
                    style={{ marginRight: 2, borderColor: "#ffffff" }}
                    shape="circle"
                    id={(parseInt(index) + 1) * 100}
                    size="small"
                    type="primary"
                    ghost
                    onClick={(e) => {
                      onRemoveRow(e, "eff");
                    }}
                  >
                    <MinusCircleOutlined />
                  </Button>
                </div>
              </Tooltip>
            ),
            forceComponent: true,
          },
        ]);
      }
      eff_height = eff_height + height_adder;
    }
    eff_data = eff_data;
    eff_height = eff_height;
  }
  function handleCreateTempData() {
    let data = inputs;
    var def = data.default == 1;
    temp_data = [
      [
        { value: "Temp °C", readOnly: true },
        { value: "Max kWac", readOnly: true },
      ],
    ];
    temp_height = 78;
    for (var index in data.derate_maxoutput) {
      if (def) {
        temp_data.splice(temp_data.length, 0, [
          { readOnly: def, value: data.derate_temps[index] },
          // {readOnly: index<2 || def, value: data.derate_maxoutput[index]},
          { readOnly: def || def, value: data.derate_maxoutput[index] },
        ]);
      } else {
        temp_data.splice(temp_data.length, 0, [
          { readOnly: def, value: data.derate_temps[index] },
          // {readOnly: index<2 || def, value: data.derate_maxoutput[index]},
          { readOnly: def || def, value: data.derate_maxoutput[index] },
          {
            readOnly: true,
            value: "",
            component: (
              <Tooltip placement="right" title="(Insert Below) / (Delete)" mouseEnterDelay={0.5}>
                <div style={{ width: 60 }}>
                  <Button
                    style={{ marginRight: 2, borderColor: "#ffffff" }}
                    shape="circle"
                    id={(parseInt(index) + 1) * 1000}
                    size="small"
                    type="primary"
                    ghost
                    onClick={(e) => {
                      onInsertRow(e, "temp");
                    }}
                  >
                    <MenuFoldOutlined />
                  </Button>
                  <Button
                    style={{ marginRight: 2, borderColor: "#ffffff" }}
                    shape="circle"
                    id={(parseInt(index) + 1) * 10000}
                    size="small"
                    type="primary"
                    ghost
                    onClick={(e) => {
                      onRemoveRow(e, "temp");
                    }}
                  >
                    <MinusCircleOutlined />
                  </Button>
                </div>
              </Tooltip>
            ),
            forceComponent: true,
          },
        ]);
      }
      temp_height = temp_height + height_adder;
    }
    temp_data = temp_data;
    temp_height = temp_height;
  }
  function onInsertRow(e, type) {
    let data = inputs;
    let index = 0;
    if (type == "eff") {
      index = parseInt(e.target.id);
      data.inv_pd_eff_pout.splice(index + 1, 0, 0);
      data.inv_pd_efficiency.splice(index + 1, 0, 0);
      updateInput("inv_pd_eff_pout", data.inv_pd_eff_pout);
      updateInput("inv_pd_efficiency", data.inv_pd_efficiency);
      handleCreateEffData();
    }
    if (type == "temp") {
      index = e == "enable_temp_derate" ? 0 : parseInt(e.target.id) / 1000 - 1;
      data.derate_temps.splice(index + 1, 0, 0);
      data.derate_maxoutput.splice(index + 1, 0, 0);
      updateInput("derate_temps", data.derate_temps);
      updateInput("derate_maxoutput", data.derate_maxoutput);
      handleCreateEffData();
    }
  }
  function onRemoveRow(e, type) {
    let data = inputs;
    let index = 0;
    if (type == "eff") {
      if (data.inv_pd_eff_pout.length == 1) return;
      index = e.target.id / 100 - 1;
      data.inv_pd_eff_pout.splice(index, 1);
      data.inv_pd_efficiency.splice(index, 1);
      updateInput("inv_pd_eff_pout", data.inv_pd_eff_pout);
      updateInput("inv_pd_efficiency", data.inv_pd_efficiency);
      handleCreateEffData();
    }
    if (type == "temp") {
      if (data.derate_temps.length == 1) return;
      index = e.target.id / 10000 - 1;
      data.derate_temps.splice(index, 1);
      data.derate_maxoutput.splice(index, 1);
      updateInput("derate_temps", data.derate_temps);
      updateInput("derate_maxoutput", data.derate_maxoutput);
      handleCreateEffData();
    }
  }
  function handleEffChanges(changes) {
    const data = eff_data.map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      if (data[row] && data[row][col]) {
        // NS: Fix for stripping % and $ from excel copied values
        let fixed_value = value.replace("$", "").replace("%", "");
        data[row][col] = { ...data[row][col], value: fixed_value };
      }
    });
    let newInvData = data.slice(1, data.length);
    var POut = [];
    var Eff = [];
    newInvData.forEach((row) => {
      let pout = parseFloat(row[0].value) == row[0].value ? parseFloat(row[0].value) * 100 : 0;
      let eff = parseFloat(row[1].value) == row[1].value ? parseFloat(row[1].value) : 0;
      POut.push(pout);
      Eff.push(eff);
    });
    updateInput("inv_pd_eff_pout", POut);
    updateInput("inv_pd_efficiency", Eff);
  }
  function handleTempChanges(changes) {
    const data = temp_data.map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      if (data[row] && data[row][col]) {
        // NS: Fix for stripping % and $ from excel copied values
        let fixed_value = value.replace("$", "").replace("%", "");
        data[row][col] = { ...data[row][col], value: fixed_value };
      }
    });
    let newInvData = data.slice(1, data.length);
    var Temp = [];
    var Output = [];
    newInvData.forEach((row) => {
      let temp = parseFloat(row[0].value) == row[0].value ? parseFloat(row[0].value) : 0;
      let output = parseFloat(row[1].value) == row[1].value ? parseFloat(row[1].value) : 0;
      Temp.push(temp);
      Output.push(output);
    });
    updateInput("derate_temps", Temp);
    updateInput("derate_maxoutput", Output);
  }
  // ////////////////////////////////////////////

  function calculateDCMaxPwr() {
    if (!isNaN(inputs.inv_pd_pacokw) && !isNaN(inputs.maxEff)) {
      if (inputs.inv_pd_pacokw > 0 && inputs.maxEff > 0) {
        dc_max_pwr = (inputs.inv_pd_pacokw / inputs.maxEff) * 100;
        dc_max_pwr = parseFloat(dc_max_pwr.toFixed(2));
      }
    }
  }

  //  init functions called every refresh
  // Create eff table data
  handleCreateEffData();
  // Create temp table data
  handleCreateTempData();
  calculateDCMaxPwr();

  return (
    <div className="input-content-box">
      {/* <Spin spinning={saving} className="sift-loader"> */}
      {/* <div className="input-row name">
          <label>
            Name
          </label>
          <Input id='name'  
            onChange={(event)=> {updateInput(event.target.id,event.target.value)}} 
            value={inputs.name} size='small'
            disabled={saving}     
          />
        </div>  */}

      <div className="input-row">
        <label>Inverter Rating</label>
        <Input
          id="inverterRating"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.inverterRating}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>kW</span>}
        />
      </div>

      <div className="input-row">
        <label>AC Power Nom</label>
        <Input
          id="pnom"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.pnom}
          size="small"
          disabled={saving}
          key={30}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>kWac</span>}
        />
      </div>
      <div className="input-row">
        <label>Min MPPT</label>
        <Input
          id="mppt_low_inverter"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mppt_low_inverter}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>V</span>}
        />
      </div>

      <div className="input-row">
        <label>AC Power Max</label>
        <Input
          id="inv_pd_pacokw"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.inv_pd_pacokw}
          size="small"
          disabled={saving}
          key={30}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>kWac</span>}
        />
      </div>
      <div className="input-row">
        <label>Max MPPT</label>
        <Input
          id="mppt_hi_inverter"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mppt_hi_inverter}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>V</span>}
        />
      </div>

      <div className="input-row">
        <label>Max Efficiency</label>
        <Input
          id="maxEff"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.maxEff}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>
      <div className="input-row">
        <label>Max PV Volts</label>
        <Input
          id="inv_pd_vdcmax"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.inv_pd_vdcmax}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>V</span>}
        />
      </div>

      <div className="input-row">
        <label>DC at Max Pwr</label>
        <span style={{ marginLeft: "auto", marginRight: "3px" }}>{dc_max_pwr} kW</span>
      </div>
      <div className="input-row">
        <label>Night Pwr Use</label>
        <Input
          id="inv_pd_pnt"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.inv_pd_pnt}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>W</span>}
        />
      </div>

      <div className="input-row">
        <label>Efficiency Curve</label>
      </div>
      <div className="input-table-container">
        <div className="input-table" style={{ height: eff_height, width: 200 }}>
          <ReactDataSheet
            data={eff_data}
            sheetRenderer={renderSheet}
            valueRenderer={(cell) => cell.value}
            onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
            onCellsChanged={handleEffChanges}
          />
        </div>
      </div>

      <Tooltip placement="bottom" title={disable_temp_derate ? "Temp Derate must have at least 2 rows to be active." : ""} mouseEnterDelay={0.5}>
        <section className="input-row">
          <label>Temp Derate</label>
          <Switch
            size="small"
            id="derate_toggle"
            checked={inputs.derate_toggle == 1}
            onChange={(b, e) => {
              updateInput("derate_toggle", b ? 1 : 0);
            }}
          />
        </section>
      </Tooltip>

      {inputs.derate_toggle == 1 && (
        <div className="input-table-container">
          <div className="input-table" style={{ height: temp_height, width: 200 }}>
            <ReactDataSheet
              data={temp_data}
              sheetRenderer={renderSheet}
              valueRenderer={(cell) => cell.value}
              onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
              onCellsChanged={handleTempChanges}
            />
          </div>
        </div>
      )}
      {/* </Spin> */}
    </div>
  );
};

export { InverterInputs };
