import React, { useState, useEffect } from 'react';

import elecshade from './images/elecshade.png';
import inveffcurve from './images/inveffcurve.png';
import outputvars from './images/outputvars_v2.gif';

import { useSelector, useDispatch } from 'react-redux';
import { siftActions } from '../Redux'

import MathJax from 'react-mathjax2'

// import './_doc.scss'
import './styles.css'

import { Layout, Anchor, Modal, Button, Spin } from 'antd';
const { Link } = Anchor;
const { Sider } = Layout;

const Docs = () => {
  const [mathJaxLoaded, setMathJaxLoaded] = useState(false);
  const [mathJaxLoading, setMathJaxLoading] = useState(false);

  useEffect(() => {
    setMathJaxLoading(true)
  }, []);  

  const dispatch = useDispatch();

  const docsVisible = useSelector((state) => state.sift.ioManager.uiState.docs_visible)

  function hideBugReport () { 
    dispatch(siftActions.toggleHelpToolbarVisibility('docs_visible', false))
  }
  return (
    <Modal
      title=''
      visible={docsVisible}
      width='95%'
      onCancel={hideBugReport}
      className="docs-modal"
      key="docs-modal"
      wrapClassName="docs-modal"      
      footer={
        <Button onClick={hideBugReport} type="primary">
          Close
        </Button>
      }	     
    >    
      <div className="body-green" >
          {/* NAV SECTION */}
          <div  style={{ float: 'left', background: '#fff', paddingTop: '3%', width:'300px' }}  >
            <Anchor className="tech-doc" offsetTop={13}
              getContainer={() =>
                document.querySelectorAll(".docs-modal")[0]
              }
            >
              <Link href="#11" title="1.1 Sunfig Performance Model"></Link>
              <Link href="#12" title="1.2 Solar Irradiance and Incidence">
                <Link href="#121" title="1.2.1 Beam Incident Component"></Link>
                <Link href="#122" title="1.2.2 Albedo Incident Irradiance"></Link>
                <Link href="#123" title="1.2.3 Horizontal Irradiance Components"></Link>
              </Link>
              <Link href="#13" title="1.3 Effective Plane of Array Irradiance">
                <Link href="#131" title="1.3.1 Beam Shade Factor"></Link>
                <Link href="#132" title="1.3.2 Diffuse Shade Factor"></Link>
                <Link href="#133" title="1.3.3 Albedo Shade Factor"></Link>
                <Link href="#134" title="1.3.4 IAM Albedo and Diffuse Factors"></Link>
                <Link href="#135" title="1.3.5 IAM Beam"></Link>
                <Link href="#136" title="1.3.6 Loss Order"></Link>
                <Link href="#137" title="1.3.7 Air Mass Modifier"></Link>
              </Link>
              <Link href="#14" title="1.4 Effective Plane of Array Irradiance">
                <Link href="#141" title="1.4.1 PV Module Model"></Link>
                <Link href="#142" title="1.4.2 PV Thermal Behavior"></Link>
                <Link href="#143" title="1.4.3 Temperature Correction on Gamma"></Link>
                <Link href="#144" title="1.4.4 Electrical Shading"></Link>
                <Link href="#145" title="1.4.5 DC Ohmic Loss"></Link>
                <Link href="#146" title="1.4.6 Bifacial Modeling"></Link>
              </Link>
              <Link href="#15" title="1.5 Inverter and AC Output">
                <Link href="#151" title="1.5.1 Inverter Temperature Derating"></Link>
                <Link href="#152" title="1.5.2 Decoupled AC"></Link>
              </Link>
              <Link href="#16" title="1.6 System AC Output">
                <Link href="#161" title="1.6.1 AC Losses"></Link>
                <Link href="#162" title="1.6.2 Grid Power Limit"></Link>
              </Link>
              <Link href="#17" title="1.7 Reporting">
                <Link href="#171" title="1.7.1 Performance Reporting"></Link>
              </Link>
              <Link href="#21" title="2.1 Sunfig Storage Model"></Link>
              <Link href="#31" title="3.1 Sunfig Financial Models"></Link>
              <Link href="#32" title="3.2 LCOE"></Link>
              <Link href="#33" title="3.3 IRR and NPV">
                <Link href="#331" title="3.3.1 Revenue"></Link>
                <Link href="#332" title="3.3.2 Expenses"></Link>
                <Link href="#333" title="3.3.3 Cost"></Link>
                <Link href="#334" title="3.3.4 EBIDA"></Link>
                <Link href="#335" title="3.3.5 Reserve Funding"></Link>
                <Link href="#336" title="3.3.6 Debt Sizing"></Link>
                <Link href="#337" title="3.3.7 Fixed Percent"></Link>
                <Link href="#338" title="3.3.8 DSCR"></Link>
                <Link href="#339" title="3.3.9 Pre-tax Cashflows"></Link>
                <Link href="#3310" title="3.3.10 Depreciation"></Link>
                <Link href="#3311" title="3.3.11 Investment Tax Credit"></Link>
                <Link href="#3312" title="3.3.12 Taxes"></Link>
              </Link>
              <Link href="#41" title="4.1 References"></Link>
            </Anchor>
          </div>

          <Layout style={{ maxWidth: '1000px', background: '#fff', paddingLeft: '1%' }}>

            {/* HEADER */}
            <div id="doc-header" className="doc-header text-center">
              <h1 className="doc-title">Technical Documentation</h1>
              <div className="meta"><i className="far fa-clock"></i> Last updated: July 16th, 2020</div>
            </div>

            <Spin spinning={!mathJaxLoaded} tip="Loading Documentation...">

            <div className="doc-body row">

              { mathJaxLoading
              &&
              <MathJax.Context
                input='ascii'
                script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=AM_HTMLorMML"
                options={{
                  asciimath2jax: {
                    useMathMLspacing: true,
                    delimiters: [["$$", "$$"]],
                    preview: "none",
                  }
                }}
                onLoad={() => setMathJaxLoaded(true)}
                // onError={(MathJax, error) => {
                //   console.warn(error);
                //   console.log("Encountered a MathJax error, re-attempting a typeset!");
                //   MathJax.Hub.Queue(
                //     MathJax.Hub.Typeset()
                //   );
                // }}
              >
                <div className="doc-content col-md-9 col-12 order-1">
                  <div className="content-inner">
                    <section id="11" className="doc-section">
                      <h2 className="section-title">1.1 Sunfig Performance Model</h2>
                      <div className="section-block">
                        <p>
                          The aim of the Sunfig Performance Model is to replicate the process, equations, and results of PVSyst as
                          closely as practicable. Specifically we seek to replicate the unlimited sheds and unlimited trackers
                          modeling capability. PVSyst is the industry standard but is not suitable or licensable for high volume or cloud-based use.
                        <br /><br />
                        Other products on the market face the same challenge and use NREL’s System Advisor Model (SAM) or PVLib.
                        In both cases, the input and output variables are different than PVSyst, leading to some confusion and difficulty
                        when comparing results. To simplify this for our users, The Sunfig Performance Model requires the
                        same input variables and reports the same output variables as PVSyst.
                        <br /><br />
                        The Sunfig Performance Model is based on NREL’s System Advisor Model (SAM) and PVLib, with significant
                        customization to improve result alignment with PVSyst. Rather than reproduce large sections of the SAM
                        Technical Reference manual, this document serves as a catalog of the algorithms and functions replaced,
                        added, and developed to create the Sunfig Performance Model. Links and sources are provided in the references portion of this document.
                        <br /><br />
                        We are working on consolidating the Sunfig Performance Model into a single programming language and documentation.
                        When that work is complete, we intend make it publicly available and
                        reviewable. Future versions of this document will define every modeling step, for now please
                        refer to the <a href="https://www.nrel.gov/docs/fy18osti/67399.pdf">SAM Technical Reference</a> manual for
                        items unlisted here.
                        </p>
                      </div>
                    </section>

                    <section id="12" className="doc-section">
                      <h2 className="section-title">1.2 Solar Irradiance and Incidence</h2>
                      <div id="121" className="section-block">
                        <h3 className="block-title">1.2.1 Beam Incident Component</h3>
                        <p>Equation or algorithm adjusted according to (Sandia).</p>

                        <div className='formula'>
                          <MathJax.Node>BeamInc = (GHI-DHI)/cos(Zenith) ** cos(AOI)</MathJax.Node>
                        </div>

                      </div>

                      <div id="122" className="section-block">
                        <h3 className="block-title">1.2.2 Albedo Incident Irradiance</h3>
                        <p>Equation or algorithm adjusted according to (Perez).</p>

                        {/* <Eq_2 /> */}
                        <div className='formula'>
                          <div>
                            <MathJax.Node>AlbInc = Albedo ** GHI ** (1-cos(til\t) )/2</MathJax.Node>
                          </div>
                        </div>
                      </div>

                      <div id="123" className="section-block"> <h3 className="block-title">1.2.3 Horizontal Irradiance Components</h3>
                        <p>Equation or algorithm adjusted according to (PVsyst, Irradiation models).</p>

                        <div className='formula'>
                          <MathJax.Node>GlobHo\r = GHI</MathJax.Node>
                        </div>

                        <div className='formula'>
                          <MathJax.Node>Di\ffHo\r = DHI</MathJax.Node>
                        </div>

                        <div className='formula'>
                          <MathJax.Node>BeamHo\r = GHI - DHI</MathJax.Node>
                        </div>

                      </div>
                    </section>

                    <section id="13" className="doc-section"> <h2 className="section-title">1.3 Effective Plane of Array Irradiance</h2>
                      <div id="131" className="section-block"> <h3 className="block-title">1.3.1 Beam Shade Factor</h3>
                        <p>
                          Equation or algorithm adjusted according to (Appelbaum) equation A13 where H_s is the shadow height.
                          Sunfig model assumes no side penetrating irradiance, also known as edge effects. This approach
                          is conservative and applies interrow shading equally to all modules within a row.
                        </p>
                        <div className='formula'>
                          <MathJax.Node>FShdBm = (1-Hs)/(Coll\ect\o\rWi\dth)</MathJax.Node>
                        </div>
                      </div>

                      <div id="132" className="section-block"> <h3 className="block-title">1.3.2 Diffuse Shade Factor</h3>
                        <p>Equation or algorithm adjusted according to (First Solar).</p>
                        <div className='formula'>
                          <MathJax.Node>FShdDiff = (Area_(sky-RTR))/(Area_(sky-noRTR))</MathJax.Node>
                        </div>
                      </div>

                      <div id="133" className="section-block"> <h3 className="block-title">1.3.3 Albedo Shade Factor</h3>
                        <p>Equation or algorithm adjusted according to (PVsyst, Treatment of the Albedo component).</p>
                        <div className='formula'>
                          <MathJax.Node>FShdAlb = (1-n_(rows))/n_(rows)</MathJax.Node>
                        </div>
                      </div>

                      <div id="134" className="section-block"> <h3 className="block-title">1.3.4 IAM Albedo and Diffuse Factors</h3>
                        <p>
                          Equation or algorithm adjusted according to (Duffie). Equations 5.4.1 and 5.4.2 are
                          reproduced below where β is the surface slope. Effective incident angle is used to
                          calculate the IAM factors and losses.
                          </p>

                        <div className='formula'>
                          <MathJax.Node>Effective\ Inci\dent\ Angl\e,\ ground = 90-0.5788β+0.002693β^2</MathJax.Node>
                        </div>

                        <div className='formula'>
                          <MathJax.Node>Effective\ Inci\dent\ Angl\e,\ di\ffuse = 90-0.1388β+0.001497β^2</MathJax.Node>
                        </div>
                      </div>
                      <div id="135" className="section-block"> <h3 className="block-title">1.3.5 IAM Beam</h3>
                        <p>
                          This function allows users to input a table of incident angle and their associated
                          IAM factors. For all incident angles that fall between a user-defined angle, the
                          resultant IAM factor is linearly interpolated. Linear interpolation is a slightly
                          conservative approach compared to creating a spline to estimate the angle - factor relationship.
                          </p>
                      </div>

                      <div id="136" className="section-block"> <h3 className="block-title">1.3.6 Loss Order</h3>
                        <p>
                          Near Shading, IAM, and Soiling losses are applied in that order. Loss application
                          order is critical for replicating PVSyst reported loss numbers. In the Sunfig
                          Performance Model, electrical shade loss is calculated and applied after PV module
                          thermal losses. These systematic changes are in alignment with the procedures followed by (PVSyst).
                            </p>
                      </div>

                      <div id="137" className="section-block"> <h3 className="block-title">1.3.7 Air Mass Modifier</h3>
                        <p>
                          Sunfig Performance Model default does not consider AM effects, this default is in
                          line with PVSyst’s default. Air mass modifiers developed by (Lee) and Panchula will
                          be incorporated in future versions.
                          </p>
                      </div>
                    </section>

                    <section id="14" className="doc-section">
                      <h2 className="section-title">1.4 Module and DC Output</h2>
                      <div id="141" className="section-block"> <h3 className="block-title">1.4.1 PV Module Model</h3>
                        <p>Sunfig Performance Model uses the Mermoud/Lejeune module model as defined in (Mermoud).  </p>
                      </div>

                      <div id="142" className="section-block"> <h3 className="block-title">1.4.2 PV Thermal Behavior</h3>
                        <p>Equation or algorithm adjusted according to (PVsyst, Array Thermal losses). </p>


                        <div className='formula'>
                          <MathJax.Node>T_(cell) = T_(ambient) + \frac(Alpha ** G_(i\nc) ** (1-Eff_(pv)))(U_c + U_v ** v_(wi\nd))</MathJax.Node>
                        </div>
                      </div>
                      <div id="143" className="section-block">
                        <h3 className="block-title">1.4.3 Temperature Correction on Gamma</h3>
                        <p>
                          The temperature coefficient of gamma, <i>muGamma</i>, is necessary to replicate the PVSyst
                          v6.26+ DC model. This coefficient is often missing from specifications, but can be derived
                          from the temperature coefficient of max power point, <i>muPmpReq</i>. If <i>muGamma</i> is
                          missing in an import module specification, SIFT will automatically calculate <i>muGamma</i>
                          in accordance with (PVSyst, Temperature Coefficients) and the single-diode equations in (Duffie).
                        </p>
                        <p>
                          Pmp at STC is calculated from the single-diode model. From the <i>muPmpReq</i> specification,
                            we can determine the target Pmp at any temperature, in this case 45C.</p>

                        <div className='formula'>
                          <MathJax.Node>Pmp_(STC) = f_(si\ngl\e-diode)(m\uG\amma = 0)</MathJax.Node>
                        </div>

                        <div className='formula'>
                          <MathJax.Node>Pmp_(45C) = Pmp_(STC) + Pmp_(STC) ** m\uPmpReq ** (45-25)</MathJax.Node>
                        </div>
                        <p>Solve the following for <i>muGamma</i>. </p>

                        <div className='formula'>
                          <MathJax.Node>Pmp_(45C) = f_(si\ngl\e-diode)(m\uG\amma)</MathJax.Node>
                        </div>
                      </div>
                      <div id="144" className="section-block"> <h3 className="block-title">1.4.4 Electrical Shading</h3>
                        <p>
                          Electrical shading or electrical effect loss in Sunfig Performance Model replicates the
                          inputs, behavior, and outputs of the electrical shading function of PVSyst.
                          </p>
                        <center><img src={elecshade} alt="" style={{ objectFit: 'contain' }} /></center>
                      </div>

                      <div id="145" className="section-block"> <h3 className="block-title">1.4.5 DC Ohmic Loss</h3>
                        <p>Equation or algorithm adjusted according to (PVsyst, Array ohmic wiring loss).</p>

                        <div className='formula'>
                          <MathJax.Node>DCWiri\ngLoss = %STC ** DCWiri\ng\%* DCkW</MathJax.Node>
                        </div>
                      </div>

                      <div id="146" className="section-block"> <h3 className="block-title">1.4.6 Bifacial Modeling</h3>
                        <p>
                          Sunfig Performance Model uses the NREL View Factor model for bifacial module calculations
                          as described in (Marion). Shed transparent fraction and structure shading factor input
                          variables and losses were added to be in line PVSyst.
                          </p>
                      </div>
                    </section>

                    <section id="15" className="doc-section"> <h2 className="section-title">1.5 Inverter and AC Output</h2>

                      <div id="151" className="section-block"> <h3 className="block-title">1.5.1 Inverter Temperature Derating</h3>
                        <p>
                          Accepts temperature max, nominal, and any number of temperature inputs vs maximum inverter Pout.
                          At each time step the model linearly interpolates over this curve to set the maximum inverter power output.
                          </p>
                      </div>

                      <div id="152" className="section-block"> <h3 className="block-title">1.5.2 Decoupled AC</h3>
                        <p>
                          The Sunfig DC-side and AC-side performance models are independent. This was done to facilitate
                          DC-coupled storage modeling. This also allows for increased flexibility and speed when
                          iterating a fixed DC system and multiple AC options.
                          </p>
                      </div>
                      <div id="152" className="section-block"> <h3 className="block-title">1.5.2 Efficiency Curves</h3>
                        <p>
                          The Sunfig performance model uses a single efficiency curve. When importing inverter specifications
                          into SIFT, the efficiency is derived from the medium voltage efficiency data. Inverter specifications
                          have 7-8 efficiencies defined, we automatically expand this list to 30 points for improved modeling
                          accuracy. An optimized curve fit is derived based on the following logarithmic equation:
                            </p>

                        <div className='formula'>
                          <MathJax.Node>y = a**\ln(1+\(1)/(x^b)) - c</MathJax.Node>
                        </div>
                        <p>
                          From this fit, 30 points are captured to characterize the curve. During performance modeling,
                            efficiencies are determined based on a linear interpolation between these points.</p>
                        <center><img src={inveffcurve} alt="" style={{ objectFit: 'contain' }} /></center>
                      </div>
                    </section>

                    <section id="16" className="doc-section"> <h2 className="section-title">1.6 System AC Output</h2>
                      <div id="161" className="section-block"> <h3 className="block-title">1.6.1 AC Losses</h3>
                        <p>
                          Applies a static % loss to all energy after the inverter, but before the grid power limit. All MV
                            Loop, Transformer, Substation losses are applied here.</p>
                      </div>
                      <div id="162" className="section-block"> <h3 className="block-title">1.6.2 Grid Power Limit</h3>
                        <p>
                          This variable sets maximum energy output after inverter and AC losses. If power at the final
                          step, at grid, is above this limit, the power for that time step will be limited to the Grid Power Limit.
                          </p>
                      </div>
                    </section>

                    <section id="17" className="doc-section"> <h2 className="section-title">1.7 Reporting</h2>
                      <div id="171" className="section-block"> <h3 className="block-title">1.7.1 Performance Reporting</h3>
                        <p>Sunfig performance model calculates and reports many of the same variables as PVsyst.</p>
                        <img src={outputvars} alt="" style={{ objectFit: 'contain' }} />
                      </div>
                    </section>

                    <section id="21" className="doc-section">
                      <h2 className="section-title">2.1 Sunfig Storage Model</h2>
                      <p>Documentation will be loaded when API and SIFT PVS integration is complete.</p>
                    </section>


                    <section id="32" className="doc-section"> <h2 className="section-title">3.2 LCOE</h2>
                      <p>Levelized Cost of Electricity, LCOE, is calculated within all Sunfig Financial Models as:</p>
                      <div className='formula'>
                        <MathJax.Node>LCOE =  \frac( \sum_(t=1)^n \left[\frac(Cost_t)((1+d_(nom))^t) \right] + Cost_(t=0) ) (\sum_(t=1)^n \left[\frac(Production_t)((1+d_(nom))^t) \right])</MathJax.Node>
                      </div>
                    </section>

                    <section id="33" className="doc-section">
                      <h2 className="section-title">3.3 IRR and NPV</h2>
                      <p>To calculate IRR, NPV, or PPA the final cash flow of the project must be defined. The Sunfig financial models lean heavilly on the work of (Short) and the financial implementations within the NREL System Advisor Model, with updates and improvements to meet our need. The Sunfig financial model first determines the revenue generated according to the price of energy and generation profile. Debt is sized according to user inputs and then taxes, depreciation, and incentives are applied. The install and operating costs, escalations, and interest rates are used to generate an after tax cash flow. The after tax cash flow is used to calculate NPV, IRR. A full financial table is produced for each configuration calculated within SIFT.</p>
                    </section>

                    <div id="331" className="section-block"> <h3 className="block-title">3.3.1 Revenue</h3>
                      <p>Revenue is calculated according to the hourly production, after all AC losses, and the rate. Fixed or hourly rates are escalated according to the standard yearly escalation formula.</p>

                      <div className='formula'>
                        <MathJax.Node>Rate_(escalated) = Rate_(year1) ** (1+escalation)^(year-1)</MathJax.Node>
                      </div>

                      <p>The revenue for each hour is calculated according to that hour's rate and production.</p>

                      <div className='formula'>
                        <MathJax.Node>Rate Reven ue = Rate ** Production</MathJax.Node>
                      </div>

                      <p>Other sources of revenue are added, if applicable, to finalize the project’s total revenue. The most common of these are Production Based Incentives (PBI) and salvage value over time.</p>

                      <div className='formula'>
                        <MathJax.Node>Total Reven ue = Rate Reven ue + Production Incentives Reven ue + Salvag e Values </MathJax.Node>
                      </div>
                    </div>

                    <div id="332" className="section-block"> <h3 className="block-title">3.3.2 Expenses</h3>
                      <p>Operating costs are calculated according to a configuration’s DC size (kWp), AC size (kWac), the fixed costs, and the yearly escalation. Users can define the cost table of the lifetime of the project if a simple escalation is not sufficient.</p>

                      <div className='formula'>
                        <MathJax.Node>Operati ng Costs = kWp ** $/(kWp_(op)) + kWac ** $/(kWac_(op)) + Fixed$_(op)</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Operati ng Costs_(escalated) = Operati ng Costs_(year1) ** (1+escalation)^(year-1)</MathJax.Node>
                      </div>

                      <p>Storage costs and insurance costs are not in SIFT as of writing but will contribute to the total expenses.</p>

                      <div className='formula'>
                        <MathJax.Node>Total Expenses = Operati ng Expense + Insurance Costs + S t o rag e Costs</MathJax.Node>
                      </div>
                    </div>

                    <div id="333" className="section-block"> <h3 className="block-title">3.3.3 Cost</h3>
                      <p>The total cost, or construction cost, of the installation incurred at year zero. Total install cost is calculated according to a configuration’s DC size (kWp), AC size (kWac), and fixed costs.</p>
                      <div className='formula'>
                        <MathJax.Node>Total Install Costs = kWp ** $/(kWp_(i n stall)) + kWac** $/(kWac_(i n stall)) + Fixed$_(i nstall)</MathJax.Node>
                      </div>
                    </div>

                    <div id="334" className="section-block"> <h3 className="block-title">3.3.4 EBIDA</h3>
                      <p>Earnings Before Interest, Depreciation, and Amortization.</p>
                      <div className='formula'>
                        <MathJax.Node>EBIDA = Total Reven ue - Total Expenses</MathJax.Node>
                      </div>
                    </div>

                    <div id="335" className="section-block"> <h3 className="block-title">3.3.5 Reserve Funding</h3>
                      <p>Reserve funding is cash that is set aside at the beginning of the project for unexpected costs, low revenue years, or planned project costs. This is also called debt service reserve, working capital reserve, or major equipment reserve. </p>
                    </div>

                    <div id="336" className="section-block"> <h3 className="block-title">3.3.6 Debt Sizing</h3>
                      <p>Finance may be structured without debt, with a fixed percent of debt, or with a debt service coverage ratio. </p>
                      <p>Cash Available For Debt Services</p>
                      <div className='formula'>
                        <MathJax.Node>CAFDS = EBIDA - Reserve Fund i n g</MathJax.Node>
                      </div>
                    </div>

                    <div id="337" className="section-block"> <h3 className="block-title">3.3.7 Fixed Percent</h3>
                      <p>Percent debt is the amount of a project’s install costs that are financed. The total principle plus interest (PI) payment is calculated according to the following equations</p>
                      <div className='formula'>
                        <MathJax.Node>Debt Size = Debt End Balance_(n=1) = Percent Debt ** Total Install Cost</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Debt Total PI_n = (Debt Size ** Debt Interest Rate) / ( (1-(1+Debt Interest Rate))^(Debt Ten o r))</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Debt Interest Payment_n = Debt End Balance_(n-1) ** Debt Interest Rate</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Debt Pri n cipl e Payment_n = Debt Total PI_n - Debt Interest Payment_n</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Debt End Balance_n = Debt End Balance_(n-1) - Debt Pri n cipl e Payment_n</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>DSCR_n = (CAFDS_n)/(Debt Total PI_n)</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Where: n = year</MathJax.Node>
                      </div>
                    </div>

                    <div id="338" className="section-block"> <h3 className="block-title">3.3.8 DSCR</h3>
                      <p>Debt Service Coverage Ratio. Each year, for the tenor of the debt, the total debt payment is equal to a fixed ratio of total revenue. The payments and initial debt size are calculated according to the following equations.</p>
                      <div className='formula'>
                        <MathJax.Node>Present Value Debt Interest Fact or_(n=0) = 1</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Present Value Debt Interest Fact or_n = (Present Value Debt Interest Fact or_(n-1))/(1 + Debt Interest Rate)</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Present Value CAFDS = CAFDS ** Present Value Debt Interest Fact or</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Debt Sizi n g DSCR_n = (Present Value CAFDS_n)/(DSCR)</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Debt Size = sum(Debt Siz i ng DSCR)</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Debt Total PI_n = (CAFDS_n)/(DSCR)</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Debt Interest Payment_n = Debt End Balance_(n-1) ** Debt Interest Rate</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Debt Pri n cipl e Payment_n = Debt Total PI_n - Debt Interest Payment_n</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Debt End Balance_n = Debt End Balance_(n-1) - Debt Pri n cipl e Payment_n</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Where: n = year</MathJax.Node>
                      </div>
                    </div>

                    <div id="339" className="section-block"> <h3 className="block-title">3.3.9 Pre-tax Cashflows</h3>
                      <div className='formula'>
                        <MathJax.Node>Cash Flows From Operati ng = EBIDA + Interest On Reserve Fundi ng - Debt Interest Payments</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Cash Flows From Investi ng_(n=0) = Total Install Costs ** -1</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Cash Flows From Investi ng_n = Cash Flows From Investi ng - Reserve Fundi ng</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Issuance of Equity = Total Install Costs - Debt Size</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Cash Flows From Fi nanci ng_(n=0) = Debt Size + Issuance of Equity</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Cash Flows From Fi nanci ng_n = Cash Flows From Fi nanci ng - Debt Pri ncipl e Payment</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Pre Tax Project Cash Flow_(n=0) = Issuance of Equity * -1</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Pre Tax Project Operati ng Cash = Cash Flows From Operati ng + Cash Flows From Investi ng + Cash Flows From Fi nanci ng</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Pre Tax Project Cash Flow = Pre Tax Project Operati ng Cash + Pre Tax Project Cash Flow</MathJax.Node>
                      </div>
                    </div>

                    <div id="3310" className="section-block"> <h3 className="block-title">3.3.10 Depreciation</h3>
                      <p>Dep r e ciation schedules and application are based on (IRS Publication 946).</p>
                      <div className='formula'>
                        <MathJax.Node>Dep r e ciation Basis = Dep r e ciation Percent ** Total Install Cost</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Dep r e ciation Amount = Dep r e ciation Schedule ** Dep r e ciation Basis</MathJax.Node>
                      </div>
                    </div>

                    <div id="3311" className="section-block"> <h3 className="block-title">3.3.11 Investment Tax Credit</h3>
                      <p>If applying the investment tax credit (ITC), a special depreciation calculation is required. ITC is only eligible against the value of the 5-year MACRS basis. The depreciation basis of 5-year MACRS is reduced and the ITC credit is added to the project cashflow in year 1. </p>
                      <div className='formula'>
                        <MathJax.Node>ITC Eligibl e % = Dep r e ciation Percent_(5yr MACRS)</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>ITC Amount = Total Install Costs ** ITC Eligibl e % ** ITC %</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Dep r e ciation Basis_(5yr MACRS) = (1 - ITC% ** 0.5) ** Total Install Cost * Dep r e ciation Percent_(5yr MACRS)</MathJax.Node>
                      </div>
                    </div>

                    <div id="3312" className="section-block"> <h3 className="block-title">3.3.12 Taxes</h3>
                      <p>The use of state tax is uncommon but available if needed. For most projects the total tax liability is carried in the federal tax rate.</p>
                      <div className='formula'>
                        <MathJax.Node>State Taxabl e Income = EBIDA + Interest On Reserve Fundi ng - Debt Interest Payment - Dep r e ciation</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>State Tax Liability = State Taxabl e Income * State Tax Rate * -1</MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Federal Taxabl e Income = EBIDA + Interest on Reserve Fundi ng - Debt Interest Payment - Dep r e ciation + State Tax Liability </MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Federal Tax Liability = Federal Taxabl e Income * Federal Tax Rate * -1 </MathJax.Node>
                      </div>
                      <div className='formula'>
                        <MathJax.Node>Project Cash Flow After Tax = Project Cash Flow Pre Tax + State Tax Liability + Federal Tax Liability</MathJax.Node>
                      </div>
                      <p>LCOE, IRR, and NPV values are based on the Project Cash Flow After Tax, costs, and financing rates described above. Upon completion of a project model, SIFT allows you to download a full financial table.</p>
                    </div>

                    <section id="41" className="doc-section"> <h2 className="section-title">4.1 References</h2>
                      <p>
                        <br />Appelbaum, J.; Bany, J. (1979) “Shadow effect of adjacent solar collectors in large scale systems." Solar Energy 23; pp. 497-507.
                        <br />Duffie, J.; Beckman, W. (2013) “Solar Engineering of Thermal Processes.”
                        <br />First Solar, Optical Losses, Near Diffuse Shading Factor. <a href="https://plantpredict.com/algorithm/optical-losses/#near-diffuse-shading-factor"> https://plantpredict.com/algorithm/optical-losses/#near-diffuse-shading-factor </a>
                        <br />Gilman, P. (2015) “SAM Photovoltaic Model Technical Reference.” National Renewable Energy Laboratory. <a href="https://sam.nrel.gov/performance">https://sam.nrel.gov/performance</a>
                        <br />IRS Publication 946. “How To Depreciate Property.” <a href="https://www.irs.gov/pub/irs-pdf/p946.pdf">https://www.irs.gov/pub/irs-pdf/p946.pdf</a>
                        <br />Lee, M.; Panchula, A. (2016) “Spectral Correction for Photovoltaic Module Performance Based on Air Mass and Precipitable Water.” IEEE Photovoltaic Specialists Conference 2016
                        <br />Marion, B.; MacAlpine, S.; Deline, C.; Asgharzadeh, A.; Toor, F.; Riley, D.; Stein, J.; Hansen, C. (2017) “A Practical Irradiance Model for Bifacial PV Modules” IEEE Photovoltaic Specialists Conference 2017
                        <br />Mermoud, A.; Lejeune, T. (2010) “Performance Assessment of a simulation model for PV modules of any available technology.”
                        <br />NREL System Advisor Model. <a href="https://github.com/NREL/SAM">https://github.com/NREL/SAM</a>
                        <br />Perez, R.; Ineichen, P.; Seals, R.; Michalsky, J.; Stewart, R. (1990) “Modeling Daylight Availability and Irradiance Component from Direct and Global Irradiance.” Solar Energy 44, no 5, pp. 271-289.
                        <br />PVLib. <a href="https://github.com/pvlib/pvlib-python">https://github.com/pvlib/pvlib-python</a>
                        <br />PVSyst, Array ohmic wiring loss. <a href="http://files.pvsyst.com/help/ohmic_loss.htm">http://files.pvsyst.com/help/ohmic_loss.htm</a>
                        <br />PVSyst, Module Temp Coefficients. <a href="http://files.pvsyst.com/help/pvmodule_tempcoefficients.htm">http://files.pvsyst.com/help/pvmodule_tempcoefficients.htm</a>
                        <br />PVSyst, Temperature Coefficients. <a href=" http://files.pvsyst.com/help/pvmodule_tempcoefficients.htm">http://files.pvsyst.com/help/pvmodule_tempcoefficients.htm</a>
                        <br />PVSyst, Treatment of the Albedo Component. <a href="http://files.pvsyst.com/help/shadings_albedo.htm">http://files.pvsyst.com/help/shadings_albedo.htm</a>
                        <br />PVSyst, Thermal Loss. <a href="http://files.pvsyst.com/help/thermal_loss.htm">http://files.pvsyst.com/help/thermal_loss.htm</a>
                        <br />Sandia, POA Beam. PVPerformance Modeling Collaborative. <a href="https://pvpmc.sandia.gov/modeling-steps/1-weather-design-inputs/plane-of-array-poa-irradiance/calculating-poa-irradiance/poa-beam/">https://pvpmc.sandia.gov/modeling-steps/1-weather-design-inputs/plane-of-array-poa-irradiance/calculating-poa-irradiance/poa-beam/</a>
                        <br />Short, W., Packey, D., Holt, T. (1995) "A Manual for the Economic Evaluation of Energy Efficiency and Renewable Energy Technologies." <a href="http://www.nrel.gov/docs/legosti/old/5173.pdf">http://www.nrel.gov/docs/legosti/old/5173.pdf</a>
                      </p>
                    </section>

                  </div>
                </div>
              </MathJax.Context>
              }
            </div>
            </Spin>  

        </Layout>  
        {/* </Layout> */}
      </div >
    </Modal>
  )
}


export { Docs }
