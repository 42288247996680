import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { siftActions, isAuthenticated } from "../../Redux";

//tutorial stuff
import { TutorialTip } from "../../TutorialTip";

import { Radio, Input, Button, Empty, Divider, Popconfirm, Spin, Switch, Checkbox, Upload } from "antd";
const RadioGroup = Radio.Group;
const Dragger = Upload.Dragger;

import { CheckCircleOutlined, InboxOutlined, LoadingOutlined } from "@ant-design/icons";

import ReactDataSheet from "react-datasheet";
import { SheetRenderer } from "../SheetRenderer";
import { InputPopout } from "../InputPopout";

const Months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
const WeatherOptions = [
  { label: "NSRDB", value: 0 },
  { label: "NASA POWER", value: 1 },
];
const weatherSources = [
  { label: "NSRDB", value: 0 },
  { label: "NASA POWER", value: 1 },
];
const columns = [
  { label: "", width: "8%" },
  { label: "", width: "20%" },
  { label: "", width: "20%" },
  { label: "", width: "15%" },
  { label: "", width: "12%" },
];
const renderSheet = (props) => {
  return <SheetRenderer columns={columns} {...props} />;
};

const WeatherInputs = (props) => {
  const inputs = useSelector((state) => state.sift.ioManager.inputs.weather.data);
  const features = useSelector((state) => state.sift.ioManager.inputs.map.features);
  const isAuthed = useSelector((state) => isAuthenticated(state));
  const account_loading = useSelector((state) => state.account.account_loading);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const weather_loading = useSelector((state) => state.sift.ioManager.uiState.weather_loading);
  const weather_id = useSelector((state) => state.sift.ioManager.inputs.weather.id);

  const saving = account_loading || input_loading;
  const [weatherDataVisible, setWeatherDataVisible] = useState(false);
  const [popoutY, setPopoutY] = useState(0);

  //Tutorial Controls
  const tutorial = useSelector((state) => state.tutorial);

  const dispatch = useDispatch();

  useEffect(() => {
    if (inputs.weather_summary !== undefined) {
      let lat = parseFloat(inputs.lat);
      let lng = parseFloat(inputs.lng);
      let search_lat = parseFloat(inputs.search_lat);
      let search_lng = parseFloat(inputs.search_lng);

      // we should only fix the weather inputs IF there is no boundaries available
      if (Object.keys(features).length > 0) {
        return;
      }

      // console.log('weather summary updated', features)

      if (lat.toFixed(0) != search_lat.toFixed(0) || lng.toFixed(0) != search_lng.toFixed(0)) {
        let lat_valid = !isNaN(lat) && lat <= 90 && lat >= -90 && lat != 0;
        let lng_valid = !isNaN(lng) && lng <= 180 && lng >= -180 && lng != 0;
        if (lat_valid && lng_valid) {
          // lat/lng aren't close to weather file AND valid, lets correct this
          dispatch(
            siftActions.fixWeatherInputs({
              lat: inputs.lat,
              lng: inputs.lng,
              tz: inputs.tz,
              ele: inputs.ele,
            })
          );
        }
      }
    }
  }, [inputs.weather_summary]);

  // const updateInput = debounce((key, value) => {
  //   console.log(key, value);
  //   dispatch(siftActions.updateInput(key, value))

  // }, 200);
  function updateInput(key, value) {
    dispatch(siftActions.updateInput(key, value));
  }
  function toggleWeatherPopup(e) {
    let cy = e.clientY;
    let pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    setPopoutY(Math.min(pageHeight - 444, pageHeight * 0.35));
    setWeatherDataVisible(!weatherDataVisible);
  }

  let weather_dt = [];

  function handleCreateWeatherSummary() {
    if (inputs.weather_summary == undefined) return;

    let data = [
      [
        { value: "", readOnly: true },
        { value: "GHI (kWh/m²/mo)", readOnly: true },
        { value: "DHI (kWh/m²/mo)", readOnly: true },
        { value: "Temp (°C)", readOnly: true },
        { value: "Wind Velocity (m/s)", readOnly: true },
      ],
    ];

    let t_HorizGlobIrrad = 0;
    let t_HorizDiffIrrad = 0;
    let t_Temp = 0;
    let t_Wind = 0;

    for (var w in inputs.weather_summary.Summary[0]) {
      let row = inputs.weather_summary.Summary[0][w];
      t_HorizGlobIrrad += row["HorizGlobIrrad"];
      t_HorizDiffIrrad += row["HorizDiffIrrad"];
      t_Temp += row["Temp"];
      t_Wind += row["Wind"];

      data.push([
        { value: Months[row["month"] - 1], readOnly: true },
        { value: row["HorizGlobIrrad"].toFixed(1), readOnly: true },
        { value: row["HorizDiffIrrad"].toFixed(1), readOnly: true },
        { value: row["Temp"].toFixed(1), readOnly: true },
        { value: row["Wind"].toFixed(2), readOnly: true },
      ]);
    }

    data.splice(1, 0, [
      { value: "Year", readOnly: true },
      { value: t_HorizGlobIrrad.toFixed(1), readOnly: true },
      { value: t_HorizDiffIrrad.toFixed(1), readOnly: true },
      { value: (t_Temp / 12).toFixed(1), readOnly: true },
      { value: (t_Wind / 12).toFixed(1), readOnly: true },
    ]);

    weather_dt = data;
  }

  // try to create the weather summary datatable
  handleCreateWeatherSummary();

  function onPullLocFromMapClick() {
    dispatch(siftActions.pullMapLoc());
  }
  function onRequestWeather() {
    dispatch(
      siftActions.requestWeather({
        lat: parseFloat(inputs.search_lat),
        lng: parseFloat(inputs.search_lng),
        source: inputs.source,
      })
    );
  }
  function onDownloadWeather() {
    dispatch(siftActions.downloadWeather(inputs.weather_id));
  }
  function onClearWeatherData() {
    dispatch(siftActions.clearWeather());
  }

  return (
    <div className="input-content-box">
      <InputPopout visible={weatherDataVisible && weather_id != 0} onClose={toggleWeatherPopup} popoutY={popoutY}>
        <div className="weather-popup">
          {/* SUMMARY OF WEATHER FILE */}
          <div className="weather-overview">
            <div>
              <span>
                Latitude & Longitude: ({inputs.lat.toFixed(3)},{inputs.lng.toFixed(3)})
              </span>
            </div>
            <div>
              <span>Elevation: {inputs.ele.toFixed(0)}</span>
            </div>
            <div>
              <span>Timezone: UTC{inputs.tz}</span>
            </div>
          </div>
          <ReactDataSheet
            data={weather_dt}
            sheetRenderer={renderSheet}
            valueRenderer={(cell) => cell.value}
            onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
            className="weather-grid"
            // onCellsChanged={this.handleChanges}
          />
        </div>
      </InputPopout>

      <Spin spinning={saving} className="sift-loader">
        <div>
          <Divider className="weather-divider" orientation="left">
            Location
          </Divider>
          <div className="input-row">
            <label>Latitude</label>
            <Input
              id="search_lat"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={inputs.search_lat}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>°</span>}
            />
          </div>
          <div className="input-row">
            <label>Longitude</label>
            <Input
              id="search_lng"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={inputs.search_lng}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>°</span>}
            />
          </div>

          <div className="input-row">
            <label>Elevation</label>
            <Input
              id="search_ele"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={inputs.search_ele}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
            />
          </div>
          <div className="input-row">
            <label>Timezone</label>
            <Input
              id="search_tz"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={inputs.search_tz}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>UTC</span>}
            />
          </div>

          <div className="input-row">
            <span>{saving ? <span>Recenter pin</span> : <a onClick={() => onPullLocFromMapClick()}>Recenter pin</a>}</span>
            <span style={{ marginLeft: "auto", marginRight: "3px" }}>
              {inputs.county}
              {inputs.state}
            </span>
          </div>
        </div>

        <Divider className="weather-divider" orientation="left">
          Data
        </Divider>

        {/* 8760 table resized with fixed headers */}
        {/* totals for each column? */}
        {inputs.weather_id != 0 && inputs.weather_summary ? (
          <a onClick={toggleWeatherPopup}>View Weather Summary</a>
        ) : (
          <div style={{ padding: "1%", width: "50%" }}>{/* <Empty description='Import Weather from source or local file'/> */}</div>
        )}

        {inputs.weather_id == 0 && (
          <div className="input-row one center">
            <RadioGroup
              options={WeatherOptions}
              name="source"
              onChange={(event) => {
                updateInput(event.target.name, event.target.value);
              }}
              value={inputs.source}
              disabled={inputs.weather_summary != undefined || saving}
            />
          </div>
        )}
        {inputs.weather_id == 0 && (
          <div className="input-row one center">
            {!isAuthed ? (
              <span>You must login to request weather.</span>
            ) : (inputs.search_lat == 0 && inputs.search_lng == 0) || saving ? (
              <span>Manual Request</span>
            ) : weather_loading ? (
              <span>
                <LoadingOutlined /> Loading...
              </span>
            ) : inputs.weather_id == 0 ? (
              <span>
                <a onClick={() => onRequestWeather()}> Manual Request</a>
              </span>
            ) : (
              <span>Weather data loaded.</span>
            )}
          </div>
        )}

        {inputs.weather_id != 0 && (
          <div>
            <div>
              <span>
                <a onClick={() => onDownloadWeather()}>Download Data</a>
              </span>
            </div>
            <div>
              <span>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => {
                    onClearWeatherData();
                  }}
                >
                  <a>Clear Data</a>
                </Popconfirm>
              </span>
            </div>
          </div>
        )}
      </Spin>
    </div>
  );
};

export { WeatherInputs };
