import React, { useState, useEffect } from 'react';
import butter from './butter-client';

import { useSelector, useDispatch } from 'react-redux';
import { siftActions } from '../Redux';

import { Note } from './Note';

import { Divider, Modal } from 'antd';

const PatchNotes = ({ mode }) => {
  const dispatch = useDispatch();
  const patchVisible = useSelector((state) => state.sift.ioManager.uiState.patch_visible);
  const newsVisible = useSelector((state) => state.sift.ioManager.uiState.news_visible);

  const [butterData, setButterData] = useState(undefined);
  const [news, setNews] = useState(undefined);
  const [patch, setPatch] = useState(undefined);

  useEffect(() => {
    async function fetchData() {
      const resp = await butter.post.list({ page: 1, page_size: 10 });
      let news = [];
      let patch = [];
      Object.values(resp.data.data).map((blurb) => {
        if (blurb.categories[0]?.slug == 'bug-fix' || blurb.categories[1]?.slug == 'patch' || blurb.categories[0]?.slug == 'patch') {
          patch.push(blurb);
        } else {
          news.push(blurb);
        }
      });
      setButterData(resp.data);
      setNews(news);
      setPatch(patch);
    }
    fetchData();
  }, []);

  // useEffect(() => {
  //   console.log('news', news);
  //   console.log('patch', patch);
  // }, [news, patch]);

  function hidePatchNotes() {
    if (mode == 'news') {
      dispatch(siftActions.toggleHelpToolbarVisibility('news_visible', false));
    } else {
      dispatch(siftActions.toggleHelpToolbarVisibility('patch_visible', false));
    }
  }

  return (
    <Modal
      title={mode == 'news' ? 'News' : 'Patch Notes'}
      visible={patchVisible || newsVisible}
      onCancel={hidePatchNotes}
      footer={null}
      width="70%"
      centered
      bodyStyle={{ height: '700px', overflowY: 'auto', paddingTop: '0px' }}
      className="sift-modal"
    >
      {butterData && mode !== 'news'
        ? patch &&
          patch.map((post, key) => {
            return (
              <div key={key}>
                <Note post={post}></Note>
                <Divider />
              </div>
            );
          })
        : news &&
          news.map((post, key) => {
            return (
              <div key={key}>
                <Note post={post}></Note>
                <Divider />
              </div>
            );
          })}
    </Modal>
  );
};

export { PatchNotes };
