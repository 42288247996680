import React, { useState, useEffect, useRef } from 'react';

import { ZoomControl } from '../ZoomControl';

import { Map, LayerGroup, GeoJSON, Polygon, Marker } from 'react-leaflet';

//styles
import { LiveReportMapWrapper } from './styles';

// redux
import { useSelector, useDispatch } from 'react-redux';

// helpers
import { siftActions, getBounds } from '../../../Redux';

//map tiles
import BingLayer from '../../../Map/Bing';

const bing_key = 'Apg1Wo2sThhv7onPyHncSIy7J3Nn98FFIzAhWYNFDrNLDpCRkZaSic1uuBHRoppM';

const TileSets = {
  Satellite: 'Aerial',
  'Satellite With Labels': 'AerialWithLabels',
  'Satellite With OnDemand Labels': 'AerialWithLabelsOnDemand',
  'Street Map': 'Road',
  // "Street Map Dark": "CanvasDark",
  // "CanvasLight": "CanvasLight",
  // "CanvasGray": "CanvasGray"
};
const basePolyStates = {
  Boundary: true,
  Exclusions: true,
  Inactive: true,
  Layout: true,
};

const zoomGranularity = 0.25;

const LiveReportMap = ({ mapName, showToggles, addMapRefs }) => {
  const dispatch = useDispatch();

  const features = useSelector((state) => state.sift.ioManager.inputs.map.features);
  const mapCenter = useSelector((state) => state.sift.ioManager.inputs.map.map_center);
  const racks = useSelector((state) => state.sift.ioManager.outputs.layout.racks);
  const roads = useSelector((state) => state.sift.ioManager.outputs.layout.roads);
  const inverters = useSelector((state) => state.sift.ioManager.outputs.layout.inverters);

  const [activeTileSet, setActivetileSet] = useState(mapName == 'vicinityMap' ? 'Street Map' : 'Satellite');
  const [showControls, setShowControls] = useState(false);
  const [polygonStates, setPolygonStates] = useState(JSON.parse(JSON.stringify(basePolyStates)));
  const [zoom, setZoom] = useState(16);

  const [showBoundary, setShowBoundary] = useState(true);
  const [showExclusion, setShowExclusion] = useState(true);
  const [showInactive, setShowInactive] = useState(true);
  const [position, setPosition] = useState(mapCenter);

  const liveReportMap = useRef();
  const markerRef = useRef();

  const [bingLayerVisible, setBingLayerVisible] = useState(true);

  useEffect(() => {
    setBingLayerVisible(true);
  }, [activeTileSet]);

  useEffect(() => {
    addMapRefs(`${mapName}`, { height: liveReportMap.current.leafletElement._size.y, width: liveReportMap.current.leafletElement._size.x, className: mapName });
    setTimeout(() => {
      handleLiveMapZoomExtents();
    }, 500);
  }, []);

  const handleMarkerDrag = () => {
    if (markerRef != null) {
      let pos = markerRef.current.leafletElement.getLatLng();
      setPosition(pos);
      let newPos = [pos.lat, pos.lng];
      dispatch(siftActions.updateReportData('location', newPos));
    }
  };

  const handleLiveMapZoomExtents = (zoomLevel = undefined) => {
    if (Object.values(features).length == 0) return;

    let bounds = getBounds(Object.values(features));

    if (zoomLevel) {
      liveReportMap.current.leafletElement.fitBounds(
        [
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ],
        { maxZoom: zoomLevel }
      );
    } else {
      liveReportMap.current.leafletElement.fitBounds([
        [bounds[1], bounds[0]],
        [bounds[3], bounds[2]],
      ]);
    }
  };

  return (
    <LiveReportMapWrapper onMouseEnter={() => setShowControls(true)} onMouseLeave={() => setShowControls(false)}>
      {showToggles ? (
        <section className="map-toggles">
          <h3>Layout Map</h3>
          <section className="map-toggle-buttons">
            <div className="toggle">
              <p>Show Boundaries</p>
              <label className="switch">
                <input type="checkbox" checked={showBoundary} onChange={(e) => setShowBoundary(!showBoundary)} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="toggle">
              <p>Show Exclusions</p>
              <label className="switch">
                <input type="checkbox" checked={showExclusion} onChange={(e) => setShowExclusion(!showExclusion)} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="toggle">
              <p>Show Inactive</p>
              <label className="switch">
                <input type="checkbox" checked={showInactive} onChange={(e) => setShowInactive(!showInactive)} />
                <span className="slider round"></span>
              </label>
            </div>
          </section>
        </section>
      ) : (
        <section className="map-title">
          <h3>Vicinity Map</h3>
        </section>
      )}

      <section className="map-area">
        <Map
          ref={liveReportMap}
          id={mapName}
          className={mapName}
          animate={true}
          noWrap={true}
          attributionControl={false}
          center={mapCenter}
          zoom={zoom}
          minZoom={0}
          maxZoom={25}
          zoomControl={false}
          zoomSnap={zoomGranularity}
          zoomDelta={zoomGranularity}
          style={{ width: '100%', height: '100%' }}
        >
          {bingLayerVisible && <BingLayer bingkey={bing_key} type={TileSets[activeTileSet]} maxZoom={25} maxNativeZoom={18} />}

          {showControls && liveReportMap.current.leafletElement && <ZoomControl mapRef={liveReportMap.current.leafletElement} mapName={mapName} position={position} />}

          {mapName == 'vicinityMap' ? (
            <Marker ref={markerRef} draggable={true} ondragend={handleMarkerDrag} position={position} />
          ) : (
            <>
              <LayerGroup>
                {features &&
                  Object.values(features).map((poly, index) => {
                    if (poly.properties.identity == 0 && !showInactive) return;
                    if (poly.properties.identity == 1 && !showBoundary) return;
                    if (poly.properties.identity == 2 && !showExclusion) return;
                    return (
                      <GeoJSON
                        data={poly}
                        key={poly.properties.index}
                        style={{
                          fillColor: '#ffffff',
                          fillOpacity: 0.01,
                          weight: 1,
                          color:
                            poly.properties.identity == 0 && showInactive
                              ? 'white'
                              : poly.properties.identity == 1 && showBoundary
                              ? 'red'
                              : poly.properties.identity == 2 && showExclusion
                              ? 'yellow'
                              : 'white',
                        }}
                      ></GeoJSON>
                    );
                  })}
              </LayerGroup>

              {/* Racks */}
              {racks && (
                <LayerGroup>
                  <Polygon positions={racks.geometry.coordinates} key={599} id={-999} color="#33A2FF" fillColor="none" weight={1}></Polygon>
                </LayerGroup>
              )}

              {/* Roads */}
              {roads && (
                <LayerGroup>
                  <Polygon positions={roads.geometry.coordinates} key={598} id={-998} color="#e0710f" fillColor="none" weight={1} />
                </LayerGroup>
              )}

              {/* Inverters */}
              {inverters && (
                <LayerGroup>
                  <Polygon positions={inverters.geometry.coordinates} key={597} id={-997} color="#FF64FF" fillColor="none" weight={1} />
                </LayerGroup>
              )}
            </>
          )}
        </Map>
      </section>
    </LiveReportMapWrapper>
  );
};

export { LiveReportMap };
