import { accountConstants } from '../_constants';
import { userService, siftService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const accountActions = {
  getAll,
  getProfile,
  updateUserData,
  updatePlanTo,
  deletePayment,
  setDefaultPayment,
  addPayment,
  validateInputs,
  runCapacity,
  getProjects,
  loadProject,
  saveProject,
  runWeather,
  getWeatherSummary,
  saveModule,
  saveInverter,
  saveRacking,
  savePerformance,
  saveFinance,
  saveWeather,
  cancelSIFT,
  resendVerifyEmail,
  importData,
  clearUploadErrors,
  getResultFiles,
  clearResultURL,
  clearDXFURL,
  getTopo,
  checkTopo,
  clearAutolayout,

  getCapacityResults,
  getRackRoadsResults,
  delete: _delete,

  // toggleLoginVis,
  toggleFunnelVis,

  downloadInvoice,
};

function getAll() {
  return (dispatch) => {
    // dispatch(alertActions.loading('Updating'));
    dispatch(request());

    userService.getAll().then(
      (userData) => {
        if (userData.url) {
          siftService.downloadFromS3(userData.url).then(
            (details) => {
              dispatch(success(details));
              if (history.location.pathname == '/login') history.push('/account');
            },
            (error) => dispatch(failure(error.toString()))
          );
        } else {
          dispatch(success(userData));
          if (history.location.pathname == '/login') history.push('/account');
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: accountConstants.GETALL_REQUEST };
  }
  function success(details) {
    return { type: accountConstants.GETALL_SUCCESS, details };
  }
  function complete(details) {
    return { type: accountConstants.GETALL_COMPLETE };
  }
  function failure(error) {
    return { type: accountConstants.GETALL_FAILURE, error };
  }
}

function getProfile() {
  return (dispatch) => {
    dispatch(request());

    userService.getProfile().then(
      (profile) => dispatch(success(profile)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: accountConstants.GETPROFILE_REQUEST };
  }
  function success(profile) {
    return { type: accountConstants.GETPROFILE_SUCCESS, profile };
  }
  function failure(error) {
    return { type: accountConstants.GETPROFILE_FAILURE, error };
  }
}

function updateUserData(data) {
  return (dispatch) => {
    userService.updateUserData(data).then(dispatch(accountActions.getAll()));
  };
}

// Called AFTER we've got a token from STRIPE endpoint
function addPayment(token) {
  return (dispatch) => {
    dispatch(request({ ...token }));

    userService.addPayment({ ...token }).then(
      (info) => {
        // console.log(info)
        if (info.response.error) {
          dispatch(failure(info));
          dispatch(alertActions.error(info.response.error.toString()));
        } else {
          dispatch(success(info));
          // dispatch(alertActions.success('Payment method added'));
          // get updated payment info from backend
          dispatch(accountActions.getAll());
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(token) {
    return { type: accountConstants.ADDPAYMENT_REQUEST, token };
  }
  function success(info) {
    return { type: accountConstants.ADDPAYMENT_SUCCESS, info };
  }
  function failure(error) {
    return { type: accountConstants.ADDPAYMENT_FAILURE, error };
  }
}

function toggleFunnelVis(value) {
  return (dispatch) => {
    dispatch({
      type: accountConstants.FUNNEL_VISIBILITY_TOGGLE,
      value,
    });
  };
}

// called after confirmation of updating plan
function updatePlanTo(sub) {
  return (dispatch) => {
    dispatch(request(sub));

    userService.updatePlanTo(sub).then(
      (info) => {
        dispatch(alertActions.success('Subscription updated'));
        dispatch(accountActions.getAll());
        dispatch(success(info));

        if (history.location.pathname == '/') {
          window.scrollTo(0, 0);
          history.push('/sift');
          history.push('#app');
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(sub) {
    return { type: accountConstants.UPDATESUB_REQUEST, sub };
  }
  function success(info) {
    return { type: accountConstants.UPDATESUB_SUCCESS, info };
  }
  function failure(error) {
    return { type: accountConstants.UPDATESUB_FAILURE, error };
  }
}
function deletePayment(id) {
  return (dispatch) => {
    userService.deletePayment(id).then(
      (info) => {
        dispatch(alertActions.success('Payment Method Removed'));
        dispatch(accountActions.getAll());
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(info) {
    return { type: accountConstants.UPDATESUB_SUCCESS, info };
  }
  function failure(error) {
    return { type: accountConstants.UPDATESUB_FAILURE, error };
  }
}
function setDefaultPayment(id) {
  return (dispatch) => {
    userService.setDefaultPayment(id).then(
      (info) => {
        dispatch(alertActions.success('Default Payment Method Set'));
        dispatch(accountActions.getAll());
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(info) {
    return { type: accountConstants.UPDATESUB_SUCCESS, info };
  }
  function failure(error) {
    return { type: accountConstants.UPDATESUB_FAILURE, error };
  }
}

/*
    DASH FUNCTIONS
*/
function validateInputs(inputs) {
  return (dispatch) => {
    dispatch(request(inputs));
    // dispatch(alertActions.loading('Validating Inputs'));

    userService.validateInputs(inputs).then(
      (response) => {
        // console.log(run_id);
        dispatch(success(response));
        // dispatch(alertActions.success('Validated Inputs'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(inputs) {
    return { type: accountConstants.DASH_VALIDATEINPUTS_REQUEST, inputs };
  }
  function success(response) {
    return { type: accountConstants.DASH_VALIDATEINPUTS_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.DASH_VALIDATEINPUTS_FAILURE, error };
  }
}

function runCapacity(inputs, run_id) {
  return (dispatch) => {
    dispatch(request(inputs));
    // dispatch(alertActions.loading('Creating Layouts'));

    userService.runCapacity(inputs, run_id).then(
      (response) => {
        // console.log(run_id);
        dispatch(success(response));
        // dispatch(alertActions.loading('Modeling Results'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(inputs) {
    return { type: accountConstants.CAPACITY_TOOL_REQUEST, inputs };
  }
  function success(response) {
    return { type: accountConstants.CAPACITY_TOOL_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.CAPACITY_TOOL_FAILURE, error };
  }
}

function getCapacityResults(id, delay) {
  return (dispatch) => {
    dispatch(request(id));

    userService.getResults(id).then(
      (response) => {
        if (response.code == 99) {
          dispatch(update(response.count));
          // console.log('results not done yet delaying then re-fetching');
          if (delay > 60000) {
            dispatch(failure('Server timed-out'));
            dispatch(alertActions.error('Server timed-out'));
          } else {
            setTimeout(() => {
              dispatch(accountActions.getCapacityResults(id, delay + 50));
            }, delay);
          }
        } else {
          dispatch(success(response));
          // dispatch(alertActions.success('Retrieving SIFT results'));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id) {
    return { type: accountConstants.CAPACITY_RESULTS_REQUEST, id };
  }
  function update(count) {
    return { type: accountConstants.CAPACITY_RESULTS_UPDATE, count };
  }
  function success(response) {
    return { type: accountConstants.CAPACITY_RESULTS_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.CAPACITY_RESULTS_FAILURE, error };
  }
}

function getRackRoadsResults(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.getRacksRoads(id).then(
      (response) => {
        // dispatch(success(response));
        // dispatch(alertActions.success('Automatic Layout Drawn'));
        userService.downloadFromS3(response).then((_response) => dispatch(success(_response)));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id) {
    return { type: accountConstants.CAPACITY_RR_REQUEST, id };
  }
  function success(response) {
    return { type: accountConstants.CAPACITY_RR_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.CAPACITY_RR_FAILURE, error };
  }
}

function getResultFiles(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.getResultFiles(id).then(
      (response) => {
        if (response == 'Error') {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        } else {
          dispatch(success(response));
        }
        // console.log(response)
        // userService.downloadFromS3toPC(response).then(response => dispatch(success(response)))
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id) {
    return { type: accountConstants.SIFT_FILE_REQUEST, id };
  }
  function success(response) {
    return { type: accountConstants.SIFT_FILE_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.SIFT_FILE_FAILURE, error };
  }
}

function getProjects() {
  return (dispatch) => {
    dispatch(request());

    userService.getProjects().then(
      (response) => {
        dispatch(success(response));
        // dispatch(alertActions.success('Retrieving all projects'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: accountConstants.DASH_LOADPROJECTS_REQUEST };
  }
  function success(response) {
    return { type: accountConstants.DASH_LOADPROJECTS_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.DASH_LOADPROJECTS_FAILURE, error };
  }
}

function getTopo(bbox) {
  return (dispatch) => {
    dispatch(request());

    userService.getTopo(bbox).then(
      (response) => {
        dispatch(success(response));
        // dispatch(alertActions.success('Retrieving all projects'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: accountConstants.DASH_GETTOPO_REQUEST };
  }
  function success(response) {
    return { type: accountConstants.DASH_GETTOPO_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.DASH_GETTOPO_FAILURE, error };
  }
}
function checkTopo(id) {
  return (dispatch) => {
    dispatch(request());

    userService.checkTopo(id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: accountConstants.DASH_CHECKTOPO_REQUEST };
  }
  function success(response) {
    return { type: accountConstants.DASH_CHECKTOPO_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.DASH_CHECKTOPO_FAILURE, error };
  }
}

function loadProject(project, option) {
  return (dispatch) => {
    dispatch(success(project, option));
  };
  function success(project, option) {
    return { type: accountConstants.DASH_LOADPROJECT, project, option };
  }
}

function clearResultURL() {
  return (dispatch) => {
    dispatch(success());
  };
  function success() {
    return { type: accountConstants.SIFT_FILE_CLEAR };
  }
}
function clearDXFURL() {
  return (dispatch) => {
    dispatch(success());
  };
  function success() {
    return { type: accountConstants.DASH_CREATEDXF_CLEAR };
  }
}
function clearAutolayout() {
  return (dispatch) => {
    dispatch(success());
  };
  function success() {
    return { type: accountConstants.DASH_CLEARAUTOLAYOUT_CLEAR };
  }
}

function cancelSIFT() {
  return (dispatch) => {
    dispatch(success());
  };
  function success() {
    return { type: accountConstants.DASH_CANCELSIFT };
  }
}

function clearUploadErrors() {
  return (dispatch) => {
    dispatch(success());
  };
  function success() {
    return { type: accountConstants.DASH_CLEAR_UPLOAD_ERRORS };
  }
}

function resendVerifyEmail() {
  return (dispatch) => {
    userService.resendVerifyEmail().then(
      (response) => {
        dispatch(alertActions.success('Email resent'));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function saveProject(project, message) {
  return (dispatch) => {
    // dispatch(alertActions.loading('Saving Project'));
    dispatch(request(project));
    userService.saveProject(project).then(
      (response) => {
        dispatch(success(response));

        userService.getProjects().then(
          (response) => {
            dispatch(alertActions.success(message));
            dispatch(successGet(response));
            dispatch(successLoad(project, 2));
          },
          (error) => dispatch(failure(error.toString()))
        );
      },
      (error) => {
        // console.log(error)
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: accountConstants.DASH_SAVEPROJECT_REQUEST, project };
  }
  function success(response) {
    return { type: accountConstants.DASH_SAVEPROJECT_SUCCESS, response };
  }
  function successGet(response) {
    return { type: accountConstants.DASH_LOADPROJECTS_SUCCESS, response };
  }
  function successLoad(project, option) {
    return { type: accountConstants.DASH_LOADPROJECT, project, option };
  }
  function failure(error) {
    return { type: accountConstants.DASH_SAVEPROJECT_FAILURE, error };
  }
}

function saveModule(mod, message) {
  return (dispatch) => {
    // dispatch(alertActions.loading('Saving Module'));
    dispatch(request(mod));
    // console.log(mod)
    userService.saveModule(mod).then(
      (response) => {
        dispatch(success(response));
        // TODO: impl below
        // dispatch(accountActions.getModules());
        dispatch(accountActions.getAll());
        dispatch(alertActions.success(message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(mod) {
    return { type: accountConstants.DASH_SAVEMODULE_REQUEST, mod };
  }
  function success(response) {
    return { type: accountConstants.DASH_SAVEMODULE_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.DASH_SAVEMODULE_FAILURE, error };
  }
}

function saveInverter(inv, message) {
  return (dispatch) => {
    // dispatch(alertActions.loading('Saving Inverter'));
    dispatch(request(inv));
    // console.log(inv)
    userService.saveInverter(inv).then(
      (response) => {
        dispatch(success(response));
        // TODO: impl below
        // dispatch(accountActions.getInverters());
        dispatch(accountActions.getAll());
        dispatch(alertActions.success(message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(inv) {
    return { type: accountConstants.DASH_SAVEINVERTER_REQUEST, inv };
  }
  function success(response) {
    return { type: accountConstants.DASH_SAVEINVERTER_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.DASH_SAVEINVERTER_FAILURE, error };
  }
}

function saveRacking(rack, message) {
  return (dispatch) => {
    // dispatch(alertActions.loading('Saving Racking'));
    dispatch(request(rack));
    // console.log(rack)
    userService.saveRacking(rack).then(
      (response) => {
        dispatch(success(response));
        // TODO: impl below
        // dispatch(accountActions.getRacking());
        dispatch(accountActions.getAll());
        dispatch(alertActions.success(message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(rack) {
    return { type: accountConstants.DASH_SAVERACKING_REQUEST, rack };
  }
  function success(response) {
    return { type: accountConstants.DASH_SAVERACKING_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.DASH_SAVERACKING_FAILURE, error };
  }
}
function savePerformance(perf, message) {
  return (dispatch) => {
    // dispatch(alertActions.loading('Saving Performance'));
    dispatch(request(perf));
    // console.log(perf)
    userService.savePerformance(perf).then(
      (response) => {
        dispatch(success(response));
        // TODO: impl below
        // dispatch(accountActions.getPerformance());
        dispatch(accountActions.getAll());
        dispatch(alertActions.success(message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(perf) {
    return { type: accountConstants.DASH_SAVEPERFORMANCE_REQUEST, perf };
  }
  function success(response) {
    return { type: accountConstants.DASH_SAVEPERFORMANCE_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.DASH_SAVEPERFORMANCE_FAILURE, error };
  }
}

function saveFinance(finance, message) {
  return (dispatch) => {
    // dispatch(alertActions.loading('Saving financeormance'));
    dispatch(request(finance));
    // console.log(finance)
    userService.saveFinance(finance).then(
      (response) => {
        dispatch(success(response));
        // TODO: impl below
        // dispatch(accountActions.getFinance());
        dispatch(accountActions.getAll());
        dispatch(alertActions.success(message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(finance) {
    return { type: accountConstants.DASH_SAVEFINANCE_REQUEST, finance };
  }
  function success(response) {
    return { type: accountConstants.DASH_SAVEFINANCE_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.DASH_SAVEFINANCE_FAILURE, error };
  }
}

function saveWeather(weather, message) {
  return (dispatch) => {
    // dispatch(alertActions.loading('Saving Weather'));
    dispatch(request(weather));
    // console.log(weather)
    userService.saveWeather(weather).then(
      (response) => {
        dispatch(success(response));
        // TODO: impl below
        // dispatch(accountActions.getWeather());
        dispatch(accountActions.getAll());
        dispatch(alertActions.success(message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(weather) {
    return { type: accountConstants.DASH_SAVEWEATHER_REQUEST, weather };
  }
  function success(response) {
    return { type: accountConstants.DASH_SAVEWEATHER_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.DASH_SAVEWEATHER_FAILURE, error };
  }
}

function runWeather(inputs) {
  return (dispatch) => {
    // dispatch(alertActions.loading('Pulling Weather Data'));
    dispatch(request(inputs));

    userService.runWeather(inputs).then(
      (response) => {
        // console.log(run_id);
        dispatch(success(response));
        // dispatch(alertActions.loading('Retrieving Weather Data'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(inputs) {
    return { type: accountConstants.DASH_RUNWEATHER_REQUEST, inputs };
  }
  function success(response) {
    return { type: accountConstants.DASH_RUNWEATHER_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.DASH_RUNWEATHER_FAILURE, error };
  }
}
function getWeatherSummary(id, delay) {
  return (dispatch) => {
    dispatch(request(id));

    userService.getWeather(id).then(
      (response) => {
        if (response.code == 99) {
          // console.log('weather not done yet, re-fetch');

          if (delay > 60000) {
            dispatch(failure('Server timed-out'));
            dispatch(alertActions.error('Server timed-out'));
          } else {
            setTimeout(() => {
              // console.log('waiting...')
              dispatch(accountActions.getWeatherSummary(id, delay + 100));
            }, delay);
          }
        } else {
          dispatch(success(response));
          dispatch(alertActions.success('Retrieved Weather Data'));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id) {
    return { type: accountConstants.DASH_GETWEATHER_REQUEST, id };
  }
  function success(response) {
    return { type: accountConstants.DASH_GETWEATHER_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.DASH_GETWEATHER_FAILURE, error };
  }
}

function importData(data) {
  return (dispatch) => {
    dispatch(request(data));

    userService.importData(data).then(
      (response) => {
        userService.getAll().then(
          (details) => {
            dispatch(successGetAll(details));
            dispatch(success(response));
          },
          (error) => dispatch(failure(error.toString()))
        );
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request(data) {
    return { type: accountConstants.IMPORTDATA_REQUEST, data };
  }
  function successGetAll(details) {
    return { type: accountConstants.GETALL_SUCCESS, details };
  }
  function success(response) {
    return { type: accountConstants.IMPORTDATA_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.IMPORTDATA_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.delete(id).then(
      (user) => dispatch(success(id)),
      (error) => dispatch(failure(id, error.toString()))
    );
  };

  function request(id) {
    return { type: accountConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: accountConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: accountConstants.DELETE_FAILURE, id, error };
  }
}

/* Helper function */
function download_file(fileURL, fileName) {
  // for non-IE
  if (!window.ActiveXObject) {
    var save = document.createElement('a');
    save.href = fileURL;
    save.target = '_blank';
    var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
    save.download = fileName || filename;
    if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search('Chrome') < 0) {
      document.location = save.href;
      // window event not working here
    } else {
      var evt = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false,
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    var _window = window.open(fileURL, '_blank');
    _window.document.close();
    _window.document.execCommand('SaveAs', true, fileName || fileURL);
    _window.close();
  }
}

function downloadInvoice(invoice) {
  return (dispatch) => {
    dispatch(request());
    userService.downloadInvoice(invoice.id).then(
      (response) => {
        // server sent the url to the file!
        // now, let's download in same tab:
        // window.open(response.url, 'Download');
        download_file(response.url, `Sunfig-Invoice-${invoice.date_charged}.pdf`);

        // another way to open
        // let link = document.createElement('a');
        // link.href = response.url;
        // link.download = `Sunfig-Invoice-${id}.pdf`;
        // link.dispatchEvent(new MouseEvent('click'));
        // you could also do:
        // window.location.href = response.file;
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: accountConstants.GET_INVOICE_REQUEST };
  }
  function success(response) {
    return { type: accountConstants.GET_INVOICE_SUCCESS, response };
  }
  function failure(error) {
    return { type: accountConstants.GET_INVOICE_FAILURE, error };
  }
}
