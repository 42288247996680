import { message } from 'antd';

export function handleAlert(alert) {
  switch (alert.type) {
    case "ALERT_SUCCESS":
      message.success(alert.message, 3.5)
    break;

    case "ALERT_ERROR":
      message.error(alert.message, 4)
    break;

    case "ALERT_INFO":
      message.info(alert.message, 3.5)
    break;

    case "ALERT_WARNING":
      message.warning(alert.message, 3.5)
    break;

    case "ALERT_LOADING":
      message.loading(alert.message, 3.5)
    break;

    case "ALERT_CLEAR":
      message.destroy();
    break;
  }
}

