import * as turf from '@turf/turf'
import ZipLoader from 'zip-loader'
import XMLParser from 'react-xml-parser';
import textEncoding from 'text-encoding';
const json2csv = require('json2csv').parse;

function createFeature(index, identity, coordinates) {
  let feature = {}
  let properties = {}
  let geometry = {}

  properties.index = index;
  properties.identity = identity;
  properties.active = true;
  properties.area = getArea([coordinates]);
  properties.oversized = false;

  geometry.type = "Polygon";
  geometry.coordinates = [coordinates];

  feature.type = "Feature"
  feature.properties = properties
  feature.geometry = geometry

  return feature
}

function validateFeature(feature, features) {
  let poly_count = feature.geometry.coordinates[0].length

  let str_coord = feature.geometry.coordinates[0].toString()
  var valid = features.length == 0 ? true : false;
  var match = false;
  for (var feat in features) {
    if (features[feat].geometry.coordinates[0].length == poly_count) {
      let str_coord_compare = features[feat].geometry.coordinates[0].toString()
      // compare the strings
      var is_equal = str_coord === str_coord_compare;
      // if the strings are equal, then this should NOT be added
      if (is_equal) {
        match = true;    
        break;    
      }
    } 
  }
  // if there was a match within the above loop, we need to set valid to false so this isn't added
  if (match) {
    valid = false
  } else {
    valid = true
  }     
  if (feature.properties.area == -1) {
    valid = false
  }
  return valid;
}

export function getCenterPoint(features) {
  var featCollection = {
    type: 'FeatureCollection',
    features: [...Object.values(features)]
  }					
  let centeroid = turf.centroid(featCollection)
  return [centeroid.geometry.coordinates[1], centeroid.geometry.coordinates[0]]
}
export function getNewCenterPoint(features) {
  let centers = []
  Object.values(features).map(feat => {
    if (feat['properties']['center']) {
      centers.push(turf.point(feat['properties']['center']))
    }
  })
  var center_features = turf.featureCollection(centers);
  return turf.getCoord(turf.flip(turf.center(center_features)));  
}

export async function getFeaturesFromFile(file) {
  let file_type = file.name.substr(file.name.length-3).toLowerCase()
  var output = {
    features: [],
    error: undefined
  }

  if (file_type == 'kml') {
    async function getStringFileOutput(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();

        reader.onload = ( function(file) {
          var fileName = file.name;
          return function(evt){
              if (evt.target.readyState == FileReader.DONE) { // DONE == 2
                var xml = new XMLParser().parseFromString(evt.target.result)
                try {
                  let features = loadSurfaceFromXML(xml);
                  console.log(features)
                  output.features = features        
                } catch(error) {
                  // console.log(error)
                  output.error = error
                }

                resolve(output);
              }          
          };
        })(file);   
        reader.readAsBinaryString(file);
      });
    }    
    let resp = await getStringFileOutput(file)
    return resp

  } else if (file_type == 'kmz') {
    // If file is *.kmz we need to unzip the .kml to get the polygons
    var instance = await ZipLoader.unzip(file);
    // unzipping the doc.kml returns an object that holds the reference to a Utf8Array buffer which holds the kml info
    var TextDecoder = textEncoding.TextDecoder;
    try {
      // Decode the buffer into a string
      var all_features = []
      var files_names = Object.keys(instance.files)
      for (var file in files_names) {
        if (files_names[file].substr(-3,3).toLowerCase() == 'kml') {
          var string = new TextDecoder("utf-8").decode(instance.files[files_names[file]]['buffer'])
          // parse the xml document from the string
          var xml = new XMLParser().parseFromString(string)
          // let features = tj.kml(xml)
          let features = loadSurfaceFromXML(xml);		
          all_features.push(...features)
        }
      }
      output.features = all_features
    } catch (error) {
      console.log(error)
      output.error = error
    }
    return output
  }  
  
}

function processElements(elements, is_multi, is_lines, inden=0) {
  let features = [];
  let index = create_UUID();
  let identity = inden;

  for (var e in elements) {
    // console.log(`processing element ${index}`)    
    let coordinates = []   
    let coords=elements[e].getElementsByTagName('coordinates')
    // console.log(coords)
    for (var c in coords) {

      if (!is_multi) {
        coordinates = []
      }
      
      let poly = coords[c].value.toString()
      let points = poly.split(" ")
      let last_lonlat = null         
      for (var p in points) {
        var lonlat = points[p].split(",")
        if (!isNaN(parseFloat(lonlat[1]))) {
          coordinates.push([parseFloat(lonlat[0]), parseFloat(lonlat[1])])
          last_lonlat = lonlat
        }
      }

      if (is_lines) {
        var first_lonlat = points[0].split(",")
        if (!isNaN(parseFloat(first_lonlat[1]))) {
          let first_point = turf.point([parseFloat(first_lonlat[0]), parseFloat(first_lonlat[1])])
          let last_point = turf.point([parseFloat(last_lonlat[0]), parseFloat(last_lonlat[1])])
          var options = {units: 'kilometers'};              
          var distance = turf.distance(first_point, last_point, options);     
          if (distance > 0.085) {
            // console.log(distance)
            // distance too far
            continue
          }
          coordinates.push([parseFloat(first_lonlat[0]), parseFloat(first_lonlat[1])])
        }
      }
      
      if (coordinates.length < 4) {
        continue
      }       

      if (!is_multi) {
        coordinates = checkFirstandLast(coordinates)

        let feature = createFeature(index, identity, coordinates)
        let valid = validateFeature(feature, features)
        // only add this if it's valid
        if (valid) {
          features.push(feature)            
        }
        // increment
        index = create_UUID();
      }
    }

    if (is_multi) {
      coordinates.push(coordinates[0])
      let feature = createFeature(index, identity, coordinates)
      let valid = validateFeature(feature, features)
      // only add this if it's valid
      if (valid) {
        features.push(feature)            
      }   
      index = create_UUID();     
    }
  }

  return features;
}

export function loadSurfaceFromXML(xml) {
  // console.log(xml);
  // pull all the polygons from the xml
  var features = []
 
  // Time to dig into the XML to pull out the coordinates
  // Doing so this way ensures we only pull out valid coords
  // var nodes=xml.getElementsByTagName('Placemark')   
  if (xml.children.length>0) {
    var extData = xml.getElementsByTagName('ExtendedData');
    if (extData.length > 0) {
      // this might have been exported from SIFT
      var nodes=xml.getElementsByTagName('Placemark')
      for (var child in nodes) {  
        let c = nodes[child].getElementsByTagName('Data')
        let polys = nodes[child].getElementsByTagName('Polygon')
        let title = c.filter(el=>el.attributes.name=='title')[0].getElementsByTagName('value')[0]
        if (title && (title.value == 'Boundary'||title.value == 'Exclusion'||title.value == 'Boundary-Unused') ) {
          let iObj = c.filter(el=>el.attributes.name=='identity')[0].getElementsByTagName('value')[0]
          let valid_obj = title && iObj && (title.value == 'Boundary'||title.value == 'Exclusion'||title.value == 'Boundary-Unused')
          if (valid_obj) {
            processed_polygons = processElements(polys, false, false, parseInt(iObj.value))
            if (processed_polygons) {
              features.push(...processed_polygons)
            }          
          }  
        }
      }
    }
    // finish early if this is a reimport
    if (features.length>0) {
      return features
    }
    
    // NOT EXPORT FROM SIFT KMZ
    let polys = []
    let processed_polygons = undefined;
    
    // process polygons
    polys = xml.getElementsByTagName('Polygon')
    // console.log(polys) 
    processed_polygons = processElements(polys, false, false)
    if (processed_polygons) {
      features.push(...processed_polygons)
    }

    // process multi
    polys=xml.getElementsByTagName('MultiGeometry')
    // console.log(polys) 
    processed_polygons = processElements(polys, true, false)
    if (processed_polygons) {
      features.push(...processed_polygons)
    }    

    // process lines
    polys=xml.getElementsByTagName('LineString')
    // console.log(polys) 
    processed_polygons = processElements(polys, false, true)
    if (processed_polygons) {
      features.push(...processed_polygons)
    }     
  }

  return features
  
}

function oldXMLParse() {
  // var index = 0
  // for (var n in nodes) {  
  //   console.log(nodes[n])
  //   continue
  //   var save = true;
  //   var is_lines = false;
  //   var is_multi = false;
  //   var identity = 0;
  //   var name=nodes[n].getElementsByTagName('name')
  //   if (name === "Site Exclusions") {
  //     continue
  //   }
  //   if (name === "Site Boundaries")
    
  //   var polys=nodes[n].getElementsByTagName('Polygon')
  //   // console.log(polys)
  //   // console.log(polys.length, nodes[n]['name'])
  //   if (polys.length == 0) {
  //     polys=nodes[n].getElementsByTagName('MultiGeometry')
  //     if (polys.length >0)
  //       is_multi = true;
  //   }      
  //   if (polys.length == 0) {
  //     polys=nodes[n].getElementsByTagName('LineString')
  //     is_lines = true;
  //   }

    
  //   if (name.length > 0) {
  //     name = name[0]['value']
  //   } else { name = 'no name provided'; }

  //   // SunfigData comes from exporting from SIFT
  //   var extData = nodes[n].getElementsByTagName('ExtendedData');
  //   if (extData.length > 0) {
  //     var data = extData[0].getElementsByTagName('Data');
      
  //     for (var d in data) {
  //       if (data[d].attributes.name == 'title') {
  //         if (data[d].children[0].value == 'Autolayout' || data[d].children[0].value == 'Road') {
  //           save = false;
  //         }
  //         else if (data[d].children[0].value == 'Boundary') {
  //           identity = 1;
  //         }
  //         else if (data[d].children[0].value == 'Exclusion') {
  //           identity = 2;
  //         }
  //       }
  //     }
  //     if (!save) continue;
  //   }      
  //   for (var p in polys) {
  //     // continue
  //     // console.log(polys[p])
  //     var coordinates = []   
  //     // if (polys[p]['name'] == 'LineString'){
  //     //   is_lines = true;
  //     // }
  //     // var coords=polys[p].getElementsByTagName('coordinates')
  //     var coords=nodes[n].getElementsByTagName('coordinates')
  //     // console.log(coords)
  //     for (var c in coords) {

  //       if (!is_multi) {
  //         coordinates = []
  //       }
        

  //       let poly = coords[c].value.toString()
  //       let points = poly.split(" ")
  //       let last_lonlat = null         
  //       for (var p in points) {
  //         var lonlat = points[p].split(",")
  //         // console.log(lonlat)
  //         if (!isNaN(parseFloat(lonlat[1]))) {
  //           // coordinates.push([parseFloat(lonlat[1]), parseFloat(lonlat[0])])
  //           coordinates.push([parseFloat(lonlat[0]), parseFloat(lonlat[1])])
  //           last_lonlat = lonlat
  //         }
  //       }
  //       // console.log(coordinates)
  //       if (is_lines) {
  //         var first_lonlat = points[0].split(",")
  //         // var last_lonlat = points[points.length-1].split(",")
  //         if (!isNaN(parseFloat(first_lonlat[1]))) {
  //           let first_point = turf.point([parseFloat(first_lonlat[0]), parseFloat(first_lonlat[1])])
  //           let last_point = turf.point([parseFloat(last_lonlat[0]), parseFloat(last_lonlat[1])])
  //           var options = {units: 'kilometers'};              
  //           var distance = turf.distance(first_point, last_point, options);     
  //           if (distance > 0.085) {
  //             // console.log(distance)
  //             // distance too far
  //             continue
  //           }
  //           coordinates.push([parseFloat(first_lonlat[0]), parseFloat(first_lonlat[1])])
  //         }
  //       }
        
  //       if (coordinates.length < 4) {
  //         continue
  //       }       

  //       if (!is_multi) {
  //         coordinates = checkFirstandLast(coordinates)

  //         let feature = createFeature(index, identity, coordinates)
  //         let valid = validateFeature(feature, features)
  //         // only add this if it's valid
  //         if (valid) {
  //           features.push(feature)            
  //         }
  //         // increment
  //         index++;
  //       }
  //     }

  //     if (is_multi) {
  //       coordinates.push(coordinates[0])
  //       let feature = createFeature(index, identity, coordinates)
  //       let valid = validateFeature(feature, features)
  //       // only add this if it's valid
  //       if (valid) {
  //         features.push(feature)            
  //       }   
  //       index++;       
  //     }
  //   }

  // }
  // // console.log(features)
  // return features
  
  // if (surfaces.length > 0) {
  //   // Update state with new surfaces
  //   this.setState({
  //     Surfaces : surfaces        
  //   }, () => {
  //     // Call our update method that will add surfaces to our map
  //     this.forceUpdate();
  //     this.onSurfacesUpdates();
  //     // this.handleZoomExtents();
  //   });
  // } else {
  //   if (points.length == 0) {
  //     Swal({title:'Error', text:'No polygons or markers found in KMZ.', type:'warning', confirmButtonColor:'#d9534f'});
  //   } else {
  //     Swal({title:'Error', text:'No polygons found in KMZ - centering on first found marker.', type:'warning', confirmButtonColor:'#d9534f'});
  //     this.setState({center: points[0]})
  //   }
  // }
}

function getArea (coords){
  // console.log(coords[0])
  // make sure first and last coord 
  // if (coords[0][0] != coords[0][coords.length-1]){
  //   console.log(coords[0][0], coords[0][coords.length-1])
  //   coords[0].push(coords[0][0])
  // }    
  let area = 0
  try { 
    let turfPoly = turf.polygon(coords);
    area = (turf.area(turfPoly)/1000000)*100
  } catch(error) {
    console.log(error)
    // console.log(coords[0][0] == coords[0][coords[0].length-1])
    // console.log(coords[0][0], coords[0][coords[0].length-1])
    area = -1
  }
  return area;
}
function checkFirstandLast(coords) {


  if (coords[0] != coords[coords.length-1]) {
    // var options = {units: 'kilometers'};              
    // var distance = turf.distance(coords[0], coords[coords.length-1], options);
    // console.log(distance)
    coords.push(coords[0])
  }
  return coords
}

export function create_UUID() {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}


export function fixResultFiles(data, meta, code) {
  var fields = [];
  var values = []
  Object.values(data).map( info => {
    values.push(
      {
      "Key": info.id,
      "Racking": meta.racking_name,
      "Module": meta.module_name,
      "Inverter": meta.inverter_name,
      "GCR": info.gcr ,
      "Pitch (m)": info.pitch ,
      "Intra-row Spacing (m)": info.rtr ,
      "Tilt (°)":info.tilt,
      "Azimuth (°)": info.azimuth,
      "Yield (kWh/kWp)":info.YIELD,
      "Generation Yr1 (MWh)":info.generation,
      "Yearly Generation (kWh)": JSON.parse(info.generation_array).toString().replace("[ ","").replace(" ]", ""),
      "DC Capacity (MWp)": info.mw_peak,
      "AC Capacity (MW)": info.mw_ac,
      "Module Qty":info.module_count,
      "Table Count (A) in Plot":JSON.parse(info.rack_breakdown)[0],
      "Table Count (B) in Plot":JSON.parse(info.rack_breakdown)[1],
      "Table Count (C) in Plot":JSON.parse(info.rack_breakdown)[2],
      "Racking Footprint (ha)": info.racking_footprint,
      "DC:AC":info.dcac,
      "Inverter Qty":info.inverter_count,
      "String Qty": info.string_count,
      "String Qty in Plot": info.string_count_in_plot,
  
      "IRR (%)": code==152 ? info.irr : undefined,
      "NPV": code==152 ? info.npv : undefined,

      "LCOE ($/kWh)": code>=151 ? info.lcoe : undefined,
      "Total Install Cost": code>=151 ? info.total_install_cost : undefined,
      "Operating Cost Array": code>=151 ? info.op_cost_array.toString().replace("[","").replace("]", "") : undefined,
      "Cashflow After Tax": code==152 ? info.cashflow_after_tax.toString().replace("[","").replace("]", "") : undefined,
  
      "DC Degradation (%)": info.dc_degradation,
      "Horizontal Global Irradiance (kWh/m²)": info.horizontal_global_irradiance,
      "Global Incident in Col. Plane (%)": info.global_incident_in_col_plane,
      "Near Shadings Irradiance Loss (%)": info.near_shadings_irradiance_loss,
      "IAM Factor on Global (%)": info.iam_factor_on_global,
      "Soiling Loss (%)": info.soiling_loss,
      "Bifacial Gain (%)": info.bifacial_gain,
      "Global Effective Irradiation on Col. (kWh/m²)": info.global_effective_irradiation_on_col,
      "Array Nominal Energy (kWh)": info.array_nominal_energy,
      "PV Loss Irrad (%)": info.pv_loss_irrad,
      "PV Loss Temp (%)": info.pv_loss_temp,
      "PV Elec Loss (%)": info.pv_elec_loss,
      "LID Loss (%)": info.lid_loss,
      "Quality Loss (%)": info.quality_loss,
      "DC Mismatch Loss (%)": info.dc_mismatch_loss,
      "Ohmic Wiring Loss (%)": info.ohmic_wiring_loss,
      "E Array (kWh)": info.e_array,
      "Inverter Loss (%)": info.inverter_loss,
      "AC Loss (%)": info.ac_loss,
      "Interconnect Lim Loss (%)": info.interconnect_lim_loss,
    })

  })
  if (code == 150) {
    // performance
    fields = [
      "Key",
      "Racking",
      "Module",
      "Inverter",
      "GCR",
      "Pitch (m)",
      "Intra-row Spacing (m)",
      "Azimuth (°)",
      "Tilt (°)",
      "Yield (kWh/kWp)",
      "DC:AC",
      "DC Capacity (MWp)",
      "AC Capacity (MW)",
      "Generation Yr1 (MWh)",
      "Module Qty",
      "Inverter Qty",
      "String Qty",
      "String Qty in Plot",
      "Table Count (A) in Plot",
      "Table Count (B) in Plot",
      "Table Count (C) in Plot",
      "Racking Footprint (ha)",

      "Yearly Generation (kWh)",

      "DC Degradation (%)",
      "Horizontal Global Irradiance (kWh/m²)",
      "Global Incident in Col. Plane (%)",
      "Near Shadings Irradiance Loss (%)",
      "IAM Factor on Global (%)",
      "Soiling Loss (%)",
      "Bifacial Gain (%)",
      "Global Effective Irradiation on Col. (kWh/m²)",
      "Array Nominal Energy (kWh)",
      "PV Loss Irrad (%)",
      "PV Loss Temp (%)",
      "PV Elec Loss (%)",
      "LID Loss (%)",
      "Quality Loss (%)",
      "DC Mismatch Loss (%)",
      "Ohmic Wiring Loss (%)",
      "E Array (kWh)",
      "Inverter Loss (%)",
      "AC Loss (%)",
      "Interconnect Lim Loss (%)",
      


      // 'Azimuth'
      // 'doFinance',
      // 'LCOE',
      // 'Total Install Cost',
      // 'Operating Cost Array'
    ]
  } else if (code == 151) {
    // performance+finance
    fields = [
      "Key",
      "Racking",
      "Module",
      "Inverter",
      "GCR",
      "Pitch (m)",
      "Intra-row Spacing (m)",
      "Azimuth (°)",
      "Tilt (°)",
      "Yield (kWh/kWp)",
      "LCOE ($/kWh)",
      "DC:AC",
      "DC Capacity (MWp)",
      "AC Capacity (MW)",
      "Generation Yr1 (MWh)",
      "Module Qty",
      "Inverter Qty",
      "String Qty",
      "String Qty in Plot",
      "Table Count (A) in Plot",
      "Table Count (B) in Plot",
      "Table Count (C) in Plot",
      "Racking Footprint (ha)",
      // "doFinance",
      "Total Install Cost",
      "Operating Cost Array",

      "Yearly Generation (kWh)",

      "DC Degradation (%)",
      "Horizontal Global Irradiance (kWh/m²)",
      "Global Incident in Col. Plane (%)",
      "Near Shadings Irradiance Loss (%)",
      "IAM Factor on Global (%)",
      "Soiling Loss (%)",
      "Bifacial Gain (%)",
      "Global Effective Irradiation on Col. (kWh/m²)",
      "Array Nominal Energy (kWh)",
      "PV Loss Irrad (%)",
      "PV Loss Temp (%)",
      "PV Elec Loss (%)",
      "LID Loss (%)",
      "Quality Loss (%)",
      "DC Mismatch Loss (%)",
      "Ohmic Wiring Loss (%)",
      "E Array (kWh)",
      "Inverter Loss (%)",
      "AC Loss (%)",
      "Interconnect Lim Loss (%)",

      // "Azimuth"
    ]							
  } else if (code == 152) {
    fields = [
      "Key",
      "Racking",
      "Module",
      "Inverter",
      "GCR",
      "Pitch (m)",
      "Intra-row Spacing (m)",
      "Azimuth (°)",
      "Tilt (°)",
      "Yield (kWh/kWp)",
      "IRR (%)",
      "NPV",
      "LCOE ($/kWh)",
      "DC:AC",
      "DC Capacity (MWp)",
      "AC Capacity (MW)",
      "Generation Yr1 (MWh)",
      "Module Qty",
      "Inverter Qty",
      "String Qty",
      "String Qty in Plot",
      "Table Count (A) in Plot",
      "Table Count (B) in Plot",
      "Table Count (C) in Plot",
      "Racking Footprint (ha)",
      // "doFinance",
      "Total Install Cost",
      "Operating Cost Array",
      "Cashflow After Tax",

      "Yearly Generation (kWh)",

      "DC Degradation (%)",
      "Horizontal Global Irradiance (kWh/m²)",
      "Global Incident in Col. Plane (%)",
      "Near Shadings Irradiance Loss (%)",
      "IAM Factor on Global (%)",
      "Soiling Loss (%)",
      "Bifacial Gain (%)",
      "Global Effective Irradiation on Col. (kWh/m²)",
      "Array Nominal Energy (kWh)",
      "PV Loss Irrad (%)",
      "PV Loss Temp (%)",
      "PV Elec Loss (%)",
      "LID Loss (%)",
      "Quality Loss (%)",
      "DC Mismatch Loss (%)",
      "Ohmic Wiring Loss (%)",
      "E Array (kWh)",
      "Inverter Loss (%)",
      "AC Loss (%)",
      "Interconnect Lim Loss (%)",

      // "Azimuth"
    ]	    
  }

	let csv_data_struct = []
	csv_data_struct.push(fields);
	Object.values(values).map(vals => {
		let data_row = []
		fields.forEach(field => {
			data_row.push(vals[field])
		})
		csv_data_struct.push(data_row)

	})

  let copy_opts = {fields, delimiter: '\t', eol: '', header: true, withBOM: false };		
  let csv_opts = { fields, delimiter: ',', header: true, withBOM: false, quote: "\"" };

  return { tsvResult: json2csv(values, copy_opts), csvResult: csv_data_struct }
  // return { tsvResult: json2csv(values, copy_opts), csvResult: json2csv(values, csv_opts) }
}


// takes in an array of GeoJSON features and returns the overall bbox
export function getBounds(features) {
  let _features = []
  for (var feat in features) {
    _features.push(features[feat])
  }
  var featCollection = {
    type: 'FeatureCollection',
    features: _features
  }		
  var bbox = turf.bbox(featCollection);
  return bbox
}

export function calculateTotalArea(features) {
  let total_area = 0;
  Object.values(features).forEach( feature => {
    if (feature.properties.identity == 1) {
      let turfPoly = turf.polygon(feature.geometry.coordinates);
      total_area += (turf.area(turfPoly)/1000000)*100
    }
  })
  return total_area
}

export function roundOff(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}