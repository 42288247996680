import * as React from 'react';
import { withLeaflet, MapLayer } from 'react-leaflet';
import * as L from 'leaflet';
import * as EL from 'esri-leaflet';

class EsriLeafletLayer extends MapLayer {

	createLeafletElement(props) {
		var layer;

    layer = new EL.dynamicMapLayer({ ...props });
    
		if (props.eventHandlers) {
			const events = Object.keys(props.eventHandlers);
			events.forEach((event) => {
				layer.on(event, props.eventHandlers[event]);
			});
		}

		return layer;
	}

	componentDidMount() {
		const { map } = this.props.leaflet;
		this.leafletElement.addTo(map);
	}

	componentWillUnmount() {
		this.leafletElement.remove();
	}
}

export default withLeaflet(EsriLeafletLayer);
