import { alertConstants } from '../_constants';
import { handleAlert } from '../_helpers'

export function alert(state = {canceling:false}, action) {
  switch (action.type) {
    case alertConstants.CANCEL:
      return {
        canceling: true
      };

    case alertConstants.SUCCESS:
      handleAlert({
        type: alertConstants.SUCCESS,
        message: action.message
      })
      return {
        // type: alertConstants.SUCCESS,
        message: action.message
      };
    case alertConstants.ERROR:
      handleAlert({
        type: alertConstants.ERROR,
        message: action.message
      })      
      return {
        // type: alertConstants.ERROR,
        message: action.message
      };
    case alertConstants.LOADING:
      handleAlert({
        type: alertConstants.LOADING,
        message: action.message
      })      
      return {
        // type: alertConstants.LOADING,
        message: action.message
      };
    case alertConstants.WARNING:
      handleAlert({
        type: alertConstants.WARNING,
        message: action.message
      })          
      return {
        // type: alertConstants.WARNING,
        message: action.message
      };
    case alertConstants.INFO:
      handleAlert({
        type: alertConstants.INFO,
        message: action.message
      })      
      return {
        // type: alertConstants.INFO,
        message: action.message
      };                              
    case alertConstants.CLEAR:
      return {canceling: false};
    default:
      return state
  }
}