import React from 'react'
import { Input, Tooltip } from 'antd';
import { CheckSquareFilled,
  CloseSquareFilled,
  PlusSquareFilled } from '@ant-design/icons';

import { LockedByYou,	LockedBySomeone,	NotLocked } from './CollabAssets'

const EditableRow = ({ index, ...props }) => (
  <tr {...props} />
);

class EditableCell extends React.Component {

  
  state = {
    editing: false,
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  }

  componentDidUpdate() {
    if (this.input) {
      this.input.focus()
    }
  }

  update = (e) => {
    const { record, handeUpdateProjectName } = this.props;
    handeUpdateProjectName({ ...record, name: e.target.value });
  }
  save = (e) => {
    const { record, handleSave } = this.props;
    this.toggleEdit();
    handleSave({ ...record, name: e.target.value });
  }

  render() {
    // const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      handeUpdateProjectName,
      editing,
      toggleCollab,
      ...restProps
    } = this.props;
    // console.log(record)

    const collab_icon = record && record.collab_on && 
      (record.locked_by_me ? LockedByYou 
        : record.locked ? LockedBySomeone(record.locked_by_email)
        : NotLocked
      )
      const is_collab = record && record.collab_on
    

    return (
      <td ref={node => (this.cell = node)} {...restProps}>
        {editable ? 
          editing ? 
            <Input
              defaultValue={record.name}
              style={{maxWidth:250, float: 'left', width: 'auto'}}
              // ref={input => input && input.focus()}
              ref={node => (this.input = node)}
              onPressEnter={this.save}
              onChange={this.update}
            />
          : 
            <div
              // className="editable-cell-value-wrap"
              style={{ float: 'left' }}
            >
              {record.name}
            </div>              
        : 
          restProps.children
        }
        <div style={{position: 'absolute', right: 0}}>
          {is_collab
          &&
            <span onClick={()=>toggleCollab(true, record.project)} >
              {collab_icon}
            </span>
				  }  
        </div>
      </td>
    );
  }
}
  

export { EditableCell, EditableRow }