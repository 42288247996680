import styled from "styled-components";

const TableWrap = styled.section`
  height: min-content;
  width: 100%;
  border: 1px solid #333;
  border-radius: 2px;
  margin: 10px 0px;

  .title {
    width: 100%;
    background: #dddddd;
    border-bottom: 1px solid #333;
    padding: 2px 5px;
    p {
      color: #333;
      margin: 0px;
    }
  }

  .table-row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #333;

    .table-header {
      grid-column: 1;
      text-align: right;
      padding: 2px 5px;
      background: #dddddd;
      border-right: 1px solid #333;
      margin: 0px;
    }
    .table-text {
      grid-column: 2;
      text-align: left;
      padding: 2px 5px;
      margin: 0px;
    }
  }

  .tracker-breakdown-container {
    margin-top: 20px;

    .tracker-header-row,
    .tracker-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      text-align: left;

      p {
        width: 25%;
        font-weight: normal;
        margin: 3px;
      }
    }
    .tracker-header-row {
      p {
        margin: 0px;
        font-weight: 700;
      }
    }
  }
`;

const TrackerTableWrap = styled.table`
  width: 100%;
  border: 1px solid #333;
  border-radius: 2px;
  thead {
    background: #dddddd;
    border-bottom: 1px solid #333;
    padding: 2px 5px;

    th {
      font-weight: 400;
      border-right: 1px solid #333;
      padding: 0px 5px;
      width: 55px;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #333;
      td {
        border-right: 1px solid #333;
        padding: 0px 5px;
      }
    }
  }
`;

const PerfSummaryTableWrap = styled.table`
  border-collapse: collapse;
  &&& {
    th,
    td {
      border: 1px solid #333;
      padding: 3px 5px;
      vertical-align: baseline;
    }
  }

  tr th {
    text-align: center;
  }
  tr td {
    white-space: nowrap;
  }
`;

const LossTableWrap = styled.table`
  border-collapse: collapse;
  width: 100%;
  &&& {
    th,
    td {
      border: 1px solid #333;
      padding: 3px 5px;
      vertical-align: baseline;
    }
  }

  tr th {
    text-align: center;
  }
  tr td {
    white-space: nowrap;
  }
`;

export { TableWrap, TrackerTableWrap, PerfSummaryTableWrap, LossTableWrap };
