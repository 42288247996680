import React from 'react'

class Note extends React.Component {

  getPost = () => {
    const post = this.props.post
    return { __html: post.body }
  }

  render () {
    const post = this.props.post

    return (
      <div className='post-container'>
        {/* <Helmet> */}
          <title>{post.seo_title}</title>
          <meta name='description' content={post.meta_description} />
          <meta name='og:image' content={post.featured_image} />
        {/* </Helmet> */}
        <h1>{post.title}</h1>
        <div dangerouslySetInnerHTML={this.getPost()} />
      </div>
    )
  }
}


export { Note }