// this is to be used as the canonical data reducer,

import { indexOf } from 'lodash-es';
import { accountConstants } from '../_constants';

const initialState = {
  loading: false, // are we loading account information?
  loaded: false, // do we have account information loaded?
  error: undefined, // was there an error retrieving account information?

  billing: [],
  projects: [], // projects tied to this account

  current_project: undefined,
  usage: [],
  user: {
    map_center: [34.6964398, -118.2827397],
  },
  products: [],
  invoices: [],
  current_plan: 1,

  downloading_invoice: false,
};

export function account(state = initialState, action) {
  switch (action.type) {
    case accountConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case accountConstants.GETALL_SUCCESS:
      let descriptionsArr = Object.keys(action.details.products).map((key) => {
        return action.details.products[key].product_description.split(';');
      });

      descriptionsArr.map((desc, index) => {
        if (index == 0) {
          desc[0] = '50 SIFT runs per month';
        }
        if (index == 1) {
          desc[0] = 'Unlimited* SIFT runs per month';
          desc[8] = 'Custom Modules & Inverters';
          desc[9] = 'Export CAD';
          desc[10] = 'Topography';
          desc[11] = 'Collaborate with up to 2 Users';
        }
        if (index == 2) {
          desc[0] = 'Unlimited* SIFT runs per month';
          desc[8] = 'Custom Modules & Inverters';
          desc[9] = 'Export CAD';
          desc[10] = 'Topography';
          desc[12] = 'Collaborate with up to 10 Users';
          desc[13] = 'Advanced Financial Models';
        }
      });

      let tempDescriptions = descriptionsArr.map((description) => {
        return description.filter((desc, index) => index < 15).join(';');
      });

      let tempProducts = action.details.products;

      Object.values(tempProducts).map((product, index) => {
        product.product_description = tempDescriptions[index];
      });

      return {
        usage: action.details.usage,
        billing: action.details.billing,
        // products: action.details.products,
        products: tempProducts,
        invoices: action.details.invoices,
        user: action.details.user,
        current_plan: action.details.user.product_plan_paid,
        loading: false,
        loaded: true,
      };
    case accountConstants.GETALL_FAILURE:
      return {
        loading: false,
        loaded: false,
        error: action.error,
      };

    case accountConstants.GETPROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case accountConstants.GETPROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.profile,
      };
    case accountConstants.GETPROFILE_FAILURE:
      return {
        error: action.error,
      };

    case accountConstants.ADDPAYMENT_SUCCESS:
      return {
        ...state,
        // billing:. billing(state.billing, action)
      };
    case accountConstants.ADDPAYMENT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case accountConstants.UPDATESUB_SUCCESS:
      return {
        ...state,
      };
    case accountConstants.UPDATESUB_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case accountConstants.GET_INVOICE_REQUEST:
      return {
        ...state,
        downloading_invoice: true,
      };
    case accountConstants.GET_INVOICE_SUCCESS:
      return {
        ...state,
        downloading_invoice: false,
      };
    case accountConstants.GET_INVOICE_FAILURE:
      return {
        ...state,
        invoice_error: action.error,
        downloading_invoice: false,
      };

    default:
      return state;
  }
}

export function getAllProjects(state) {
  return JSON.stringify(JSON.parse(state.projects));
}
