import React, { useState, useEffect } from "react";

import { logo } from "../../../../assets/images";

import { ReportPageFooterWrap } from "./styles";

const LiveReportFooter = ({ pageNum, designation }) => {
  const [numOfPages, setNumOfPages] = useState(undefined);

  useEffect(() => {
    setNumOfPages(document.querySelectorAll(".page-wrap").length);
  }, []);

  return (
    <ReportPageFooterWrap>
      <div className="page-designation">
        <p>{designation ? designation : ""}</p>
      </div>
      <div className="powered-by-sunfig">{/* <img src={logo} /> */}</div>
      <div className="page-number">
        <p>{`Page ${pageNum} of ${numOfPages}`}</p>
      </div>
    </ReportPageFooterWrap>
  );
};

export { LiveReportFooter };
