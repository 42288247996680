import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { siftActions, EPSGData } from "../..";

import { Input, Spin, Button, Switch, Tooltip, Select, Radio, Divider } from "antd";
import { MenuFoldOutlined, MinusCircleOutlined, DownOutlined, RightOutlined } from "@ant-design/icons";

import { gft_on, gft_off, sat_on, sat_off } from "../../../assets/images";

import { LayoutInputWrapper, SVGIndicatorWrap } from "../StyledComponents";

// const SVGIndicator = ({ toggleIndicator }) => {
//   return (
//     <SVGIndicatorWrap xmlns="http://www.w3.org/2000/SVGIndicatorWrap" viewBox="0 0 300 35">
//       {toggleIndicator == 'left' ? (
//         <path fill="none" stroke="#f44803" strokeWidth="2" d="M20 10 V25 H65 M65 25 L60 20 M65 25 L60 30 " />
//       ) : (
//         <path fill="none" stroke="#f44803" strokeWidth="2" d="M280 10 V25 H235 M235 25 L240 20 M235 25 L240 30"></path>
//       )}
//     </SVGIndicatorWrap>
//   );
// };

const LayoutInputs = () => {
  const inputs = useSelector((state) => state.sift.ioManager.inputs.layout.data);
  const account_loading = useSelector((state) => state.account.account_loading);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const rackType = useSelector((state) => state.sift.ioManager.inputs.racking.data.type);
  const saving = account_loading || input_loading;
  const doGridPower = useSelector((state) => state.sift.ioManager.inputs.performance.data.doGridPower);
  const grid_power_limit = useSelector((state) => state.sift.ioManager.inputs.performance.data.grid_power_limit);
  const inverter_rating = useSelector((state) => state.sift.ioManager.inputs.inverter.data.inverterRating);

  const coord_code = inputs.epsg;
  const export_unit = EPSGData[coord_code].name.toLowerCase().indexOf("feet") >= 0 ? "Feet" : "Meter";

  const [showDesLimits, setShowDesLimits] = useState(true);
  const [showOptions, setShowOptions] = useState(true);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const dispatch = useDispatch();

  // const updateInput = debounce((key, value) => {
  //   console.log(key, value);
  //   dispatch(siftActions.updateInput(key, value))

  // }, 200);
  function updateInput(key, value, tab = "layout") {
    dispatch(siftActions.updateInput(key, value, tab));
  }

  // , fontSize: 'x-small'
  return (
    <LayoutInputWrapper>
      {/* <Spin spinning={saving}> */}
      {/* <div className="input-row name">
          <label>
            Name
          </label>
          <Input id='name'  
            onChange={(event)=> {updateInput(event.target.id,event.target.value)}} 
            value={inputs.name} size='small'
            disabled={saving}     
          />
        </div>  */}
      {/* Azimuth */}
      <div className="layout-inputs-panel">
        <Divider className="weather-divider" orientation="left" onClick={() => setShowDesLimits(!showDesLimits)}>
          <span style={{ color: "#002bcb" }}>{showDesLimits ? <DownOutlined /> : <RightOutlined />}</span>
          <span> Limits</span>
        </Divider>

        {showDesLimits && (
          <Fragment>
            <div className="layout-input-three-col">
              <label>POI Limit</label>
              <Switch
                size="small"
                id="poi_limit_lock"
                checked={doGridPower == 1}
                onChange={(b, e) => {
                  updateInput("doGridPower", b ? 1 : 0, "performance");
                }}
              />
              <Input
                // style={{ width: '136px', marginLeft: 'auto' }}
                size="small"
                disabled={doGridPower != 1}
                id="grid_power_limit"
                type="number"
                min={1}
                max={10000000000}
                value={grid_power_limit}
                onChange={(event) => {
                  updateInput(event.target.id, event.target.value, "performance");
                }}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>kWac</span>}
              />
            </div>

            {/* Inverter Quantity */}
            <div className="layout-input-three-col">
              <label>Set Inverter Qty</label>
              <Switch
                size="small"
                id="do_inverter_qty_lock"
                checked={inputs.do_inverter_qty_lock == 1}
                onChange={(b, e) => {
                  updateInput("do_inverter_qty_lock", b ? 1 : 0);
                }}
              />
              <Input
                // style={{ width: '136px', marginLeft: 'auto' }}
                size="small"
                disabled={inputs.do_inverter_qty_lock == 0}
                id="inverter_qty"
                type="number"
                min={1}
                max={500}
                value={inputs.do_inverter_qty_lock == 0 ? `Max` : inputs.inverter_qty}
                onChange={(event) => {
                  updateInput(event.target.id, event.target.value);
                }}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>inv qty</span>}
              />
            </div>
            <div className="layout-input-one-col">
              {inputs.do_inverter_qty_lock == 1 ? <span>{`Layout Limited to ~${((inputs.inverter_qty * inverter_rating) / 1000).toFixed(2)}MWac`}</span> : <span>No Limit to site AC Rating</span>}
            </div>
          </Fragment>
        )}

        <Divider className="weather-divider" orientation="left" onClick={() => setShowOptions(!showOptions)}>
          <span style={{ color: "#002bcb" }}>{showOptions ? <DownOutlined /> : <RightOutlined />}</span>
          <span> Options</span>
        </Divider>

        {showOptions && (
          <Fragment>
            {/* racking align images */}
            <div className="layout-racking-input">
              <div className="radio-buttons">
                {rackType == 0 ? (
                  <span>
                    <img style={{ marginRight: "40px" }} src={gft_on}></img>
                    <img src={gft_off}></img>
                  </span>
                ) : (
                  <span>
                    <img style={{ marginRight: "40px" }} src={sat_on}></img>
                    <img src={sat_off}></img>
                  </span>
                )}
              </div>
            </div>

            <div className="layout-racking-input">
              <div className="radio-buttons">
                <Radio.Group
                  name="do_rack_align"
                  onChange={(event) => {
                    updateInput(event.target.name, event.target.value);
                  }}
                  value={inputs.do_rack_align}
                >
                  <Radio style={{ marginRight: "40px" }} value={1}>
                    Aligned
                  </Radio>
                  <Radio value={0}>Unaligned</Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="layout-input-two-col">
              <label>Azimuth</label>
              <Input
                id="azimuth"
                type="number"
                onChange={(event) => {
                  updateInput(event.target.id, event.target.value);
                }}
                value={inputs.azimuth}
                size="small"
                disabled={saving}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>°</span>}
              />
            </div>
            {/* East-West Roads */}
            <div className="layout-input-do-roads">
              <label>Draw East-West Roads</label>
              <Radio.Group
                style={{ marginLeft: "auto" }}
                value={inputs.road_spacing_option}
                // disabled={rackType == 2}
                buttonStyle="solid"
                size="small"
                onChange={(event) => {
                  if (event.target.value === 0) {
                    updateInput("do_roads", 0);
                  } else {
                    updateInput("do_roads", 1);
                  }
                  updateInput(event.target.id, event.target.value);
                }}
              >
                <Radio.Button id="road_spacing_option" value={0}>
                  Off
                </Radio.Button>
                <Radio.Button id="road_spacing_option" value={2}>
                  Auto
                </Radio.Button>
                <Radio.Button id="road_spacing_option" value={1}>
                  Manual
                </Radio.Button>
              </Radio.Group>
            </div>
            {/* value dependent on inputs */}
            <div className="layout-input-two-col">
              <label>Max Road Spacing</label>
              <Input
                disabled={inputs.road_spacing_option == 2 || inputs.road_spacing_option == 0}
                size="small"
                id="road_spacing"
                min={1}
                max={500}
                value={inputs.road_spacing}
                onChange={(event) => {
                  updateInput(event.target.id, event.target.value);
                }}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
              />
            </div>
            <div className="layout-input-three-col" style={{ marginBottom: "15px" }}>
              <label>Draw Inverters</label>
              <Switch
                // style={{ marginLeft: 'auto' }}
                size="small"
                disabled={inputs.road_spacing_option == 0 ? true : false}
                id="do_inv_spacing"
                checked={inputs.do_inv_spacing == 1}
                onChange={(b, e) => {
                  updateInput("do_inv_spacing", b ? 1 : 0);
                }}
              />
              <Input
                // style={{ width: '136px', marginLeft: 'auto' }}
                size="small"
                disabled={inputs.do_inv_spacing == 0}
                id="inverter_per_cut"
                min={1}
                max={500}
                type="number"
                step="1"
                value={inputs.inverter_per_cut}
                onChange={(event) => {
                  updateInput(event.target.id, event.target.value);
                }}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>inv/pad</span>}
              />
            </div>
            <div className="layout-input-one-col coord">
              <label>Layout Coordinate System</label>
            </div>
            <div className="layout-text-block">
              <Select
                id="coord-system-menu"
                showSearch
                // style={{ width: '100%' }}
                // placeholder={`Select a ${props.name} Preset`}
                optionFilterProp="children"
                onChange={(value) => {
                  updateInput("epsg", value);
                }}
                value={saving ? "Loading..." : inputs.epsg}
                filterOption={(input, option) => option.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toString().indexOf(input.toLowerCase()) >= 0}
                // filterOption={(input, option) => console.log(option, input)}
              >
                {/* Drop in any custom modules the user has saved to their account */}
                {Object.values(EPSGData).map((item, index) => {
                  return (
                    <Select.Option value={item.id.code} key={index}>
                      <Tooltip placement="top" title={item.name}>
                        {/* {item.id.code != '0' ? <span>{`${item.id.code} - ${item.name}`}</span> : <span>{item.name}</span>} */}
                        <span>{item.name}</span>
                      </Tooltip>
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            {coord_code > 0 && (
              <div className="layout-input-one-col coord indent">
                <a href={`https://epsg.io/${coord_code}`} target="_blank">
                  Coordinate System Data
                </a>
              </div>
            )}
            <div className="layout-input-one-col coord indent">
              <label>Export Unit: {export_unit}</label>
            </div>

            <div className="layout-input-two-col">
              <label style={{ gridColumn: "1 / -1" }}>Racking on Topography Actions</label>
            </div>
            <div className="layout-input-one-column" style={{ marginLeft: 5 }}>
              <Radio.Group style={{ display: "flex", flexDirection: "column", marginBottom: 8 }} value={inputs.topo_action} onChange={(e) => updateInput("topo_action", e.target.value)}>
                <Radio value="nothing" checked={inputs.topo_action == "nothing"}>
                  No Action, Ignore Grade
                </Radio>
                <Radio value="delete" checked={inputs.topo_action == "delete"}>{`Remove if Grade > Racking Grade Limit`}</Radio>
              </Radio.Group>
            </div>
            {/* <div className="layout-input-one-column" style={{ marginLeft: 5 }}>
              <Radio value="nothing" onChange={(e) => console.log(e)}>
                No Action, Ignore Grade
              </Radio>
            </div>
            <div className="layout-input-one-column" style={{ marginLeft: 5 }}>
              <Radio value="delete">{`Remove if Grade > Racking Grade Limit`}</Radio>
            </div> */}

            {/* <Select
                value={inputs.topo_action}
                onChange={(value) => {
                  updateInput('topo_action', value);
                }}
              >
                <Select.Option key="nothing" value="nothing">
                  No Action, ignore Grade
                </Select.Option>
                <Select.Option key="delete" value="delete">
                  {`Remove if Grade > Racking Grade Limit`}
                </Select.Option>
                <Select.Option key="flag" value="flag" disabled>
                  Highlight according to Racking Slope Spec
                </Select.Option>
              </Select> */}

            <div className="layout-input-three-col">
              <label>Designer Margin</label>
              <Switch
                size="small"
                id="do_designer_margin"
                checked={inputs.do_designer_margin == 1}
                onChange={(b, e) => {
                  updateInput("do_designer_margin", b ? 1 : 0);
                }}
              />
              {/* value dependant inputs */}
              <Input
                disabled={inputs.do_designer_margin !== 1}
                // style={{ width: '136px', marginLeft: 'auto' }}
                size="small"
                type="number"
                id="designer_margin"
                min={0}
                max={20}
                value={inputs.designer_margin}
                onChange={(event) => {
                  updateInput(event.target.id, event.target.value);
                }}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
              />
            </div>
            {inputs.do_designer_margin == 1 && (
              <div className="layout-input-one-col">
                <span>
                  Layout is padded with extra racks. See <span style={{ color: "#002bcb" }}>?</span> for details.
                </span>
              </div>
            )}
          </Fragment>
        )}

        {/* <Divider className="weather-divider" orientation="left" onClick={() => setShowAdvanced(!showAdvanced)}>
          <span style={{ color: '#002bcb' }}>{showAdvanced ? <DownOutlined /> : <RightOutlined />}</span>
          <span> Advanced</span>
        </Divider> */}

        {/* {showAdvanced && (
          <Fragment>
           
          </Fragment>
        )} */}

        {/* <div className="layout-input-two-col">
            <label>Layout Fill</label>
            <Switch
              size="small"
              id="layout_fill"
              checked={inputs.layout_fill == 1}
              onChange={(b, e) => {
                updateInput('layout_fill', b ? 1 : 0);
              }}
            />
          </div> */}

        {/* values dependant inputs */}
        {/* <div className="layout-text-block">
            {inputs.layout_fill == 0 && (
              <p>
                No impact on results, racks removed from layout to match DC targets. See <span style={{ color: '#002bcb' }}>?</span> for details.
              </p>
            )}
            {inputs.layout_fill == 1 && (
              <p>
                No impact on results, visual preference for layouts to display fully populated. See <span style={{ color: '#002bcb' }}>?</span> for
                details.
              </p>
            )}
          </div> */}
      </div>
      {/* </Spin> */}
    </LayoutInputWrapper>
  );
};

export { LayoutInputs };
