import React, { useState, useEffect } from "react";
import "./navigation.css";
import "./buttons.css";

import { Button, Row, Col } from "antd";
import { SIFT_Button } from "./Button";

import sunfig_logo from "../_images/sunfig_logo.svg";
import close from "../_images/close.svg";
import menu from "../_images/menu.svg";

import { useSelector, useDispatch } from "react-redux";
import { siftActions } from "../../../components/Redux";

const Navigation = (props) => {
  const dispatch = useDispatch();
  const news_visible = useDispatch((state) => state.sift.ioManager.uiState.patch_visible);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());
  const [ToggleOpenClose, setToggleOpenClose] = useState(false);

  function getWindowWidth() {
    const { innerWidth: width } = window;
    return width;
  }

  function getScrollPosition() {
    setScrollPosition(window.pageYOffset);
  }

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowWidth());
    }

    function watchForScroll() {
      window.addEventListener("scroll", getScrollPosition, { passive: true });
    }

    window.addEventListener("resize", handleResize);

    watchForScroll();
    return () => {
      window.removeEventListener("scroll", getScrollPosition);
      window.removeEventListener("resize", handleResize);
    };
  });

  function toggleNews(bool) {
    dispatch(siftActions.toggleHelpToolbarVisibility("news_visible", bool));
  }

  return (
    <header>
      {windowWidth > 667 ? (
        <nav className={scrollPosition > 100 ? "nav-scrolling" : "nav-top-position"}>
          <div className="logo">
            <a href="/" aria-label="Link button to Sunfig's homepage">
              <img src={sunfig_logo} alt="Sunfig Logo" />
            </a>
          </div>
          <ul className="nav-links">
            <li>
              <a href="/sift">SIFT</a>
            </li>
            <li>
              <a onClick={props.onShowPricing}>PRICING</a>
            </li>
            <li>
              <a onClick={() => toggleNews(true)}>NEWS</a>
            </li>
            <li>{props.isAuthenticated ? <a onClick={props.onLogout}>LOGOUT</a> : <a onClick={props.onLoginShow}>LOGIN</a>}</li>

            {!props.isAuthenticated && (
              <li>
                <SIFT_Button onClick={() => (window.location.href = "https://www.sunfig.com/sift")}>Sign Up Now</SIFT_Button>
              </li>
            )}
          </ul>
        </nav>
      ) : (
        <nav className={scrollPosition > 100 ? "mobile-nav-scrolling" : "mobile-nav-top-position"}>
          <div className="mobile-logo">
            <a href="/" aria-label="Link button to Sunfig's homepage">
              <img src={sunfig_logo} alt="Sunfig Logo" />
            </a>
          </div>
          <div className="open-button">
            <Button ghost style={{ border: "none", boxShadow: "none" }} onClick={() => setToggleOpenClose(true)}>
              <img src={menu} alt="" />
            </Button>
          </div>

          <section className={ToggleOpenClose ? "mobile-nav-links open" : "mobile-nav-links"}>
            <div className="close-button">
              <Button ghost style={{ border: "none", boxShadow: "none" }} onClick={() => setToggleOpenClose(false)}>
                <img src={close} alt="" />
              </Button>
            </div>
            <ul>
              <li>
                <a href="/sift">SIFT</a>
              </li>
              <li>
                <a onClick={props.onShowPricing}>PRICING</a>
              </li>
              <li>
                <a onClick={() => toggleNews(true)}>NEWS</a>
              </li>
              <li>{props.isAuthenticated ? <a onClick={props.onLogout}>LOGOUT</a> : <a onClick={props.onLoginShow}>LOGIN</a>}</li>

              {!props.isAuthenticated && (
                <li>
                  <SIFT_Button onClick={() => (window.location.href = "https://www.sunfig.com/sift")}>Sign Up Now</SIFT_Button>
                </li>
              )}
            </ul>
          </section>
        </nav>
      )}
    </header>
  );
};

export default Navigation;
