import React from 'react';
import { Alert, Spin } from 'antd';
import config from 'config';
import { Base } from '../../layouts';

const emailUrl = config.env == "prod" ? "https://api.sunfig.com/emailverify/" : "https://api.sunfig.solar/emailverify/"
class EmailVerification extends React.Component {
  state = {
    code: 0,
  }
  componentDidMount () {
    const { code } = this.props.match.params
    console.log('Verifying email', code)
    const requestOptions = {
      method: 'POST',
      // headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "type": 'emailverify',
        "code": code
      })
    };
    fetch(emailUrl, requestOptions).then(this.handleResponse)
  }
  handleResponse = (response) => {
    return response.text().then(text => {
        let data = text && JSON.parse(text);
        if (!response.ok) {

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data == 'Success') {
          this.setState({code: 1})
        } else {
          this.setState({code: 2})
        }

        return data;
    });
  }

  render() {
    return (
      <Base>
      <div className='email-verify'>
      { this.state.code == 0 ?
        <div>
          {/* Loading */}
          <Spin spinning={true} >
            <br />
            <span>  Verifying Email</span>
          </Spin>
        </div>
      : this.state.code == 1 ?
        <div className='email-verify-response'>
          {/* Verified */}
          <Alert
            message="Verified Email"
            description={<span>Email has been verified. <a href="/account">Go to Account page.</a></span>}
            type="success"
            showIcon
          />
        </div>
      :
      <div className='email-verify-response'>
          {/* Error */}
          <Alert
            message="Verified Email"
            description="Email unable to be verified, please try again later."
            type="error"
            showIcon
          />
        </div>      
      }
      </div>
      </Base>
    )
  }
}

export { EmailVerification }