import React from 'react';
import { Modal, Spin, Alert } from 'antd';
import CheckoutForm from '../CheckoutForm.js';

const AddPaymentMethod = (props) => {
  /*
    props required

    user - object
    addingCard - bool
    updating = bool

    onAddingCard - function
    handleReturn - function
  */
  return(
    <Modal
      className="sift-modal"
      title="Add New Payment Method"
      visible={props.addingCard}
      zIndex={10000}
      footer={null}
      confirmLoading={props.updating}
      onCancel={()=> {props.onAddingCard(false)}}
    >
    <Spin spinning={props.updating}>
      <CheckoutForm
        visible={props.addingCard}
        onReturn={props.handleReturn} 
        onCancel={()=>{props.onAddingCard(false)}}
      />
    </Spin>
  </Modal>
  )
}


export { AddPaymentMethod }