import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import cards from './cards';
import { Button, Divider, Card, Col, Row, Modal, Tabs, Spin, Alert, Empty } from 'antd';
const TabPane = Tabs.TabPane;

import './funnel.css';
import '../../routes/Home/_components/buttons.css';

import { Info_50MW, Info_Topo, Info_50runs, Info_customModInv, Info_1000MW, Info_Core, Info_CustomWeather, Info_AdvFin, Info_UnlimRuns, Info_CAD, Info_Collab } from './info';

import { ArrowLeftOutlined, IssuesCloseOutlined, QuestionOutlined } from '@ant-design/icons';

// import { Login } from '../../../Pages/Login'
// import { Register } from '../../../Pages/Register'
import CheckoutForm from '../Account/_components/CheckoutForm';
import { accountActions, isAuthenticated } from '../Redux';

import { AuthCard } from '../Auth/AuthCard';

const header_titles = ['Manage Your Plan', 'Account Setup', 'Summary of Changes'];
const products = {
  1: {
    id: 1,
    name: 'Starter',
    product_description: '20 SIFT runs per month;Save/Load Projects;Boundaries up to 100ha., ≈50MWac;Import/Export KMZ;Import Weather (NSRDB, NASA);Generic Products;Sunfig Performance Models',
    desc: ['Projects up to 50MW', 'Up to 50 SIFT runs per month', 'Core SIFT Features'],
    monthly_cost: 0,
    can_dxf: false,
    can_kmz: true,
    can_meteo: true,
    can_project: false,
    can_pan: true,
    can_ond: true,
    can_api_key: false,
    can_run_performance: true,
    can_run_finance: true,
    can_run_storage: false,
    can_download_8760: false,
    api_credit: 0,
    boundary_size_limit: 100,
    run_limit: 20,
  },
  2: {
    id: 2,
    name: 'Standard',
    product_description:
      '100 SIFT runs per month;Save/Load Projects;Boundaries up to 100ha., ≈50MWac;Import/Export KMZ;Import Weather (NSRDB, NASA, Custom);Default and Custom Products;Sunfig Performance Models;Import PAN & OND;API Key;$70/month API Credit',
    desc: ['Projects up to 50MW', 'Unlimited* SIFT runs per month', 'Core SIFT Features', 'Custom Modules and Inverters', 'Custom Weather', 'Export CAD', 'Topography'],
    monthly_cost: 85,
    can_dxf: true,
    can_kmz: true,
    can_meteo: true,
    can_project: true,
    can_pan: true,
    can_ond: true,
    can_api_key: true,
    can_run_performance: true,
    can_run_finance: true,
    can_run_storage: false,
    can_download_8760: true,
    api_credit: 70,
    boundary_size_limit: 100,
    run_limit: 100,
  },
  3: {
    id: 3,
    name: 'Pro',
    product_description:
      '500 SIFT runs per month;Save/Load Projects;Boundaries up to 2000ha., ≈1000MWac;Import/Export KMZ;Import Weather (NSRDB, NASA, Custom);Default and Custom Products;Sunfig Performance Models;Import PAN & OND;API Key;$190/month API Credit',
    desc: ['Projects up to 50MW', 'Unlimited* SIFT runs per month', 'Core SIFT Features', 'Custom Modules and Inverters', 'Custom Weather', 'Export CAD', 'Topography', 'Advanced Financial Models'],
    monthly_cost: 260,
    can_dxf: true,
    can_kmz: true,
    can_meteo: true,
    can_project: true,
    can_pan: true,
    can_ond: true,
    can_api_key: true,
    can_run_performance: true,
    can_run_finance: true,
    can_run_storage: true,
    can_download_8760: true,
    api_credit: 390,
    boundary_size_limit: 2000,
    run_limit: 500,
  },
};

class Funnel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      plan_stage: 0,
      selected_plan: '',
      selected_plan_id: 0,
      active_tab: 'signup',
      adding_card: false,
      planCard: 0,
    };
  }

  handleSelectSub = (e) => {
    this.setState({ selected_plan_id: e.target.id }, () => {
      // move to next page
      let new_stage = this.props.isAuthenticated ? 2 : 1;
      this.setState({ plan_stage: new_stage });
    });
  };
  goBack = () => {
    this.setState({ plan_stage: 0, active_tab: 'signup', adding_card: false });
  };

  handleTabChange = (e) => {
    this.setState({ active_tab: e });
  };

  // handles confirmation of plan update
  handleUpdatePlan = () => {
    this.props.dispatch(accountActions.updatePlanTo(this.state.selected_plan_id));
  };

  // handles return from stripe component
  handleReturn = (token, name) => {
    this.setState({ adding_card: false });
    let _token = {
      ...token,
      name: name,
    };
    this.props.dispatch(accountActions.addPayment(_token));
  };

  // handle cancel funnel
  handleCloseFunnel = () => {
    this.props.dispatch(accountActions.toggleFunnelVis(false));
  };
  email = () => {
    var part1 = 'info';
    var part2 = Math.pow(2, 6);
    var part3 = String.fromCharCode(part2);
    var part4 = 'sunfig.com';
    var part5 = part1 + String.fromCharCode(part2) + part4;
    window.open('mai' + 'lto' + ':' + part5);
  };

  render() {
    let free_trial_status = 0;
    let current_plan_id = 0;
    let prev_plan = -1;
    let days_remaining_in_cycle = 0;
    let plan_charge_dt = 'xx/xx/xx';
    let free_trial_end_dt = 'xx/xx/xx';
    let user_type = 0;
    let prorated_amt = 0;

    if (this.props.user.free_trial_status) {
      const { user } = this.props;
      free_trial_status = user.free_trial_status;
      current_plan_id = user.product_plan_paid;
      prev_plan = user.product_plan;
      days_remaining_in_cycle = user.days_remaining_in_cycle;
      plan_charge_dt = user.plan_charge_dt;
      free_trial_end_dt = user.free_trial_end_dt;
      user_type = user.user_type;

      if (this.state.selected_plan_id > 0) {
        prorated_amt = ((days_remaining_in_cycle - 1) * (products[this.state.selected_plan_id].monthly_cost / 30 - products[prev_plan].monthly_cost / 30)).toFixed(0);
      }
    }

    const upgrading = current_plan_id < this.state.selected_plan_id;
    // const defaultPayment = {
    //   brand: "Visa",
    //   last4: 1234,
    //   exp_month: 12,
    //   exp_year: 20
    // }
    const { defaultPayment } = this.props;

    const last_page = this.props.isAuthenticated && this.state.plan_stage >= 1;
    const can_confirm = defaultPayment != undefined || this.state.selected_plan_id == 1;
    const okButtonProps = {
      disabled: !can_confirm,
      style: last_page ? {} : { display: 'none' },
    };

    const plan_cards = (selected = prev_plan) => {
      return [
        <Card title="Starter" bordered={false} className="funnel-card">
          <div className="sub-header">
            <p>Explore what SIFT can do with no commitment.</p>
            <div className="amt">$0 USD</div>
          </div>
          <Divider className="funnel-divider" />
          <ul>
            <li>
              Projects up to 50MW{' '}
              <span>
                <Info_50MW />
              </span>
            </li>
            <li>
              Up to 50 SIFT runs per month{' '}
              <span>
                <Info_50runs />
              </span>
            </li>
            <li>
              Core SIFT Features{' '}
              <span>
                <Info_Core />
              </span>
            </li>
          </ul>
          <div className="btn-wrap">
            <Button type="primary" id="1" className="btn" onClick={this.handleSelectSub} disabled={selected == 1}>
              {selected == this.state.selected_plan_id ? 'SELECTED PLAN' : selected == 1 ? 'CURRENT PLAN' : 'SELECT PLAN'}
            </Button>
          </div>
        </Card>,
        <Card title="Standard" bordered={false} className="funnel-card">
          <div className="sub-header">
            <p>Customize your projects and generate the data you need to make optimal project decisions.</p>
            <div className="amt">$85 USD</div>
            <div className="below-amt">/ month per user</div>
            <div className="below-amt">7-day free trial</div>
          </div>
          <Divider className="funnel-divider" />
          <ul>
            <li>
              Projects up to 50MW{' '}
              <span>
                <Info_50MW />
              </span>
            </li>
            <li>
              Unlimited* SIFT runs per month{' '}
              <span>
                <Info_UnlimRuns />
              </span>
            </li>
            <li>
              Core SIFT Features{' '}
              <span>
                <Info_Core />
              </span>
            </li>
          </ul>
          <div className="plus">+</div>
          <ul>
            <li>
              Custom Modules and Inverters{' '}
              <span>
                <Info_customModInv />
              </span>
            </li>
            <li>
              Custom Weather{' '}
              <span>
                <Info_CustomWeather />
              </span>
            </li>
            <li>
              Export CAD{' '}
              <span>
                <Info_CAD />
              </span>
            </li>
            <li>
              Topography{' '}
              <span>
                <Info_Topo />
              </span>
            </li>
            <li>
              Collaborate with up to 2 Users{' '}
              <span>
                <Info_Collab />
              </span>
            </li>
          </ul>
          <div className="btn-wrap">
            <Button type="primary" id="2" className="btn" onClick={this.handleSelectSub} disabled={selected == 2}>
              {selected == this.state.selected_plan_id ? 'SELECTED PLAN' : selected == 2 ? 'CURRENT PLAN' : 'SELECT PLAN'}
            </Button>
          </div>
        </Card>,
        <Card title="Pro" bordered={false} className="funnel-card">
          <div className="sub-header">
            <p>Unlock the full power of SIFT and model projects up to 1GW in size.</p>
            <div className="amt">$260 USD</div>
            <div className="below-amt">/ month per user</div>
            <div className="below-amt">7-day free trial</div>
          </div>
          <Divider className="funnel-divider" />
          <ul>
            <li>
              Projects up to 1000MW{' '}
              <span>
                <Info_1000MW />
              </span>
            </li>
            <li>
              Unlimited* SIFT runs per month{' '}
              <span>
                <Info_UnlimRuns />
              </span>
            </li>
            <li>
              Core SIFT Features{' '}
              <span>
                <Info_Core />
              </span>
            </li>
          </ul>
          <div className="plus">+</div>
          <ul>
            <li>
              Custom Modules and Inverters{' '}
              <span>
                <Info_customModInv />
              </span>
            </li>
            <li>
              Custom Weather{' '}
              <span>
                <Info_CustomWeather />
              </span>
            </li>
            <li>
              Export CAD{' '}
              <span>
                <Info_CAD />
              </span>
            </li>
            <li>
              Topography{' '}
              <span>
                <Info_Topo />
              </span>
            </li>
            <li>
              Collaborate with up to 10 Users{' '}
              <span>
                <Info_Collab />
              </span>
            </li>
          </ul>
          <div className="plus">+</div>
          <ul>
            <li>
              Advanced Financial Models{' '}
              <span>
                <Info_AdvFin />
              </span>
            </li>
          </ul>
          <div className="btn-wrap">
            <Button type="primary" id="3" className="btn" onClick={this.handleSelectSub} disabled={selected == 3}>
              {selected == this.state.selected_plan_id ? 'SELECTED PLAN' : selected == 3 ? 'CURRENT PLAN' : 'SELECT PLAN'}
            </Button>
          </div>
        </Card>,
      ];
    };

    // console.log((days_remaining_in_cycle-1 * (products[this.state.selected_plan_id].monthly_cost / 30 - products[prev_plan].monthly_cost / 30)).toFixed(0))
    return (
      <Modal
        title={header_titles[this.state.plan_stage]}
        style={{ maxWidth: '1300px', zIndex: 5000 }}
        width="80%"
        okText={can_confirm ? 'Confirm' : 'Payment Method Required'}
        cancelText={
          this.state.plan_stage > 0 ? (
            <span>
              <ArrowLeftOutlined /> Back
            </span>
          ) : (
            'Cancel'
          )
        }
        visible={this.props.funnel_visible}
        onOk={this.handleUpdatePlan}
        confirmLoading={this.props.billing_loading}
        onCancel={this.state.plan_stage > 0 ? this.goBack : () => this.handleCloseFunnel()}
        afterClose={() => this.setState({ plan_stage: 0, selected_plan_id: 0, active_tab: 'signup' })}
        okButtonProps={okButtonProps}
        className="funnel-modal"
      >
        {/* PLAN SELECTOR */}
        {this.state.plan_stage == 0 ? (
          <Fragment>
            <div className="funnel-banner">
              <h2>Sign up for Standard or Pro and get 1 week free!</h2>
            </div>
            <div className="plan-cards">
              <div className="plan-card">{plan_cards()[0]}</div>
              <div className="plan-card">{plan_cards()[1]}</div>
              <div className="plan-card">{plan_cards()[2]}</div>
            </div>
            <div className="plan-cards-mobile">
              <ul className="plan-cards-mobile-tabs">
                <li>
                  <button className={this.state.planCard === 0 ? 'active-tab' : null} onClick={() => this.setState({ ...this.state, planCard: 0 })}>
                    Starter
                  </button>
                </li>
                <li>
                  <button className={this.state.planCard === 1 ? 'active-tab' : null} onClick={() => this.setState({ ...this.state, planCard: 1 })}>
                    Standard
                  </button>
                </li>
                <li>
                  <button className={this.state.planCard === 2 ? 'active-tab' : null} onClick={() => this.setState({ ...this.state, planCard: 2 })}>
                    Pro
                  </button>
                </li>
              </ul>
              {this.state.planCard === 0 && <div className="plan-card-mobile">{plan_cards()[0]}</div>}
              {this.state.planCard === 1 && <div className="plan-card-mobile">{plan_cards()[1]}</div>}
              {this.state.planCard === 2 && <div className="plan-card-mobile">{plan_cards()[2]}</div>}
            </div>
            <div className="enterprise-block">
              <h4>Enterprise</h4>
              <p>
                <a onClick={this.email}>Contact sales </a>
                for multiple user pricing, customization, whitelabel solutions or to pay with PO
              </p>
            </div>
          </Fragment>
        ) : /* <Row gutter={16}>
            <Col span={8}>{plan_cards()[0]}</Col>
            <Col span={8}>{plan_cards()[1]}</Col>
            <Col span={8}>{plan_cards()[2]}</Col>

            <div className="enterprise-block">
              <h4>Enterprise</h4>
              <p>
                <a onClick={this.email}>Contact sales </a>
                for multiple user pricing, customization, whitelabel solutions or to pay with PO
              </p>
            </div>
          </Row> */

        this.state.plan_stage == 1 && !this.props.isAuthenticated ? (
          <Row gutter={16}>
            {/* LOGIN / REGISTER */}
            <Col span={12} style={{ margin: '0 auto', width: '425px' }}>
              <AuthCard initial_tab="signup" />
              {/* <Card title={this.state.active_tab == "login" ? "Log In" : "Register"} bordered={false} className="funnel-card" style={{margin: '0 auto', width: '425px'}}>
              <Tabs size="small" animated={true} defaultActiveKey='singup' 
                activeKey={this.state.active_tab} onChange={this.handleTabChange}
                tabBarStyle={{ display: 'none' }} className="login-tabs"
              >
                <TabPane tab="Log In" key="login" className='input-tab'>

                  <Login isModal={true} onLoggedIn={null} forward={false}/>
                  <span className="login-toggle">Don't have an account? <a onClick={()=>this.handleTabChange("signup")}>Register Now</a></span>

                </TabPane>
                <TabPane tab="Register" key="signup" className='input-tab'>

                  <Register onLoggedIn={null} forward={false}/>
                  <span className="login-toggle">Already have an account? <a onClick={()=>this.handleTabChange("login")}>Log In</a></span>

                </TabPane>
              </Tabs>
            </Card> */}
            </Col>
          </Row>
        ) : (
          <section className="summary-of-changes">
            <div className="summary-card">{plan_cards(this.state.selected_plan_id)[this.state.selected_plan_id - 1]}</div>
            <div className="summary-modal-text">
              {this.state.selected_plan_id == current_plan_id && (
                <div className="summary-text">
                  <span>
                    <IssuesCloseOutlined /> <b>{products[current_plan_id].name}</b> plan is currently active. No changes to your billing.
                  </span>
                </div>
              )}

              {/* UPGRADING TO A NEW PLAN OR TRIAL IS ACTIVE */}
              {upgrading ? (
                <div className="summary-text">
                  {free_trial_status == 0 ? (
                    <span>
                      <IssuesCloseOutlined /> You are activating your SIFT Free Trial, which will expire in 7 days. You will be charged at the end of your trial.
                    </span>
                  ) : free_trial_status == 1 || free_trial_status == 3 ? (
                    <span>
                      <IssuesCloseOutlined /> Your SIFT Free Trial is still active and will expire on {free_trial_end_dt}.{' '}
                      {this.state.selected_plan_id > 1 && <span>You will be charged at the end of your trial.</span>}
                    </span>
                  ) : free_trial_status == 2 && current_plan_id == 2 && this.state.selected_plan_id == 3 ? (
                    <span>
                      <IssuesCloseOutlined /> You will be charged a prorated <b>${prorated_amt} now</b> for the remainder of this billing cycle.
                    </span>
                  ) : (
                    <span>
                      <IssuesCloseOutlined /> You will be charged <b>${products[this.state.selected_plan_id].monthly_cost}</b> today.
                    </span>
                  )}
                </div>
              ) : (
                // DOWNGRADING PLAN (Already paid)
                current_plan_id > this.state.selected_plan_id && (
                  <div>
                    <div className="summary-text">
                      <span>
                        <IssuesCloseOutlined /> <b>{products[current_plan_id].name}</b> plan is paid and active for the remainder of the billing cycle.
                      </span>
                    </div>
                    <div className="summary-text">
                      <span>
                        <IssuesCloseOutlined /> Your plan will change to <b>{products[this.state.selected_plan_id].name}</b> on {plan_charge_dt}.
                      </span>
                    </div>
                  </div>
                )
              )}
              {this.state.selected_plan_id > 1 && (
                <div className="summary-text">
                  <span>
                    <IssuesCloseOutlined /> Each billing cycle covers one month of SIFT service.{' '}
                  </span>
                </div>
              )}
              {this.state.selected_plan_id > 1 && (
                <div className="summary-text">
                  <span>
                    <IssuesCloseOutlined /> Cancel future charges any time by downgrading to a STARTER plan.{' '}
                  </span>
                </div>
              )}
            </div>
            <div className="summary-card-payment">
              <Spin className="funnel-spin" spinning={this.props.billing_loading} style={{ width: '100%' }}>
                {defaultPayment && !this.state.adding_card ? (
                  <div>
                    <div style={{ paddingTop: '2%', paddingBottom: '2%' }}>This is the payment method you have selected:</div>
                    <div className="payment-text-holder">
                      <div className="payment-text">
                        {defaultPayment.brand} (**** {defaultPayment.last4})
                      </div>
                      <div className="payment-text">
                        Expiration Date {defaultPayment.exp_month}/{defaultPayment.exp_year}
                      </div>
                      <div className="payment-text">
                        <a onClick={() => this.setState({ adding_card: true })}>Add different card.</a>
                      </div>
                    </div>
                    <div>
                      {cards.findIndex((card) => card.name == defaultPayment.brand) > -1 ? (
                        <img src={cards[cards.findIndex((card) => card.name == defaultPayment.brand)].icon} />
                      ) : (
                        <QuestionOutlined />
                      )}
                    </div>

                    {/* 
                  <div>
                    <Button type="primary" className="btn" onClick={()=>this.setState({adding_card: true})}>
                      Add Payment Method
                    </Button>
                  </div> */}
                  </div>
                ) : this.state.adding_card ? (
                  <CheckoutForm visible={true} onReturn={this.handleReturn} onCancel={() => this.setState({ adding_card: false })} />
                ) : (
                  <Empty description="" image={Empty.PRESENTED_IMAGE_SIMPLE}>
                    <Button type="primary" className="btn" onClick={() => this.setState({ adding_card: true })}>
                      Add Payment Method
                    </Button>
                  </Empty>
                )}
              </Spin>
            </div>
          </section>
        )}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { account, authentication, alert, payments, dash } = state;
  const { billing, usage, user, products, invoices } = account;
  return {
    isAuthenticated: isAuthenticated(state),
    // replace with selector // get_default_payment()
    defaultPayment: billing && billing.length > 0 ? billing.filter((p) => p.is_default)[0] : undefined,
    billing_loading: payments.loading,
    billing_error: payments.eror,
    account_loading: account.loading,
    account_loaded: account.loaded,
    funnel_visible: payments.funnel_visible,

    account,
    billing,
    usage,
    invoices,
    user,
    authentication,
    payments,
    dash,
  };
}

const connectedFunnel = connect(mapStateToProps)(Funnel);
export { connectedFunnel as Funnel };
