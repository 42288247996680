import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { siftActions } from "../../Redux";
import { InputPopoutWrapper, SATWrap, SATWrapNoBorder, RackingSpecErrors } from "./styles";

// components
import { Switch } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

//helpers
import { isNumber } from "../../Redux";

// assets
import { SAT_Large, SAT_Medium, SAT_Small, EWF_Large, EWF_Medium, EWF_Small, GFT_Large, GFT_Medium, GFT_Small } from "./RackingImages";

const RackingSpecs = ({ autoRack }) => {
  const inputs = useSelector((state) => state.sift.ioManager.inputs.racking.data);
  const modStrings = useSelector((state) => state.sift.ioManager.inputs.performance.data.modules_per_string);
  const type = useSelector((state) => state.sift.ioManager.inputs.racking.data.type);

  const module_width = useSelector((state) => state.sift.ioManager.inputs.module.data.mlm_Width);
  const module_length = useSelector((state) => state.sift.ioManager.inputs.module.data.mlm_Length);

  const rackAY = useSelector((state) => state.sift.ioManager.inputs.racking.data.racks[0].ydim);
  const rackBY = useSelector((state) => state.sift.ioManager.inputs.racking.data.racks[1].ydim);
  const rackCY = useSelector((state) => state.sift.ioManager.inputs.racking.data.racks[2].ydim);

  const rackAX = useSelector((state) => state.sift.ioManager.inputs.racking.data.racks[0].xdim);
  const rackBX = useSelector((state) => state.sift.ioManager.inputs.racking.data.racks[1].xdim);
  const rackCX = useSelector((state) => state.sift.ioManager.inputs.racking.data.racks[2].xdim);

  const rackAMod = useSelector((state) => state.sift.ioManager.inputs.racking.data.racks[0].module);
  const rackBMod = useSelector((state) => state.sift.ioManager.inputs.racking.data.racks[1].module);
  const rackCMod = useSelector((state) => state.sift.ioManager.inputs.racking.data.racks[2].module);

  const rackAStrings = useSelector((state) => state.sift.ioManager.inputs.racking.data.racks[0].string_count);
  const rackBStrings = useSelector((state) => state.sift.ioManager.inputs.racking.data.racks[1].string_count);
  const rackCStrings = useSelector((state) => state.sift.ioManager.inputs.racking.data.racks[2].string_count);

  const [rack_a_y, set_rack_a_y] = useState(rackAY);
  const [rack_b_y, set_rack_b_y] = useState(rackBY);
  const [rack_c_y, set_rack_c_y] = useState(rackCY);

  const [rack_a_x, set_rack_a_x] = useState(rackAX);
  const [rack_b_x, set_rack_b_x] = useState(rackBX);
  const [rack_c_x, set_rack_c_x] = useState(rackCX);

  const [rack_a_module, set_rack_a_module] = useState(rackAMod);
  const [rack_b_module, set_rack_b_module] = useState(rackBMod);
  const [rack_c_module, set_rack_c_module] = useState(rackCMod);

  const [rack_a_strings, set_rack_a_strings] = useState(rackAStrings);
  const [rack_b_strings, set_rack_b_strings] = useState(rackBStrings);
  const [rack_c_strings, set_rack_c_strings] = useState(rackCStrings);

  const dispatch = useDispatch();

  // this seems silly, but it's the only way I could get the inputs to update correctly when changing the racking inputs
  useEffect(() => {
    set_rack_a_y(rackAY);
  }, [rackAY]);

  useEffect(() => {
    set_rack_b_y(rackBY);
  }, [rackBY]);

  useEffect(() => {
    set_rack_c_y(rackCY);
  }, [rackCY]);

  useEffect(() => {
    set_rack_a_x(rackAX);
  }, [rackAX]);

  useEffect(() => {
    set_rack_b_x(rackBX);
  }, [rackBX]);

  useEffect(() => {
    set_rack_c_x(rackCX);
  }, [rackCX]);

  useEffect(() => {
    set_rack_a_module(rackAMod);
  }, [rackAMod]);

  useEffect(() => {
    set_rack_b_module(rackBMod);
  }, [rackBMod]);

  useEffect(() => {
    set_rack_c_module(rackCMod);
  }, [rackCMod]);

  useEffect(() => {
    set_rack_a_strings(rackAStrings);
  }, [rackAStrings]);

  useEffect(() => {
    set_rack_b_strings(rackBStrings);
  }, [rackBStrings]);

  useEffect(() => {
    set_rack_c_strings(rackCStrings);
  }, [rackCStrings]);

  function updateInput(key, value) {
    dispatch(siftActions.updateInput(key, value));
  }

  function onChangeActive(index) {
    let { racks } = inputs;
    racks[index].active = racks[index].active == 0 ? 1 : 0;
    updateInput("racks", racks);
  }

  function onChangeXDim(e, index) {
    let { racks } = inputs;

    let changed_value = parseFloat(e.target.value);

    if (inputs.type == 1 || inputs.type == 2) {
      // SAT - all x dims should be equal to this value
      set_rack_a_x(e.target.value);
      set_rack_b_x(e.target.value);
      set_rack_c_x(e.target.value);

      racks.forEach((r) => {
        r.xdim = changed_value;
      });
    } else {
      if (index == 0) {
        set_rack_a_x(e.target.value);
      } else if (index == 1) {
        set_rack_b_x(e.target.value);
      } else if (index == 2) {
        set_rack_c_x(e.target.value);
      }
      racks[index].xdim = changed_value;
    }

    if (!isNumber(changed_value)) {
      return;
    }

    updateInput("racks", racks);
  }

  function onChangeYDim(e, index) {
    let { racks } = inputs;
    let changed_value = parseFloat(e.target.value);

    if (inputs.type == 0) {
      // GFT - all y dims should be equal to this value

      set_rack_a_y(e.target.value);
      set_rack_b_y(e.target.value);
      set_rack_c_y(e.target.value);

      racks.forEach((r) => {
        r.ydim = changed_value;
      });
    } else {
      if (index == 0) {
        set_rack_a_y(e.target.value);
      } else if (index == 1) {
        set_rack_b_y(e.target.value);
      } else if (index == 2) {
        set_rack_c_y(e.target.value);
      }
      racks[index].ydim = changed_value;
    }

    if (!isNumber(changed_value)) {
      return;
    }
    updateInput("racks", racks);
  }

  function onChangeModule(e, index) {
    let { racks } = inputs;
    if (index == 0) {
      set_rack_a_module(e.target.value);
    } else if (index == 1) {
      set_rack_b_module(e.target.value);
    } else if (index == 2) {
      set_rack_c_module(e.target.value);
    }

    let changed_value = parseFloat(e.target.value);

    if (!isNumber(changed_value)) {
      return;
    }

    racks[index].module = changed_value;
    updateInput("racks", racks);
  }

  return (
    <div className="popout-content">
      {/* A */}
      <SATWrap active={inputs.racks[0].active} autoRack={autoRack}>
        <div className="popout-header">
          <h4>Rack A</h4>
          <div className="toggle-active">
            <Switch key="0" checkedChildren="On" unCheckedChildren="Off" checked={inputs.racks[0].active === 1} disabled={inputs.rack_dims == 0} onChange={(e) => onChangeActive(0)} />
          </div>
        </div>
        <div className="mod-qty">
          <label>Mod Count</label>
          {type == 2 && <p>{`(Single Face)`}</p>}
          <input className="racking_input" type="text" disabled={!inputs.racks[0].active || inputs.rack_dims == 0} value={rack_a_module} onChange={(e) => onChangeModule(e, 0)} />
        </div>
        <div className="racking-information">
          <div className="string_count">
            <InfoCircleOutlined style={{ color: inputs.racks[0].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{type == 2 ? `${_.round(rackAMod / modStrings,2)} Strings per face` : `${_.round(rackAMod / modStrings,2)} Strings`}</p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: inputs.racks[0].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{type == 2 ? `${inputs.racks[0].module * 2} Total Modules` : `${inputs.racks[0].module} Total Modules`}</p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: inputs.racks[0].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{`${(module_width * module_length * inputs.racks[0].module).toFixed(2)}m² Modules Area`}</p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: inputs.racks[0].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{`${(rack_a_x * rack_a_y).toFixed(2)}m² Racking Area`}</p>
          </div>
        </div>
        <div className="y-input">
          <label>Y(m)</label>
          <input className="racking_input" type="text" disabled={!inputs.racks[0].active || inputs.rack_dims == 0} value={rack_a_y} onChange={(e) => onChangeYDim(e, 0)} />
        </div>
        <div className="x-input">
          <label>X(m)</label>
          <input className="racking_input" type="text" disabled={!inputs.racks[0].active || inputs.rack_dims == 0} value={rack_a_x} onChange={(e) => onChangeXDim(e, 0)} />
        </div>
        <div className="racking-image">
          {type == 2 ? (
            <EWF_Large active={inputs.racks[0].active} autoRack={autoRack} />
          ) : type == 0 ? (
            <GFT_Large active={inputs.racks[0].active} autoRack={autoRack} />
          ) : (
            <SAT_Large active={inputs.racks[0].active} autoRack={autoRack} />
          )}
        </div>
      </SATWrap>
      {/* B */}
      <SATWrap active={inputs.racks[1].active} autoRack={autoRack}>
        <div className="popout-header">
          <h4>Rack B</h4>
          <div className="toggle-active">
            <Switch key="1" checkedChildren="On" unCheckedChildren="Off" checked={inputs.racks[1].active === 1} disabled={inputs.rack_dims == 0} onChange={(e) => onChangeActive(1)} />
          </div>
        </div>
        <div className="mod-qty">
          <label>Mod Count</label>
          {type == 2 && <p>{`(Single Face)`}</p>}
          <input className="racking_input" type="text" disabled={!inputs.racks[1].active || inputs.rack_dims == 0} value={rack_b_module} onChange={(e) => onChangeModule(e, 1)} />
        </div>
        <div className="racking-information">
          <div className="string_count">
            <InfoCircleOutlined style={{ color: inputs.racks[1].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
						<p>{type == 2 ? `${_.round(rackBMod / modStrings,2)} Strings per face` : `${_.round(rackBMod / modStrings,2)} Strings`}</p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: inputs.racks[1].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{type == 2 ? `${inputs.racks[1].module * 2} Total Modules` : `${inputs.racks[1].module} Total Modules`}</p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: inputs.racks[1].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{`${(module_width * module_length * inputs.racks[1].module).toFixed(2)}m² Modules Area`}</p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: inputs.racks[1].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{`${(rack_b_x * rack_b_y).toFixed(2)}m² Racking Area`}</p>
          </div>
        </div>
        <div className="y-input">
          <label>Y(m)</label>
          <input className="racking_input" type="text" disabled={!inputs.racks[1].active || inputs.rack_dims == 0} value={rack_b_y} onChange={(e) => onChangeYDim(e, 1)} />
        </div>
        {/* {type == 0 ? (
          <div className="y-input-read-only">
            <p>{`Y(m): ${inputs.racks[1].ydim}`}</p>
          </div>
        ) : (
          <div className="y-input">
            <label>Y(m)</label>
            <input type="text" disabled={!inputs.racks[1].active} value={inputs.racks[1].ydim} onChange={(e) => onChangeYDim(e, 1)} } />
          </div>
        )} */}
        <div className="x-input">
          <label>X(m)</label>
          <input className="racking_input" type="text" disabled={!inputs.racks[1].active || inputs.rack_dims == 0} value={rack_b_x} onChange={(e) => onChangeXDim(e, 1)} />
        </div>
        {/* {(type == 1 || type == 2) && inputs.racks[0].active == 1 ? (
          <div className="x-input-read-only">
            <p>{`X(m): ${inputs.racks[1].xdim}`}</p>
          </div>
        ) : (
          <div className="x-input">
            <label>X(m)</label>
            <input type="text" disabled={!inputs.racks[1].active} value={inputs.racks[1].xdim} onChange={(e) => onChangeXDim(e, 1)} } />
          </div>
        )} */}
        <div className="racking-image">
          {type == 2 ? (
            <EWF_Medium active={inputs.racks[1].active} autoRack={autoRack} />
          ) : type == 0 ? (
            <GFT_Medium active={inputs.racks[1].active} autoRack={autoRack} />
          ) : (
            <SAT_Medium active={inputs.racks[1].active} autoRack={autoRack} />
          )}
        </div>
      </SATWrap>
      {/* C */}
      <SATWrapNoBorder active={inputs.racks[2].active} autoRack={autoRack}>
        <div className="popout-header">
          <h4>Rack C</h4>
          <div className="toggle-active">
            <Switch key="2" checkedChildren="On" unCheckedChildren="Off" checked={inputs.racks[2].active === 1} disabled={inputs.rack_dims == 0} onChange={(e) => onChangeActive(2)} />
          </div>
        </div>
        <div className="mod-qty">
          <label>Mod Count</label>
          {type == 2 && <p>{`(Single Face)`}</p>}
          <input className="racking_input" type="text" disabled={!inputs.racks[2].active || inputs.rack_dims == 0} value={rack_c_module} onChange={(e) => onChangeModule(e, 2)} />
        </div>
        <div className="racking-information">
          <div className="string_count">
            <InfoCircleOutlined style={{ color: inputs.racks[2].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
						<p>{type == 2 ? `${_.round(rackCMod / modStrings,2)} Strings per face` : `${_.round(rackCMod / modStrings,2)} Strings`}</p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: inputs.racks[2].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{type == 2 ? `${inputs.racks[2].module * 2} Total Modules` : `${inputs.racks[2].module} Total Modules`}</p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: inputs.racks[2].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{`${(module_width * module_length * inputs.racks[2].module).toFixed(2)}m² Modules Area`}</p>
          </div>
          <div className="string_count">
            <InfoCircleOutlined style={{ color: inputs.racks[2].active === 1 ? "#1E90FF" : "#444444", marginRight: "5px" }} />
            <p>{`${(rack_c_x * rack_c_y).toFixed(2)}m² Racking Area`}</p>
          </div>
        </div>
        <div className="y-input">
          <label>Y(m)</label>
          <input className="racking_input" type="text" disabled={!inputs.racks[1].active || inputs.rack_dims == 0} value={rack_c_y} onChange={(e) => onChangeYDim(e, 2)} />
        </div>
        {/* {type == 0 ? (
          <div className="y-input-read-only">
            <p>{`Y(m): ${inputs.racks[2].ydim}`}</p>
          </div>
        ) : (
          <div className="y-input">
            <label>Y(m)</label>
            <input type="text" disabled={!inputs.racks[1].active} value={inputs.racks[2].ydim} onChange={(e) => onChangeYDim(e, 2)} } />
          </div>
        )} */}
        <div className="x-input">
          <label>X(m)</label>
          <input className="racking_input" type="text" disabled={!inputs.racks[2].active || inputs.rack_dims == 0} value={rack_c_x} onChange={(e) => onChangeXDim(e, 2)} />
        </div>
        {/* {(type == 1 || type == 2) && inputs.racks[0].active == 1 ? (
          <div className="x-input-read-only">
            <p>{`X(m): ${inputs.racks[2].xdim}`}</p>
          </div>
        ) : (
          <div className="x-input">
            <label>X(m)</label>
            <input type="text" disabled={!inputs.racks[2].active} value={inputs.racks[2].xdim} onChange={(e) => onChangeXDim(e, 2)} } />
          </div>
        )} */}
        <div className="racking-image">
          {type == 2 ? (
            <EWF_Small active={inputs.racks[2].active} autoRack={autoRack} />
          ) : type == 0 ? (
            <GFT_Small active={inputs.racks[2].active} autoRack={autoRack} />
          ) : (
            <SAT_Small active={inputs.racks[2].active} autoRack={autoRack} />
          )}
        </div>
      </SATWrapNoBorder>
    </div>
  );
};

const DynamicInputPopout = ({ onClose, popoutY, visible, autoRack }) => {
  const dispatch = useDispatch();

  const { racking, module, performance } = useSelector((state) => state.sift.ioManager.inputs);
  const type = racking.data.type;

  const racking_error_present = useSelector((state) => state.sift.ioManager.uiState.racking_error_present);
  const racking_errors = useSelector((state) => state.sift.ioManager.uiState.racking_errors);

  const [showError, setShowError] = useState(false);
  const [checkInputs, setCheckInputs] = useState(false);

  useEffect(() => {
    setShowError(false);
    setCheckInputs(false);
  }, [type]);

  // const checkForErrors = () => {
  //   const selectedModuleArea = module.data.module_area;
  //   const racks = racking.data.racks;
  //   let error_one;
  //   let error_two;
  //   let error_three;
  //   const modAreaErrorOne = [];
  //   const modAreaErrorTwo = [];
  //   const inputError = [];
  //   const rackingNames = ["Rack A", "Rack B", "Rack C"];

  //   racks.map((rack, index) => {
  //     if (rack.active == 1) {
  //       let rack_area = type == 2 ? rack.xdim * 2 * rack.ydim : rack.xdim * rack.ydim;
  //       let module_area = rack.module * selectedModuleArea;
  //       // console.log('module area', module_area);
  //       // console.log('rack_area', rack_area);

  //       if (module_area > rack_area) {
  //         modAreaErrorOne.push(rackingNames[index]);
  //       }

  //       if (module_area < 0.8 * rack_area) {
  //         modAreaErrorTwo.push(rackingNames[index]);
  //       }

  //       if (!isNumeric(rack.xdim) || !isNumeric(rack.ydim) || !isNumeric(rack.module)) {
  //         inputError.push(rackingNames[index]);
  //       }
  //     }
  //   });

  //   if (modAreaErrorOne.length > 0) {
  //     error_one = `Warning: Rack Modules Area (Module Area * Mod Count) of ${modAreaErrorOne.join(
  //       ", "
  //     )} is greater than your Racking Area  (X * Y). Rack dimensions are not large enough for module count. Reduce module count or check rack dimensions.`;
  //   }

  //   if (modAreaErrorTwo.length > 0) {
  //     error_two = `Warning: Rack Modules Area (Module Area * Mod Count) of ${modAreaErrorTwo.join(
  //       ", "
  //     )} accounts for less than 80% of your Racking Area (X * Y). Check the racking dimensions and the module counts.`;
  //   }

  //   if (inputError.length > 0 || !Number.isInteger(performance.data.modules_per_string)) {
  //     error_three = `Warning: Strings per rack of ${inputError.join(", ")} (Mod Count / Modules per String) is non integer. Check Mod Count per rack and Modules Per String in Performance.`;
  //   }

  //   let errors = {
  //     error_one,
  //     error_two,
  //     error_three,
  //   };

  //   if (errors.error_one !== undefined || errors.error_two !== undefined || errors.error_three !== undefined) {
  //     setErrorMsg(errors);
  //     setShowError(true);
  //     dispatch(siftActions.updateUIState("racking_error_present", true));
  //   } else {
  //     setErrorMsg(undefined);
  //     setShowError(false);
  //     setCheckInputs(false);
  //     dispatch(siftActions.updateUIState("racking_error_present", false));
  //   }
  // };

  const checkBeforeClose = () => {
    if (racking_error_present && !checkInputs) {
      setCheckInputs(true);
    } else {
      setCheckInputs(false);
      onClose();
    }
  };

  return (
    <InputPopoutWrapper popoutY={popoutY}>
      {visible && (
        <Fragment>
          <div className="input-popout">
            <div className="popout-close">
              {checkInputs && (
                <p>
                  <InfoCircleOutlined style={{ color: "red", marginRight: "5px" }} />
                  {`Racking specs contain critical errors.  Click 'Close' to ignore.`}
                </p>
              )}
              <a onClick={() => checkBeforeClose()}>
                <u>Close</u>
              </a>
            </div>
            <RackingSpecs autoRack={autoRack} />
          </div>
          <RackingSpecErrors showError={racking_error_present}>
            <ul>
              {racking_error_present &&
                (racking_errors.error_one || racking_errors.error_two || racking_errors.error_three) &&
                Object.values(racking_errors).map((err, index) =>
                  err == undefined ? null : (
                    <li key={index}>
                      <div className="error_list">
                        <InfoCircleOutlined style={{ color: "red", marginRight: "5px", marginTop: "4px" }} />
                        <p>{err}</p>
                      </div>
                    </li>
                  )
                )}
            </ul>
          </RackingSpecErrors>
        </Fragment>
      )}
    </InputPopoutWrapper>
  );
};

export { DynamicInputPopout };
