import React from "react";
import { connect } from "react-redux";
import { authActions, accountActions, isAuthenticated } from "../../components/Redux";
import ReCAPTCHA from "react-google-recaptcha";

//components
import { Agreement } from "./agreement";
import { Info } from "../../components/Funnel/info";
import { Spinner } from "../Spinner";

// assets
import { triAlert } from "../../assets/images";

import { RegisterWrapper } from "./authstyles";

const Info_Reg_Error = ({ errors }) => {
  return (
    <Info
      text={
        <div>
          <strong>Registration Errors</strong>
          <ol>
            {errors.map((error, index) => {
              return <li key={index}>{error}</li>;
            })}
          </ol>
        </div>
      }
    />
  );
};

class RegisterNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        first_name: "",
        company: "",
        email: "",
        password1: "",
        password2: "",
        apiKey: "none",
        apiKeyId: "",
        stripeId: "",
        product_plan: "",
      },
      submitted: false,
      error: undefined,
      confirmDirty: false,
      autoCompleteResult: [],
      agree_terms: false,
      terms_error: undefined,
      visible: false,
      pw_valid: false,
      comparePassError: false,
      passCriteria: false,
    };

    this.formRef = React.createRef();

    // this.reCaptchaRef = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.handleConfirmBlur = this.handleConfirmBlur.bind(this);
    // this.compareToFirstPassword = this.compareToFirstPassword.bind(this);
    // this.validateToNextPassword = this.validateToNextPassword.bind(this);
  }

  componentDidUpdate = (prevProps, prevState) => {
    setTimeout(() => {
      if (prevState.user.password2 && prevState.user.password2 !== this.state.user.password2) {
        setTimeout(() => {
          this.comparePassword();
        }, 1000);
      }
      if (prevState.user.password1.length > 0 && prevState.user.password1.length < 8) {
        this.setState({ ...this.state, passCriteria: true });
      } else {
        this.setState({ ...this.state, passCriteria: false });
      }
    }, 1000);
  };

  toggleTerms = (visible) => {
    this.props.dispatch(authActions.showTerms(visible));
  };

  // handleOk = (e) => {
  //   // console.log(e);
  //   // this.setState({
  //   //   visible: false,
  //   // });
  //   this.props.dispatch(authActions.showTerms(false));
  // };

  // handleCancel = (e) => {
  //   // console.log(e);
  //   // this.setState({
  //   //   visible: false,
  //   // });
  // };
  agree_to_terms = (e) => {
    if (e.target.checked) {
      localStorage.setItem("TOS_Agreement", 1);
    } else {
      localStorage.setItem("TOS_Agreement", 0);
    }
    this.setState({ agree_terms: e.target.checked });
  };
  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      error: undefined,
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  handleToggleFunnel = (e, val = true) => {
    this.props.dispatch(accountActions.toggleFunnelVis(val));
  };

  comparePassword = () => {
    let { user } = this.state;
    let pw_valid = user.password1 === user.password2;
    if (!pw_valid) {
      this.setState({ ...this.state, comparePassError: true, error: `This Password doesn't match.` });
    } else if (pw_valid) {
      this.setState({ ...this.state, comparePassError: false, error: "" });
    }
  };

  handleSubmit = (values) => {
    values.preventDefault();

    // get a reCaptcha token to send to backend
    // const token = await this.reCaptchaRef.executeAsync();
    // console.log('reCaptcha token', token)
    // this resets the the recaptcha in case there was some error with submitting the form, so that recaptcha can work a subsequent time.
    // this.reCaptchaRef.reset();

    const { dispatch } = this.props;
    const { user } = this.state;

    let pw_valid = user.password1 === user.password2 && user.password1.length > 4;
    if (!pw_valid) {
      this.setState({ error: `This Password doesn't match.` });
    } else if (!this.state.agree_terms) {
      this.setState({ terms_error: "Must accept Terms to continue" });
    } else {
      this.setState({ submitted: true, error: undefined });
      dispatch(authActions.register(user, this.props.forward));
    }
  };
  handleConfirmBlur(e) {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  // compareToFirstPassword(rule, value, callback) {
  //   const form = this.props.form;
  //   if (value && value !== form.getFieldValue('password1')) {
  //     callback('Passwords do not match.');
  //   } else {
  //     callback();
  //   }
  // }
  // validateToNextPassword(rule, value, callback) {
  //   const form = this.props.form;
  //   if (value && this.state.confirmDirty) {
  //     form.validateFields(['password2'], { force: true });
  //   }
  //   callback();
  // }

  render() {
    const { registering, loggingIn } = this.props;
    const loading = registering || loggingIn;
    const validateMessages = {
      required: "This field is required!",
      types: {
        email: "Not a validate email!",
        number: "Not a validate number!",
      },
      number: {
        range: "Must be between ${min} and ${max}",
      },
    };

    return (
      <RegisterWrapper>
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <div className="form-input">
            <label htmlFor="email">Email</label>
            <input type="text" name="email" onChange={(e) => this.setState({ ...this.state, user: { ...this.state.user, email: e.target.value } })} />
          </div>
          <div className="form-input">
            <label htmlFor="password1">Password</label>
            <input type="password" name="password1" onChange={(e) => this.setState({ ...this.state, user: { ...this.state.user, password1: e.target.value } })} autoComplete="new-password"></input>
            {this.state.passCriteria && <span className="pw-error">Password must be greater than 8 Characters</span>}
          </div>
          <div className="form-input">
            <label htmlFor="password2">Confirm Password</label>
            <input
              type="password"
              name="password2"
              onChange={(e) => {
                this.setState({ ...this.state, user: { ...this.state.user, password2: e.target.value } });
              }}
            />
            {this.state.comparePassError && (
              <span className="form-alert">
                <img src={triAlert} />
                <p>{this.state.error}</p>
              </span>
            )}
          </div>
          <div className="terms">
            <input type="checkbox" name="agree_terms" disabled={loading} checked={this.state.agree_terms} onChange={(e) => this.agree_to_terms(e)} />
            <p>
              I accept the{" "}
              <span>
                <a onClick={() => this.props.dispatch(authActions.showTerms(true))}>Terms and Conditions</a>
              </span>
            </p>
          </div>
          {!this.state.agree_terms && this.state.terms_error && (
            <span className="form-alert" style={{ justifyContent: "center" }}>
              <img src={triAlert} />
              <p>{this.state.terms_error}</p>
            </span>
          )}
          <button type="submit">
            <span className="button-content">
              {this.props.registering && <Spinner />}
              Sign Up
            </span>
          </button>
          <div className="pricing-link">
            <a onClick={() => this.handleToggleFunnel()}>Plans and Pricing</a>
          </div>
        </form>
        {/* <Agreement visible={this.props.show_terms} onOk={this.toggleTerms(false)} onCancel={this.toggleTerms(false)} /> */}
        {/* <ReCAPTCHA size="invisible" sitekey={public key} ref={this.reCaptchaRef} style={{ transform: 'scale(0.77)', transformOrigin: '0 0' }} /> */}
      </RegisterWrapper>
      // <div>
      //   <Form onFinish={this.handleSubmit} className="login-form" validateMessages={validateMessages} ref={this.formRef}>
      //     <div style={{ height: 15 }}></div>
      //     <FormItem name="email" className="input" rules={[{ required: true, message: 'Please input your email!' }]}>
      //       <Input
      //         prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
      //         placeholder="Email"
      //         name="email"
      //         disabled={loading}
      //         // ref={(input) => input && input.focus()}
      //         onChange={this.handleChange}
      //         className="inner-input"
      //       />
      //     </FormItem>

      //     <div style={{ height: 6 }}></div>

      //     <FormItem rules={[{ required: true }]} name="password1" className="input" rules={[{ required: true, message: null }]}>
      //       <Input
      //         prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
      //         name="password1"
      //         onChange={this.handleChange}
      //         type="password"
      //         disabled={loading}
      //         placeholder="Password"
      //         className="inner-input"
      //       />
      //     </FormItem>

      //     <div style={{ height: 6 }}></div>

      //     <FormItem name="password2" className="input" rules={[{ required: true, message: 'Please input your password!' }]}>
      //       <Input
      //         name="password2"
      //         onChange={this.handleChange}
      //         onBlur={this.handleConfirmBlur}
      //         disabled={loading}
      //         prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
      //         type="password"
      //         placeholder="Confirm Password"
      //         className="inner-input"
      //       />
      //     </FormItem>

      //     <div style={{ height: 6 }}></div>

      //     <FormItem valuePropName="agree_terms" className="input" rules={[{ required: true, message: 'Must accept Terms to continue' }]}>
      //       <Checkbox name="agree_terms" disabled={loading} checked={this.state.agree_terms} onChange={(e) => this.setState({ agree_terms: e.target.checked })}>
      //         I accept the <a onClick={() => this.setState({ visible: true })}>Terms and Conditions</a>
      //       </Checkbox>
      //     </FormItem>

      //     <FormItem>
      //       {this.state.error && (
      //         <span style={{ color: 'red' }} className="input">
      //           {this.state.error}
      //         </span>
      //       )}
      //       {this.props.register_error && this.props.register_error.length > 0 && (
      //         <span style={{ color: 'red' }} className="input">
      //           <span>
      //             <Info_Reg_Error errors={this.props.register_error} /> Must be a valid email address. Password requirements: 8 character minimum, at least 1 non-numeric, no common passwords{' '}
      //           </span>
      //         </span>
      //       )}
      //     </FormItem>

      //     <FormItem className="button-wrapper">
      //       <Button type="primary" htmlType="submit" loading={loading} className="button">
      //         {registering ? 'Registering...' : loggingIn ? 'Logging in...' : 'Register'}
      //       </Button>
      //     </FormItem>
      //   </Form>

      //   <Agreement visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} />
      // </div>
    );
  }
}

function mapStateToProps(state) {
  const { registering, loggingIn, register_error, show_terms } = state.authentication;
  return {
    show_terms,
    registering,
    loggingIn,
    register_error,
    isAuthenticated: isAuthenticated(state),
  };
}

const connectedRegister = connect(mapStateToProps)(RegisterNew);
export { connectedRegister as RegisterNew };
