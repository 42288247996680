import React, { useState, useEffect, useRef, Fragment } from "react";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
HighchartsExporting(Highcharts);

//test data
import { weather_data } from "../perfTestData";

// redux
import { useSelector } from "react-redux";

const svgString2Image = (svgString, width, height, format, loadGraphImages) => {
  format = format ? format : "png";
  let svgData = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgString)));
  let canvas = document.createElement("canvas");
  let context = canvas.getContext("2d");
  canvas.width = width;
  canvas.height = height;

  let image = new Image();
  image.onload = function () {
    context.clearRect(0, 0, width, height);
    context.drawImage(image, 0, 0, width, height);
    let pngData = canvas.toDataURL("image/" + format);
    loadGraphImages(pngData);
  };
  image.src = svgData;
};

const options = {
  chart: {
    width: 500,
    height: 350,
    type: "column",
  },
  title: {
    text: "Performance Ratio (Yf / Yr)",
  },
  xAxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "Performance Ratio PR",
    },
  },
  series: [
    {
      name: "PR: Performance Ratio (Yf / Yr)",
      data: [0.8, 0.7, 0.6, 0.8, 0.7, 0.5, 0.6, 0.7, 0.5, 0.8, 0.7, 0.6],
    },
  ],
};

const splineData = {
  chart: {
    type: "spline",
    width: 500,
    height: 350,
    inverted: true,
    className: "monthly-chart",
  },
  title: {
    text: "Atmosphere Temperature by Altitude",
  },
  subtitle: {
    text: "According to the Standard Atmosphere Model",
  },
  xAxis: {
    reversed: false,
    title: {
      enabled: true,
      text: "Altitude",
    },
    labels: {
      format: "{value} km",
    },
    accessibility: {
      rangeDescription: "Range: 0 to 80 km.",
    },
    maxPadding: 0.05,
    showLastLabel: true,
  },
  yAxis: {
    title: {
      text: "Temperature",
    },
    labels: {
      format: "{value}°",
    },
    accessibility: {
      rangeDescription: "Range: -90°C to 20°C.",
    },
    lineWidth: 2,
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    headerFormat: "<b>{series.name}</b><br/>",
    pointFormat: "{point.x} km: {point.y}°C",
  },
  plotOptions: {
    spline: {
      marker: {
        enable: false,
      },
    },
  },
  series: [
    {
      name: "Temperature",
      data: [
        [0, 15],
        [10, -50],
        [20, -56.5],
        [30, -46.5],
        [40, -22.1],
        [50, -2.5],
        [60, -27.7],
        [70, -55.7],
        [80, -76.5],
      ],
    },
  ],
};

const iamOptions = {
  chart: {
    width: 500,
    height: 350,
    type: "line",
  },
  title: {
    text: "",
  },
  xAxis: {
    categories: undefined,
    title: {
      text: "Incidence Angle°",
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: "IAM",
    },
  },
  series: [
    {
      name: "",
      data: undefined,
      showInLegend: false,
      color: "#00d5e7",
    },
  ],
};

const IAMGraph = ({ captureGraphImage, reportData }) => {
  // state
  const [iamData, setIamData] = useState(undefined);
  // refs
  const iamRef = useRef();

  const loadGraphImages = (data) => {
    captureGraphImage("iamGraph", data);
  };

  useEffect(() => {
    setTimeout(() => {
      let svg = iamRef.current.chart.getSVG();
      svgString2Image(svg, 450, 350, "jpeg", loadGraphImages);
    }, 500);
  }, [iamData]);

  useEffect(() => {
    shapeIamData(reportData);
  }, []);

  const shapeIamData = (data) => {
    let tempData = iamOptions;
    tempData.xAxis.categories = data.module_iam_ang;
    tempData.series[0].data = data.module_iam_eff;
    setIamData(tempData);
  };

  return <Fragment>{<HighchartsReact ref={iamRef} highcharts={Highcharts} options={iamData} />}</Fragment>;
};

const PerfSummaryOptions = {
  chart: {
    width: 850,
    height: 500,
    type: "line",
    borderWidth: 1,
    borderColor: "#d9d9d9",
  },
  title: {
    text: "Irradiance Profile",
  },
  xAxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: "",
  },
  series: [
    {
      name: "GHI (kWh/m²)",
      data: [],
      color: "#60de4f",
    },
    {
      name: "DHI (kWh/m²) ",
      data: [],
      color: "#002bdb",
    },
    // {
    //   name: "Temp (C°)",
    //   data: [],
    // },
    // {
    //   name: "Wind Velocity (m/s)",
    //   data: [],
    // },
  ],
};

const PerfGraph = ({ reportData, captureGraphImage }) => {
  // state
  const [perfSummary, setPerfSummary] = useState(undefined);
  // refs
  const perfSumRef = useRef();

  useEffect(() => {
    shapePerfData(reportData);
  }, []);

  const loadGraphImages = (data) => {
    captureGraphImage("perfGraph", data);
  };

  useEffect(() => {
    setTimeout(() => {
      let svg = perfSumRef.current.chart.getSVG();
      svgString2Image(svg, 700, 350, "jpeg", loadGraphImages);
    }, 500);
  }, [perfSummary]);

  const shapePerfData = (reportData) => {
    let { DHISums_kWhm2, GHISums_kWhm2 } = data;
    DHISums_kWhm2.pop();
    GHISums_kWhm2.pop();
    PerfSummaryOptions.series[0].data = GHISums_kWhm2;
    PerfSummaryOptions.series[1].data = DHISums_kWhm2;

    setPerfSummary(PerfSummaryOptions);
  };

  return <Fragment>{<HighchartsReact ref={perfSumRef} highcharts={Highcharts} options={perfSummary} />}</Fragment>;
};

//wind and temp

const windAndTempOptions = {
  chart: {
    width: 350,
    height: 215,
    // type: "area",
    borderWidth: 1,
    borderColor: "#d9d9d9",
  },
  title: {
    text: "Wind and Temp",
    style: {
      fontSize: 14,
    },
  },
  xAxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    crosshair: true,
  },
  yAxis: [
    {
      min: 0,
      title: "Wind/Temp",
    },
    {
      labels: {
        format: "{value}°C",
        style: {
          color: "#00d5e7",
        },
      },
      title: {
        text: "Temperature",
        style: {
          color: "#00d5e7",
        },
      },
      opposite: true,
    },
    {
      gridLineWidth: 0,
      title: {
        text: "Wind",
        style: {
          color: "#002bdb",
        },
      },
      labels: {
        format: "{value} m/s",
        style: {
          color: "#002bdb",
        },
      },
    },
  ],
  series: [
    {
      name: "Wind (m/s)",
      data: [],
      color: "dodgerblue",
      type: "spline",
      opacity: 0.8,
      yAxis: 2,
    },
    {
      name: "Temp (C°)",
      data: [],
      color: "#1bac51",
      type: "spline",
      opacity: 0.5,
      yAxis: 1,
    },
  ],
};

const WindAndTemp = ({ reportData, captureGraphImage }) => {
  const [windAndTemp, setWindAndTemp] = useState(undefined);
  // refs
  const windAndTempRef = useRef();

  useEffect(() => {
    shapeWindAndTemp(reportData);
  }, []);

  const loadGraphImages = (data) => {
    captureGraphImage("windAndTempGraph", data);
  };

  useEffect(() => {
    setTimeout(() => {
      let svg = windAndTempRef.current.chart.getSVG();
      svgString2Image(svg, 300, 150, "jpeg", loadGraphImages);
    }, 500);
  }, [windAndTemp]);

  const shapeWindAndTemp = (weather_data) => {
    let { TAvg_C, WindAvg_ms } = data;
    TAvg_C.pop();
    WindAvg_ms.pop();
    windAndTempOptions.series[0].data = WindAvg_ms;
    windAndTempOptions.series[1].data = TAvg_C;
    setWindAndTemp(windAndTempOptions);
  };
  return <Fragment>{<HighchartsReact ref={windAndTempRef} highcharts={Highcharts} options={windAndTemp} />}</Fragment>;
};

const eArrayEGridOptions = {
  chart: {
    width: 350,
    height: 195,
    type: "column",
    borderWidth: 1,
    borderColor: "#d9d9d9",
  },
  title: {
    text: "Performance Graph",
    style: {
      fontSize: 14,
    },
  },
  xAxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: "",
  },
  series: [
    {
      name: "EArray (MWh)",
      data: [],
      color: "dodgerblue",
    },
    {
      name: "E_Grid (MWh)",
      data: [],
      color: "#60de4f",
    },
  ],
};

const EArrayEGridGraph = ({ reportData, captureGraphImage }) => {
  const [eArrayEGrid, seteArrayEGrid] = useState(undefined);
  // refs
  const eArrayEGridRef = useRef();

  useEffect(() => {
    shapeEArrayEGrid(reportData);
  }, []);

  const loadGraphImages = (data) => {
    captureGraphImage("eArrayGraph", data);
  };

  useEffect(() => {
    setTimeout(() => {
      let svg = eArrayEGridRef.current.chart.getSVG();
      svgString2Image(svg, 300, 150, "jpeg", loadGraphImages);
    }, 500);
  }, [eArrayEGrid]);

  const shapeEArrayEGrid = (reportData) => {
    let { EArray_MWh, EGrid_MWh } = data;
    EArray_MWh.pop();
    EGrid_MWh.pop();
    eArrayEGridOptions.series[0].data = EArray_MWh;
    eArrayEGridOptions.series[1].data = EGrid_MWh;
    seteArrayEGrid(eArrayEGridOptions);
  };
  return <Fragment>{<HighchartsReact ref={eArrayEGridRef} highcharts={Highcharts} options={eArrayEGrid} />}</Fragment>;
};

const InverterEffOptions = {
  chart: {
    width: 500,
    height: 350,
    type: "line",
  },
  tooltip: {
    enabled: false,
  },
  title: {
    text: "Inverter Efficiency",
  },
  xAxis: {
    // max: 1.2,
    max: 100,
    title: {
      text: "Nameplate %",
    },
  },
  yAxis: {
    title: {
      text: "Efficiency %",
    },
  },
  plotOptions: {
    line: {
      marker: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: "",
      data: undefined,
      showInLegend: false,
      color: "#00d5e7",
    },
  ],
};

const InverterEffGraph = ({ captureGraphImage, reportData }) => {
  // state
  const [inverterEffData, setInverterEffData] = useState(undefined);
  // refs
  const inverterGraph = useRef();

  const loadGraphImages = (data) => {
    captureGraphImage("inverterEffGraph", data);
  };

  useEffect(() => {
    setTimeout(() => {
      let svg = inverterGraph.current.chart.getSVG();
      svgString2Image(svg, 450, 350, "jpeg", loadGraphImages);
    }, 500);
  }, [setInverterEffData]);

  useEffect(() => {
    shapeInverterEffData(reportData);
  }, []);

  const shapeInverterEffData = (data) => {
    let tempData = InverterEffOptions;
    tempData.series[0].data = [
      [0.001, 0],
      [0.007, 68.9],
      [0.014, 81.3],
      [0.02, 86.6],
      [0.027, 89.4],
      [0.033, 91.2],
      [0.04, 92.4],
      [0.046, 93.3],
      [0.053, 93.9],
      [0.059, 94.4],
      [0.066, 94.8],
      [0.072, 95.1],
      [0.079, 95.4],
      [0.085, 95.6],
      [0.091, 95.8],
      [0.098, 96],
      [0.16, 96.8],
      [0.222, 97.3],
      [0.283, 97.6],
      [0.345, 97.7],
      [0.407, 97.8],
      [0.469, 97.9],
      [0.531, 97.9],
      [0.593, 97.9],
      [0.655, 97.9],
      [0.716, 97.9],
      [0.778, 97.8],
      [0.84, 97.7],
      [0.902, 97.6],
      [0.964, 97.6],
    ];
    tempData.series[0].data.forEach((arr) => {
      arr[0] = arr[0] * 100;
    });
    setInverterEffData(tempData);
  };

  return <Fragment>{<HighchartsReact ref={inverterGraph} highcharts={Highcharts} options={inverterEffData} />}</Fragment>;
};

export { IAMGraph, PerfGraph, WindAndTemp, EArrayEGridGraph, InverterEffGraph };
