import React, { useEffect } from 'react';

import { usePDF } from '@react-pdf/renderer';
import { SIFTReport } from './Report';

import { siftActions } from '../Redux';
import { useDispatch } from 'react-redux';

const DownloadPDF = (props) => {
  const dispatch = useDispatch();

  const [instance, updateInstance] = usePDF({
    document: <SIFTReport reportData={props.reportData} />,
  });

  useEffect(() => {
    if (instance.error) {
      console.log('💥💥 Something went wrong with PDF Generation 💥💥', instance.error);
    } else if (!instance.loading && instance.url) {
      openLink(instance.url);
      // this just resets the report
      setTimeout(() => {
        dispatch(siftActions.updateReportData('reset'));
      }, 3000);
    }
  }, [instance.loading, instance.url]);

  useEffect(() => {
    return () => {};
  }, []);

  const openLink = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return <>{props.children}</>;
};

export { DownloadPDF };
