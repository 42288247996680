import React from 'react';
import { SIFTHeader } from './SIFTHeader';

import { Layout } from 'antd';

const Base = (props) => {
  return (
    <Layout className='base-page'>

      {/* HEADER */}
      <SIFTHeader/>

      {/* CONTENT */}
      <div className='content-container'>
        <div className='content'>
            {props.children}
        </div>    
        {/* <div className="push"></div>  */}
      </div>


      {/* FOOTER */}
      <div className='footer' style={{width: '100%'}}>
      </div>
    </Layout>
  );
}

export { Base }