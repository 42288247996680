import { siftConstants } from "../_constants";
import { userService, siftService } from "../_services";
import { alertActions, tutorialActions } from "./";
import { history, getFeaturesFromFile, create_UUID } from "../_helpers";
import { getSIFTInputs } from "../_reducers";
import * as turf from "@turf/turf";
import { isUndefined } from "lodash-es";

export const siftActions = {
  // project manager
  newProject,
  selectProjectById,
  loadProjectById,
  deleteProject,
  saveProject,
  toggleProjectManagerModal,

  // helper toolbar
  sendBugReport,
  toggleHelpToolbarVisibility,

  // map
  // importKMZ,
  parseXML,
  toggleIsDrawing,
  createFeature,
  updateFeature,
  deleteFeature,
  selectFeatureById,
  updateTotalArea,
  updateMapCenter,
  createDXF,
  offsetPolygon,

  // io manager
  updateInput,
  updateTab,
  updateFinanceToggle,
  updateDepreciation,
  updateUIState,
  dropDownSelect,
  getRunId,
  fixWeatherInputs,
  initSIFTWithWeather,
  initSIFT,
  runSIFT,
  closeErrorSIFT,
  errorSIFT,
  generateResults,
  cancelRun,
  selectResult,
  getLayoutData,
  downloadFiles,
  downloadWeather,

  // io header
  toggleImportVis,
  importData,
  saveInput,

  //
  pullMapLoc,
  exportMap,
  updateMarker,
  requestWeather,
  getWeatherDataThenInitSIFT,
  getWeatherData,
  clearWeather,

  runTopo,
  updateTopoMode,
  updateTopo,
  clearTopo,
  getTopoId,
  getTopoData,

  runTutorial,

  toggleCollabModal,
  shareCollab,
  checkCollab,
  forceCheckInCollab,
  refreshCollab,

  toggleAccountModal,
  clearErrors,
  updateScreenWidth,
  toggleMobileInputs,
  undoFeatures,
  redoFeatures,

  pollForAlerts,
  prepareReport,
  updateReportData,
};

// Project Manager Actions
function newProject() {
  return (dispatch) => {
    dispatch(request());
    setTimeout(() => dispatch(complete()), 300);
  };
  function request() {
    return { type: siftConstants.NEW_PROJECT_REQUEST };
  }
  function complete() {
    return { type: siftConstants.NEW_PROJECT_COMPLETE };
  }
}
function selectProjectById(id) {
  return {
    type: siftConstants.SELECT_PROJECT,
    id,
  };
}
function loadProjectById(id, collab_on) {
  return (dispatch) => {
    dispatch(tutorialActions.endTutorial());
    dispatch(request(id));
    userService.getProjectById(id, collab_on).then(
      (response) => {
        dispatch(downloaded(response, id));
        setTimeout(() => dispatch(complete()), 500);
      },
      (error) => {
        dispatch(failed(error));
      }
    );
  };
  function request(id) {
    return { type: siftConstants.LOAD_PROJECT_REQUEST, id };
  }
  function downloaded(response, id) {
    return { type: siftConstants.LOAD_PROJECT_DOWNLOADED, response, id };
  }
  function complete() {
    return { type: siftConstants.LOAD_PROJECT_COMPLETE };
  }
  function failed(error) {
    return { type: siftConstants.LOAD_PROJECT_FAILED, id };
  }
}

function deleteProject(project) {
  return (dispatch) => {
    dispatch(request(project.project));
    userService.saveProject(project).then(
      (response) => {
        dispatch(success(response));
        setTimeout(() => dispatch(complete()), 500);
        // userService.getProjects().then(
        //   (response) => dispatch(complete(response)),
        //   (error) => dispatch(failure(error.toString()))
        // );
      },
      (error) => {
        dispatch(failure(error.statusText));
        dispatch(alertActions.error(error.statusText));
      }
    );
  };
  function request(id) {
    return { type: siftConstants.DELETE_PROJECT_REQUEST, id };
  }
  function success(response) {
    return { type: siftConstants.DELETE_PROJECT_SUCCESS, response };
  }
  function complete() {
    return { type: siftConstants.DELETE_PROJECT_COMPLETE };
  }
  function failure() {
    return { type: siftConstants.DELETE_PROJECT_FAILURE };
  }
}
function saveProject(project) {
  console.log(project);
  return (dispatch) => {
    dispatch(request(project));
    userService.saveProject(project).then(
      (response) => {
        dispatch(success(response.projectId, response.editDate, project));
        setTimeout(() => dispatch(complete()), 500);
        // userService.getProjects().then(
        //   (_response) => dispatch(complete(_response)),
        //   (error) => dispatch(failure(error.toString()))
        // );
      },
      (error) => {
        dispatch(failure(error.statusText));
        dispatch(alertActions.error(error.statusText));
      }
    );
  };
  function request(project) {
    return { type: siftConstants.SAVE_PROJECT_REQUEST, project };
  }
  function success(id, date, project) {
    return { type: siftConstants.SAVE_PROJECT_SUCCESS, id, date, project };
  }
  function complete() {
    return { type: siftConstants.SAVE_PROJECT_COMPLETE };
  }
  function failure() {
    return { type: siftConstants.SAVE_PROJECT_FAILURE };
  }
}
function toggleProjectManagerModal(bool) {
  return {
    type: siftConstants.TOGGLE_PROJECTMANAGER_MODAL,
    bool,
  };
}

// Helper Toolbar Actions
function sendBugReport(params) {
  return (dispatch) => {
    dispatch(request(params));
    siftService.sendBugReport(params).then(
      (response) => {
        dispatch(success());

        setTimeout(() => dispatch(complete(response)), 2000);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request(params) {
    return { type: siftConstants.BUG_REPORT_REQUEST, params };
  }
  function success() {
    return { type: siftConstants.BUG_REPORT_SUCCESS };
  }
  function complete() {
    return { type: siftConstants.BUG_REPORT_COMPLETE };
  }
  function failure() {
    return { type: siftConstants.BUG_REPORT_FAILURE };
  }
}
function toggleHelpToolbarVisibility(toolbar, bool, drawer = undefined) {
  return {
    type: siftConstants.TOGGLE_TOOLBAR_VISIBILITY,
    toolbar,
    bool,
    drawer,
  };
}

// Map Actions
// function importKMZ(file) {
//   return (dispatch) => {
//     dispatch(request(file.name));
//     getFeaturesFromFile(file).then((output) => {
//       dispatch(success(output));
//     });
//   };
//   function request(name) {
//     return { type: siftConstants.IMPORT_KMZ_REQUEST, name };
//   }
//   function success(output) {
//     return { type: siftConstants.IMPORT_KMZ_PROCESS, output };
//   }
// }
// Map Actions
function parseXML(inputs) {
  return (dispatch) => {
    dispatch(request(inputs.name));

    userService.importData({ file_type: "xml" }).then(
      (response) => {
        // console.log(response);
        inputs.runId = response.run_id;
        userService.uploadDataToURL(response.url, inputs).then(
          (_response) => dispatch(getParsedXMLData(response.run_id, 10)),
          (error) => dispatch(success(error))
        );
      },
      (error) => dispatch(success(error))
      // (response) => dispatch(getParsedXMLData(response.run_id, 10)),
    );
    // getFeaturesFromFile(file).then((output) => {
    //   dispatch(success(output));
    // });
  };
  function request(name) {
    return { type: siftConstants.IMPORT_KMZ_REQUEST, name };
  }
  function success(output) {
    return { type: siftConstants.IMPORT_KMZ_PROCESS, output };
  }
}

function getParsedXMLData(run_id, delay) {
  return (dispatch) => {
    // dispatch(request());
    siftService.pingImportedDataStatus(run_id).then((response) => {
      if (response.status == 100) {
        siftService.downloadFromS3(response.url).then((_response) => dispatch(success(_response)));
      } else if (response.status == 97) {
        // ERROR
        dispatch(success({ error: "KMZ Parse Failed" }));
      } else {
        // dispatch(update(response));
        setTimeout(dispatch(getParsedXMLData(run_id, Math.pow(delay, 2))));
      }
    });
  };

  function success(output) {
    return { type: siftConstants.IMPORT_KMZ_PROCESS, output };
  }
}

function toggleIsDrawing(bool) {
  return {
    type: siftConstants.TOGGLE_ISDRAWING,
    bool,
  };
}
function createFeature(geoJson, id) {
  return {
    type: siftConstants.CREATE_FEATURE,
    geoJson,
    id,
  };
}
function updateFeature(geoJson) {
  return {
    type: siftConstants.UPDATE_FEATURE,
    geoJson,
  };
}
function deleteFeature(id) {
  return {
    type: siftConstants.DELETE_FEATURE,
    id,
  };
}
function selectFeatureById(id) {
  return {
    type: siftConstants.SELECT_FEATURE_ID,
    id,
  };
}
function updateTotalArea(area) {
  return {
    type: siftConstants.UPDATE_TOTAL_AREA,
    area,
  };
}
function updateMapCenter(point) {
  return {
    type: siftConstants.UPDATE_MAP_CENTER,
    point,
  };
}

function createDXF(inputs, msg = "Preparing CAD...") {
  return (dispatch) => {
    dispatch(request());
    // console.log(inputs)
    userService.createDXF(inputs).then(
      (response) => {
        // server sent the url to the file!
        // now, let's download in same tab:
        // console.log(response.run_id);
        dispatch(pollDXF(response.run_id, 10));
        // window.open(response.dxf_url, '_self');
        // you could also do:
        // window.location.href = response.file;
        // dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: siftConstants.CREATEDXF_REQUEST, msg };
  }

  function failure(error) {
    return { type: siftConstants.CREATEDXF_FAILURE, error };
  }
}

function pollDXF(run_id, delay) {
  return (dispatch) => {
    // dispatch(request());
    userService.pollDXF(run_id).then((response) => {
      if (response.status == 100) {
        window.open(response.url, "_self");
        dispatch(success(response));
      } else if (response.status == 97) {
        // ERROR
        dispatch(failure("DXF Builder has failed"));
      } else {
        // dispatch(update(response));
        setTimeout(dispatch(pollDXF(run_id, Math.pow(delay, 2))));
      }
    });
  };

  function success(response) {
    return { type: siftConstants.CREATEDXF_SUCCESS, response };
  }
  function failure(error) {
    return { type: siftConstants.CREATEDXF_FAILURE, error };
  }
}

function offsetPolygon(polygon, offset) {
  return (dispatch) => {
    dispatch(request());
    siftService.offsetPolygon(polygon, offset).then(
      (response) => {
        // we could get multiple polygons back if the offset had to split
        Object.values(response["offsetPolygon"]).map((result) => {
          let new_id = create_UUID();
          result.properties["index"] = new_id;
          result.properties["identity"] = 0;
          result.properties["active"] = true;

          let turfPoly = turf.polygon(result.geometry.coordinates);
          let real_poly = turf.flip(turfPoly);

          result.properties["area"] = (turf.area(turfPoly) / 1000000) * 100;
          result.properties["center"] = turf.getCoords(turf.flip(turf.centroid(real_poly)));

          // console.log(result, new_id)

          dispatch(createFeature(result, new_id));
        });
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: siftConstants.OFFSETPOLYGON_REQUEST };
  }
  function success(response) {
    return { type: siftConstants.OFFSETPOLYGON_SUCCESS, response };
  }
  function failure(error) {
    return { type: siftConstants.OFFSETPOLYGON_FAILURE, error };
  }
}

function updateTab(tab) {
  return {
    type: siftConstants.UPDATE_TAB,
    tab,
  };
}
function updateInput(key, value = undefined, tab = undefined) {
  return {
    type: siftConstants.UPDATE_INPUT,
    key,
    value,
    tab,
  };
}
function updateFinanceToggle(bool) {
  return {
    type: siftConstants.UPDATE_FINANCE_TOGGLE,
    bool,
  };
}
function updateDepreciation(which) {
  return {
    type: siftConstants.UPDATE_FINANCE_DEPRECIATION,
    which,
  };
}

function updateUIState(key, value) {
  return {
    type: siftConstants.UPDATE_UI_STATE,
    key,
    value,
  };
}

function dropDownSelect(index) {
  return {
    type: siftConstants.DROPDOWN_SELECT,
    index,
  };
}
function getRunId(cancelTutorial = true) {
  return (dispatch) => {
    if (cancelTutorial) {
      dispatch(tutorialActions.endTutorial());
    }
    dispatch(request());
    siftService.getRunId().then(
      (response) => {
        dispatch(success(response.run_id));
      },
      (error) => {
        // console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: siftConstants.GET_RUNID_REQUEST };
  }
  function success(id) {
    return { type: siftConstants.GET_RUNID_SUCCESS, id };
  }
  function failure(error) {
    return { type: siftConstants.GET_RUNID_FAILURE, error };
  }
}

function fixWeatherInputs(inputs) {
  return {
    type: siftConstants.FIX_WEATHER_INPUTS,
    inputs,
  };
}
function initSIFTWithWeather(inputs) {
  return (dispatch) => {
    dispatch(request());

    // console.log(inputs)
    // GET THE CENTER OF THE MAP BASED ON THE FEATURES PROVIDED
    var featCollection = {
      type: "FeatureCollection",
      features: [...Object.values(inputs.features)],
    };
    let centeroid = turf.getCoords(turf.flip(turf.centroid(featCollection)));
    // console.log(centeroid)
    inputs.performance.lat = centeroid[0];
    inputs.performance.lng = centeroid[1];
    inputs.performance.center = centeroid;

    // if (inputs.performance.lat == 0 || inputs.performance.lng == 0 || !isNaN(inputs.performance.lat) || !isNaN(inputs.performance.lng)) {
    //   inputs.performance.lat = inputs.performance.center[0];
    //   inputs.performance.lng = inputs.performance.center[1];
    // }

    let weather_inputs = {
      lat: inputs.performance.lat,
      lng: inputs.performance.lng,
      ele: inputs.performance.ele,
      tz: inputs.performance.tz,
      source: inputs.performance.weather_source,
    };

    // console.log("weather inputs", weather_inputs);

    if (weather_inputs.ele == 0 || weather_inputs.tz == 0) {
      // console.log('getting ele/tz info')
      let latlng = [weather_inputs.lat, weather_inputs.lng];
      siftService.getElevation(latlng).then(
        (response) => {
          // dispatch(success({latlng: latlng, ...response.data, county_state: {...response2} }))
          inputs.performance.ele = response.data.ele.results[0].elevation || 0;
          inputs.performance.tz = response.data.tz.rawOffset / 3600;
          weather_inputs.ele = inputs.performance.ele;
          weather_inputs.tz = inputs.performance.tz;
          // console.log(weather_inputs)
          userService.runWeather(weather_inputs).then(
            (response) => {
              // console.log(response)
              inputs.performance.weather = response.weather_id;
              if (response.weather_id) {
                dispatch(siftActions.getWeatherDataThenInitSIFT(inputs, 0));

                dispatch(success(weather_inputs));
              } else {
                dispatch(alertActions.error("Error contacting weather service."));
                dispatch(failure("Error contacting weather service."));
              }
            },
            (error) => {
              dispatch(failure(error.toString()));
            }
          );
        },
        (error) => {
          dispatch(alertActions.error("Error pulling location from map."));
          dispatch(failure("Error pulling location from map."));
        }
      );
    } else {
      userService.runWeather(weather_inputs).then(
        (response) => {
          // console.log(response)
          inputs.performance.weather = response.weather_id;
          if (response.weather_id) {
            dispatch(siftActions.getWeatherDataThenInitSIFT(inputs, 0));

            dispatch(success(weather_inputs));
          } else {
            dispatch(alertActions.error("Error contacting weather service."));
            dispatch(failure("Error contacting weather service."));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
    }
  };

  function request() {
    return { type: siftConstants.INIT_WITH_WEATHER_REQ };
  }
  function success(data) {
    return { type: siftConstants.INIT_WITH_WEATHER_SUCCESS, data };
  }
  function failure(error) {
    return { type: siftConstants.INIT_WITH_WEATHER_FAILURE, error };
  }
}
function initSIFT(inputs) {
  return (dispatch) => {
    dispatch(request());
    siftService.initSIFT(inputs).then((response) => {
      if (response.run_id) {
        dispatch(success(response));

        dispatch(siftActions.generateResults(response.run_id, inputs, 10, 0, 90));
      } else {
        dispatch(failure("Error starting runs"));
      }
    });
  };

  function request() {
    return { type: siftConstants.INIT_RESULTS_REQUEST };
  }
  function success(data) {
    return { type: siftConstants.INIT_RESULTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: siftConstants.INIT_RESULTS_ERROR, error };
  }
}
function runSIFT(inputs, id) {
  return (dispatch) => {
    dispatch(request());
    let full_inputs = { ...inputs, runId: id };
    // console.log(full_inputs)
    siftService.runSIFT(full_inputs).then((response) => {
      if (response.run_id) {
        dispatch(success(response));

        dispatch(siftActions.generateResults(response.run_id, undefined, 10, 0, 98));
      } else {
        dispatch(failure("Error starting runs"));
      }
    });
  };

  function request() {
    return { type: siftConstants.RUN_SIFT_REQUEST };
  }
  function success(data) {
    return { type: siftConstants.RUN_SIFT_SUCCESS, data };
  }
  function failure(error) {
    return { type: siftConstants.RUN_SIFT_ERROR, error };
  }
}
function closeErrorSIFT() {
  return {
    type: siftConstants.CLOSE_ERROR_SIFT,
  };
}
function errorSIFT(errors) {
  return {
    type: siftConstants.ERROR_SIFT,
    errors,
  };
}
function generateResults(run_id, inputs, delay, count, prev_status) {
  return (dispatch) => {
    if (count > 1400) {
      dispatch(failure({ error: ["Error running SIFT, hit the bug report button to inform the Sunfig team"] }));
      return;
    }

    dispatch(request());
    siftService.getResults(run_id).then((response) => {
      if (response.status == 100) {
        // console.log(response.data[0])
        let plotRecord = {
          id: response.data[0].id,
          gcr: response.data[0].gcr,
          dcac: response.data[0].dcac,
          layout_id: response.data[0].layout_id,
        };
        // console.log(plotRecord)
        // plot the first record before finishing
        siftService.getLayout(plotRecord.id).then(
          (layout_response) => {
            // dispatch(success(response));
            // dispatch(alertActions.success('Automatic Layout Drawn'));
            siftService.downloadFromS3(layout_response).then(
              (_response) => dispatch(layout_success({ layout: _response, record: plotRecord })),
              // COMPLETE
              dispatch(complete()),
              // WAIT TO CALL SUCCESS SO THE COMPLETE :) SHOWS UP FOR A SECOND(1000ms)
              setTimeout(() => dispatch(success(response)), 500)
            );
          },
          (error) => {
            dispatch(failure(error.toString()));
            // dispatch(alertActions.error(error.toString()));
          }
        );
      } else if (response.status == 50) {
        // CANCEL
        dispatch(failure("cancel"));
      } else if (response.status == 97) {
        // ERROR
        dispatch(failure(response.error));
      } else if (response.status == 98) {
        // VALIDATION COMPLETE
        dispatch(validated());
        // RUN FULL RUN NOW
        dispatch(siftActions.runSIFT(inputs, run_id));
      } else {
        if (prev_status == 90 && response.status == 91) {
          count = 0;
        }
        dispatch(update(response));
        setTimeout(dispatch(siftActions.generateResults(run_id, inputs, Math.pow(delay, 2), count + 1, response.status), delay));
      }
    });
  };

  function request() {
    return { type: siftConstants.GENERATE_RESULTS_REQUEST };
  }
  function update(response) {
    return { type: siftConstants.GENERATE_RESULTS_UPDATE, response };
  }
  function complete() {
    return { type: siftConstants.GENERATE_RESULTS_COMPLETE };
  }
  function success(data) {
    return { type: siftConstants.GENERATE_RESULTS_SUCCESS, data };
  }
  function validated() {
    return { type: siftConstants.GENERATE_RESULTS_VALIDATED };
  }
  function layout_success(response) {
    return { type: siftConstants.GET_LAYOUT_SUCCESS, response };
  }
  function failure(error) {
    return { type: siftConstants.GENERATE_RESULTS_FAILURE, error };
  }
}

function cancelRun(id) {
  return (dispatch) => {
    dispatch(request());
    siftService.cancelRun(id);
  };
  function request() {
    return { type: siftConstants.CANCEL_RUN_REQUEST };
  }
}

function selectResult(record) {
  return {
    type: siftConstants.RESULT_SELECT,
    record,
  };
}

function getLayoutData(record) {
  return (dispatch) => {
    dispatch(request());

    siftService.getLayout(record.id).then(
      (response) => {
        // dispatch(success(response));
        // dispatch(alertActions.success('Automatic Layout Drawn'));
        siftService.downloadFromS3(response).then((_response) => dispatch(success({ layout: _response, record: record })));
      },
      (error) => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: siftConstants.GET_LAYOUT_REQUEST };
  }
  function success(response) {
    return { type: siftConstants.GET_LAYOUT_SUCCESS, response };
  }
  function failure(error) {
    return { type: siftConstants.GET_LAYOUT_FAILURE, error };
  }
}

// function downloadFiles(id) {
//   return (dispatch) => {
//     dispatch(request(id));

//     userService.getResultFiles(id).then(
//       (response) => {
//         if (response.url == 'Error') {
//           dispatch(failure('There was a problem downloading Data files.'));
//           dispatch(alertActions.error('There was a problem downloading Data files.'));
//         } else {
//           window.open(response.url, '_self');
//           dispatch(success(response.url));
//           // siftService.downloadFromS3toPC(response.url).then(_response => dispatch(success(_response)));
//         }
//       },
//       (error) => {
//         dispatch(failure(error.toString()));
//         dispatch(alertActions.error(error.toString()));
//       }
//     );
//   };

//   function request(id) {
//     return { type: siftConstants.DOWNLOAD_FILES_REQUEST, id };
//   }
//   function success(response) {
//     return { type: siftConstants.DOWNLOAD_FILES_SUCCESS, response };
//   }
//   function failure(error) {
//     return { type: siftConstants.DOWNLOAD_FILES_FAILURE, error };
//   }
// }

function downloadFiles(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.getResultFiles(id).then(
      (response) => {
        // server sent the job_id/run_id of the report generation job
        // console.log(response.run_id);
        dispatch(pollReport(response.run_id, 10));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: siftConstants.DOWNLOAD_FILES_REQUEST, id };
  }

  function failure(error) {
    return { type: siftConstants.DOWNLOAD_FILES_FAILURE, error };
  }
}

function pollReport(run_id, delay) {
  return (dispatch) => {
    // dispatch(request());
    userService.pollReport(run_id).then((response) => {
      if (response.status == 100) {
        window.open(response.url, "_self");
        dispatch(success(response));
      } else if (response.status == 97) {
        // ERROR
        dispatch(failure("Report Generation has failed"));
      } else {
        // dispatch(update(response));
        setTimeout(dispatch(pollReport(run_id, Math.pow(delay, 2))));
      }
    });
  };

  function success(response) {
    return { type: siftConstants.DOWNLOAD_FILES_SUCCESS, response };
  }
  function failure(error) {
    return { type: siftConstants.DOWNLOAD_FILES_FAILURE, error };
  }
}

function downloadWeather(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.getWeatherFile(id).then(
      (response) => {
        if (response.weatherUrl == "Error") {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        } else {
          window.open(response.weatherUrl, "_self");
          dispatch(success(response.weatherUrl));
          // siftService.downloadFromS3toPC(response.url).then(_response => dispatch(success(_response)));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id) {
    return { type: siftConstants.DOWNLOAD_FILES_REQUEST, id };
  }
  function success(response) {
    return { type: siftConstants.DOWNLOAD_FILES_SUCCESS, response };
  }
  function failure(error) {
    return { type: siftConstants.DOWNLOAD_FILES_FAILURE, error };
  }
}

function toggleImportVis(bool) {
  return {
    type: siftConstants.TOGGLE_IMPORT_VIS,
    bool,
  };
}
function importData(options, data) {
  return (dispatch) => {
    dispatch(request(options));

    userService.importData(options).then(
      (response) => {
        // console.log(response);
        options.runId = response.run_id;
        userService.uploadDataToURL(response.url, data).then(
          (_response) => dispatch(getParsedImportedData(options, 10)),
          (error) => dispatch(success({ error: "Import Data Failed", tab: options.tab, raw_error: error }))
        );
      },
      (error) => dispatch(success({ error: "Import Data Failed", tab: options.tab, raw_error: error }))
    );
  };

  function request(options) {
    return { type: siftConstants.IMPORTDATA_REQUEST, options };
  }
  function success(response) {
    return { type: siftConstants.IMPORTDATA_SUCCESS, response };
  }
  function failure(error) {
    return { type: siftConstants.IMPORTDATA_FAILURE, error };
  }
}

function getParsedImportedData(options, delay) {
  return (dispatch) => {
    // dispatch(request());
    siftService.pingImportedDataStatus(options.runId).then((response) => {
      if (response.status == 100) {
        siftService.downloadFromS3(response.url).then((_response) => {
          dispatch(success({ ...options, s3contents: _response }));
        });
      } else if (response.status == 97) {
        // ERROR
        dispatch(success({ error: "Import Data Failed", tab: options.tab }));
      } else {
        // dispatch(update(response));
        setTimeout(dispatch(getParsedImportedData(options, Math.pow(delay, 2))));
      }
    });
  };

  function success(response) {
    return { type: siftConstants.IMPORTDATA_SUCCESS, response };
  }
}

function saveInput(input, tab, key) {
  return (dispatch) => {
    dispatch(request());

    siftService.saveInput(input, tab).then(
      (response) => dispatch(success({ response, input, key })),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: siftConstants.SAVEINPUT_REQUEST };
  }
  function success(payload) {
    return { type: siftConstants.SAVEINPUT_SUCCESS, payload };
  }
  function failure(error) {
    return { type: siftConstants.SAVEINPUT_FAILURE, error };
  }
}

function pullMapLoc() {
  return (dispatch) => {
    dispatch(request()), setTimeout(() => dispatch(success()), 100);
  };
  function request() {
    return { type: siftConstants.PULL_MAP_LOC };
  }
  function success() {
    return { type: siftConstants.PULL_MAP_LOC_SUCCESS };
  }
}

function exportMap(fileType, msg = "Map Loading...") {
  return {
    type: siftConstants.EXPORT_MAP,
    fileType,
    msg,
  };
}
function updateMarker(marker) {
  // get google ping
  return (dispatch) => {
    let elevation = 0;
    let tz = 0;
    let countyState = {
      county: undefined,
      state: undefined,
    };

    dispatch(request());
    let latlng = [marker.lat, marker.lng];
    siftService.getElevation(latlng).then(
      (response) => {
        if (response.data.ele.status == "OK") elevation = response.data.ele.results[0].elevation;
        if (response.data.tz.status == "OK") tz = response.data.tz.rawOffset / 3600;

        siftService.getCountyState(marker.lat, marker.lng).then(
          (response2) => {
            if (response2.results && response2.results.length > 0) {
              countyState = {
                county: `${response2.results[0]["county_name"]}, `,
                state: response2.results[0]["state_code"],
              };
            }
            dispatch(success({ latlng, elevation, tz, countyState }));
          },
          (error) => {
            dispatch(success({ latlng, elevation, tz, countyState }));
          }
        );
      },
      (error) => {
        dispatch(success({ latlng, elevation, tz, countyState }));
      }
    );
  };
  function request() {
    return { type: siftConstants.UPDATE_MARKER_REQ };
  }
  function success(response) {
    return { type: siftConstants.UPDATE_MARKER, response };
  }
}

function requestWeather(inputs) {
  return (dispatch) => {
    // dispatch(alertActions.loading('Pulling Weather Data'));
    dispatch(request(inputs));

    userService.runWeather(inputs).then(
      (response) => {
        // console.log(run_id);
        if (response.weather_id) {
          dispatch(siftActions.getWeatherData(response.weather_id, 0));
          dispatch(success(response.weather_id));
        } else {
          dispatch(alertActions.error("Error contacting weather service."));
          dispatch(failure("Error contacting weather service."));
        }
        // dispatch(alertActions.loading('Retrieving Weather Data'));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: siftConstants.WEATHER_REQUEST };
  }
  function success(id) {
    return { type: siftConstants.WEATHER_SUCCESS, id };
  }
  function failure(error) {
    return { type: siftConstants.WEATHER_FAILURE, error };
  }
}

function getWeatherData(id, count) {
  return (dispatch) => {
    userService.getWeather(id).then(
      (response) => {
        if (response.code == 99) {
          if (count > 200) {
            dispatch(alertActions.error("Weather Sevice timed out, try again."));
            dispatch(failure("Weather Sevice timed out, try again."));
          } else {
            setTimeout(dispatch(siftActions.getWeatherData(id, count + 1)), 300);
          }
        } else {
          dispatch(success(response.data));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function success(data) {
    return { type: siftConstants.WEATHER_DATA_SUCCESS, data };
  }
  function failure(error) {
    return { type: siftConstants.WEATHER_DATA_FAILURE, error };
  }
}

function getWeatherDataThenInitSIFT(inputs, count) {
  return (dispatch) => {
    userService.getWeather(inputs.performance.weather).then(
      (response) => {
        if (response.code == 99) {
          if (count > 450) {
            dispatch(alertActions.error("Weather Sevice timed out, try again."));
            dispatch(failure("Weather Sevice timed out, try again."));
          } else {
            setTimeout(dispatch(siftActions.getWeatherDataThenInitSIFT(inputs, count + 1)), 300);
          }
        } else {
          dispatch(success(response.data));
          // console.log(inputs)
          dispatch(siftActions.initSIFT(inputs));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function success(data) {
    return { type: siftConstants.WEATHER_DATA_SUCCESS, data };
  }
  function failure(error) {
    return { type: siftConstants.WEATHER_DATA_FAILURE, error };
  }
}

function clearWeather() {
  return {
    type: siftConstants.CLEAR_WEATHER,
  };
}

function updateTopo(key, value) {
  return {
    type: siftConstants.UPDATE_TOPO,
    key,
    value,
  };
}

function clearTopo() {
  return {
    type: siftConstants.CLEAR_TOPO_DATA,
  };
}

function getTopoId(inputs) {
  return (dispatch) => {
    dispatch(request()),
      siftService.getTopoId(inputs).then(
        (response) => {
          dispatch(success(response, inputs.bbox));
          dispatch(siftActions.getTopoData(response.topo_id));
        },
        (error) => dispatch(failure(error))
      );
  };

  function request() {
    return { type: siftConstants.GET_TOPO_ID_REQUEST };
  }
  function success(data, topo_bbox) {
    return { type: siftConstants.GET_TOPO_ID_SUCCESS, data, topo_bbox };
  }
  function failure(error) {
    return { type: siftConstants.GET_TOPO_ID_FAILURE, error };
  }
}

function getTopoData(id) {
  return (dispatch) => {
    dispatch(request()),
      siftService.checkTopoId(id).then((response) => {
        if (response.code == 99) {
          setTimeout(dispatch(siftActions.getTopoData(id)), 1000);
        } else if (response.code == 97) {
          dispatch(failure({ error: "cannot connect" }));
        } else if (response.code == 100) {
          dispatch(success(response));
        }
      });
  };

  function request() {
    return { type: siftConstants.GET_TOPO_DATA_REQUEST };
  }
  function success(data) {
    return { type: siftConstants.GET_TOPO_DATA_SUCCESS, data };
  }
  function failure(error) {
    return { type: siftConstants.GET_TOPO_DATA_FAILURE, error };
  }
}

function runTutorial(step = undefined) {
  return (dispatch) => {
    if (step == undefined) dispatch(request());

    // request the tutorial project from the backend
    userService.getTutorial().then(
      (response) => {
        // Load the project into ioManager, similar to loading a project
        dispatch(success(response));

        // now run init SIFT call to run the project
        if (step == undefined) {
          dispatch(siftActions.getRunId(false));

          setTimeout(() => dispatch(complete()), 300);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: siftConstants.GET_TUTORIAL_REQUEST };
  }
  function success(tutorialProject) {
    return { type: siftConstants.GET_TUTORIAL_SUCCESS, tutorialProject, step };
  }
  function complete() {
    return { type: siftConstants.GET_TUTORIAL_COMPLETE };
  }
  function failure() {
    return { type: siftConstants.GET_TUTORIAL_FAILURE };
  }
}

function toggleCollabModal(toggle = true, projectId = undefined) {
  return (dispatch) => {
    dispatch(update(toggle, projectId));
  };
  function update(toggle, projectId) {
    return { type: siftConstants.TOGGLE_COLLAB_MODAL, toggle, projectId };
  }
}
function shareCollab(inputs, userList = undefined) {
  return (dispatch) => {
    dispatch(request(inputs));
    siftService.shareCollab(inputs).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
    // if (isUndefined(userList) || (userList && userList.length < 50)) {
    //   siftService.shareCollab(inputs).then(
    //     (response) => {
    //       dispatch(success(response));
    //     },
    //     (error) => {
    //       dispatch(failure(error.toString()));
    //     }
    //   );
    // } else {
    //   dispatch(failure("Your request could not be fulfilled as you have reached the limit of 10 collaborators per project."));
    // }
  };

  function request(inputs) {
    return { type: siftConstants.SHARE_COLLAB_REQUEST, inputs };
  }
  function success(response) {
    return { type: siftConstants.COLLAB_SUCCESS, response };
  }
  function failure(error) {
    return { type: siftConstants.COLLAB_FAILURE, error };
  }
}
function checkCollab(inputs) {
  return (dispatch) => {
    dispatch(request(inputs));
    if (inputs.checkin) {
      // checking in, save this project first
      userService.saveProject(inputs.project).then(
        setTimeout(() => {
          siftService.shareCollab(inputs).then(
            (response) => {
              dispatch(success(response));
            },
            (error) => {
              dispatch(failure(error.toString()));
            }
          );
        }, 500)
      );
    } else {
      dispatch(clear());
      setTimeout(() => {
        dispatch(request_load(inputs.project_id));

        setTimeout(() => {
          // checking out, super simple
          siftService.shareCollab(inputs).then(
            (share_response) => {
              // console.log(share_response)
              userService.getProjectById(share_response.updated_project.project, 1).then(
                (response) => {
                  // console.log(share_response.updated_project.project, response)
                  dispatch(downloaded(response, share_response.updated_project.project));
                  setTimeout(() => {
                    dispatch(success(share_response));
                    dispatch(complete_load());
                  }, 500);
                },
                (error) => {
                  dispatch(failed(error));
                }
              );
            },
            (error) => {
              dispatch(failure(error.toString()));
            }
          );
        }, 500);
      }, 1000);
    }
  };

  function request(inputs) {
    return { type: siftConstants.CHECK_COLLAB_REQUEST, inputs };
  }
  function success(response) {
    return { type: siftConstants.COLLAB_SUCCESS, response };
  }
  function failure(error) {
    return { type: siftConstants.COLLAB_FAILURE, error };
  }

  function clear() {
    return { type: siftConstants.CLEAR_PROJECT };
  }

  function request_load(id) {
    return { type: siftConstants.LOAD_PROJECT_REQUEST, id };
  }
  function downloaded(response, id) {
    return { type: siftConstants.LOAD_PROJECT_DOWNLOADED, response, id };
  }
  function complete_load() {
    return { type: siftConstants.LOAD_PROJECT_COMPLETE };
  }
}
function forceCheckInCollab(inputs) {
  return (dispatch) => {
    dispatch(request(inputs));
    siftService.shareCollab(inputs).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request(inputs) {
    return { type: siftConstants.CHECK_COLLAB_REQUEST, inputs };
  }
  function success(response) {
    return { type: siftConstants.COLLAB_SUCCESS, response };
  }
  function failure(error) {
    return { type: siftConstants.COLLAB_FAILURE, error };
  }
}

function refreshCollab(project_id) {
  return (dispatch) => {
    dispatch(request());
    siftService.refreshCollab(project_id).then((response) => dispatch(success(response)));
  };

  function request() {
    return { type: siftConstants.REFRESH_COLLAB_REQUEST };
  }
  function success(response) {
    return { type: siftConstants.REFRESH_COLLAB_SUCCESS, response };
  }
}

function toggleAccountModal(bool = true) {
  return {
    type: siftConstants.TOGGLE_ACCOUNT_MODAL,
    bool,
  };
}
function clearErrors() {
  return {
    type: siftConstants.CLEAR_ERRORS,
  };
}

function updateScreenWidth(width) {
  return {
    type: siftConstants.UPDATE_SCREEN_WIDTH,
    width,
  };
}

function toggleMobileInputs(bool) {
  return {
    type: siftConstants.TOGGLE_MOBILE_INPUTS,
    bool,
  };
}

function runTopo(inputs, action) {
  return (dispatch) => {
    dispatch(request(inputs)),
      siftService
        .runTopo(inputs)
        .then((response) => pollTopoUpdate(response["topo_id"]))
        .then((response) => dispatch(success(response, action, inputs)));
  };

  function request(inputs) {
    return { type: siftConstants.GET_TOPO_DATA_REQUEST, inputs };
  }
  function success(data, action, inputs) {
    return { type: siftConstants.GET_TOPO_DATA_SUCCESS, data, action, inputs };
  }
  function failure(error) {
    return { type: siftConstants.GET_TOPO_DATA_ERROR, error };
  }
}

function updateTopoMode(data) {
  return { type: siftConstants.UPDATE_TOPO_MODE, data };
}

async function pollTopoUpdate(id) {
  let topo_id = id;
  var loopBool = true;
  let run_count = 0;

  // polling loop
  while (loopBool) {
    var topo_check = await siftService.checkTopoId2(topo_id);

    if (topo_check["code"] == 100) {
      loopBool = false;
      return {
        topo_id: topo_id,
        output: JSON.parse(topo_check.output),
      };
    } else if (topo_check["code"] == 97) {
      // ERROR
      loopBool = false;
    } else {
      setTimeout(async () => {}, 10000);
      run_count += 1;
      if (run_count > 10000) {
        // RUN COUNT REACHED
        loopBool = false;
      }
    }
  }

  return {
    topo_loading: false,
    error: {
      msg: "Error Connecting to Topography Server",
      code: 2,
    },
  };
}

function undoFeatures(undoState) {
  return {
    type: siftConstants.UNDO_FEATURES,
    undoState,
  };
}

function redoFeatures(redoState) {
  return {
    type: siftConstants.REDO_FEATURES,
    redoState,
  };
}

function pollForAlerts() {
  return (dispatch) => {
    // checking in, save this project first
    // // Piggybacking on the polling to make sure the user has agreed to the updated TaC
    // let tac = localStorage.getItem("TOS_Agreement");
    // if (tac == 0 || tac == null || tac == undefined || Math.sign(tac) == -1) {
    //   dispatch(authActions.showAgreementModal(true));
    // }

    siftService.pollForAlerts().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(success(error));
      }
    );
  };

  function success(response) {
    return { type: siftConstants.POLL_FOR_ALERTS, response };
  }
}

function prepareReport(reportData, preparingReport) {
  return {
    type: siftConstants.PREPARE_REPORT,
    reportData,
    preparingReport,
  };
}

function updateReportData(key, value = undefined, reportIsDone = undefined) {
  return {
    type: siftConstants.UPDATE_REPORT_DATA,
    key,
    value,
    reportIsDone,
  };
}
