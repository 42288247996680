import styled, { css } from "styled-components";

export const ToggleResultWrapper = styled.section`
  position: absolute;
  top: 10px;
  right: 70px;
  z-index: 1001;

  @media (max-width: 480px) {
    width: 85%;
  }

  .toggle-result {
    height: 100%;
    z-index: 1;
    display: grid;
    place-content: end;

    a {
      border-radius: 3px 3px 0px 0px;
      display: block;
      width: min-content;
      padding: 8px 5px;
      text-align: center;
    }
  }
`;

export const OutputPanelWrapper = styled.section`
  position: absolute;
  bottom: 0;
  padding-top: 5px;
  width: 100%;
  height: ${(props) => (props.detailsVisible ? (props.show_live_report ? "100vh" : "445px") : "0px")};
  /* height: ${(props) => (props.detailsVisible ? "445px" : "87px")}; */
  background-color: #fff0;
  z-index: 510;
  overflow-y: hidden;
  transition: all 0.3s var(--easing-spring);

  .output-header {
    width: 100%;
    height: 80px;
    padding-top: 5px;

    .complete {
      display: grid;
      grid-template-columns: 220px 200px auto;

      .meta-data {
        border-right: 1px solid rgb(160, 160, 160);
        overflow-x: hidden;
      }

      /* .selected-buttons {
        margin-left: 15px !important;
      } */
    }
  }
  .progress {
    height: 100%;
    background-color: #fff0;
    padding: 0px 50px 0px 50px;
    border-radius: 3px;

    .ant-steps {
      background-color: #fff;
      margin: 0 auto;
      padding: 0px 20px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  .output-data {
    width: ${(props) => (props.show_live_report ? "100%" : "95%")};
    height: ${(props) => (props.show_live_report ? "100vh" : "unset")};
    padding-top: 10px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;

    .ant-tabs {
      padding: 0px 5px !important;

      .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar {
        margin-right: 50px !important;
      }
    }

    .results-table-graph {
      display: grid;
      grid-template-columns: 440px auto;
    }

    .output-tabs {
      .tab-results {
        .output-graph {
          height: 100%;
          display: grid;
          place-items: center;
          border: 1px solid #aaa9aa;
          margin-right: 10px;
          border-radius: 3px;
          grid-area: results_graph;
          .result-graph-toggle {
            position: absolute;
            bottom: 10px;
            right: 10px;
          }
        }
      }
    }
  }
  .result-buttons-content {
    display: grid;
    grid-template-columns: auto 1fr;
    align-content: center;
    border-left: 1px solid #aaa9aa;
    height: 64px;
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 3px;
  }

  .output-detail {
    /* height: 415px; */
    padding: 5px 0px;
  }

  .tab-res {
    font-size: 9pt;
  }

  .tab-results {
    display: grid;

    ${({ show_live_report }) =>
      !show_live_report &&
      css`
        grid-template-columns: repeat(2, minmax(300px, 1fr));
        grid-template-rows: 60px 1fr;
        grid-auto-flow: dense;
        margin-bottom: 10px;
        grid-template-areas:
          "results_buttons results_graph"
          "results_table results_graph";
      `}

    ${({ show_live_report }) =>
      show_live_report &&
      css`
        height: 100vh;
        grid-template-columns: 1fr;
        grid-template-rows: min-content 1fr;
        grid-auto-flow: dense;
        margin-bottom: 10px;
        grid-template-areas:
          "results_buttons"
          "live_report";
      `}

    .results-buttons {
      grid-area: results_buttons;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: 1fr;
      padding: 5px;

      .report-button {
        margin-top: 5px;
      }

      .report-download-button {
        margin: 5px 0px 0px 10px;
      }

      .all-results {
        margin-left: 10px;
        .all-results-header {
          p {
            margin: 0px;
          }
        }
      }
    }
  }

  .result-holder {
    overflow-y: auto;
    height: 300px;
    border: 1px solid #aaa9aa;
    margin: 0px 10px;
    border-radius: 3px;
    grid-area: results_table;
  }

  @media (max-width: 1200px) {
    overflow-y: auto;

    .tab-results {
      padding: 0px;
      grid-gap: 20px;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        "results_buttons"
        "results_table"
        "results_graph";
    }
    .output-tabs {
      .tab-results {
        .output-graph {
          border: 1px solid #aaa9aa;
          margin-left: 10px;
        }
      }
    }

    .result-holder {
      border: 1px solid #aaa9aa;
      height: 200px;
    }
  }
`;
