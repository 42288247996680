import React, { useState, useEffect } from 'react';

import DragDiv from './DragDiv';

// styles
import { LayerToggleMenuWrap, ToggleButton } from './styles';

//asset
import { close } from '../../assets/images';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { siftActions } from '../';

const LayerToggleMenu = () => {
  const layer_toggle = useSelector((state) => state.sift.ioManager.uiState.layer_toggle);
  const [translate, setTranslate] = useState({
    x: 0,
    y: 0,
  });

  const handleDragMove = (e) => {
    setTranslate({
      x: translate.x + e.movementX,
      y: translate.y + e.movementY,
    });
  };

  const dispatch = useDispatch();

  const updateLayerInput = (key, value) => {
    dispatch(siftActions.updateInput('layer_visiblity', { key, value }));
  };

  return (
    <DragDiv onDragMove={handleDragMove}>
      <LayerToggleMenuWrap
        id="layer_menu"
        style={{
          transform: `translateX(${translate.x}px) translateY(${translate.y}px)`,
        }}
      >
        <div className="layers_menu_header">
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 1.55556C0 2.41467 0.651222 3.11111 1.45455 3.11111C2.25787 3.11111 2.90909 2.41467 2.90909 1.55556C2.90909 0.696446 2.25787 0 1.45455 0C0.651222 0 0 0.696446 0 1.55556ZM1.45455 8.55556C0.651222 8.55556 0 7.85912 0 7.00001C0 6.1409 0.651222 5.44445 1.45455 5.44445C2.25787 5.44445 2.90909 6.1409 2.90909 7.00001C2.90909 7.85912 2.25787 8.55556 1.45455 8.55556ZM1.45455 14C0.651222 14 0 13.3035 0 12.4444C0 11.5853 0.651222 10.8889 1.45455 10.8889C2.25787 10.8889 2.90909 11.5853 2.90909 12.4444C2.90909 13.3035 2.25787 14 1.45455 14Z"
              fill="#555555"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.09094 1.55556C5.09094 2.41467 5.74216 3.11111 6.54549 3.11111C7.34881 3.11111 8.00003 2.41467 8.00003 1.55556C8.00003 0.696446 7.34881 0 6.54549 0C5.74216 0 5.09094 0.696446 5.09094 1.55556ZM6.54549 8.55556C5.74216 8.55556 5.09094 7.85912 5.09094 7.00001C5.09094 6.1409 5.74216 5.44445 6.54549 5.44445C7.34881 5.44445 8.00003 6.1409 8.00003 7.00001C8.00003 7.85912 7.34881 8.55556 6.54549 8.55556ZM6.54549 14C5.74216 14 5.09094 13.3035 5.09094 12.4444C5.09094 11.5853 5.74216 10.8889 6.54549 10.8889C7.34881 10.8889 8.00003 11.5853 8.00003 12.4444C8.00003 13.3035 7.34881 14 6.54549 14Z"
              fill="#555555"
            />
          </svg>

          <img src={close} onClick={() => updateLayerInput('show_layer_toggle_menu', false)} />
        </div>
        <ToggleButton>
          <p>Boundaries</p>
          <label className="switch">
            <input type="checkbox" checked={layer_toggle.show_boundaries} onChange={(e) => updateLayerInput('show_boundaries', !layer_toggle.show_boundaries)} />
            <span className="slider round"></span>
          </label>
        </ToggleButton>
        <ToggleButton>
          <p>Exclusions</p>
          <label className="switch">
            <input type="checkbox" checked={layer_toggle.show_exclusions} onChange={(e) => updateLayerInput('show_exclusions', !layer_toggle.show_exclusions)} />
            <span className="slider round"></span>
          </label>
        </ToggleButton>
        <ToggleButton>
          <p>Inactive</p>
          <label className="switch">
            <input type="checkbox" checked={layer_toggle.show_inactive} onChange={(e) => updateLayerInput('show_inactive', !layer_toggle.show_inactive)} />
            <span className="slider round"></span>
          </label>
        </ToggleButton>
        <ToggleButton>
          <p>Racks</p>
          <label className="switch">
            <input type="checkbox" checked={layer_toggle.show_racks} onChange={(e) => updateLayerInput('show_racks', !layer_toggle.show_racks)} />
            <span className="slider round"></span>
          </label>
        </ToggleButton>
        <ToggleButton>
          <p>Roads</p>
          <label className="switch">
            <input type="checkbox" checked={layer_toggle.show_roads} onChange={(e) => updateLayerInput('show_roads', !layer_toggle.show_roads)} />
            <span className="slider round"></span>
          </label>
        </ToggleButton>
        <ToggleButton>
          <p>Inverters</p>
          <label className="switch">
            <input type="checkbox" checked={layer_toggle.show_inverters} onChange={(e) => updateLayerInput('show_inverters', !layer_toggle.show_inverters)} />
            <span className="slider round"></span>
          </label>
        </ToggleButton>
      </LayerToggleMenuWrap>
    </DragDiv>
  );
};

export { LayerToggleMenu };
