import React, { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Input, Alert, Popover, Badge, Button, Spin, Radio } from "antd";

import { siftActions, isNumber } from "../../Redux";

import { ConfigWrapper } from "../StyledComponents";

import { InfoCircleOutlined } from "@ant-design/icons";
import { parse } from "vis-uuid";

const gcr_or_pitch = ["GCR", "Pitch (m)"];
const slider_or_fixed = ["Slider", "Range"];

const required_inputs = (module_rating, nameplate, modules_per_string) => (
  <div>
    <p>
      <Badge status={module_rating > 0 ? "success" : "error"} />
      Module Rating
    </p>
    <p>
      <Badge status={nameplate > 0 ? "success" : "error"} />
      Inverter Rating
    </p>
    <p>
      <Badge status={modules_per_string > 0 ? "success" : "error"} />
      Modules per String
    </p>
  </div>
);

//
const ConfigInputs = (props) => {
  const inputs = useSelector((state) => state.sift.ioManager.inputs.config.data);
  const tab = useSelector((state) => state.sift.ioManager.uiState.tab);
  const module_rating = useSelector((state) => state.sift.ioManager.inputs.module.data.rating);
  const nameplate = useSelector((state) => state.sift.ioManager.inputs.inverter.data.inverterRating);
  const modules_per_string = useSelector((state) => state.sift.ioManager.inputs.performance.data.modules_per_string);

  const account_loading = useSelector((state) => state.account.account_loading);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const saving = account_loading || input_loading;

  const spi_range_min = useSelector((state) => state.sift.ioManager.inputs.config.data.spi_range[0]);
  const spi_range_max = useSelector((state) => state.sift.ioManager.inputs.config.data.spi_range[1]);

  const [stringsPerInverterMin, setStringPerInverterMin] = useState(spi_range_min);
  const [stringsPerInverterMax, setStringPerInverterMax] = useState(spi_range_max);

  const [rangeError, setRangeError] = useState(false);
  const [errMessage, setErrMessage] = useState(undefined);

  const dispatch = useDispatch();

  // const updateInput = debounce((key, value) => {
  //   console.log(key, value);
  //   dispatch(siftActions.updateInput(key, value))

  // }, 200);
  function OnUpdateInput(key, value) {
    dispatch(siftActions.updateInput(key, value));
  }

  function handleToggleGCRPitch() {
    OnUpdateInput("selected_gcr_pitch", inputs["selected_gcr_pitch"] == 0 ? 1 : 0);
  }

  function handleToggleSliderFixed() {
    OnUpdateInput("selected_slider_fixed", inputs["selected_slider_fixed"] == 0 ? 1 : 0);
  }

  function changeSPIRange(minOrMax, e) {
    let changed_value = parseInt(e.target.value);
    if (minOrMax == "min") {
      setStringPerInverterMin(e.target.value);
      if (!isNumber(changed_value)) return;
      OnUpdateInput("spi_range", [changed_value, parseInt(stringsPerInverterMax)]);
    } else {
      setStringPerInverterMax(e.target.value);
      if (!isNumber(changed_value)) return;
      OnUpdateInput("spi_range", [parseInt(stringsPerInverterMin), changed_value]);
    }
  }

  function correctInputRange(value, min, max) {
    if (value < min) {
      //value is below min
    } else if (value > max) {
      //value is above max
    } else {
      //everything checks out
    }
  }

  function checkInputRange(value, min, max, msg) {
    if (value < min || !isNaN(value)) {
      //value is below min
      setRangeError(true);
      setErrMessage(msg);
    } else if (value > max || !isNaN(value)) {
      //value is above max
      setRangeError(true);
      setErrMessage(msg);
    } else {
      //everything checks out
      setRangeError(false);
      setErrMessage(undefined);
    }
  }

  useEffect(() => {
    dispatch(siftActions.updateInput("recalc", null));
  }, [tab]);

  return (
    <ConfigWrapper>
      {saving ? (
        <div className="spinner">
          <Spin style={{ padding: "0px" }} size="large" spinning={saving} className="sift-loader" />
        </div>
      ) : (
        <div className="config-inputs" id="config-tip">
          {/* <div className="config-header">
            <label>
              When your site and inputs are prepared, click Run 
              SIFT and each {inputs.selected_gcr_pitch==0?"GCR":"Pitch"} and DC:AC combination will be evaluated.
            </label>
          </div> */}

          <div className="config-input-label">
            <Radio.Group buttonStyle="solid" size="small" value={inputs["selected_gcr_pitch"]} onChange={(event) => handleToggleGCRPitch()}>
              <Radio.Button value={0}>GCR</Radio.Button>
              <Radio.Button value={1}>Pitch</Radio.Button>
            </Radio.Group>
          </div>
          {rangeError && (
            <div className="config-err">
              <InfoCircleOutlined style={{ color: "#002bcb", fontSize: "12px" }} />
              <p>{errMessage}</p>
            </div>
          )}
          <div className="config-input">
            {inputs.selected_gcr_pitch == 0 && (
              <Fragment>
                <div className="min">
                  <label>Min</label>
                  <Input
                    style={{ width: "75px" }}
                    id="gcr_range[0]"
                    size="small"
                    type="number"
                    step={inputs.gcr_step}
                    // min={0.2}
                    // max={100000.0}
                    // defaultValue={inputs.gcr_range[0]}
                    value={inputs.gcr_range[0]}
                    onBlur={(e) => {
                      if (e.target.value < 0.2) {
                        OnUpdateInput("gcr_range", [0.2, inputs.gcr_range[1]]);
                      } else if (e.target.value > inputs.gcr_range[1]) {
                        OnUpdateInput("gcr_range", [inputs.gcr_range[1], inputs.gcr_range[1]]);
                      } else if (e.target.value > inputs.gcr_range[1]) {
                        OnUpdateInput("gcr_range", [inputs.gcr_range[0], e.target.value]);
                      } else if (isNaN(parseFloat(e.target.value))) {
                        OnUpdateInput("gcr_range", [0.2, inputs.gcr_range[1]]);
                      }
                      setRangeError(false);
                      setErrMessage(undefined);
                    }}
                    onChange={(e) => {
                      if (e.target.value.length < 6) {
                        checkInputRange(e.target.value, 0.2, inputs.gcr_range[1], `Min value should be between 0.2 - ${inputs.gcr_range[1]}`);
                        OnUpdateInput("gcr_range", [e.target.value, inputs.gcr_range[1]]);
                      }
                    }}
                  />
                </div>
                <div className="dash">
                  <span>-</span>
                </div>
                <div className="max">
                  <label>Max</label>
                  <Input
                    style={{ width: "75px" }}
                    id="gcr_range[1]"
                    size="small"
                    type="number"
                    step={inputs.gcr_step}
                    // min={0.0}
                    // max={100000.0}
                    // max={1.0}
                    // defaultValue={inputs.gcr_range[1]}
                    value={inputs.gcr_range[1]}
                    onBlur={(e) => {
                      if (e.target.value > 1.0) {
                        OnUpdateInput("gcr_range", [inputs.gcr_range[0], 1.0]);
                      } else if (e.target.value < 0) {
                        OnUpdateInput("gcr_range", [inputs.gcr_range[0], Math.max(e.target.value, inputs.gcr_range[0])]);
                      } else if (isNaN(e.target.value) || e.target.value < 0.2 || e.target.value < inputs.gcr_range[0]) {
                        OnUpdateInput("gcr_range", [inputs.gcr_range[0], inputs.gcr_range[0]]);
                      }

                      setRangeError(false);
                      setErrMessage(undefined);
                    }}
                    onChange={(e) => {
                      if (e.target.value.length < 6) {
                        checkInputRange(e.target.value, 0, 1.0, `Max value should be between 0.0 - 1.0`);
                        OnUpdateInput("gcr_range", [inputs.gcr_range[0], e.target.value]);
                      }
                    }}
                  />
                </div>
                <div className="comma">
                  <span>,</span>
                </div>
                <div className="increment">
                  <label>Step</label>
                  <Input
                    style={{ width: "60px" }}
                    id="gcr_step"
                    size="small"
                    type="number"
                    step={0.01}
                    min={0.01}
                    max={0.1}
                    value={inputs.gcr_step}
                    // onChange={(value)=> {OnUpdateInput('gcr_step', value)}}
                    onChange={(e) => {
                      OnUpdateInput("gcr_step", e.target.value);
                    }}
                  />
                </div>
                <div className="total">
                  <p>{inputs.gcr_count}</p>
                  <label>Total</label>
                </div>
              </Fragment>
            )}
            {inputs.selected_gcr_pitch == 1 && (
              <Fragment>
                <div className="min">
                  <label>Min</label>
                  <Input
                    style={{ width: "75px" }}
                    id="pitch_range[0]"
                    size="small"
                    type="number"
                    step={0.01}
                    suffix={<span style={{ fontSize: "12px" }}>m</span>}
                    // min={inputs.pitch_min_max[0]}
                    // max={inputs.pitch_min_max[1]}
                    // defaultValue={inputs.pitch_range[0]}
                    value={inputs.pitch_range[0]}
                    onBlur={(e) => {
                      if (e.target.value > inputs.pitch_range[1]) {
                        OnUpdateInput("pitch_range", [inputs.pitch_range[1], inputs.pitch_range[1]]);
                      } else if (e.target.value < inputs.pitch_min_max[0]) {
                        OnUpdateInput("pitch_range", [inputs.pitch_min_max[0], inputs.pitch_range[1]]);
                      }
                      setRangeError(false);
                      setErrMessage(undefined);
                    }}
                    onChange={(e) => {
                      if (e.target.value.length < 6) {
                        checkInputRange(
                          e.target.value,
                          inputs.pitch_min_max[0],
                          inputs.pitch_min_max[1],
                          `Min value should be between ${inputs.pitch_min_max[0].toFixed(4)} - ${inputs.pitch_min_max[1].toFixed(4)}`
                        );
                        OnUpdateInput("pitch_range", [e.target.value, inputs.pitch_range[1]]);
                      }
                    }}
                  />
                </div>
                <div className="dash">
                  <span>-</span>
                </div>
                <div className="max">
                  <label>Max</label>
                  <Input
                    style={{ width: "75px" }}
                    id="pitch_range[1]"
                    size="small"
                    type="number"
                    step={0.01}
                    suffix={<span style={{ fontSize: "12px" }}>m</span>}
                    // min={inputs.pitch_min_max[0]}
                    // max={inputs.pitch_min_max[1]}
                    // defaultValue={inputs.pitch_range[1]}
                    value={inputs.pitch_range[1]}
                    onBlur={(e) => {
                      if (e.target.value > inputs.pitch_min_max[1]) {
                        OnUpdateInput("pitch_range", [inputs.pitch_range[0], inputs.pitch_min_max[1]]);
                      } else if (e.target.value < inputs.pitch_min_max[0] || e.target.value < inputs.pitch_range[0] || !isNaN(e.target.value)) {
                        OnUpdateInput("pitch_range", [inputs.pitch_range[0], Math.max(inputs.pitch_range[0], e.target.value)]);
                      }
                      setRangeError(false);
                      setErrMessage(undefined);
                    }}
                    onChange={(e) => {
                      if (e.target.value.length < 6) {
                        checkInputRange(
                          e.target.value,
                          inputs.pitch_min_max[0],
                          inputs.pitch_min_max[1],
                          `Max value should be between ${inputs.pitch_min_max[0].toFixed(4)} - ${inputs.pitch_min_max[1].toFixed(4)}`
                        );
                        OnUpdateInput("pitch_range", [inputs.pitch_range[0], e.target.value]);
                      }
                    }}
                  />
                </div>
                <div className="comma">
                  <span>,</span>
                </div>
                <div className="increment">
                  <label>Step</label>
                  <Input
                    style={{ width: "60px" }}
                    id="pitch_step"
                    size="small"
                    type="number"
                    step={0.01}
                    min={0.01}
                    max={10}
                    value={inputs.pitch_step}
                    defaultValue={inputs.pitch_step}
                    onChange={(e) => {
                      OnUpdateInput("pitch_step", e.target.value);
                    }}
                  />
                </div>
                <div className="total">
                  <p>{inputs.pitch_count}</p>
                  <label>Total</label>
                </div>
              </Fragment>
            )}
          </div>

          <div style={{ height: 10 }}></div>

          <div className="config-input-label">
            <label>Strings Per Inverter</label>
          </div>
          <div className={`config-input ${stringsPerInverterMin <= 0 && stringsPerInverterMax <= 0 ? "error" : ""}`}>
            {true ? (
              <Fragment>
                <div className="min">
                  <label>Min</label>
                  <Input
                    id="spi_range[0]"
                    // style={{ width: "75px" }}
                    step={1}
                    type="number"
                    size="small"
                    // min={stringsPerInverterMin}
                    min="1"
                    max={stringsPerInverterMax}
                    // defaultValue={stringsPerInverterMin}
                    value={stringsPerInverterMin}
                    onChange={(e) => {
                      changeSPIRange("min", e);
                    }}
                  />
                </div>
                <div className="dash">
                  <span>-</span>
                </div>
                <div className="max">
                  <label>Max</label>
                  <Input
                    id="spi_range[1]"
                    // style={{ width: "75px" }}
                    step={1}
                    type="number"
                    size="small"
                    min={stringsPerInverterMin}
                    // max={stringsPerInverterMax}
                    value={stringsPerInverterMax}
                    // defaultValue={stringsPerInverterMax}
                    onChange={(e) => {
                      changeSPIRange("max", e);
                      // OnUpdateInput("spi_range", [inputs.spi_range[0], parseInt(e.target.value)]);
                    }}
                  />
                </div>
                <div className="comma">
                  <span>,</span>
                </div>
                <div className="increment">
                  <label>Step</label>
                  <Input
                    style={{ width: "60px" }}
                    id="spi_step"
                    type="number"
                    size="small"
                    step={1}
                    min={1}
                    max={50}
                    value={inputs.spi_step}
                    onChange={(e) => {
                      OnUpdateInput("spi_step", parseInt(e.target.value));
                    }}
                  />
                </div>
                <div className="total">
                  <p>{inputs.spi_count}</p>
                  <label>Total</label>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <Alert
                  className="sift-alert"
                  message={
                    <span>
                      <Popover content={required_inputs(module_rating, nameplate, modules_per_string)} trigger="hover" placement="bottomLeft">
                        Missing Required Inputs
                      </Popover>
                    </span>
                  }
                  type="warning"
                  showIcon
                />
              </Fragment>
            )}
          </div>
          <div className="spi-total">
            <div className="dc-ac">
              <p>DC:AC:</p>
              <span>
                {inputs.dcac_range[0]} - {inputs.dcac_range[1]}
              </span>
            </div>
            {/* <div className="spi-count">
              <p>Total:</p>
              <span>{inputs.spi_count}</span>
            </div> */}
          </div>

          <section className="config-row">
            <section className="config-info-msg">
              {inputs.worker_count > inputs.worker_limit && (
                <div className="config-err">
                  <InfoCircleOutlined style={{ color: "#002bcb", fontSize: "12px" }} />
                  <p>{`Limit exceeded. Please adjust inputs`}</p>
                </div>
              )}
            </section>
            <section className="config-limit">
              <p className="underline">{`= ${inputs.worker_count}`}</p>
              <p>{`${inputs.worker_limit} Limit`}</p>
            </section>
            {/* <span>
              Total {inputs.selected_gcr_pitch == 0 ? 'GCR' : 'Pitch'} Configurations: {inputs.selected_gcr_pitch == 0 ? inputs.gcr_count : inputs.pitch_count}
            </span>
            <span style={{ borderBottom: '1px solid #a7a6a6' }}>Total DC:AC Configurations: {inputs.spi_count}</span> */}

            {/* <p>
              <span>{inputs.worker_count}</span> {`(${inputs.worker_limit} Limit per Run)`}
            </p> */}
          </section>
        </div>
      )}
    </ConfigWrapper>
  );
};

export { ConfigInputs };
