import styled from "styled-components";

const LiveReportLayout = styled.section`
  width: 100%;
  height: calc(100% - 50px);
  background: #dadada;
  padding: 5px 40px;
  display: grid;
  overflow-y: auto;
  grid-template-rows: repeat(2, auto);

  .page-wrap {
    height: 1200px;
    width: 900px;
    margin-bottom: 15px;
    display: grid;
    place-self: center;
    padding: 10px;
    background: #fff;

    .page {
      height: 100%;
      width: 100%;
      display: grid;
      border: 1px solid rgba(0, 0, 0, 0.9);
      grid-template-rows: min-content 1fr min-content;
      grid-template-areas:
        "report-header"
        "report-content"
        "report-footer";
    }

    .report-loader {
      grid-area: report-header;
      width: 100%;
      display: grid;
      place-items: center;
    }

    .report-rundown {
      grid-area: report-content;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, min-content) 1fr;
      grid-template-areas:
        "report-title report-title"
        "info-boxes vicinity-map"
        "layout-map layout-map";
    }

    .vicinity-map {
      grid-area: vicinity-map;
      padding: 10px;
    }

    .layout-map {
      grid-area: layout-map;
      padding: 10px;
    }

    .large-layout-map {
      grid-area: report-content;
      padding: 10px;
    }

    .report-content {
      grid-area: report-content;
      padding: 10px;
    }

    /* this is a generic content page layout */
    .page-content {
      grid-area: report-content;
      padding: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content min-content 1fr;
      grid-column-gap: 20px;
      grid-template-areas:
        "title title"
        "topLeft topRight"
        "bottomLeft bottomRight";
    }

    .title {
      grid-area: title;

      h3 {
        margin: 0px;
      }
    }
    .topLeft {
      grid-area: topLeft;
    }

    .topRight {
      grid-area: topRight;
    }
    .bottomLeft {
      grid-area: bottomLeft;
    }
    .bottom-right {
      grid-area: bottomRight;
    }

    .left-column {
      padding: 10px;
      grid-column: 1;
      grid-row: 2 / -1;
    }
    .right-column {
      padding: 10px;
      grid-column: 2;
      grid-row: 2 / -1;
    }

    .bifacial-column {
      padding: 148px 10px 0px 10px;
      grid-column: 2;
      grid-row: 2 / -1;
    }

    .bottomLeft-and-bottomRight {
      margin-top: 20px;
      grid-row: 3;
      grid-column: 1 / -1;
    }

    .topLeft-perf-table {
      grid-area: topLeft;
      width:75%;
    }

    .module-content {
      grid-area: report-content;
      padding: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, min-content);
      grid-template-areas:
        "title title"
        "module-table iam-graph"
        "module-table iam-table";
    }

    .module-table {
      grid-area: module-table;
    }

    .iam-graph {
      grid-area: iam-graph;
    }
    .iam-table {
      grid-area: iam-table;
      max-width: 175px;
      margin-left: auto;
    }

    .perf-results {
      grid-area: report-content;
      padding: 10px;
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: repeat(3, min-content) 1fr;
      gap: 10px 5px;
      grid-template-areas:
        "title title "
        "perf-table wind-graph "
        "perf-table eArray-graph"
        "perf-graph perf-graph";
    }

    .perf-table {
      grid-area: perf-table;
    }
    .wind-graph {
      grid-area: wind-graph;
    }
    .eArray-graph {
      grid-area: eArray-graph;
    }
    .perf-graph {
      grid-area: perf-graph;
      place-self: center;
    }

    .topo-content {
      grid-area: report-content;
      padding: 10px;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      gap: 15px 0px;
    }

    .topo-container {
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-template-rows: min-content 1fr;
      grid-template-areas:
        "topo-title topo-title"
        "topo-map topo-scale";
    }
    .topo-title {
      grid-area: topo-title;
    }
    .topo-map {
      grid-area: topo-map;
    }
    .topo-scale {
      grid-area: topo-scale;
      display: grid;
      place-content: center;
    }
  }
`;

export { LiveReportLayout };
