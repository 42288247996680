import { accountConstants } from '../_constants';

const initial_state ={
  error: undefined,
  loading: false,
  funnel_visible: false,
}

export function billing(state = initial_state, action) {
  switch (action.type) {

    case accountConstants.ADDPAYMENT_REQUEST:
        return { ...state, loading: true};

    case accountConstants.ADDPAYMENT_SUCCESS:
        return {...state, loading:false}

    case accountConstants.ADDPAYMENT_FAILURE:
        return { 
          ...state,
          error: action.error,
          loading:false
        };    

    case accountConstants.FUNNEL_VISIBILITY_TOGGLE:
      return { ...state, funnel_visible: action.value}

    case accountConstants.UPDATESUB_REQUEST:

      return {
        ...state,
        loading: true
      }
    case accountConstants.UPDATESUB_SUCCESS:

      return {
        ...state,
        loading: false,
        funnel_visible: false,

      }
    case accountConstants.UPDATESUB_FAILURE:

      return {
        ...state,
        loading: false,
        error: "Error updating plan."
      }


    default:
      return state
  }
}