import React, { Fragment } from "react";

// redux
import { connect } from "react-redux";
import { isAuthenticated, siftActions } from "../Redux";

// components
import { Modal, Button, Input, Menu, Dropdown, Table, Popconfirm, Empty, Spin, message, Checkbox, Tooltip, Popover } from "antd";
const { TextArea } = Input;
import {
  SaveOutlined,
  PlusOutlined,
  EllipsisOutlined,
  FolderOpenOutlined,
  BookOutlined,
  UserAddOutlined,
  LockOutlined,
  ReloadOutlined,
  CloudUploadOutlined,
  CloseSquareFilled,
  CloudOutlined,
  ExclamationCircleFilled,
  CloudDownloadOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { EditableCell, EditableRow } from "./EditableTable";
import matchSorter from "match-sorter";

import { LockedByYou, LockedBySomeone, NotLocked, collab_columns, notes_columns } from "./CollabAssets";
import { ValidateEmail, getTime } from "./helper";

//tutorial stuff
import { TutorialTip } from "../TutorialTip";

//
class ProjectManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterText: "",
      modal_type: "save",
      temp_projectName: "",
      temp_shareEmail: undefined,
      notify_users: true,

      project_sortedInfo: {
        field: "editDate",
        order: "descend",
      },
      collab_sortedInfo: {
        field: "posted_dt",
        order: "descend",
      },
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { collab_projectId } = prevProps;

    if (this.props.collab_projectId && this.props.localProjects[this.props.collab_projectId].collab_on && this.props.collab_projectId != collab_projectId) {
      // collab project was updated, call refresh

      this.handleRefreshProject();
    }
  }

  // this is called when finished editing a row's (projects) name
  handleSaveProjectName = (project) => {
    this.setState({ edit_project: undefined });
    this.props.dispatch(siftActions.saveProject(project));
  };
  handeUpdateProjectName = (project) => {
    this.setState({ edit_project: project });
  };
  handleProjectEdit(project) {
    if (project.editing == undefined || !project.editing) {
      project.editing = true;
      this.setState({ edit_project: project });
    } else {
      project.editing = false;
      this.handleSaveProjectName(this.state.edit_project);
    }
  }

  handleToolbarButtonPressed = (type) => {
    if (type == "collab") {
      this.props.dispatch(siftActions.toggleCollabModal());
      return;
    }

    if (type == "new") {
      // hitting new project
      this.props.dispatch(siftActions.newProject());
      return;
    }

    if (type == "save" && this.props.currentProjectId) {
      if (this.props.localProjects[this.props.currentProjectId].locked && !this.props.localProjects[this.props.currentProjectId].locked_by_me) {
        // someone has it checked-out, NOTIFICATION
        message.warning("Project not saved. Check Out for Read/Write", 2.5);
        return;
      }

      // hitting save with a project loaded
      this.handleSaveProject();
      return;
    }

    if (type == "checkin" || type == "checkout") {
      let projId = this.props.localProjects[this.props.currentProjectId].project;
      this.handleCheckProject(type == "checkin", projId);
      return;
    }

    // save+no project loaded, saveas, or load button pressed
    this.setState({ modal_type: type }, () => this.props.dispatch(siftActions.toggleProjectManagerModal(true)));
  };
  handleCloseModal = () => {
    this.props.dispatch(siftActions.toggleProjectManagerModal(false));
  };
  handleProjectNameUpdate = (value) => {
    this.setState({ temp_projectName: value });
  };
  handleProjectSelected(record) {
    if (record.project == this.props.selectedProjectId) {
      return;
    }
    this.props.dispatch(siftActions.selectProjectById(record.project));
  }
  handleLoadProject = (id) => {
    let collab_on = this.props.localProjects[id].collab_on ? 1 : 0;
    if (this.props.localProjects[id].collab_on) {
      if (!this.props.localProjects[id].locked) {
        // not checked-out, lets check it out
        this.handleCheckProject(false, id);
        message.warning("Project was automatically Checked Out. When finished, Check In.", 5);
      }
      if (this.props.localProjects[id].locked && !this.props.localProjects[id].locked_by_me) {
        // someone has it checked-out, NOTIFICATION
        message.warning(`Project Checked Out by ${this.props.localProjects[id].locked_by_email}. Project is Read Only.`, 5);
      }
    }

    this.props.dispatch(siftActions.loadProjectById(id, collab_on));
  };
  handleDeleteProject = (id) => {
    let del_proj = this.props.localProjects[id];
    if (del_proj.collab_on && !del_proj.creator) {
      message.error("Project can only be deleted by the owner", 5);
      return;
    }

    del_proj.active = 0;
    this.props.dispatch(siftActions.saveProject(del_proj));
  };

  getSaveProject = (saveas, _projectId = undefined) => {
    let projectId = _projectId || this.props.currentProjectId;

    return {
      // project Id drives whether it's a new/saveas vs just a save
      project: !saveas && projectId ? this.props.localProjects[projectId].project : undefined,
      // inputs: projectId ? this.props.localProjects[projectId].inputs : this.props.ioManager.inputs,
      inputs: { ...JSON.parse(JSON.stringify(this.props.ioManager.inputs)) },
      name: !saveas && projectId ? this.props.localProjects[projectId].name : this.state.temp_projectName,
      editDate: projectId ? this.props.localProjects[projectId].editDate : undefined,
      active: 1,

      // collab vars
      collab_on: saveas ? undefined : projectId && this.props.localProjects[projectId].collab_on,
      creator: !saveas && projectId && this.props.localProjects[projectId].creator,
      locked: !saveas && projectId && this.props.localProjects[projectId].locked,
      locked_by_me: !saveas && projectId && this.props.localProjects[projectId].locked_by_me,
      locked_by_email: !saveas && projectId && this.props.localProjects[projectId].locked_by_email,
      user_list: !saveas && projectId && this.props.localProjects[projectId].user_list,
    };
  };

  handleSaveProject = (saveas = false) => {
    // called by either save new project or save as,
    let save_project = this.getSaveProject(saveas);
    if (saveas) {
      save_project.project = undefined;
    }
    // console.log(save_project);
    this.props.dispatch(siftActions.saveProject(save_project));
    this.setState({ temp_projectName: undefined });
  };
  // PROJECT MANAGER FUNCTION
  handleFilter = (e) => {
    this.setState({ filterText: e.target.value });
  };
  // PROJECT MANAGER FUNCTION
  handleChangeSort = (pagination, filters, sorter) => {
    let _sorter = sorter;
    if (!sorter.order) {
      // interrupt the default functionality
      _sorter = this.state.project_sortedInfo;
      _sorter.order = "ascend";
    }
    this.setState({
      filteredInfo: filters,
      project_sortedInfo: _sorter,
    });
  };

  handleUpdateShareEmail = (value) => {
    this.setState({ temp_shareEmail: value, shareError: undefined });
  };
  hanldeUpdateNote = (value) => {
    this.setState({ check_in_note: value, shareError: undefined });
  };
  handleShareProject = () => {
    if (!ValidateEmail(this.state.temp_shareEmail)) {
      this.setState({ shareError: "You have entered an invalid email address!" });
      return;
    }

    if (this.state.temp_shareEmail.length > 0 && this.props.collab_projectId) {
      let inputs = {
        type: "share",
        project_id: this.props.collab_projectId,
        email: this.state.temp_shareEmail,
      };
      let userList = this.props.collab_projectId && this.props.localProjects[this.props.collab_projectId].user_list;

      this.props.dispatch(siftActions.shareCollab(inputs, userList));
      this.setState({ temp_shareEmail: undefined });
    } else {
      this.setState({ shareError: "Enter an email to share" });
    }
  };
  handleCheckProject = (checkin = false, id = undefined, do_save = true) => {
    let collab_id = id || this.props.collab_projectId;
    let inputs = {
      type: "checkinout",
      checkin: checkin,
      project_id: collab_id,
      project: checkin ? this.getSaveProject(false, collab_id) : undefined,
      note: this.state.check_in_note,
      notify: this.state.notify_users,
    };
    // if collab_id and currentProjectId don't match
    // 			this means the user has a project open, and launched the collaborate window
    // 			from the project table from a DIFFERENT project.
    // 		Doing this action will not load the checked-in project, but if checking out
    // 			the project that is open, it will automatically reload the project..
    // 		Not sure if we should load the project you are checking out if it's different..
    if (do_save && collab_id == this.props.currentProjectId) this.props.dispatch(siftActions.checkCollab(inputs));
    else this.props.dispatch(siftActions.forceCheckInCollab(inputs));

    this.setState({ check_in_note: undefined, shareError: undefined });
  };

  handleRefreshProject = () => {
    // console.log(this.props.collab_projectId);
    if (this.props.collab_projectId && this.props.localProjects[this.props.collab_projectId].collab_on) this.props.dispatch(siftActions.refreshCollab(this.props.collab_projectId));
  };

  toggleCollab = (toggle = true, projectId = undefined) => {
    this.props.dispatch(siftActions.toggleCollabModal(toggle, projectId));
  };

  render() {
    const { loading, loaded, modal_visible, collabVisible, localProjects, selectedProjectId, currentProjectId, isAuthenticated, collab_loading, collab_projectId } = this.props;

    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const current_project = currentProjectId && localProjects[currentProjectId];

    // console.log(localProjects,currentProjectId)
    const loadable = loading || (localProjects && Object.keys(localProjects).length == 0) || !isAuthenticated;
    const collab_locked = current_project && localProjects[currentProjectId].collab_on && !localProjects[currentProjectId].locked_by_me && localProjects[currentProjectId].locked;
    const usable = !isAuthenticated || loading;
    const can_save = !isAuthenticated || loading || collab_locked;

    // if (currentProjectId) console.log(localProjects[currentProjectId])
    const collab_project = collab_projectId && localProjects[collab_projectId];

    const collab_message =
      currentProjectId && current_project.collab_on && (current_project.locked_by_me ? LockedByYou : current_project.locked ? LockedBySomeone(current_project.locked_by_email) : NotLocked);
    const can_checkin = currentProjectId && current_project.collab_on && current_project.locked_by_me;
    const can_checkout = currentProjectId && current_project.collab_on && !current_project.locked;
    const cannot_save_collab = currentProjectId && current_project.collab_on && current_project.locked && !current_project.locked_by_me;
    const can_share = this.props.account.current_plan > 1;
    const is_collab = currentProjectId && current_project.collab_on;

    const menu = (
      <Menu onClick={({ item, key, keyPath, domEvent }) => this.handleToolbarButtonPressed(key)}>
        <Menu.Item disabled={usable} key="saveas">
          <SaveOutlined style={{ fontSize: "19px" }} />
          <span style={{ display: "inline-block" }}>Save As</span>
        </Menu.Item>
        <Menu.Item disabled={!can_checkin} key="checkin">
          <CloudUploadOutlined style={{ fontSize: "19px" }} />
          <span style={{ display: "inline-block" }}>Check In</span>
        </Menu.Item>
        <Menu.Item disabled={!can_checkout} key="checkout">
          <CloudDownloadOutlined style={{ fontSize: "19px" }} />
          <span style={{ display: "inline-block" }}>Check Out</span>
        </Menu.Item>
      </Menu>
    );

    const notes_columns = [
      {
        title: "Email",
        dataIndex: "email",
        width: "275px",
        sorter: (a, b) => a.email.length - b.email.length,
        sortDirections: ["descend"],
      },
      {
        title: "Note",
        dataIndex: "note",
        width: "275px",
      },
      {
        title: "Date",
        dataIndex: "posted_dt",
        width: "200px",
        render: (text, record) => <span>{getTime(record.posted_dt)}</span>,
        sorter: (a, b) => a.posted_dt - b.posted_dt,
        sortOrder: this.state.collab_sortedInfo.field === "posted_dt" && this.state.collab_sortedInfo.order,
      },
    ];

    return (
      <div className="pm-toolbar">
        <TutorialTip
          elementKey="project-manager-tip"
          customTipWidth={this.props.ioManager.uiState.screenWidth < 768 ? -340 : 290}
          visible={this.props.tutorial.visibleTips.project_manager}
          highlight={false}
          placement="bottom"
          content={this.props.tutorial.content && Object.values(this.props.tutorial.content)[this.props.tutorial.currentIndex].tip_text.project_manager}
        >
          <div className="pm-buttons" id="project-manager-tip" style={{ textAlign: "center", border: this.props.tutorial.visibleTips.project_manager ? "2px solid #002bcb" : "2px solid transparent" }}>
            <Button size="small" disabled={loading} style={{ marginRight: "5px" }} onClick={() => this.handleToolbarButtonPressed("new")}>
              <span>
                <PlusOutlined /> New
              </span>
            </Button>

            <Button.Group>
              <Tooltip
                title={
                  cannot_save_collab ? (
                    <span>
                      <ExclamationCircleFilled style={{ color: "#faad14", marginRight: "5px" }} /> Project is in a Read Only state. Check Out before editing and to save changes.
                    </span>
                  ) : null
                }
              >
                <Button size="small" loading={loading} disabled={usable || cannot_save_collab} id="save" onClick={() => this.handleToolbarButtonPressed("save")}>
                  <span>
                    <SaveOutlined /> Save
                  </span>
                </Button>
              </Tooltip>
              <Dropdown trigger={["click"]} overlay={menu} placement="bottomRight" disabled={usable}>
                <Button size="small" style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                  <EllipsisOutlined />
                </Button>
              </Dropdown>
            </Button.Group>

            <Button
              size="small"
              // TODO: FIX THIS AFTER SPI
              disabled={loadable}
              loading={loading}
              style={{ marginLeft: "5px" }}
              onClick={() => this.handleToolbarButtonPressed("load")}
            >
              <span>
                <FolderOpenOutlined /> Load
              </span>
            </Button>

            <Button
              size="small"
              // TODO: FIX THIS AFTER SPI
              disabled={loadable}
              // loading={loading}
              style={{ marginRight: "5px", marginLeft: "5px" }}
              onClick={() => this.handleToolbarButtonPressed("collab")}
            >
              <span>
                <CloudOutlined /> Collab
              </span>
            </Button>
          </div>
        </TutorialTip>

        <div className="pm-name">
          {/* // Only show project and map name if there is a project loaded (this is all optional) */}
          {currentProjectId && (
            <label>
              <Tooltip placement="bottom" title={localProjects[currentProjectId].name || localProjects[currentProjectId].projectName}>
                <span>{localProjects[currentProjectId].name || localProjects[currentProjectId].projectName}</span>
              </Tooltip>
            </label>
          )}

          {is_collab && <span onClick={() => this.props.dispatch(siftActions.toggleCollabModal())}>{collab_message}</span>}
        </div>

        <Modal
          title=""
          className="sift-modal"
          onCancel={this.handleCloseModal}
          width={this.state.modal_type == "save" || this.state.modal_type == "saveas" ? "20%" : "65%"}
          visible={modal_visible}
          footer={
            <div>
              {this.state.modal_type == "load" && (
                <span style={{ position: "absolute", left: "15px" }}>
                  <Input type="text" value={this.state.filterText} onChange={this.handleFilter} placeholder="Filter projects by name" style={{ width: "200px" }} />
                </span>
              )}

              <Button onClick={this.handleCloseModal} disabled={usable}>
                Close
              </Button>
              {this.state.modal_type == "save" || this.state.modal_type == "saveas" ? (
                <Button onClick={() => this.handleSaveProject(this.state.modal_type == "saveas")} loading={usable} type="primary">
                  Save
                </Button>
              ) : (
                <Button onClick={() => this.handleLoadProject(selectedProjectId)} loading={usable} type="primary">
                  Load
                </Button>
              )}
            </div>
          }
        >
          <div>
            {this.state.modal_type == "save" || this.state.modal_type == "saveas" ? (
              <Input
                id="project_name"
                disabled={usable}
                // className="login-input"
                ref={(input) => input && input.focus()}
                value={this.state.temp_projectName}
                prefix={<BookOutlined type="project" style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Enter project name."
                onChange={(e) => this.handleProjectNameUpdate(e.target.value)}
                onPressEnter={() => this.handleSaveProject(this.state.modal_type == "saveas")}
              />
            ) : (
              <div style={{ height: "445px" }}>
                <Table
                  loading={loading}
                  dataSource={
                    matchSorter(Object.values(localProjects), this.state.filterText, {
                      keys: ["name"],
                    }) || Object.values(localProjects)
                  }
                  useFixedHeader={false}
                  scroll={{ x: "max-content", y: 375 }}
                  className="project-table"
                  components={components}
                  rowClassName={(record, index) => (record.project === selectedProjectId ? "editable-row-selected" : "editable-row")}
                  rowKey="project"
                  size="small"
                  // pagination={{ pageSize: 20 }}
                  pagination={false}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        this.handleProjectSelected(record);
                      }, // click row
                      onDoubleClick: (event) => {
                        this.handleLoadProject(record.project);
                      }, // double click row
                      onContextMenu: (event) => {}, // right button click row
                      onMouseEnter: (event) => {}, // mouse enter row
                      onMouseLeave: (event) => {}, // mouse leave row
                    };
                  }}
                  onChange={this.handleChangeSort}
                  columns={[
                    {
                      title: "Name",
                      dataIndex: "name",
                      width: "300px",
                      sorter: (a, b) => a.name > b.name,
                      sortOrder: this.state.project_sortedInfo.field === "name" && this.state.project_sortedInfo.order,
                      // sortOrder: sortedInfo.field === col.dataIndex && sortedInfo.order,
                      onCell: (record, rowIndex) => ({
                        record,
                        editable: true,
                        dataIndex: rowIndex,
                        title: record.name,
                        handleSave: this.handleSaveProjectName,
                        handeUpdateProjectName: this.handeUpdateProjectName,
                        editing: record.editing,
                        toggleCollab: this.toggleCollab,
                      }),
                    },
                    {
                      title: "Modified Date",
                      dataIndex: "editDate",
                      align: "center",
                      width: "150px",
                      sorter: (a, b) => a.editDate - b.editDate,
                      sortOrder: this.state.project_sortedInfo.field === "editDate" && this.state.project_sortedInfo.order,
                      render: (text, record) => <span>{getTime(record.editDate)}</span>,
                    },
                    {
                      title: "Actions",
                      align: "center",
                      width: "170px",
                      render: (text, record, index) => (
                        <div>
                          {record.editing ? (
                            <span>
                              <a onClick={() => this.handleProjectEdit(record)}>Save</a> -{" "}
                            </span>
                          ) : (
                            <span>
                              <a onClick={() => this.handleProjectEdit(record)}>Edit</a> -{" "}
                            </span>
                          )}
                          <Popconfirm title="Are you sure?" onConfirm={() => this.handleDeleteProject(record.project)}>
                            <a>Delete</a>
                          </Popconfirm>
                          <span>
                            {" "}
                            - <a onClick={() => this.props.dispatch(siftActions.toggleCollabModal(true, record.project))}>Collaborate</a>
                          </span>
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </Modal>

        <Modal
          title={<span>Collaborate {collab_projectId && `: ${localProjects[collab_projectId].name}`}</span>}
          className="sift-modal"
          onCancel={() => {
            this.setState({ temp_shareEmail: undefined });
            this.props.dispatch(siftActions.toggleCollabModal(false));
          }}
          width="65%"
          visible={collabVisible}
          footer={
            <div>
              <Button onClick={() => this.props.dispatch(siftActions.toggleCollabModal(false))} disabled={usable}>
                Close
              </Button>
            </div>
          }
        >
          <div style={{ height: "400px", overflowY: "scroll" }}>
            <Spin tip="Closing..." spinning={!collabVisible} style={{ height: "100%" }}>
              {collab_projectId && (
                <div>
                  <div>
                    <Input
                      id="share_with"
                      value={this.state.temp_shareEmail}
                      disabled={!can_share || collab_loading}
                      placeholder={can_share ? "Enter an email." : "Starter accounts cannot share projects."}
                      onChange={(e) => this.handleUpdateShareEmail(e.target.value)}
                      onPressEnter={() => this.handleShareProject()}
                      style={{ width: "300px", marginRight: "10px" }}
                    />
                    <Tooltip title={can_share ? null : "Starter accounts cannot share projects."}>
                      <Button type="ghost" icon={<UserAddOutlined />} style={{ marginRight: "10px" }} disabled={!can_share} onClick={() => this.handleShareProject()} loading={collab_loading}>
                        Share
                      </Button>
                    </Tooltip>

                    {collab_project.collab_on && (
                      <Tooltip title="Refresh">
                        <Button type="ghost" icon={<ReloadOutlined />} style={{ marginRight: "10px" }} onClick={() => this.handleRefreshProject()} loading={collab_loading}></Button>
                      </Tooltip>
                    )}

                    {collab_project.creator && (
                      <Tooltip title="Force check in.">
                        <Button
                          type="ghost"
                          icon={<UnlockOutlined />}
                          style={{ marginRight: "10px" }}
                          onClick={() => this.handleCheckProject(true, collab_projectId, false)}
                          loading={collab_loading}
                        ></Button>
                      </Tooltip>
                    )}
                  </div>
                  <span style={{ color: "red" }}>{this.state.shareError}</span>
                  <br />

                  {/* 
									this is the checkin/checkout button
									1- you can check Out project, which locks to all other users
									2- you can check In project, which is save the changes you've made for other users to see
									3- you can't do anything because another user has it checked-out

									collab_projectId && collab_project.collab.locked_by_other
								*/}
                  <div>
                    {collab_project.collab_on == undefined ? (
                      <div></div>
                    ) : collab_project.locked_by_me ? (
                      <div>
                        <TextArea
                          id="check_in_note"
                          rows={1}
                          value={this.state.check_in_note}
                          disabled={collab_loading}
                          placeholder="Optional note for Check In"
                          onChange={(e) => this.hanldeUpdateNote(e.target.value)}
                          onPressEnter={() => this.handleCheckProject(true)}
                          style={{ width: "300px", marginRight: "10px" }}
                        />
                        <Button type="ghost" icon={<CloudUploadOutlined />} loading={collab_loading} style={{ marginRight: "10px" }} onClick={() => this.handleCheckProject(true)}>
                          Check In
                        </Button>
                        <Checkbox checked={this.state.notify_users} onChange={(e) => this.setState({ notify_users: e.target.checked })}>
                          Notify users of Check In
                        </Checkbox>
                      </div>
                    ) : collab_project.locked ? (
                      <div>
                        {/* <Button type="ghost" icon={<LockOutlined />}
												disabled 
												style={{marginRight:'10px'}}
											> 
												Locked
											</Button> */}
                        Project is Checked Out by {collab_project.locked_by_email}. Project is Read Only.
                      </div>
                    ) : (
                      <div>
                        <Button type="ghost" icon={<CloudDownloadOutlined />} loading={collab_loading} style={{ marginRight: "10px" }} onClick={() => this.handleCheckProject(false)}>
                          Check Out Project
                        </Button>
                      </div>
                    )}
                  </div>

                  <br />
                  <Table
                    loading={collab_loading}
                    dataSource={collab_project.user_list}
                    useFixedHeader={false}
                    scroll={{ x: "max-content", y: 96 }}
                    className="project-table"
                    rowKey="email"
                    size="small"
                    pagination={false}
                    // onChange={this.handleSortChange}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {}, // click row
                        onDoubleClick: (event) => {}, // double click row
                        onContextMenu: (event) => {}, // right button click row
                        onMouseEnter: (event) => {}, // mouse enter row
                        onMouseLeave: (event) => {}, // mouse leave row
                      };
                    }}
                    columns={collab_columns}
                  />
                  <div style={{ height: "10px" }}></div>
                  <Table
                    loading={collab_loading}
                    dataSource={collab_project.notes}
                    useFixedHeader={false}
                    scroll={{ x: "max-content", y: 96 }}
                    className="project-table"
                    rowKey="email"
                    size="small"
                    pagination={false}
                    // onChange={this.handleSortChange}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {}, // click row
                        onDoubleClick: (event) => {}, // double click row
                        onContextMenu: (event) => {}, // right button click row
                        onMouseEnter: (event) => {}, // mouse enter row
                        onMouseLeave: (event) => {}, // mouse leave row
                      };
                    }}
                    columns={notes_columns}
                  />

                  {/* Functionality on this screen:
								<ol>
									<li>See if collab is enabled for this project</li>
									<li>See if project is locked</li>
									<li>Quick share project to another user</li>

								</ol> */}
                </div>
              )}
              {!collab_projectId && collabVisible && (
                <div style={{ paddingTop: "70px" }}>
                  <Empty description={<span>Save to enable collaborate.</span>}>
                    <Input
                      id="project_name"
                      disabled={usable}
                      value={collab_projectId ? collab_project.name : this.state.temp_projectName}
                      prefix={<BookOutlined type="project" style={{ color: "rgba(0,0,0,.25)" }} />}
                      placeholder="Enter project name."
                      onChange={(e) => this.handleProjectNameUpdate(e.target.value)}
                      onPressEnter={() => this.handleSaveProject()}
                      style={{ width: "200px", marginRight: "10px" }}
                    />
                    <Button type="primary" loading={usable} onClick={() => this.handleSaveProject()}>
                      <span>
                        <SaveOutlined /> Save
                      </span>
                    </Button>
                  </Empty>
                </div>
              )}
            </Spin>
          </div>
        </Modal>
      </div>
    );
  }
}

// this maps redux store states into the props of this component
function mapStateToProps(state) {
  const { sift, account, tutorial } = state;
  const { projectManager, ioManager, uiState } = sift;
  const { loading, loaded, modal_visible, localProjects, selectedProjectId, currentProjectId, collab_loading, collab_request, collab_projectId } = projectManager;
  return {
    isAuthenticated: isAuthenticated(state),
    loading,
    loaded,
    modal_visible,
    localProjects,
    selectedProjectId,
    currentProjectId,
    account,
    ioManager,
    tutorial,
    collabVisible: uiState.collabVisible,
    collab_loading,
    collab_request,
    collab_projectId,
  };
}

const connectedComponent = connect(mapStateToProps)(ProjectManager);
export { connectedComponent as ProjectManager };
