import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isAuthenticated, siftActions, authActions, getSIFTInputs, validateInputs, accountActions } from "../../Redux";

import { Select, Switch, Dropdown, Button, Tooltip, Input } from "antd";

import { UserOutlined, SaveOutlined, LockOutlined, ImportOutlined, EllipsisOutlined, CloudSyncOutlined } from "@ant-design/icons";

import { MenuOverlay } from "./InputManager";

//tutorial stuff
import { TutorialTip } from "../../TutorialTip";

const InputHeader = (props) => {
  const dispatch = useDispatch();

  // tutorial controls
  const tutorial = useSelector((state) => state.tutorial);

  const tab = useSelector((state) => state.sift.ioManager.uiState.tab);
  const inputs = useSelector((state) => state.sift.ioManager.inputs[tab]);

  const account_loading = useSelector((state) => state.account.account_loading);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const saving = account_loading || input_loading;

  const dropdown = useSelector((state) => state.sift.ioManager.dropdowns[tab]);
  const selected = inputs && inputs.data;
  const isAuthed = useSelector((state) => isAuthenticated(state));

  const running = useSelector((state) => state.sift.ioManager.outputs.runState.running);
  const doFinance = useSelector((state) => state.sift.ioManager.inputs.config.data.do_finance == 1);
  const topo_live = useSelector((state) => state.sift.ioManager.inputs.topo.data.topo_live);
  const rack_type = useSelector((state) => state.sift.ioManager.inputs.racking.data.type);
  const current_plan = useSelector((state) => state.account.current_plan);
  const can_import = current_plan > 1;
  const screenWidth = useSelector((state) => state.sift.ioManager.uiState.screenWidth);

  let is_generic = false;
  let can_delete = true;
  let can_save = true;
  let can_save_as = true;
  if (inputs) {
    if (tab != "topo") {
      // all other tabs have a .data object
      if (inputs.data.default == 1 || (tab == "weather" && inputs.id == 0)) is_generic = true;
      if (inputs.data.default == 1 || inputs.id == 0 || (inputs.data.upload && inputs.data.upload == 1)) can_delete = false;
    }
    if ((tab == "module" || tab == "inverter") && current_plan == 1) {
      can_save = false;
      can_save_as = false;
    } else if (is_generic) {
      can_save = false;
    }
  }

  function updateInput(key, value) {
    dispatch(siftActions.updateInput(key, value));
  }

  function onDropDownSelect(value) {
    dispatch(siftActions.dropDownSelect(value));
  }
  function onUpdateFinance(bool) {
    dispatch(siftActions.updateFinanceToggle(bool));
  }
  function onSaveInputPressed(key) {
    // this function is used by save, saveas, and delete buttons
    let input = JSON.parse(JSON.stringify(inputs));
    if (key == "saveas") {
      // if (input.id == 0) {
      //   let tabName = sift.ioManager.uiState.tab.charAt(0).toUpperCase() + sift.ioManager.uiState.tab.slice(1);
      //   input.data.name = `Custom ${tabName} [${Date.now()}]`
      // } else {
      //   input.data.name = `${input.data.name} - copy`
      // }

      input.data.name = `${input.data.name} - copy`;

      input.id = "";
      input.data.id = "";
      input.data.default = 0;
    } else if (key == "delete") {
      input.delete = "delete";
    } else if (key == "save") {
      if (input.id == 0) {
        input.id = "";
        input.data.id = "";
        input.data.default = 0;
      }
    }
    console.log(input)
    dispatch(siftActions.saveInput(input, tab, key));
  }

  function capitalizeFirstLetter(type) {
    return type.replace(/^./, type[0].toUpperCase());
  }

  let selectedProd = "No Product Selected";
  if (selected && selected.name) {
    selectedProd = selected.name;
  }
  // console.log(selected)
  const headerClassName = `input-selector ${tab}`;
  return (
    <div className={headerClassName}>
      {tab == "config" ? (
        <div className="select-content">
          <label>When your site and inputs are prepared, click Initialize SIFT and each DC:AC and GCR combination will be evaluated.</label>
        </div>
      ) : tab == "topo" ? (
        <div className="select-content">
          {/* header - not sure what would go here */}
          {!topo_live ? (
            <label>
              Topography import is defined by map boundaries, verify boundaries before clicking import. SIFT will create slope analysis layers from the imported topography. Limits define the color
              gradients and scale of the generated layers. Slopes above limit will display in dark gray.
            </label>
          ) : (
            <label>
              Topography import is complete! Use the buttons at the bottom right of the map to toggle between slope analysis layers. Limits define the color gradients and scale of the generated layers
              and cannot be modified after import. Slopes above set limits are displayed in dark gray.
            </label>
          )}
        </div>
      ) : (
        (tab == "module" || tab == "inverter" || tab == "racking" || tab == "layout" || tab == "weather" || tab == "finance" || tab == "performance") && (
          <Fragment>
            <TutorialTip
              elementKey="import-menu"
              customTipWidth={screenWidth < 768 ? (tab == "inverter" ? 300 : -340) : undefined}
              customTipHeight={screenWidth < 768 ? (tab == "inverter" ? 65 : 100) : undefined}
              placement={screenWidth < 768 ? "top" : "right"}
              visible={
                (tutorial.visibleTips.import_modules && tab == "module") || (tutorial.visibleTips.import_inverter && tab == "inverter") || (tutorial.visibleTips.racking_select && tab == "racking")
              }
              highlight={true}
              content={
                (tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text?.import_modules) ||
                (tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text?.import_inverter) ||
                (tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text?.racking_select)
              }
            >
              <div className="select-content" id="import-menu">
                <Select
                  disabled={(tab == "finance" && !doFinance) || saving}
                  showSearch
                  size="small"
                  style={{ width: 130, marginBottom: 5, marginRight: 5, marginLeft: 5 }}
                  placeholder={`Select a ${tab} Preset`}
                  optionFilterProp="children"
                  onChange={(value) => {
                    onDropDownSelect(value);
                  }}
                  dropdownMatchSelectWidth={290}
                  dropdownStyle={{ width: 290 }}
                  // value={saving ? 'Loading...': selectedProd}  ${capitalizeFirstLetter(tab)}
                  value={saving ? "Loading..." : `Search...`}
                  filterOption={(input, option) => option.children[2].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {/* Drop in any custom modules the user has saved to their account */}
                  {Object.values(dropdown).map((item, index) => {
                    const { data } = item;
                    if (tab == "weather") {
                      if (data.default == 1) {
                        return null;
                      } else {
                        return (
                          <Select.Option value={item.id} key={item.id}>
                            <span>{tab == "weather" && item.id == 0 ? <CloudSyncOutlined /> : data.default == 1 ? <LockOutlined /> : <UserOutlined />}</span> <span>{data.name}</span>
                          </Select.Option>
                        );
                      }
                    } else {
                      return (
                        <Select.Option value={item.id} key={item.id}>
                          <span>{tab == "weather" && item.id == 0 ? <CloudSyncOutlined /> : data.default == 1 ? <LockOutlined /> : <UserOutlined />}</span> <span>{data.name}</span>
                        </Select.Option>
                      );
                    }
                  })}
                </Select>

                {/* INPUT MANAGER */}
                <div className="button-group">
                  <Button.Group>
                    {(tab == "module" || tab == "inverter" || tab == "weather") && (
                      <Tooltip placement="top" title={can_import ? null : `${capitalizeFirstLetter(tab)} import and save limited to paid plans.`}>
                        <Button
                          size="small"
                          // loading={saving}
                          disabled={!can_import || saving}
                          onClick={() => dispatch(siftActions.toggleImportVis(true))}
                        >
                          <span>
                            <ImportOutlined /> Import
                          </span>
                        </Button>
                      </Tooltip>
                    )}

                    <Tooltip
                      placement="top"
                      title={(tab == "module" || tab == "inverter" || tab == "weather") && !can_save && !is_generic ? `${capitalizeFirstLetter(tab)} import and save limited to paid plans.` : null}
                    >
                      <Button
                        size="small"
                        // loading={saving}
                        disabled={(tab == "finance" && !doFinance) || !can_save}
                        onClick={() => onSaveInputPressed("save")}
                      >
                        <span>
                          <SaveOutlined /> Save
                        </span>
                      </Button>
                    </Tooltip>

                    <Dropdown
                      trigger={["click"]}
                      overlay={MenuOverlay({ type: tab, can_delete: can_delete, onMenuClick: onSaveInputPressed })}
                      placement="bottomRight"
                      disabled={(tab == "finance" && !doFinance) || !can_save_as || saving}
                    >
                      <Button size="small" style={{ paddingLeft: "8px", paddingRight: "8px" }} disabled={tab == "finance" && !doFinance}>
                        <EllipsisOutlined />
                      </Button>
                    </Dropdown>
                  </Button.Group>
                </div>
              </div>
            </TutorialTip>

            {tab == "finance" && (
              <div className="input-name">
                <div className="input-row name">
                  <label>Enable Finance</label>
                  <Switch
                    size="small"
                    checked={doFinance}
                    onChange={() => {
                      onUpdateFinance(!doFinance);
                    }}
                    disabled={running}
                  />
                </div>
              </div>
            )}
            {/* {tab == 'racking' && (
              <div className="input-name">
                <div className="input-row name">
                  <label>Rack Type</label>
                  <div>
                    <Select
                      style={{ width: '100%' }}
                      value={rack_type}
                      size="small"
                      onChange={(value) => {
                        updateInput('type', value, 'racking');
                      }}
                    >
                      <Select.Option key="rack_type1" value={0}>
                        Ground Fixed TIlt
                      </Select.Option>
                      <Select.Option key="rack_type2" value={1}>
                        Single-Axis Tracker
                      </Select.Option>
                      <Select.Option key="rack_type3" value={2}>
                        East-West Fixed
                      </Select.Option>
                    </Select>
                  </div>
                </div>
              </div>
            )} */}

            <div className="input-name">
              <div className="input-row name">
                <label>Name</label>
                <Input
                  id="name"
                  onChange={(event) => {
                    updateInput(event.target.id, event.target.value);
                  }}
                  value={selected.name}
                  size="small"
                  disabled={saving}
                />
              </div>
            </div>
          </Fragment>
        )
      )}
    </div>
  );
};

export { InputHeader };
