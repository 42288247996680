import React, { useState, useEffect } from "react";

const SAT_Large = ({ active, autoRack }) => {
  const [activeMainColor, setActiveMainColor] = useState("#1E90FF");
  const [activeSecondColor, setActiveSecondColor] = useState("#444444");

  useEffect(() => {
    let mainColor = active == 1 && autoRack == 1 ? "#1E90FF" : "#777777";
    let secondColor = active == 1 && autoRack == 1 ? "#444444" : "#777777";
    setActiveMainColor(mainColor);
    setActiveSecondColor(secondColor);
  }, [active]);

  return (
    <svg width="100" height="208" viewBox="0 0 100 208" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="100" height="208" fill="none" />
      <path d="M72.5918 92.752L74.8184 88.4688H76.0957L73.1543 93.8184V97H72.0293V93.8184L69.0879 88.4688H70.377L72.5918 92.752Z" fill="#333333" />
      <line x1="72.5" y1="100.997" x2="72.978" y2="176.995" stroke={activeSecondColor} />
      <line y1="-0.5" x2="76" y2="-0.5" transform="matrix(0.00628918 -0.99998 -0.99998 -0.00628918 72 85)" stroke={activeSecondColor} />
      <line x1="68" y1="177.5" x2="78" y2="177.5" stroke={activeSecondColor} />
      <line y1="-0.5" x2="10" y2="-0.5" transform="matrix(1 0 0 -1 68 8)" stroke={activeSecondColor} />
      <line x1="35.5" y1="190" x2="35.5" y2="181" stroke={activeSecondColor} />
      <line x1="64.5" y1="190" x2="64.5" y2="181" stroke={activeSecondColor} />
      <rect x="35.5" y="8.5" width="29" height="169" stroke={activeMainColor} />
      <line x1="36" y1="185.5" x2="43" y2="185.5" stroke={activeSecondColor} />
      <line x1="57" y1="185.5" x2="64" y2="185.5" stroke={activeSecondColor} />
      <path
        d="M49.7734 184.738L51.8184 181.469H53.1426L50.4473 185.699L53.207 190H51.8711L49.7734 186.672L47.6641 190H46.334L49.0996 185.699L46.3984 181.469H47.7168L49.7734 184.738Z"
        fill="#333333"
      />
    </svg>
  );
};

const SAT_Medium = ({ active, autoRack }) => {
  const [activeMainColor, setActiveMainColor] = useState("#1E90FF");
  const [activeSecondColor, setActiveSecondColor] = useState("#444444");

  useEffect(() => {
    let mainColor = active == 1 && autoRack == 1 ? "#1E90FF" : "#777777";
    let secondColor = active == 1 && autoRack == 1 ? "#444444" : "#777777";
    setActiveMainColor(mainColor);
    setActiveSecondColor(secondColor);
  }, [active]);

  return (
    <svg width="100" height="208" viewBox="0 0 100 208" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="100" height="208" fill="none" />
      <line x1="71.5" y1="47.9969" x2="71.8019" y2="95.9959" stroke={activeSecondColor} />
      <line y1="-0.5" x2="48" y2="-0.5" transform="matrix(0.00628918 -0.99998 -0.99998 -0.00628918 71 159.999)" stroke={activeSecondColor} />
      <line x1="66" y1="48.5" x2="77" y2="48.5" stroke={activeSecondColor} />
      <line y1="-0.5" x2="11" y2="-0.5" transform="matrix(1 0 0 -1 66 158.999)" stroke={activeSecondColor} />
      <path d="M71.5918 103.752L73.8184 99.4688H75.0957L72.1543 104.818V108H71.0293V104.818L68.0879 99.4688H69.377L71.5918 103.752Z" fill="#333333" />
      <line x1="35.5" y1="174" x2="35.5" y2="165" stroke={activeSecondColor} />
      <line x1="64.5" y1="174" x2="64.5" y2="165" stroke={activeSecondColor} />
      <rect x="35.5" y="48.5" width="29" height="111" stroke={activeMainColor} />
      <line x1="36" y1="169.5" x2="43" y2="169.5" stroke={activeSecondColor} />
      <line x1="58" y1="169.5" x2="65" y2="169.5" stroke={activeSecondColor} />
      <path
        d="M49.7734 168.738L51.8184 165.469H53.1426L50.4473 169.699L53.207 174H51.8711L49.7734 170.672L47.6641 174H46.334L49.0996 169.699L46.3984 165.469H47.7168L49.7734 168.738Z"
        fill="#333333"
      />
    </svg>
  );
};

const SAT_Small = ({ active, autoRack }) => {
  const [activeMainColor, setActiveMainColor] = useState("#1E90FF");
  const [activeSecondColor, setActiveSecondColor] = useState("#444444");

  useEffect(() => {
    let mainColor = active == 1 && autoRack == 1 ? "#1E90FF" : "#777777";
    let secondColor = active == 1 && autoRack == 1 ? "#444444" : "#777777";
    setActiveMainColor(mainColor);
    setActiveSecondColor(secondColor);
  }, [active]);

  return (
    <svg width="100" height="208" viewBox="0 0 100 208" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="100" height="208" fill="none" />
      <line x1="72.5" y1="69.9969" x2="72.6761" y2="97.9963" stroke={activeSecondColor} />
      <line y1="-0.5" x2="28" y2="-0.5" transform="matrix(0.00628918 -0.99998 -0.99998 -0.00628918 72 138.999)" stroke={activeSecondColor} />
      <line x1="67" y1="70.5" x2="78" y2="70.5" stroke={activeSecondColor} />
      <line y1="-0.5" x2="11" y2="-0.5" transform="matrix(1 0 0 -1 67 137.999)" stroke={activeSecondColor} />
      <rect x="35.5" y="70.5" width="29" height="68" stroke={activeMainColor} />
      <line x1="35.5" y1="154" x2="35.5" y2="145" stroke={activeSecondColor} />
      <line x1="64.5" y1="154" x2="64.5" y2="145" stroke={activeSecondColor} />
      <line x1="36" y1="149.5" x2="45" y2="149.5" stroke={activeSecondColor} />
      <line x1="56" y1="149.5" x2="65" y2="149.5" stroke={activeSecondColor} />
      <path
        d="M50.7734 148.738L52.8184 145.469H54.1426L51.4473 149.699L54.207 154H52.8711L50.7734 150.672L48.6641 154H47.334L50.0996 149.699L47.3984 145.469H48.7168L50.7734 148.738Z"
        fill="#333333"
      />
      <path d="M72.5918 103.752L74.8184 99.4688H76.0957L73.1543 104.818V108H72.0293V104.818L69.0879 99.4688H70.377L72.5918 103.752Z" fill="#333333" />
    </svg>
  );
};

const EWF_Large = ({ active, autoRack }) => {
  const [activeMainColor, setActiveMainColor] = useState("#1E90FF");
  const [activeSecondColor, setActiveSecondColor] = useState("#444444");

  useEffect(() => {
    let mainColor = active == 1 && autoRack == 1 ? "#1E90FF" : "#777777";
    let secondColor = active == 1 && autoRack == 1 ? "#444444" : "#777777";
    setActiveMainColor(mainColor);
    setActiveSecondColor(secondColor);
  }, [active]);

  return (
    <svg width="100" height="208" viewBox="0 0 100 208" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="100" height="208" fill="none" />
      <line x1="84.5" y1="8.99686" x2="84.978" y2="84.9954" stroke={activeSecondColor} />
      <line y1="-0.5" x2="74" y2="-0.5" transform="matrix(0.00628918 -0.99998 -0.99998 -0.00628918 84 177.999)" stroke={activeSecondColor} />
      <line x1="79" y1="9.5" x2="90" y2="9.5" stroke={activeSecondColor} />
      <line y1="-0.5" x2="11" y2="-0.5" transform="matrix(1 0 0 -1 79 176.999)" stroke={activeSecondColor} />
      <line x1="32.5" y1="190" x2="32.5" y2="181" stroke={activeSecondColor} />
      <line x1="54.5" y1="190" x2="54.5" y2="181" stroke={activeSecondColor} />
      <rect x="32.5" y="9.5" width="44" height="168" stroke={activeMainColor} />
      <line x1="33" y1="185.5" x2="37" y2="185.5" stroke={activeSecondColor} />
      <line x1="50" y1="185.5" x2="54" y2="185.5" stroke={activeSecondColor} />
      <path d="M84.5918 92.752L86.8184 88.4688H88.0957L85.1543 93.8184V97H84.0293V93.8184L81.0879 88.4688H82.377L84.5918 92.752Z" fill="#333333" />
      <path
        d="M43.7734 184.738L45.8184 181.469H47.1426L44.4473 185.699L47.207 190H45.8711L43.7734 186.672L41.6641 190H40.334L43.0996 185.699L40.3984 181.469H41.7168L43.7734 184.738Z"
        fill="#333333"
      />
      <line x1="54.5757" y1="179.02" x2="54.5757" y2="4.98" stroke="#1E90FF" strokeDasharray="2 2" />
    </svg>
  );
};

const EWF_Medium = ({ active, autoRack }) => {
  const [activeMainColor, setActiveMainColor] = useState("#1E90FF");
  const [activeSecondColor, setActiveSecondColor] = useState("#444444");

  useEffect(() => {
    let mainColor = active == 1 && autoRack == 1 ? "#1E90FF" : "#777777";
    let secondColor = active == 1 && autoRack == 1 ? "#444444" : "#777777";
    setActiveMainColor(mainColor);
    setActiveSecondColor(secondColor);
  }, [active]);

  return (
    <svg width="100" height="208" viewBox="0 0 100 208" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="100" height="208" fill="none" />
      <line x1="86.5" y1="47.9969" x2="86.7956" y2="94.9959" stroke={activeSecondColor} />
      <line y1="-0.5" x2="46" y2="-0.5" transform="matrix(0.00628918 -0.99998 -0.99998 -0.00628918 86 158.999)" stroke={activeSecondColor} />
      <line x1="81" y1="48.5" x2="92" y2="48.5" stroke={activeSecondColor} />
      <line y1="-0.5" x2="11" y2="-0.5" transform="matrix(1 0 0 -1 81 157.999)" stroke={activeSecondColor} />
      <rect x="33.5" y="48.5" width="44" height="111" stroke={activeMainColor} />
      <path d="M86.5918 103.752L88.8184 99.4688H90.0957L87.1543 104.818V108H86.0293V104.818L83.0879 99.4688H84.377L86.5918 103.752Z" fill="#333333" />
      <line x1="33.5" y1="172" x2="33.5" y2="163" stroke={activeSecondColor} />
      <line x1="55.5" y1="172" x2="55.5" y2="163" stroke={activeSecondColor} />
      <line x1="34" y1="167.5" x2="38" y2="167.5" stroke={activeSecondColor} />
      <line x1="51" y1="167.5" x2="55" y2="167.5" stroke={activeSecondColor} />
      <path
        d="M44.7734 166.738L46.8184 163.469H48.1426L45.4473 167.699L48.207 172H46.8711L44.7734 168.672L42.6641 172H41.334L44.0996 167.699L41.3984 163.469H42.7168L44.7734 166.738Z"
        fill="#333333"
      />
      <line x1="55.5757" y1="161.02" x2="55.5757" y2="43.98" stroke="#1E90FF" strokeDasharray="2 2" />
    </svg>
  );
};

const EWF_Small = ({ active, autoRack }) => {
  const [activeMainColor, setActiveMainColor] = useState("#1E90FF");
  const [activeSecondColor, setActiveSecondColor] = useState("#444444");

  useEffect(() => {
    let mainColor = active == 1 && autoRack == 1 ? "#1E90FF" : "#777777";
    let secondColor = active == 1 && autoRack == 1 ? "#444444" : "#777777";
    setActiveMainColor(mainColor);
    setActiveSecondColor(secondColor);
  }, [active]);

  return (
    <svg width="100" height="208" viewBox="0 0 100 208" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="100" height="208" fill="none" />
      <line x1="88.5" y1="69.9969" x2="88.6572" y2="94.9964" stroke={activeSecondColor} />
      <line y1="-0.5" x2="25" y2="-0.5" transform="matrix(0.00628918 -0.99998 -0.99998 -0.00628918 88 138)" stroke={activeSecondColor} />
      <line x1="83" y1="70.5" x2="94" y2="70.5" stroke={activeSecondColor} />
      <line y1="-0.5" x2="11" y2="-0.5" transform="matrix(1 0 0 -1 83 137)" stroke={activeSecondColor} />
      <rect x="36.5" y="70.5" width="44" height="68" stroke={activeMainColor} />
      <line x1="35.5" y1="151" x2="35.5" y2="142" stroke={activeSecondColor} />
      <line x1="57.5" y1="151" x2="57.5" y2="142" stroke={activeSecondColor} />
      <line x1="36" y1="146.5" x2="40" y2="146.5" stroke={activeSecondColor} />
      <line x1="53" y1="146.5" x2="57" y2="146.5" stroke={activeSecondColor} />
      <path
        d="M46.7734 145.738L48.8184 142.469H50.1426L47.4473 146.699L50.207 151H48.8711L46.7734 147.672L44.6641 151H43.334L46.0996 146.699L43.3984 142.469H44.7168L46.7734 145.738Z"
        fill="#333333"
      />
      <path d="M88.5918 103.752L90.8184 99.4688H92.0957L89.1543 104.818V108H88.0293V104.818L85.0879 99.4688H86.377L88.5918 103.752Z" fill="#333333" />
      <line x1="57.5757" y1="140.02" x2="57.5757" y2="64.98" stroke="#1E90FF" strokeDasharray="2 2" />
    </svg>
  );
};

const GFT_Large = ({ active, autoRack }) => {
  const [activeMainColor, setActiveMainColor] = useState("#1E90FF");
  const [activeSecondColor, setActiveSecondColor] = useState("#444444");

  useEffect(() => {
    let mainColor = active == 1 && autoRack == 1 ? "#1E90FF" : "#777777";
    let secondColor = active == 1 && autoRack == 1 ? "#444444" : "#777777";
    setActiveMainColor(mainColor);
    setActiveSecondColor(secondColor);
  }, [active]);

  return (
    <svg width="144" height="208" viewBox="0 0 144 208" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="144" height="208" fill="none" />
      <line x1="19.5" y1="83.9969" x2="19.5692" y2="94.9966" stroke={activeSecondColor} />
      <line y1="-0.5" x2="10" y2="-0.5" transform="matrix(0.00628918 -0.99998 -0.99998 -0.00628918 19 120)" stroke={activeSecondColor} />
      <line x1="14" y1="84.5" x2="25" y2="84.5" stroke={activeSecondColor} />
      <path d="M19.5918 102.752L21.8184 98.4688H23.0957L20.1543 103.818V107H19.0293V103.818L16.0879 98.4688H17.377L19.5918 102.752Z" fill="#333333" />
      <line y1="-0.5" x2="11" y2="-0.5" transform="matrix(1 0 0 -1 14 119)" stroke={activeSecondColor} />
      <line x1="27.5" y1="135" x2="27.5" y2="124" stroke={activeSecondColor} />
      <line y1="-0.5" x2="11" y2="-0.5" transform="matrix(0 -1 -1 0 139 135)" stroke={activeSecondColor} />
      <line x1="27.5" y1="135" x2="27.5" y2="124" stroke={activeSecondColor} />
      <line y1="-0.5" x2="11" y2="-0.5" transform="matrix(0 -1 -1 0 139 135)" stroke={activeSecondColor} />
      <rect x="27.5" y="119.5" width="35" height="112" transform="rotate(-90 27.5 119.5)" stroke={activeMainColor} />
      <line x1="28" y1="129.5" x2="63" y2="129.5" stroke={activeSecondColor} />
      <line y1="-0.5" x2="35" y2="-0.5" transform="matrix(-1 0 0 1 139 130)" stroke={activeSecondColor} />
      <line x1="28" y1="129.5" x2="74" y2="129.5" stroke={activeSecondColor} />
      <line y1="-0.5" x2="55" y2="-0.5" transform="matrix(-1 0 0 1 139 130)" stroke={activeSecondColor} />
      <path
        d="M78.7734 128.738L80.8184 125.469H82.1426L79.4473 129.699L82.207 134H80.8711L78.7734 130.672L76.6641 134H75.334L78.0996 129.699L75.3984 125.469H76.7168L78.7734 128.738Z"
        fill="#333333"
      />
    </svg>
  );
};

const GFT_Medium = ({ active, autoRack }) => {
  const [activeMainColor, setActiveMainColor] = useState("#1E90FF");
  const [activeSecondColor, setActiveSecondColor] = useState("#444444");

  useEffect(() => {
    let mainColor = active == 1 && autoRack == 1 ? "#1E90FF" : "#777777";
    let secondColor = active == 1 && autoRack == 1 ? "#444444" : "#777777";
    setActiveMainColor(mainColor);
    setActiveSecondColor(secondColor);
  }, [active]);

  return (
    <svg width="144" height="208" viewBox="0 0 144 208" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="144" height="208" fill="none" />
      <line x1="41.5" y1="135" x2="41.5" y2="124" stroke={activeSecondColor} />
      <line y1="-0.5" x2="11" y2="-0.5" transform="matrix(0 -1 -1 0 115 135)" stroke={activeSecondColor} />
      <rect x="41.5" y="119.5" width="35" height="74" transform="rotate(-90 41.5 119.5)" stroke={activeMainColor} />
      <line x1="42" y1="129.5" x2="72" y2="129.5" stroke={activeSecondColor} />
      <line y1="-0.5" x2="32" y2="-0.5" transform="matrix(-1 0 0 1 115 130)" stroke={activeSecondColor} />
      <line x1="32.5" y1="83.9969" x2="32.5692" y2="94.9966" stroke={activeSecondColor} />
      <line x1="32.5" y1="83.9969" x2="32.5692" y2="94.9966" stroke={activeSecondColor} />
      <line y1="-0.5" x2="10" y2="-0.5" transform="matrix(0.00628918 -0.99998 -0.99998 -0.00628918 32 120)" stroke={activeSecondColor} />
      <line y1="-0.5" x2="10" y2="-0.5" transform="matrix(0.00628918 -0.99998 -0.99998 -0.00628918 32 120)" stroke={activeSecondColor} />
      <line x1="27" y1="84.5" x2="38" y2="84.5" stroke={activeSecondColor} />
      <line x1="27" y1="84.5" x2="38" y2="84.5" stroke={activeSecondColor} />
      <path d="M32.5918 102.752L34.8184 98.4688H36.0957L33.1543 103.818V107H32.0293V103.818L29.0879 98.4688H30.377L32.5918 102.752Z" fill="#333333" />
      <path d="M32.5918 102.752L34.8184 98.4688H36.0957L33.1543 103.818V107H32.0293V103.818L29.0879 98.4688H30.377L32.5918 102.752Z" fill="#333333" />
      <line y1="-0.5" x2="11" y2="-0.5" transform="matrix(1 0 0 -1 27 119)" stroke={activeSecondColor} />
      <line y1="-0.5" x2="11" y2="-0.5" transform="matrix(1 0 0 -1 27 119)" stroke={activeSecondColor} />
      <path
        d="M77.7734 128.738L79.8184 125.469H81.1426L78.4473 129.699L81.207 134H79.8711L77.7734 130.672L75.6641 134H74.334L77.0996 129.699L74.3984 125.469H75.7168L77.7734 128.738Z"
        fill="#333333"
      />
    </svg>
  );
};
const GFT_Small = ({ active, autoRack }) => {
  const [activeMainColor, setActiveMainColor] = useState("#1E90FF");
  const [activeSecondColor, setActiveSecondColor] = useState("#444444");

  useEffect(() => {
    let mainColor = active == 1 && autoRack == 1 ? "#1E90FF" : "#777777";
    let secondColor = active == 1 && autoRack == 1 ? "#444444" : "#777777";
    setActiveMainColor(mainColor);
    setActiveSecondColor(secondColor);
  }, [active]);

  return (
    <svg width="144" height="208" viewBox="0 0 144 208" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="144" height="208" fill="none" />
      <line x1="44.5" y1="135" x2="44.5" y2="124" stroke={activeSecondColor} />
      <line y1="-0.5" x2="11" y2="-0.5" transform="matrix(0 -1 -1 0 81 135)" stroke={activeSecondColor} />
      <rect x="44.5" y="119.5" width="35" height="37" transform="rotate(-90 44.5 119.5)" stroke={activeMainColor} />
      <line x1="45" y1="129.5" x2="58" y2="129.5" stroke={activeSecondColor} />
      <line y1="-0.5" x2="13" y2="-0.5" transform="matrix(-1 0 0 1 81 130)" stroke={activeSecondColor} />
      <path
        d="M62.7734 128.738L64.8184 125.469H66.1426L63.4473 129.699L66.207 134H64.8711L62.7734 130.672L60.6641 134H59.334L62.0996 129.699L59.3984 125.469H60.7168L62.7734 128.738Z"
        fill="#333333"
      />
      <line x1="34.5" y1="83.9969" x2="34.5692" y2="94.9966" stroke={activeSecondColor} />
      <line x1="34.5" y1="83.9969" x2="34.5692" y2="94.9966" stroke={activeSecondColor} />
      <line y1="-0.5" x2="10" y2="-0.5" transform="matrix(0.00628918 -0.99998 -0.99998 -0.00628918 34 120)" stroke={activeSecondColor} />
      <line y1="-0.5" x2="10" y2="-0.5" transform="matrix(0.00628918 -0.99998 -0.99998 -0.00628918 34 120)" stroke={activeSecondColor} />
      <line x1="29" y1="84.5" x2="40" y2="84.5" stroke={activeSecondColor} />
      <line x1="29" y1="84.5" x2="40" y2="84.5" stroke={activeSecondColor} />
      <path d="M34.5918 102.752L36.8184 98.4688H38.0957L35.1543 103.818V107H34.0293V103.818L31.0879 98.4688H32.377L34.5918 102.752Z" fill="#333333" />
      <path d="M34.5918 102.752L36.8184 98.4688H38.0957L35.1543 103.818V107H34.0293V103.818L31.0879 98.4688H32.377L34.5918 102.752Z" fill="#333333" />
      <line y1="-0.5" x2="11" y2="-0.5" transform="matrix(1 0 0 -1 29 119)" stroke={activeSecondColor} />
      <line y1="-0.5" x2="11" y2="-0.5" transform="matrix(1 0 0 -1 29 119)" stroke={activeSecondColor} />
    </svg>
  );
};

export { SAT_Large, SAT_Medium, SAT_Small, EWF_Large, EWF_Medium, EWF_Small, GFT_Large, GFT_Medium, GFT_Small };
