import React, { useState, useEffect } from "react";
import styled from "styled-components";

//locations
import { NaturalLocations } from "../Redux/_helpers";

const EarthViewTesterWrap = styled.section`
  height: 175px;
  width: 300px;
  display: grid;
  grid-template-rows: repeat(7, auto);
  position: absolute;
  bottom: 20px;
  right: 25px;
  background: #fff;
  color: #333;
  z-index: 1000;
  padding: 10px;
  place-items: start;
  border-radius: 2px;
  border: 1px solid #002bcb;

  p {
    margin: 0px;
  }

  .prev-next {
    display: flex;
    align-items: center;

    .controls {
      display: flex;
      align-items: center;

      input {
        width: 50px;
        height: 18px;
        margin-right: 8px;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      margin-left: 8px;

      button {
        margin-right: 5px;
      }
      p {
        margin: 0px 5px 0px 0px;
      }
    }
  }

  .save-zoom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button {
      margin-right: 5px;
    }
    p {
      margin: 0px 5px 0px 0px;
    }
  }
`;

const EarthViewTester = ({ zoom, earthViewFn }) => {
  const [earthViewIndex, setEarthViewIndex] = useState(0);

  const handlePrevNext = (direction) => {
    let index;
    if (direction == "prev" && earthViewIndex !== 0) {
      index = earthViewIndex - 1;
      setEarthViewIndex(index);
    }

    if (direction == "next" && earthViewIndex < Object.values(NaturalLocations).length) {
      index = earthViewIndex + 1;
      setEarthViewIndex(index);
    }
  };

  useEffect(() => {
    earthViewFn.current(earthViewIndex);
  }, [earthViewIndex]);

  const createLocationJson = () => {
    let newZoom = {
      earthViewIndex,
      zoom,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newZoom),
    };

    fetch("http://localhost:9945/locations", options);
  };

  return (
    <EarthViewTesterWrap>
      <p>{`Name: ${NaturalLocations[earthViewIndex].name}`}</p>
      <p>{`Region: ${NaturalLocations[earthViewIndex].region || "N/A"}`}</p>
      <p>{`latlng: 'lat': ${NaturalLocations[earthViewIndex].latlng.lat}, 'lng': ${NaturalLocations[earthViewIndex].latlng.lng} `}</p>
      <p>{`Earthview Zoom Level: ${NaturalLocations[earthViewIndex].zoomLevel}`}</p>
      <div className="prev-next">
        <div className="controls">
          <input type="text" value={earthViewIndex} onChange={(e) => setEarthViewIndex(e.target.value)} />
          <p>{` / ${Object.values(NaturalLocations).length}`}</p>
        </div>
        <div className="buttons">
          <button onClick={() => handlePrevNext("prev")}>prev</button>
          <button onClick={() => handlePrevNext("next")}>next</button>
        </div>
      </div>
      <div className="save-zoom">
        <p>{`Current zoom Level: ${zoom}`}</p>
        <button onClick={() => createLocationJson()}>Save Zoom</button>
      </div>
    </EarthViewTesterWrap>
  );
};

export { EarthViewTester };
