import React, { useState } from "react";
import { Button, notification, Tooltip, Popconfirm } from "antd";
import { StarOutlined } from "@ant-design/icons";

// redux
import { useSelector, useDispatch } from "react-redux";
import { isAuthenticated, siftActions } from "../../components/Redux";

import { question_mark } from "../../assets/images";

// css
import "./tutorial.css";

const Tutorial = (props) => {
  const ioManager = useSelector((state) => state.sift.ioManager);
  const dispatch = useDispatch();

  const [tutKey, setTutKey] = useState(`open${Date.now()}`);
  const [isOpen, setIsOpen] = useState(false);
  const [hasLaunched, setHasLaunched] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  // const [hideTutorial, setHideTutorial] = useState(false);

  function confirm() {
    localStorage.setItem("hideTutorial", "true");
    close();
    notification.destroy();
  }

  function gotoLastPage() {
    // console.log('going to last page ', tutKey)
    const complete = (
      <div>
        <p>We built SIFT to be easy to learn and use. Start simple and work through the input tabs one at a time.</p>
        <p>
          Need help? Click 'Help' or{" "}
          <a onClick={() => dispatch(siftActions.toggleHelpToolbarVisibility("helper_visible", true))}>
            <img src={question_mark} style={{ height: 13 }} />
          </a>{" "}
          for documentation. You will also find a link to schedule a 1-on-1 training.
        </p>
        Get Started:
        <ol>
          <li>Click ‘New’. Use the map tools to draw a project boundary or drag & drop a KMZ directly into SIFT.</li>
          <li>
            If an input tab is confusing, skip it and use the default values. You can come back later and learn more about each tab and input by browsing the help menu by clicking ‘Help’ or{" "}
            <a onClick={() => dispatch(siftActions.toggleHelpToolbarVisibility("helper_visible", true))}>
              <img src={question_mark} style={{ height: 13 }} />
            </a>
            .
          </li>
          <li>In ‘/config’, define up to 500 GCR and DC:AC options to evaluate. Click ‘RUN SIFT’.</li>
          <li>Get data and win projects with SIFT!</li>
        </ol>
        <br />
        <br />
        <br />
        <Popconfirm placement="leftBottom" title="Are you sure to hide this Tutorial in the future?" onConfirm={confirm} okText="Yes" cancelText="No">
          <span style={{ float: "right" }}>
            <a>
              <u>Don't show again.</u>
            </a>
          </span>
        </Popconfirm>
      </div>
    );
    notification.open({
      message: <span style={{ color: "#2F5598" }}>Your Turn</span>,
      description: complete,
      key: tutKey,
      duration: null,
      onClose: close,
      icon: <StarOutlined style={{ color: "#002bcb" }} />,
      style: { width: "426px" },
    });
  }

  // know when it's open
  // know when run starts to switch to next screen
  // know when results done to switch to last screen
  if (isOpen && !ioManager.outputs.runState.running && isRunning && !isComplete) {
    if (Object.values(ioManager.outputs.results).length > 0) {
      // console.log('marking complete ', tutKey)

      setIsComplete(true);

      const complete = (
        <div>
          <p>The project run is complete and 400+ layout, performance, and financial results are ready.</p>
          <p>You can review all results in the ‘/results’ tab. From this tab you can download a run summary to CSV, create a Report for a selected result, or download a layout to KMZ or CAD.</p>
          <p>Use SIFT to generate the data you need to have confidence in your design decisions, maximize margins, and submit winning bids with a competitive edge.</p>
          <a onClick={gotoLastPage}>
            <u>Next, define your own project.</u>
          </a>

          <br />
          <br />
          <br />
          <Popconfirm placement="leftBottom" title="Are you sure to hide this Tutorial in the future?" onConfirm={confirm} okText="Yes" cancelText="No">
            <span style={{ float: "right" }}>
              <a>
                <u>Don't show again.</u>
              </a>
            </span>
          </Popconfirm>
        </div>
      );
      notification.open({
        message: <span style={{ color: "#2F5598" }}>Run complete!</span>,
        description: complete,
        key: tutKey,
        duration: null,
        onClose: close,
        icon: <StarOutlined style={{ color: "#002bcb" }} />,
        style: { width: "426px" },
      });
    }
  }
  if (isOpen && ioManager.outputs.runState.running != isRunning && !isRunning) {
    // console.log('marking running ', tutKey)

    setIsRunning(true);

    const running = (
      <div>
        <p>We just asked SIFT to run an IRR optimization for this example site. Our cloud servers are working hard to create 400+ unique layout, performance, and financial models!</p>
        <p>The project is running, you can monitor the progress below. The tutorial will progress when the run is complete.</p>
      </div>
    );
    notification.open({
      message: <span style={{ color: "#2F5598" }}>Example Project Running…</span>,
      description: running,
      key: tutKey,
      duration: null,
      onClose: close,
      icon: <StarOutlined style={{ color: "#002bcb" }} />,
      style: { width: "426px" },
    });
  }

  function close() {
    dispatch(siftActions.toggleHelpToolbarVisibility("tutorial_visible", false));
    // setTutKey(undefined);
    setIsOpen(false);
    setIsRunning(false);
    setIsComplete(false);
  }

  function openNotification() {
    // console.log('opening notification ', tutKey)
    // dispatch(siftActions.toggleHelpToolbarVisibility('helper_visible', true))
    // const key = `open${Date.now()}`;
    // setTutKey(key)
    setIsOpen(true);
    setHasLaunched(true);
    const tutorial = (
      <div>
        <p>With SIFT you will be able to explore hundreds of project options, pinpoint better project designs, and save your team days of effort and churn.</p>
        <p>This two-minute tutorial will demonstrate the power of SIFT and highlight key features to get you started.</p>
        <br />
        <a onClick={() => dispatch(siftActions.runTutorial())}>
          <u>Click here to Run an example project.</u>
        </a>

        <br />
        <br />
        <br />
        {/* <a onClick={()=>localStorage.setItem('hideTutorial', 'false')}>Reset</a> */}
        <Popconfirm placement="leftBottom" title="The tutorial is in the 'Help' menu, but it will not show when you log in." onConfirm={confirm} okText="Ok" cancelText="Cancel">
          <span style={{ float: "right" }}>
            <a>
              <u>Don't show again.</u>
            </a>
          </span>
        </Popconfirm>
      </div>
    );
    notification.open({
      message: <span style={{ color: "#2F5598" }}>Welcome to SIFT!</span>,
      description: tutorial,
      // btn,
      key: tutKey,
      // keeps from closing
      duration: null,
      onClose: close,
      icon: <StarOutlined style={{ color: "#002bcb" }} />,
      style: { width: "426px" },
    });
  }

  // if (ioManager.uiState.hide_tutorial == 'false' && !hasLaunched) {
  //   // auto launch if has not launched and hide_tutorial set to false (from cookies)
  //   openNotification();
  // }

  // if (!isOpen && ioManager.uiState.tutorial_visible) {
  //   openNotification();
  // }

  return (
    <div></div>
    // <Tooltip title='Tutorial'>
    //   <Button
    //     type="primary" ghost shape="circle" size="large"
    //     onClick={openNotification}
    //     icon={<StarOutlined />}
    //     className="right button icon"
    //   />
    // </Tooltip>
  );
};

export { Tutorial };
