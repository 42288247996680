import config from "config";
import { authHeader } from "../_helpers";

export const siftService = {
  getElevation,
  getCountyState,
  sendBugReport,
  // sift run
  getRunId,
  initSIFT,
  runSIFT,
  getResults,
  pingImportedDataStatus,
  cancelRun,
  getRacks,
  getLayout,
  downloadFromS3,
  downloadFromS3toPC,
  saveInput,
  // topo
  getTopoId,
  checkTopoId,
  shareCollab,
  refreshCollab,

  offsetPolygon,
  runTopo,
  checkTopoId2,

  pollForAlerts,
};
async function getElevation(latlng) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(latlng),
  };

  return fetch(`${config.apiUrl}/dash/swm/eletz`, requestOptions).then(handleResponse);
}
async function getCountyState(lat, lon) {
  return fetch(`https://geo.fcc.gov/api/census/area?lat=${lat}&lon=${lon}&format=json`, {
    method: "GET",
  }).then(handleResponse);
}

function sendBugReport(params) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      message: params.message,
      inputs: params.inputs,
    }),
  };
  return fetch(`${config.apiUrl}/dash/bug/`, requestOptions).then(handleResponse);
}

async function getRunId() {
  let response = await fetch(`${config.apiUrl}/dash/swm/init`, {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  });

  let responseJson = await response.json();
  return responseJson;
}
async function initSIFT(inputs) {
  let response = await fetch(`${config.apiUrl}/dash/swm/`, {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      ...inputs,
      validate: true,
    }),
  });

  let responseJson = await response.json();
  return responseJson;
}

async function runSIFT(inputs) {
  let response = await fetch(`${config.apiUrl}/dash/swm/`, {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      ...inputs,
    }),
  });

  let responseJson = await response.json();
  return responseJson;
}

async function getResults(id) {
  // console.log('_init ID', id);
  let response = await fetch(`${config.apiUrl}/dash/swm/?runId=${id}`, {
    // mode: 'no-cors',
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  });

  let responseJson = await response.json();
  return responseJson;
}

async function pingImportedDataStatus(id) {
  // console.log('_init ID', id);
  let response = await fetch(`${config.apiUrl}/dash/import/?runId=${id}`, {
    // mode: 'no-cors',
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  });

  let responseJson = await response.json();
  return responseJson;
}

async function cancelRun(run_id) {
  let response = await fetch(`${config.apiUrl}/dash/swm/`, {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      run_id,
      cancel: true,
    }),
  });

  let responseJson = await response.json();
  return responseJson;
}

async function getRacks(id) {
  let token = await localStorage.getItem("access");
  if (await verifyToken(token)) {
    // GET http://localhost:8000/platform/rbi/result/?runId=a34ae313fc87420295e9417d61d18543&action=results
    let response = await fetch(`${config.apiUrl}/platform/rbi/result/?runId=${id}&action=racks`, {
      method: "GET",
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    let responseJson = await response.json();
    return responseJson;
  } else {
    let refresh = localStorage.getItem("refresh");
    let access = await refreshToken(refresh);
    if (access) {
      let response = await fetch(`${config.apiUrl}/platform/rbi/result/?runId=${id}&action=racks`, {
        method: "GET",
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${access}`,
        },
      });

      let responseJson = await response.json();
      return responseJson;
    }
  }
}

function getLayout(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${config.apiUrl}/dash/swm/layout?resultId=${id}`, requestOptions).then(handleResponse);
}

function downloadFromS3(url) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "attachment" },
  };
  return fetch(url, requestOptions).then(handleResponse);
}
function downloadFromS3toPC(url) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Disposition": "attachment" },
  };
  return fetch(url, requestOptions);
}

function saveInput(input, type) {
  console.log("ineput", input);
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };

  return fetch(`${config.apiUrl}/dash/${type}/`, requestOptions).then(handleResponse);
}

async function getTopoId(inputs) {
  // console.log("oin[its", inputs);
  // let response = await fetch(`https://api.sunfig.solar/swa-test/dash/topo/`, {
  let response = await fetch(`${config.apiUrl}/dash/topo/`, {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ ...inputs }),
  });

  let responseJson = await response.json();
  // console.log(responseJson);
  return responseJson;
}
async function checkTopoId(id) {
  let response = await fetch(`${config.apiUrl}/dash/topo/?topo_id=${id}`, {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  });

  let responseJson = await response.json();
  return responseJson;
}

async function shareCollab(inputs) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(inputs),
  };

  return fetch(`${config.apiUrl}/dash/swm/collab`, requestOptions).then(handleResponse);
}
async function refreshCollab(project_id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(`${config.apiUrl}/dash/swm/collab/?project_id=${project_id}`, requestOptions).then(handleResponse);
}

async function offsetPolygon(polygon, offset) {
  let response = await fetch(`${config.apiUrl}/dash/swm/offset`, {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ polygon, offset }),
  });

  let responseJson = await response.json();
  return responseJson;
}

function handleResponse(response) {
  return response.text().then((text) => {
    let data = text && JSON.parse(text);
    // console.log(response)
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      // console.log(data)
      // const error = (data && data.message) || response.statusText;
      const error = data || response.statusText;

      // console.log(error)
      return Promise.reject(error);
    }

    return data;
  });
}

async function runTopo(inputs) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(inputs),
  };
  let response = fetch(`${config.apiUrl}/dash/swm/topo/`, requestOptions).then((response) => response.json());
  return response;
}

async function checkTopoId2(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let response = await fetch(`${config.apiUrl}/dash/swm/topo/?topo_id=${id}`, requestOptions);
  let responseJson = await response.json();
  return responseJson;
}

async function pollForAlerts() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let response = await fetch(`${config.apiUrl}/dash/swm/alerts`, requestOptions);
  let responseJson = await response.json();
  return responseJson;
}
