import React, { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { siftActions } from "../Redux";

import { Modal, Upload, Tabs, Button, Input } from "antd";

const Dragger = Upload.Dragger;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;

import { CheckCircleOutlined, InboxOutlined, LoadingOutlined } from "@ant-design/icons";

import * as XLSX from "xlsx";

const ImportTool = (props) => {
  const [importType, setImportType] = useState("file");
  const [fileType, setFileType] = useState("");
  const [uploadFile, setUploadFile] = useState(undefined);
  const [uploadData, setUploadData] = useState(undefined);
  const [uploadText, setUploadText] = useState(undefined);
  const [fileName, setFileName] = useState(undefined);
  // could come from store
  const [uploadError, setUploadError] = useState(undefined);

  const visible = useSelector((state) => state.sift.ioManager.uiState.import_visible);
  const import_loading = useSelector((state) => state.sift.ioManager.uiState.import_loading);
  const tab = useSelector((state) => state.sift.ioManager.uiState.tab);
  const loading = useSelector((state) => state.sift.ioManager.uiState.loading);
  const dispatch = useDispatch();

  function handleLoadFile(file, fileList) {
    // let type = file.name.toUpperCase().slice(-3);
    let type = file.name.toLowerCase().split(".");
    type = type[type.length - 1];

    if ((type == "pan" && tab == "module") || (type == "ond" && tab == "inverter") || ((type == "csv" || type == "xls" || type == "xlsx") && tab == "weather")) {
      setFileType(type);
      setUploadFile(file);
      setFileName(file.name);
      setUploadError(undefined);
    } else {
      setUploadFile(undefined);
      setUploadError("Cannot Parse this file type.");
    }
  }

  function handleUpload() {
    let options = {
      data_type: importType,
      file_type: fileType,
      file_name: fileName,
      tab: tab,
      // "data": importType == 'file' ? uploadData : uploadText,
    };

    let data = importType == "file" ? uploadData : uploadText;
    if (data == undefined) {
      return;
    }

    dispatch(siftActions.importData(options, data));

    setFileType("");
    setFileName(undefined);
    setImportType("file");
    setUploadFile(undefined);
    setUploadError(undefined);
    setUploadData(undefined);
    setUploadText(undefined);
  }

  function handleImportFile() {
    if (uploadFile == undefined) return;
    var reader = new FileReader();
    reader.onloadend = function (evt) {
      if (evt.target.readyState == FileReader.DONE) {
        // DONE == 2
        var upload_data = undefined;
        var file_type = fileType.toLowerCase();
        var file_name = fileName;
        if (file_type == "xls" || file_type == "xlsx") {
          /* Parse data */
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
          /* Update state */
          upload_data = data;
          file_type = "csv";
          file_name = file_name.replace("xlsx", "csv").replace("xls", "csv");
        } else {
          upload_data = evt.target.result;
        }

        setFileName(file_name);
        setFileType(file_type);
        setUploadData(upload_data);
      }
    }.bind(this);
    reader.readAsBinaryString(uploadFile);
  }

  function handlePasteText(value) {
    setUploadText(value);
    let type = tab == "module" ? "panpvsyst" : "ondpvsyst";
    setFileType(type);
  }

  return (
    <Modal visible={visible} onCancel={() => dispatch(siftActions.toggleImportVis(false))} footer={null} width={350} className="sift-modal" destroyOnClose={true}>
      {(tab == "module" || tab == "inverter") && (
        <Tabs type="small" defaultActiveKey="file" onChange={setImportType}>
          <TabPane
            tab={
              <span>
                {tab == "module" && "PAN "}
                {tab == "inverter" && "OND "}
                File
              </span>
            }
            key="file"
          >
            <div className="file-import-body">
              {uploadData || import_loading ? (
                <div className="file-import-ready">
                  <div>
                    <p className="file-import-image">{import_loading ? <LoadingOutlined style={{ color: "#002bcb" }} /> : <CheckCircleOutlined style={{ color: "#002bcb" }} />}</p>

                    <p className="file-upload-text">
                      {fileName && <span>{fileName} ready to import</span>}
                      {import_loading && <span>Importing file...</span>}
                    </p>
                  </div>
                </div>
              ) : (
                <Dragger
                  action={null}
                  customRequest={handleImportFile}
                  beforeUpload={handleLoadFile}
                  multiple={false}
                  name="file-import"
                  accept={tab == "module" ? ".pan" : ".ond"}
                  className="sift-dragger"
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>

                  {uploadError && <p className="file-upload-error">{uploadError}</p>}
                </Dragger>
              )}
            </div>

            <Button type="primary" htmlType="submit" className="login-form-button" onClick={handleUpload} loading={import_loading}>
              Import
            </Button>
          </TabPane>
          <TabPane tab="PVSyst Text" key="text">
            <div className="file-import-body">
              <TextArea
                rows={9}
                // autosize={true}
                placeholder='Paste PVSyst "Copy to Table" text here.'
                value={uploadText}
                onChange={(e) => handlePasteText(e.target.value)}
              />
            </div>

            <Button type="primary" htmlType="submit" className="login-form-button" onClick={handleUpload} loading={import_loading}>
              Import
            </Button>
          </TabPane>
        </Tabs>
      )}
      {tab == "weather" && (
        <div>
          <div>
            <div className="tmy-instruction">
              <span>
                If your weather file fails to load, copy your data into
                <a href="https://sift-template-files.s3.us-east-2.amazonaws.com/sift_met_template.xls" download>
                  {" "}
                  this excel template
                </a>{" "}
                and then import.
              </span>
            </div>
          </div>
          <div className="file-import-body">
            {uploadData || import_loading ? (
              <div className="file-import-ready">
                <div>
                  <p className="file-import-image">{import_loading ? <LoadingOutlined style={{ color: "#002bcb" }} /> : <CheckCircleOutlined style={{ color: "#002bcb" }} />}</p>

                  <p className="file-upload-text">
                    {fileName && <span>{fileName} ready to import</span>}
                    {import_loading && <span>Importing file...</span>}
                  </p>
                </div>
              </div>
            ) : (
              <Dragger action={null} customRequest={handleImportFile} beforeUpload={handleLoadFile} multiple={false} name="file-import" accept={".csv,.xls,.xlsx"} className="sift-dragger">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>

                {uploadError && <p className="file-upload-error">{uploadError}</p>}
              </Dragger>
            )}
          </div>
          <Button type="primary" htmlType="submit" className="login-form-button" onClick={handleUpload} loading={import_loading}>
            Import
          </Button>
        </div>
      )}
    </Modal>
  );
};

export { ImportTool };
