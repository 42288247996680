import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { siftActions, getBounds, calculateTotalArea } from "../../Redux";
import * as turf from "@turf/turf";

import config from "config";

import { Radio, Input, Button, Tooltip, Popconfirm, message, Checkbox, Switch, Empty } from "antd";
import { DeleteOutlined, ImportOutlined, InfoCircleOutlined } from "@ant-design/icons";

const TopoInputs = (props) => {
  const ioManager = useSelector((state) => state.sift.ioManager);
  const inputs = ioManager.inputs.topo.data;
  const account_loading = useSelector((state) => state.account.account_loading);
  const input_loading = ioManager.uiState.input_loading;
  const topo_loading = inputs.topo_loading;
  const paid_user = useSelector((state) => state.account.current_plan > 1);
  const isSurface = useSelector((state) => Object.values(ioManager.inputs.map.features).filter((feature) => feature.properties.identity == 1).length > 0);
  const boundary_bbox = ioManager.inputs.map.boundary_bbox;
  const topo_live = inputs.topo_live;
  const dispatch = useDispatch();

  // console.log(inputs)
  // const updateInput = debounce((key, value) => {
  //   console.log(key, value);
  //   dispatch(siftActions.updateInput(key, value))

  // }, 200);

  function onTopoUpdate(key, value) {
    dispatch(siftActions.updateTopo(key, value));
  }

  function onTopoPull() {
    let boundaries = Object.values(ioManager.inputs.map.features).filter((feature) => feature.properties.identity == 1);
    if (boundaries.length == 0) {
      return;
    }

    let bbox = getBounds(boundaries);

    let bboxArea = (turf.area(turf.bboxPolygon(bbox)) / 1000000) * 100;

    let area = calculateTotalArea(boundaries);

    if (bboxArea > 10000) {
      message.error("Import topography failed. Bounding box that encloses all boundaries exceeds 10,000 hectares.");
      return;
    }
    if (area > 2000) {
      message.error("Import topography failed. Site boundaries exceed maximum allowed area of 2000 hectares. Reduce boundary area and try again");
      return;
    }

    // let topo_inputs = {
    //   //
    //   'env': config.env == 'prod' ? 'prod' : 'test',
    //   'id': inputs.topo_id,
    //   'source': inputs.topo_source,
    //   // 'id': 'c81f04f5e39f4ee7ab324c76f4dfc046',
    //   'bbox': bbox,
    //   // # 'bbox': [-120.640711, 36.695949, -120.626086, 36.703532],
    //   // # bools for which tiles to generate
    //   'generate_layers': { 'generate_ele': [true, false], 'generate_ns': [true, false], 'generate_ew': [true, false], 'generate_u': [true, false], 'generate_cf': false },
    //   // # used as limits for slope analysis
    //   'grade_limits': {
    //     'ns_grade_limit': !isNaN(parseInt(inputs.ns_grade_limit)) ? parseInt(inputs.ns_grade_limit) : 8,
    //     'ew_grade_limit': !isNaN(parseInt(inputs.ew_grade_limit)) ? parseInt(inputs.ew_grade_limit) : 20,
    //     'u_grade_limit': !isNaN(parseInt(inputs.u_grade_limit)) ? parseInt(inputs.u_grade_limit) : 15,
    //   },
    //   // # grading inputs
    //   'grading': {
    //     'enabled': false,
    //     'ns_grade_target': 0,
    //     'ew_grade_target': 0,
    //     'grade_target': 0,
    //     'grade_target_type': 0,
    //   },
    //   // map features
    //   'features': [],
    // };

    let topo_inputs = {
      source: inputs.topo_source,
      bbox: bbox,
      grade_limits: {
        // make sure these are ints
        ns_grade_limit: !isNaN(parseInt(inputs.ns_grade_limit)) ? parseInt(inputs.ns_grade_limit) : 8,
        ew_grade_limit: !isNaN(parseInt(inputs.ew_grade_limit)) ? parseInt(inputs.ew_grade_limit) : 20,
        u_grade_limit: !isNaN(parseInt(inputs.u_grade_limit)) ? parseInt(inputs.u_grade_limit) : 15,
      },
      generate_layers: { generate_ele: true, generate_ns: true, generate_ew: true, generate_u: true },
    };
    // console.log(topo_inputs)
    dispatch(siftActions.getTopoId(topo_inputs));
    // dispatch(siftActions.runTopo(topo_inputs, 'pull_ele'));
  }
  async function handlePullTopo() {
    if (ioManager.inputs.map.boundary_bbox && ioManager.inputs.map.boundary_bbox.every((x) => isFinite(x))) {
      // form inputs - this is elevation call so disable grading / layers
      let topoInputs = {
        //
        env: config.env == "prod" ? "prod" : "test",
        id: inputs.topo_id,
        source: inputs.topo_source,
        // 'id': 'c81f04f5e39f4ee7ab324c76f4dfc046',
        bbox: ioManager.inputs.map.boundary_bbox,
        // # 'bbox': [-120.640711, 36.695949, -120.626086, 36.703532],
        // # bools for which tiles to generate
        generate_layers: { generate_ele: [true, false], generate_ns: [false, false], generate_ew: [false, false], generate_u: [false, false], generate_cf: false },
        // # used as limits for slope analysis
        grade_limits: {
          ns_grade_limit: parseInt(inputs.ns_grade_limit),
          ew_grade_limit: parseInt(inputs.ew_grade_limit),
          u_grade_limit: parseInt(inputs.u_grade_limit),
        },
        // # grading inputs
        grading: {
          enabled: false,
          ns_grade_target: 0,
          ew_grade_target: 0,
          grade_target: 0,
          grade_target_type: 0,
        },
        // map features
        features: Object.values(ioManager.inputs.map.features),
      };

      // console.log(inputs);
      dispatch(siftActions.runTopo(topoInputs, "pull_ele"));
    } else {
      message.error("Draw a boundary in the map before importing Topography");
    }
  }

  function onTopoDelete() {
    dispatch(siftActions.updateInput("clearTopoData"));
  }

  async function handleCalcGrade() {
    if (inputs.grading.grade_target > 0 && inputs.grading.grade_target < 100) {
      let action = "calc_grade";
      // form inputs - this is grading call, so disable elevation / layers
      let calcInputs = {
        //
        env: config.env == "prod" ? "prod" : "test",
        id: inputs.topo_id,
        source: inputs.topo_id ? "file" : inputs.topo_source,
        // 'id': 'c81f04f5e39f4ee7ab324c76f4dfc046',
        bbox: ioManager.inputs.map.boundary_bbox,
        // # 'bbox': [-120.640711, 36.695949, -120.626086, 36.703532],
        // # bools for which tiles to generate
        generate_layers: { generate_ele: [false, false], generate_ns: [false, false], generate_ew: [false, false], generate_u: [false, false], generate_cf: true },
        // # used as limits for slope analysis
        grade_limits: {
          ns_grade_limit: parseInt(inputs.ns_grade_limit),
          ew_grade_limit: parseInt(inputs.ew_grade_limit),
          u_grade_limit: parseInt(inputs.u_grade_limit),
        },
        // # grading inputs
        grading: {
          enabled: true,
          ns_grade_target: parseFloat(inputs.grading.grade_target),
          ew_grade_target: parseFloat(inputs.grading.grade_target),
          grade_target: parseFloat(inputs.grading.grade_target),
          grade_target_type: 0,
        },
        // map features
        features: Object.values(ioManager.inputs.map.features),
      };
      // console.log(inputs);
      dispatch(siftActions.runTopo(calcInputs, action));
    } else {
      message.error("Grade Target needs to be between 1-100");
    }
  }
  async function handleCreateLayers(clear = false) {
    if (
      inputs.ns_grade_enabled ||
      inputs.ew_grade_enabled ||
      inputs.u_grade_enabled ||
      inputs.ns_grade_raw_enabled ||
      inputs.ew_grade_raw_enabled ||
      inputs.u_grade_raw_enabled ||
      inputs.ele_use_graded_data
    ) {
      let gen_ele = [false, inputs.ele_use_graded_data];
      // form inputs - this is layers call, so disable elevation / grading
      let createLayerInputs = {
        //
        env: config.env == "prod" ? "prod" : "test",
        id: inputs.topo_id,
        source: "file",
        // 'id': 'c81f04f5e39f4ee7ab324c76f4dfc046',
        bbox: ioManager.inputs.map.boundary_bbox,
        // # 'bbox': [-120.640711, 36.695949, -120.626086, 36.703532],
        // # bools for which tiles to generate
        generate_layers: {
          generate_ele: gen_ele,
          generate_ns: [inputs.ns_grade_raw_enabled, inputs.ns_grade_enabled],
          generate_ew: [inputs.ew_grade_raw_enabled, inputs.ew_grade_enabled],
          generate_u: [inputs.u_grade_raw_enabled, inputs.u_grade_enabled],
          // 'generate_ns': [false, inputs.ns_grade_enabled],
          // 'generate_ew': [false, inputs.ew_grade_enabled],
          // 'generate_u': [false, inputs.u_grade_enabled],
          generate_cf: false,
        },
        // # used as limits for slope analysis
        grade_limits: {
          ns_grade_limit: parseInt(inputs.ns_grade_limit),
          ew_grade_limit: parseInt(inputs.ew_grade_limit),
          u_grade_limit: parseInt(inputs.u_grade_limit),
        },
        // # grading inputs
        grading: {
          enabled: false,
          ns_grade_target: 0,
          ew_grade_target: 0,
          grade_target: 0,
          grade_target_type: 0,
        },
        // map features
        features: Object.values(ioManager.inputs.map.features),
      };
      if (inputs.topo_id === undefined) {
        message.error("Topography ID Error, refresh project to continue.");
        return;
      }
      // console.log(inputs);
      dispatch(siftActions.runTopo(createLayerInputs, "gen_layers"));
    } else {
      message.error("Grade Target needs to be between 1-100");
    }
  }

  return (
    <div className="input-content-box">
      <Fragment>
        <div className="input-row topo-source">
          <label>Source:</label>
          {!topo_live ? (
            <Radio.Group name="radiogroup" style={{ marginLeft: "auto" }} defaultValue={inputs.topo_source} onChange={(e) => onTopoUpdate("topo_source", e.target.value)} disabled={topo_loading}>
              <Radio value={"USGS"}>USGS</Radio>
              <Radio value={"Google"}>Google</Radio>
            </Radio.Group>
          ) : (
            <span>{inputs.topo_source || `USGS`}</span>
          )}
        </div>

        <div className="input-row one">
          <label>Slope Analysis Layers:</label>
        </div>

        <div className="input-row three">
          <label>Max Slope</label>
          <span>Limit</span>
          <Input
            size="small"
            value={inputs.u_grade_limit}
            id="u_grade_limit"
            disabled={topo_loading || topo_live}
            onChange={(e) => {
              onTopoUpdate(e.target.id, e.target.value);
            }}
            suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
          />
        </div>

        <div className="input-row three">
          <label>North-South Slope</label>
          <span>Limit</span>
          <Input
            size="small"
            value={inputs.ns_grade_limit}
            id="ns_grade_limit"
            disabled={topo_loading || topo_live}
            onChange={(e) => {
              onTopoUpdate(e.target.id, e.target.value);
            }}
            suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
          />
        </div>

        <div className="input-row three">
          <label>East-West Slope</label>
          <span>Limit</span>
          <Input
            size="small"
            value={inputs.ew_grade_limit}
            id="ew_grade_limit"
            disabled={topo_loading || topo_live}
            onChange={(e) => {
              onTopoUpdate(e.target.id, e.target.value);
            }}
            suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
          />
        </div>

        <div className="input-row one">
          <span>
            {!topo_live ? (
              <Tooltip placement="right" title={!paid_user ? "Topography import only available for paid plans." : null}>
                <Button type="default" icon={<ImportOutlined />} disabled={!paid_user || !isSurface} loading={topo_loading} onClick={onTopoPull}>
                  Import Topography
                </Button>
              </Tooltip>
            ) : (
              <Popconfirm
                title="Delete Topography?"
                onConfirm={() => {
                  onTopoDelete();
                }}
              >
                <Button type="default" icon={<DeleteOutlined />} disabled={!isSurface} loading={topo_loading}>
                  Delete Topography
                </Button>
              </Popconfirm>
            )}
          </span>
        </div>
      </Fragment>
    </div>
  );
};

export { TopoInputs };
