export function ValidateEmail(email) 
{
 if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))
  {
    return (true)
  }
    // alert("You have entered an invalid email address!")
    return (false)
}

export function getTime(editDate) {

	var date = new Date(editDate * 1000)
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? 'pm' : 'am';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes;
	var strTime = hours + ':' + minutes + ' ' + ampm;

	let format_d = `${strTime} - ${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`

	return format_d.toString();
}