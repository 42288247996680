import React, { Fragment, useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";
import { siftActions, authActions, accountActions, isAuthenticated, tutorialActions, Alert } from "../../components";

import { Tooltip, Menu, Popconfirm, Button } from "antd";

const { SubMenu } = Menu;

import { logo, logo_small, logo_panels, question_mark, price, ts_logo } from "../../assets/images";

//tutorial stuff
import { TutorialTip } from "../../components/TutorialTip";

// icons
import {
  CloudOutlined,
  UserOutlined,
  DiffOutlined,
  LogoutOutlined,
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
  LoginOutlined,
  BugOutlined,
  QuestionCircleOutlined,
  CaretRightOutlined,
  HomeOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";

// sc
import { Nav } from "./StyledComponents";
import { importManager } from "less";

const Navigation = () => {
  const isAuthed = useSelector((state) => isAuthenticated(state));
  const show_agreement_modal = useSelector((state) => state.authentication.show_agreement_modal);
  const system_alerts = useSelector((state) => state.sift.system_alerts);

  const screenWidth = useSelector((state) => state.sift.ioManager.uiState.screenWidth);
  const [selectedKey, setSelectedKey] = useState(undefined);
  const [toggleMobileNav, setToggleMobileNav] = useState(false);

  //Tutorial Controls
  const tutorial = useSelector((state) => state.tutorial);

  const dispatch = useDispatch();

  useEffect(() => {
    if (tutorial.tutorialPath == 0) {
      switch (tutorial.currentIndex) {
        case 13:
          setToggleMobileNav(true);
          break;
        case 14:
          setToggleMobileNav(true);
          break;
        case 15:
          setToggleMobileNav(false);
          break;
        default:
          break;
      }
    }
  }, [tutorial.currentIndex]);

  // Used by both menu's in the nav
  function handleMenuClick(key) {
    if (key == "bug") {
      dispatch(siftActions.toggleHelpToolbarVisibility("bug_visible", true));
    }
    if (key == "patch") {
      dispatch(siftActions.toggleHelpToolbarVisibility("patch_visible", true));
    }
    if (key == "login") {
      dispatch(authActions.toggleLoginVis(true, "login"));
    }
    if (key == "help") {
      dispatch(siftActions.toggleHelpToolbarVisibility("helper_visible", true));
    }
    // if (key == 'collab') {
    //   dispatch(siftActions.toggleCollabModal());
    // }
    if (key == "account") {
      if (isAuthed) {
        dispatch(siftActions.toggleAccountModal());
      } else {
        dispatch(accountActions.toggleFunnelVis(true));
      }
    }
  }

  // DEPRECATE THIS
  function handleButtonClick(event) {
    dispatch(authActions.logout());
  }

  return (
    <Fragment>
      <Nav toggleMobileNav={toggleMobileNav} isAuthed={isAuthed} show_agreement_modal={show_agreement_modal}>
        {/* logo */}

        <div className="brand-logo">
          {screenWidth >= 768 ? (
            <a href="http://www.Terrasmart.com/products/software/sift/" target="_blank" rel="noopener noreferrer">
              <img src={ts_logo} />
            </a>
          ) : (
            <Button type="link" onClick={() => setToggleMobileNav(!toggleMobileNav)}>
              <img src={ts_logo} />
            </Button>
          )}
        </div>

        <div className="mobile-background"></div>

        <div className={screenWidth <= 768 ? (toggleMobileNav ? "nav-links open" : "nav-links closed") : "nav-links"}>
          {/* {screenWidth <= 1024 && (
            <Tooltip placement="right" title="https://www.sunfig.com">
              <button href="https://www.sunfig.com" onClick={() => (window.location.href = 'https://www.sunfig.com')}>
                <HomeOutlined style={{ fontSize: '18px' }} />
              </button>
            </Tooltip>
          )} */}
          {system_alerts.poll_message.length > 0 && <Alert />}

          <Tooltip placement="right" title="Help">
            <button id="help-tip" style={{ border: tutorial.visibleTips.help_docs ? "2px solid #002bcb" : "2px solid transparent" }} onClick={() => handleMenuClick("help")}>
              <TutorialTip
                elementKey="help-tip"
                visible={tutorial.visibleTips.help_docs}
                highlight={false}
                placement="right"
                content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.help_docs}
              >
                <QuestionCircleOutlined />
              </TutorialTip>
            </button>
          </Tooltip>

          {/* {isAuthed && (
            <Tooltip placement="right" title="Collaborate">
              <button onClick={() => handleMenuClick('collab')}>
                <CloudOutlined />
              </button>
            </Tooltip>
          )} */}

          <Tooltip placement="right" title="Send Bug Report">
            <button id="bug-tip" style={{ border: tutorial.visibleTips.bug_report ? "2px solid #002bcb" : "2px solid transparent" }} onClick={() => handleMenuClick("bug")}>
              <TutorialTip
                elementKey="bug-tip"
                visible={tutorial.visibleTips.bug_report}
                highlight={false}
                placement="right"
                content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.bug_report}
              >
                <BugOutlined />
              </TutorialTip>
            </button>
          </Tooltip>

          <Tooltip placement="right" title="Patch Notes">
            <button onClick={() => handleMenuClick("patch")}>
              <DiffOutlined />
            </button>
          </Tooltip>

          <Tooltip placement="right" title="See Pricing">
            <button onClick={() => dispatch(accountActions.toggleFunnelVis(true))}>
              <img src={price} />
            </button>
          </Tooltip>

          <Tooltip placement="right" title="Account">
            <button onClick={() => handleMenuClick("account")}>
              <UserOutlined />
            </button>
          </Tooltip>

          {isAuthed && (
            <Tooltip placement="right" title="Logout">
              <Popconfirm title="Logout?" onConfirm={(event) => handleButtonClick(event)} okText="Yes" cancelText="No">
                <button>
                  <LogoutOutlined />
                </button>
              </Popconfirm>
            </Tooltip>
          )}
          {/* : (
            <Tooltip placement="right" title="Login">
              <button onClick={() => handleMenuClick('login')}>
                <LoginOutlined />
              </button>
            </Tooltip>
          )} */}
        </div>

        {/* routes */}
        {/* <div className="nav-links">
          <Menu onClick={handleMenuClick} selectedKeys={[selectedKey]} className="nav-menu">
            <Menu.Item key="collab" className="nav-button">
              <Tooltip placement="top" title="Collaborate">
                <div className="nav-icon">
                  <CloudOutlined />
                </div>
              </Tooltip>
            </Menu.Item>

            <Menu.Item key="help" className="nav-button">
              <Tooltip placement="top" title="Help">
                <div className="nav-icon">
                  <QuestionCircleOutlined />
                </div>
              </Tooltip>
            </Menu.Item>

            <Menu.Item key="bug" className="nav-button">
              <Tooltip placement="top" title="Send bug report">
                <BugOutlined />
              </Tooltip>
            </Menu.Item>

            <Menu.Item key="patch" className="nav-button">
              <Tooltip placement="top" title="Patch Notes">
                <DiffOutlined />
              </Tooltip>
            </Menu.Item>

            <Menu.Item key="account" className="nav-button">
              <Tooltip placement="top" title="Account">
                <div className="nav-icon">
                  <UserOutlined />
                </div>
              </Tooltip>
            </Menu.Item>

            <Menu.Item key="logout" className="nav-button">
              <Tooltip placement="top" title="Logout">
                <Popconfirm title="Logout?" onConfirm={(event) => handleButtonClick(event)} okText="Yes" cancelText="No">
                  <Button
                    type="link"
                    size="large"
                    icon={<LogoutOutlined />}
                    // onClick={this.showPatchNotes}
                    className="right button icon"
                  />
                </Popconfirm>
              </Tooltip>
            </Menu.Item>
          </Menu>
        </div> */}

        {/* help, bug report, logout */}
        {/* <div className="support-container">
          <Menu onClick={handleMenuClick} selectedKeys={[selectedKey]} className="support-menu">
            <Menu.Item key="patch" className="nav-button">
              <Tooltip placement="right" title="Patch Notes">
                <DiffOutlined />
              </Tooltip>
            </Menu.Item>

            <Menu.Item key="bug" className="nav-button">
              <Tooltip placement="right" title="Send bug report">
                <BugOutlined />
              </Tooltip>
            </Menu.Item>

            <Menu.Item key="logout" className="nav-button">
              <Tooltip placement="right" title="Logout">
                <Popconfirm title="Logout?" onConfirm={(event) => handleButtonClick(event)} okText="Yes" cancelText="No">
                  <Button
                    type="link"
                    size="large"
                    icon={<LogoutOutlined />}
                    // onClick={this.showPatchNotes}
                    className="right button icon"
                  />
                </Popconfirm>
              </Tooltip>
            </Menu.Item>
          </Menu>
        </div> */}
      </Nav>
    </Fragment>
  );
};

Navigation.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Navigation);
