import { divIcon } from "leaflet";

export function getColumns(code, sortedInfo) {
  console.log("soreted", sortedInfo);
  if (code == 150) {
    return [
      {
        title: "GCR",
        dataIndex: "gcr",
        key: "gcr",
        width: "85px",
        sorter: (a, b) => a["gcr"] - b["gcr"],
        render: (text, record, index) => parseFloat(text.toFixed(5)),
        sortOrder: sortedInfo.columnKey === "gcr" && sortedInfo.order,
      },
      {
        title: "Pitch (m)",
        dataIndex: "pitch",
        key: "pitch",
        width: "85px",
        sorter: (a, b) => a["pitch"] - b["pitch"],
        sortOrder: sortedInfo.columnKey === "pitch" && sortedInfo.order,
      },
      {
        title: "Tilt (°)",
        dataIndex: "tilt",
        key: "tilt",
        width: "85px",
        sorter: (a, b) => a["tilt"] - b["tilt"],
        sortOrder: sortedInfo.columnKey === "tilt" && sortedInfo.order,
      },
      {
        title: "Azimuth (°)",
        dataIndex: "azimuth",
        key: "azimuth",
        width: "85px",
        sorter: (a, b) => a["azimuth"] - b["azimuth"],
        sortOrder: sortedInfo.columnKey === "azimuth" && sortedInfo.order,
      },
      {
        title: "Yield (kWh/kWp)",
        dataIndex: "YIELD",
        key: "YIELD",
        width: "120px",
        sorter: (a, b) => a["YIELD"] - b["YIELD"],
        sortOrder: sortedInfo.columnKey === "YIELD" && sortedInfo.order,
      },
      // {
      //   title: 'DC:AC',
      //   dataIndex: 'dcac',
      //   key: 'dcac',
      //   width: '120px',
      //   sorter: (a, b) => a['dcac'] - b['dcac'],
      //   render: (text, record, index) => parseFloat(text.toFixed(5)),
      //   sortOrder: sortedInfo.columnKey === 'dcac' && sortedInfo.order,
      // },
      {
        title: "DC Capacity (MWp)",
        dataIndex: "mw_peak",
        key: "mw_peak",
        width: "120px",
        sorter: (a, b) => a["mw_peak"] - b["mw_peak"],
        sortOrder: sortedInfo.columnKey === "mw_peak" && sortedInfo.order,
      },
      {
        title: "AC Capacity (MW)",
        dataIndex: "mw_ac",
        key: "mw_ac",
        width: "120px",
        sorter: (a, b) => a["mw_ac"] - b["mw_ac"],
        sortOrder: sortedInfo.columnKey === "mw_ac" && sortedInfo.order,
      },
      {
        title: "Generation Yr1 (MWh)",
        dataIndex: "generation",
        key: "generation",
        width: "200px",
        sorter: (a, b) => a["generation"] - b["generation"],
        sortOrder: sortedInfo.columnKey === "generation" && sortedInfo.order,
      },
      {
        title: "Module Qty",
        dataIndex: "module_count",
        key: "module_count",
        width: "120px",
        sorter: (a, b) => a["module_count"] - b["module_count"],
        sortOrder: sortedInfo.columnKey === "module_count" && sortedInfo.order,
      },
      {
        title: "Inverter Qty",
        dataIndex: "inverter_count",
        key: "inverter_count",
        width: "85px",
        sorter: (a, b) => a["inverter_count"] - b["inverter_count"],
        sortOrder: sortedInfo.columnKey === "inverter_count" && sortedInfo.order,
      },
      {
        title: "Strings in Calculation",
        dataIndex: "string_count",
        key: "string_count",
        width: "120px",
        sorter: (a, b) => a["string_count"] - b["string_count"],
        sortOrder: sortedInfo.columnKey === "string_count" && sortedInfo.order,
      },
      {
        title: "Strings in Plot",
        dataIndex: "string_count_in_plot",
        key: "string_count_in_plot",
        width: "120px",
        sorter: (a, b) => a["string_count_in_plot"] - b["string_count_in_plot"],
        sortOrder: sortedInfo.columnKey === "string_count_in_plot" && sortedInfo.order,
      },
    ];
  }
  if (code == 151) {
    return [
      {
        title: "GCR",
        dataIndex: "gcr",
        key: "gcr",
        width: "85px",
        sorter: (a, b) => a["gcr"] - b["gcr"],
        render: (text, record, index) => parseFloat(text.toFixed(5)),
        sortOrder: sortedInfo.columnKey === "gcr" && sortedInfo.order,
      },
      {
        title: "Pitch (m)",
        dataIndex: "pitch",
        key: "pitch",
        width: "85px",
        sorter: (a, b) => a["pitch"] - b["pitch"],
        sortOrder: sortedInfo.columnKey === "pitch" && sortedInfo.order,
      },
      {
        title: "Tilt (°)",
        dataIndex: "tilt",
        key: "tilt",
        width: "85px",
        sorter: (a, b) => a["tilt"] - b["tilt"],
        sortOrder: sortedInfo.columnKey === "tilt" && sortedInfo.order,
      },
      {
        title: "Azimuth (°)",
        dataIndex: "azimuth",
        key: "azimuth",
        width: "85px",
        sorter: (a, b) => a["azimuth"] - b["azimuth"],
        sortOrder: sortedInfo.columnKey === "azimuth" && sortedInfo.order,
      },
      {
        title: "Yield (kWh/kWp)",
        dataIndex: "YIELD",
        key: "YIELD",
        width: "120px",
        sorter: (a, b) => a["YIELD"] - b["YIELD"],
        sortOrder: sortedInfo.columnKey === "YIELD" && sortedInfo.order,
      },
      {
        title: "LCOE",
        dataIndex: "lcoe",
        key: "lcoe",
        width: "120px",
        sorter: (a, b) => a["lcoe"] - b["lcoe"],
        sortOrder: sortedInfo.columnKey === "lcoe" && sortedInfo.order,
      },
      {
        title: "DC:AC",
        dataIndex: "dcac",
        key: "dcac",
        width: "120px",
        sorter: (a, b) => a["dcac"] - b["dcac"],
        render: (text, record, index) => parseFloat(text.toFixed(5)),
        sortOrder: sortedInfo.columnKey === "dcac" && sortedInfo.order,
      },
      {
        title: "DC Capacity (MWp)",
        dataIndex: "mw_peak",
        key: "mw_peak",
        width: "120px",
        sorter: (a, b) => a["mw_peak"] - b["mw_peak"],
        sortOrder: sortedInfo.columnKey === "mw_peak" && sortedInfo.order,
      },
      {
        title: "AC Capacity (MW)",
        dataIndex: "mw_ac",
        key: "mw_ac",
        width: "120px",
        sorter: (a, b) => a["mw_ac"] - b["mw_ac"],
        sortOrder: sortedInfo.columnKey === "mw_ac" && sortedInfo.order,
      },
      {
        title: "Generation Yr1 (MWh)",
        dataIndex: "generation",
        key: "generation",
        width: "200px",
        sorter: (a, b) => a["generation"] - b["generation"],
        sortOrder: sortedInfo.columnKey === "generation" && sortedInfo.order,
      },
      {
        title: "Module Qty",
        dataIndex: "module_count",
        key: "module_count",
        width: "120px",
        sorter: (a, b) => a["module_count"] - b["module_count"],
        sortOrder: sortedInfo.columnKey === "module_count" && sortedInfo.order,
      },
      {
        title: "Inverter Qty",
        dataIndex: "inverter_count",
        key: "inverter_count",
        width: "85px",
        sorter: (a, b) => a["inverter_count"] - b["inverter_count"],
        sortOrder: sortedInfo.columnKey === "inverter_count" && sortedInfo.order,
      },
      {
        title: "Strings in Calculation",
        dataIndex: "string_count",
        key: "string_count",
        width: "120px",
        sorter: (a, b) => a["string_count"] - b["string_count"],
        sortOrder: sortedInfo.columnKey === "string_count" && sortedInfo.order,
      },
      {
        title: "Strings in Plot",
        dataIndex: "string_count_in_plot",
        key: "string_count_in_plot",
        width: "120px",
        sorter: (a, b) => a["string_count_in_plot"] - b["string_count_in_plot"],
        sortOrder: sortedInfo.columnKey === "string_count_in_plot" && sortedInfo.order,
      },
    ];
  }
  if (code == 152) {
    return [
      {
        title: "GCR",
        dataIndex: "gcr",
        key: "gcr",
        width: "85px",
        sorter: (a, b) => a["gcr"] - b["gcr"],
        render: (text, record, index) => parseFloat(text.toFixed(5)),
        sortOrder: sortedInfo.columnKey === "gcr" && sortedInfo.order,
      },
      {
        title: "Pitch (m)",
        dataIndex: "pitch",
        key: "pitch",
        width: "85px",
        sorter: (a, b) => a["pitch"] - b["pitch"],
        sortOrder: sortedInfo.columnKey === "pitch" && sortedInfo.order,
      },
      {
        title: "Tilt (°)",
        dataIndex: "tilt",
        key: "tilt",
        width: "85px",
        sorter: (a, b) => a["tilt"] - b["tilt"],
        sortOrder: sortedInfo.columnKey === "tilt" && sortedInfo.order,
      },
      {
        title: "Azimuth (°)",
        dataIndex: "azimuth",
        key: "azimuth",
        width: "85px",
        sorter: (a, b) => a["azimuth"] - b["azimuth"],
        sortOrder: sortedInfo.columnKey === "azimuth" && sortedInfo.order,
      },
      {
        title: "Yield (kWh/kWp)",
        dataIndex: "YIELD",
        key: "YIELD",
        width: "120px",
        sorter: (a, b) => a["YIELD"] - b["YIELD"],
        sortOrder: sortedInfo.columnKey === "YIELD" && sortedInfo.order,
      },
      ,
      {
        title: "IRR (%)",
        dataIndex: "irr",
        key: "irr",
        width: "120px",
        sorter: (a, b) => a["irr"] - b["irr"],
        render: (text, record, index) => parseFloat(text.toFixed(3)),
        sortOrder: sortedInfo.columnKey === "irr" && sortedInfo.order,
      },
      {
        title: "DC:AC",
        dataIndex: "dcac",
        key: "dcac",
        width: "120px",
        sorter: (a, b) => a["dcac"] - b["dcac"],
        render: (text, record, index) => parseFloat(text.toFixed(5)),
        sortOrder: sortedInfo.columnKey === "dcac" && sortedInfo.order,
      },
      {
        title: "DC Capacity (MWp)",
        dataIndex: "mw_peak",
        key: "mw_peak",
        width: "120px",
        sorter: (a, b) => a["mw_peak"] - b["mw_peak"],
        sortOrder: sortedInfo.columnKey === "mw_peak" && sortedInfo.order,
      },
      {
        title: "AC Capacity (MW)",
        dataIndex: "mw_ac",
        key: "mw_ac",
        width: "120px",
        sorter: (a, b) => a["mw_ac"] - b["mw_ac"],
        sortOrder: sortedInfo.columnKey === "mw_ac" && sortedInfo.order,
      },
      {
        title: "Generation Yr1 (MWh)",
        dataIndex: "generation",
        key: "generation",
        width: "200px",
        sorter: (a, b) => a["generation"] - b["generation"],
        sortOrder: sortedInfo.columnKey === "generation" && sortedInfo.order,
      },
      {
        title: "Module Qty",
        dataIndex: "module_count",
        key: "module_count",
        width: "120px",
        sorter: (a, b) => a["module_count"] - b["module_count"],
        sortOrder: sortedInfo.columnKey === "module_count" && sortedInfo.order,
      },
      {
        title: "Inverter Qty",
        dataIndex: "inverter_count",
        key: "inverter_count",
        width: "85px",
        sorter: (a, b) => a["inverter_count"] - b["inverter_count"],
        sortOrder: sortedInfo.columnKey === "inverter_count" && sortedInfo.order,
      },
      {
        title: "Strings in Calculation",
        dataIndex: "string_count",
        key: "string_count",
        width: "120px",
        sorter: (a, b) => a["string_count"] - b["string_count"],
        sortOrder: sortedInfo.columnKey === "string_count" && sortedInfo.order,
      },
      {
        title: "Strings in Plot",
        dataIndex: "string_count_in_plot",
        key: "string_count_in_plot",
        width: "120px",
        sorter: (a, b) => a["string_count_in_plot"] - b["string_count_in_plot"],
        sortOrder: sortedInfo.columnKey === "string_count_in_plot" && sortedInfo.order,
      },
    ];
  }
}

export function resultColumns(code) {
  return [
    // {
    //   title: 'Module',
    //   dataIndex: 'module_name',
    //   key: 'module_name',
    //   width: 'auto',
    //   visible: true,
    //   sorter: (a, b) => a['module_name'] - b['module_name'],
    // },
    // {
    //   title: 'Module Rating',
    //   dataIndex: 'module_rating',
    //   key: 'module_rating',
    //   width: 'auto',
    //   visible: true,
    //   sorter: (a, b) => a['module_rating'] - b['module_rating'],
    // },
    {
      title: "GCR",
      dataIndex: "gcr",
      key: "gcr",
      width: "85px",
      visible: true,
      sorter: (a, b) => a["gcr"] - b["gcr"],
      render: (text) => parseFloat(text.toFixed(5)),
    },
    {
      title: "Pitch",
      dataIndex: "pitch",
      key: "pitch",
      width: "85px",
      visible: true,
      sorter: (a, b) => a["pitch"] - b["pitch"],
    },
    {
      title: "Tilt",
      dataIndex: "tilt",
      key: "tilt",
      width: "85px",
      visible: true,
      sorter: (a, b) => a["tilt"] - b["tilt"],
    },
    {
      title: "Azimuth",
      dataIndex: "azimuth",
      key: "azimuth",
      width: "85px",
      visible: true,
      sorter: (a, b) => a["azimuth"] - b["azimuth"],
    },
    {
      title: "Yield",
      dataIndex: "YIELD",
      key: "YIELD",
      width: "120px",
      visible: true,
      sorter: (a, b) => a["YIELD"] - b["YIELD"],
    },
    {
      title: "IRR (%)",
      dataIndex: "irr",
      key: "irr",
      width: "120px",
      visible: code == 152,
      sorter: (a, b) => a["irr"] - b["irr"],
      render: (text, record, index) => parseFloat(text.toFixed(3)),
    },
    {
      title: "LCOE",
      dataIndex: "lcoe",
      key: "lcoe",
      width: "120px",
      visible: code == 151,
      sorter: (a, b) => a["lcoe"] - b["lcoe"],
    },
    {
      title: "DC:AC",
      dataIndex: "dcac",
      key: "dcac",
      width: "120px",
      visible: true,
      sorter: (a, b) => a["dcac"] - b["dcac"],
      render: (text, record, index) => parseFloat(text).toFixed(5),
    },
    {
      title: "MWp",
      dataIndex: "mw_peak",
      key: "mw_peak",
      width: "120px",
      visible: true,
      sorter: (a, b) => a["mw_peak"] - b["mw_peak"],
    },
    {
      title: "MWac",
      dataIndex: "mw_ac",
      key: "mw_ac",
      width: "120px",
      visible: true,
      sorter: (a, b) => a["mw_ac"] - b["mw_ac"],
      render: (text, record, index) => parseFloat(text).toFixed(5),
    },
    {
      title: "Yr1 MWh",
      dataIndex: "generation",
      key: "generation",
      width: "200px",
      visible: true,
      sorter: (a, b) => a["generation"] - b["generation"],
    },
    {
      title: "Modules",
      dataIndex: "module_count",
      key: "module_count",
      width: "120px",
      visible: false,
      sorter: (a, b) => a["module_count"] - b["module_count"],
    },
    {
      title: "Inverters",
      dataIndex: "inverter_count",
      key: "inverter_count",
      width: "85px",
      visible: true,
      sorter: (a, b) => a["inverter_count"] - b["inverter_count"],
    },
    {
      title: "Strings in Calculation",
      dataIndex: "string_count",
      key: "string_count",
      width: "120px",
      visible: false,
      sorter: (a, b) => a["string_count"] - b["string_count"],
    },
    {
      title: "Strings in Plot",
      dataIndex: "string_count_in_plot",
      key: "string_count_in_plot",
      width: "120px",
      visible: false,
      sorter: (a, b) => a["string_count_in_plot"] - b["string_count_in_plot"],
    },
  ];
}
