import { authConstants } from "../_constants";
import { userService, authService } from "../_services";
import { alertActions, accountActions } from "./";
import { history } from "../_helpers";
import { resolveOnChange } from "antd/lib/input/Input";
import { resolve } from "path";
import LogRocket from "logrocket";

export const authActions = {
  login,
  continueLogin,
  logout,
  refreshToken,
  register,
  pwReset,
  toggleLoginVis,
  toggleLoginTab,
  identifyUser,
  showTerms,
  showAgreementModal,
  agreementDeclined,
  agreementAccepted,
};

function toggleLoginVis(visible, tab) {
  return (dispatch) => {
    dispatch({
      type: authConstants.LOGIN_VISIBILITY_TOGGLE,
      visible,
      tab,
    });
  };
}

function toggleLoginTab(tab) {
  return (dispatch) => {
    dispatch({
      type: authConstants.LOGIN_TAB_TOGGLE,
      tab,
    });
  };
}

function login(username, password, forward = false) {
  //temp tad agreement variable
  // let tac = localStorage.getItem("TOS_Agreement");
  return (dispatch) => {
    dispatch(
      request({
        username,
      })
    );
    userService.login(username, password, forward).then(
      (user) => {
        dispatch(success(user, forward));
        if (forward) {
          window.scrollTo(0, 0);
          history.push("/sift");
        }
				// console.log('login-action', user.show_agreement_modal)
        if ( user.terrasmart_terms == undefined || user.terrasmart_terms === false) {
          // dispatch(authActions.logout());
          dispatch(authActions.showAgreementModal(true));
          return;
        } else {
          dispatch(accountActions.getAll());
          dispatch(authActions.identifyUser(user.access.user_id, username));
        }
      },
      (error) => {
        // console.log(error)
        // dispatch(failure(error.toString()));
        dispatch(failure("Incorrect email or password"));
        dispatch(alertActions.error("Incorrect email or password"));
      }
    );
  };

  function request(user) {
    return {
      type: authConstants.LOGIN_REQUEST,
      user,
    };
  }

  function success(user, forward) {
    return {
      type: authConstants.LOGIN_SUCCESS,
      user,
      forward,
    };
  }

  function failure(error) {
    return {
      type: authConstants.LOGIN_FAILURE,
      error,
    };
  }
}
function showAgreementModal(visible) {
  return {
    type: authConstants.TOGGLE_NEW_AGREEMENT_MODAL,
    visible,
  };
}

function continueLogin(user) {
  return (dispatch) => {
		// console.log(user)
		if ( user.terrasmart_terms == undefined || user.terrasmart_terms === false) {
			// dispatch(authActions.showAgreementModal(true));
			dispatch(authActions.logout());
			return;
		} else {
			dispatch(success(user));
			dispatch(accountActions.getAll());
			dispatch(authActions.identifyUser(user.access.user_id));
		}		
  };

  function success(user) {
    return {
      type: authConstants.CONTINUE_LOGIN,
      user,
    };
  }
}

function showTerms(visible, openedFromAgreementUpdate = false) {
  return {
    type: authConstants.SHOW_TERMS,
    visible,
    openedFromAgreementUpdate,
  };
}

function agreementAccepted(user) {
  return (dispatch) => {
    dispatch(acceptedRequest());

    userService.agreeToTerms().then(
      (response) => {
				dispatch(authActions.continueLogin(user));
			}
    );		
    // localStorage.setItem("TOS_Agreement", 1);
  };
  function acceptedRequest() {
    return {
      type: authConstants.AGREEMENT_ACCEPTED,
    };
  }
}

function agreementDeclined() {
  return (dispatch) => {
    dispatch(declinedRequest());
    // localStorage.setItem("TOS_Agreement", -1);
    setTimeout(() => {
      dispatch(authActions.logout());
    }, 1000);
  };
  function declinedRequest() {
    return {
      type: authConstants.AGREEMENT_DECLINED,
    };
  }
}

function logout() {
  return (dispatch) => {
    dispatch(request());
    userService.logout();
  };
  // return { type: authConstants.LOGOUT };
  function request() {
    return {
      type: authConstants.LOGOUT,
    };
  }
}

function refreshToken(refresh) {
  return (dispatch) => {
    dispatch(
      request({
        refresh,
      })
    );

    userService.refreshToken(refresh).then(
      (access) => {
        dispatch(success(access, refresh));
        dispatch(accountActions.getAll());
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(refresh) {
    return {
      type: authConstants.REFRESH_TOKEN_REQUEST,
      refresh,
    };
  }

  function success(access, refresh) {
    return {
      type: authConstants.REFRESH_TOKEN_SUCCESS,
      access,
      refresh,
    };
  }

  function failure(error) {
    return {
      type: authConstants.REFRESH_TOKEN_FAILURE,
      error,
    };
  }
}

function register(user, forward) {
  return (dispatch) => {
    dispatch(request(user));
    let saved_user = user;
    userService.register(user).then(
      // data => console.log(data)
      (data) => {
        dispatch(success());
        dispatch(alertActions.success("Registration successful"));

        dispatch(authActions.login(saved_user.email, saved_user.password1, forward));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return {
      type: authConstants.REGISTER_REQUEST,
      user,
    };
  }

  function success(user) {
    return {
      type: authConstants.REGISTER_SUCCESS,
      user,
    };
  }

  function failure(error) {
    return {
      type: authConstants.REGISTER_FAILURE,
      error,
    };
  }
}

function pwReset(email) {
  return (dispatch) => {
    dispatch(request());
    userService.pwReset(email).then(
      (email) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: authConstants.PWRESET_REQUEST,
    };
  }

  function success() {
    return {
      type: authConstants.PWRESET_SUCCESS,
    };
  }

  function failure(error) {
    return {
      type: authConstants.PWRESET_FAILURE,
      error,
    };
  }
}
function identifyUser(user_id, email = undefined) {
  return (dispatch) => {
    if (process.env.NODE_ENV === "production") {
      if (email) {
        LogRocket.identify(user_id, { email: email });
      } else {
        LogRocket.identify(user_id);
      }
    }
  };
}
