// prettier-ignore
import * as turf from "@turf/turf";
import { getBounds } from "../";
import { checkCoordSystemBounds, checkSiteLocation } from "./";
import { ModulesDD, InvertersDD, RackingDD, PerformanceDD, WeatherDD, FinanceDD, ConfigDefault } from "../../InputPanel";

const tabDefaults = {
  module: JSON.parse(JSON.stringify(ModulesDD["364aa9e4cee04535aed7806302f3f652"])),
  inverter: JSON.parse(JSON.stringify(InvertersDD["0f14d6b175444d6699dfe4d69f32c243"])),
  racking: JSON.parse(JSON.stringify(RackingDD["49aa35992cf4480e9a2f1152c43edcda"])),
  weather: JSON.parse(JSON.stringify(WeatherDD["0"])),
  performance: JSON.parse(JSON.stringify(PerformanceDD["0"])),
  finance: JSON.parse(JSON.stringify(FinanceDD["0"])),
  config: JSON.parse(JSON.stringify(ConfigDefault)),
};

export function validateInputs(inputs) {
  // console.log("validating SIFT inputs", inputs);
  let errors = [];
  let warnings = [];

  // map feature validation
  if (inputs.features.length == 0) {
    errors.push(
      "SIFT requires at least one project boundary to run. Use the draw tool to define your project boundaries, or drop a KMZ into the map. Be sure to set at least one polygon to a boundary (red). See help for additional information and tutorials."
    );
  } else if (inputs.features.findIndex((poly) => poly.properties.identity == 1) == -1) {
    errors.push(
      "SIFT requires at least one project boundary to run. Use the draw tool to define your project boundaries, or drop a KMZ into the map. Be sure to set at least one polygon to a boundary (red). See help for additional information and tutorials."
    );
  }
  // SIZE LIMIT
  let areaLimit = inputs.currentPlan == 3 ? 2000 : 100;
  if (inputs.totalArea > areaLimit) {
    if (inputs.currentPlan == 3) {
      errors.push(
        "Projects in SIFT are limited by boundary area. Your plan allows boundaries up to 2000 hectares(~5000 acres, 30000亩). This is enough area for a 1000MWac single-axis project with common wattage modules and typical DC:AC ratios."
      );
    } else {
      errors.push(
        "Projects in SIFT are limited by boundary area. This tier allows boundaries up to 100 hectares(~250 acres, 1500亩). This is enough area for a 50MWac single-axis project with common wattage moldules and typical DC:AC ratios."
      );
    }
    // errors.push("The total boundary area for this project exceeds your limit. Total boundary area is displayed at the bottom left of the map. Your boundary limit can be found and upgraded in the Account tab. PRO accounts can operate on boundaries up to 20 sq km (approximately 2500 acres).")
  }

  // COORD SYSTEM VALIDATION
  if (inputs.features.length > 0 && inputs.coord_system_bbox) {
    let coordSystemCheck = checkCoordSystemBounds(inputs.features, inputs.coord_system_bbox);
    if (coordSystemCheck === false) {
      errors.push(
        `Your project boundary exists outside of the selected layout coordinate system and would adversely affect your layout results. Please select a more specific layout coordinate system, or select "WGS84" from the Layout Coordinate System dropdown in the Layout menu.`
      );
    }
  }

  // MODULE  VALIDATION
  if (inputs.module.rating <= 0 || isNaN(inputs.module.rating)) {
    errors.push("Invalid module rating. Select or import a module in the Module Tab.");
  }
  inputs.module.rating = parseFloat(inputs.module.rating);
  inputs.module.mlm_D2MuTau = parseFloat(inputs.module.mlm_D2MuTau);
  inputs.module.mlm_E_g = parseFloat(inputs.module.mlm_E_g);
  inputs.module.mlm_I_mp_ref = parseFloat(inputs.module.mlm_I_mp_ref);
  inputs.module.mlm_I_sc_ref = parseFloat(inputs.module.mlm_I_sc_ref);
  inputs.module.mlm_Length = parseFloat(inputs.module.mlm_Length);
  inputs.module.mlm_N_diodes = parseFloat(inputs.module.mlm_N_diodes);
  inputs.module.mlm_N_parallel = parseFloat(inputs.module.mlm_N_parallel);
  inputs.module.mlm_N_series = parseFloat(inputs.module.mlm_N_series);
  inputs.module.mlm_R_s = parseFloat(inputs.module.mlm_R_s);
  inputs.module.mlm_R_sh0 = parseFloat(inputs.module.mlm_R_sh0);
  inputs.module.mlm_R_shexp = parseFloat(inputs.module.mlm_R_shexp);
  inputs.module.mlm_R_shref = parseFloat(inputs.module.mlm_R_shref);
  inputs.module.mlm_S_ref = parseFloat(inputs.module.mlm_S_ref);
  inputs.module.mlm_T_c_fa_alpha = parseFloat(inputs.module.mlm_T_c_fa_alpha);
  inputs.module.mlm_T_ref = parseFloat(inputs.module.mlm_T_ref);
  inputs.module.mlm_V_mp_ref = parseFloat(inputs.module.mlm_V_mp_ref);
  inputs.module.mlm_V_oc_ref = parseFloat(inputs.module.mlm_V_oc_ref);
  inputs.module.mlm_Width = parseFloat(inputs.module.mlm_Width);
  inputs.module.mlm_alpha_isc = parseFloat(inputs.module.mlm_alpha_isc);
  inputs.module.mlm_beta_voc_spec = parseFloat(inputs.module.mlm_beta_voc_spec);
  inputs.module.mlm_mu_n = parseFloat(inputs.module.mlm_mu_n);
  inputs.module.mlm_n_0 = parseFloat(inputs.module.mlm_n_0);

  let combine_iam = [];
  inputs.module.module_iam_ang.forEach((ang, index) => {
    inputs.module.module_iam_ang[index] = parseFloat(ang);
  });
  inputs.module.module_iam_eff.forEach((eff, index) => {
    inputs.module.module_iam_eff[index] = parseFloat(eff);

    combine_iam.push([inputs.module.module_iam_ang[index], inputs.module.module_iam_eff[index]]);
  });
  // now that we have the IAM arrays fixed as floats, lets combine, sort, and split again
  combine_iam.sort(function (a, b) {
    if (a[0] == b[0]) {
      return a[1] - b[1];
    }
    return a[0] - b[0];
  });
  let stringArray = combine_iam.map(JSON.stringify);
  let uniqueStringArray = new Set(stringArray);
  inputs.module.module_iam_ang = [];
  inputs.module.module_iam_eff = [];
  Array.from(uniqueStringArray, JSON.parse).forEach((row, index) => {
    inputs.module.module_iam_ang.push(row[0]);
    inputs.module.module_iam_eff.push(row[1]);
  });

  // field for bifacial patch
  inputs.module.module_bifaciality = parseFloat(inputs.module.module_bifaciality);
  inputs.module.module_area = parseFloat(inputs.module.module_area);

  // INVERTER VALIDATION
  if (inputs.inverter.inverterRating <= 0 || isNaN(inputs.inverter.inverterRating)) {
    // Swal.fire({ title:"Error", text:"Not a valid Inverter Rating", type:"warning", confirmButtonColor:"#002bcb" });
    // return false;
    errors.push("Invalid Inverter rating. Select or import an inverter in the Inverter Tab.");
  }
  if (inputs.inverter.maxEff <= 0 || inputs.inverter.maxEff > 100 || isNaN(inputs.inverter.maxEff)) {
    errors.push("Invalid Inverter Max Efficiency. Must be >0 and <=100.");
  }

  let combine_temp_derate = [];
  inputs.inverter.derate_maxoutput.forEach((output, index) => {
    inputs.inverter.derate_maxoutput[index] = parseFloat(output);
  });
  inputs.inverter.derate_temps.forEach((temp, index) => {
    inputs.inverter.derate_temps[index] = parseFloat(temp);

    combine_temp_derate.push([inputs.inverter.derate_maxoutput[index], inputs.inverter.derate_temps[index]]);
  });

  let stringTemps = combine_temp_derate.map(JSON.stringify);
  let uniqueStringTempArray = new Set(stringTemps);
  inputs.inverter.derate_maxoutput = [];
  inputs.inverter.derate_temps = [];
  Array.from(uniqueStringTempArray, JSON.parse).forEach((row, index) => {
    inputs.inverter.derate_maxoutput.push(row[0]);
    inputs.inverter.derate_temps.push(row[1]);
  });

  // field for bifacial patch
  inputs.module.module_bifaciality = parseFloat(inputs.module.module_bifaciality);
  inputs.module.module_area = parseFloat(inputs.module.module_area);

  inputs.inverter.inv_pd_eff_pout.forEach((pout, index) => {
    inputs.inverter.inv_pd_eff_pout[index] = parseFloat(pout);
  });
  inputs.inverter.inv_pd_efficiency.forEach((eff, index) => {
    inputs.inverter.inv_pd_efficiency[index] = parseFloat(eff);
  });

  inputs.inverter.inv_pd_vdcmax = parseFloat(inputs.inverter.inv_pd_vdcmax);
  inputs.inverter.inv_pd_pacokw = parseFloat(inputs.inverter.inv_pd_pacokw);
  inputs.inverter.inv_pd_pnt = parseFloat(inputs.inverter.inv_pd_pnt);
  inputs.inverter.inverterRating = parseFloat(inputs.inverter.inverterRating);
  inputs.inverter.maxEff = parseFloat(inputs.inverter.maxEff);
  inputs.inverter.mppt_hi_inverter = parseFloat(inputs.inverter.mppt_hi_inverter);
  inputs.inverter.mppt_low_inverter = parseFloat(inputs.inverter.mppt_low_inverter);
  inputs.inverter.pnom = parseFloat(inputs.inverter.pnom);
  // inputs.inverter.inv_pd_pdco = parseFloat(inputs.inverter.inv_pd_pdco);

  // RACKING VALIDATION
  //
  inputs.racking.racks.forEach((rack, index) => {
    if (isNaN(parseFloat(rack.xdim)) || isNaN(parseFloat(rack.ydim)) || isNaN(parseFloat(rack.module))) {
      inputs.racking.racks[index].active = 0;
      inputs.racking.racks[index].xdim = 0;
      inputs.racking.racks[index].ydim = 0;
      inputs.racking.racks[index].module = 0;
    } else {
      inputs.racking.racks[index].xdim = parseFloat(rack.xdim);
      inputs.racking.racks[index].ydim = parseFloat(rack.ydim);
      inputs.racking.racks[index].module = parseFloat(rack.module);
    }
  });

  // check for at least 1 active rack
  if (inputs.racking.racks.findIndex((r) => r.active == true) == -1) {
    errors.push("There are no active Racking dimesions. Navigate to the Racking Tab and mark at least one Type (A,B,or C) as Active.");
  }
  if (inputs.racking.type == 0) {
    if (inputs.racking.type.tilts == false) {
      errors.push("Invalid Tilt Inputs in Racking tab");
    }
  }

  inputs.racking.type = parseInt(inputs.racking.type);
  inputs.racking.gap = parseFloat(inputs.racking.gap);
  inputs.racking.tilt_min = parseFloat(inputs.racking.tilt_min);
  inputs.racking.tilt_max = parseFloat(inputs.racking.tilt_max);
  inputs.racking.backtrack = parseInt(inputs.racking.backtrack);
  inputs.racking.track_angle = parseFloat(inputs.racking.track_angle);
  inputs.racking.shade_mode = parseInt(inputs.racking.shade_mode);
  inputs.racking.string_steps = parseFloat(inputs.racking.string_steps);
  inputs.racking.cell_trav = parseFloat(inputs.racking.cell_trav);
  // 2 new fields for Bifacial patch
  inputs.racking.module_clearance_height = parseFloat(inputs.racking.module_clearance_height);
  inputs.racking.module_transmission_perc = parseFloat(inputs.racking.module_transmission_perc);
  inputs.racking.module_structure_shade_perc = parseFloat(inputs.racking.module_structure_shade_perc);

  // TOPO INPUTS
  inputs.topo.grade_limit = parseInt(inputs.topo.grade_limit);

  if (inputs.topo.topo_bbox && inputs.topo.do_topo) {
		let fixed_bbox = [
			inputs.topo.topo_bbox[0]-0.0009,
			inputs.topo.topo_bbox[1]-0.0009,
			inputs.topo.topo_bbox[2]+0.0009,
			inputs.topo.topo_bbox[3]+0.0009,
		]
		let topo_bounding_box = turf.bboxPolygon(fixed_bbox);
    let feature_bounding_box = turf.bboxPolygon(getBounds(inputs.features));
    if (!turf.booleanContains(topo_bounding_box, feature_bounding_box)) {
      errors.push("There are boundaries located outside of the Topography area. Please delete and re-import Topography");
    }
  }

  if (inputs.racking.shade_mode == 1 && inputs.racking.backtrack == 0) {
    if (inputs.racking.string_steps <= 0) {
      errors.push("String Steps in Racking tab must be greater than 0.");
    }
    if (inputs.racking.cell_trav <= 0) {
      errors.push("Cell Transverse in Racking tab must be greater than 0.");
    }
  }

  let config_check = [
    {
      key: "gcr_range",
      value: [parseFloat(inputs.config.gcr_range[0]), parseFloat(inputs.config.gcr_range[1])],
      isNaN: isNaN(parseFloat(inputs.config.gcr_range[0])) || isNaN(parseFloat(inputs.config.gcr_range[1])),
    },
    {
      key: "pitch_range",
      value: [parseFloat(inputs.config.pitch_range[0]), parseFloat(inputs.config.pitch_range[1])],
      isNaN: isNaN(parseFloat(inputs.config.pitch_range[0])) || isNaN(parseFloat(inputs.config.pitch_range[1])),
    },
    { key: "gcr_step", value: parseFloat(inputs.config.gcr_step), isNaN: isNaN(parseFloat(inputs.config.gcr_step)) },
    { key: "spi_step", value: parseFloat(inputs.config.spi_step), isNaN: isNaN(parseFloat(inputs.config.spi_step)) },
    { key: "pitch_step", value: parseFloat(inputs.config.pitch_step), isNaN: isNaN(parseFloat(inputs.config.pitch_step)) },
    { key: "gcr_count", value: parseInt(inputs.config.gcr_count), isNaN: isNaN(parseInt(inputs.config.gcr_count)) },
    { key: "spi_count", value: parseInt(inputs.config.spi_count), isNaN: isNaN(parseInt(inputs.config.spi_count)) },
    { key: "selected_gcr_pitch", value: parseInt(inputs.config.selected_gcr_pitch), isNaN: isNaN(parseInt(inputs.config.selected_gcr_pitch)) },
    { key: "selected_slider_fixed", value: parseInt(inputs.config.selected_slider_fixed), isNaN: isNaN(parseInt(inputs.config.selected_slider_fixed)) },
    { key: "layout_fill", value: parseInt(inputs.config.layout_fill), isNaN: isNaN(parseInt(inputs.config.layout_fill)) },

    { key: "do_inverter_qty_lock", value: parseInt(inputs.config.do_inverter_qty_lock), isNaN: isNaN(parseInt(inputs.config.do_inverter_qty_lock)) },
    { key: "inverter_qty", value: parseInt(inputs.config.inverter_qty), isNaN: isNaN(parseInt(inputs.config.inverter_qty)) },
    { key: "do_inv_spacing", value: parseInt(inputs.config.do_inv_spacing), isNaN: isNaN(parseInt(inputs.config.do_inv_spacing)) },
    { key: "inverter_per_cut", value: parseInt(inputs.config.inverter_per_cut), isNaN: isNaN(parseInt(inputs.config.inverter_per_cut)) },
    { key: "layout_margin", value: parseFloat(inputs.config.layout_margin), isNaN: isNaN(parseFloat(inputs.config.layout_margin)) },
    { key: "road_spacing_option", value: parseInt(inputs.config.road_spacing_option), isNaN: isNaN(parseInt(inputs.config.road_spacing_option)) },
    { key: "road_spacing", value: parseFloat(inputs.config.road_spacing), isNaN: isNaN(parseFloat(inputs.config.road_spacing)) },
    { key: "do_roads", value: parseInt(inputs.config.do_roads), isNaN: isNaN(parseInt(inputs.config.do_roads)) },
    { key: "do_rack_align", value: parseInt(inputs.config.do_rack_align), isNaN: isNaN(parseInt(inputs.config.do_rack_align)) },
    { key: "designer_margin", value: parseFloat(inputs.config.designer_margin), isNaN: isNaN(parseFloat(inputs.config.designer_margin)) },
    { key: "azimuth", value: parseFloat(inputs.config.azimuth), isNaN: isNaN(parseFloat(inputs.config.azimuth)) },
    { key: "worker_count", value: parseInt(inputs.config.worker_count), isNaN: isNaN(parseInt(inputs.config.worker_count)) },
    { key: "worker_limit", value: parseInt(inputs.config.worker_limit), isNaN: isNaN(parseInt(inputs.config.worker_limit)) },

    // ...isNan(...config.customSchedule),
  ];
  config_check.forEach((input) => {
    if (input.isNaN == true) {
      // inputs.config[input.key] = 0
      inputs.config[input.key] = tabDefaults.config.data[input.key];
      warnings.push(`Config Input (${input.key}) found blank, setting to default:${tabDefaults.config.data[input.key]}`);
    } else {
      inputs.config[input.key] = input.value;
    }
  });

  // CONFIG VALIDATION
  // 1- check to make sure worker_count < worker_limit
  // 2- check to make sure worker_count == gcr/pitch_count * spi_couunt
  // 3- maybe check inverter inputs based on total area used or plan id
  if (inputs.config.worker_count > inputs.config.worker_limit) {
    errors.push(
      "The number of workers required for this SIFT run are over your limit. Adjust the range or Increment fields in the /config tab. The number of workers and your limit are shown in the /config tab."
    );
  }
  if (inputs.config.selected_gcr_pitch == 0) {
    if (isNaN(inputs.config.gcr_range[0]) || inputs.config.gcr_range[0] == null || inputs.config.gcr_range[0] == "" || inputs.config.gcr_range[0] < 0.2) {
      errors.push("The GCR Min is invalid. Adjust value in the /config tab");
    }
    if (isNaN(inputs.config.gcr_range[1]) || inputs.config.gcr_range[1] == null || inputs.config.gcr_range[1] == "" || inputs.config.gcr_range[1] > 1.0) {
      errors.push("The GCR Max is invalid. Adjust value in the /config tab");
    }
    if (isNaN(inputs.config.gcr_step) || inputs.config.gcr_step == null) {
      errors.push("The GCR Increment is invalid. Adjust value in the /config tab");
    }
  } else {
    if (isNaN(inputs.config.pitch_range[0]) || inputs.config.pitch_range[0] == null || inputs.config.pitch_range[0] == "" || inputs.config.pitch_range[0] < inputs.config.pitch_min_max[0]) {
      errors.push("The Pitch Min is invalid. Adjust value in the /config tab");
    }
    if (isNaN(inputs.config.pitch_range[1]) || inputs.config.pitch_range[1] == null || inputs.config.pitch_range[1] == "" || inputs.config.pitch_range[1] > inputs.config.pitch_min_max[1]) {
      errors.push("The Pitch Max is invalid. Adjust value in the /config tab");
    }
    if (isNaN(inputs.config.pitch_step) || inputs.config.pitch_step == null) {
      errors.push("The Piitch Increment is invalid. Adjust value in the /config tab");
    }
  }

  // WEATHER VALIDATION
  if (inputs.performance.weather == undefined || inputs.performance.weather == 0) {
    // THIS WILL BE AN AUTO_GEN WEATHER RUN
    // errors.push("Input Validation Failed. No weather data loaded to the project. Navigate to the Weather tab and import weather information for this location.")
  }

  if (inputs.performance.weather != undefined || inputs.weather.search_lat != 0) {
    let _boundaries = inputs.features.filter((poly) => poly.properties.identity == 1);
    let weather_center = [inputs.weather.search_lat, inputs.weather.search_lng];
    if (!checkSiteLocation(_boundaries, weather_center)) {
      errors.push("Latitude/Longitude inputs are +/- 5 degrees from the site location. Recenter site location in weather tab.");
    }
  }

  // PERFORMANCE VALIDATION
  // force convert all performance variables
  inputs.performance.grid_power_limit = parseFloat(inputs.performance.grid_power_limit);
  inputs.performance.modules_per_string = parseFloat(inputs.performance.modules_per_string);
  inputs.performance.albedo = parseFloat(inputs.performance.albedo);
  inputs.performance.dc_degradation = parseFloat(inputs.performance.dc_degradation);
  inputs.performance.irrad_mode = parseFloat(inputs.performance.irrad_mode);
  inputs.performance.mismatch_loss = parseFloat(inputs.performance.mismatch_loss);
  inputs.performance.sky_model = parseFloat(inputs.performance.sky_model);
  inputs.performance.dc_loss_stc = parseFloat(inputs.performance.dc_loss_stc);
  inputs.performance.quality_loss = parseFloat(inputs.performance.quality_loss);
  inputs.performance.lid_loss = parseFloat(inputs.performance.lid_loss);
  inputs.performance.ac_losses = parseFloat(inputs.performance.ac_losses);
  inputs.performance.thermal_uc = parseFloat(inputs.performance.thermal_uc);
  inputs.performance.thermal_uv = parseFloat(inputs.performance.thermal_uv);
  inputs.performance.soiling = parseFloat(inputs.performance.soiling);

  inputs.performance.soiling_monthly.forEach((soil, index) => {
    if (inputs.performance.doMonthlySoiling == 1) {
      inputs.performance.soiling_monthly[index] = parseFloat(soil);
    } else {
      inputs.performance.soiling_monthly[index] = inputs.performance.soiling;
    }
    soil = parseFloat(soil);
  });
  inputs.performance.albedo_monthly.forEach((albedo, index) => {
    if (inputs.performance.doMonthlySoiling == 1) {
      inputs.performance.albedo_monthly[index] = parseFloat(albedo);
    } else {
      inputs.performance.albedo_monthly[index] = inputs.performance.albedo;
    }
    albedo = parseFloat(albedo);
  });

  inputs.performance.g_input = parseFloat(inputs.performance.g_input);

  if (inputs.performance.modules_per_string == undefined || inputs.performance.modules_per_string <= 0) {
    errors.push("Invalid Modules per String. Review the Modules Per String input in the Performance Tab.");
  }

  // FINANCE VALIDATION
  if (inputs.config.do_finance) {
    // console.log(inputs.finance)
    // _.isFinite()
    let finance_check = [
      { key: "analysis_period", value: parseInt(inputs.finance.analysis_period), isNaN: isNaN(parseInt(inputs.finance.analysis_period)) },
      { key: "discount_rate", value: parseFloat(inputs.finance.discount_rate), isNaN: isNaN(parseFloat(inputs.finance.discount_rate)) },

      // BELOW HAVE BEEN REPLACED IN THE NEW FINANCE INSTALL COST INPUTS
      // { key: "module_cost", value: parseFloat(inputs.finance.module_cost), isNaN: isNaN(parseFloat(inputs.finance.module_cost)) },
      // { key: "other_dc_cost", value: parseFloat(inputs.finance.other_dc_cost), isNaN: isNaN(parseFloat(inputs.finance.other_dc_cost)) },
      // { key: "footprint_install_cost", value: parseFloat(inputs.finance.footprint_install_cost), isNaN: isNaN(parseFloat(inputs.finance.footprint_install_cost)) },
      // { key: "ac_cost", value: parseFloat(inputs.finance.ac_cost), isNaN: isNaN(parseFloat(inputs.finance.ac_cost)) },
      // { key: "fixed_cost", value: parseFloat(inputs.finance.fixed_cost), isNaN: isNaN(parseFloat(inputs.finance.fixed_cost)) },

      { key: "dc_op_cost", value: parseFloat(inputs.finance.dc_op_cost), isNaN: isNaN(parseFloat(inputs.finance.dc_op_cost)) },
      { key: "ac_op_cost", value: parseFloat(inputs.finance.ac_op_cost), isNaN: isNaN(parseFloat(inputs.finance.ac_op_cost)) },
      { key: "footprint_op_cost", value: parseFloat(inputs.finance.footprint_op_cost), isNaN: isNaN(parseFloat(inputs.finance.footprint_op_cost)) },
      { key: "fixed_op_cost", value: parseFloat(inputs.finance.fixed_op_cost), isNaN: isNaN(parseFloat(inputs.finance.fixed_op_cost)) },
      { key: "escalation", value: parseFloat(inputs.finance.escalation), isNaN: isNaN(parseFloat(inputs.finance.escalation)) },
      // ...isNan(...finance.customSchedule),
      { key: "ri_power", value: parseFloat(inputs.finance.ri_power), isNaN: isNaN(parseFloat(inputs.finance.ri_power)) },
      { key: "ri_escalation", value: parseFloat(inputs.finance.ri_escalation), isNaN: isNaN(parseFloat(inputs.finance.ri_escalation)) },
      { key: "itc_percent", value: parseFloat(inputs.finance.itc_percent), isNaN: isNaN(parseFloat(inputs.finance.itc_percent)) },
      { key: "debt_percent", value: parseFloat(inputs.finance.debt_percent), isNaN: isNaN(parseFloat(inputs.finance.debt_percent)) },
      { key: "debt_dscr", value: parseFloat(inputs.finance.debt_dscr), isNaN: isNaN(parseFloat(inputs.finance.debt_dscr)) },
      { key: "debt_interest", value: parseFloat(inputs.finance.debt_interest), isNaN: isNaN(parseFloat(inputs.finance.debt_interest)) },
      { key: "debt_tenor", value: parseFloat(inputs.finance.debt_tenor), isNaN: isNaN(parseFloat(inputs.finance.debt_tenor)) },
      { key: "dep_5yrSL", value: parseFloat(inputs.finance.dep_5yrSL), isNaN: isNaN(parseFloat(inputs.finance.dep_5yrSL)) },
      { key: "dep_15yrSL", value: parseFloat(inputs.finance.dep_15yrSL), isNaN: isNaN(parseFloat(inputs.finance.dep_15yrSL)) },
      { key: "dep_20yrSL", value: parseFloat(inputs.finance.dep_20yrSL), isNaN: isNaN(parseFloat(inputs.finance.dep_20yrSL)) },
      { key: "dep_30yrSL", value: parseFloat(inputs.finance.dep_30yrSL), isNaN: isNaN(parseFloat(inputs.finance.dep_30yrSL)) },
      { key: "dep_35yrSL", value: parseFloat(inputs.finance.dep_35yrSL), isNaN: isNaN(parseFloat(inputs.finance.dep_35yrSL)) },
      { key: "dep_39yrSL", value: parseFloat(inputs.finance.dep_39yrSL), isNaN: isNaN(parseFloat(inputs.finance.dep_39yrSL)) },
      { key: "dep_5yrMACRS", value: parseFloat(inputs.finance.dep_5yrMACRS), isNaN: isNaN(parseFloat(inputs.finance.dep_5yrMACRS)) },
      { key: "dep_15yrMACRS", value: parseFloat(inputs.finance.dep_15yrMACRS), isNaN: isNaN(parseFloat(inputs.finance.dep_15yrMACRS)) },
      { key: "state_taxes", value: parseFloat(inputs.finance.state_taxes), isNaN: isNaN(parseFloat(inputs.finance.state_taxes)) },
      { key: "federal_taxes", value: parseFloat(inputs.finance.federal_taxes), isNaN: isNaN(parseFloat(inputs.finance.federal_taxes)) },

      { key: "do_include_itc", value: parseFloat(inputs.finance.do_include_itc), isNaN: isNaN(parseFloat(inputs.finance.do_include_itc)) },
      { key: "do_ri_customSchedule", value: parseFloat(inputs.finance.do_ri_customSchedule), isNaN: isNaN(parseFloat(inputs.finance.do_ri_customSchedule)) },
    ];
    // toggle_finance_type: inputs.data.toggle_finance_type ? inputs.data.toggle_finance_type : tabDefaults.finance.data.toggle_finance_type,
    if (inputs.finance.toggle_finance_type == 0) {
      // summarized data
      finance_check.push({ key: "summarized_dc", value: parseFloat(inputs.finance.summarized_dc), isNaN: isNaN(parseFloat(inputs.finance.summarized_dc)) });
      finance_check.push({ key: "summarized_ac", value: parseFloat(inputs.finance.summarized_ac), isNaN: isNaN(parseFloat(inputs.finance.summarized_ac)) });
      // finance_check.push({ key: "summarized_dc_wiring_wp", value: parseFloat(inputs.finance.summarized_dc_wiring_wp.total), isNaN: isNaN(parseFloat(inputs.finance.summarized_dc_wiring_wp.total)) })
      // finance_check.push({ key: "summarized_dc_wiring_gcr", value: parseFloat(inputs.finance.summarized_dc_wiring_gcr.total), isNaN: isNaN(parseFloat(inputs.finance.summarized_dc_wiring_gcr.total)) })
      // finance_check.push({ key: "foot_print", value: parseFloat(inputs.finance.foot_print), isNaN: isNaN(parseFloat(inputs.finance.foot_print)) })
    } else {
      // D/C Units
      finance_check.push({
        key: "module_dc_cost",
        value: { type: inputs.finance.module_dc_cost.type, value: parseFloat(inputs.finance.module_dc_cost.value) },
        isNaN: isNaN(parseFloat(inputs.finance.module_dc_cost.value)),
      });
      finance_check.push({
        key: "rack_a_finance",
        value: { type: inputs.finance.rack_a_finance.type, value: parseFloat(inputs.finance.rack_a_finance.value) },
        isNaN: isNaN(parseFloat(inputs.finance.rack_a_finance.value)),
      });
      finance_check.push({
        key: "rack_b_finance",
        value: { type: inputs.finance.rack_b_finance.type, value: parseFloat(inputs.finance.rack_b_finance.value) },
        isNaN: isNaN(parseFloat(inputs.finance.rack_b_finance.value)),
      });
      finance_check.push({
        key: "rack_c_finance",
        value: { type: inputs.finance.rack_c_finance.type, value: parseFloat(inputs.finance.rack_c_finance.value) },
        isNaN: isNaN(parseFloat(inputs.finance.rack_c_finance.value)),
      });
      finance_check.push({
        key: "bos_other",
        value: { type: inputs.finance.bos_other.type, value: parseFloat(inputs.finance.bos_other.value) },
        isNaN: isNaN(parseFloat(inputs.finance.bos_other.value)),
      });

      // fixed units
      finance_check.push({
        key: "interconnection",
        value: { type: inputs.finance.interconnection.type, value: parseFloat(inputs.finance.interconnection.value) },
        isNaN: isNaN(parseFloat(inputs.finance.interconnection.value)),
      });
      finance_check.push({
        key: "permits_fees",
        value: { type: inputs.finance.permits_fees.type, value: parseFloat(inputs.finance.permits_fees.value) },
        isNaN: isNaN(parseFloat(inputs.finance.permits_fees.value)),
      });
      finance_check.push({
        key: "engineering",
        value: { type: inputs.finance.engineering.type, value: parseFloat(inputs.finance.engineering.value) },
        isNaN: isNaN(parseFloat(inputs.finance.engineering.value)),
      });
      finance_check.push({ key: "margin", value: { type: inputs.finance.margin.type, value: parseFloat(inputs.finance.margin.value) }, isNaN: isNaN(parseFloat(inputs.finance.margin.value)) });
      finance_check.push({
        key: "other_fixed",
        value: { type: inputs.finance.other_fixed.type, value: parseFloat(inputs.finance.other_fixed.value) },
        isNaN: isNaN(parseFloat(inputs.finance.other_fixed.value)),
      });

      // A/C Units
      finance_check.push({ key: "inverter", value: { type: inputs.finance.inverter.type, value: parseFloat(inputs.finance.inverter.value) }, isNaN: isNaN(parseFloat(inputs.finance.inverter.value)) });
      finance_check.push({ key: "ac_aux", value: { type: inputs.finance.ac_aux.type, value: parseFloat(inputs.finance.ac_aux.value) }, isNaN: isNaN(parseFloat(inputs.finance.ac_aux.value)) });
      finance_check.push({ key: "mv_wire", value: { type: inputs.finance.mv_wire.type, value: parseFloat(inputs.finance.mv_wire.value) }, isNaN: isNaN(parseFloat(inputs.finance.mv_wire.value)) });
      finance_check.push({ key: "other_ac", value: { type: inputs.finance.other_ac.type, value: parseFloat(inputs.finance.other_ac.value) }, isNaN: isNaN(parseFloat(inputs.finance.other_ac.value)) });

      // Misc Units
      finance_check.push({
        key: "rack_footprint",
        value: { type: inputs.finance.rack_footprint.type, value: parseFloat(inputs.finance.rack_footprint.value) },
        isNaN: isNaN(parseFloat(inputs.finance.rack_footprint.value)),
      });
      finance_check.push({
        key: "boundary_area",
        value: { type: inputs.finance.boundary_area.type, value: parseFloat(inputs.finance.boundary_area.value) },
        isNaN: isNaN(parseFloat(inputs.finance.boundary_area.value)),
      });
      finance_check.push({
        key: "contingency",
        value: { type: inputs.finance.contingency.type, value: parseFloat(inputs.finance.contingency.value) },
        isNaN: isNaN(parseFloat(inputs.finance.contingency.value)),
      });

      // spacing adder
      let spacing_gcr = [];
      _.forEach(inputs.finance.spacing_gcr, (gcr) => {
        let _gcr = isNaN(parseFloat(gcr)) == true ? 0.0 : parseFloat(gcr);
        spacing_gcr.push(_gcr);
      });
      let spacing_per_wp = [];
      _.forEach(inputs.finance.spacing_per_wp, (wp) => {
        let _wp = isNaN(parseFloat(wp)) == true ? 0.0 : parseFloat(wp);
        spacing_per_wp.push(_wp);
      });
      inputs.finance.spacing_gcr = spacing_gcr;
      inputs.finance.spacing_per_wp = spacing_per_wp;
    }
    // finance_check.push({ key: "spacing_gcr", value: parseFloat(inputs.finance.spacing_gcr.value), isNaN: isNaN(parseFloat(inputs.finance.spacing_gcr.value)) })
    // finance_check.push({ key: "spacing_per_wp", value: parseFloat(inputs.finance.spacing_per_wp.value), isNaN: isNaN(parseFloat(inputs.finance.spacing_per_wp.value)) })

    finance_check.forEach((input) => {
      if (input.isNaN == true) {
        // inputs.finance[input.key] = tabDefaults.finance.data[input.key];
        // warnings.push(`Finance Input (${input.key}) found blank, setting to default:${tabDefaults.finance.data[input.key]}`);
        inputs.finance[input.key] = 0;
        warnings.push(`Finance Input (${input.key}) found blank, setting to 0`);
      } else {
        inputs.finance[input.key] = input.value;
      }
    });

    if (inputs.finance.analysis_period <= 0 || inputs.finance.analysis_period > 50) {
      errors.push("Invalid Analysis Period. In the Finance Tab, Analysis Period must be between 1 and 50 years. You may need to toggle off Custom Schedule to change the Analysis Period field.");
    }
    if (inputs.finance.metric == 1 && inputs.finance.debt_structure > 0 && inputs.finance.analysis_period < inputs.finance.debt_tenor) {
      errors.push("Invalid Debt Tenor. In the Finance Tab, Debt Tenor must be less than or equal to Analysis Period.");
    }
  }

  // racking dimensions warnings checks ( only if performance is enabled )
  let dims_too_large = false;
  let dims_too_small = false;
  // if (inputs.complexity.performance) {
  // 	let mod_area = inputs.module.module_area;
  // 	inputs.racking.racks.forEach((rack, index) => {
  // 		// For types [a,b,c] If (aRacking X * aRacking Y) > (Module Area * aModule Ct) * 1.5
  // 		if ((rack.xdim * rack.ydim) > (mod_area * rack.module) * 1.5)
  // 		dims_too_large = true
  // 		// For types [a,b,c] If (aRacking X * aRacking Y) *.75 < (Module Area * aModule Ct)
  // 		if ((rack.xdim * rack.ydim) * 0.75 > (mod_area * rack.module))
  // 		dims_too_small = true
  // 	});
  // }

  if (dims_too_large)
    warnings.push("Racking dimensions are too large for the module counts. Your Racking area is >150% the required module area. Check the XY dimensions and Modules Counts in your Racking tab.");
  if (dims_too_small)
    warnings.push("Racking dimensions are too small for the module counts. Your Racking table area is <75% the required module area. Check the XY dimensions and Modules Counts in your Racking tab.");

  let valid = true;
  if (errors.length > 0) {
    valid = false;
  }
  // console.log("Invalidate Input End", inputs);

  return { valid: valid, validatedInputs: inputs, errors: errors, warnings: warnings };
}
