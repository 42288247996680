import React, { useEffect } from 'react';

// componenets
import Navigation from './_components/navigation';
import Hero from './_components/hero';
import SIFTIntro from './_components/SIFTIntro';
import ProductsAndServices from './_components/productsAndServices';
import Sift from './_components/sift';
import DevelopmentServices from './_components/developmentServices';
import Api from './_components/api';
import Team from './_components/team';
import Contact from './_components/contact';
import Footer from './_components/footer';

import { useSelector, useDispatch } from 'react-redux';
import { authActions, siftActions, accountActions, isAuthenticated } from '../../components/Redux';

import './home.css';

export const Home = (props) => {
  const isAuthed = useSelector((state) => isAuthenticated(state));
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname === '/news') {
      dispatch(siftActions.toggleHelpToolbarVisibility('news_visible', true));
    }
  }, []);

  function handleToggleFunnel(e, val = true) {
    dispatch(accountActions.toggleFunnelVis(val));
  }
  function handleToggleLogin() {
    dispatch(authActions.toggleLoginVis(true, 'login'));
  }
  function handleLogout() {
    dispatch(authActions.logout());
  }

  return (
    <div className="home">
      <Navigation onShowPricing={handleToggleFunnel} onLoginShow={handleToggleLogin} onLogout={handleLogout} isAuthenticated={isAuthed} />
      <Hero onShowPricing={handleToggleFunnel} />
      <SIFTIntro onShowPricing={handleToggleFunnel} />
      {/* <ProductsAndServices /> */}
      <Sift onShowPricing={handleToggleFunnel} />
      {/* <DevelopmentServices /> */}
      {/* <Api /> */}
      <Team />
      <Contact />
      <Footer onShowPricing={handleToggleFunnel} />
    </div>
  );
};
