import React, { useState, useEffect } from "react";

//styles and assets
import { FinanceTabLayout } from "./styles";
import { Input, Tooltip, Radio, Empty } from "antd";

//components
import { FinanceInputs_IRR, FinanceInstallCosts } from "./";
import { TitleDivider } from "../../TitleDivider";
//redux
import { useSelector, useDispatch } from "react-redux";
import { siftActions, isNumber } from "../../Redux";
import { FinanceOperatingCosts } from "../..";

const FinanceInputs = () => {
  const dispatch = useDispatch();

  // Store values
  const pro_user = useSelector((state) => state.account.current_plan == 3);
  const account_loading = useSelector((state) => state.account.account_loading);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const doFinance = useSelector((state) => state.sift.ioManager.inputs.config.data.do_finance == 1);
  const doCustomSchedule = useSelector((state) => state.sift.ioManager.inputs.finance.data.doCustomSchedule);
  const metric = useSelector((state) => state.sift.ioManager.inputs.finance.data.metric);

  const finance = useSelector((state) => state.sift.ioManager.inputs.finance);
  //shared inputs between summarized and detailed
  const analysis_period = useSelector((state) => state.sift.ioManager.inputs.finance.data.analysis_period);
  const discount_rate = useSelector((state) => state.sift.ioManager.inputs.finance.data.discount_rate);

  // menu toggles
  const [expand, setExpand] = useState(false);

  // input values used for updating inputs
  const [analysisPeriod, setAnalysisPeriod] = useState(analysis_period);
  const [discountRate, setDiscountRate] = useState(discount_rate);

  const MetricOptions = [
    { label: "LCOE", value: 0 },
    { label: "IRR", value: 1, disabled: !pro_user },
  ];

  useEffect(() => {
    if (doFinance == false) {
      setExpand(false);
    }
  }, [doFinance]);

  useEffect(() => {
    setAnalysisPeriod(analysis_period);
    setDiscountRate(discount_rate);
  }, [finance]);

  const updateInput = (key, value, notAnObject = false) => {
    if (notAnObject) {
      let changed_value = parseFloat(value);
      if (!isNumber(changed_value)) return;
      dispatch(siftActions.updateInput(key, changed_value));
    } else {
      let changed_value = { value: parseFloat(value.value), type: parseInt(value.type), total: parseFloat(value.total) };

      if (!isNumber(changed_value.value)) return;
      dispatch(siftActions.updateInput(key, changed_value));
    }
  };

  return (
    <FinanceTabLayout>
      {doFinance ? (
        <>
          <section className="input-row-two-col">
            <label>Metric</label>
            <Tooltip placement="right" title={pro_user ? null : "IRR only available for PRO users"}>
              <Radio.Group
                style={{ marginLeft: "auto" }}
                name="metric"
                options={MetricOptions}
                onChange={(e) => {
                  updateInput(e.target.name, e.target.value, true);
                }}
                value={metric}
                disabled={account_loading || input_loading}
              />
            </Tooltip>
          </section>

          <Tooltip placement="right" title={doCustomSchedule ? "Please toggle off Custom Schedule to enable editing of the Analysis Period field" : ""} defaultVisible={doCustomSchedule}>
            <section className="input-row-two-col">
              <label>Analysis Period</label>
              <Input
                id="analysis_period"
                value={analysisPeriod}
                style={{ maxWidth: 135, marginLeft: "auto" }}
                size="small"
                type="number"
                min="0"
                autoComplete="off"
                disabled={account_loading || input_loading || doCustomSchedule}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>Years</span>}
                onChange={(e) => {
                  setAnalysisPeriod(e.target.value);
                  updateInput(e.target.id, e.target.value, true);
                }}
              />
            </section>
          </Tooltip>

          <section className="input-row-two-col">
            <label>Discount Rate (Nom)</label>
            <Tooltip placement="right" title={pro_user ? null : "IRR only available for PRO users"}>
              <Input
                id="discount_rate"
                value={discountRate}
                style={{ maxWidth: 135, marginLeft: "auto" }}
                size="small"
                type="number"
                min="0"
                autoComplete="off"
                disabled={account_loading || input_loading}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
                onChange={(e) => {
                  setDiscountRate(e.target.value);
                  updateInput(e.target.id, e.target.value, true);
                }}
              />
            </Tooltip>
          </section>

          <section className="input-row-two-col">
            <a
              className="custom-schedule-link"
              onClick={() => {
                setExpand(!expand);
              }}
            >
              {expand ? "Collapse All" : "Expand All"}
            </a>
          </section>

          <FinanceInstallCosts expand={expand} />
          <FinanceOperatingCosts expand={expand} />
          {metric == 1 && <FinanceInputs_IRR expand={expand} />}
        </>
      ) : (
        <Empty description="Finance inputs are not required to run SIFT. To include a financial calculation, toggle Enable Finance." />
      )}
    </FinanceTabLayout>
  );
};

export { FinanceInputs };
