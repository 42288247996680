import config from 'config';
import { authHeader } from '../_helpers';
import jwtDecode from 'jwt-decode';

export const userService = {
  login,
  logout,
	agreeToTerms,
  refreshToken,
  register,
  pwReset,
  getAll,
  getProfile,
  updateUserData,
  validateInputs,
  runCapacity,
  getResults,
  getRacksRoads,
  getProjects,
  getProjectById,
  saveProject,
  runWeather,
  getWeather,
  saveModule,
  saveInverter,
  saveRacking,
  savePerformance,
  saveFinance,
  saveWeather,

  resendVerifyEmail,
  addPayment,
  deletePayment,
  setDefaultPayment,
  updatePlanTo,

  getResultFiles,
  pollReport,
  getWeatherFile,
  getTopo,
  checkTopo,

  createDXF,
  pollDXF,

  importData,
  uploadDataToURL,
  downloadFromS3,
  downloadFromS3toPC,

  getById,
  update,
  delete: _delete,
  getTutorial,
  downloadInvoice,
};

async function login(email, password, forward) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${config.apiUrl}/api/auth/token/obtain/`, requestOptions)
    .then((response) => handleResponse(response, true))
    .then((returnedUser) => {
      // console.log('user', returnedUser)
      // login successful if there's a jwt access token in the response
      let remember = localStorage.getItem('remember');
      let user = {
        loggingIn: false,
        loggedIn: true,
        login_visible: false,
        forward: forward,
        access: {
          token: returnedUser.access,
          ...jwtDecode(returnedUser.access),
        },
        refresh: {
          token: returnedUser.refresh,
          ...jwtDecode(returnedUser.refresh),
        },
        remember: remember,
				refresh_visible: false,
				show_agreement_modal: !returnedUser.terrasmart_terms,
				terrasmart_terms: returnedUser.terrasmart_terms,
				show_terms: false,
				openedViaAgreegmentUpdate: false,				
      };
      // store user object for later
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('refresh', JSON.stringify(user.refresh.token));
      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  location.reload();
  return true;
}

function refreshToken(refresh) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ refresh }),
  };

  return fetch(`${config.apiUrl}/api/auth/token/refresh/`, requestOptions)
    .then(handleResponse)
    .then((access) => {
      // login successful if there's a jwt access token in the response

      if (access) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('user', JSON.stringify(user));
      }
      return access;
    });
}

function pwReset(email) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      'email': email,
    }),
  };

  return fetch(`${config.apiUrl}/api/password_reset/`, requestOptions).then(this.handleResponse);
}

function agreeToTerms() {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  };

  return fetch(`${config.apiUrl}/user/agree/`, requestOptions).then(this.handleResponse);
}

function handleRequest(request, inputs) {
  // check if access if expired
  // if so, refresh, then forward request
  // if not, just forward request
}

function getAll() {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/user/`, requestOptions).then(handleResponse);
}

function getProfile() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/usage/`, requestOptions).then(handleResponse);
}
function updateUserData(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(`${config.apiUrl}/user/`, requestOptions).then(handleResponse);
}

/*
    DASHBOARD FUNCTIONS

*/
function validateInputs(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ inputs }),
  };
  return fetch(`${config.apiUrl}/dash/validate/`, requestOptions)
    .then(handleResponse)
    .then((valid) => {
      return valid;
    });
}

function runCapacity(inputs, run_id) {
  // console.log(run_id)
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ inputs, run_id }),
  };
  // console.log(requestOptions);
  // console.log(`${config.apiUrl}/dash/`);
  return fetch(`${config.apiUrl}/dash/`, requestOptions)
    .then(handleResponse)
    .then((run_id) => {
      // console.log(run_id);
      // store run_id for pulling results
      localStorage.setItem('run_id', JSON.stringify(run_id));

      return run_id;
    });
}
function getProjects() {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  return fetch(`${config.apiUrl}/dash/project/`, requestOptions).then(handleResponse);
}
function getProjectById(id, collab_on) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  return fetch(`${config.apiUrl}/dash/project/?projectId=${id}&collab_on=${collab_on}`, requestOptions).then(handleResponse);
}

function getResults(id) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  // GET http://localhost:8000/dash/?runId=2a1a8e5a8f0f4228b5dc6ab1461dae18
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  return fetch(`${config.apiUrl}/dash/?runId=${id}`, requestOptions).then(handleResponse);
}
function getRacksRoads(id) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  // GET http://localhost:8000/dash/?rackId=2a1a8e5a8f0f4228b5dc6ab1461dae18
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  return fetch(`${config.apiUrl}/dash/?rackId=${id}`, requestOptions).then(handleResponse);
}
function downloadFromS3(url) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'attachment' },
  };
  return fetch(url, requestOptions).then(handleResponse);
}
function downloadFromS3toPC(url) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Disposition': 'attachment' },
  };
  return fetch(url, requestOptions);
}

function getResultFiles(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'attachment' },
  };
  return fetch(`${config.apiUrl}/dash/files/?perf_id=${id}`, requestOptions).then(handleResponse);
}
function pollReport(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  return fetch(`${config.apiUrl}/dash/swm/dxf/?run_id=${id}`, requestOptions).then(handleResponse);
}

function getWeatherFile(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'attachment' },
  };
  return fetch(`${config.apiUrl}/dash/swm/download/weather/?weatherId=${id}`, requestOptions).then(handleResponse);
}

function getTopo(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...inputs }),
  };
  return fetch(`${config.apiUrl}/dash/topo/`, requestOptions).then(handleResponse);
}
function checkTopo(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  return fetch(`${config.apiUrl}/dash/topo/?topo_id=${id}`, requestOptions).then(handleResponse);
}

function createDXF(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(inputs),
  };
  return fetch(`${config.apiUrl}/dash/swm/dxf`, requestOptions).then(handleResponse);
}
function pollDXF(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  return fetch(`${config.apiUrl}/dash/swm/dxf/?run_id=${id}`, requestOptions).then(handleResponse);
}

function saveProject(project) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(project),
  };
  return fetch(`${config.apiUrl}/dash/project/`, requestOptions).then(handleResponse);
}

function saveModule(mod) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(mod),
  };

  return fetch(`${config.apiUrl}/dash/modules/`, requestOptions).then(handleResponse);
}

function saveInverter(inv) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(inv),
  };

  return fetch(`${config.apiUrl}/dash/inverters/`, requestOptions).then(handleResponse);
}
function saveRacking(rack) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(rack),
  };

  return fetch(`${config.apiUrl}/dash/racking/`, requestOptions).then(handleResponse);
}

function savePerformance(perf) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(perf),
  };

  return fetch(`${config.apiUrl}/dash/performance/`, requestOptions).then(handleResponse);
}

function saveFinance(finance) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(finance),
  };

  return fetch(`${config.apiUrl}/dash/finance/`, requestOptions).then(handleResponse);
}

function saveWeather(weather) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(weather),
  };

  return fetch(`${config.apiUrl}/dash/weather/`, requestOptions).then(handleResponse);
}

function runWeather(inputs) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ inputs }),
  };
  // console.log(requestOptions);
  // console.log(`${config.apiUrl}/dash/`);
  return fetch(`${config.apiUrl}/dash/weatherRun/`, requestOptions)
    .then(handleResponse)
    .then((weather_id) => {
      // console.log(run_id);
      // store run_id for pulling results
      localStorage.setItem('weather_id', JSON.stringify(weather_id));

      return weather_id;
    });
}
function getWeather(id) {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  // GET http://localhost:8000/dash/?runId=2a1a8e5a8f0f4228b5dc6ab1461dae18
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/dash/weatherRun/?weatherId=${id}`, requestOptions).then(handleResponse);
}

function resendVerifyEmail() {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  return fetch(`${config.apiUrl}/user/emailverify/`, requestOptions).then(handleResponse);
}

function addPayment(token) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(token),
  };
  // console.log(token)
  return fetch(`${config.apiUrl}/billing/?token=${token.id}`, requestOptions).then(handleResponse);
}

function deletePayment(id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  return fetch(`${config.apiUrl}/billing/?delId=${id}`, requestOptions).then(handleResponse);
}

function setDefaultPayment(id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  return fetch(`${config.apiUrl}/billing/?defaultId=${id}`, requestOptions).then(handleResponse);
}

function updatePlanTo(sub) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  return fetch(`${config.apiUrl}/billing/plan/?planId=${sub}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(`${config.apiUrl}/rest-auth/registration/`, requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);
}

function importData(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}/dash/import/`, requestOptions).then(handleResponse);
}
function uploadDataToURL(url, data) {
  const requestOptions = {
    method: 'PUT',
    // headers: { 'Content-Type': 'application/json' },
    // headers: { 'Content-Type': type },
    // body: JSON.stringify(data)
    body: data,
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function getTutorial(inputs) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  return fetch(`${config.apiUrl}/dash/tutorial/`, requestOptions).then(handleResponse);
}

function downloadInvoice(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  return fetch(`${config.apiUrl}/billing/invoice/?invoiceId=${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response, login = false) {
  return response.text().then((text) => {
    let data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401 && !login) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      console.log(data);
      // const error = (data && data.message) || response.statusText;
      const error = data || response.statusText;

      // console.log(error)
      return Promise.reject(error);
    }

    return data;
  });
}
