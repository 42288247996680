// prettier-ignore

const RackingDD = {
  0: {
    'id': 0,
    'data': {
      'name': 'Custom Rack',
      'icon': 0,
      'default': 0,
      'type': 0,
      'racks': [
        {
          'key': 1,
          'active': 1,
          'xdim': 29.89,
          'ydim': 3.917,
          'module': 60,
          'string_count' : 3,
        },
        {
          'key': 2,
          'active': 1,
          'xdim': 15.01,
          'ydim': 3.917,
          'module': 30,
          'string_count' : 2,
        },
        {
          'key': 3,
          'active': 0,
          'xdim': 10.05,
          'ydim': 3.917,
          'module': 20,
          'string_count' : 1,
        },
      ],
      'gap': 1,
      'tilt_min': 10,
      'tilt_max': 35,
			'tilt_inc': 5,
      'backtrack': 0,
      'track_angle': 0,
      'shade_mode': 1,
      'string_steps': 2,
      'cell_trav': 0.156,
      'module_clearance_height': 0.5,
      'module_transmission_perc': 0,
      'module_structure_shade_perc': 0,
      "modules_per_string": 28,
      'do_dualTilt': 0,
			'grade_limit': 20,
      'rack_dims': 0, //0  = auto, 1 = manual
      'modules_high': 1,
      'orientation': 0,// 0 = portrait, 1 = landscape
      'module_gap': 0.02,
      'drive_gap': 2.0

    },
  },
  '49aa35992cf4480e9a2f1152c43edcda': {
    'id': '49aa35992cf4480e9a2f1152c43edcda',
    'data': {
      'name': 'Generic SAT',
      'icon': 2,
      'default': 0,
      'type': 1,
      'racks': [
        {
          'key': 1,
          'active': 1,
          'xdim': 1.956,
          'ydim': 88.303,
          'module': 84,
          'string_count' : 3,
        },
        {
          'key': 2,
          'active': 1,
          'xdim': 1.956,
          'ydim': 59.41,
          'module': 56,
          'string_count' : 2,
        },
        {
          'key': 3,
          'active': 0,
          'xdim': 1.956,
          'ydim': 30.516,
          'module': 28,
          'string_count' : 1,
        },
      ],
      'gap': 2,
      'tilt_min': 0,
      'tilt_max': 0,
			'tilt_inc': 0,
      'backtrack': 1,
      'track_angle': 60,
      'shade_mode': 1,
      'string_steps': 1,
      'cell_trav': 0.156,
      'module_clearance_height': 1.5,
      'module_transmission_perc': 0,
      'module_structure_shade_perc': 0,
      "modules_per_string": 28,
      'grade_limit': 10,
      'rack_dims': 0, //0  = auto, 1 = manual
      'modules_high': 1,
      'orientation': 0,// 0 = portrait, 1 = landscape
      'module_gap': 0.02,
      'drive_gap': 2.0
    },
  },
  '451693916eaa41329809004a12b4f5d8': {
    'id': '451693916eaa41329809004a12b4f5d8',
    'data': {
      'name': 'Generic GFT 2HP',
      'icon': 2,
      'default': 0,
      'type': 0,
      'racks': [
        {
          'key': 1,
          'active': 1,
          'xdim': 27.906,
          'ydim': 3.917,
          'module': 56,
          'string_count' : 2,
        },
        {
          'key': 2,
          'active': 1,
          'xdim': 14.018,
          'ydim': 3.917,
          'module': 28,
          'string_count' : 1,
        },
        {
          'key': 3,
          'active': 0,
          'xdim': 7.074,
          'ydim': 3.917,
          'module': 14,
          'string_count' : 0.5,
        },
      ],
      'gap': 0,
      'tilt_min': 10,
      'tilt_max': 30,
			'tilt_inc': 5,
      'backtrack': 0,
      'track_angle': 5,
      'shade_mode': 1,
      'string_steps': 2,
      'cell_trav': 0.156,
      'module_clearance_height': 0.5,
      'module_transmission_perc': 0,
      'module_structure_shade_perc': 0,
      "modules_per_string": 28,
      'grade_limit': 20,
      'rack_dims': 0, //0  = auto, 1 = manual
      'modules_high': 2,
      'orientation': 0,// 0 = portrait, 1 = landscape
      'module_gap': 0.02,
      'drive_gap': 2.0
    },
  },
  '07a81036b1c6468189f82b9dbbef81db': {
    'id': '07a81036b1c6468189f82b9dbbef81db',
    'data': {
      'name': 'Generic EW 1HP',
      'icon': 2,
      'default': 0,
      'type': 2,
      'racks': [
        {
          'key': 1,
          'active': 1,
          'xdim': 1.96,
          'ydim': 27.91,
          'module': 56,
          'string_count' : 3,
        },
        {
          'key': 2,
          'active': 1,
          'xdim': 1.96,
          'ydim': 14.02,
          'module': 28,
          'string_count' : 2,
          
        },
        {
          'key': 3,
          'active': 0,
          'xdim': 1.96,
          'ydim': 7.07,
          'module': 14,
          'string_count' : 1,
        },
      ],
      'gap': 0,
      'tilt_min': 8,
      'tilt_max': 8,
			'tilt_inc': 5,
      'backtrack': 0,
      'track_angle': 5,
      'shade_mode': 1,
      'string_steps': 1,
      'cell_trav': 0.156,
      'module_clearance_height': 0.5,
      'module_transmission_perc': 0,
      'module_structure_shade_perc': 0,
      "modules_per_string": 28,
      'grade_limit': 10,
      'rack_dims': 0, //0  = auto, 1 = manual
      'modules_high': 2,
      'orientation': 0,// 0 = portrait, 1 = landscape
      'module_gap': 0.02,
      'drive_gap': 2.0
    },
  },
};

export { RackingDD };
