// ********** LOSS TABLE STUFF ***********************
// these arrays are used to shape and calculate loss data.
const losses = [
  "aa. Horizontal global irradiation (kWh/m^2)",
  "ab. Global incident in coll. plane %",
  "ac. Global incident below threshold",
  "ad. Near Shadings: irradiance_loss",
  "ae. IAM factor on global",
  "af. Soiling loss factor",
  "ba. Ground reflection on front side",
  "bb. Global incidence on ground",
  "bc. Global incidence on ground on area",
  "bd. Ground reflection loss (Albedo)",
  "be. View Factor for rear side",
  "bf. sky diffuse on the rear side",
  "bi. Beam effective on the rear side",
  "bj. Shadings loss on rear side",
  "bk. Global irradiance on rear side",
  "bl. Effective irradiation on collectors rear (kWh/m^2)",
  "bm. Effective irradiation on collectors rear * bifaciality (kWh/m^2)",
  "ca. Effective irradiation on collectors (kWh/m^2)",
  "cb. Area of collectors (m^2)",
  "cc. Efficiency at STC (%)",
  "cd. Array nominal energy at STC (MWh)",
  "da. Loss due to irradiance level",
  "db. PV loss due to temperature",
  "dc. Shadings: Electrical loss",
  "dd. Spectral correction",
  "de. Module quality loss",
  "df. LID - Light induced degradation",
  "dg. Mismatch loss, modules and strings",
  "dh. Mismatch for back irradiance",
  "di. Ohmic wiring loss",
  "dj. Array virual energy at MPP",
  "ea. Inverter Loss during operation (efficiency)",
  "eb. Inverter Loss over nominal inv. power",
  "ec. Inverter Loss due to max input current",
  "ed. Inverter Loss over nominal inv. voltage",
  "ee. Inverter loss due to power threshold",
  "ef. Inverter loss due to voltage threshold",
  "eg. Night consumption",
  "eh. Available Energy at Inverter Output (MWh)",
  "fa. Auxiliaries (fans, other)",
  "fb. AC ohmic loss",
  "fc. MV transformer loss",
  "fd. MV line ohmic losss",
  "fe. Fixed Transmission Loss",
  "ff. AC Other",
  "fg. Unused energy (grid limitation)",
  "fh. Energy injected into Grid (MWh)",
  "y. Yield (kWh/kWp)",
];

let ghi_outputs = [
  "aa. Horizontal global irradiation (kWh/m^2)",
  "ab. Global incident in coll. plane %",
  "ac. Global incident below threshold",
  "ad. Near Shadings: irradiance_loss",
  "ae. IAM factor on global",
  "af. Soiling loss factor",
  "ba. Ground reflection on front side",
  "ca. Effective irradiation on collectors (kWh/m^2)",
  "cb. Area of collectors (m^2)",
];

let bifacial_outputs = [
  "bb. Global incidence on ground",
  "bc. Global incidence on ground on area",
  "bd. Ground reflection loss (Albedo)",
  "be. View Factor for rear side",
  "bf. sky diffuse on the rear side",
  "bi. Beam effective on the rear side",
  "bj. Shadings loss on rear side",
  "bk. Global irradiance on rear side",
  "PV conversion, Bifaciality factor",
  "bl. Effective irradiation on collectors rear (kWh/m^2)",
  "bm. Effective irradiation on collectors rear * bifaciality (kWh/m^2)",
];

let bolded_poi_outputs = [
  "aa. Horizontal global irradiation (kWh/m^2)",
  "ca. Effective irradiation on collectors (kWh/m^2)",
  "cd. Array nominal energy at STC (MWh)",
  "dj. Array virual energy at MPP",
  "eh. Available Energy at Inverter Output (MWh)",
  "fh. Energy injected into Grid (MWh)",
  "bb. Global incidence on ground",
  "bk. Global irradiance on rear side",
  "bl. Effective irradiation on collectors rear (kWh/m^2)",
  "bm. Effective irradiation on collectors rear * bifaciality (kWh/m^2)",
];

let items_to_remove = ["fg. Unused energy (grid limitation)", "y. Yield (kWh/kWp)", "bm. Effective irradiation on collectors rear * bifaciality (kWh/m^2)"];

const clean_object_key = (key) => {
  // remove the first few characters of the object keys that come from the backend.
  let regex = /[a-zA-Z]+\. /i;
  return key.replace(regex, "");
};

const pick = (obj, keys) => {
  // this function just pulls out all of the values we need from the flattened result data
  let result = {};
  for (let key of keys) {
    result[key] = obj[key];
  }
  return result;
};

const generateLossTable = (data) => {
  let loss_stack = pick(data, losses);
  let temp_loss_table_one = {};
  let temp_loss_table = {};
  let temp_loss_table_final = {};
  let current_base_number;

  console.log("loss", loss_stack);

  //organize data by loss type so that we can loop through it a little easier in order to do the calculations in the correct order
  Object.keys(loss_stack).map((key, index) => {
    if (ghi_outputs.findIndex((type) => type == key) >= 0) {
      temp_loss_table_one["ghi"] = {
        ...temp_loss_table_one["ghi"],
        [key]: { value: loss_stack[key] },
      };
    } else if (bifacial_outputs.findIndex((type) => type == key) >= 0) {
      temp_loss_table_one["bifacial"] = {
        ...temp_loss_table_one["bifacial"],
        [key]: { value: loss_stack[key] },
      };
    } else {
      temp_loss_table_one["efficiency"] = {
        ...temp_loss_table_one["efficiency"],
        [key]: { value: loss_stack[key] },
      };
    }
  });

  //loop through the reorganized data and calculate losses and gains and account for any inconsistencies in the data.
  Object.keys(temp_loss_table_one).map((type) => {
    Object.keys(temp_loss_table_one[type]).map((key, index) => {
      if (type == "ghi") {
        if (key == "aa. Horizontal global irradiation (kWh/m^2)") {
          current_base_number = temp_loss_table_one[type][key].value;
          temp_loss_table[key] = { value: parseFloat(current_base_number.toFixed(2)), poi: bolded_poi_outputs?.findIndex((type) => type == key) >= 0 ? true : false };
        } else if (key == "cb. Area of collectors (m^2)") {
          temp_loss_table[key] = { value: +temp_loss_table_one[type][key].value.toFixed(2), change: 0 };
        } else if (key == "ca. Effective irradiation on collectors (kWh/m^2)") {
          temp_loss_table[key] = { value: parseFloat(temp_loss_table_one[type][key].value.toFixed(2)), change: 0, poi: bolded_poi_outputs?.findIndex((type) => type == key) >= 0 ? true : false };
        } else {
          // multiply current base number by absolute value of the loss
          let calculated_value = current_base_number * Math.abs(temp_loss_table_one[type][key].value);
          // get percentage of loss or gain
          let percentage = Math.sign(temp_loss_table_one[type][key].value) == -1 ? -(calculated_value / current_base_number) * 100 : (calculated_value / current_base_number) * 100;
          // add or subtract the calculated value depending on if the value is negative or positive
          let new_base_value = Math.sign(temp_loss_table_one[type][key].value) == -1 ? current_base_number - calculated_value : current_base_number + calculated_value;
          // reassign current_base_number
          current_base_number = new_base_value;
          // add object
          temp_loss_table[key] = {
            value: parseFloat(new_base_value.toFixed(2)),
            change: parseFloat(percentage.toFixed(2)),
            poi: bolded_poi_outputs?.findIndex((type) => type == key) >= 0 ? true : false,
          };
        }
      } else if (temp_loss_table_one["bifacial"].length > 0 && type == "bifacial") {
        if (key !== "bl. Effective irradiation on collectors rear (kWh/m^2)") {
          if (key == "bb. Global incidence on ground") {
            // clear base number
            current_base_number = 0;
            // assign new base number
            current_base_number = temp_loss_table_one[type][key].value;
            // add object
            temp_loss_table[key] = { value: parseFloat(current_base_number.toFixed(2)), change: 0, poi: bolded_poi_outputs?.findIndex((type) => type == key) >= 0 ? true : false };
          } else if (key == "bc. Global incidence on ground on area") {
            temp_loss_table[key] = { value: parseFloat(temp_loss_table_one[type][key].value.toFixed(2)), change: 0, poi: bolded_poi_outputs?.findIndex((type) => type == key) >= 0 ? true : false };
          } else if (key == "bk. Global irradiance on rear side") {
            //calculate percentage
            let irr_rear_percentage = temp_loss_table_one[type][key].value * 100;
            temp_loss_table[key] = {
              value: parseFloat(temp_loss_table_one[type]["bl. Effective irradiation on collectors rear (kWh/m^2)"].value.toFixed(2)),
              change: parseFloat(irr_rear_percentage.toFixed(2)),
              poi: bolded_poi_outputs?.findIndex((type) => type == key) >= 0 ? true : false,
            };
          } else {
            // multiply current base number by absolute value of the loss
            let calculated_value = current_base_number * Math.abs(temp_loss_table_one[type][key].value);
            // get percentage of loss or gain
            let percentage = Math.sign(temp_loss_table_one[type][key].value) == -1 ? -(calculated_value / current_base_number) * 100 : (calculated_value / current_base_number) * 100;
            // add or subtract the calculated value depending on if the value is negative or positive
            let new_base_value = Math.sign(temp_loss_table_one[type][key].value) == -1 ? current_base_number - calculated_value : current_base_number + calculated_value;
            // reassign current_base_number
            current_base_number = new_base_value;
            // add object
            temp_loss_table[key] = {
              value: parseFloat(new_base_value.toFixed(2)),
              change: parseFloat(percentage.toFixed(2)),
              poi: bolded_poi_outputs?.findIndex((type) => type == key) >= 0 ? true : false,
            };
          }
        }
      } else {
        if (key == "cc. Efficiency at STC (%)") {
          let calculated__stc_percentage = temp_loss_table_one[type][key].value * 100;
          temp_loss_table[key] = {
            value: parseFloat(temp_loss_table_one[type][key].value.toFixed(2)),
            change: parseFloat(calculated__stc_percentage.toFixed(2)),
            poi: bolded_poi_outputs?.findIndex((type) => type == key) >= 0 ? true : false,
          };
        } else if (key == "cd. Array nominal energy at STC (MWh)") {
          // clear base number
          current_base_number = 0;
          // assign new base number
          current_base_number = temp_loss_table_one[type][key].value;
          // add object
          temp_loss_table[key] = { value: parseFloat(current_base_number.toFixed(2)), change: 0, poi: bolded_poi_outputs?.findIndex((type) => type == key) >= 0 ? true : false };
        } else if (key == "da. Loss due to irradiance level") {
          // this number is already a percentage so we just add the object without changing anything
          temp_loss_table[key] = {
            value: parseFloat(temp_loss_table_one[type][key].value.toFixed(2)),
            change: parseFloat(temp_loss_table_one[type][key].value.toFixed(2)),
            poi: bolded_poi_outputs?.findIndex((type) => type == key) >= 0 ? true : false,
          };
        } else if (key == "dj. Array virual energy at MPP" || key == "eh. Available Energy at Inverter Output (MWh)") {
          // clear base number
          current_base_number = 0;
          // assign new base number
          current_base_number = temp_loss_table_one[type][key].value;
          // add object
          temp_loss_table[key] = { value: parseFloat(current_base_number.toFixed(2)), change: 0, poi: bolded_poi_outputs?.findIndex((type) => type == key) >= 0 ? true : false };
        } else if (key == "fh. Energy injected into Grid (MWh)") {
          temp_loss_table[key] = { value: parseFloat(current_base_number.toFixed(2)), change: 0, poi: bolded_poi_outputs?.findIndex((type) => type == key) >= 0 ? true : false };
        } else {
          // multiply current base number by absolute value of the loss
          let calculated_value = current_base_number * Math.abs(temp_loss_table_one[type][key].value);
          // get percentage of loss or gain
          let percentage = Math.sign(temp_loss_table_one[type][key].value) == -1 ? -(calculated_value / current_base_number) * 100 : (calculated_value / current_base_number) * 100;
          // add or subtract the calculated value depending on if the value is negative or positive
          let new_base_value = Math.sign(temp_loss_table_one[type][key].value) == -1 ? current_base_number - calculated_value : current_base_number + calculated_value;
          // reassign current_base_number
          current_base_number = new_base_value;
          // add object
          temp_loss_table[key] = {
            value: parseFloat(new_base_value.toFixed(2)),
            change: parseFloat(percentage.toFixed(2)),
            poi: bolded_poi_outputs?.findIndex((type) => type == key) >= 0 ? true : false,
          };
        }
      }
    });
  });

  //clean keys and organize them into bifacial/ghi objects
  Object.keys(temp_loss_table).map((key, index) => {
    if (items_to_remove.findIndex((type) => type == key) >= 0) return;
    if (bifacial_outputs.findIndex((type) => type == key) >= 0) {
      temp_loss_table_final["bifacial"] = {
        ...temp_loss_table_final.bifacial,
        [clean_object_key(key)]: { ...temp_loss_table[key] },
      };
    } else {
      temp_loss_table_final["ghi"] = {
        ...temp_loss_table_final.ghi,
        [clean_object_key(key)]: { ...temp_loss_table[key] },
      };
    }
  });

  return temp_loss_table_final;
};

export { generateLossTable };
