import styled from 'styled-components';

const RackingPanelWrapper = styled.section`
  width: 100%;
  padding: 10px;

  .input-2-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'label rackInput';
    place-items: center start;
    margin: 8px 0px;

    label {
      grid-area: label;
      margin: 0px;
    }

    input {
      grid-area: rackInput;
      margin-left: auto;
      max-width: 120px;
    }

    p {
      grid-area: rackInput;
      margin: 0px;
      margin-left: auto;
    }
  }

  .input-header-right {
    width: 100%;
    display: grid;
    place-content: center right;
    margin-top: 25px;
    margin-bottom: 5px;

    p {
      margin: 0px;
    }
  }

  .input-3-col {
    display: grid;
    grid-template-columns: 2fr 1fr 95px;
    grid-template-areas: 'label switch input';
    margin: 8px 0px;

    .toggle {
      width: 50px;
    }
  }

  .racking_error_warning {
    width: 100%;
    display: grid;
    place-items: center;
    color: red;
    margin: 10px 0px;
    p {
      margin: 0px;
    }
  }
`;

export { RackingPanelWrapper };
