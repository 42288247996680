import React, { Fragment } from "react";

import { Link } from "react-router-dom";

import { Button, Card, Col, Row, Skeleton, Popconfirm, Spin, Alert, Empty, Tag } from "antd";
import { QuestionOutlined, InfoCircleFilled } from "@ant-design/icons";

import cards from "./cards";
import { PlanManager, AddPaymentMethod } from "./";

import { accountActions } from "../../../Redux";
import { Funnel } from "../../../Funnel";

// styles
import { ManagePlanColumn, FreeTrialColumn } from "./ContainerStyles";

class Plan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addingCard: false,
      showPlanManager: false,
      updating_payments: false,
      funnel_visible: false,
    };
  }

  // controls bool for if payment method modal is visible
  handleAddingCard = (bool) => {
    this.setState({ addingCard: bool });
  };
  // called to open modal to manage plans
  showPlanMangerMoodal = () => {
    this.setState({ showPlanManager: true });
  };
  // called when modal is canceled
  handleCancel = () => {
    this.setState({
      showPlanManager: false,
      addingCard: false,
    });
  };
  // called from payment method addition page
  handleConfirmSub = (sub) => {
    if (this.props.user.user_type == 3) {
      this.setState({
        showPlanManager: false,
        confirmLoading: false,
        selectingSub: false,
      });
      return;
    }

    if (sub) {
      this.setState({ selectingSub: true });
      this.props.OnPlanUpdated(sub);
    }
    setTimeout(() => {
      this.setState({
        showPlanManager: false,
        confirmLoading: false,
        selectingSub: false,
      });
    }, 500);
  };
  // Called from stripe callback for adding a card
  handleReturn = (token, name) => {
    this.setState({ addingCard: false });
    let _token = {
      ...token,
      name: name,
    };
    this.props.OnPaymentAdded(_token);
  };

  // called from link within payment table
  handleMakeDefault = (method) => {
    this.props.OnMakeDefault(method.id);
  };
  // called from link within payment table
  handleDeleteMethod = (method) => {
    this.props.OnDeleteMethod(method.id);
  };
  getRemanningDays = () => {
    var date = new Date();
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    var days = time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
    return days;
  };
  calculateProration = (new_product, current_charges) => {
    // console.log(new_product);
    let charge_per_day = Math.round(new_product.monthly_cost / 30);
    let days_left = this.getRemanningDays();
    // console.log(charge_per_day, days_left, current_charges)
    return (charge_per_day * days_left - current_charges).toFixed(2);
  };
  resendEmailVerification = () => {
    this.props.OnResendEmailVerification();
  };

  render() {
    const { billing, products, user } = this.props;
    let loaded = false;

    // console.log( billing, products, user )
    if (billing && products && user) {
      loaded = true;
    }

    if (!loaded) {
      return (
        <div style={{ padding: "7%" }}>
          <Skeleton active />
        </div>
      );
    } else {
      // const { billing, products, user } = details;
      let paymentInfo = billing;
      let apiKey = user.apiKey;

      return (
        <div style={{ background: "#FFFFFF" }}>
          {user.email_verified == 0 && (
            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <Alert
                className="sift-alert"
                message={
                  <span>
                    E-mail must be verified to use SIFT, check your inbox or spam folder for the verification link.
                    <span> </span>
                    <a
                      onClick={() => {
                        this.resendEmailVerification();
                      }}
                    >
                      <b>RESEND VERIFICATION E-MAIL.</b>
                    </a>
                  </span>
                }
                type="warning"
                showIcon
              />
            </div>
          )}
          {loaded === true && (
            <Row gutter={24}>
              <Col span={12}>
                <Card title={`Plan: ${products[user.product_plan_paid - 1].name}`} style={{ height: 650 }} className="account">
                  <div>
                    {/* <div style={{ width: '350px', margin: '0 auto', paddingBottom: '32px' }}> */}
                    <ManagePlanColumn>
                      <Button
                        type="primary"
                        style={{ width: "100%" }}
                        // onClick={this.showPlanMangerMoodal}
                        onClick={this.props.onToggleFunnel}
                      >
                        MANAGE PLAN
                      </Button>
                    </ManagePlanColumn>
                    {/* </div> */}
                  </div>

                  {/* Account alerts */}
                  {(user.free_trial_status == 1 || user.free_trial_status == 3) && (
                    <p>
                      SIFT Free Trial expires: <b>{user.free_trial_end_dt}.</b>
                    </p>
                  )}
                  {user.free_trial_status == 2 && user.product_plan > 1 && (
                    <p>
                      Next billing cycle: <b>{user.plan_charge_dt}</b>.
                    </p>
                  )}
                  {user.product_plan_paid > user.product_plan && (
                    <p>
                      You have paid for the <b>{products[user.product_plan_paid - 1].name}</b> plan this billing cycle. You will be downgraded to the <b>{products[user.product_plan - 1].name}</b> plan
                      on {user.plan_charge_dt}. {user.product_plan == 1 && <span>You will no longer be charged for SIFT.</span>}
                    </p>
                  )}

                  {/* Overview of plan */}
                  <div>
                    <Alert
                      className="sift-alert"
                      message="Plan Overview"
                      type="success"
                      showIcon
                      description={
                        <span>
                          <ul style={{ padding: "0px 20px" }}>
                            {products[user.product_plan_paid - 1].product_description.split(";").map((desc, index) => (
                              <li key={index}>{desc}</li>
                            ))}
                          </ul>
                        </span>
                      }
                    />
                  </div>

                  <br />
                  {/* API Key */}
                  {/* <p>API Key:</p> <ul> <li> {user.product_plan_paid > 1 ? apiKey : <span>Free plan does not include API access.</span>} </li> </ul>

						<p>API Keys are used to access Sunfig's Developer APIs. See <Link to="/docs">documentation</Link> for endpoint information.</p> */}
                </Card>
              </Col>

              <Col span={12}>
                <Card title="Payment Settings" style={{ height: 650 }} className="account">
                  <div>
                    {/* <div style={{ width: '350px', margin: '0 auto', paddingBottom: '32px' }}> */}
                    <ManagePlanColumn>
                      <Button
                        type="primary"
                        style={{ width: "100%" }}
                        // onClick={() => {this.showModal('payment')}}
                        disabled={user.user_type == 3}
                        onClick={() => {
                          this.setState({ addingCard: true });
                        }}
                      >
                        <span>ADD PAYMENT METHOD</span>
                      </Button>
                    </ManagePlanColumn>
                    {/* </div> */}
                  </div>

                  {user.free_trial_status == 0 && (
                    <FreeTrialColumn>
                      <InfoCircleFilled style={{ fontSize: "13px", color: "#2890FF", marginTop: "5px", marginRight: "8px" }} />
                      <span>
                        You have a 7 day Free Trial for a STANDARD or PRO plan, click <a onClick={() => this.props.dispatch(accountActions.toggleFunnelVis(true))}>here</a> to upgrade. Valid payment
                        method is required.
                      </span>
                    </FreeTrialColumn>
                  )}

                  {/* <Alert
                        className="sift-alert"
                        message={
                          <span>
                            You have a 7 day Free Trial for a STANDARD or PRO plan, click <a onClick={() => this.props.dispatch(accountActions.toggleFunnelVis(true))}>here</a> to upgrade. Valid
                            payment method is required.
                          </span>
                        }
                        type="info"
                        showIcon
                      />
                      <br /> */}

                  {/* TEMP ALERT WHILE IN BETA: REMOVE AUG 1st */}
                  {/* <div style={{paddingBottom: '10px'}}>
								<Alert
									message="Notice"
									description="The SIFT platform will be in beta from June 1 through July 31. We appreciate your feedback during this time of growth and development. We do require a valid form of payment to upgrade to a paid plan, but we will not be charging fees for June or July. Our first invoices will go out August 1. If you change your plan to free before August 1, you will not be invoiced. Thank you!"
									type="warning"
									showIcon
								/>								
							</div> */}

                  {user.user_type == 3 ? (
                    <div style={{ paddingBottom: "10px" }}>
                      <Alert
                        className="sift-alert"
                        // message="Notice"
                        description="Your account is paid for and managed by your organization, no payment method is required. Your plan features are shown to the left, upgrading or downgrading your plan is disabled. For inquiries about upgrading SIFT to a higher plan, speak with your organization's SIFT accounts manager."
                        type="info"
                        showIcon
                      />
                    </div>
                  ) : (
                    <div style={{ overflowY: "auto", width: "inherit", height: "290px", padding: "0 10px" }}>
                      {paymentInfo.length == 0 && (
                        <div id="empty-payment-container">
                          <Empty description="No Payment Method Added" />
                        </div>
                      )}
                      <Spin spinning={this.props.updating}>
                        {/* loop through payment method's and create a component */}
                        {paymentInfo.map((method, index) => {
                          // console.log(method.brand)
                          const paymentIconIndex = cards.findIndex((card) => card.name == method.brand);
                          return (
                            <div className="payment-card" key={index}>
                              <div className="payment-text-holder">
                                <div className="payment-text">
                                  {method.brand} (**** {method.last4})
                                </div>
                                <div className="payment-text">
                                  Expiration Date {method.exp_month}/{method.exp_year}
                                </div>
                                <div className="payment-text">
                                  <span>
                                    {method.is_default ? (
                                      <Fragment>
                                        <span>
                                          <Tag color="#002bcb">DEFAULT</Tag>
                                        </span>
                                        -{" "}
                                        <span>
                                          <Popconfirm
                                            title="Delete this Payment Method?"
                                            onConfirm={() => {
                                              this.handleDeleteMethod(method);
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <a>Delete</a>
                                          </Popconfirm>
                                        </span>
                                      </Fragment>
                                    ) : (
                                      <span>
                                        <Popconfirm
                                          title="Make this method default?"
                                          onConfirm={() => {
                                            this.handleMakeDefault(method);
                                          }}
                                          okText="Yes"
                                          cancelText="No"
                                        >
                                          <a>Make Default</a>
                                        </Popconfirm>{" "}
                                        -{" "}
                                      </span>
                                    )}
                                    {method.is_default ? (
                                      <span></span>
                                    ) : (
                                      <span>
                                        <Popconfirm
                                          title="Delete this Payment Method?"
                                          onConfirm={() => {
                                            this.handleDeleteMethod(method);
                                          }}
                                          okText="Yes"
                                          cancelText="No"
                                        >
                                          <a>Delete</a>
                                        </Popconfirm>
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div>{paymentIconIndex > -1 ? <img src={cards[paymentIconIndex].icon} /> : <QuestionOutlined />}</div>
                            </div>
                          );
                        })}
                      </Spin>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          )}

          {apiKey && (
            <div>
              {/* <PlanManager
						user={user}
						products={products}
						billing={billing}
						active={this.state.showPlanManager}
						updating={this.props.updating}
						onCancel={this.handleCancel}
						onAddingCard={this.handleAddingCard}
						onSubConfirmed={this.handleConfirmSub}

					/> */}

              <AddPaymentMethod user={user} addingCard={this.state.addingCard} updating={this.props.updating} onAddingCard={this.handleAddingCard} handleReturn={this.handleReturn} />
            </div>
          )}
        </div>
      );
    }
  }
}

export { Plan };
