import React from 'react';
import { connect } from 'react-redux';

import { alertActions, accountActions, authActions, siftActions } from '../../components/Redux/_actions';
import { handleAlert } from '../../components/Redux/_helpers';

import { Plan, Billing, UserSettings } from './_components/_containers';

import { Modal, Tabs, message, Spin } from 'antd';
import { SolutionOutlined, BookOutlined, UserOutlined } from '@ant-design/icons';
const TabPane = Tabs.TabPane;

const tab_style = {
  float: 'right',
};

class Account extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: this.props.account.user,
      billing: this.props.account.billing,
      products: this.props.account.products,
      usage: this.props.account.usage,
      authentication: this.props.authentication,
      invoices: this.props.invoices,

      plan_updating: false,
      saving_user: false,

      local_user: undefined,
    };

    if (this.state.user) {
      this.state.local_user = {
        invoice_name: this.state.user.invoice_name,
        invoice_company: this.state.user.company,
        invoice_address: this.state.user.invoice_address,
        default_lat: this.state.user.map_center[0],
        default_lng: this.state.user.map_center[1],
				use_random_map_location: this.state.user.use_random_map_location
      };
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(props) {
    const { alert } = props;
    if (alert != this.props.alert) {
      handleAlert(alert);
    }

    const { billing, usage, user, products, invoices, payments } = props;

    if (billing != this.props.billing) {
      this.setState({ billing: billing, plan_updating: false });
    }

    if (usage != this.props.usage) {
      this.setState({ usage: usage, plan_updating: false });
    }

    // should only be called if new user information has been syncd down (plan update, name update, email update, etc)
    if (user && user != this.props.user) {
      this.setState({
        user: user,
        plan_updating: false,
        local_user: {
          invoice_name: user.invoice_name,
          invoice_company: user.company,
          invoice_address: user.invoice_address,
          default_lat: user.map_center[0],
          default_lng: user.map_center[1],
					use_random_map_location: user.use_random_map_location
        },
      });
    }

    if (products != this.props.products) {
      this.setState({ products: products, plan_updating: false });
    }

    if (invoices != this.props.invoices) {
      this.setState({ invoices: invoices, plan_updating: false });
    }

    if (payments != this.props.payments) {
      this.setState({ payments: payments, plan_updating: false });
    }
  }

  // Plan.jsx - event hooks
  handlePaymentAdded = (token) => {
    this.setState({ plan_updating: true });
    this.props.dispatch(accountActions.addPayment(token));
  };
  handleUpdatePlan = (plan) => {
    this.setState({ plan_updating: true });
    this.props.dispatch(accountActions.updatePlanTo(plan));
  };
  handleMakeDefault = (id) => {
    this.setState({ plan_updating: true });
    this.props.dispatch(accountActions.setDefaultPayment(id));
  };
  handleDeleteMethod = (id) => {
    this.setState({ plan_updating: true });
    let cardArray = Object.values(this.props.billing).filter((card) => card.id !== id);
    if (cardArray.length > 0) {
      this.handleMakeDefault(cardArray[0].id);
    }
    this.props.dispatch(accountActions.deletePayment(id));
  };
  handleResendVerification = () => {
    // console.log('Sending Email Verification code resend request')
    this.props.dispatch(accountActions.resendVerifyEmail());
  };

  // UserSettings.jsx - event hooks
  handleLogOut = () => {
    // console.log('logging out')
    this.props.dispatch(authActions.logout());
  };
  handleSaveChanges = () => {
    this.props.dispatch(accountActions.updateUserData(this.state.local_user));

    this.setState({ saving_user: true }, () => {
      setTimeout(() => {
        this.setState({ saving_user: false });
        message.success('User Info Saved.', 2.5);
      }, 2000);
    });
  };
  handleInputUpdate = (key, value) => {
    let state = this.state;
    state.local_user[key] = value;
    this.setState(state);
  };

  handleToggleFunnel = () => {
    // console.log('toggling')
    this.props.dispatch(accountActions.toggleFunnelVis(true));
  };

  handleToggleOff = () => {
    this.props.dispatch(siftActions.toggleAccountModal(false));
  };

  render() {
    const local_run_count = this.props.sift && this.props.sift.local_run_count;

    return (
      <Modal
        title="Account"
        visible={this.props.visible}
        onCancel={this.handleToggleOff}
        footer={null}
        width="80%"
        centered
        bodyStyle={{ height: '700px', overflowY: 'auto', paddingTop: '0px' }}
        className="sift-modal"
      >
        <Spin spinning={this.props.account.loading} tip="Account Loading..." className="sift-loader">
          {this.props.account.loaded && (
            <Tabs defaultActiveKey="1" onTabClick={this.toggleTab} animated={false} className="account-tab">
              <TabPane
                tab={
                  <span>
                    <SolutionOutlined />
                    Plan
                  </span>
                }
                key="1"
              >
                <Plan
                  dispatch={this.props.dispatch}
                  updating={this.state.plan_updating}
                  billing={this.state.billing}
                  user={this.state.user}
                  products={this.state.products}
                  authentication={this.state.authentication}
                  OnPaymentAdded={this.handlePaymentAdded}
                  OnPlanUpdated={this.handleUpdatePlan}
                  OnMakeDefault={this.handleMakeDefault}
                  OnDeleteMethod={this.handleDeleteMethod}
                  OnResendEmailVerification={this.handleResendVerification}
                  onToggleFunnel={this.handleToggleFunnel}
                />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <BookOutlined />
                    Billing
                  </span>
                }
                key="2"
              >
                <Billing usage={this.state.usage} user={this.state.user} products={this.state.products} invoices={this.state.invoices} local_run_count={local_run_count} />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <UserOutlined />
                    User
                  </span>
                }
                key="3"
              >
                <UserSettings
                  user={this.state.user}
                  local_user={this.state.local_user}
                  OnLogOut={this.handleLogOut}
                  OnSaveChanges={this.handleSaveChanges}
                  OnInputUpdate={this.handleInputUpdate}
                  loading={this.state.saving_user}
                />
              </TabPane>
            </Tabs>
          )}
        </Spin>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { account, authentication, alert, payments, sift } = state;
  const { billing, usage, user, products, invoices } = account;
  // const dispatch = useDispatch();
  // const patchVisible = useSelector((state) => state.sift.ioManager.uiState.patch_visible)
  return {
    alert,
    account,
    billing,
    usage,
    invoices,
    user,
    products,
    authentication,
    payments,
    sift,
    visible: sift.ioManager.uiState.account_visible,
  };
}

const connectedAccount = connect(mapStateToProps)(Account);
export { connectedAccount as Account };
