import React from 'react';
import { connect } from 'react-redux';

import { authActions, isAuthenticated } from '../../components/Redux';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

import './login.css';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      reset_pw: false,
      remember: false,
      continueLogin: false,
      refreshToken: false,
      pw_reset_msg: '',
    };

    this.formRef = React.createRef();
    this.state.remember = localStorage.getItem('remember');
  }

  handleSubmit = (values) => {
    this.setState({ submitted: true, pw_reset_msg: undefined });

    if (this.state.reset_pw) {
      this.props.dispatch(authActions.pwReset(values.email));
    } else {
      if (values.email && values.password) {
        if (values.remember) {
          localStorage.setItem('remember', 1);
        } else {
          localStorage.setItem('remember', 0);
        }
        this.props.dispatch(authActions.login(values.email, values.password, this.props.forward));
      }
    }
  };

  handleForgotPassword = () => {
    this.setState({ reset_pw: true, error: undefined });
  };
  handleCancel = () => {
    this.setState({ reset_pw: false, error: undefined });
  };
  render() {
    return (
      <div>
        <Form onFinish={this.handleSubmit} className="login-form" ref={this.formRef}>
          <div style={{ height: 25 }}></div>

          {/* <label>Email</label> */}
          <FormItem name="email" className="input" rules={[{ required: true, message: 'Please input your email!' }]}>
            <Input ref={(input) => input && input.focus()} prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" className="inner-input" />
          </FormItem>

          <div style={{ height: 6 }}></div>

          {this.state.reset_pw && <FormItem className="input">Enter the email you registered with, and we'll email you a link to reset your password.</FormItem>}

          {/* {!this.state.reset_pw && 
          <label>Password</label>
          } */}

          {!this.state.reset_pw && (
            <FormItem name="password" className="input" rules={[{ required: true, message: 'Please input your password!' }]}>
              <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" className="inner-input" />
            </FormItem>
          )}

          {this.state.reset_pw && (
            <FormItem className="forgot">
              <a onClick={this.handleCancel}>Cancel</a>
            </FormItem>
          )}

          {this.state.reset_pw ? (
            <FormItem className="input" style={{ color: 'red' }}>
              {this.props.pw_reset_msg}
            </FormItem>
          ) : (
            <FormItem className="input" style={{ color: 'red' }}>
              {this.props.login_error}
            </FormItem>
          )}

          {!this.state.reset_pw && (
            <FormItem className="forgot">
              <a onClick={this.handleForgotPassword}>Forgot password</a>
            </FormItem>
          )}

          <Form.Item className="button-wrapper">
            <Button type="primary" htmlType="submit" loading={this.props.loggingIn}>
              {!this.state.reset_pw ? 'Log in' : 'Request Password Reset Email'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, login_error, pw_reset_msg } = state.authentication;
  return {
    login_error,
    pw_reset_msg,
    loggingIn,
    isAuthenticated: isAuthenticated(state),
  };
}

const connectedLogin = connect(mapStateToProps)(Login);
export { connectedLogin as Login };
