import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { siftActions, getProjectInputs } from '../Redux'

import { Modal, Result, Spin, Input } from 'antd';
 
const BugReport = () => {
  const [bugDesc, setBugDesc] = useState('');
  const [bugSent, setBugSent] = useState(false);
  const [bugSending, setBugSending] = useState(false);
  const dispatch = useDispatch();

  const ioManager = useSelector((state) => state.sift.ioManager)
  const bugVisible = ioManager.uiState.bug_visible

  function hideBugReport () { 
    setBugSending(false)
    setBugSent(false);
    setBugDesc('');
    dispatch(siftActions.toggleHelpToolbarVisibility('bug_visible', false))
  }
  function handleBugReport (event) {
    setBugSending(true)

    let params = {
      message: bugDesc,
      inputs: {
        message: bugDesc,
        ...getProjectInputs(ioManager),
      }
    }

    dispatch(siftActions.sendBugReport({...params}))

    setTimeout(() => {
      setBugSent(true)
      setBugSending(false)

      setTimeout(()=>{        
        hideBugReport();

      }, 2000)
    }, 2000);	
  }


  function handleBugDescUpdate (desc) {
    setBugDesc(desc)
  }


    return (
      <Modal
        title="Bug Report"
        visible={bugVisible}
        onOk={handleBugReport}
        okText='Send'
        confirmLoading={bugSending}
        onCancel={hideBugReport}
        okButtonProps={{disabled:bugSent}}
        className="sift-modal bug-report"
      >
        {bugSent ? 
          <Result
            status="success"
            title="Thank you for the report!"
            subTitle="This window will close in 2 seconds"
          /> 
          :
          <div>
            <div style={{height:'5px'}}></div>
            {bugSending 
              ?
              <Spin spinning={bugSending} tip="Sending report..."><div style={{width:'100%', height:'75px'}}></div></Spin> 
              :
              <Input.TextArea 
                rows={3} placeholder="Enter a short description of the bug." 
                onChange={(e)=>handleBugDescUpdate(e.target.value)}
                value={bugDesc}
              />
            }
          </div>
        }
      </Modal>			      
    )
}

export { BugReport }