import React, { useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

//tutorial stuff
import { TutorialTip } from "../../components/TutorialTip";

import { Descriptions, Tooltip, Button, Dropdown, Spin, DatePicker, Input, Statistic } from "antd";

import { BuildOutlined, FileZipOutlined, AppstoreOutlined, FileImageOutlined } from "@ant-design/icons";

const ResultDetailWrapper = styled.section`
  height: 315px;
  overflow-y: scroll;
  display: grid;
  padding: 15px;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  .detail-box {
    width: 100%;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    margin: 10px auto;
    height: min-content;

    h3 {
      padding: 5px;
      margin: 0px;
      font-size: 10pt;
      border-radius: 3px 3px 0px 0px;
      color: #002bcb;
      background: #fafafa;
      border-bottom: 1px solid #e8e8e8;
    }
    .detail-row {
      display: flex;
      justify-content: space-between;
      padding: 3px 10px;
      width: 100%;
      color: #444;
      border-bottom: 1px solid #e8e8e8;

      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

const ResultDetail = (props) => {
  const { result, meta, loading_layout, loading_files, map_loading, onPlot } = props;

  //Tutorial Controls
  const tutorial = useSelector((state) => state.tutorial);
  const screenWidth = useSelector((state) => state.sift.ioManager.uiState.screenWidth);
  const show_live_report = useSelector((state) => state.sift.ioManager.uiState.show_live_report);

  return (
    <div>
      <div className="selected-buttons" style={{ marginLeft: "15px" }}>
        <div>
          <span>Result Actions:</span>
        </div>
        <TutorialTip
          elementKey="download-buttons-tip"
          visible={tutorial.visibleTips.download_buttons}
          customTipWidth={screenWidth < 992 ? 210 : undefined}
          highlight={false}
          placement={screenWidth < 992 ? "bottom" : "right"}
          content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.download_buttons}
        >
          <div id="download-buttons-tip" style={{ display: "flex", width: "min-content", outline: tutorial.visibleTips.download_buttons ? "3px solid #002bcb" : "3px solid transparent" }}>
            <span style={{ marginRight: 5 }}>
              <Tooltip placement="top" title="Plot selected result in the map" mouseEnterDelay={0.5}>
                <Button ghost type="primary" icon={<BuildOutlined />} onClick={() => props.onPlot(result)} loading={loading_layout} size="small">
                  Plot
                </Button>
              </Tooltip>
            </span>

            <span style={{ marginRight: 5 }}>
              <Tooltip placement="top" title="Selected result and 8760 performance to CSV" mouseEnterDelay={0.5}>
                <Button ghost type="primary" icon={<FileZipOutlined />} onClick={() => props.onDownloadFiles(result)} loading={loading_files} size="small">
                  Report
                </Button>
              </Tooltip>
            </span>
            {/* <span style={{ marginRight: 5 }}>
              <Tooltip placement="top" title="Show Live Report" mouseEnterDelay={0.5}>
                <Button ghost type="primary" icon={<FileZipOutlined />} onClick={() => props.onShowReport(result)} loading={loading_files} size="small">
                  {show_live_report ? 'Hide Report' : 'Show Report'}
                </Button>
              </Tooltip>
            </span> */}
            <span style={{ marginRight: 5 }}>
              <Tooltip placement="top" title="Selected result and 8760 performance to CSV" mouseEnterDelay={0.5}>
                <Button ghost type="primary" icon={<FileZipOutlined />} onClick={() => props.onDownloadFiles(result)} loading={loading_files} size="small">
                  CSV
                </Button>
              </Tooltip>
            </span>
            <span style={{ marginRight: 5 }}>
              <Tooltip placement="top" title="Export layout to DXF" mouseEnterDelay={0.5}>
                <Button ghost type="primary" icon={<AppstoreOutlined />} onClick={() => props.onExportMap("export_dxf")} loading={map_loading} size="small">
                  CAD
                </Button>
              </Tooltip>
            </span>
            <span style={{ marginRight: 5 }}>
              <Tooltip placement="top" title="Export map to KMZ" mouseEnterDelay={0.5}>
                <Button ghost type="primary" icon={<FileImageOutlined />} onClick={() => props.onExportMap("export_kml")} loading={map_loading} size="small">
                  KMZ
                </Button>
              </Tooltip>
            </span>
          </div>
        </TutorialTip>
      </div>

      <ResultDetailWrapper>
        {/* module */}
        <div className="detail-box">
          <h3>Module</h3>
          <div className="detail-row">
            <label>Name</label>
            <span>{meta.module_name}</span>
          </div>
          <div className="detail-row">
            <label>Rating (W)</label>
            <span>{meta.module_rating}</span>
          </div>
          <div className="detail-row">
            <label>Bifacial</label>
            <span>{meta.bifacial}</span>
          </div>
          <div className="detail-row">
            <label>Area (m²)</label>
            <span>{meta.module_area}</span>
          </div>
        </div>

        {/* inverter */}
        <div className="detail-box">
          <h3>Inverter</h3>
          <div className="detail-row">
            <label>Name</label>
            <span>{meta.inverter_name}</span>
          </div>
          <div className="detail-row">
            <label>Rating (kW)</label>
            <span>{meta.inverter_rating}</span>
          </div>
        </div>

        {/* racking+performance */}

        <div className="detail-box">
          <h3>Racking</h3>
          <div className="detail-row">
            <label>Name</label>
            <span>{meta.racking_name}</span>
          </div>
          <div className="detail-row">
            <label>Type</label>
            <span>{meta.rack_type == 0 ? "Ground Fixed Tilt" : meta.rack_type == 1 ? "Single-Axis Tracker" : "East West Fixed"}</span>
          </div>
          {/* GFT */}
          {meta.rack_type == 0 ||
            (meta.rack_type == 2 && (
              <div className="detail-row">
                <label>Tilt</label>
                <span>{result.tilt}</span>
              </div>
            ))}

          {/* SAT */}
          {meta.rack_type == 1 && (
            <div className="detail-row">
              <label>Backtracking</label>
              <span>{meta.backtrack}</span>
            </div>
          )}
          {meta.rack_type == 1 && (
            <div className="detail-row">
              <label>Tracking Angle</label>
              <span>{meta.track_angle}</span>
            </div>
          )}
        </div>

        <div className="detail-box">
          <h3>Performance</h3>
          <div className="detail-row">
            <label>Name</label>
            <span>{meta.perf_name}</span>
          </div>
          <div className="detail-row">
            <label>Yield (kWh/kWp)</label>
            <span>{result.YIELD}</span>
          </div>
          <div className="detail-row">
            <label>Generation Yr1 (MWh)</label>
            <span>{result.generation.toFixed(1)}</span>
          </div>
        </div>

        {/* config */}
        <div className="detail-box">
          <h3>Configuration</h3>
          <div className="detail-row">
            <label>GCR</label>
            <span>{result.gcr.toFixed(3)}</span>
          </div>
          <div className="detail-row">
            <label>Pitch (m)</label>
            <span>{result.pitch.toFixed(3)}</span>
          </div>
          <div className="detail-row">
            <label>Intra-row Spacing (m)</label>
            <span>{result.rtr}</span>
          </div>
          <div className="detail-row">
            <label>Inverter Qty</label>
            <span>{result.inverter_count}</span>
          </div>
          <div className="detail-row">
            <label>String Qty</label>
            <span>{result.string_count}</span>
          </div>
          <div className="detail-row">
            <label>Module Qty</label>
            <span>{result.module_count}</span>
          </div>
          <div className="detail-row">
            <label>DC:AC</label>
            <span>{result.dcac.toFixed(3)}</span>
          </div>
          <div className="detail-row">
            <label>Azimuth (°)</label>
            <span>{result.azimuth}</span>
          </div>
          <div className="detail-row">
            <label>MW dc</label>
            <span>{result.mw_peak}</span>
          </div>
          <div className="detail-row">
            <label>MW ac</label>
            <span>{result.mw_ac}</span>
          </div>
        </div>
        {/* losses */}

        <div className="detail-box">
          <h3>Losses</h3>
          <div className="detail-row">
            <label>DC Degradation (%)</label>
            <span>{result.dc_degradation.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>Horiz. Global Irradiance (kWh/m²)</label>
            <span>{result.horizontal_global_irradiance.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>Global Incident in Col. Plane (%)</label>
            <span>{result.global_incident_in_col_plane.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>Near Shadings Irradiance Loss (%)</label>
            <span>{result.near_shadings_irradiance_loss.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>IAM Factor on Global (%)</label>
            <span>{result.iam_factor_on_global.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>Soiling Loss (%)</label>
            <span>{result.soiling_loss.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>Bifacial Gain (%)</label>
            <span>{result.bifacial_gain.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>Glob. Eff. Irradiation on Col (kWh/m²)</label>
            <span>{result.global_effective_irradiation_on_col.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>Array Nominal Energy (kWh)</label>
            <span>{result.array_nominal_energy.toFixed(0)}</span>
          </div>
          <div className="detail-row">
            <label>PV Loss Irrad (%)</label>
            <span>{result.pv_loss_irrad.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>PV Loss Temp (%)</label>
            <span>{result.pv_loss_temp.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>PV Elec Loss (%)</label>
            <span>{result.pv_elec_loss.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>LID Loss (%)</label>
            <span>{result.lid_loss.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>Quality Loss (%)</label>
            <span>{result.quality_loss.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>DC Mismatch Loss (%)</label>
            <span>{result.dc_mismatch_loss.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>Ohmic Wiring Loss (%)</label>
            <span>{result.ohmic_wiring_loss.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>E Array (kWh)</label>
            <span>{result.e_array.toFixed(0)}</span>
          </div>
          <div className="detail-row">
            <label>Inverter Loss (%)</label>
            <span>{result.inverter_loss.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>AC Loss (%)</label>
            <span>{result.ac_loss.toFixed(2)}</span>
          </div>
          <div className="detail-row">
            <label>Interconnect Limit Loss (%)</label>
            <span>{result.interconnect_lim_loss.toFixed(2)}</span>
          </div>
        </div>

        {/* finance */}
        {meta.do_finance && (
          <div className="detail-box">
            <h3>Finance</h3>
            <div className="detail-row">
              <label>Name</label>
              <span>{meta.finance_name}</span>
            </div>
            <div className="detail-row">
              <label>Analysis Period (Yrs)</label>
              <span>{meta.finance_period}</span>
            </div>
            <div className="detail-row">
              <label>Nominal Discount Rate (%)</label>
              <span>{meta.finance_discount_rate}</span>
            </div>

            <div className="detail-row">
              <label>LCOE</label>
              <span>{result.lcoe}</span>
            </div>
            {meta.finance_metric == 1 && (
              <div className="detail-row">
                <label>IRR</label>
                <span>{result.irr}</span>
              </div>
            )}
          </div>
        )}
      </ResultDetailWrapper>
    </div>
  );
};

export { ResultDetail };
