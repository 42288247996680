import React, { useState, useRef, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { siftActions, EPSGData } from "../Redux";
import { OutputPanelWrapper, ToggleResultWrapper } from "./sc";
import { ResultDetail } from "./ResultDetail";
import { ResultTable } from "./ResultTable";
import { Graph3D, createData, defaultOptions } from "../Graph3D";
import { DownloadPDF } from "../PDFReport";

import domtoimage from "dom-to-image";

//tutorial stuff
import { TutorialTip } from "../../components/TutorialTip";

import { LiveReport, generateLossTable } from "../LiveReport";

import { message } from "antd";

import {
  UnorderedListOutlined,
  CopyOutlined,
  FileExcelOutlined,
  BuildOutlined,
  FileZipOutlined,
  AppstoreOutlined,
  FileImageOutlined,
  LoadingOutlined,
  FrownOutlined,
  FileUnknownOutlined,
  ClusterOutlined,
  BarChartOutlined,
  SmileOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";

import { Button, Tabs, Tooltip, Steps } from "antd";
const { TabPane } = Tabs;

import { CSVLink } from "react-csv";
import { hexagon_1 } from "../../assets/images";

const icons = {
  0: <FileUnknownOutlined />,
  1: <ClusterOutlined />,
  2: <BuildOutlined />,
  3: <BarChartOutlined />,
  4: <SmileOutlined />,
};

const d = new Date();
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const OutputPanel = () => {
  const ioManager = useSelector((state) => state.sift.ioManager);
  const userName = useSelector((state) => state.account.user.email);
  const reportData = useSelector((state) => state.sift.ioManager.report.reportData);
  const reportComplete = useSelector((state) => state.sift.ioManager.report.reportComplete);
  const detailsVisible = useSelector((state) => ioManager.uiState.output_detail_visible);
  const tutorialActive = useSelector((state) => state.tutorial.tutorialActive);
  const weather = useSelector((state) => state.sift.ioManager.inputs.weather);

  useEffect(() => {
    // console.log("weather", weather);
  }, [weather]);

  const outputs = ioManager.outputs;
  const doFinance = ioManager.inputs.config.data.do_finance == 1;
  const [graphMode, setGraphMode] = useState(doFinance ? 1 : 0);
  const epsgCode = ioManager.inputs.layout.data.epsg;
  // const [detailsVisible, setDetailsVisible] = useState(false)
  const [activeTab, setActiveTab] = useState("resultList");
  const [detailedResults, setDetailedResults] = useState([]);
  const [hold, setHold] = useState(false);
  const [capturingReport, setCapturingReport] = useState(false);

  const [mapRefs, setMapRefs] = useState({});

  // const [selectedResultId, setSelectedResultId] = useState(undefined);
  const textArea = useRef(null);

  const code = outputs.code;
  const results = Object.values(outputs.results);
  const selectedResult = outputs.selectedResult;
  const show_live_report = ioManager.uiState.show_live_report;

  //used to navigate back to the tab that was plotted during the tutorial when switching back and forth between steps
  const [plottedTutoralTab, setPlottedTutoralTab] = useState(null);

  const loading_layout = ioManager.uiState.loading_layout;
  const loading_files = ioManager.uiState.loading_files;
  const map_loading = ioManager.inputs.map.loading_map;

  //Tutorial Controls
  const tutorial = useSelector((state) => state.tutorial);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log(detailsVisible);
  //   if (selectedResult && activeTab != `detail_${selectedResult.id}`) {
  //     if (detailedResults.findIndex((resId) => resId != selectedResult.id) > 0) {
  //       // setActiveTab(`detail_${selectedResult.id}`);
  //     } else {
  //       onEdit(selectedResult.id, 'add');
  //     }
  //   }
  // }, [detailsVisible]);

  useEffect(() => {
    if (tutorial.tutorialActive && tutorial.tutorialPath == 1) {
      switch (tutorial.currentIndex) {
        case 2:
          setActiveTab("resultList");

          break;
        case 3:
          onPlot(Object.values(results)[0]);
          setTutorialPlotTabOpen();

          break;

        default:
          break;
      }
    }

    // occasionally during step 3 or 4 of the example project tutorial all active tab panes become inactive which collapses the results window. This just checks to make sure there is an active tab and if not, sets it to the default Results tab pane
    if (tutorial.tutorialActive && tutorial.tutorialPath == 1 && (tutorial.currentIndex == 2 || tutorial.currentIndex == 3)) {
      let activeTabPresent = document.getElementsByClassName("ant-tabs-tabpane-active");
      setTimeout(() => {
        if (!activeTabPresent && tutorial.currentIndex == 2) {
          setActiveTab("resultsList");
        } else if (!activeTabPresent && tutorial.currentIndex == 3) {
          setTutorialPlotTabOpen();
        }
      }, 400);
    }
  }, [tutorial.currentIndex]);

  useEffect(() => {
    if (Object.values(outputs.results).length > 0) {
      // setDetailsVisible(true)
      dispatch(siftActions.toggleHelpToolbarVisibility("output_detail_visible", true));
    } else {
      setActiveTab("resultList");
      setDetailedResults([]);
    }
  }, [Object.values(outputs.results).length]);

  let graphData = undefined;
  let zLabel = graphMode == 0 ? "Yield" : code == 151 ? "$/MWh" : "IRR %";
  if (results.length) {
    graphData = createData(graphMode, code, results);
  }

  function captureGraphImage(key, imageData) {
    let tempReportImages = reportData.images;
    tempReportImages[key] = imageData;
    dispatch(siftActions.updateReportData("images", tempReportImages, true));
  }

  function addMapRefs(key, value) {
    setMapRefs((prevState) => ({ ...prevState, [key]: value }));
  }

  function generateLiveReport() {
    let date = `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
    let numOfPages = document.querySelectorAll(".page-wrap").length;

    let reportData = {
      results: selectedResult,
      loss_stack: generateLossTable(selectedResult),
      images: {},
      location: ioManager.inputs.map.map_center,
      racking: ioManager.inputs.racking.data,
      module: ioManager.inputs.module.data,
      inverter: ioManager.inputs.inverter.data,
      performance: ioManager.inputs.performance.data,
      layout: ioManager.inputs.layout.data,
      weather: ioManager.inputs.weather.data,
      // topoData: this.props.portal.topoData,
      projectName: ioManager.inputs.config.project_name,
      userName: userName,
      totalArea: ioManager.inputs.map.totalArea,
      inactiveArea: undefined,
      boundaryArea: undefined,
      exclusionArea: undefined,
      coordSystem: EPSGData[epsgCode].name,
      address: undefined,
      date,
      numOfPages: numOfPages,
    };

    dispatch(siftActions.prepareReport(reportData, true));
  }

  function captureLiveReport(keys = undefined) {
    setCapturingReport(true);
    setHold(false);
    let tempMapImages = {
      ...reportData.images,
      vicinityMap: undefined,
      layoutMap: undefined,
      largeLayoutMap: undefined,
    };
    Object.keys(mapRefs).map(async (key) => {
      let value = await getPngUrl(mapRefs[key]);
      let url = { keys: keys || "overall", value };
      tempMapImages[key] = url.value.url;
    });

    dispatch(siftActions.updateReportData("images", tempMapImages, true));

    setTimeout(() => {
      setCapturingReport(false);
      setHold(true);
    }, 3000);
  }

  async function getPngUrl(mapRef) {
    return new Promise((resolve, reject) => {
      let width = mapRef.width;
      let height = mapRef.height;
      let node = document.getElementById(`${mapRef.className}`);
      domtoimage
        .toJpeg(node, { width, height, quality: 0.6 })
        .then((url) => resolve({ url, width, height }))
        .catch((error) => reject(error));
    });
  }

  // this tries to ensure that there is a plotted tab to switch too for the tutorial.
  function setTutorialPlotTabOpen() {
    let giveUp = false;
    let giveUpCounter = 0;

    while (!giveUp && giveUpCounter < 100) {
      giveUpCounter++;
      if (ioManager.uiState.currentPlot && ioManager.uiState.currentPlot.id) {
        setActiveTab(`detail_${ioManager.uiState.currentPlot.id}`);
        giveUp = true;
      }
    }
  }

  function onPlot(result = undefined) {
    let selectedResult = result || outputs.selectedResult;
    dispatch(siftActions.getLayoutData(selectedResult));
    onEdit(selectedResult.id, "add");
  }

  function onDownloadFiles(result = undefined) {
    let selectedResult = result || outputs.selectedResult;
    dispatch(siftActions.downloadFiles(selectedResult.id));
  }

  function onShowReport(result = undefined) {
    let selectedResult = result || outputs.selectedResult;
    if (!show_live_report) {
      dispatch(siftActions.getLayoutData(selectedResult));
      generateLiveReport();
    }
    dispatch(siftActions.updateUIState("show_live_report", !show_live_report));
    setActiveTab("resultList");
  }

  function onCopyResults() {
    // Copy to Clipoard
    textArea.current.hidden = false;
    textArea.current.select();
    document.execCommand("copy");
    textArea.current.hidden = true;
    message.success("Results copied to clipboard", 3.5);
    // dispatch(alertActions.success('Results copied to clipboard'));
  }
  function onExportMap(fileType) {
    dispatch(siftActions.exportMap(fileType));
  }
  function onSelectResult(result) {
    // setSelectedResultId(result.id);
    dispatch(siftActions.selectResult(result));
  }
  function onHideResults(toggle) {
    // setDetailsVisible(toggle);
    // setSlideDownResults(true);
    dispatch(siftActions.toggleHelpToolbarVisibility("output_detail_visible", toggle));
  }
  function onOpenDetail(result) {
    let selectedResult = result || outputs.selectedResult;
    // dispatch(siftActions.getLayoutData(selectedResult))
    onEdit(selectedResult.id, "add");
  }

  function onEdit(targetKey, action) {
    if (action == "add" && selectedResult && detailedResults.findIndex((resId) => resId == selectedResult.id) == -1) {
      let newOpenedResultsData = [...detailedResults, selectedResult.id];
      setDetailedResults(newOpenedResultsData);
      setActiveTab(`detail_${selectedResult.id}`);
      if (tutorial.tutorialActive && tutorial.currentIndex === 3) setPlottedTutoralTab(`detail_${selectedResult.id}`);
    }
    if (action == "remove") {
      let id = targetKey.replace("detail_", "");
      let newOpenedResultsData = detailedResults.filter((resId) => resId != id);
      setDetailedResults(newOpenedResultsData);
      setActiveTab("resultList");
    }
  }

  function onChangeTab(tab) {
    if (tab != "resultList") {
      let id = tab.replace("detail_", "");
      onSelectResult(outputs.results[id]);
      onPlot();
      // dispatch(siftActions.getLayoutData(selectedResult));
    }
    setActiveTab(tab);
  }

  return (
    <OutputPanelWrapper generated={outputs.runState.generated} detailsVisible={detailsVisible} show_live_report={show_live_report} id="result-table-tip">
      {results.length > 0 && (
        <ToggleResultWrapper detailsVisible={detailsVisible}>
          <section className="toggle-result">
            <a
              onClick={() => {
                if (show_live_report) {
                  dispatch(siftActions.updateUIState("show_live_report", !show_live_report));
                } else {
                  onHideResults(!detailsVisible);
                }
              }}
            >
              {detailsVisible && <DownOutlined />}
            </a>
          </section>
        </ToggleResultWrapper>
      )}
      {detailsVisible && results.length > 0 && (
        <TutorialTip
          elementKey="result-table-tip"
          visible={tutorial.visibleTips.result_table}
          customTipHeight={500}
          customTipWidth={300}
          placement="top"
          content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.result_table}
        >
          <section className="output-data" style={{ outline: tutorial.visibleTips.result_table ? "3px solid #002bcb" : "unset" }}>
            <Tabs type="editable-card" onChange={onChangeTab} activeKey={activeTab || "resultList"} onEdit={onEdit} hideAdd={true} className="output-tabs" tabBarStyle={{}}>
              {/* <UnorderedListOutlined /> */}
              <TabPane
                tab={
                  <TutorialTip
                    elementKey="yolo"
                    customTipHeight={70}
                    customTipWidth={125}
                    offetArrowLeft={"20%"}
                    visible={tutorial.visibleTips.result_tab}
                    // visible={false}
                    highlight={true}
                    placement="top"
                    content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.result_tab}
                  >
                    <section id="yolo">
                      <p>Results</p>
                    </section>
                  </TutorialTip>
                }
                key="resultList"
                closable={false}
              >
                <section className="tab-results">
                  <TutorialTip
                    elementKey="download-csv-tip"
                    visible={tutorial.visibleTips.download_CSV}
                    highlight={false}
                    offetArrowLeft={"5%"}
                    // customTipHeight={150}
                    // customTipWidth={300}
                    placement="bottom"
                    content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.download_CSV}
                  >
                    <section className="results-buttons">
                      {/* <section className="report-button">
                        <Button
                          ghost
                          type="primary"
                          // disabled={tutorialActive}
                          size="large"
                          style={{ width: 120 }}
                          onClick={() => {
                            dispatch(siftActions.updateUIState("show_live_report", !show_live_report));
                            //plot currently selected result if the live report is opened
                            if (!show_live_report && (selectedResult || outputs.selectResult)) {
                              dispatch(siftActions.getLayoutData(selectedResult || outputs.selectedResult));
                              generateLiveReport();
                            }
                          }}
                        >
                          {show_live_report ? "Hide Report" : "Show Report"}
                        </Button>
                      </section> */}

                      {show_live_report ? (
                        <section className="report-download-button">
                          <Button
                            ghost
                            disabled={loading_layout}
                            loading={capturingReport}
                            type="primary"
                            size="large"
                            onClick={() => {
                              captureLiveReport();
                            }}
                          >
                            Download Report
                          </Button>
                        </section>
                      ) : (
                        <section className="all-results">
                          <section className="all-results-header">
                            <p>All Results:</p>
                          </section>
                          <section className="all-results-buttons">
                            <span id="download-csv-tip" style={{ marginRight: 5, outline: tutorial.visibleTips.download_CSV ? "3px solid #002bcb" : "unset" }}>
                              <CSVLink filename={outputs.fileName} data={outputs.csvData}>
                                <Tooltip placement="top" title="All results to CSV" mouseEnterDelay={0.5}>
                                  <Button ghost type="primary" size="small">
                                    <FileExcelOutlined />
                                    CSV
                                  </Button>
                                </Tooltip>
                              </CSVLink>
                            </span>

                            <Tooltip placement="top" title="All results to clipboard" mouseEnterDelay={0.5}>
                              <Button ghost type="primary" onClick={onCopyResults} size="small">
                                <CopyOutlined />
                                Copy
                              </Button>
                            </Tooltip>
                          </section>
                        </section>
                      )}
                      {/* <section className="all-results">
                        <section className="all-results-header">
                          <p>All Results:</p>
                        </section>
                        <section className="all-results-buttons">
                          <span id="download-csv-tip" style={{ marginRight: 5, outline: tutorial.visibleTips.download_CSV ? "3px solid #002bcb" : "unset" }}>
                            <CSVLink filename={outputs.fileName} data={outputs.csvData}>
                              <Tooltip placement="top" title="All results to CSV" mouseEnterDelay={0.5}>
                                <Button ghost type="primary" size="small">
                                  <FileExcelOutlined />
                                  CSV
                                </Button>
                              </Tooltip>
                            </CSVLink>
                          </span>

                          <Tooltip placement="top" title="All results to clipboard" mouseEnterDelay={0.5}>
                            <Button ghost type="primary" onClick={onCopyResults} size="small">
                              <CopyOutlined />
                              Copy
                            </Button>
                          </Tooltip>
                        </section>
                      </section> */}
                    </section>
                  </TutorialTip>

                  {show_live_report ? (
                    <LiveReport addMapRefs={addMapRefs} loadingLayout={loading_layout} captureGraphImage={captureGraphImage} />
                  ) : (
                    <>
                      <ResultTable onSelectResult={onSelectResult} onOpenDetail={onOpenDetail} />

                      <section className="output-graph">
                        <Graph3D
                          data={graphData}
                          options={{
                            ...defaultOptions,
                            zLabel: zLabel,
                            zValueLabel: function (z) {
                              return graphMode == 1 && code == 151 ? z * -1 : z;
                            },
                            width: "380px",
                            height: "280px",
                            tooltip: function ({ x, y, z }) {
                              let tooltipHtml = `<table>
                                                    <tbody>
                                                      <tr>
                                                        <td>GCR:</td>
                                                        <td>${x.toFixed(4)}</td>
                                                      </tr>
                                                      <tr>
                                                        <td>DC:AC:</td>
                                                        <td>${y.toFixed(4)}</td>
                                                      </tr>
                                                      <tr>
                                                        <td>${zLabel}:</td>
                                                        <td>${Math.abs(z).toFixed(3)}</td>
                                                      </tr>
                                                    </tbody>
                                                  </table>`;
                              return tooltipHtml.toString();
                            },
                          }}
                          code={code}
                          graph={graphMode}
                        />
                        {code > 150 && (
                          <section className="result-graph-toggle">
                            <span>3D Plot Z-Axis: </span>
                            <Button size="small" type="link" onClick={() => setGraphMode(0)} disabled={graphMode == 0} style={{ color: graphMode == 0 && "#5f5f5f" }}>
                              Yield
                            </Button>
                            or
                            <Button size="small" type="link" onClick={() => setGraphMode(1)} disabled={graphMode == 1} style={{ color: graphMode == 1 && "#5f5f5f" }}>
                              {code == 151 ? "$/MWh" : "IRR %"}
                            </Button>
                          </section>
                        )}
                      </section>
                    </>
                  )}

                  {reportData && reportComplete && hold && <DownloadPDF reportData={reportData} />}
                </section>
              </TabPane>

              {detailedResults.map((resId) => {
                let res = outputs.results[resId];
                return (
                  <TabPane
                    tab={
                      <span className="tab-res">
                        {parseFloat(res.gcr.toFixed(3))}GCR,{parseFloat(res.dcac.toFixed(4))}DC:AC
                      </span>
                    }
                    key={`detail_${res.id}`}
                    closable={true}
                  >
                    <section className="output-detail">
                      <ResultDetail
                        result={res}
                        meta={outputs.meta}
                        loading_layout={loading_layout}
                        loading_files={loading_files}
                        map_loading={map_loading}
                        onPlot={onPlot}
                        onCopyResults={onCopyResults}
                        onExportMap={onExportMap}
                        onDownloadFiles={onDownloadFiles}
                        onShowReport={onShowReport}
                      />
                    </section>
                  </TabPane>
                );
              })}
            </Tabs>
          </section>
        </TutorialTip>
      )}

      {/* {runState.generated && !detailsVisible && (
          <section className="result-buttons-content">
            <section className="results-buttons">
              <span style={{ paddingLeft: '14px' }}>All Results:</span>

              <section style={{ textAlign: 'center' }}>
                <span style={{ paddingRight: 5 }}>
                  <CSVLink filename={outputs.fileName} data={outputs.csvData}>
                    <Tooltip placement="top" title="All results to CSV" mouseEnterDelay={0.5}>
                      <Button ghost type="primary" size="small">
                        <FileExcelOutlined />
                        CSV
                      </Button>
                    </Tooltip>
                  </CSVLink>
                </span>

                <Tooltip placement="top" title="All results to clipboard" mouseEnterDelay={0.5}>
                  <Button ghost type="primary" onClick={onCopyResults} size="small">
                    <CopyOutlined />
                    Copy
                  </Button>
                </Tooltip>
              </section>
            </section>
            
            {!detailsVisible && selectedResult && (
              <section className="selected-buttons">
                <section>
                  <span>
                    Plotted Result: {selectedResult.gcr.toFixed(3)} GCR, {selectedResult.dcac.toFixed(3)} DC:AC
                  </span>
                </section>
                <section>
                  <span style={{ marginRight: 5 }}>
                    <Tooltip placement="top" title="Plot selected result in the map" mouseEnterDelay={0.5}>
                      <Button ghost type="primary" onClick={onPlot} loading={loading_layout} size="small">
                        {!loading_layout && <BuildOutlined />}Plot
                      </Button>
                    </Tooltip>
                  </span>
                  <span style={{ marginRight: 5 }}>
                    <Tooltip placement="top" title="Selected result and 8760 performance to CSV" mouseEnterDelay={0.5}>
                      <Button ghost type="primary" onClick={onDownloadFiles} loading={loading_files} size="small">
                        {!loading_files && <FileZipOutlined />}Report
                      </Button>
                    </Tooltip>
                  </span>
                  <span style={{ marginRight: 5 }}>
                    <Tooltip placement="top" title="Export layout to DXF" mouseEnterDelay={0.5}>
                      <Button ghost type="primary" onClick={() => onExportMap('export_dxf')} loading={map_loading} size="small">
                        {!map_loading && <AppstoreOutlined />}CAD
                      </Button>
                    </Tooltip>
                  </span>
                  <span style={{ marginRight: 5 }}>
                    <Tooltip placement="top" title="Export map to KMZ" mouseEnterDelay={0.5}>
                      <Button ghost type="primary" onClick={() => onExportMap('export_kml')} loading={map_loading} size="small">
                        {!map_loading && <FileImageOutlined />}KMZ
                      </Button>
                    </Tooltip>
                  </span>
                </section>
              </section>
            )}
          </section>
        )} */}

      {/* {!runState.generated && (
          <section className="progress">
            <Steps>
              {Object.values(runState.steps).map((step) => {
                return (
                  <Step
                    status={step.status}
                    title={step.title}
                    key={step.index}
                    icon={step.status == 'process' ? <LoadingOutlined /> : step.status == 'error' ? <FrownOutlined /> : icons[step.index]}
                  />
                );
              })}
            </Steps>
          </section>
        )} */}

      <textarea
        hidden
        ref={textArea}
        name="TextResults"
        cols="40"
        rows="50"
        value={outputs.tsvData}
        onChange={(e) => {
          console.log("copied to clipboard");
        }}
      />
    </OutputPanelWrapper>
  );
};

export { OutputPanel };
