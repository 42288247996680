import React, { useState, useEffect, useLayoutEffect, useRef, Fragment } from "react";

import { useSelector, useDispatch } from "react-redux";
import { accountActions, siftActions } from "../Redux/_actions";

import { Top, Bottom, Right, Left, TutorialTipWrap } from "./styles";

// assets
import { infoIcon, price, help } from "../../assets/images";

// Tutorial Tip takes 4 props
// 1. elementKey - is the ID of the component that the TutorialTup is wrapping. It's used to get the components dims
// 2. visible - boolean which indicates if the tip is shown or hidden.
// 3. content  - string that contains the text content of the tooltip.
// 4. placement - string ('left', 'right', 'top', 'bottom') which indicates where the tip will be located in realation to what it's wrapping. Default is bottom.
// 5. hightligh - boolean which indicates if the element that TutorialTip is wrapping is outlined or not. Default is false.

const TutorialTip = ({
  elementKey,
  visible,
  content,
  placement,
  highlight,
  children,
  SecondaryBorderColor,
  showPricingLink = false,
  showHelpLink = false,
  offetArrowLeft = undefined,
  customTipHeight = undefined,
  customTipWidth = undefined,
  customElementHeight = undefined,
  customElementWidth = undefined,
}) => {
  //tip ref
  const tip = useRef();

  const screenWidth = useSelector((state) => state.sift.ioManager.uiState.screenWidth);
  const tutIndex = useSelector((state) => state.tutorial.currentIndex);
  const tutPath = useSelector((state) => state.tutorial.tutorialPath);

  const dispatch = useDispatch();
  //prettier-ignore
  const [elementDims, setElementDims] = useState({});
  const [tipDims, setTipDims] = useState({});

  useEffect(() => {
    getElementDims();
  }, [screenWidth]);

  useEffect(() => {
    if ((tutPath == 1 && tutIndex == 1) || (tutPath == 0 && tutIndex == 15)) {
      getElementDims();
      getTipDims();
    }
  }, [tutIndex, tutPath]);

  useLayoutEffect(() => {
    if (!visible) return;
    getElementDims();
    getTipDims();
  }, []);

  useEffect(() => {
    if ((tipDims.x == 0 || tipDims.x == undefined) && (tipDims.y == 0 || tipDims.y == undefined)) {
      getTipDims();
    }
  }, [tipDims]);

  const getTipDims = () => {
    let tipDimensions = tip.current;
    if (tipDimensions) {
      setTipDims(tipDimensions.getBoundingClientRect());
    }
  };

  useEffect(() => {
    if ((elementDims.x == 0 || elementDims.x == undefined) && (elementDims.y == 0 || elementDims.y == undefined)) {
      getElementDims();
    }
  }, [elementDims]);

  const getElementDims = () => {
    const dims = document.getElementById(`${elementKey}`)?.getBoundingClientRect();
    if (dims) {
      setElementDims(dims);
    }
  };

  return (
    <TutorialTipWrap
      placement={placement}
      customTipHeight={customTipHeight}
      customTipWidth={customTipWidth}
      offetArrowLeft={offetArrowLeft}
      elementDims={elementDims}
      customElementHeight={customElementHeight}
      customElementWidth={customElementWidth}
      tipDims={tipDims}
      visible={visible}
      highlight={highlight || false}
      content={content ? 1 : 0}
      SecondaryBorderColor={SecondaryBorderColor}
    >
      {children}
      {visible && (
        <div ref={tip} className="tutorial-tip">
          {placement == undefined && <Bottom offetArrowLeft={offetArrowLeft} />}
          {placement == "top" && <Bottom offetArrowLeft={offetArrowLeft} />}
          {placement == "bottom" && <Top offetArrowLeft={offetArrowLeft} />}
          {placement == "right" && <Left offetArrowLeft={offetArrowLeft} />}
          {placement == "left" && <Right offetArrowLeft={offetArrowLeft} />}
          <div className="tip-content">
            <div className="tip-text">
              <img src={infoIcon} />
              <p>{content}</p>
            </div>
            {showPricingLink && (
              <div className="pricing-link">
                <img src={price} alt="price tag" />
                <a onClick={() => dispatch(accountActions.toggleFunnelVis(true))}>See SIFT Pricing for more details</a>
              </div>
            )}
            {showHelpLink && (
              <div className="pricing-link">
                <p>
                  See
                  <a onClick={() => dispatch(siftActions.toggleHelpToolbarVisibility("helper_visible", true))}>
                    <img src={help} />
                  </a>
                  for full documentation.
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </TutorialTipWrap>
  );
};

export { TutorialTip };
