import styled from 'styled-components';

const InfoBoxWrap = styled.section`
  grid-area: info-boxes;
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    'info-box-title'
    'info-box-container ';

  .info-box-title {
    grid-area: info-box-title;

    h3 {
      margin: 0px 5px 5px;
    }
  }

  .info-box-container {
    grid-area: info-box-container;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);

    .info_box {
      height: 100px;
      padding: 5px;
      width: 125px;
      border-radius: 2px;
      display: flex;
      margin: 0px 5px;
      flex-direction: column;
      justify-content: start;
      border: 1px solid #d9d9d9;

      svg {
        height: 20px;
      }

      p {
        margin: 0px;
        opacity: 0.8;
        font-size: 12px;
      }

      p:nth-child(3) {
        opacity: 1;
        font-weight: 600;
      }
    }
  }
`;

export { InfoBoxWrap };
