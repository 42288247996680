import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import requestMiddleware from './requestMiddleware';
import rootReducer from '../_reducers';

import config from 'config';

const loggerMiddleware = createLogger();
const initialState = {};
const middleware = [thunkMiddleware, requestMiddleware];
// const middleware = [thunkMiddleware];

// export const store = process.env.NODE_ENV !== 'production' ?

//     // development mode
//     createStore(
//         rootReducer,
//         applyMiddleware(
//             thunkMiddleware,
//             requestMiddleware,
//             // loggerMiddleware
//         ),
//         window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//     ) :
//     // production mode
//     createStore(
//         rootReducer,
//         applyMiddleware(
//             thunkMiddleware,
//             requestMiddleware
//         )
//     )

// console.log(process.env.NODE_ENV, config.env)
export const store =
  config.env === 'prod' || config.env === 'test'
    ? // production
      createStore(
        rootReducer,
        initialState,
        compose(
          applyMiddleware(...middleware)
          // Redux Devtools setup
          // Chrome Extension available here --> https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
        )
      )
    : // development
      createStore(
        rootReducer,
        initialState,
        compose(
          applyMiddleware(...middleware),
          // Redux Devtools setup
          // Chrome Extension available here --> https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
          window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      );
