import * as turf from "@turf/turf";
import { getBounds } from "./";

// used for config tab
export function calculateSPI(config, module_rating, nameplate, modules_per_string) {
  // console.log()
  // console.log(module_rating, nameplate, modules_per_string )
  if (!isNaN(module_rating) && !isNaN(nameplate)) {
    if (module_rating > 0 && nameplate > 0) {
      // calculate the min/max SPI for the range/slider
      // NS: Changed how min/max SPI are calculated to be more clear
      //     min DCAC is ~0.95 and max is ~3.01 (the module rating and modules per string dictate the ~ )
      let minSPI = parseInt(Math.floor((nameplate * 0.95) / ((modules_per_string * module_rating) / 1000)));
      let maxSPI = parseInt(Math.ceil((nameplate * 3.01) / ((modules_per_string * module_rating) / 1000)));

      // This piece is only called:
      //  1- when a new module or inverter is selected
      //  2- when modules per string are updated
      // Weve got a GCR range and we have a spi range
      // We need to force this to set below the users threshold of workers
      // basic gcr range = set already
      // basic dcac range = 1.1 - 1.5
      // check worker count with those in mind, based on that value we know which way to go
      // calculate worker totals

      // Make sure we have an SPI Step of 1
      config.spi_step = config.spi_step > 0 ? config.spi_step : 1;
      // var spi_count = minSPI > 0 ? Math.round((maxSPI+config.spi_step - minSPI) / config.spi_step) : 1;]
      // console.log(config.spi_range, [minSPI, maxSPI])
      var targetMinSPI = config.spi_range[0] >= minSPI && config.spi_range[0] <= maxSPI ? config.spi_range[0] : parseInt((1.1 * nameplate * 1000) / (modules_per_string * module_rating));
      var targetMaxSPI = config.spi_range[1] >= minSPI && config.spi_range[1] <= maxSPI ? config.spi_range[1] : parseInt((1.5 * nameplate * 1000) / (modules_per_string * module_rating));
      config.spi_count = targetMinSPI > 0 ? Math.round((targetMaxSPI + config.spi_step - targetMinSPI) / config.spi_step) : 1;
      config.worker_count = (config.spi_count > 0 ? config.spi_count : 1) * config.gcr_count;
      // console.log(spi_count, config.gcr_count)
      // console.log(targetMinSPI, targetMaxSPI)
      // if this worker count is above the threshold, we need to increase the step until it fits below
      if (config.worker_count > 500) {
        let looper = true;
        while (looper) {
          config.spi_step += 1;
          config.spi_count = minSPI > 0 ? Math.round((maxSPI + config.spi_step - minSPI) / config.spi_step) : 1;
          targetMinSPI = parseInt((1.1 * nameplate * 1000) / (modules_per_string * module_rating));
          targetMaxSPI = parseInt((1.5 * nameplate * 1000) / (modules_per_string * module_rating));
          let counts = calculateCounts(targetMinSPI, targetMaxSPI, config.spi_step, config.gcr_count);
          config.worker_count = counts[0];
          config.spi_count = counts[1];
          // console.log(worker_count,targetMinSPI,targetMaxSPI)
          if (config.worker_count < 500) {
            looper = false;
          }
        }
      }
      // console.log(config.spi_range, targetMinSPI, targetMaxSPI)
      config.spi_range = [targetMinSPI, targetMaxSPI];
      // if (config.spi_range[0] > 0) {
      //   targetMinSPI = config.spi_range[0];
      // }
      // if (config.spi_range[1] > 0) {
      //   targetMaxSPI = config.spi_range[1];
      // }

      // this is what sets the min/max for spi slider
      config.spi_min_max = [minSPI, maxSPI];
      config.spi_range[0] = config.spi_range[0] == 0 ? targetMinSPI : Math.max(targetMinSPI, Math.min(config.spi_range[0], targetMaxSPI));
      config.spi_range[1] = config.spi_range[1] == 0 ? targetMaxSPI : Math.max(targetMinSPI, Math.min(config.spi_range[1], targetMaxSPI));
      let dcac_min = (config.spi_range[0] * modules_per_string * module_rating) / 1000 / nameplate;
      let dcac_max = (config.spi_range[1] * modules_per_string * module_rating) / 1000 / nameplate;
      config.dcac_range = [dcac_min.toFixed(4), dcac_max.toFixed(4)];
    } else {
      config.spi_min_max = [0, 0];
      // this.setState({minSPI: 0,maxSPI: 0})
    }
  }
}

export function checkCoordSystemBounds(features, coordBoundary) {
  let bounds = getBounds(Object.values(features));
  let featureBounds = turf.bboxPolygon(bounds);
  let contains = turf.booleanContains(coordBoundary, featureBounds);
  let overlap = turf.booleanOverlap(coordBoundary, featureBounds);

  if (contains || overlap) {
    return true;
  } else {
    return false;
  }
}

export function checkSiteLocation(features, location) {
  let bounds = getBounds(Object.values(features));
  let featureBounds = turf.flip(turf.bboxPolygon(bounds));
  let boundary_center = turf.getCoords(turf.centroid(featureBounds));
  if (Math.abs(boundary_center[0] - location[0]) > 5 || Math.abs(boundary_center[1] - location[1]) > 5) {
    return false;
  } else {
    return true;
  }
}

// used for config tab
// [0.2,0.9]
// [7.15, 13.05]
export function calculatePitchOrGcr(dimension, range) {
  let new_range = [0, 0];
  new_range[1] = parseFloat((dimension / range[0]).toFixed(4));
  new_range[0] = parseFloat((dimension / range[1]).toFixed(4));
  return new_range;
}

// used for inverter tab
export function calculateDCMaxPwr(inv_pd_pacokw, maxEff) {
  let dc_max_pwr = 0;
  if (!isNaN(inv_pd_pacokw) && !isNaN(maxEff)) {
    if (inv_pd_pacokw > 0 && maxEff > 0) {
      dc_max_pwr = (inv_pd_pacokw / maxEff) * 100;
    }
  }
  return parseFloat(dc_max_pwr.toFixed(2));
}

// used for config tab
export function setInverterCuts(layout, nameplate) {
  // if (nameplate >= 1000) {
  //   config.inverter_per_cut = 1
  // } else if (nameplate < 1000 && nameplate >= 500) {
  //   config.inverter_per_cut = 2
  // } else if (nameplate < 500 && nameplate >= 250) {
  //   config.inverter_per_cut = 4
  // } else if (nameplate < 250) {
  //   config.inverter_per_cut = 6
  // }
  if (nameplate >= 1000) {
    layout.inverter_per_cut = 1;
  } else if (nameplate < 1000 && nameplate >= 500) {
    layout.inverter_per_cut = 2;
  } else if (nameplate < 500 && nameplate >= 250) {
    layout.inverter_per_cut = 4;
  } else if (nameplate < 250) {
    layout.inverter_per_cut = 6;
  }
}

// used for config tab
export function calculateWorkers(config, modules_per_string, module_rating, nameplate) {
  let count_ = 0;

  if (config.selected_gcr_pitch == 0) {
    // GCR is the Range selected
    config.gcr_count = Math.round((config.gcr_range[1] + config.gcr_step) * (1 / config.gcr_step) - config.gcr_range[0] * (1 / config.gcr_step));
    count_ = config.gcr_count;
  } else {
    // Pitch is the Range selected
    config.pitch_count = Math.round((config.pitch_range[1] + config.pitch_step) * (1 / config.pitch_step) - config.pitch_range[0] * (1 / config.pitch_step));
    count_ = config.pitch_count;
  }
  config.spi_count = config.spi_range[0] > 0 ? Math.round((config.spi_range[1] + config.spi_step - config.spi_range[0]) / config.spi_step) : 1;

  config.worker_count = config.spi_count * count_;

  let dcac_min = (config.spi_range[0] * modules_per_string * module_rating) / 1000 / nameplate;
  let dcac_max = (config.spi_range[1] * modules_per_string * module_rating) / 1000 / nameplate;
  config.dcac_range = [dcac_min.toFixed(4), dcac_max.toFixed(4)];
}

// used when loading a project or a presaved dropdown inputs
export function checkTabInputs(tab_key, inputs) {
  // basically holds all our checks for when we've updated the input structure or added new variables
  // that might not be in an older version of a project or dropdown
}

export function calculateCounts(minSPI, maxSPI, spi_step, gcr_count) {
  let spi_count = minSPI > 0 ? Math.round((maxSPI + spi_step - minSPI) / spi_step) : 1;
  let worker_count = (spi_count > 0 ? spi_count : 1) * gcr_count;
  return [worker_count, spi_count];
}

export function isNumber(value) {
  return typeof value === "number" && !isNaN(value);
}
export function getTilts(min, max, inc) {
  if (!isNumber(min) || !isNumber(max) || !isNumber(inc)) {
    return false;
  }
  let tilts = [];
  for (let tilt = min; tilt < max; tilt += inc) {
    tilts.push(tilt);
    if (tilts.length >= 20) break;
  }
  tilts.push(parseFloat(max));
  return tilts;
}

function Standard_GFT(mlm_Width, mlm_Length, modules_per_rack, modules_high, orientation, module_gap) {
  // #User inputs a mods per tracker, returns an X and Y. Agnostic of modules per string or 2,1,0.5 string targets. That logic should exist before this
  let Rack_Y;
  let Rack_X;

  if (orientation == 0) {
    Rack_Y = modules_high * mlm_Length + (modules_high - 1) * module_gap;
  } else {
    Rack_Y = modules_high * mlm_Width + (modules_high - 1) * module_gap;
  }

  // #Complex Python but it only rounds up. 25mod/4high = 7 wide.
  let Mods_Wide = Math.floor(modules_per_rack / modules_high) + (modules_per_rack % modules_high);

  if (orientation == 0) {
    Rack_X = Mods_Wide * mlm_Width + (Mods_Wide - 1) * module_gap;
  } else {
    Rack_X = Mods_Wide * mlm_Length + (Mods_Wide - 1) * module_gap;
  }

  return [Rack_X, Rack_Y];
}

function Standard_SAT(mlm_Width, mlm_Length, modules_per_rack, modules_high, orientation, module_gap, drive_gap) {
  // #User inputs a mods per tracker, returns an X and Y. Agnostic of modules per string or 2,1,0.5 string targets. That logic should exist before this
  let Rack_Y;
  let Rack_X;

  if (orientation == 0) {
    // portrait
    Rack_X = modules_high * mlm_Length + (modules_high - 1) * module_gap;
  } else {
    Rack_X = modules_high * mlm_Width + (modules_high - 1) * module_gap;
  }

  // #Complex Python but it only rounds up. 25mod/4high = 7 wide.
  let Mods_Wide = Math.floor(modules_per_rack / modules_high) + (modules_per_rack % modules_high);

  if (orientation == 0) {
    // portrait
    Rack_Y = drive_gap + Mods_Wide * mlm_Width + (Mods_Wide - 1) * module_gap;
  } else {
    Rack_Y = drive_gap + Mods_Wide * mlm_Length + (Mods_Wide - 1) * module_gap;
  }

  return [Rack_X, Rack_Y];
}

function Standard_EWF(mlm_Width, mlm_Length, modules_per_rack, modules_high, orientation, module_gap) {
  // #User inputs a mods per tracker, returns an X and Y. Agnostic of modules per string or 2,1,0.5 string targets. That logic should exist before this
  let Rack_Y;
  let Rack_X;

  if (orientation == 0) {
    // portrait
    Rack_X = modules_high * mlm_Length + (modules_high - 1) * module_gap;
  } else {
    Rack_X = modules_high * mlm_Width + (modules_high - 1) * module_gap;
  }

  // #Complex Python but it only rounds up. 25mod/4high = 7 wide.
  let Mods_Wide = Math.floor(modules_per_rack / modules_high) + (modules_per_rack % modules_high);

  if (orientation == 0) {
    // portrait
    Rack_Y = Mods_Wide * mlm_Width + (Mods_Wide - 1) * module_gap;
  } else {
    Rack_Y = Mods_Wide * mlm_Length + (Mods_Wide - 1) * module_gap;
  }

  return [Rack_X, Rack_Y];
}

export function calculate_racking_dims(inputs) {
  inputs.racks.forEach((rack) => {
    let rack_xy = [];
    if (rack.string_count > 0) {
      let string_check = parseFloat(rack.string_count) === 0.5;
      let str_cnt = string_check ? parseFloat(rack.string_count) : parseInt(rack.string_count);
      rack.module = inputs.modules_per_string * str_cnt;
    } else {
      rack.string_count = rack.module / inputs.modules_per_string;
    }

    if (inputs.type == 0) {
      // GFT
      rack_xy = Standard_GFT(inputs.mlm_Width, inputs.mlm_Length, rack.module, inputs.modules_high, inputs.orientation, inputs.module_gap);
    } else if (inputs.type == 1) {
      // SAT
      rack_xy = Standard_SAT(inputs.mlm_Width, inputs.mlm_Length, rack.module, inputs.modules_high, inputs.orientation, inputs.module_gap, inputs.drive_gap);
    } else {
      // EWF
      rack_xy = Standard_EWF(inputs.mlm_Width, inputs.mlm_Length, rack.module, inputs.modules_high, inputs.orientation, inputs.module_gap);
    }

    rack.xdim = rack_xy[0];
    rack.ydim = rack_xy[1];

    if (isNumber(rack.xdim)) {
      rack.xdim = parseFloat(rack.xdim.toFixed(3));
    }
    if (isNumber(rack.ydim)) {
      rack.ydim = parseFloat(rack.ydim.toFixed(3));
    }
  });
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function checkForRackingErrors(inputs) {
  let { type, modules_per_string, modules_high, orientation, racks, module_gap, drive_gap, mlm_Width, mlm_Length } = inputs;
  const moduleArea = parseFloat(mlm_Length * mlm_Width).toFixed(3);
  const rackingNames = ["Rack A", "Rack B", "Rack C"];
  let error_one;
  let error_two;
  let error_three;
  const modAreaErrorOne = [];
  const modAreaErrorTwo = [];
  const inputError = [];

  racks.map((rack, index) => {
    if (rack.active == 1) {
      let rack_area = rack.xdim * rack.ydim;
      let module_area = rack.module * moduleArea;

      if (module_area > rack_area) {
        modAreaErrorOne.push(rackingNames[index]);
      }

      if (module_area < 0.8 * rack_area) {
        modAreaErrorTwo.push(rackingNames[index]);
      }

      if (!isNumeric(rack.xdim) || !isNumeric(rack.ydim) || !isNumeric(rack.module)) {
        inputError.push(rackingNames[index]);
      }
    }
  });

  if (modAreaErrorOne.length > 0) {
    error_one = `Warning: Rack Modules Area (Module Area * Mod Count) of ${modAreaErrorOne.join(
      ", "
    )} is greater than your Racking Area  (X * Y). Rack dimensions are not large enough for module count. Reduce module count or check rack dimensions.`;
  }

  if (modAreaErrorTwo.length > 0) {
    error_two = `Warning: Rack Modules Area (Module Area * Mod Count) of ${modAreaErrorTwo.join(
      ", "
    )} accounts for less than 80% of your Racking Area (X * Y). Check the racking dimensions and the module counts.`;
  }

  if (inputError.length > 0 || !Number.isInteger(modules_per_string)) {
    error_three = `Warning: Strings per rack of ${inputError.join(", ")} (Mod Count / Modules per String) is non integer. Check Mod Count per rack and Modules Per String in Performance.`;
  }

  let errors = {
    error_one,
    error_two,
    error_three,
  };

  let error_obj;

  if (errors.error_one || errors.error_two || errors.error_three) {
    error_obj = {
      contains_errors: true,
      errors,
    };
    return error_obj;
  } else {
    error_obj = {
      contains_errors: false,
      errors,
    };
    return error_obj;
  }
}

export function calculate_finance(action, racks, module_rating, inverter_rating) {
  // console.log("calculate_finance - inputs", action);

  let rack_a_count = racks[0].active == 1 ? racks[0].module : 0;
  let rack_b_count = racks[1].active == 1 ? racks[1].module : 0;
  let rack_c_count = racks[2].active == 1 ? racks[2].module : 0;

  let tempAction = action;

  if (action.key == "module_dc_cost") {
		// $/Wp is selected
    if (action.value.type == 0) {
      // if you're going from type 1 -> type 0
      if (action.value.prev_type == 1) {
        // switching from $ -> $/Wp
        tempAction.value.value = _.round(action.value.value / module_rating, 4);
      } else {
				// no switching, just normal input update
        tempAction.value.value = action.value.value;
      }

			tempAction.value.total = tempAction.value.value
      return tempAction;

    } 
		// $ is selected
		else if (action.value.type == 1) {
      if (action.value.prev_type == 0) {
        // switching from $/Wp -> $
        tempAction.value.value = _.round(action.value.value * module_rating, 4);
      } else {
				// no switching, just normal input update
        tempAction.value.value = action.value.value;
      }

			tempAction.value.total = _.round(tempAction.value.value / module_rating, 4);
      return tempAction;
    }
  }

  if (action.key == "rack_a_finance") {
		// $/Wp is selected
    if (action.value.type == 0) {
      if (action.value.prev_type == 1) {
        // $/mod -> $/Wp
        tempAction.value.value = _.round(action.value.value / module_rating, 4);
      } else if (action.value.prev_type == 2) {
        // $ -> $W/p
        tempAction.value.value = _.round(action.value.value / rack_a_count / module_rating, 4);
      } else {
        // no switching
        tempAction.value.value = action.value.value;
      }
			// value is already in $/Wp so just set total equal to value
			tempAction.value.total = tempAction.value.value
      return tempAction;
    } 
		// $/mod is selected
		else if (action.value.type == 1) {
      // $/mod
      if (action.value.prev_type == 0) {
        // $/Wp -> $/mod
        tempAction.value.value = _.round(action.value.value * module_rating, 4);
      } else if (action.value.prev_type == 2) {
        // $ -> $/mod
        tempAction.value.value = _.round(action.value.value / rack_a_count, 4);
      } else {
        // no switching
        tempAction.value.value = action.value.value;
      }
			// value is in $/mod so convert once
			tempAction.value.total =  _.round(tempAction.value.value / module_rating, 4);
      return tempAction;
    } 
		// $ is selected
		else if (action.value.type == 2) {
      // $
      if (action.value.prev_type == 0) {
        // $/Wp -> $
        tempAction.value.value = _.round(action.value.value * module_rating * rack_a_count, 4);
      } else if (action.value.prev_type == 1) {
        // $/mod -> $
        tempAction.value.value = _.round(action.value.value * rack_a_count, 4);
      } else {
        // no switching
        tempAction.value.value = action.value.value;
      }
			// value is in $ so convert once
			tempAction.value.total =  _.round(tempAction.value.value / rack_a_count / module_rating, 4);
      return tempAction;
    }
  }

  if (action.key == "rack_b_finance") {
    if (action.value.type == 0) {
      if (action.value.prev_type == 1) {
        // $/mod -> $/Wp
        tempAction.value.value = _.round(action.value.value / module_rating, 4);
      } else if (action.value.prev_type == 2) {
        // $ -> $W/p
        tempAction.value.value = _.round(action.value.value / rack_b_count / module_rating, 4);
      } else {
        // no switching
        tempAction.value.value = action.value.value;
      }
			// value is already in $/Wp so just set total equal to value
			tempAction.value.total = tempAction.value.value			
      return tempAction;
    } else if (action.value.type == 1) {
      //mod
      if (action.value.prev_type == 0) {
        // $/Wp -> $/mod
        tempAction.value.value = _.round(action.value.value * module_rating, 4);
      } else if (action.value.prev_type == 2) {
        // $ -> $/mod
        tempAction.value.value = _.round(action.value.value / rack_b_count, 4);
      } else {
        // no switching
        tempAction.value.value = action.value.value;
      }
			// value is in $/mod so convert once to $/Wp
			tempAction.value.total =  _.round(tempAction.value.value / module_rating, 4);
      return tempAction;
    } else if (action.value.type == 2) {
      // $
      if (action.value.prev_type == 0) {
        // $/Wp -> $
        tempAction.value.value = _.round(action.value.value * module_rating * rack_b_count, 4);
      } else if (action.value.prev_type == 1) {
        // $/mod -> $
        tempAction.value.value = _.round(action.value.value * rack_b_count, 4);
      } else {
        // no switching
        tempAction.value.value = action.value.value;
      }
			// value is in $ so convert once
			tempAction.value.total =  _.round(tempAction.value.value / rack_b_count / module_rating, 4);			
      return tempAction;
    }
  }

  if (action.key == "rack_c_finance") {
    if (action.value.type == 0) {
      if (action.value.prev_type == 1) {
        // $/mod -> $/Wp
        tempAction.value.value = _.round(action.value.value / module_rating, 4);
      } else if (action.value.prev_type == 2) {
        // $ -> $W/p
        tempAction.value.value = _.round(action.value.value / rack_c_count / module_rating, 4);
      } else {
        // no switching
        tempAction.value.value = action.value.value;
      }
			// value is already in $/Wp so just set total equal to value
			tempAction.value.total = tempAction.value.value			
      return tempAction;
    } else if (action.value.type == 1) {
      //mod
      if (action.value.prev_type == 0) {
        // $/Wp -> $/mod
        tempAction.value.value = _.round(action.value.value * module_rating, 4);
      } else if (action.value.prev_type == 2) {
        // $ -> $/mod
        tempAction.value.value = _.round(action.value.value / rack_c_count, 4);
      } else {
        // no switching
        tempAction.value.value = action.value.value;
      }
			// value is in $/mod so convert once to $/Wp
			tempAction.value.total =  _.round(tempAction.value.value / module_rating, 4);			
      return tempAction;
    } else if (action.value.type == 2) {
      // $
      if (action.value.prev_type == 0) {
        // $/Wp -> $
        tempAction.value.value = _.round(action.value.value * module_rating * rack_c_count, 4);
      } else if (action.value.prev_type == 1) {
        // $/mod -> $
        tempAction.value.value = _.round(action.value.value * rack_c_count, 4);
      } else {
        // no switching
        tempAction.value.value = action.value.value;
      }
			tempAction.value.total =  _.round(tempAction.value.value / rack_c_count / module_rating, 4);			
      return tempAction;
    }
  }

  if (action.key == "bos_other") {
		// only option is $/Wp
    tempAction.value.value = action.value.value;
    tempAction.value.total = action.value.value;
    return tempAction;
  }

	// inverter_rating is in kilo-watts in front-end -- must convert to Watts (*1000)
  if (action.key == "inverter") {
		// $/Wac selected
    if (action.value.type == 0) {
      if (action.value.prev_type == 1) {
				// switching from $ -> $/Wac
        tempAction.value.value = _.round(action.value.value / (inverter_rating*1000), 4);
      } else {
				tempAction.value.value = action.value.value;
			}
			// value already converted to $/Wac
			tempAction.value.total = tempAction.value.value
    } 
		// $ selected
		else if (action.value.type == 1) {
      if (action.value.prev_type == 0) {
				// switching from $/Wac -> $
        tempAction.value.value = _.round(action.value.value * (inverter_rating*1000), 4);
      } else {
				tempAction.value.value = action.value.value;
			}
			// in $ -- convert to $/Wac
			tempAction.value.total = _.round(tempAction.value.value / (inverter_rating*1000), 4);
    }		
    return tempAction;
  }

  if (action.key == "ac_aux") {
    if (action.value.type == 0) {
      if (action.value.prev_type == 1) {
				// switching from $/inv -> $/Wac
        tempAction.value.value = _.round(action.value.value / (inverter_rating*1000), 4);
      } else {
				tempAction.value.value = action.value.value;
			}
			// value already converted to $/Wac
			tempAction.value.total = tempAction.value.value
    } else if (action.value.type == 1) {
      if (action.value.prev_type == 0) {
				// switching from $/Wac -> $/inv
        tempAction.value.value = _.round(action.value.value * (inverter_rating*1000), 4);
      } else {
				tempAction.value.value = action.value.value;
			}
			// in $ -- convert to $/Wac
			tempAction.value.total = _.round(tempAction.value.value / (inverter_rating*1000), 4);			
    }
		return tempAction;
  }

  if (action.key == "mv_wire" || action.key == "other_ac") {
    // only option is $/Wac
    tempAction.value.value = action.value.value;
    tempAction.value.total = action.value.value;
    return tempAction;
  }

  if (action.key == "interconnection" || action.key == "permits_fees" || action.key == "engineering" || action.key == "margin" || action.key == "other_fixed") {
    return tempAction;
  }

  if (action.key == "rack_footprint" || action.key == "boundary_area") {
    if (action.value.type == 0) {
      // ha
			if (action.value.prev_type == 1) {
				// switching from acre -> ha
				tempAction.value.value = _.round(action.value.value / 2.47105, 4);
			} else {
				tempAction.value.value = action.value.value;
			}

      return tempAction;

    } else if (action.value.type == 1) {
      // acre
			if (action.value.prev_type == 0) {
				// switching from ha -> acre
				tempAction.value.value = _.round(action.value.value * 2.47105, 4);
			} else {
				tempAction.value.value = action.value.value;
			}
      return tempAction;
    }
  }

  if (action.key == "contingency") {
    // newValue = 1 * (1 + action.value.value);
    // tempAction.value.total = newValue;
    return tempAction;
  }

  if (action.key == "spacing_gcr" || action.key == "spacing_per_wp") {
    // *** This calculation happens in the FinanceInputs.js component in the "handleChanges" function
    return tempAction;
  }

  return tempAction;
}

{
  /*  DEPRECATED FUNCTIONS
export function handleStepChange(type, value) {
  var config = this.state.selectedConfig;  
  if (isNaN(value) || value <= 0.0) 
    value = 0.01;
  this.calculateWorkers(config.gcr_range, parseFloat(value), config.spi_range, config.spi_step)
}
export function handleSPIStepChange(value) {
  var config = this.state.selectedConfig;  
  if (isNaN(value) || value <= 0.0) 
    value = 1;
  this.calculateWorkers(config.gcr_range, config.gcr_step, config.spi_range, parseFloat(value))
}
export function handleInputChange(e) {
  
  var config = this.state.selectedConfig;  
  config[e.target.id] = e.target.value; 
  this.setState({selectedConfig: config});
  this.props.OnChangeConfigData(config)
}
export function handleSwitchChange(b,e) {
  var config = this.state.selectedConfig;  
  // fix rack align
  if (e.target.id == 'doShifting') {
    config[e.target.id] = b?0:1; 
  } else {
    config[e.target.id] = b?1:0; 
  }

  if (this.props.rack_type == 0) {
    // GFT
    if (e.target.id == 'inv_spacing' && config[e.target.id] == 1) {
      // ^ and inverters being turned ON
      config['doRoadSpacing'] = 1;
    } 
    if (e.target.id == 'doRoadSpacing' && config['inv_spacing'] == 1) {
      config['inv_spacing'] = 0;
    }
  } else {
    // SAT
    if (e.target.id == 'doShifting' && config[e.target.id] == 1) {
      // ^ and rack_align being turned OFF
      config['inv_spacing'] = 0;
      config['doRoadSpacing'] = 0;
    }
    if (e.target.id == 'doRoadSpacing') {
      // ^ and road spacing
      if (config[e.target.id] == 1) {
        // ^ ON
        config['doShifting'] = 0;
      } else {
        // ^ OFF
        config['inv_spacing'] = 0;
      }     
    }       
    if (e.target.id == 'inv_spacing' && config[e.target.id] == 1) {
      // ^ and inverters ON
      config['doShifting'] = 0;
      config['doRoadSpacing'] = 1;        
    }
  }    

  this.setState({selectedConfig: config});
  this.props.OnChangeConfigData(config)    
}
export function onAfterGCR(value) {
  var config = this.state.selectedConfig;

  // this value is either gcr_range or pitch_range depending on the current option selected

  config.gcr_range = value;	
  config.gcr_count = Math.round((value[1]+config.gcr_step)*(1/config.gcr_step) - value[0]*(1/config.gcr_step));
  // console.log(config.gcr_count)
  config.worker_count = (config.spi_count > 0 ? config.spi_count : 1) * config.gcr_count;

  this.setState({selectedConfig: config, gcr_range: value});
  this.props.OnChangeConfigData(config)
}    
export function onAfterSPI(value, loading=false) {
  var config = this.state.selectedConfig;

  if (!loading || 
      config.spi_range[0] + config.spi_range[1] === 0 ||
      config.spi_range[0] < this.state.minSPI || config.spi_range[1] > this.state.maxSPI ) {
    config.spi_range = value;
  }
  
  
  let dcac_min = (config.spi_range[0] * this.state.modules_per_string * this.state.module_rating/1000) / (this.state.nameplate);
  let dcac_max = (config.spi_range[1] * this.state.modules_per_string * this.state.module_rating/1000) / (this.state.nameplate);
  config.dcac_range = [dcac_min.toFixed(4),dcac_max.toFixed(4)]
  
  // console.log(config, value, this.state.modules_per_string, this.state.module_rating,  this.state.nameplate)
  // calculate worker totals
  config.spi_count = this.state.minSPI > 0 ? Math.round((config.spi_range[1]+config.spi_step - config.spi_range[0]) / config.spi_step) : 1;
  config.worker_count = (config.spi_count > 0 ? config.spi_count : 1) * config.gcr_count;

  // 0-250kw = 6
  // 250-500 = 4
  // 500-1000 = 2
  // 1000+ = 1    
  // if (this.state.nameplate >= 1000) {      
  //   config.inverter_per_cut = Math.max(config.inverter_per_cut, 1)
  // } else if (this.state.nameplate < 1000 && this.state.nameplate >= 500) {  
  //   config.inverter_per_cut = Math.max(config.inverter_per_cut, 2)
  // } else if (this.state.nameplate < 500 && this.state.nameplate >= 250) {  
  //   config.inverter_per_cut = Math.max(config.inverter_per_cut, 4)
  // } else if (this.state.nameplate < 250) {  
  //   config.inverter_per_cut = Math.max(config.inverter_per_cut, 6)
  // }

  this.setState({selectedConfig: config});
  this.props.OnChangeConfigData(config)
}
export function onChangeGCR(value) {
  console.log(value)
  this.setState({gcr_range: value});
}
export function onChangeSPI(value) {
  var config = this.state.selectedConfig;
  config.spi_range = value;
  this.setState({selectedConfig: config});
}
*/
}
