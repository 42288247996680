import React, { useState, useEffect, Fragment } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { isAuthenticated, siftActions, tutorialActions } from '../../components/Redux';

import { Popconfirm } from 'antd';

// assets
import { close, books } from '../../assets/images';

// styles
import { TutorialWrap } from './styles';

const TutorialNew = () => {
  const exampleRunning = useSelector((state) => state.sift.ioManager.outputs.runState.running);
  const outputs = useSelector((state) => state.sift.ioManager.outputs);
  //Tutorial Controls
  const tutorial = useSelector((state) => state.tutorial);

  const [isComplete, setIsComplete] = useState(false);

  // used for the tutorial. Just checks to see if the example project is complete
  useEffect(() => {
    if (Object.values(outputs.results).length > 0) {
      dispatch(tutorialActions.startExampleTut());
    }
  }, [outputs.results]);

  const dispatch = useDispatch();

  const dontShow = () => {
    localStorage.setItem('hideTutorial', 'true');
    dispatch(tutorialActions.endTutorial());
  };

  const closeTut = () => {
    dispatch(tutorialActions.endTutorial());
    // dispatch(siftActions.toggleHelpToolbarVisibility('tutorial_visible', false));
  };

  const startTutorial = (tutorialPath) => {
    if (tutorialPath == 'tour') {
      dispatch(tutorialActions.startTour());
    } else {
      dispatch(siftActions.runTutorial());
      setTimeout(() => {
        dispatch(tutorialActions.runExample());
      }, 800);
    }
  };

  return (
    <TutorialWrap>
      {tutorial.tutorialPath == 1 || exampleRunning ? (
        <Fragment>
          {!exampleRunning && (
            <div className="close-button">
              <a onClick={closeTut}>
                <img src={close} />
              </a>
            </div>
          )}
          <div className="tutorial-start">
            <div className="icon">
              <img src={books} />
            </div>
            <div className="tutorial-text">
              <h3>Example Project is running...</h3>
              <p>The cloud servers are working hard to create up to 500 unique layout, performance, and financial models!</p>
              <p>While the project is running you can monitor the progress to the left. The tour will continue when the run completes.</p>
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="close-button">
            <a onClick={closeTut}>
              <img src={close} />
            </a>
          </div>
          <div className="tutorial-start">
            <div className="icon">
              <img src={books} />
            </div>
            <div className="tutorial-text">
              <h3>Welcome to the SIFT Tutorial!</h3>
              <p>With SIFT you will be able to explore hundreds of project options, pinpoint better project designs, and save your team days of effort and churn.</p>
            </div>
          </div>
          <div className="tutorial-start-buttons">
            <button onClick={() => startTutorial('tour')}>Take a Tour</button>
            <button onClick={() => startTutorial('example')}>Run an Example Project</button>
          </div>
          <div className="dont-show">
            <Popconfirm title="The tutorial is in the 'Help' menu, but it will not show when you log in." onConfirm={dontShow} okText="Ok" cancelText="Cancel" placement="bottomRight">
              <a>Don't show again</a>
            </Popconfirm>
          </div>
        </Fragment>
      )}
    </TutorialWrap>
  );
};

export { TutorialNew };
