import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

// styles
import "./contactForm.css";
import "./buttons.css";

//antd
import { Button, message } from "antd";
import { sendContact } from "./contact.run";
import { SIFT_Button } from "./Button";

const ContactForm = () => {
  const [email, setEmail] = useState("");
  const [contactMessage, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  const reCaptchaRef = useRef();
  const publicKey = "6Lez5SQaAAAAAHvRyMWK4rIwaBTRc75SwPhIJyKk";

  const handleSubmit = async (e) => {
    e.preventDefault();

    // get a reCaptcha token to send to backend
    // the execute and executeAsync calls only work for invisible component
    const token = await reCaptchaRef.current.getValue();
    // console.log('reCaptcha token', token)
    // this resets the the recaptcha in case there was some error with submitting the form, so that recaptcha can work a subsequent time.

    if (email && contactMessage) {
      setSending(true);

      let resp = sendContact({ email, contactMessage, token });

      setTimeout(() => {
        message.success("Message sent.", 2);
        reCaptchaRef.current.reset();
        setEmail("");
        setMessage("");
        setSending(false);
      }, 3000);
    }
  };

  return (
    <aside className="contact-form">
      <form onSubmit={handleSubmit}>
        <div className="email-area">
          <label htmlFor="email">Your Email</label>
          <input aria-label="your name" name="email" value={email} type="text" onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className="message-area">
          <label htmlFor="message">Your Message</label>
          <textarea aria-label="your message" type="text" value={contactMessage} name="message" onChange={(e) => setMessage(e.target.value)} required />
        </div>
        <div className="captcha">
          <ReCAPTCHA size="normal" sitekey={publicKey} ref={reCaptchaRef} style={{ transform: "scale(1.07)", transformOrigin: "0 0" }} />
        </div>
        <div className="button-area">
          <SIFT_Button loading={sending} className="home-button" htmlType="submit" style={{ float: "right", marginRight: "0px" }}>
            Send Message ►
          </SIFT_Button>
        </div>
      </form>
    </aside>
  );
};

export default ContactForm;
