/*
  Purpose of this file is to make sure the inputs or in the 
  correct format.
*/
import { create_UUID } from "./helper";
import { ModulesDD, InvertersDD, RackingDD, PerformanceDD, WeatherDD, FinanceDD, LayoutDD, ConfigDefault, TopographyDD } from "../../InputPanel";
import { isUndefined } from "lodash-es";
const tabDefaults = {
  module: JSON.parse(JSON.stringify(ModulesDD["364aa9e4cee04535aed7806302f3f652"])),
  inverter: JSON.parse(JSON.stringify(InvertersDD["0f14d6b175444d6699dfe4d69f32c243"])),
  racking: JSON.parse(JSON.stringify(RackingDD["49aa35992cf4480e9a2f1152c43edcda"])),
  weather: JSON.parse(JSON.stringify(WeatherDD["0"])),
  performance: JSON.parse(JSON.stringify(PerformanceDD["0"])),
  finance: JSON.parse(JSON.stringify(FinanceDD["0"])),
  config: JSON.parse(JSON.stringify(ConfigDefault)),
  layout: JSON.parse(JSON.stringify(LayoutDD["0"])),
  topography: JSON.parse(JSON.stringify(TopographyDD["0"])),
};

export function fixInputs(inputs) {
  // we need to make sure this isn't an old format
  // console.log(inputs)

  // map inputs format was changed
  let map = {};
  if (inputs.features) {
    // old map format
    let new_features = {};
    Object.values(inputs.features).forEach((feature) => {
      // console.log(feature)
      let new_id = create_UUID();
      feature.properties.index = new_id;
      feature.properties.identity = feature.properties.identity || 0;
      feature.properties.active = true;
      new_features[new_id] = feature;
    });

    map = {
      features: new_features,
      // map state
      isDrawing: false,
      isEditing: false,
      selectedFeatureId: undefined,
      map_center: [34.6964398, -118.2827397],
      map_reset: false,
      loading_map: false,
      show_site_marker: false,
      pull_location: false,
    };
  } else if (inputs.map == undefined) {
    map = {
      features: {},

      // map state
      isDrawing: false,
      isEditing: false,
      selectedFeatureId: undefined,

      map_center: [34.6964398, -118.2827397],
      map_reset: false,
      loading_map: false,
      show_site_marker: false,
      pull_location: false,
    };
  } else {
    // moved this here to fix old features structure from old projects
    if (Array.isArray(inputs.map.features)) {
      let new_features = {};
      Object.values(inputs.map.features).forEach((feature) => {
        let new_id = create_UUID();
        feature.properties.index = new_id;
        feature.properties.identity = feature.properties.identity || 0;
        feature.properties.active = true;
        new_features[new_id] = feature;
      });
      map = {
        ...inputs.map,
        features: new_features,
      };
    } else {
      map = inputs.map;
    }
  }
  // these didn't change at all from what I remember
  let module = inputs.module == undefined ? JSON.parse(JSON.stringify(tabDefaults.module)) : inputs.module;
  let inverter = inputs.inverter == undefined ? JSON.parse(JSON.stringify(tabDefaults.inverter)) : inputs.inverter;
  let racking = inputs.racking == undefined ? JSON.parse(JSON.stringify(tabDefaults.racking)) : fixRacking(inputs.racking);
  // { DEPRECATED -- Just use the universal fixRacking function -- will create a function for each tab eventually..
  //     data: {
  //       ...inputs.racking.data,
  //       module_clearance_height: inputs.racking.data.module_clearance_height != undefined ? inputs.racking.data.module_clearance_height : inputs.racking.data.type == 0 ? 0.5 : 1.5,
  //       module_transmission_perc: inputs.racking.data.module_transmission_perc != undefined ? inputs.racking.data.module_transmission_perc : 0,
  //       module_structure_shade_perc: inputs.racking.data.module_structure_shade_perc != undefined ? inputs.racking.data.module_structure_shade_perc : 0,
  //       grade_limit: inputs.racking.data.grade_limit != undefined ? inputs.racking.data.grade_limit : inputs.racking.data.type == 0 ? 20 : 10,
  //       tilt_inc: 1,
  // 			rack_dims: 0,
  // 			modules_high: 1,
  // 			orientation: 0,
  // 			module_gap: 0.02,
  // 			drive_gap: 2.0
  //     },
  //   };
  let performance = inputs.performance == undefined ? JSON.parse(JSON.stringify(tabDefaults.performance)) : fixPerformance(inputs.performance);
  let finance = inputs.finance == undefined ? JSON.parse(JSON.stringify(tabDefaults.finance)) : fixFinance(inputs.finance);

  // moved into weather.data
  let weather = {};
  if (inputs.weather == undefined) {
    // doesn't exist at all
    weather = JSON.parse(JSON.stringify(tabDefaults.weather));
  } else if (inputs.weather.data == undefined) {
    // old weather format
    weather["data"] = inputs.weather;
  } else {
    weather = inputs.weather;
  }

  let config = {};
  if (inputs.config == undefined) {
    config = JSON.parse(JSON.stringify(tabDefaults.config));
  } else if (inputs.config.doShifting != undefined) {
    let minSPI = parseInt(inverter.data.inverterRating / (1.35 * ((performance.data.modules_per_string * module.data.rating) / 1000)));
    let maxSPI = parseInt(inverter.data.inverterRating / (0.5 * ((performance.data.modules_per_string * module.data.rating) / 1000)));
    config = {
      data: {
        ...JSON.parse(JSON.stringify(tabDefaults.config)).data,
        ...inputs.config,
        // add/correct
        platform: "sift",
        do_finance: inputs.complexity && inputs.complexity.finance ? 1 : 0,
        do_continuous: 0,
        do_dc_lock: 0,
        dc_lock_value: 0,

        do_inverter_qty_lock: inputs.config.inverter_qty && inputs.config.inverter_qty > 0 ? inputs.config.inverter_qty_lock : 0,
        inverter_qty: inputs.config.inverter_qty,

        do_rack_align: inputs.config.doShifting && inputs.config.doShifting == 1 ? 0 : 1,
        do_roads: inputs.config.doRoadSpacing && inputs.config.doRoadSpacing == 1 ? 1 : 0,

        do_inv_spacing: inputs.config.inv_spacing && inputs.config.inv_spacing == 1 ? 1 : 0,

        selected_gcr_pitch: inputs.config.selected_gcr_pitch ? inputs.config.selected_gcr_pitch : 0,
        selected_slider_fixed: inputs.config.selected_slider_fixed ? inputs.config.selected_slider_fixed : 0,

        spi_min_max: [minSPI, maxSPI],

        doShifting: undefined,
        doRoadSpacing: undefined,
      },
    };
  } else if (inputs.config.data == undefined) {
    config = JSON.parse(JSON.stringify(tabDefaults.config));
  } else if (inputs.config.data.doShifting != undefined) {
    // old config format
    let minSPI = parseInt(inverter.data.inverterRating / (1.35 * ((performance.data.modules_per_string * module.data.rating) / 1000)));
    let maxSPI = parseInt(inverter.data.inverterRating / (0.5 * ((performance.data.modules_per_string * module.data.rating) / 1000)));
    config = {
      data: {
        ...JSON.parse(JSON.stringify(tabDefaults.config)).data,
        ...inputs.config.data,
        // add/correct
        platform: "sift",
        do_finance: inputs.complexity && inputs.complexity.finance ? 1 : 0,
        do_continuous: 0,
        do_dc_lock: 0,
        dc_lock_value: 0,

        do_inverter_qty_lock: inputs.config.inverter_qty && inputs.config.inverter_qty > 0 ? inputs.config.inverter_qty_lock : 0,
        inverter_qty: inputs.config.data.inverter_qty,

        do_rack_align: inputs.config.data.doShifting && inputs.config.data.doShifting == 1 ? 0 : 1,
        do_roads: inputs.config.data.doRoadSpacing && inputs.config.data.doRoadSpacing == 1 ? 1 : 0,

        do_inv_spacing: inputs.config.data.inv_spacing && inputs.config.data.inv_spacing == 1 ? 1 : 0,

        selected_gcr_pitch: inputs.config.data.selected_gcr_pitch ? inputs.config.data.selected_gcr_pitch : 0,
        selected_slider_fixed: inputs.config.data.selected_slider_fixed ? inputs.config.data.selected_slider_fixed : 0,

        spi_min_max: [minSPI, maxSPI],

        doShifting: undefined,
        doRoadSpacing: undefined,
      },
    };
  } else {
    config = {
      ...inputs.config,
      data: {
        ...JSON.parse(JSON.stringify(tabDefaults.config)).data,
        ...inputs.config.data,
      },
    };
  }

  let topo = {};
  if (inputs.topo == undefined) {
    // doesn't exist
    topo = JSON.parse(JSON.stringify(tabDefaults.topography));
  } else if (inputs.topo && inputs.topo.layers_generated) {
    // old format
    topo = JSON.parse(JSON.stringify(tabDefaults.topography));
    let mode = inputs.racking.data.type == 0 ? "EW" : "NS";
    let mode_ext = `${mode}/${inputs.topo.layers_generated[mode].limit}`;
    topo.data = {
      ...topo.data,
      topo_live: true,
      topo_url: `https://topo-tiles.sunfig.com/test/${inputs.topo.topo_id}/${mode_ext}/{z}/{x}/{y}.png`,
      topo_scale_url: `https://topo-tiles.sunfig.com/test/${inputs.topo.topo_id}/${mode_ext}/scale.png`,
      topo_id: inputs.topo.topo_id,
      topo_action: inputs.topo.topo_action == undefined ? "nothing" : inputs.topo.topo_action,
      ns_grade_limit: inputs.topo.layers_generated["NS"].limit,
      ew_grade_limit: inputs.topo.layers_generated["EW"].limit,
      u_grade_limit: inputs.topo.layers_generated["U"].limit,
    };
  } else {
    if (inputs.topo.data) {
      topo = { ...inputs.topo };
      topo.data.topo_loading = false;
    } else {
      topo = JSON.parse(JSON.stringify(tabDefaults.topography));
    }
  }

  let layout = {};
  if (inputs.layout == undefined) {
    // pull values from config / defaults
    layout = {
      data: {
        azimuth: config.data.azimuth,
        do_inverter_qty_lock: config.data.do_inverter_qty_lock,
        inverter_qty: config.data.inverter_qty,
        do_rack_align: config.data.do_rack_align,
        do_roads: config.data.do_roads,
        road_spacing_option: config.data.road_spacing_option,
        road_spacing: config.data.road_spacing,
        do_inv_spacing: config.data.do_inv_spacing,
        inverter_per_cut: config.data.inverter_per_cut,
        layout_fill: config.data.layout_fill,
        do_designer_margin: 0,
        designer_margin: 0,
        topo_action: topo.topo_action,
        epsg: 0,
      },
    };
  } else {
    layout = {
      id: inputs.layout.id,
      data: {
        azimuth: config.data.azimuth,
        do_inverter_qty_lock: config.data.do_inverter_qty_lock,
        inverter_qty: config.data.inverter_qty,
        do_rack_align: config.data.do_rack_align,
        do_roads: config.data.do_roads,
        road_spacing_option: config.data.road_spacing_option,
        road_spacing: config.data.road_spacing,
        do_inv_spacing: config.data.do_inv_spacing,
        inverter_per_cut: config.data.inverter_per_cut,
        layout_fill: config.data.layout_fill,
        do_designer_margin: 0,
        designer_margin: 0,
        epsg: 0,

        ...inputs.layout.data,
        topo_action: topo.topo_action,
      },
    };
  }

  let fixed_inputs = {
    map,
    module,
    inverter,
    racking,
    performance,
    weather,
    finance,
    config,
    topo,
    layout,
  };

  // console.log(fixed_inputs)
  return fixed_inputs;
}

function fixStringCount(index) {
  return 3;
}

export function fixRacking(inputs, module_per_string = 28) {
  // console.log(module_per_string)
  // console.log(inputs)
  let racking = {};

  let rack_dims = isUndefined(inputs.data.rack_dims) ? 1 : inputs.data.rack_dims;

  let fix_racks = [];
  Object.values(inputs.data.racks).map((rack, index) => {
    // manual assumption - calculate the string count based on modules and modules_per_string
    let string_count = _.round(parseInt(rack.module) / parseInt(module_per_string), 2);
    if (rack_dims == 0) {
      string_count = isUndefined(rack.string_count) ? string_count : rack.string_count;
    }

    fix_racks.push({
      key: index + 1,
      active: rack.active,
      xdim: parseFloat(rack.xdim),
      ydim: parseFloat(rack.ydim),
      module: parseInt(rack.module),
      string_count: string_count,
    });
  });

  racking = {
    ...inputs,
    data: {
      ...inputs.data,
      tilt_inc: isUndefined(inputs.data.tilt_inc) ? tabDefaults.racking.data.tilt_inc : inputs.data.tilt_inc,
      module_clearance_height: inputs.data.module_clearance_height != undefined ? inputs.data.module_clearance_height : inputs.data.type == 0 ? 0.5 : 1.5,
      module_transmission_perc: inputs.data.module_transmission_perc != undefined ? inputs.data.module_transmission_perc : 0,
      module_structure_shade_perc: inputs.data.module_structure_shade_perc != undefined ? inputs.data.module_structure_shade_perc : 0,
      grade_limit: inputs.data.grade_limit != undefined ? inputs.data.grade_limit : inputs.data.type == 0 ? 20 : 10,
      racks: fix_racks,
      rack_dims: rack_dims, //0  = auto, 1 = manual
      modules_high: inputs.data.modules_high != undefined ? inputs.data.modules_high : tabDefaults.racking.data.modules_high,
      orientation: inputs.data.orientation != undefined ? inputs.data.orientation : tabDefaults.racking.data.orientation, // 0 = portrait, 1 = landscape
      module_gap: inputs.data.module_gap != undefined ? inputs.data.module_gap : tabDefaults.racking.data.module_gap,
      drive_gap: inputs.data.drive_gap != undefined ? inputs.data.drive_gap : tabDefaults.racking.data.drive_gap,
    },
  };

  // console.log(racking)
  return racking;
}

export function fixPerformance(inputs) {
  let performance = {};
  performance = {
    ...inputs,
    data: {
      ...inputs.data,

      performance_model: inputs.data.performance_model != undefined ? parseInt(inputs.data.performance_model) : tabDefaults.performance.data.performance_model,
      doGridPower: inputs.data.doGridPower != undefined ? parseInt(inputs.data.doGridPower) : tabDefaults.performance.data.doGridPower,
      grid_power_limit: inputs.data.grid_power_limit != undefined ? parseFloat(inputs.data.grid_power_limit) : tabDefaults.performance.data.grid_power_limit,
      modules_per_string: inputs.data.modules_per_string != undefined ? parseInt(inputs.data.modules_per_string) : tabDefaults.performance.data.modules_per_string,
      dc_degradation: inputs.data.dc_degradation != undefined ? parseFloat(inputs.data.dc_degradation) : tabDefaults.performance.data.dc_degradation,
      irrad_mode: inputs.data.irrad_mode != undefined ? parseInt(inputs.data.irrad_mode) : tabDefaults.performance.data.irrad_mode,
      mismatch_loss: inputs.data.mismatch_loss != undefined ? parseFloat(inputs.data.mismatch_loss) : tabDefaults.performance.data.mismatch_loss,
      sky_model: inputs.data.sky_model != undefined ? parseInt(inputs.data.sky_model) : tabDefaults.performance.data.sky_model,
      dc_loss_stc: inputs.data.dc_loss_stc != undefined ? parseFloat(inputs.data.dc_loss_stc) : tabDefaults.performance.data.dc_loss_stc,
      quality_loss: inputs.data.quality_loss != undefined ? parseFloat(inputs.data.quality_loss) : tabDefaults.performance.data.quality_loss,
      lid_loss: inputs.data.lid_loss != undefined ? parseFloat(inputs.data.lid_loss) : tabDefaults.performance.data.lid_loss,
      ac_losses: inputs.data.ac_losses != undefined ? parseFloat(inputs.data.ac_losses) : tabDefaults.performance.data.ac_losses,
      thermal_uc: inputs.data.thermal_uc != undefined ? parseFloat(inputs.data.thermal_uc) : tabDefaults.performance.data.thermal_uc,
      thermal_uv: inputs.data.thermal_uv != undefined ? parseFloat(inputs.data.thermal_uv) : tabDefaults.performance.data.thermal_uv,
      albedo: inputs.data.albedo != undefined ? parseFloat(inputs.data.albedo) : tabDefaults.performance.data.albedo,
      doMonthlyAlbedo: inputs.data.doMonthlyAlbedo != undefined ? parseInt(inputs.data.doMonthlyAlbedo) : tabDefaults.performance.data.doMonthlyAlbedo,
      albedo_monthly: inputs.data.albedo_monthly != undefined ? inputs.data.albedo_monthly : tabDefaults.performance.data.albedo_monthly,
      soiling: inputs.data.soiling != undefined ? parseFloat(inputs.data.soiling) : tabDefaults.performance.data.soiling,
      doMonthlySoiling: inputs.data.doMonthlySoiling != undefined ? parseInt(inputs.data.doMonthlySoiling) : tabDefaults.performance.data.doMonthlySoiling,
      soiling_monthly: inputs.data.soiling_monthly != undefined ? inputs.data.soiling_monthly : tabDefaults.performance.data.soiling_monthly,
    },
  };

  return performance;
}

export function fixFinance(inputs) {
  let finance = {};
  // console.log("fixFinance inputs", inputs);

  // function shapeModuleCost(modCost) {
  //   if (typeof modCost == "object") {
  //     return modCost;
  //   } else {
  //     return { value: modCost, type: 0, total: 0.35 };
  //   }
  // }
  let summed_dc = _.isUndefined(inputs.data.summarized_dc) ? tabDefaults.finance.data.summarized_dc : parseFloat(inputs.data.summarized_dc);
  let summed_ac = _.isUndefined(inputs.data.summarized_ac) ? tabDefaults.finance.data.summarized_ac : parseFloat(inputs.data.summarized_ac);
  let summed_fixed = _.isFinite(parseFloat(inputs.data.fixed_cost)) ? parseFloat(inputs.data.fixed_cost) : 0;
  let rack_footprint = _.isUndefined(inputs.data.rack_footprint) ? tabDefaults.finance.data.rack_footprint : inputs.data.rack_footprint;
  if (_.isUndefined(inputs.data.summarized_dc) && _.isFinite(parseFloat(inputs.data.module_cost))) {
    // probably old project structure
    summed_dc = parseFloat(inputs.data.module_cost);
    if (inputs.data.other_dc_cost) {
      summed_dc += parseFloat(inputs.data.other_dc_cost);
    }
  }
  if (_.isUndefined(inputs.data.summarized_ac) && _.isFinite(parseFloat(inputs.data.ac_cost))) {
    summed_ac = parseFloat(inputs.data.ac_cost);
  }
  if (_.isFinite(parseFloat(inputs.data.footprint_install_cost))) {
    summed_fixed += parseFloat(inputs.data.footprint_install_cost);
    rack_footprint.value = parseFloat(inputs.data.footprint_install_cost);
  }

  finance = {
    ...inputs,
    data: {
      ...inputs.data,

      toggle_finance_type: inputs.data.toggle_finance_type ? inputs.data.toggle_finance_type : tabDefaults.finance.data.toggle_finance_type,

      // summarized data
      summarized_dc: summed_dc,
      summarized_ac: summed_ac,
      fixed_cost: summed_fixed,
      // summarized_dc_wiring_wp: inputs.data.summarized_dc_wiring_wp ? inputs.data.summarized_dc_wiring_wp : tabDefaults.finance.data.summarized_dc_wiring_wp,
      // summarized_dc_wiring_gcr: inputs.data.summarized_dc_wiring_gcr ? inputs.data.summarized_dc_wiring_gcr : tabDefaults.finance.data.summarized_dc_wiring_gcr,
      rack_footprint: rack_footprint,
      footprint_op_cost: inputs.data.footprint_op_cost ? inputs.data.footprint_op_cost : tabDefaults.finance.data.footprint_op_cost,

      // D/C Units
      module_dc_cost: inputs.data.module_dc_cost ? inputs.data.module_dc_cost : tabDefaults.finance.data.module_dc_cost,
      rack_a_finance: inputs.data.rack_a_finance ? inputs.data.rack_a_finance : tabDefaults.finance.data.rack_a_finance,
      rack_b_finance: inputs.data.rack_b_finance ? inputs.data.rack_b_finance : tabDefaults.finance.data.rack_b_finance,
      rack_c_finance: inputs.data.rack_c_finance ? inputs.data.rack_c_finance : tabDefaults.finance.data.rack_c_finance,
      bos_other: inputs.data.bos_other ? inputs.data.bos_other : tabDefaults.finance.data.bos_other,

      // fixed units
      interconnection: inputs.data.interconnection ? inputs.data.interconnection : tabDefaults.finance.data.interconnection,
      permits_fees: inputs.data.permits_fees ? inputs.data.permits_fees : tabDefaults.finance.data.permits_fees,
      engineering: inputs.data.engineering ? inputs.data.engineering : tabDefaults.finance.data.engineering,
      margin: inputs.data.margin ? inputs.data.margin : tabDefaults.finance.data.margin,
      other_fixed: inputs.data.other_fixed ? inputs.data.other_fixed : tabDefaults.finance.data.other_fixed,

      // A/C Units
      inverter: inputs.data.inverter ? inputs.data.inverter : tabDefaults.finance.data.inverter,
      ac_aux: inputs.data.ac_aux ? inputs.data.ac_aux : tabDefaults.finance.data.ac_aux,
      mv_wire: inputs.data.mv_wire ? inputs.data.mv_wire : tabDefaults.finance.data.mv_wire,
      other_ac: inputs.data.other_ac ? inputs.data.other_ac : tabDefaults.finance.data.other_ac,

      // Misc Units
      rack_footprint: inputs.data.rack_footprint ? inputs.data.rack_footprint : tabDefaults.finance.data.rack_footprint,
      boundary_area: inputs.data.boundary_area ? inputs.data.boundary_area : tabDefaults.finance.data.boundary_area,
      contingency: inputs.data.contingency ? inputs.data.contingency : tabDefaults.finance.data.contingency,
      spacing_gcr: inputs.data.spacing_gcr ? inputs.data.spacing_gcr : tabDefaults.finance.data.spacing_gcr,
      spacing_per_wp: inputs.data.spacing_per_wp ? inputs.data.spacing_per_wp : tabDefaults.finance.data.spacing_per_wp,
    },
  };

  // console.log("fixFinance outputs", finance);
  return finance;
}
