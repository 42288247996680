export const EPSGData = {
  "0": {   
    "name": "WGS84 to Meters (default)",
    "id": {
      "authority": "EPSG",
      "code": 0
    },  
  },
  "3467": {
    "bbox": {
        "east_longitude": -129.99,
        "north_latitude": 71.4,
        "south_latitude": 51.3,
        "west_longitude": 172.42
    },
    "id": {
        "authority": "EPSG",
        "code": 3467
    },
    "name": "NAD83 / Alaska Albers"
    },
    "3468": {
        "bbox": {
            "east_longitude": -129.99,
            "north_latitude": 60.35,
            "south_latitude": 54.61,
            "west_longitude": -141
        },
        "id": {
            "authority": "EPSG",
            "code": 3468
        },
        "name": "NAD83 / Alaska zone 1"
    },
    "3469": {
        "bbox": {
            "east_longitude": -140.98,
            "north_latitude": 70.16,
            "south_latitude": 59.72,
            "west_longitude": -144.01
        },
        "id": {
            "authority": "EPSG",
            "code": 3469
        },
        "name": "NAD83 / Alaska zone 2"
    },
    "3470": {
        "bbox": {
            "east_longitude": -144,
            "north_latitude": 70.38,
            "south_latitude": 59.72,
            "west_longitude": -148
        },
        "id": {
            "authority": "EPSG",
            "code": 3470
        },
        "name": "NAD83 / Alaska zone 3"
    },
    "3471": {
        "bbox": {
            "east_longitude": -147.99,
            "north_latitude": 70.63,
            "south_latitude": 59.11,
            "west_longitude": -152.01
        },
        "id": {
            "authority": "EPSG",
            "code": 3471
        },
        "name": "NAD83 / Alaska zone 4"
    },
    "3472": {
        "bbox": {
            "east_longitude": -151.86,
            "north_latitude": 71.28,
            "south_latitude": 55.72,
            "west_longitude": -156
        },
        "id": {
            "authority": "EPSG",
            "code": 3472
        },
        "name": "NAD83 / Alaska zone 5"
    },
    "3473": {
        "bbox": {
            "east_longitude": -155.99,
            "north_latitude": 71.4,
            "south_latitude": 54.89,
            "west_longitude": -160
        },
        "id": {
            "authority": "EPSG",
            "code": 3473
        },
        "name": "NAD83 / Alaska zone 6"
    },
    "3474": {
        "bbox": {
            "east_longitude": -160,
            "north_latitude": 70.74,
            "south_latitude": 54.32,
            "west_longitude": -164.01
        },
        "id": {
            "authority": "EPSG",
            "code": 3474
        },
        "name": "NAD83 / Alaska zone 7"
    },
    "3475": {
        "bbox": {
            "east_longitude": -164,
            "north_latitude": 69.05,
            "south_latitude": 54.34,
            "west_longitude": -168.26
        },
        "id": {
            "authority": "EPSG",
            "code": 3475
        },
        "name": "NAD83 / Alaska zone 8"
    },
    "3476": {
        "bbox": {
            "east_longitude": -168,
            "north_latitude": 65.82,
            "south_latitude": 56.49,
            "west_longitude": -173.16
        },
        "id": {
            "authority": "EPSG",
            "code": 3476
        },
        "name": "NAD83 / Alaska zone 9"
    },
    "3477": {
        "bbox": {
            "east_longitude": -164.84,
            "north_latitude": 54.34,
            "south_latitude": 51.3,
            "west_longitude": 172.42
        },
        "id": {
            "authority": "EPSG",
            "code": 3477
        },
        "name": "NAD83 / Alaska zone 10"
    },
    "3536": {
        "bbox": {
            "east_longitude": -90.15,
            "north_latitude": 43.51,
            "south_latitude": 41.85,
            "west_longitude": -96.65
        },
        "id": {
            "authority": "EPSG",
            "code": 3536
        },
        "name": "NAD83 / Iowa North"
    },
    "3538": {
        "bbox": {
            "east_longitude": -90.14,
            "north_latitude": 42.04,
            "south_latitude": 40.37,
            "west_longitude": -96.14
        },
        "id": {
            "authority": "EPSG",
            "code": 3538
        },
        "name": "NAD83 / Iowa South"
    },
    "3631": {
        "bbox": {
            "east_longitude": -75.38,
            "north_latitude": 36.59,
            "south_latitude": 33.83,
            "west_longitude": -84.33
        },
        "id": {
            "authority": "EPSG",
            "code": 3631
        },
        "name": "NAD83 / North Carolina"
    },
    "3649": {
        "bbox": {
            "east_longitude": -74.7,
            "north_latitude": 42.53,
            "south_latitude": 40.6,
            "west_longitude": -80.53
        },
        "id": {
            "authority": "EPSG",
            "code": 3649
        },
        "name": "NAD83 / Pennsylvania North"
    },
    "3651": {
        "bbox": {
            "east_longitude": -74.72,
            "north_latitude": 41.18,
            "south_latitude": 39.71,
            "west_longitude": -80.53
        },
        "id": {
            "authority": "EPSG",
            "code": 3651
        },
        "name": "NAD83 / Pennsylvania South"
    },
    "3655": {
        "bbox": {
            "east_longitude": -78.52,
            "north_latitude": 35.21,
            "south_latitude": 32.05,
            "west_longitude": -83.36
        },
        "id": {
            "authority": "EPSG",
            "code": 3655
        },
        "name": "NAD83 / South Carolina"
    },
    "26929": {
        "bbox": {
            "east_longitude": -84.89,
            "north_latitude": 35,
            "south_latitude": 30.99,
            "west_longitude": -86.79
        },
        "id": {
            "authority": "EPSG",
            "code": 26929
        },
        "name": "NAD83 / Alabama East"
    },
    "26930": {
        "bbox": {
            "east_longitude": -86.3,
            "north_latitude": 35.02,
            "south_latitude": 30.14,
            "west_longitude": -88.48
        },
        "id": {
            "authority": "EPSG",
            "code": 26930
        },
        "name": "NAD83 / Alabama West"
    },
    "26931": {
        "bbox": {
            "east_longitude": -129.99,
            "north_latitude": 60.35,
            "south_latitude": 54.61,
            "west_longitude": -141
        },
        "id": {
            "authority": "EPSG",
            "code": 26931
        },
        "name": "NAD83 / Alaska zone 1"
    },
    "26932": {
        "bbox": {
            "east_longitude": -140.98,
            "north_latitude": 70.16,
            "south_latitude": 59.72,
            "west_longitude": -144.01
        },
        "id": {
            "authority": "EPSG",
            "code": 26932
        },
        "name": "NAD83 / Alaska zone 2"
    },
    "26933": {
        "bbox": {
            "east_longitude": -144,
            "north_latitude": 70.38,
            "south_latitude": 59.72,
            "west_longitude": -148
        },
        "id": {
            "authority": "EPSG",
            "code": 26933
        },
        "name": "NAD83 / Alaska zone 3"
    },
    "26934": {
        "bbox": {
            "east_longitude": -147.99,
            "north_latitude": 70.63,
            "south_latitude": 59.11,
            "west_longitude": -152.01
        },
        "id": {
            "authority": "EPSG",
            "code": 26934
        },
        "name": "NAD83 / Alaska zone 4"
    },
    "26935": {
        "bbox": {
            "east_longitude": -151.86,
            "north_latitude": 71.28,
            "south_latitude": 55.72,
            "west_longitude": -156
        },
        "id": {
            "authority": "EPSG",
            "code": 26935
        },
        "name": "NAD83 / Alaska zone 5"
    },
    "26936": {
        "bbox": {
            "east_longitude": -155.99,
            "north_latitude": 71.4,
            "south_latitude": 54.89,
            "west_longitude": -160
        },
        "id": {
            "authority": "EPSG",
            "code": 26936
        },
        "name": "NAD83 / Alaska zone 6"
    },
    "26937": {
        "bbox": {
            "east_longitude": -160,
            "north_latitude": 70.74,
            "south_latitude": 54.32,
            "west_longitude": -164.01
        },
        "id": {
            "authority": "EPSG",
            "code": 26937
        },
        "name": "NAD83 / Alaska zone 7"
    },
    "26938": {
        "bbox": {
            "east_longitude": -164,
            "north_latitude": 69.05,
            "south_latitude": 54.34,
            "west_longitude": -168.26
        },
        "id": {
            "authority": "EPSG",
            "code": 26938
        },
        "name": "NAD83 / Alaska zone 8"
    },
    "26939": {
        "bbox": {
            "east_longitude": -168,
            "north_latitude": 65.82,
            "south_latitude": 56.49,
            "west_longitude": -173.16
        },
        "id": {
            "authority": "EPSG",
            "code": 26939
        },
        "name": "NAD83 / Alaska zone 9"
    },
    "26940": {
        "bbox": {
            "east_longitude": -164.84,
            "north_latitude": 54.34,
            "south_latitude": 51.3,
            "west_longitude": 172.42
        },
        "id": {
            "authority": "EPSG",
            "code": 26940
        },
        "name": "NAD83 / Alaska zone 10"
    },
    "26941": {
        "bbox": {
            "east_longitude": -119.99,
            "north_latitude": 42.01,
            "south_latitude": 39.59,
            "west_longitude": -124.45
        },
        "id": {
            "authority": "EPSG",
            "code": 26941
        },
        "name": "NAD83 / California zone 1"
    },
    "26942": {
        "bbox": {
            "east_longitude": -119.54,
            "north_latitude": 40.16,
            "south_latitude": 38.02,
            "west_longitude": -124.06
        },
        "id": {
            "authority": "EPSG",
            "code": 26942
        },
        "name": "NAD83 / California zone 2"
    },
    "26943": {
        "bbox": {
            "east_longitude": -117.83,
            "north_latitude": 38.71,
            "south_latitude": 36.73,
            "west_longitude": -123.02
        },
        "id": {
            "authority": "EPSG",
            "code": 26943
        },
        "name": "NAD83 / California zone 3"
    },
    "26944": {
        "bbox": {
            "east_longitude": -115.62,
            "north_latitude": 37.58,
            "south_latitude": 35.78,
            "west_longitude": -122.01
        },
        "id": {
            "authority": "EPSG",
            "code": 26944
        },
        "name": "NAD83 / California zone 4"
    },
    "26945": {
        "bbox": {
            "east_longitude": -114.12,
            "north_latitude": 35.81,
            "south_latitude": 32.76,
            "west_longitude": -121.42
        },
        "id": {
            "authority": "EPSG",
            "code": 26945
        },
        "name": "NAD83 / California zone 5"
    },
    "26946": {
        "bbox": {
            "east_longitude": -114.42,
            "north_latitude": 34.08,
            "south_latitude": 32.53,
            "west_longitude": -118.15
        },
        "id": {
            "authority": "EPSG",
            "code": 26946
        },
        "name": "NAD83 / California zone 6"
    },
    "26948": {
        "bbox": {
            "east_longitude": -109.04,
            "north_latitude": 37.01,
            "south_latitude": 31.33,
            "west_longitude": -111.71
        },
        "id": {
            "authority": "EPSG",
            "code": 26948
        },
        "name": "NAD83 / Arizona East"
    },
    "26949": {
        "bbox": {
            "east_longitude": -110.44,
            "north_latitude": 37.01,
            "south_latitude": 31.33,
            "west_longitude": -113.35
        },
        "id": {
            "authority": "EPSG",
            "code": 26949
        },
        "name": "NAD83 / Arizona Central"
    },
    "26950": {
        "bbox": {
            "east_longitude": -112.52,
            "north_latitude": 37,
            "south_latitude": 32.05,
            "west_longitude": -114.81
        },
        "id": {
            "authority": "EPSG",
            "code": 26950
        },
        "name": "NAD83 / Arizona West"
    },
    "26951": {
        "bbox": {
            "east_longitude": -89.64,
            "north_latitude": 36.5,
            "south_latitude": 34.67,
            "west_longitude": -94.62
        },
        "id": {
            "authority": "EPSG",
            "code": 26951
        },
        "name": "NAD83 / Arkansas North"
    },
    "26952": {
        "bbox": {
            "east_longitude": -90.4,
            "north_latitude": 35.1,
            "south_latitude": 33.01,
            "west_longitude": -94.48
        },
        "id": {
            "authority": "EPSG",
            "code": 26952
        },
        "name": "NAD83 / Arkansas South"
    },
    "26953": {
        "bbox": {
            "east_longitude": -102.04,
            "north_latitude": 41.01,
            "south_latitude": 39.56,
            "west_longitude": -109.06
        },
        "id": {
            "authority": "EPSG",
            "code": 26953
        },
        "name": "NAD83 / Colorado North"
    },
    "26954": {
        "bbox": {
            "east_longitude": -102.04,
            "north_latitude": 40.09,
            "south_latitude": 38.14,
            "west_longitude": -109.06
        },
        "id": {
            "authority": "EPSG",
            "code": 26954
        },
        "name": "NAD83 / Colorado Central"
    },
    "26955": {
        "bbox": {
            "east_longitude": -102.04,
            "north_latitude": 38.68,
            "south_latitude": 36.98,
            "west_longitude": -109.06
        },
        "id": {
            "authority": "EPSG",
            "code": 26955
        },
        "name": "NAD83 / Colorado South"
    },
    "26956": {
        "bbox": {
            "east_longitude": -71.78,
            "north_latitude": 42.05,
            "south_latitude": 40.98,
            "west_longitude": -73.73
        },
        "id": {
            "authority": "EPSG",
            "code": 26956
        },
        "name": "NAD83 / Connecticut"
    },
    "26957": {
        "bbox": {
            "east_longitude": -74.97,
            "north_latitude": 39.85,
            "south_latitude": 38.44,
            "west_longitude": -75.8
        },
        "id": {
            "authority": "EPSG",
            "code": 26957
        },
        "name": "NAD83 / Delaware"
    },
    "26958": {
        "bbox": {
            "east_longitude": -79.97,
            "north_latitude": 30.83,
            "south_latitude": 24.41,
            "west_longitude": -82.33
        },
        "id": {
            "authority": "EPSG",
            "code": 26958
        },
        "name": "NAD83 / Florida East"
    },
    "26959": {
        "bbox": {
            "east_longitude": -81.13,
            "north_latitude": 29.6,
            "south_latitude": 26.27,
            "west_longitude": -83.34
        },
        "id": {
            "authority": "EPSG",
            "code": 26959
        },
        "name": "NAD83 / Florida West"
    },
    "26960": {
        "bbox": {
            "east_longitude": -82.04,
            "north_latitude": 31.01,
            "south_latitude": 29.21,
            "west_longitude": -87.63
        },
        "id": {
            "authority": "EPSG",
            "code": 26960
        },
        "name": "NAD83 / Florida North"
    },
    "26961": {
        "bbox": {
            "east_longitude": -154.74,
            "north_latitude": 20.33,
            "south_latitude": 18.87,
            "west_longitude": -156.1
        },
        "id": {
            "authority": "EPSG",
            "code": 26961
        },
        "name": "NAD83 / Hawaii zone 1"
    },
    "26962": {
        "bbox": {
            "east_longitude": -155.93,
            "north_latitude": 21.26,
            "south_latitude": 20.45,
            "west_longitude": -157.36
        },
        "id": {
            "authority": "EPSG",
            "code": 26962
        },
        "name": "NAD83 / Hawaii zone 2"
    },
    "26963": {
        "bbox": {
            "east_longitude": -157.61,
            "north_latitude": 21.75,
            "south_latitude": 21.2,
            "west_longitude": -158.33
        },
        "id": {
            "authority": "EPSG",
            "code": 26963
        },
        "name": "NAD83 / Hawaii zone 3"
    },
    "26964": {
        "bbox": {
            "east_longitude": -159.23,
            "north_latitude": 22.29,
            "south_latitude": 21.81,
            "west_longitude": -159.85
        },
        "id": {
            "authority": "EPSG",
            "code": 26964
        },
        "name": "NAD83 / Hawaii zone 4"
    },
    "26965": {
        "bbox": {
            "east_longitude": -159.99,
            "north_latitude": 22.07,
            "south_latitude": 21.73,
            "west_longitude": -160.3
        },
        "id": {
            "authority": "EPSG",
            "code": 26965
        },
        "name": "NAD83 / Hawaii zone 5"
    },
    "26966": {
        "bbox": {
            "east_longitude": -80.77,
            "north_latitude": 34.68,
            "south_latitude": 30.36,
            "west_longitude": -83.47
        },
        "id": {
            "authority": "EPSG",
            "code": 26966
        },
        "name": "NAD83 / Georgia East"
    },
    "26967": {
        "bbox": {
            "east_longitude": -82.99,
            "north_latitude": 35.01,
            "south_latitude": 30.62,
            "west_longitude": -85.61
        },
        "id": {
            "authority": "EPSG",
            "code": 26967
        },
        "name": "NAD83 / Georgia West"
    },
    "26968": {
        "bbox": {
            "east_longitude": -111.04,
            "north_latitude": 44.75,
            "south_latitude": 41.99,
            "west_longitude": -113.24
        },
        "id": {
            "authority": "EPSG",
            "code": 26968
        },
        "name": "NAD83 / Idaho East"
    },
    "26969": {
        "bbox": {
            "east_longitude": -112.68,
            "north_latitude": 45.7,
            "south_latitude": 41.99,
            "west_longitude": -115.3
        },
        "id": {
            "authority": "EPSG",
            "code": 26969
        },
        "name": "NAD83 / Idaho Central"
    },
    "26970": {
        "bbox": {
            "east_longitude": -114.32,
            "north_latitude": 49.01,
            "south_latitude": 41.99,
            "west_longitude": -117.24
        },
        "id": {
            "authority": "EPSG",
            "code": 26970
        },
        "name": "NAD83 / Idaho West"
    },
    "26971": {
        "bbox": {
            "east_longitude": -87.02,
            "north_latitude": 42.5,
            "south_latitude": 37.06,
            "west_longitude": -89.28
        },
        "id": {
            "authority": "EPSG",
            "code": 26971
        },
        "name": "NAD83 / Illinois East"
    },
    "26972": {
        "bbox": {
            "east_longitude": -88.93,
            "north_latitude": 42.51,
            "south_latitude": 36.98,
            "west_longitude": -91.52
        },
        "id": {
            "authority": "EPSG",
            "code": 26972
        },
        "name": "NAD83 / Illinois West"
    },
    "26973": {
        "bbox": {
            "east_longitude": -84.78,
            "north_latitude": 41.77,
            "south_latitude": 37.95,
            "west_longitude": -86.59
        },
        "id": {
            "authority": "EPSG",
            "code": 26973
        },
        "name": "NAD83 / Indiana East"
    },
    "26974": {
        "bbox": {
            "east_longitude": -86.24,
            "north_latitude": 41.77,
            "south_latitude": 37.77,
            "west_longitude": -88.06
        },
        "id": {
            "authority": "EPSG",
            "code": 26974
        },
        "name": "NAD83 / Indiana West"
    },
    "26975": {
        "bbox": {
            "east_longitude": -90.15,
            "north_latitude": 43.51,
            "south_latitude": 41.85,
            "west_longitude": -96.65
        },
        "id": {
            "authority": "EPSG",
            "code": 26975
        },
        "name": "NAD83 / Iowa North"
    },
    "26976": {
        "bbox": {
            "east_longitude": -90.14,
            "north_latitude": 42.04,
            "south_latitude": 40.37,
            "west_longitude": -96.14
        },
        "id": {
            "authority": "EPSG",
            "code": 26976
        },
        "name": "NAD83 / Iowa South"
    },
    "26977": {
        "bbox": {
            "east_longitude": -94.58,
            "north_latitude": 40.01,
            "south_latitude": 38.52,
            "west_longitude": -102.06
        },
        "id": {
            "authority": "EPSG",
            "code": 26977
        },
        "name": "NAD83 / Kansas North"
    },
    "26978": {
        "bbox": {
            "east_longitude": -94.6,
            "north_latitude": 38.88,
            "south_latitude": 36.99,
            "west_longitude": -102.05
        },
        "id": {
            "authority": "EPSG",
            "code": 26978
        },
        "name": "NAD83 / Kansas South"
    },
    "26979": {
        "bbox": {
            "east_longitude": -82.47,
            "north_latitude": 39.15,
            "south_latitude": 37.71,
            "west_longitude": -85.96
        },
        "id": {
            "authority": "EPSG",
            "code": 26979
        },
        "name": "NAD83 / Kentucky North"
    },
    "26980": {
        "bbox": {
            "east_longitude": -81.95,
            "north_latitude": 38.17,
            "south_latitude": 36.49,
            "west_longitude": -89.57
        },
        "id": {
            "authority": "EPSG",
            "code": 26980
        },
        "name": "NAD83 / Kentucky South"
    },
    "26981": {
        "bbox": {
            "east_longitude": -90.86,
            "north_latitude": 33.03,
            "south_latitude": 30.85,
            "west_longitude": -94.05
        },
        "id": {
            "authority": "EPSG",
            "code": 26981
        },
        "name": "NAD83 / Louisiana North"
    },
    "26982": {
        "bbox": {
            "east_longitude": -88.75,
            "north_latitude": 31.07,
            "south_latitude": 28.85,
            "west_longitude": -93.94
        },
        "id": {
            "authority": "EPSG",
            "code": 26982
        },
        "name": "NAD83 / Louisiana South"
    },
    "26983": {
        "bbox": {
            "east_longitude": -66.91,
            "north_latitude": 47.47,
            "south_latitude": 43.88,
            "west_longitude": -70.03
        },
        "id": {
            "authority": "EPSG",
            "code": 26983
        },
        "name": "NAD83 / Maine East"
    },
    "26984": {
        "bbox": {
            "east_longitude": -69.26,
            "north_latitude": 46.58,
            "south_latitude": 43.04,
            "west_longitude": -71.09
        },
        "id": {
            "authority": "EPSG",
            "code": 26984
        },
        "name": "NAD83 / Maine West"
    },
    "26985": {
        "bbox": {
            "east_longitude": -74.97,
            "north_latitude": 39.73,
            "south_latitude": 37.97,
            "west_longitude": -79.49
        },
        "id": {
            "authority": "EPSG",
            "code": 26985
        },
        "name": "NAD83 / Maryland"
    },
    "26986": {
        "bbox": {
            "east_longitude": -69.86,
            "north_latitude": 42.89,
            "south_latitude": 41.46,
            "west_longitude": -73.5
        },
        "id": {
            "authority": "EPSG",
            "code": 26986
        },
        "name": "NAD83 / Massachusetts Mainland"
    },
    "26987": {
        "bbox": {
            "east_longitude": -69.89,
            "north_latitude": 41.51,
            "south_latitude": 41.19,
            "west_longitude": -70.91
        },
        "id": {
            "authority": "EPSG",
            "code": 26987
        },
        "name": "NAD83 / Massachusetts Island"
    },
    "26988": {
        "bbox": {
            "east_longitude": -83.44,
            "north_latitude": 48.32,
            "south_latitude": 45.08,
            "west_longitude": -90.42
        },
        "id": {
            "authority": "EPSG",
            "code": 26988
        },
        "name": "NAD83 / Michigan North"
    },
    "26989": {
        "bbox": {
            "east_longitude": -82.27,
            "north_latitude": 45.92,
            "south_latitude": 43.8,
            "west_longitude": -87.06
        },
        "id": {
            "authority": "EPSG",
            "code": 26989
        },
        "name": "NAD83 / Michigan Central"
    },
    "26990": {
        "bbox": {
            "east_longitude": -82.13,
            "north_latitude": 44.22,
            "south_latitude": 41.69,
            "west_longitude": -87.2
        },
        "id": {
            "authority": "EPSG",
            "code": 26990
        },
        "name": "NAD83 / Michigan South"
    },
    "26991": {
        "bbox": {
            "east_longitude": -89.49,
            "north_latitude": 49.38,
            "south_latitude": 46.64,
            "west_longitude": -97.22
        },
        "id": {
            "authority": "EPSG",
            "code": 26991
        },
        "name": "NAD83 / Minnesota North"
    },
    "26992": {
        "bbox": {
            "east_longitude": -92.29,
            "north_latitude": 47.48,
            "south_latitude": 45.28,
            "west_longitude": -96.86
        },
        "id": {
            "authority": "EPSG",
            "code": 26992
        },
        "name": "NAD83 / Minnesota Central"
    },
    "26993": {
        "bbox": {
            "east_longitude": -91.21,
            "north_latitude": 45.59,
            "south_latitude": 43.49,
            "west_longitude": -96.85
        },
        "id": {
            "authority": "EPSG",
            "code": 26993
        },
        "name": "NAD83 / Minnesota South"
    },
    "26994": {
        "bbox": {
            "east_longitude": -88.09,
            "north_latitude": 35.01,
            "south_latitude": 30.01,
            "west_longitude": -89.97
        },
        "id": {
            "authority": "EPSG",
            "code": 26994
        },
        "name": "NAD83 / Mississippi East"
    },
    "26995": {
        "bbox": {
            "east_longitude": -89.37,
            "north_latitude": 35.01,
            "south_latitude": 31,
            "west_longitude": -91.65
        },
        "id": {
            "authority": "EPSG",
            "code": 26995
        },
        "name": "NAD83 / Mississippi West"
    },
    "26996": {
        "bbox": {
            "east_longitude": -89.1,
            "north_latitude": 40.61,
            "south_latitude": 35.98,
            "west_longitude": -91.97
        },
        "id": {
            "authority": "EPSG",
            "code": 26996
        },
        "name": "NAD83 / Missouri East"
    },
    "26997": {
        "bbox": {
            "east_longitude": -91.41,
            "north_latitude": 40.61,
            "south_latitude": 36.48,
            "west_longitude": -93.79
        },
        "id": {
            "authority": "EPSG",
            "code": 26997
        },
        "name": "NAD83 / Missouri Central"
    },
    "26998": {
        "bbox": {
            "east_longitude": -93.48,
            "north_latitude": 40.59,
            "south_latitude": 36.48,
            "west_longitude": -95.77
        },
        "id": {
            "authority": "EPSG",
            "code": 26998
        },
        "name": "NAD83 / Missouri West"
    },
    "32100": {
        "bbox": {
            "east_longitude": -104.04,
            "north_latitude": 49.01,
            "south_latitude": 44.35,
            "west_longitude": -116.07
        },
        "id": {
            "authority": "EPSG",
            "code": 32100
        },
        "name": "NAD83 / Montana"
    },
    "32104": {
        "bbox": {
            "east_longitude": -95.3,
            "north_latitude": 43.01,
            "south_latitude": 39.99,
            "west_longitude": -104.06
        },
        "id": {
            "authority": "EPSG",
            "code": 32104
        },
        "name": "NAD83 / Nebraska"
    },
    "32107": {
        "bbox": {
            "east_longitude": -114.03,
            "north_latitude": 42,
            "south_latitude": 34.99,
            "west_longitude": -117.01
        },
        "id": {
            "authority": "EPSG",
            "code": 32107
        },
        "name": "NAD83 / Nevada East"
    },
    "32108": {
        "bbox": {
            "east_longitude": -114.99,
            "north_latitude": 41,
            "south_latitude": 36,
            "west_longitude": -118.19
        },
        "id": {
            "authority": "EPSG",
            "code": 32108
        },
        "name": "NAD83 / Nevada Central"
    },
    "32109": {
        "bbox": {
            "east_longitude": -116.99,
            "north_latitude": 42,
            "south_latitude": 36.95,
            "west_longitude": -120
        },
        "id": {
            "authority": "EPSG",
            "code": 32109
        },
        "name": "NAD83 / Nevada West"
    },
    "32110": {
        "bbox": {
            "east_longitude": -70.63,
            "north_latitude": 45.31,
            "south_latitude": 42.69,
            "west_longitude": -72.56
        },
        "id": {
            "authority": "EPSG",
            "code": 32110
        },
        "name": "NAD83 / New Hampshire"
    },
    "32111": {
        "bbox": {
            "east_longitude": -73.88,
            "north_latitude": 41.36,
            "south_latitude": 38.87,
            "west_longitude": -75.6
        },
        "id": {
            "authority": "EPSG",
            "code": 32111
        },
        "name": "NAD83 / New Jersey"
    },
    "32112": {
        "bbox": {
            "east_longitude": -102.99,
            "north_latitude": 37,
            "south_latitude": 32,
            "west_longitude": -105.72
        },
        "id": {
            "authority": "EPSG",
            "code": 32112
        },
        "name": "NAD83 / New Mexico East"
    },
    "32113": {
        "bbox": {
            "east_longitude": -104.84,
            "north_latitude": 37,
            "south_latitude": 31.78,
            "west_longitude": -107.73
        },
        "id": {
            "authority": "EPSG",
            "code": 32113
        },
        "name": "NAD83 / New Mexico Central"
    },
    "32114": {
        "bbox": {
            "east_longitude": -106.32,
            "north_latitude": 37,
            "south_latitude": 31.33,
            "west_longitude": -109.06
        },
        "id": {
            "authority": "EPSG",
            "code": 32114
        },
        "name": "NAD83 / New Mexico West"
    },
    "32115": {
        "bbox": {
            "east_longitude": -73.23,
            "north_latitude": 45.02,
            "south_latitude": 40.88,
            "west_longitude": -75.87
        },
        "id": {
            "authority": "EPSG",
            "code": 32115
        },
        "name": "NAD83 / New York East"
    },
    "32116": {
        "bbox": {
            "east_longitude": -75.04,
            "north_latitude": 44.41,
            "south_latitude": 41.99,
            "west_longitude": -77.75
        },
        "id": {
            "authority": "EPSG",
            "code": 32116
        },
        "name": "NAD83 / New York Central"
    },
    "32117": {
        "bbox": {
            "east_longitude": -77.36,
            "north_latitude": 43.64,
            "south_latitude": 41.99,
            "west_longitude": -79.77
        },
        "id": {
            "authority": "EPSG",
            "code": 32117
        },
        "name": "NAD83 / New York West"
    },
    "32118": {
        "bbox": {
            "east_longitude": -71.8,
            "north_latitude": 41.3,
            "south_latitude": 40.47,
            "west_longitude": -74.26
        },
        "id": {
            "authority": "EPSG",
            "code": 32118
        },
        "name": "NAD83 / New York Long Island"
    },
    "32119": {
        "bbox": {
            "east_longitude": -75.38,
            "north_latitude": 36.59,
            "south_latitude": 33.83,
            "west_longitude": -84.33
        },
        "id": {
            "authority": "EPSG",
            "code": 32119
        },
        "name": "NAD83 / North Carolina"
    },
    "32120": {
        "bbox": {
            "east_longitude": -96.83,
            "north_latitude": 49.01,
            "south_latitude": 47.15,
            "west_longitude": -104.07
        },
        "id": {
            "authority": "EPSG",
            "code": 32120
        },
        "name": "NAD83 / North Dakota North"
    },
    "32121": {
        "bbox": {
            "east_longitude": -96.55,
            "north_latitude": 47.83,
            "south_latitude": 45.93,
            "west_longitude": -104.05
        },
        "id": {
            "authority": "EPSG",
            "code": 32121
        },
        "name": "NAD83 / North Dakota South"
    },
    "32122": {
        "bbox": {
            "east_longitude": -80.51,
            "north_latitude": 42.33,
            "south_latitude": 40.1,
            "west_longitude": -84.81
        },
        "id": {
            "authority": "EPSG",
            "code": 32122
        },
        "name": "NAD83 / Ohio North"
    },
    "32123": {
        "bbox": {
            "east_longitude": -80.7,
            "north_latitude": 40.36,
            "south_latitude": 38.4,
            "west_longitude": -84.83
        },
        "id": {
            "authority": "EPSG",
            "code": 32123
        },
        "name": "NAD83 / Ohio South"
    },
    "32124": {
        "bbox": {
            "east_longitude": -94.42,
            "north_latitude": 37.01,
            "south_latitude": 35.27,
            "west_longitude": -103
        },
        "id": {
            "authority": "EPSG",
            "code": 32124
        },
        "name": "NAD83 / Oklahoma North"
    },
    "32125": {
        "bbox": {
            "east_longitude": -94.42,
            "north_latitude": 35.57,
            "south_latitude": 33.62,
            "west_longitude": -100
        },
        "id": {
            "authority": "EPSG",
            "code": 32125
        },
        "name": "NAD83 / Oklahoma South"
    },
    "32126": {
        "bbox": {
            "east_longitude": -116.47,
            "north_latitude": 46.26,
            "south_latitude": 43.95,
            "west_longitude": -124.17
        },
        "id": {
            "authority": "EPSG",
            "code": 32126
        },
        "name": "NAD83 / Oregon North"
    },
    "32127": {
        "bbox": {
            "east_longitude": -116.9,
            "north_latitude": 44.56,
            "south_latitude": 41.98,
            "west_longitude": -124.6
        },
        "id": {
            "authority": "EPSG",
            "code": 32127
        },
        "name": "NAD83 / Oregon South"
    },
    "32128": {
        "bbox": {
            "east_longitude": -74.7,
            "north_latitude": 42.53,
            "south_latitude": 40.6,
            "west_longitude": -80.53
        },
        "id": {
            "authority": "EPSG",
            "code": 32128
        },
        "name": "NAD83 / Pennsylvania North"
    },
    "32129": {
        "bbox": {
            "east_longitude": -74.72,
            "north_latitude": 41.18,
            "south_latitude": 39.71,
            "west_longitude": -80.53
        },
        "id": {
            "authority": "EPSG",
            "code": 32129
        },
        "name": "NAD83 / Pennsylvania South"
    },
    "32130": {
        "bbox": {
            "east_longitude": -71.08,
            "north_latitude": 42.02,
            "south_latitude": 41.13,
            "west_longitude": -71.85
        },
        "id": {
            "authority": "EPSG",
            "code": 32130
        },
        "name": "NAD83 / Rhode Island"
    },
    "32134": {
        "bbox": {
            "east_longitude": -96.45,
            "north_latitude": 45.95,
            "south_latitude": 44.14,
            "west_longitude": -104.07
        },
        "id": {
            "authority": "EPSG",
            "code": 32134
        },
        "name": "NAD83 / South Dakota North"
    },
    "32135": {
        "bbox": {
            "east_longitude": -96.43,
            "north_latitude": 44.79,
            "south_latitude": 42.48,
            "west_longitude": -104.06
        },
        "id": {
            "authority": "EPSG",
            "code": 32135
        },
        "name": "NAD83 / South Dakota South"
    },
    "32136": {
        "bbox": {
            "east_longitude": -81.65,
            "north_latitude": 36.68,
            "south_latitude": 34.98,
            "west_longitude": -90.31
        },
        "id": {
            "authority": "EPSG",
            "code": 32136
        },
        "name": "NAD83 / Tennessee"
    },
    "32137": {
        "bbox": {
            "east_longitude": -99.99,
            "north_latitude": 36.5,
            "south_latitude": 34.3,
            "west_longitude": -103.03
        },
        "id": {
            "authority": "EPSG",
            "code": 32137
        },
        "name": "NAD83 / Texas North"
    },
    "32138": {
        "bbox": {
            "east_longitude": -94,
            "north_latitude": 34.58,
            "south_latitude": 31.72,
            "west_longitude": -103.07
        },
        "id": {
            "authority": "EPSG",
            "code": 32138
        },
        "name": "NAD83 / Texas North Central"
    },
    "32139": {
        "bbox": {
            "east_longitude": -93.5,
            "north_latitude": 32.27,
            "south_latitude": 29.78,
            "west_longitude": -106.66
        },
        "id": {
            "authority": "EPSG",
            "code": 32139
        },
        "name": "NAD83 / Texas Central"
    },
    "32140": {
        "bbox": {
            "east_longitude": -93.76,
            "north_latitude": 30.67,
            "south_latitude": 27.78,
            "west_longitude": -105
        },
        "id": {
            "authority": "EPSG",
            "code": 32140
        },
        "name": "NAD83 / Texas South Central"
    },
    "32141": {
        "bbox": {
            "east_longitude": -96.85,
            "north_latitude": 28.21,
            "south_latitude": 25.83,
            "west_longitude": -100.2
        },
        "id": {
            "authority": "EPSG",
            "code": 32141
        },
        "name": "NAD83 / Texas South"
    },
    "32142": {
        "bbox": {
            "east_longitude": -109.04,
            "north_latitude": 42.01,
            "south_latitude": 40.55,
            "west_longitude": -114.04
        },
        "id": {
            "authority": "EPSG",
            "code": 32142
        },
        "name": "NAD83 / Utah North"
    },
    "32143": {
        "bbox": {
            "east_longitude": -109.04,
            "north_latitude": 41.08,
            "south_latitude": 38.49,
            "west_longitude": -114.05
        },
        "id": {
            "authority": "EPSG",
            "code": 32143
        },
        "name": "NAD83 / Utah Central"
    },
    "32144": {
        "bbox": {
            "east_longitude": -109.04,
            "north_latitude": 38.58,
            "south_latitude": 36.99,
            "west_longitude": -114.05
        },
        "id": {
            "authority": "EPSG",
            "code": 32144
        },
        "name": "NAD83 / Utah South"
    },
    "32145": {
        "bbox": {
            "east_longitude": -71.5,
            "north_latitude": 45.03,
            "south_latitude": 42.72,
            "west_longitude": -73.44
        },
        "id": {
            "authority": "EPSG",
            "code": 32145
        },
        "name": "NAD83 / Vermont"
    },
    "32146": {
        "bbox": {
            "east_longitude": -76.51,
            "north_latitude": 39.46,
            "south_latitude": 37.77,
            "west_longitude": -80.06
        },
        "id": {
            "authority": "EPSG",
            "code": 32146
        },
        "name": "NAD83 / Virginia North"
    },
    "32147": {
        "bbox": {
            "east_longitude": -75.31,
            "north_latitude": 38.28,
            "south_latitude": 36.54,
            "west_longitude": -83.68
        },
        "id": {
            "authority": "EPSG",
            "code": 32147
        },
        "name": "NAD83 / Virginia South"
    },
    "32148": {
        "bbox": {
            "east_longitude": -117.02,
            "north_latitude": 49.05,
            "south_latitude": 47.08,
            "west_longitude": -124.79
        },
        "id": {
            "authority": "EPSG",
            "code": 32148
        },
        "name": "NAD83 / Washington North"
    },
    "32149": {
        "bbox": {
            "east_longitude": -116.91,
            "north_latitude": 47.61,
            "south_latitude": 45.54,
            "west_longitude": -124.4
        },
        "id": {
            "authority": "EPSG",
            "code": 32149
        },
        "name": "NAD83 / Washington South"
    },
    "32150": {
        "bbox": {
            "east_longitude": -77.72,
            "north_latitude": 40.64,
            "south_latitude": 38.76,
            "west_longitude": -81.76
        },
        "id": {
            "authority": "EPSG",
            "code": 32150
        },
        "name": "NAD83 / West Virginia North"
    },
    "32151": {
        "bbox": {
            "east_longitude": -79.05,
            "north_latitude": 39.17,
            "south_latitude": 37.2,
            "west_longitude": -82.65
        },
        "id": {
            "authority": "EPSG",
            "code": 32151
        },
        "name": "NAD83 / West Virginia South"
    },
    "32152": {
        "bbox": {
            "east_longitude": -88.05,
            "north_latitude": 47.31,
            "south_latitude": 45.37,
            "west_longitude": -92.89
        },
        "id": {
            "authority": "EPSG",
            "code": 32152
        },
        "name": "NAD83 / Wisconsin North"
    },
    "32153": {
        "bbox": {
            "east_longitude": -86.25,
            "north_latitude": 45.8,
            "south_latitude": 43.98,
            "west_longitude": -92.89
        },
        "id": {
            "authority": "EPSG",
            "code": 32153
        },
        "name": "NAD83 / Wisconsin Central"
    },
    "32154": {
        "bbox": {
            "east_longitude": -86.95,
            "north_latitude": 44.33,
            "south_latitude": 42.48,
            "west_longitude": -91.43
        },
        "id": {
            "authority": "EPSG",
            "code": 32154
        },
        "name": "NAD83 / Wisconsin South"
    },
    "32155": {
        "bbox": {
            "east_longitude": -104.05,
            "north_latitude": 45.01,
            "south_latitude": 40.99,
            "west_longitude": -106.33
        },
        "id": {
            "authority": "EPSG",
            "code": 32155
        },
        "name": "NAD83 / Wyoming East"
    },
    "32156": {
        "bbox": {
            "east_longitude": -106,
            "north_latitude": 45.01,
            "south_latitude": 40.99,
            "west_longitude": -108.63
        },
        "id": {
            "authority": "EPSG",
            "code": 32156
        },
        "name": "NAD83 / Wyoming East Central"
    },
    "32157": {
        "bbox": {
            "east_longitude": -107.5,
            "north_latitude": 45.01,
            "south_latitude": 40.99,
            "west_longitude": -111.06
        },
        "id": {
            "authority": "EPSG",
            "code": 32157
        },
        "name": "NAD83 / Wyoming West Central"
    },
    "32158": {
        "bbox": {
            "east_longitude": -109.04,
            "north_latitude": 44.67,
            "south_latitude": 40.99,
            "west_longitude": -111.06
        },
        "id": {
            "authority": "EPSG",
            "code": 32158
        },
        "name": "NAD83 / Wyoming West"
    },
    "32199": {
        "bbox": {
            "east_longitude": -88.75,
            "north_latitude": 33.03,
            "south_latitude": 28.85,
            "west_longitude": -94.05
        },
        "id": {
            "authority": "EPSG",
            "code": 32199
        },
        "name": "NAD83 / Louisiana Offshore"
    },
    "32601": {
        "bbox": {
            "east_longitude": -174,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -180
        },
        "id": {
            "authority": "EPSG",
            "code": 32601
        },
        "name": "WGS 84 / UTM zone 1N"
    },
    "32602": {
        "bbox": {
            "east_longitude": -168,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -174
        },
        "id": {
            "authority": "EPSG",
            "code": 32602
        },
        "name": "WGS 84 / UTM zone 2N"
    },
    "32603": {
        "bbox": {
            "east_longitude": -162,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -168
        },
        "id": {
            "authority": "EPSG",
            "code": 32603
        },
        "name": "WGS 84 / UTM zone 3N"
    },
    "32604": {
        "bbox": {
            "east_longitude": -156,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -162
        },
        "id": {
            "authority": "EPSG",
            "code": 32604
        },
        "name": "WGS 84 / UTM zone 4N"
    },
    "32605": {
        "bbox": {
            "east_longitude": -150,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -156
        },
        "id": {
            "authority": "EPSG",
            "code": 32605
        },
        "name": "WGS 84 / UTM zone 5N"
    },
    "32606": {
        "bbox": {
            "east_longitude": -144,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -150
        },
        "id": {
            "authority": "EPSG",
            "code": 32606
        },
        "name": "WGS 84 / UTM zone 6N"
    },
    "32607": {
        "bbox": {
            "east_longitude": -138,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -144
        },
        "id": {
            "authority": "EPSG",
            "code": 32607
        },
        "name": "WGS 84 / UTM zone 7N"
    },
    "32608": {
        "bbox": {
            "east_longitude": -132,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -138
        },
        "id": {
            "authority": "EPSG",
            "code": 32608
        },
        "name": "WGS 84 / UTM zone 8N"
    },
    "32609": {
        "bbox": {
            "east_longitude": -126,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -132
        },
        "id": {
            "authority": "EPSG",
            "code": 32609
        },
        "name": "WGS 84 / UTM zone 9N"
    },
    "32610": {
        "bbox": {
            "east_longitude": -120,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -126
        },
        "id": {
            "authority": "EPSG",
            "code": 32610
        },
        "name": "WGS 84 / UTM zone 10N"
    },
    "32611": {
        "bbox": {
            "east_longitude": -114,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -120
        },
        "id": {
            "authority": "EPSG",
            "code": 32611
        },
        "name": "WGS 84 / UTM zone 11N"
    },
    "32612": {
        "bbox": {
            "east_longitude": -108,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -114
        },
        "id": {
            "authority": "EPSG",
            "code": 32612
        },
        "name": "WGS 84 / UTM zone 12N"
    },
    "32613": {
        "bbox": {
            "east_longitude": -102,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -108
        },
        "id": {
            "authority": "EPSG",
            "code": 32613
        },
        "name": "WGS 84 / UTM zone 13N"
    },
    "32614": {
        "bbox": {
            "east_longitude": -96,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -102
        },
        "id": {
            "authority": "EPSG",
            "code": 32614
        },
        "name": "WGS 84 / UTM zone 14N"
    },
    "32615": {
        "bbox": {
            "east_longitude": -90,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -96
        },
        "id": {
            "authority": "EPSG",
            "code": 32615
        },
        "name": "WGS 84 / UTM zone 15N"
    },
    "32616": {
        "bbox": {
            "east_longitude": -84,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -90
        },
        "id": {
            "authority": "EPSG",
            "code": 32616
        },
        "name": "WGS 84 / UTM zone 16N"
    },
    "32617": {
        "bbox": {
            "east_longitude": -78,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -84
        },
        "id": {
            "authority": "EPSG",
            "code": 32617
        },
        "name": "WGS 84 / UTM zone 17N"
    },
    "32618": {
        "bbox": {
            "east_longitude": -72,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -78
        },
        "id": {
            "authority": "EPSG",
            "code": 32618
        },
        "name": "WGS 84 / UTM zone 18N"
    },
    "32619": {
        "bbox": {
            "east_longitude": -66,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -72
        },
        "id": {
            "authority": "EPSG",
            "code": 32619
        },
        "name": "WGS 84 / UTM zone 19N"
    },
    "32620": {
        "bbox": {
            "east_longitude": -60,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -66
        },
        "id": {
            "authority": "EPSG",
            "code": 32620
        },
        "name": "WGS 84 / UTM zone 20N"
    },
    "32621": {
        "bbox": {
            "east_longitude": -54,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -60
        },
        "id": {
            "authority": "EPSG",
            "code": 32621
        },
        "name": "WGS 84 / UTM zone 21N"
    },
    "32622": {
        "bbox": {
            "east_longitude": -48,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -54
        },
        "id": {
            "authority": "EPSG",
            "code": 32622
        },
        "name": "WGS 84 / UTM zone 22N"
    },
    "32623": {
        "bbox": {
            "east_longitude": -42,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -48
        },
        "id": {
            "authority": "EPSG",
            "code": 32623
        },
        "name": "WGS 84 / UTM zone 23N"
    },
    "32624": {
        "bbox": {
            "east_longitude": -36,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -42
        },
        "id": {
            "authority": "EPSG",
            "code": 32624
        },
        "name": "WGS 84 / UTM zone 24N"
    },
    "32625": {
        "bbox": {
            "east_longitude": -30,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -36
        },
        "id": {
            "authority": "EPSG",
            "code": 32625
        },
        "name": "WGS 84 / UTM zone 25N"
    },
    "32626": {
        "bbox": {
            "east_longitude": -24,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -30
        },
        "id": {
            "authority": "EPSG",
            "code": 32626
        },
        "name": "WGS 84 / UTM zone 26N"
    },
    "32627": {
        "bbox": {
            "east_longitude": -18,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -24
        },
        "id": {
            "authority": "EPSG",
            "code": 32627
        },
        "name": "WGS 84 / UTM zone 27N"
    },
    "32628": {
        "bbox": {
            "east_longitude": -12,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -18
        },
        "id": {
            "authority": "EPSG",
            "code": 32628
        },
        "name": "WGS 84 / UTM zone 28N"
    },
    "32629": {
        "bbox": {
            "east_longitude": -6,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -12
        },
        "id": {
            "authority": "EPSG",
            "code": 32629
        },
        "name": "WGS 84 / UTM zone 29N"
    },
    "32630": {
        "bbox": {
            "east_longitude": 0,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": -6
        },
        "id": {
            "authority": "EPSG",
            "code": 32630
        },
        "name": "WGS 84 / UTM zone 30N"
    },
    "32631": {
        "bbox": {
            "east_longitude": 6,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 0
        },
        "id": {
            "authority": "EPSG",
            "code": 32631
        },
        "name": "WGS 84 / UTM zone 31N"
    },
    "32632": {
        "bbox": {
            "east_longitude": 12,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 6
        },
        "id": {
            "authority": "EPSG",
            "code": 32632
        },
        "name": "WGS 84 / UTM zone 32N"
    },
    "32633": {
        "bbox": {
            "east_longitude": 18,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 12
        },
        "id": {
            "authority": "EPSG",
            "code": 32633
        },
        "name": "WGS 84 / UTM zone 33N"
    },
    "32634": {
        "bbox": {
            "east_longitude": 24,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 18
        },
        "id": {
            "authority": "EPSG",
            "code": 32634
        },
        "name": "WGS 84 / UTM zone 34N"
    },
    "32635": {
        "bbox": {
            "east_longitude": 30,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 24
        },
        "id": {
            "authority": "EPSG",
            "code": 32635
        },
        "name": "WGS 84 / UTM zone 35N"
    },
    "32636": {
        "bbox": {
            "east_longitude": 36,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 30
        },
        "id": {
            "authority": "EPSG",
            "code": 32636
        },
        "name": "WGS 84 / UTM zone 36N"
    },
    "32637": {
        "bbox": {
            "east_longitude": 42,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 36
        },
        "id": {
            "authority": "EPSG",
            "code": 32637
        },
        "name": "WGS 84 / UTM zone 37N"
    },
    "32638": {
        "bbox": {
            "east_longitude": 48,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 42
        },
        "id": {
            "authority": "EPSG",
            "code": 32638
        },
        "name": "WGS 84 / UTM zone 38N"
    },
    "32639": {
        "bbox": {
            "east_longitude": 54,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 48
        },
        "id": {
            "authority": "EPSG",
            "code": 32639
        },
        "name": "WGS 84 / UTM zone 39N"
    },
    "32640": {
        "bbox": {
            "east_longitude": 60,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 54
        },
        "id": {
            "authority": "EPSG",
            "code": 32640
        },
        "name": "WGS 84 / UTM zone 40N"
    },
    "32641": {
        "bbox": {
            "east_longitude": 66,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 60
        },
        "id": {
            "authority": "EPSG",
            "code": 32641
        },
        "name": "WGS 84 / UTM zone 41N"
    },
    "32642": {
        "bbox": {
            "east_longitude": 72,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 66
        },
        "id": {
            "authority": "EPSG",
            "code": 32642
        },
        "name": "WGS 84 / UTM zone 42N"
    },
    "32643": {
        "bbox": {
            "east_longitude": 78,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 72
        },
        "id": {
            "authority": "EPSG",
            "code": 32643
        },
        "name": "WGS 84 / UTM zone 43N"
    },
    "32644": {
        "bbox": {
            "east_longitude": 84,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 78
        },
        "id": {
            "authority": "EPSG",
            "code": 32644
        },
        "name": "WGS 84 / UTM zone 44N"
    },
    "32645": {
        "bbox": {
            "east_longitude": 90,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 84
        },
        "id": {
            "authority": "EPSG",
            "code": 32645
        },
        "name": "WGS 84 / UTM zone 45N"
    },
    "32646": {
        "bbox": {
            "east_longitude": 96,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 90
        },
        "id": {
            "authority": "EPSG",
            "code": 32646
        },
        "name": "WGS 84 / UTM zone 46N"
    },
    "32647": {
        "bbox": {
            "east_longitude": 102,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 96
        },
        "id": {
            "authority": "EPSG",
            "code": 32647
        },
        "name": "WGS 84 / UTM zone 47N"
    },
    "32648": {
        "bbox": {
            "east_longitude": 108,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 102
        },
        "id": {
            "authority": "EPSG",
            "code": 32648
        },
        "name": "WGS 84 / UTM zone 48N"
    },
    "32649": {
        "bbox": {
            "east_longitude": 114,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 108
        },
        "id": {
            "authority": "EPSG",
            "code": 32649
        },
        "name": "WGS 84 / UTM zone 49N"
    },
    "32650": {
        "bbox": {
            "east_longitude": 120,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 114
        },
        "id": {
            "authority": "EPSG",
            "code": 32650
        },
        "name": "WGS 84 / UTM zone 50N"
    },
    "32651": {
        "bbox": {
            "east_longitude": 126,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 120
        },
        "id": {
            "authority": "EPSG",
            "code": 32651
        },
        "name": "WGS 84 / UTM zone 51N"
    },
    "32652": {
        "bbox": {
            "east_longitude": 132,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 126
        },
        "id": {
            "authority": "EPSG",
            "code": 32652
        },
        "name": "WGS 84 / UTM zone 52N"
    },
    "32653": {
        "bbox": {
            "east_longitude": 138,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 132
        },
        "id": {
            "authority": "EPSG",
            "code": 32653
        },
        "name": "WGS 84 / UTM zone 53N"
    },
    "32654": {
        "bbox": {
            "east_longitude": 144,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 138
        },
        "id": {
            "authority": "EPSG",
            "code": 32654
        },
        "name": "WGS 84 / UTM zone 54N"
    },
    "32655": {
        "bbox": {
            "east_longitude": 150,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 144
        },
        "id": {
            "authority": "EPSG",
            "code": 32655
        },
        "name": "WGS 84 / UTM zone 55N"
    },
    "32656": {
        "bbox": {
            "east_longitude": 156,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 150
        },
        "id": {
            "authority": "EPSG",
            "code": 32656
        },
        "name": "WGS 84 / UTM zone 56N"
    },
    "32657": {
        "bbox": {
            "east_longitude": 162,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 156
        },
        "id": {
            "authority": "EPSG",
            "code": 32657
        },
        "name": "WGS 84 / UTM zone 57N"
    },
    "32658": {
        "bbox": {
            "east_longitude": 168,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 162
        },
        "id": {
            "authority": "EPSG",
            "code": 32658
        },
        "name": "WGS 84 / UTM zone 58N"
    },
    "32659": {
        "bbox": {
            "east_longitude": 174,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 168
        },
        "id": {
            "authority": "EPSG",
            "code": 32659
        },
        "name": "WGS 84 / UTM zone 59N"
    },
    "32660": {
        "bbox": {
            "east_longitude": 180,
            "north_latitude": 84,
            "south_latitude": 0,
            "west_longitude": 174
        },
        "id": {
            "authority": "EPSG",
            "code": 32660
        },
        "name": "WGS 84 / UTM zone 60N"
    },
    "32701": {
        "bbox": {
            "east_longitude": -174,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -180
        },
        "id": {
            "authority": "EPSG",
            "code": 32701
        },
        "name": "WGS 84 / UTM zone 1S"
    },
    "32702": {
        "bbox": {
            "east_longitude": -168,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -174
        },
        "id": {
            "authority": "EPSG",
            "code": 32702
        },
        "name": "WGS 84 / UTM zone 2S"
    },
    "32703": {
        "bbox": {
            "east_longitude": -162,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -168
        },
        "id": {
            "authority": "EPSG",
            "code": 32703
        },
        "name": "WGS 84 / UTM zone 3S"
    },
    "32704": {
        "bbox": {
            "east_longitude": -156,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -162
        },
        "id": {
            "authority": "EPSG",
            "code": 32704
        },
        "name": "WGS 84 / UTM zone 4S"
    },
    "32705": {
        "bbox": {
            "east_longitude": -150,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -156
        },
        "id": {
            "authority": "EPSG",
            "code": 32705
        },
        "name": "WGS 84 / UTM zone 5S"
    },
    "32706": {
        "bbox": {
            "east_longitude": -144,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -150
        },
        "id": {
            "authority": "EPSG",
            "code": 32706
        },
        "name": "WGS 84 / UTM zone 6S"
    },
    "32707": {
        "bbox": {
            "east_longitude": -138,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -144
        },
        "id": {
            "authority": "EPSG",
            "code": 32707
        },
        "name": "WGS 84 / UTM zone 7S"
    },
    "32708": {
        "bbox": {
            "east_longitude": -132,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -138
        },
        "id": {
            "authority": "EPSG",
            "code": 32708
        },
        "name": "WGS 84 / UTM zone 8S"
    },
    "32709": {
        "bbox": {
            "east_longitude": -126,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -132
        },
        "id": {
            "authority": "EPSG",
            "code": 32709
        },
        "name": "WGS 84 / UTM zone 9S"
    },
    "32710": {
        "bbox": {
            "east_longitude": -120,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -126
        },
        "id": {
            "authority": "EPSG",
            "code": 32710
        },
        "name": "WGS 84 / UTM zone 10S"
    },
    "32711": {
        "bbox": {
            "east_longitude": -114,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -120
        },
        "id": {
            "authority": "EPSG",
            "code": 32711
        },
        "name": "WGS 84 / UTM zone 11S"
    },
    "32712": {
        "bbox": {
            "east_longitude": -108,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -114
        },
        "id": {
            "authority": "EPSG",
            "code": 32712
        },
        "name": "WGS 84 / UTM zone 12S"
    },
    "32713": {
        "bbox": {
            "east_longitude": -102,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -108
        },
        "id": {
            "authority": "EPSG",
            "code": 32713
        },
        "name": "WGS 84 / UTM zone 13S"
    },
    "32714": {
        "bbox": {
            "east_longitude": -96,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -102
        },
        "id": {
            "authority": "EPSG",
            "code": 32714
        },
        "name": "WGS 84 / UTM zone 14S"
    },
    "32715": {
        "bbox": {
            "east_longitude": -90,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -96
        },
        "id": {
            "authority": "EPSG",
            "code": 32715
        },
        "name": "WGS 84 / UTM zone 15S"
    },
    "32716": {
        "bbox": {
            "east_longitude": -84,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -90
        },
        "id": {
            "authority": "EPSG",
            "code": 32716
        },
        "name": "WGS 84 / UTM zone 16S"
    },
    "32717": {
        "bbox": {
            "east_longitude": -78,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -84
        },
        "id": {
            "authority": "EPSG",
            "code": 32717
        },
        "name": "WGS 84 / UTM zone 17S"
    },
    "32718": {
        "bbox": {
            "east_longitude": -72,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -78
        },
        "id": {
            "authority": "EPSG",
            "code": 32718
        },
        "name": "WGS 84 / UTM zone 18S"
    },
    "32719": {
        "bbox": {
            "east_longitude": -66,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -72
        },
        "id": {
            "authority": "EPSG",
            "code": 32719
        },
        "name": "WGS 84 / UTM zone 19S"
    },
    "32720": {
        "bbox": {
            "east_longitude": -60,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -66
        },
        "id": {
            "authority": "EPSG",
            "code": 32720
        },
        "name": "WGS 84 / UTM zone 20S"
    },
    "32721": {
        "bbox": {
            "east_longitude": -54,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -60
        },
        "id": {
            "authority": "EPSG",
            "code": 32721
        },
        "name": "WGS 84 / UTM zone 21S"
    },
    "32722": {
        "bbox": {
            "east_longitude": -48,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -54
        },
        "id": {
            "authority": "EPSG",
            "code": 32722
        },
        "name": "WGS 84 / UTM zone 22S"
    },
    "32723": {
        "bbox": {
            "east_longitude": -42,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -48
        },
        "id": {
            "authority": "EPSG",
            "code": 32723
        },
        "name": "WGS 84 / UTM zone 23S"
    },
    "32724": {
        "bbox": {
            "east_longitude": -36,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -42
        },
        "id": {
            "authority": "EPSG",
            "code": 32724
        },
        "name": "WGS 84 / UTM zone 24S"
    },
    "32725": {
        "bbox": {
            "east_longitude": -30,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -36
        },
        "id": {
            "authority": "EPSG",
            "code": 32725
        },
        "name": "WGS 84 / UTM zone 25S"
    },
    "32726": {
        "bbox": {
            "east_longitude": -24,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -30
        },
        "id": {
            "authority": "EPSG",
            "code": 32726
        },
        "name": "WGS 84 / UTM zone 26S"
    },
    "32727": {
        "bbox": {
            "east_longitude": -18,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -24
        },
        "id": {
            "authority": "EPSG",
            "code": 32727
        },
        "name": "WGS 84 / UTM zone 27S"
    },
    "32728": {
        "bbox": {
            "east_longitude": -12,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -18
        },
        "id": {
            "authority": "EPSG",
            "code": 32728
        },
        "name": "WGS 84 / UTM zone 28S"
    },
    "32729": {
        "bbox": {
            "east_longitude": -6,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -12
        },
        "id": {
            "authority": "EPSG",
            "code": 32729
        },
        "name": "WGS 84 / UTM zone 29S"
    },
    "32730": {
        "bbox": {
            "east_longitude": 0,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": -6
        },
        "id": {
            "authority": "EPSG",
            "code": 32730
        },
        "name": "WGS 84 / UTM zone 30S"
    },
    "32731": {
        "bbox": {
            "east_longitude": 6,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 0
        },
        "id": {
            "authority": "EPSG",
            "code": 32731
        },
        "name": "WGS 84 / UTM zone 31S"
    },
    "32732": {
        "bbox": {
            "east_longitude": 12,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 6
        },
        "id": {
            "authority": "EPSG",
            "code": 32732
        },
        "name": "WGS 84 / UTM zone 32S"
    },
    "32733": {
        "bbox": {
            "east_longitude": 18,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 12
        },
        "id": {
            "authority": "EPSG",
            "code": 32733
        },
        "name": "WGS 84 / UTM zone 33S"
    },
    "32734": {
        "bbox": {
            "east_longitude": 24,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 18
        },
        "id": {
            "authority": "EPSG",
            "code": 32734
        },
        "name": "WGS 84 / UTM zone 34S"
    },
    "32735": {
        "bbox": {
            "east_longitude": 30,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 24
        },
        "id": {
            "authority": "EPSG",
            "code": 32735
        },
        "name": "WGS 84 / UTM zone 35S"
    },
    "32736": {
        "bbox": {
            "east_longitude": 36,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 30
        },
        "id": {
            "authority": "EPSG",
            "code": 32736
        },
        "name": "WGS 84 / UTM zone 36S"
    },
    "32737": {
        "bbox": {
            "east_longitude": 42,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 36
        },
        "id": {
            "authority": "EPSG",
            "code": 32737
        },
        "name": "WGS 84 / UTM zone 37S"
    },
    "32738": {
        "bbox": {
            "east_longitude": 48,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 42
        },
        "id": {
            "authority": "EPSG",
            "code": 32738
        },
        "name": "WGS 84 / UTM zone 38S"
    },
    "32739": {
        "bbox": {
            "east_longitude": 54,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 48
        },
        "id": {
            "authority": "EPSG",
            "code": 32739
        },
        "name": "WGS 84 / UTM zone 39S"
    },
    "32740": {
        "bbox": {
            "east_longitude": 60,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 54
        },
        "id": {
            "authority": "EPSG",
            "code": 32740
        },
        "name": "WGS 84 / UTM zone 40S"
    },
    "32741": {
        "bbox": {
            "east_longitude": 66,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 60
        },
        "id": {
            "authority": "EPSG",
            "code": 32741
        },
        "name": "WGS 84 / UTM zone 41S"
    },
    "32742": {
        "bbox": {
            "east_longitude": 72,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 66
        },
        "id": {
            "authority": "EPSG",
            "code": 32742
        },
        "name": "WGS 84 / UTM zone 42S"
    },
    "32743": {
        "bbox": {
            "east_longitude": 78,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 72
        },
        "id": {
            "authority": "EPSG",
            "code": 32743
        },
        "name": "WGS 84 / UTM zone 43S"
    },
    "32744": {
        "bbox": {
            "east_longitude": 84,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 78
        },
        "id": {
            "authority": "EPSG",
            "code": 32744
        },
        "name": "WGS 84 / UTM zone 44S"
    },
    "32745": {
        "bbox": {
            "east_longitude": 90,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 84
        },
        "id": {
            "authority": "EPSG",
            "code": 32745
        },
        "name": "WGS 84 / UTM zone 45S"
    },
    "32746": {
        "bbox": {
            "east_longitude": 96,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 90
        },
        "id": {
            "authority": "EPSG",
            "code": 32746
        },
        "name": "WGS 84 / UTM zone 46S"
    },
    "32747": {
        "bbox": {
            "east_longitude": 102,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 96
        },
        "id": {
            "authority": "EPSG",
            "code": 32747
        },
        "name": "WGS 84 / UTM zone 47S"
    },
    "32748": {
        "bbox": {
            "east_longitude": 108,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 102
        },
        "id": {
            "authority": "EPSG",
            "code": 32748
        },
        "name": "WGS 84 / UTM zone 48S"
    },
    "32749": {
        "bbox": {
            "east_longitude": 114,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 108
        },
        "id": {
            "authority": "EPSG",
            "code": 32749
        },
        "name": "WGS 84 / UTM zone 49S"
    },
    "32750": {
        "bbox": {
            "east_longitude": 120,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 114
        },
        "id": {
            "authority": "EPSG",
            "code": 32750
        },
        "name": "WGS 84 / UTM zone 50S"
    },
    "32751": {
        "bbox": {
            "east_longitude": 126,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 120
        },
        "id": {
            "authority": "EPSG",
            "code": 32751
        },
        "name": "WGS 84 / UTM zone 51S"
    },
    "32752": {
        "bbox": {
            "east_longitude": 132,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 126
        },
        "id": {
            "authority": "EPSG",
            "code": 32752
        },
        "name": "WGS 84 / UTM zone 52S"
    },
    "32753": {
        "bbox": {
            "east_longitude": 138,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 132
        },
        "id": {
            "authority": "EPSG",
            "code": 32753
        },
        "name": "WGS 84 / UTM zone 53S"
    },
    "32754": {
        "bbox": {
            "east_longitude": 144,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 138
        },
        "id": {
            "authority": "EPSG",
            "code": 32754
        },
        "name": "WGS 84 / UTM zone 54S"
    },
    "32755": {
        "bbox": {
            "east_longitude": 150,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 144
        },
        "id": {
            "authority": "EPSG",
            "code": 32755
        },
        "name": "WGS 84 / UTM zone 55S"
    },
    "32756": {
        "bbox": {
            "east_longitude": 156,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 150
        },
        "id": {
            "authority": "EPSG",
            "code": 32756
        },
        "name": "WGS 84 / UTM zone 56S"
    },
    "32757": {
        "bbox": {
            "east_longitude": 162,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 156
        },
        "id": {
            "authority": "EPSG",
            "code": 32757
        },
        "name": "WGS 84 / UTM zone 57S"
    },
    "32758": {
        "bbox": {
            "east_longitude": 168,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 162
        },
        "id": {
            "authority": "EPSG",
            "code": 32758
        },
        "name": "WGS 84 / UTM zone 58S"
    },
    "32759": {
        "bbox": {
            "east_longitude": 174,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 168
        },
        "id": {
            "authority": "EPSG",
            "code": 32759
        },
        "name": "WGS 84 / UTM zone 59S"
    },
    "32760": {
        "bbox": {
            "east_longitude": 180,
            "north_latitude": 0,
            "south_latitude": -80,
            "west_longitude": 174
        },
        "id": {
            "authority": "EPSG",
            "code": 32760
        },
        "name": "WGS 84 / UTM zone 60S"
    },
    "102229": {
        "bbox": {
            "east_longitude": -84.89,
            "north_latitude": 35,
            "south_latitude": 30.99,
            "west_longitude": -86.79
        },
        "id": {
            "authority": "ESRI",
            "code": 102229
        },
        "name": "NAD83 StatePlane Alabama East"
    },
    "102230": {
        "bbox": {
            "east_longitude": -86.3,
            "north_latitude": 35.02,
            "south_latitude": 30.14,
            "west_longitude": -88.48
        },
        "id": {
            "authority": "ESRI",
            "code": 102230
        },
        "name": "NAD83 StatePlane Alabama West"
    },
    "102241": {
        "bbox": {
            "east_longitude": -119.99,
            "north_latitude": 42.01,
            "south_latitude": 39.59,
            "west_longitude": -124.45
        },
        "id": {
            "authority": "ESRI",
            "code": 102241
        },
        "name": "NAD83 StatePlane California I"
    },
    "102242": {
        "bbox": {
            "east_longitude": -119.54,
            "north_latitude": 40.16,
            "south_latitude": 38.02,
            "west_longitude": -124.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102242
        },
        "name": "NAD83 StatePlane California II"
    },
    "102243": {
        "bbox": {
            "east_longitude": -117.83,
            "north_latitude": 38.71,
            "south_latitude": 36.73,
            "west_longitude": -123.02
        },
        "id": {
            "authority": "ESRI",
            "code": 102243
        },
        "name": "NAD83 StatePlane California III"
    },
    "102244": {
        "bbox": {
            "east_longitude": -115.62,
            "north_latitude": 37.58,
            "south_latitude": 35.78,
            "west_longitude": -122.01
        },
        "id": {
            "authority": "ESRI",
            "code": 102244
        },
        "name": "NAD83 StatePlane California IV"
    },
    "102245": {
        "bbox": {
            "east_longitude": -114.12,
            "north_latitude": 35.81,
            "south_latitude": 32.76,
            "west_longitude": -121.42
        },
        "id": {
            "authority": "ESRI",
            "code": 102245
        },
        "name": "NAD83 StatePlane California V"
    },
    "102246": {
        "bbox": {
            "east_longitude": -114.42,
            "north_latitude": 34.08,
            "south_latitude": 32.53,
            "west_longitude": -118.15
        },
        "id": {
            "authority": "ESRI",
            "code": 102246
        },
        "name": "NAD83 StatePlane California VI"
    },
    "102248": {
        "bbox": {
            "east_longitude": -109.04,
            "north_latitude": 37.01,
            "south_latitude": 31.33,
            "west_longitude": -111.71
        },
        "id": {
            "authority": "ESRI",
            "code": 102248
        },
        "name": "NAD83 StatePlane Arizona East"
    },
    "102249": {
        "bbox": {
            "east_longitude": -110.44,
            "north_latitude": 37.01,
            "south_latitude": 31.33,
            "west_longitude": -113.35
        },
        "id": {
            "authority": "ESRI",
            "code": 102249
        },
        "name": "NAD83 StatePlane Arizona Central"
    },
    "102250": {
        "bbox": {
            "east_longitude": -112.52,
            "north_latitude": 37,
            "south_latitude": 32.05,
            "west_longitude": -114.81
        },
        "id": {
            "authority": "ESRI",
            "code": 102250
        },
        "name": "NAD83 StatePlane Arizona West"
    },
    "102251": {
        "bbox": {
            "east_longitude": -89.64,
            "north_latitude": 36.5,
            "south_latitude": 34.67,
            "west_longitude": -94.62
        },
        "id": {
            "authority": "ESRI",
            "code": 102251
        },
        "name": "NAD83 StatePlane Arkansas North"
    },
    "102252": {
        "bbox": {
            "east_longitude": -90.4,
            "north_latitude": 35.1,
            "south_latitude": 33.01,
            "west_longitude": -94.48
        },
        "id": {
            "authority": "ESRI",
            "code": 102252
        },
        "name": "NAD83 StatePlane Arkansas South"
    },
    "102253": {
        "bbox": {
            "east_longitude": -102.04,
            "north_latitude": 41.01,
            "south_latitude": 39.56,
            "west_longitude": -109.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102253
        },
        "name": "NAD83 StatePlane Colorado North"
    },
    "102254": {
        "bbox": {
            "east_longitude": -102.04,
            "north_latitude": 40.09,
            "south_latitude": 38.14,
            "west_longitude": -109.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102254
        },
        "name": "NAD83 StatePlane Colorado Central"
    },
    "102255": {
        "bbox": {
            "east_longitude": -102.04,
            "north_latitude": 38.68,
            "south_latitude": 36.98,
            "west_longitude": -109.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102255
        },
        "name": "NAD83 StatePlane Colorado South"
    },
    "102256": {
        "bbox": {
            "east_longitude": -71.78,
            "north_latitude": 42.05,
            "south_latitude": 40.98,
            "west_longitude": -73.73
        },
        "id": {
            "authority": "ESRI",
            "code": 102256
        },
        "name": "NAD83 StatePlane Connecticut"
    },
    "102257": {
        "bbox": {
            "east_longitude": -74.97,
            "north_latitude": 39.85,
            "south_latitude": 38.44,
            "west_longitude": -75.8
        },
        "id": {
            "authority": "ESRI",
            "code": 102257
        },
        "name": "NAD83 StatePlane Delaware"
    },
    "102258": {
        "bbox": {
            "east_longitude": -79.97,
            "north_latitude": 30.83,
            "south_latitude": 24.41,
            "west_longitude": -82.33
        },
        "id": {
            "authority": "ESRI",
            "code": 102258
        },
        "name": "NAD83 StatePlane Florida East"
    },
    "102259": {
        "bbox": {
            "east_longitude": -81.13,
            "north_latitude": 29.6,
            "south_latitude": 26.27,
            "west_longitude": -83.34
        },
        "id": {
            "authority": "ESRI",
            "code": 102259
        },
        "name": "NAD83 StatePlane Florida West"
    },
    "102260": {
        "bbox": {
            "east_longitude": -82.04,
            "north_latitude": 31.01,
            "south_latitude": 29.21,
            "west_longitude": -87.63
        },
        "id": {
            "authority": "ESRI",
            "code": 102260
        },
        "name": "NAD83 StatePlane Florida North"
    },
    "102261": {
        "bbox": {
            "east_longitude": -154.74,
            "north_latitude": 20.33,
            "south_latitude": 18.87,
            "west_longitude": -156.1
        },
        "id": {
            "authority": "ESRI",
            "code": 102261
        },
        "name": "NAD83 StatePlane Hawaii 1"
    },
    "102262": {
        "bbox": {
            "east_longitude": -155.93,
            "north_latitude": 21.26,
            "south_latitude": 20.45,
            "west_longitude": -157.36
        },
        "id": {
            "authority": "ESRI",
            "code": 102262
        },
        "name": "NAD83 StatePlane Hawaii 2"
    },
    "102263": {
        "bbox": {
            "east_longitude": -157.61,
            "north_latitude": 21.75,
            "south_latitude": 21.2,
            "west_longitude": -158.33
        },
        "id": {
            "authority": "ESRI",
            "code": 102263
        },
        "name": "NAD83 StatePlane Hawaii 3"
    },
    "102264": {
        "bbox": {
            "east_longitude": -159.23,
            "north_latitude": 22.29,
            "south_latitude": 21.81,
            "west_longitude": -159.85
        },
        "id": {
            "authority": "ESRI",
            "code": 102264
        },
        "name": "NAD83 StatePlane Hawaii 4"
    },
    "102265": {
        "bbox": {
            "east_longitude": -159.99,
            "north_latitude": 22.07,
            "south_latitude": 21.73,
            "west_longitude": -160.3
        },
        "id": {
            "authority": "ESRI",
            "code": 102265
        },
        "name": "NAD83 StatePlane Hawaii 5"
    },
    "102266": {
        "bbox": {
            "east_longitude": -80.77,
            "north_latitude": 34.68,
            "south_latitude": 30.36,
            "west_longitude": -83.47
        },
        "id": {
            "authority": "ESRI",
            "code": 102266
        },
        "name": "NAD83 StatePlane Georgia East"
    },
    "102267": {
        "bbox": {
            "east_longitude": -82.99,
            "north_latitude": 35.01,
            "south_latitude": 30.62,
            "west_longitude": -85.61
        },
        "id": {
            "authority": "ESRI",
            "code": 102267
        },
        "name": "NAD83 StatePlane Georgia West"
    },
    "102268": {
        "bbox": {
            "east_longitude": -111.04,
            "north_latitude": 44.75,
            "south_latitude": 41.99,
            "west_longitude": -113.24
        },
        "id": {
            "authority": "ESRI",
            "code": 102268
        },
        "name": "NAD83 StatePlane Idaho East"
    },
    "102269": {
        "bbox": {
            "east_longitude": -112.68,
            "north_latitude": 45.7,
            "south_latitude": 41.99,
            "west_longitude": -115.3
        },
        "id": {
            "authority": "ESRI",
            "code": 102269
        },
        "name": "NAD83 StatePlane Idaho Central"
    },
    "102270": {
        "bbox": {
            "east_longitude": -114.32,
            "north_latitude": 49.01,
            "south_latitude": 41.99,
            "west_longitude": -117.24
        },
        "id": {
            "authority": "ESRI",
            "code": 102270
        },
        "name": "NAD83 StatePlane Idaho West"
    },
    "102271": {
        "bbox": {
            "east_longitude": -87.02,
            "north_latitude": 42.5,
            "south_latitude": 37.06,
            "west_longitude": -89.28
        },
        "id": {
            "authority": "ESRI",
            "code": 102271
        },
        "name": "NAD83 StatePlane Illinois East"
    },
    "102272": {
        "bbox": {
            "east_longitude": -88.93,
            "north_latitude": 42.51,
            "south_latitude": 36.98,
            "west_longitude": -91.52
        },
        "id": {
            "authority": "ESRI",
            "code": 102272
        },
        "name": "NAD83 StatePlane Illinois West"
    },
    "102273": {
        "bbox": {
            "east_longitude": -84.78,
            "north_latitude": 41.77,
            "south_latitude": 37.95,
            "west_longitude": -86.59
        },
        "id": {
            "authority": "ESRI",
            "code": 102273
        },
        "name": "NAD83 StatePlane Indiana East"
    },
    "102274": {
        "bbox": {
            "east_longitude": -86.24,
            "north_latitude": 41.77,
            "south_latitude": 37.77,
            "west_longitude": -88.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102274
        },
        "name": "NAD83 StatePlane Indiana West"
    },
    "102277": {
        "bbox": {
            "east_longitude": -94.58,
            "north_latitude": 40.01,
            "south_latitude": 38.52,
            "west_longitude": -102.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102277
        },
        "name": "NAD83 StatePlane Kansas North"
    },
    "102278": {
        "bbox": {
            "east_longitude": -94.6,
            "north_latitude": 38.88,
            "south_latitude": 36.99,
            "west_longitude": -102.05
        },
        "id": {
            "authority": "ESRI",
            "code": 102278
        },
        "name": "NAD83 StatePlane Kansas South"
    },
    "102279": {
        "bbox": {
            "east_longitude": -82.47,
            "north_latitude": 39.15,
            "south_latitude": 37.71,
            "west_longitude": -85.96
        },
        "id": {
            "authority": "ESRI",
            "code": 102279
        },
        "name": "NAD83 StatePlane Kentucky North"
    },
    "102280": {
        "bbox": {
            "east_longitude": -81.95,
            "north_latitude": 38.17,
            "south_latitude": 36.49,
            "west_longitude": -89.57
        },
        "id": {
            "authority": "ESRI",
            "code": 102280
        },
        "name": "NAD83 StatePlane Kentucky South"
    },
    "102281": {
        "bbox": {
            "east_longitude": -90.86,
            "north_latitude": 33.03,
            "south_latitude": 30.85,
            "west_longitude": -94.05
        },
        "id": {
            "authority": "ESRI",
            "code": 102281
        },
        "name": "NAD83 StatePlane Louisiana North"
    },
    "102282": {
        "bbox": {
            "east_longitude": -88.75,
            "north_latitude": 31.07,
            "south_latitude": 28.85,
            "west_longitude": -93.94
        },
        "id": {
            "authority": "ESRI",
            "code": 102282
        },
        "name": "NAD83 StatePlane Louisiana South"
    },
    "102283": {
        "bbox": {
            "east_longitude": -66.91,
            "north_latitude": 47.47,
            "south_latitude": 43.88,
            "west_longitude": -70.03
        },
        "id": {
            "authority": "ESRI",
            "code": 102283
        },
        "name": "NAD83 StatePlane Maine East"
    },
    "102284": {
        "bbox": {
            "east_longitude": -69.26,
            "north_latitude": 46.58,
            "south_latitude": 43.04,
            "west_longitude": -71.09
        },
        "id": {
            "authority": "ESRI",
            "code": 102284
        },
        "name": "NAD83 StatePlane Maine West"
    },
    "102285": {
        "bbox": {
            "east_longitude": -74.97,
            "north_latitude": 39.73,
            "south_latitude": 37.97,
            "west_longitude": -79.49
        },
        "id": {
            "authority": "ESRI",
            "code": 102285
        },
        "name": "NAD83 StatePlane Maryland"
    },
    "102286": {
        "bbox": {
            "east_longitude": -69.86,
            "north_latitude": 42.89,
            "south_latitude": 41.46,
            "west_longitude": -73.5
        },
        "id": {
            "authority": "ESRI",
            "code": 102286
        },
        "name": "NAD83 StatePlane Massachusetts Mainland"
    },
    "102287": {
        "bbox": {
            "east_longitude": -69.89,
            "north_latitude": 41.51,
            "south_latitude": 41.19,
            "west_longitude": -70.91
        },
        "id": {
            "authority": "ESRI",
            "code": 102287
        },
        "name": "NAD83 StatePlane Massachusetts Island"
    },
    "102288": {
        "bbox": {
            "east_longitude": -83.44,
            "north_latitude": 48.32,
            "south_latitude": 45.08,
            "west_longitude": -90.42
        },
        "id": {
            "authority": "ESRI",
            "code": 102288
        },
        "name": "NAD83 StatePlane Michigan North"
    },
    "102289": {
        "bbox": {
            "east_longitude": -82.27,
            "north_latitude": 45.92,
            "south_latitude": 43.8,
            "west_longitude": -87.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102289
        },
        "name": "NAD83 StatePlane Michigan Central"
    },
    "102290": {
        "bbox": {
            "east_longitude": -82.13,
            "north_latitude": 44.22,
            "south_latitude": 41.69,
            "west_longitude": -87.2
        },
        "id": {
            "authority": "ESRI",
            "code": 102290
        },
        "name": "NAD83 StatePlane Michigan South"
    },
    "102291": {
        "bbox": {
            "east_longitude": -89.49,
            "north_latitude": 49.38,
            "south_latitude": 46.64,
            "west_longitude": -97.22
        },
        "id": {
            "authority": "ESRI",
            "code": 102291
        },
        "name": "NAD83 StatePlane Minnesota North"
    },
    "102292": {
        "bbox": {
            "east_longitude": -92.29,
            "north_latitude": 47.48,
            "south_latitude": 45.28,
            "west_longitude": -96.86
        },
        "id": {
            "authority": "ESRI",
            "code": 102292
        },
        "name": "NAD83 StatePlane Minnesota Central"
    },
    "102293": {
        "bbox": {
            "east_longitude": -91.21,
            "north_latitude": 45.59,
            "south_latitude": 43.49,
            "west_longitude": -96.85
        },
        "id": {
            "authority": "ESRI",
            "code": 102293
        },
        "name": "NAD83 StatePlane Minnesota South"
    },
    "102294": {
        "bbox": {
            "east_longitude": -88.09,
            "north_latitude": 35.01,
            "south_latitude": 30.01,
            "west_longitude": -89.97
        },
        "id": {
            "authority": "ESRI",
            "code": 102294
        },
        "name": "NAD83 StatePlane Mississippi East"
    },
    "102295": {
        "bbox": {
            "east_longitude": -89.37,
            "north_latitude": 35.01,
            "south_latitude": 31,
            "west_longitude": -91.65
        },
        "id": {
            "authority": "ESRI",
            "code": 102295
        },
        "name": "NAD83 StatePlane Mississippi West"
    },
    "102296": {
        "bbox": {
            "east_longitude": -89.1,
            "north_latitude": 40.61,
            "south_latitude": 35.98,
            "west_longitude": -91.97
        },
        "id": {
            "authority": "ESRI",
            "code": 102296
        },
        "name": "NAD83 StatePlane Missouri East"
    },
    "102297": {
        "bbox": {
            "east_longitude": -91.41,
            "north_latitude": 40.61,
            "south_latitude": 36.48,
            "west_longitude": -93.79
        },
        "id": {
            "authority": "ESRI",
            "code": 102297
        },
        "name": "NAD83 StatePlane Missouri Central"
    },
    "102298": {
        "bbox": {
            "east_longitude": -93.48,
            "north_latitude": 40.59,
            "south_latitude": 36.48,
            "west_longitude": -95.77
        },
        "id": {
            "authority": "ESRI",
            "code": 102298
        },
        "name": "NAD83 StatePlane Missouri West"
    },
    "102300": {
        "bbox": {
            "east_longitude": -104.04,
            "north_latitude": 49.01,
            "south_latitude": 44.35,
            "west_longitude": -116.07
        },
        "id": {
            "authority": "ESRI",
            "code": 102300
        },
        "name": "NAD83 StatePlane Montana"
    },
    "102304": {
        "bbox": {
            "east_longitude": -95.3,
            "north_latitude": 43.01,
            "south_latitude": 39.99,
            "west_longitude": -104.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102304
        },
        "name": "NAD83 StatePlane Nebraska"
    },
    "102307": {
        "bbox": {
            "east_longitude": -114.03,
            "north_latitude": 42,
            "south_latitude": 34.99,
            "west_longitude": -117.01
        },
        "id": {
            "authority": "ESRI",
            "code": 102307
        },
        "name": "NAD83 StatePlane Nevada East"
    },
    "102308": {
        "bbox": {
            "east_longitude": -114.99,
            "north_latitude": 41,
            "south_latitude": 36,
            "west_longitude": -118.19
        },
        "id": {
            "authority": "ESRI",
            "code": 102308
        },
        "name": "NAD83 StatePlane Nevada Central"
    },
    "102309": {
        "bbox": {
            "east_longitude": -116.99,
            "north_latitude": 42,
            "south_latitude": 36.95,
            "west_longitude": -120
        },
        "id": {
            "authority": "ESRI",
            "code": 102309
        },
        "name": "NAD83 StatePlane Nevada West"
    },
    "102310": {
        "bbox": {
            "east_longitude": -70.63,
            "north_latitude": 45.31,
            "south_latitude": 42.69,
            "west_longitude": -72.56
        },
        "id": {
            "authority": "ESRI",
            "code": 102310
        },
        "name": "NAD83 StatePlane New Hampshire"
    },
    "102311": {
        "bbox": {
            "east_longitude": -73.88,
            "north_latitude": 41.36,
            "south_latitude": 38.87,
            "west_longitude": -75.6
        },
        "id": {
            "authority": "ESRI",
            "code": 102311
        },
        "name": "NAD83 StatePlane New Jersey"
    },
    "102312": {
        "bbox": {
            "east_longitude": -102.99,
            "north_latitude": 37,
            "south_latitude": 32,
            "west_longitude": -105.72
        },
        "id": {
            "authority": "ESRI",
            "code": 102312
        },
        "name": "NAD83 StatePlane New Mexico East"
    },
    "102313": {
        "bbox": {
            "east_longitude": -104.84,
            "north_latitude": 37,
            "south_latitude": 31.78,
            "west_longitude": -107.73
        },
        "id": {
            "authority": "ESRI",
            "code": 102313
        },
        "name": "NAD83 StatePlane New Mexico Central"
    },
    "102314": {
        "bbox": {
            "east_longitude": -106.32,
            "north_latitude": 37,
            "south_latitude": 31.33,
            "west_longitude": -109.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102314
        },
        "name": "NAD83 StatePlane New Mexico West"
    },
    "102315": {
        "bbox": {
            "east_longitude": -73.23,
            "north_latitude": 45.02,
            "south_latitude": 40.88,
            "west_longitude": -75.87
        },
        "id": {
            "authority": "ESRI",
            "code": 102315
        },
        "name": "NAD83 StatePlane New York East"
    },
    "102316": {
        "bbox": {
            "east_longitude": -75.04,
            "north_latitude": 44.41,
            "south_latitude": 41.99,
            "west_longitude": -77.75
        },
        "id": {
            "authority": "ESRI",
            "code": 102316
        },
        "name": "NAD83 StatePlane New York Central"
    },
    "102317": {
        "bbox": {
            "east_longitude": -77.36,
            "north_latitude": 43.64,
            "south_latitude": 41.99,
            "west_longitude": -79.77
        },
        "id": {
            "authority": "ESRI",
            "code": 102317
        },
        "name": "NAD83 StatePlane New York West"
    },
    "102318": {
        "bbox": {
            "east_longitude": -71.8,
            "north_latitude": 41.3,
            "south_latitude": 40.47,
            "west_longitude": -74.26
        },
        "id": {
            "authority": "ESRI",
            "code": 102318
        },
        "name": "NAD83 StatePlane New York Long Island"
    },
    "102320": {
        "bbox": {
            "east_longitude": -96.83,
            "north_latitude": 49.01,
            "south_latitude": 47.15,
            "west_longitude": -104.07
        },
        "id": {
            "authority": "ESRI",
            "code": 102320
        },
        "name": "NAD83 StatePlane North Dakota North"
    },
    "102321": {
        "bbox": {
            "east_longitude": -96.55,
            "north_latitude": 47.83,
            "south_latitude": 45.93,
            "west_longitude": -104.05
        },
        "id": {
            "authority": "ESRI",
            "code": 102321
        },
        "name": "NAD83 StatePlane North Dakota South"
    },
    "102322": {
        "bbox": {
            "east_longitude": -80.51,
            "north_latitude": 42.33,
            "south_latitude": 40.1,
            "west_longitude": -84.81
        },
        "id": {
            "authority": "ESRI",
            "code": 102322
        },
        "name": "NAD83 StatePlane Ohio North"
    },
    "102323": {
        "bbox": {
            "east_longitude": -80.7,
            "north_latitude": 40.36,
            "south_latitude": 38.4,
            "west_longitude": -84.83
        },
        "id": {
            "authority": "ESRI",
            "code": 102323
        },
        "name": "NAD83 StatePlane Ohio South"
    },
    "102324": {
        "bbox": {
            "east_longitude": -94.42,
            "north_latitude": 37.01,
            "south_latitude": 35.27,
            "west_longitude": -103
        },
        "id": {
            "authority": "ESRI",
            "code": 102324
        },
        "name": "NAD83 StatePlane Oklahoma North"
    },
    "102325": {
        "bbox": {
            "east_longitude": -94.42,
            "north_latitude": 35.57,
            "south_latitude": 33.62,
            "west_longitude": -100
        },
        "id": {
            "authority": "ESRI",
            "code": 102325
        },
        "name": "NAD83 StatePlane Oklahoma South"
    },
    "102326": {
        "bbox": {
            "east_longitude": -116.47,
            "north_latitude": 46.26,
            "south_latitude": 43.95,
            "west_longitude": -124.17
        },
        "id": {
            "authority": "ESRI",
            "code": 102326
        },
        "name": "NAD83 StatePlane Oregon North"
    },
    "102327": {
        "bbox": {
            "east_longitude": -116.9,
            "north_latitude": 44.56,
            "south_latitude": 41.98,
            "west_longitude": -124.6
        },
        "id": {
            "authority": "ESRI",
            "code": 102327
        },
        "name": "NAD83 StatePlane Oregon South"
    },
    "102330": {
        "bbox": {
            "east_longitude": -71.08,
            "north_latitude": 42.02,
            "south_latitude": 41.13,
            "west_longitude": -71.85
        },
        "id": {
            "authority": "ESRI",
            "code": 102330
        },
        "name": "NAD83 StatePlane Rhode Island"
    },
    "102334": {
        "bbox": {
            "east_longitude": -96.45,
            "north_latitude": 45.95,
            "south_latitude": 44.14,
            "west_longitude": -104.07
        },
        "id": {
            "authority": "ESRI",
            "code": 102334
        },
        "name": "NAD83 StatePlane South Dakota North"
    },
    "102335": {
        "bbox": {
            "east_longitude": -96.43,
            "north_latitude": 44.79,
            "south_latitude": 42.48,
            "west_longitude": -104.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102335
        },
        "name": "NAD83 StatePlane South Dakota South"
    },
    "102336": {
        "bbox": {
            "east_longitude": -81.65,
            "north_latitude": 36.68,
            "south_latitude": 34.98,
            "west_longitude": -90.31
        },
        "id": {
            "authority": "ESRI",
            "code": 102336
        },
        "name": "NAD83 StatePlane Tennessee"
    },
    "102337": {
        "bbox": {
            "east_longitude": -99.99,
            "north_latitude": 36.5,
            "south_latitude": 34.3,
            "west_longitude": -103.03
        },
        "id": {
            "authority": "ESRI",
            "code": 102337
        },
        "name": "NAD83 StatePlane Texas North"
    },
    "102338": {
        "bbox": {
            "east_longitude": -94,
            "north_latitude": 34.58,
            "south_latitude": 31.72,
            "west_longitude": -103.07
        },
        "id": {
            "authority": "ESRI",
            "code": 102338
        },
        "name": "NAD83 StatePlane Texas North Central"
    },
    "102339": {
        "bbox": {
            "east_longitude": -93.5,
            "north_latitude": 32.27,
            "south_latitude": 29.78,
            "west_longitude": -106.66
        },
        "id": {
            "authority": "ESRI",
            "code": 102339
        },
        "name": "NAD83 StatePlane Texas Central"
    },
    "102340": {
        "bbox": {
            "east_longitude": -93.76,
            "north_latitude": 30.67,
            "south_latitude": 27.78,
            "west_longitude": -105
        },
        "id": {
            "authority": "ESRI",
            "code": 102340
        },
        "name": "NAD83 StatePlane Texas South Central"
    },
    "102341": {
        "bbox": {
            "east_longitude": -96.85,
            "north_latitude": 28.21,
            "south_latitude": 25.83,
            "west_longitude": -100.2
        },
        "id": {
            "authority": "ESRI",
            "code": 102341
        },
        "name": "NAD83 StatePlane Texas South"
    },
    "102342": {
        "bbox": {
            "east_longitude": -109.04,
            "north_latitude": 42.01,
            "south_latitude": 40.55,
            "west_longitude": -114.04
        },
        "id": {
            "authority": "ESRI",
            "code": 102342
        },
        "name": "NAD83 StatePlane Utah North"
    },
    "102343": {
        "bbox": {
            "east_longitude": -109.04,
            "north_latitude": 41.08,
            "south_latitude": 38.49,
            "west_longitude": -114.05
        },
        "id": {
            "authority": "ESRI",
            "code": 102343
        },
        "name": "NAD83 StatePlane Utah Central"
    },
    "102344": {
        "bbox": {
            "east_longitude": -109.04,
            "north_latitude": 38.58,
            "south_latitude": 36.99,
            "west_longitude": -114.05
        },
        "id": {
            "authority": "ESRI",
            "code": 102344
        },
        "name": "NAD83 StatePlane Utah South"
    },
    "102345": {
        "bbox": {
            "east_longitude": -71.5,
            "north_latitude": 45.03,
            "south_latitude": 42.72,
            "west_longitude": -73.44
        },
        "id": {
            "authority": "ESRI",
            "code": 102345
        },
        "name": "NAD83 StatePlane Vermont"
    },
    "102346": {
        "bbox": {
            "east_longitude": -76.51,
            "north_latitude": 39.46,
            "south_latitude": 37.77,
            "west_longitude": -80.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102346
        },
        "name": "NAD83 StatePlane Virginia North"
    },
    "102347": {
        "bbox": {
            "east_longitude": -75.31,
            "north_latitude": 38.28,
            "south_latitude": 36.54,
            "west_longitude": -83.68
        },
        "id": {
            "authority": "ESRI",
            "code": 102347
        },
        "name": "NAD83 StatePlane Virginia South"
    },
    "102348": {
        "bbox": {
            "east_longitude": -117.02,
            "north_latitude": 49.05,
            "south_latitude": 47.08,
            "west_longitude": -124.79
        },
        "id": {
            "authority": "ESRI",
            "code": 102348
        },
        "name": "NAD83 StatePlane Washington North"
    },
    "102349": {
        "bbox": {
            "east_longitude": -116.91,
            "north_latitude": 47.61,
            "south_latitude": 45.54,
            "west_longitude": -124.4
        },
        "id": {
            "authority": "ESRI",
            "code": 102349
        },
        "name": "NAD83 StatePlane Washington South"
    },
    "102350": {
        "bbox": {
            "east_longitude": -77.72,
            "north_latitude": 40.64,
            "south_latitude": 38.76,
            "west_longitude": -81.76
        },
        "id": {
            "authority": "ESRI",
            "code": 102350
        },
        "name": "NAD83 StatePlane West Virginia North"
    },
    "102351": {
        "bbox": {
            "east_longitude": -79.05,
            "north_latitude": 39.17,
            "south_latitude": 37.2,
            "west_longitude": -82.65
        },
        "id": {
            "authority": "ESRI",
            "code": 102351
        },
        "name": "NAD83 StatePlane West Virginia South"
    },
    "102352": {
        "bbox": {
            "east_longitude": -88.05,
            "north_latitude": 47.31,
            "south_latitude": 45.37,
            "west_longitude": -92.89
        },
        "id": {
            "authority": "ESRI",
            "code": 102352
        },
        "name": "NAD83 StatePlane Wisconsin North"
    },
    "102353": {
        "bbox": {
            "east_longitude": -86.25,
            "north_latitude": 45.8,
            "south_latitude": 43.98,
            "west_longitude": -92.89
        },
        "id": {
            "authority": "ESRI",
            "code": 102353
        },
        "name": "NAD83 StatePlane Wisconsin Central"
    },
    "102354": {
        "bbox": {
            "east_longitude": -86.95,
            "north_latitude": 44.33,
            "south_latitude": 42.48,
            "west_longitude": -91.43
        },
        "id": {
            "authority": "ESRI",
            "code": 102354
        },
        "name": "NAD83 StatePlane Wisconsin South"
    },
    "102355": {
        "bbox": {
            "east_longitude": -104.05,
            "north_latitude": 45.01,
            "south_latitude": 40.99,
            "west_longitude": -106.33
        },
        "id": {
            "authority": "ESRI",
            "code": 102355
        },
        "name": "NAD83 StatePlane Wyoming East"
    },
    "102356": {
        "bbox": {
            "east_longitude": -106,
            "north_latitude": 45.01,
            "south_latitude": 40.99,
            "west_longitude": -108.63
        },
        "id": {
            "authority": "ESRI",
            "code": 102356
        },
        "name": "NAD83 StatePlane Wyoming East Central"
    },
    "102357": {
        "bbox": {
            "east_longitude": -107.5,
            "north_latitude": 45.01,
            "south_latitude": 40.99,
            "west_longitude": -111.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102357
        },
        "name": "NAD83 StatePlane Wyoming West Central"
    },
    "102358": {
        "bbox": {
            "east_longitude": -109.04,
            "north_latitude": 44.67,
            "south_latitude": 40.99,
            "west_longitude": -111.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102358
        },
        "name": "NAD83 StatePlane Wyoming West"
    },
    "102629": {
        "bbox": {
            "east_longitude": -84.89,
            "north_latitude": 35,
            "south_latitude": 30.99,
            "west_longitude": -86.79
        },
        "id": {
            "authority": "ESRI",
            "code": 102629
        },
        "name": "NAD83 StatePlane Alabama East Feet"
    },
    "102630": {
        "bbox": {
            "east_longitude": -86.3,
            "north_latitude": 35.02,
            "south_latitude": 30.14,
            "west_longitude": -88.48
        },
        "id": {
            "authority": "ESRI",
            "code": 102630
        },
        "name": "NAD83 StatePlane Alabama West Feet"
    },
    "102631": {
        "bbox": {
            "east_longitude": -129.99,
            "north_latitude": 60.35,
            "south_latitude": 54.61,
            "west_longitude": -141
        },
        "id": {
            "authority": "ESRI",
            "code": 102631
        },
        "name": "NAD83 StatePlane Alaska 1 Feet"
    },
    "102632": {
        "bbox": {
            "east_longitude": -140.98,
            "north_latitude": 70.16,
            "south_latitude": 59.72,
            "west_longitude": -144.01
        },
        "id": {
            "authority": "ESRI",
            "code": 102632
        },
        "name": "NAD83 StatePlane Alaska 2 Feet"
    },
    "102633": {
        "bbox": {
            "east_longitude": -144,
            "north_latitude": 70.38,
            "south_latitude": 59.72,
            "west_longitude": -148
        },
        "id": {
            "authority": "ESRI",
            "code": 102633
        },
        "name": "NAD83 StatePlane Alaska 3 Feet"
    },
    "102634": {
        "bbox": {
            "east_longitude": -147.99,
            "north_latitude": 70.63,
            "south_latitude": 59.11,
            "west_longitude": -152.01
        },
        "id": {
            "authority": "ESRI",
            "code": 102634
        },
        "name": "NAD83 StatePlane Alaska 4 Feet"
    },
    "102635": {
        "bbox": {
            "east_longitude": -151.86,
            "north_latitude": 71.28,
            "south_latitude": 55.72,
            "west_longitude": -156
        },
        "id": {
            "authority": "ESRI",
            "code": 102635
        },
        "name": "NAD83 StatePlane Alaska 5 Feet"
    },
    "102636": {
        "bbox": {
            "east_longitude": -155.99,
            "north_latitude": 71.4,
            "south_latitude": 54.89,
            "west_longitude": -160
        },
        "id": {
            "authority": "ESRI",
            "code": 102636
        },
        "name": "NAD83 StatePlane Alaska 6 Feet"
    },
    "102637": {
        "bbox": {
            "east_longitude": -160,
            "north_latitude": 70.74,
            "south_latitude": 54.32,
            "west_longitude": -164.01
        },
        "id": {
            "authority": "ESRI",
            "code": 102637
        },
        "name": "NAD83 StatePlane Alaska 7 Feet"
    },
    "102638": {
        "bbox": {
            "east_longitude": -164,
            "north_latitude": 69.05,
            "south_latitude": 54.34,
            "west_longitude": -168.26
        },
        "id": {
            "authority": "ESRI",
            "code": 102638
        },
        "name": "NAD83 StatePlane Alaska 8 Feet"
    },
    "102639": {
        "bbox": {
            "east_longitude": -168,
            "north_latitude": 65.82,
            "south_latitude": 56.49,
            "west_longitude": -173.16
        },
        "id": {
            "authority": "ESRI",
            "code": 102639
        },
        "name": "NAD83 StatePlane Alaska 9 Feet"
    },
    "102640": {
        "bbox": {
            "east_longitude": -164.84,
            "north_latitude": 54.34,
            "south_latitude": 51.3,
            "west_longitude": 172.42
        },
        "id": {
            "authority": "ESRI",
            "code": 102640
        },
        "name": "NAD83 StatePlane Alaska 10 Feet"
    },
    "102641": {
        "bbox": {
            "east_longitude": -119.99,
            "north_latitude": 42.01,
            "south_latitude": 39.59,
            "west_longitude": -124.45
        },
        "id": {
            "authority": "ESRI",
            "code": 102641
        },
        "name": "NAD83 StatePlane California I Feet"
    },
    "102642": {
        "bbox": {
            "east_longitude": -119.54,
            "north_latitude": 40.16,
            "south_latitude": 38.02,
            "west_longitude": -124.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102642
        },
        "name": "NAD83 StatePlane California II Feet"
    },
    "102643": {
        "bbox": {
            "east_longitude": -117.83,
            "north_latitude": 38.71,
            "south_latitude": 36.73,
            "west_longitude": -123.02
        },
        "id": {
            "authority": "ESRI",
            "code": 102643
        },
        "name": "NAD83 StatePlane California III Feet"
    },
    "102644": {
        "bbox": {
            "east_longitude": -115.62,
            "north_latitude": 37.58,
            "south_latitude": 35.78,
            "west_longitude": -122.01
        },
        "id": {
            "authority": "ESRI",
            "code": 102644
        },
        "name": "NAD83 StatePlane California IV Feet"
    },
    "102645": {
        "bbox": {
            "east_longitude": -114.12,
            "north_latitude": 35.81,
            "south_latitude": 32.76,
            "west_longitude": -121.42
        },
        "id": {
            "authority": "ESRI",
            "code": 102645
        },
        "name": "NAD83 StatePlane California V Feet"
    },
    "102646": {
        "bbox": {
            "east_longitude": -114.42,
            "north_latitude": 34.08,
            "south_latitude": 32.53,
            "west_longitude": -118.15
        },
        "id": {
            "authority": "ESRI",
            "code": 102646
        },
        "name": "NAD83 StatePlane California VI Feet"
    },
    "102648": {
        "bbox": {
            "east_longitude": -109.04,
            "north_latitude": 37.01,
            "south_latitude": 31.33,
            "west_longitude": -111.71
        },
        "id": {
            "authority": "ESRI",
            "code": 102648
        },
        "name": "NAD83 StatePlane Arizona East Feet"
    },
    "102649": {
        "bbox": {
            "east_longitude": -110.44,
            "north_latitude": 37.01,
            "south_latitude": 31.33,
            "west_longitude": -113.35
        },
        "id": {
            "authority": "ESRI",
            "code": 102649
        },
        "name": "NAD83 StatePlane Arizona Central Feet"
    },
    "102650": {
        "bbox": {
            "east_longitude": -112.52,
            "north_latitude": 37,
            "south_latitude": 32.05,
            "west_longitude": -114.81
        },
        "id": {
            "authority": "ESRI",
            "code": 102650
        },
        "name": "NAD83 StatePlane Arizona West Feet"
    },
    "102651": {
        "bbox": {
            "east_longitude": -89.64,
            "north_latitude": 36.5,
            "south_latitude": 34.67,
            "west_longitude": -94.62
        },
        "id": {
            "authority": "ESRI",
            "code": 102651
        },
        "name": "NAD83 StatePlane Arkansas North Feet"
    },
    "102652": {
        "bbox": {
            "east_longitude": -90.4,
            "north_latitude": 35.1,
            "south_latitude": 33.01,
            "west_longitude": -94.48
        },
        "id": {
            "authority": "ESRI",
            "code": 102652
        },
        "name": "NAD83 StatePlane Arkansas South Feet"
    },
    "102653": {
        "bbox": {
            "east_longitude": -102.04,
            "north_latitude": 41.01,
            "south_latitude": 39.56,
            "west_longitude": -109.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102653
        },
        "name": "NAD83 StatePlane Colorado North Feet"
    },
    "102654": {
        "bbox": {
            "east_longitude": -102.04,
            "north_latitude": 40.09,
            "south_latitude": 38.14,
            "west_longitude": -109.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102654
        },
        "name": "NAD83 StatePlane Colorado Central Feet"
    },
    "102655": {
        "bbox": {
            "east_longitude": -102.04,
            "north_latitude": 38.68,
            "south_latitude": 36.98,
            "west_longitude": -109.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102655
        },
        "name": "NAD83 StatePlane Colorado South Feet"
    },
    "102656": {
        "bbox": {
            "east_longitude": -71.78,
            "north_latitude": 42.05,
            "south_latitude": 40.98,
            "west_longitude": -73.73
        },
        "id": {
            "authority": "ESRI",
            "code": 102656
        },
        "name": "NAD83 StatePlane Connecticut Feet"
    },
    "102657": {
        "bbox": {
            "east_longitude": -74.97,
            "north_latitude": 39.85,
            "south_latitude": 38.44,
            "west_longitude": -75.8
        },
        "id": {
            "authority": "ESRI",
            "code": 102657
        },
        "name": "NAD83 StatePlane Delaware Feet"
    },
    "102658": {
        "bbox": {
            "east_longitude": -79.97,
            "north_latitude": 30.83,
            "south_latitude": 24.41,
            "west_longitude": -82.33
        },
        "id": {
            "authority": "ESRI",
            "code": 102658
        },
        "name": "NAD83 StatePlane Florida East Feet"
    },
    "102659": {
        "bbox": {
            "east_longitude": -81.13,
            "north_latitude": 29.6,
            "south_latitude": 26.27,
            "west_longitude": -83.34
        },
        "id": {
            "authority": "ESRI",
            "code": 102659
        },
        "name": "NAD83 StatePlane Florida West Feet"
    },
    "102660": {
        "bbox": {
            "east_longitude": -82.04,
            "north_latitude": 31.01,
            "south_latitude": 29.21,
            "west_longitude": -87.63
        },
        "id": {
            "authority": "ESRI",
            "code": 102660
        },
        "name": "NAD83 StatePlane Florida North Feet"
    },
    "102661": {
        "bbox": {
            "east_longitude": -154.74,
            "north_latitude": 20.33,
            "south_latitude": 18.87,
            "west_longitude": -156.1
        },
        "id": {
            "authority": "ESRI",
            "code": 102661
        },
        "name": "NAD83 StatePlane Hawaii 1 Feet"
    },
    "102662": {
        "bbox": {
            "east_longitude": -155.93,
            "north_latitude": 21.26,
            "south_latitude": 20.45,
            "west_longitude": -157.36
        },
        "id": {
            "authority": "ESRI",
            "code": 102662
        },
        "name": "NAD83 StatePlane Hawaii 2 Feet"
    },
    "102663": {
        "bbox": {
            "east_longitude": -157.61,
            "north_latitude": 21.75,
            "south_latitude": 21.2,
            "west_longitude": -158.33
        },
        "id": {
            "authority": "ESRI",
            "code": 102663
        },
        "name": "NAD83 StatePlane Hawaii 3 Feet"
    },
    "102664": {
        "bbox": {
            "east_longitude": -159.23,
            "north_latitude": 22.29,
            "south_latitude": 21.81,
            "west_longitude": -159.85
        },
        "id": {
            "authority": "ESRI",
            "code": 102664
        },
        "name": "NAD83 StatePlane Hawaii 4 Feet"
    },
    "102665": {
        "bbox": {
            "east_longitude": -159.99,
            "north_latitude": 22.07,
            "south_latitude": 21.73,
            "west_longitude": -160.3
        },
        "id": {
            "authority": "ESRI",
            "code": 102665
        },
        "name": "NAD83 StatePlane Hawaii 5 Feet"
    },
    "102666": {
        "bbox": {
            "east_longitude": -80.77,
            "north_latitude": 34.68,
            "south_latitude": 30.36,
            "west_longitude": -83.47
        },
        "id": {
            "authority": "ESRI",
            "code": 102666
        },
        "name": "NAD83 StatePlane Georgia East Feet"
    },
    "102667": {
        "bbox": {
            "east_longitude": -82.99,
            "north_latitude": 35.01,
            "south_latitude": 30.62,
            "west_longitude": -85.61
        },
        "id": {
            "authority": "ESRI",
            "code": 102667
        },
        "name": "NAD83 StatePlane Georgia West Feet"
    },
    "102668": {
        "bbox": {
            "east_longitude": -111.04,
            "north_latitude": 44.75,
            "south_latitude": 41.99,
            "west_longitude": -113.24
        },
        "id": {
            "authority": "ESRI",
            "code": 102668
        },
        "name": "NAD83 StatePlane Idaho East Feet"
    },
    "102669": {
        "bbox": {
            "east_longitude": -112.68,
            "north_latitude": 45.7,
            "south_latitude": 41.99,
            "west_longitude": -115.3
        },
        "id": {
            "authority": "ESRI",
            "code": 102669
        },
        "name": "NAD83 StatePlane Idaho Central Feet"
    },
    "102670": {
        "bbox": {
            "east_longitude": -114.32,
            "north_latitude": 49.01,
            "south_latitude": 41.99,
            "west_longitude": -117.24
        },
        "id": {
            "authority": "ESRI",
            "code": 102670
        },
        "name": "NAD83 StatePlane Idaho West Feet"
    },
    "102671": {
        "bbox": {
            "east_longitude": -87.02,
            "north_latitude": 42.5,
            "south_latitude": 37.06,
            "west_longitude": -89.28
        },
        "id": {
            "authority": "ESRI",
            "code": 102671
        },
        "name": "NAD83 StatePlane Illinois East Feet"
    },
    "102672": {
        "bbox": {
            "east_longitude": -88.93,
            "north_latitude": 42.51,
            "south_latitude": 36.98,
            "west_longitude": -91.52
        },
        "id": {
            "authority": "ESRI",
            "code": 102672
        },
        "name": "NAD83 StatePlane Illinois West Feet"
    },
    "102673": {
        "bbox": {
            "east_longitude": -84.78,
            "north_latitude": 41.77,
            "south_latitude": 37.95,
            "west_longitude": -86.59
        },
        "id": {
            "authority": "ESRI",
            "code": 102673
        },
        "name": "NAD83 StatePlane Indiana East Feet"
    },
    "102674": {
        "bbox": {
            "east_longitude": -86.24,
            "north_latitude": 41.77,
            "south_latitude": 37.77,
            "west_longitude": -88.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102674
        },
        "name": "NAD83 StatePlane Indiana West Feet"
    },
    "102675": {
        "bbox": {
            "east_longitude": -90.15,
            "north_latitude": 43.51,
            "south_latitude": 41.85,
            "west_longitude": -96.65
        },
        "id": {
            "authority": "ESRI",
            "code": 102675
        },
        "name": "NAD83 StatePlane Iowa North Feet"
    },
    "102676": {
        "bbox": {
            "east_longitude": -90.14,
            "north_latitude": 42.04,
            "south_latitude": 40.37,
            "west_longitude": -96.14
        },
        "id": {
            "authority": "ESRI",
            "code": 102676
        },
        "name": "NAD83 StatePlane Iowa South Feet"
    },
    "102677": {
        "bbox": {
            "east_longitude": -94.58,
            "north_latitude": 40.01,
            "south_latitude": 38.52,
            "west_longitude": -102.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102677
        },
        "name": "NAD83 StatePlane Kansas North Feet"
    },
    "102678": {
        "bbox": {
            "east_longitude": -94.6,
            "north_latitude": 38.88,
            "south_latitude": 36.99,
            "west_longitude": -102.05
        },
        "id": {
            "authority": "ESRI",
            "code": 102678
        },
        "name": "NAD83 StatePlane Kansas South Feet"
    },
    "102679": {
        "bbox": {
            "east_longitude": -82.47,
            "north_latitude": 39.15,
            "south_latitude": 37.71,
            "west_longitude": -85.96
        },
        "id": {
            "authority": "ESRI",
            "code": 102679
        },
        "name": "NAD83 StatePlane Kentucky North Feet"
    },
    "102680": {
        "bbox": {
            "east_longitude": -81.95,
            "north_latitude": 38.17,
            "south_latitude": 36.49,
            "west_longitude": -89.57
        },
        "id": {
            "authority": "ESRI",
            "code": 102680
        },
        "name": "NAD83 StatePlane Kentucky South Feet"
    },
    "102681": {
        "bbox": {
            "east_longitude": -90.86,
            "north_latitude": 33.03,
            "south_latitude": 30.85,
            "west_longitude": -94.05
        },
        "id": {
            "authority": "ESRI",
            "code": 102681
        },
        "name": "NAD83 StatePlane Louisiana North Feet"
    },
    "102682": {
        "bbox": {
            "east_longitude": -88.75,
            "north_latitude": 31.07,
            "south_latitude": 28.85,
            "west_longitude": -93.94
        },
        "id": {
            "authority": "ESRI",
            "code": 102682
        },
        "name": "NAD83 StatePlane Louisiana South Feet"
    },
    "102683": {
        "bbox": {
            "east_longitude": -66.91,
            "north_latitude": 47.47,
            "south_latitude": 43.88,
            "west_longitude": -70.03
        },
        "id": {
            "authority": "ESRI",
            "code": 102683
        },
        "name": "NAD83 StatePlane Maine East Feet"
    },
    "102684": {
        "bbox": {
            "east_longitude": -69.26,
            "north_latitude": 46.58,
            "south_latitude": 43.04,
            "west_longitude": -71.09
        },
        "id": {
            "authority": "ESRI",
            "code": 102684
        },
        "name": "NAD83 StatePlane Maine West Feet"
    },
    "102685": {
        "bbox": {
            "east_longitude": -74.97,
            "north_latitude": 39.73,
            "south_latitude": 37.97,
            "west_longitude": -79.49
        },
        "id": {
            "authority": "ESRI",
            "code": 102685
        },
        "name": "NAD83 StatePlane Maryland Feet"
    },
    "102686": {
        "bbox": {
            "east_longitude": -69.86,
            "north_latitude": 42.89,
            "south_latitude": 41.46,
            "west_longitude": -73.5
        },
        "id": {
            "authority": "ESRI",
            "code": 102686
        },
        "name": "NAD83 StatePlane Massachusetts Mainland Feet"
    },
    "102687": {
        "bbox": {
            "east_longitude": -69.89,
            "north_latitude": 41.51,
            "south_latitude": 41.19,
            "west_longitude": -70.91
        },
        "id": {
            "authority": "ESRI",
            "code": 102687
        },
        "name": "NAD83 StatePlane Massachusetts Island Feet"
    },
    "102688": {
        "bbox": {
            "east_longitude": -83.44,
            "north_latitude": 48.32,
            "south_latitude": 45.08,
            "west_longitude": -90.42
        },
        "id": {
            "authority": "ESRI",
            "code": 102688
        },
        "name": "NAD83 StatePlane Michigan North Feet"
    },
    "102689": {
        "bbox": {
            "east_longitude": -82.27,
            "north_latitude": 45.92,
            "south_latitude": 43.8,
            "west_longitude": -87.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102689
        },
        "name": "NAD83 StatePlane Michigan Central Feet"
    },
    "102690": {
        "bbox": {
            "east_longitude": -82.13,
            "north_latitude": 44.22,
            "south_latitude": 41.69,
            "west_longitude": -87.2
        },
        "id": {
            "authority": "ESRI",
            "code": 102690
        },
        "name": "NAD83 StatePlane Michigan South Feet"
    },
    "102691": {
        "bbox": {
            "east_longitude": -89.49,
            "north_latitude": 49.38,
            "south_latitude": 46.64,
            "west_longitude": -97.22
        },
        "id": {
            "authority": "ESRI",
            "code": 102691
        },
        "name": "NAD83 StatePlane Minnesota North Feet"
    },
    "102692": {
        "bbox": {
            "east_longitude": -92.29,
            "north_latitude": 47.48,
            "south_latitude": 45.28,
            "west_longitude": -96.86
        },
        "id": {
            "authority": "ESRI",
            "code": 102692
        },
        "name": "NAD83 StatePlane Minnesota Central Feet"
    },
    "102693": {
        "bbox": {
            "east_longitude": -91.21,
            "north_latitude": 45.59,
            "south_latitude": 43.49,
            "west_longitude": -96.85
        },
        "id": {
            "authority": "ESRI",
            "code": 102693
        },
        "name": "NAD83 StatePlane Minnesota South Feet"
    },
    "102694": {
        "bbox": {
            "east_longitude": -88.09,
            "north_latitude": 35.01,
            "south_latitude": 30.01,
            "west_longitude": -89.97
        },
        "id": {
            "authority": "ESRI",
            "code": 102694
        },
        "name": "NAD83 StatePlane Mississippi East Feet"
    },
    "102695": {
        "bbox": {
            "east_longitude": -89.37,
            "north_latitude": 35.01,
            "south_latitude": 31,
            "west_longitude": -91.65
        },
        "id": {
            "authority": "ESRI",
            "code": 102695
        },
        "name": "NAD83 StatePlane Mississippi West Feet"
    },
    "102696": {
        "bbox": {
            "east_longitude": -89.1,
            "north_latitude": 40.61,
            "south_latitude": 35.98,
            "west_longitude": -91.97
        },
        "id": {
            "authority": "ESRI",
            "code": 102696
        },
        "name": "NAD83 StatePlane Missouri East Feet"
    },
    "102697": {
        "bbox": {
            "east_longitude": -91.41,
            "north_latitude": 40.61,
            "south_latitude": 36.48,
            "west_longitude": -93.79
        },
        "id": {
            "authority": "ESRI",
            "code": 102697
        },
        "name": "NAD83 StatePlane Missouri Central Feet"
    },
    "102698": {
        "bbox": {
            "east_longitude": -93.48,
            "north_latitude": 40.59,
            "south_latitude": 36.48,
            "west_longitude": -95.77
        },
        "id": {
            "authority": "ESRI",
            "code": 102698
        },
        "name": "NAD83 StatePlane Missouri West Feet"
    },
    "102700": {
        "bbox": {
            "east_longitude": -104.04,
            "north_latitude": 49.01,
            "south_latitude": 44.35,
            "west_longitude": -116.07
        },
        "id": {
            "authority": "ESRI",
            "code": 102700
        },
        "name": "NAD83 StatePlane Montana Feet"
    },
    "102704": {
        "bbox": {
            "east_longitude": -95.3,
            "north_latitude": 43.01,
            "south_latitude": 39.99,
            "west_longitude": -104.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102704
        },
        "name": "NAD83 StatePlane Nebraska Feet"
    },
    "102707": {
        "bbox": {
            "east_longitude": -114.03,
            "north_latitude": 42,
            "south_latitude": 34.99,
            "west_longitude": -117.01
        },
        "id": {
            "authority": "ESRI",
            "code": 102707
        },
        "name": "NAD83 StatePlane Nevada East Feet"
    },
    "102708": {
        "bbox": {
            "east_longitude": -114.99,
            "north_latitude": 41,
            "south_latitude": 36,
            "west_longitude": -118.19
        },
        "id": {
            "authority": "ESRI",
            "code": 102708
        },
        "name": "NAD83 StatePlane Nevada Central Feet"
    },
    "102709": {
        "bbox": {
            "east_longitude": -116.99,
            "north_latitude": 42,
            "south_latitude": 36.95,
            "west_longitude": -120
        },
        "id": {
            "authority": "ESRI",
            "code": 102709
        },
        "name": "NAD83 StatePlane Nevada West Feet"
    },
    "102710": {
        "bbox": {
            "east_longitude": -70.63,
            "north_latitude": 45.31,
            "south_latitude": 42.69,
            "west_longitude": -72.56
        },
        "id": {
            "authority": "ESRI",
            "code": 102710
        },
        "name": "NAD83 StatePlane New Hampshire Feet"
    },
    "102711": {
        "bbox": {
            "east_longitude": -73.88,
            "north_latitude": 41.36,
            "south_latitude": 38.87,
            "west_longitude": -75.6
        },
        "id": {
            "authority": "ESRI",
            "code": 102711
        },
        "name": "NAD83 StatePlane New Jersey Feet"
    },
    "102712": {
        "bbox": {
            "east_longitude": -102.99,
            "north_latitude": 37,
            "south_latitude": 32,
            "west_longitude": -105.72
        },
        "id": {
            "authority": "ESRI",
            "code": 102712
        },
        "name": "NAD83 StatePlane New Mexico East Feet"
    },
    "102713": {
        "bbox": {
            "east_longitude": -104.84,
            "north_latitude": 37,
            "south_latitude": 31.78,
            "west_longitude": -107.73
        },
        "id": {
            "authority": "ESRI",
            "code": 102713
        },
        "name": "NAD83 StatePlane New Mexico Central Feet"
    },
    "102714": {
        "bbox": {
            "east_longitude": -106.32,
            "north_latitude": 37,
            "south_latitude": 31.33,
            "west_longitude": -109.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102714
        },
        "name": "NAD83 StatePlane New Mexico West Feet"
    },
    "102715": {
        "bbox": {
            "east_longitude": -73.23,
            "north_latitude": 45.02,
            "south_latitude": 40.88,
            "west_longitude": -75.87
        },
        "id": {
            "authority": "ESRI",
            "code": 102715
        },
        "name": "NAD83 StatePlane New York East Feet"
    },
    "102716": {
        "bbox": {
            "east_longitude": -75.04,
            "north_latitude": 44.41,
            "south_latitude": 41.99,
            "west_longitude": -77.75
        },
        "id": {
            "authority": "ESRI",
            "code": 102716
        },
        "name": "NAD83 StatePlane New York Central Feet"
    },
    "102717": {
        "bbox": {
            "east_longitude": -77.36,
            "north_latitude": 43.64,
            "south_latitude": 41.99,
            "west_longitude": -79.77
        },
        "id": {
            "authority": "ESRI",
            "code": 102717
        },
        "name": "NAD83 StatePlane New York West Feet"
    },
    "102718": {
        "bbox": {
            "east_longitude": -71.8,
            "north_latitude": 41.3,
            "south_latitude": 40.47,
            "west_longitude": -74.26
        },
        "id": {
            "authority": "ESRI",
            "code": 102718
        },
        "name": "NAD83 StatePlane New York Long Island Feet"
    },
    "102719": {
        "bbox": {
            "east_longitude": -75.38,
            "north_latitude": 36.59,
            "south_latitude": 33.83,
            "west_longitude": -84.33
        },
        "id": {
            "authority": "ESRI",
            "code": 102719
        },
        "name": "NAD83 StatePlane North Carolina Feet"
    },
    "102720": {
        "bbox": {
            "east_longitude": -96.83,
            "north_latitude": 49.01,
            "south_latitude": 47.15,
            "west_longitude": -104.07
        },
        "id": {
            "authority": "ESRI",
            "code": 102720
        },
        "name": "NAD83 StatePlane North Dakota North Feet"
    },
    "102721": {
        "bbox": {
            "east_longitude": -96.55,
            "north_latitude": 47.83,
            "south_latitude": 45.93,
            "west_longitude": -104.05
        },
        "id": {
            "authority": "ESRI",
            "code": 102721
        },
        "name": "NAD83 StatePlane North Dakota South Feet"
    },
    "102722": {
        "bbox": {
            "east_longitude": -80.51,
            "north_latitude": 42.33,
            "south_latitude": 40.1,
            "west_longitude": -84.81
        },
        "id": {
            "authority": "ESRI",
            "code": 102722
        },
        "name": "NAD83 StatePlane Ohio North Feet"
    },
    "102723": {
        "bbox": {
            "east_longitude": -80.7,
            "north_latitude": 40.36,
            "south_latitude": 38.4,
            "west_longitude": -84.83
        },
        "id": {
            "authority": "ESRI",
            "code": 102723
        },
        "name": "NAD83 StatePlane Ohio South Feet"
    },
    "102724": {
        "bbox": {
            "east_longitude": -94.42,
            "north_latitude": 37.01,
            "south_latitude": 35.27,
            "west_longitude": -103
        },
        "id": {
            "authority": "ESRI",
            "code": 102724
        },
        "name": "NAD83 StatePlane Oklahoma North Feet"
    },
    "102725": {
        "bbox": {
            "east_longitude": -94.42,
            "north_latitude": 35.57,
            "south_latitude": 33.62,
            "west_longitude": -100
        },
        "id": {
            "authority": "ESRI",
            "code": 102725
        },
        "name": "NAD83 StatePlane Oklahoma South Feet"
    },
    "102726": {
        "bbox": {
            "east_longitude": -116.47,
            "north_latitude": 46.26,
            "south_latitude": 43.95,
            "west_longitude": -124.17
        },
        "id": {
            "authority": "ESRI",
            "code": 102726
        },
        "name": "NAD83 StatePlane Oregon North Feet"
    },
    "102727": {
        "bbox": {
            "east_longitude": -116.9,
            "north_latitude": 44.56,
            "south_latitude": 41.98,
            "west_longitude": -124.6
        },
        "id": {
            "authority": "ESRI",
            "code": 102727
        },
        "name": "NAD83 StatePlane Oregon South Feet"
    },
    "102728": {
        "bbox": {
            "east_longitude": -74.7,
            "north_latitude": 42.53,
            "south_latitude": 40.6,
            "west_longitude": -80.53
        },
        "id": {
            "authority": "ESRI",
            "code": 102728
        },
        "name": "NAD83 StatePlane Pennsylvania North Feet"
    },
    "102729": {
        "bbox": {
            "east_longitude": -74.72,
            "north_latitude": 41.18,
            "south_latitude": 39.71,
            "west_longitude": -80.53
        },
        "id": {
            "authority": "ESRI",
            "code": 102729
        },
        "name": "NAD83 StatePlane Pennsylvania South Feet"
    },
    "102730": {
        "bbox": {
            "east_longitude": -71.08,
            "north_latitude": 42.02,
            "south_latitude": 41.13,
            "west_longitude": -71.85
        },
        "id": {
            "authority": "ESRI",
            "code": 102730
        },
        "name": "NAD83 StatePlane Rhode Island Feet"
    },
    "102733": {
        "bbox": {
            "east_longitude": -78.52,
            "north_latitude": 35.21,
            "south_latitude": 32.05,
            "west_longitude": -83.36
        },
        "id": {
            "authority": "ESRI",
            "code": 102733
        },
        "name": "NAD83 StatePlane South Carolina Feet"
    },
    "102734": {
        "bbox": {
            "east_longitude": -96.45,
            "north_latitude": 45.95,
            "south_latitude": 44.14,
            "west_longitude": -104.07
        },
        "id": {
            "authority": "ESRI",
            "code": 102734
        },
        "name": "NAD83 StatePlane South Dakota North Feet"
    },
    "102735": {
        "bbox": {
            "east_longitude": -96.43,
            "north_latitude": 44.79,
            "south_latitude": 42.48,
            "west_longitude": -104.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102735
        },
        "name": "NAD83 StatePlane South Dakota South Feet"
    },
    "102736": {
        "bbox": {
            "east_longitude": -81.65,
            "north_latitude": 36.68,
            "south_latitude": 34.98,
            "west_longitude": -90.31
        },
        "id": {
            "authority": "ESRI",
            "code": 102736
        },
        "name": "NAD83 StatePlane Tennessee Feet"
    },
    "102737": {
        "bbox": {
            "east_longitude": -99.99,
            "north_latitude": 36.5,
            "south_latitude": 34.3,
            "west_longitude": -103.03
        },
        "id": {
            "authority": "ESRI",
            "code": 102737
        },
        "name": "NAD83 StatePlane Texas North Feet"
    },
    "102738": {
        "bbox": {
            "east_longitude": -94,
            "north_latitude": 34.58,
            "south_latitude": 31.72,
            "west_longitude": -103.07
        },
        "id": {
            "authority": "ESRI",
            "code": 102738
        },
        "name": "NAD83 StatePlane Texas North Central Feet"
    },
    "102739": {
        "bbox": {
            "east_longitude": -93.5,
            "north_latitude": 32.27,
            "south_latitude": 29.78,
            "west_longitude": -106.66
        },
        "id": {
            "authority": "ESRI",
            "code": 102739
        },
        "name": "NAD83 StatePlane Texas Central Feet"
    },
    "102740": {
        "bbox": {
            "east_longitude": -93.76,
            "north_latitude": 30.67,
            "south_latitude": 27.78,
            "west_longitude": -105
        },
        "id": {
            "authority": "ESRI",
            "code": 102740
        },
        "name": "NAD83 StatePlane Texas South Central Feet"
    },
    "102741": {
        "bbox": {
            "east_longitude": -96.85,
            "north_latitude": 28.21,
            "south_latitude": 25.83,
            "west_longitude": -100.2
        },
        "id": {
            "authority": "ESRI",
            "code": 102741
        },
        "name": "NAD83 StatePlane Texas South Feet"
    },
    "102742": {
        "bbox": {
            "east_longitude": -109.04,
            "north_latitude": 42.01,
            "south_latitude": 40.55,
            "west_longitude": -114.04
        },
        "id": {
            "authority": "ESRI",
            "code": 102742
        },
        "name": "NAD83 StatePlane Utah North Feet"
    },
    "102743": {
        "bbox": {
            "east_longitude": -109.04,
            "north_latitude": 41.08,
            "south_latitude": 38.49,
            "west_longitude": -114.05
        },
        "id": {
            "authority": "ESRI",
            "code": 102743
        },
        "name": "NAD83 StatePlane Utah Central Feet"
    },
    "102744": {
        "bbox": {
            "east_longitude": -109.04,
            "north_latitude": 38.58,
            "south_latitude": 36.99,
            "west_longitude": -114.05
        },
        "id": {
            "authority": "ESRI",
            "code": 102744
        },
        "name": "NAD83 StatePlane Utah South Feet"
    },
    "102745": {
        "bbox": {
            "east_longitude": -71.5,
            "north_latitude": 45.03,
            "south_latitude": 42.72,
            "west_longitude": -73.44
        },
        "id": {
            "authority": "ESRI",
            "code": 102745
        },
        "name": "NAD83 StatePlane Vermont Feet"
    },
    "102746": {
        "bbox": {
            "east_longitude": -76.51,
            "north_latitude": 39.46,
            "south_latitude": 37.77,
            "west_longitude": -80.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102746
        },
        "name": "NAD83 StatePlane Virginia North Feet"
    },
    "102747": {
        "bbox": {
            "east_longitude": -75.31,
            "north_latitude": 38.28,
            "south_latitude": 36.54,
            "west_longitude": -83.68
        },
        "id": {
            "authority": "ESRI",
            "code": 102747
        },
        "name": "NAD83 StatePlane Virginia South Feet"
    },
    "102748": {
        "bbox": {
            "east_longitude": -117.02,
            "north_latitude": 49.05,
            "south_latitude": 47.08,
            "west_longitude": -124.79
        },
        "id": {
            "authority": "ESRI",
            "code": 102748
        },
        "name": "NAD83 StatePlane Washington North Feet"
    },
    "102749": {
        "bbox": {
            "east_longitude": -116.91,
            "north_latitude": 47.61,
            "south_latitude": 45.54,
            "west_longitude": -124.4
        },
        "id": {
            "authority": "ESRI",
            "code": 102749
        },
        "name": "NAD83 StatePlane Washington South Feet"
    },
    "102750": {
        "bbox": {
            "east_longitude": -77.72,
            "north_latitude": 40.64,
            "south_latitude": 38.76,
            "west_longitude": -81.76
        },
        "id": {
            "authority": "ESRI",
            "code": 102750
        },
        "name": "NAD83 StatePlane West Virginia North Feet"
    },
    "102751": {
        "bbox": {
            "east_longitude": -79.05,
            "north_latitude": 39.17,
            "south_latitude": 37.2,
            "west_longitude": -82.65
        },
        "id": {
            "authority": "ESRI",
            "code": 102751
        },
        "name": "NAD83 StatePlane West Virginia South Feet"
    },
    "102752": {
        "bbox": {
            "east_longitude": -88.05,
            "north_latitude": 47.31,
            "south_latitude": 45.37,
            "west_longitude": -92.89
        },
        "id": {
            "authority": "ESRI",
            "code": 102752
        },
        "name": "NAD83 StatePlane Wisconsin North Feet"
    },
    "102753": {
        "bbox": {
            "east_longitude": -86.25,
            "north_latitude": 45.8,
            "south_latitude": 43.98,
            "west_longitude": -92.89
        },
        "id": {
            "authority": "ESRI",
            "code": 102753
        },
        "name": "NAD83 StatePlane Wisconsin Central Feet"
    },
    "102754": {
        "bbox": {
            "east_longitude": -86.95,
            "north_latitude": 44.33,
            "south_latitude": 42.48,
            "west_longitude": -91.43
        },
        "id": {
            "authority": "ESRI",
            "code": 102754
        },
        "name": "NAD83 StatePlane Wisconsin South Feet"
    },
    "102755": {
        "bbox": {
            "east_longitude": -104.05,
            "north_latitude": 45.01,
            "south_latitude": 40.99,
            "west_longitude": -106.33
        },
        "id": {
            "authority": "ESRI",
            "code": 102755
        },
        "name": "NAD83 StatePlane Wyoming East Feet"
    },
    "102756": {
        "bbox": {
            "east_longitude": -106,
            "north_latitude": 45.01,
            "south_latitude": 40.99,
            "west_longitude": -108.63
        },
        "id": {
            "authority": "ESRI",
            "code": 102756
        },
        "name": "NAD83 StatePlane Wyoming East Central Feet"
    },
    "102757": {
        "bbox": {
            "east_longitude": -107.5,
            "north_latitude": 45.01,
            "south_latitude": 40.99,
            "west_longitude": -111.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102757
        },
        "name": "NAD83 StatePlane Wyoming West Central Feet"
    },
    "102758": {
        "bbox": {
            "east_longitude": -109.04,
            "north_latitude": 44.67,
            "south_latitude": 40.99,
            "west_longitude": -111.06
        },
        "id": {
            "authority": "ESRI",
            "code": 102758
        },
        "name": "NAD83 StatePlane Wyoming West Feet"
    },
    "102361": {
        "bbox": {
            "east_longitude": -64.51,
            "north_latitude": 18.57,
            "south_latitude": 17.62,
            "west_longitude": -67.97
        },
        "id": {
            "authority": "ESRI",
            "code": 102361
        },
        "name": "NAD83 StatePlane Puerto Rico Virgin Islands"
    },
    "102761": {
        "bbox": {
            "east_longitude": -63.88,
            "north_latitude": 21.86,
            "south_latitude": 14.92,
            "west_longitude": -68.49
        },
        "id": {
            "authority": "ESRI",
            "code": 102761
        },
        "name": "NAD83 StatePlane Puerto Rico Virgin Islands Feet"
    }    
}