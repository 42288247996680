import React from 'react';
import { Menu } from 'antd';
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';

const MenuOverlay = (props) => (
  <Menu onClick={(e) => props.onMenuClick(e.key)}>
    {props.type != 'weather' && (
      <Menu.Item key="saveas">
        <SaveOutlined />
        Save As
      </Menu.Item>
    )}
    <Menu.Item disabled={!props.can_delete} key="delete">
      <DeleteOutlined />
      Delete
    </Menu.Item>
  </Menu>
);

export { MenuOverlay };
