import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";

// antd
import { Button, Tooltip, Modal } from "antd";
import { CheckCircleTwoTone, DownOutlined, RightOutlined, UpOutlined, CaretRightOutlined, CaretLeftOutlined } from "@ant-design/icons";

// components
import { InputHeader } from "./InputHeader";
import { ResultTab, ErrorFromSIFT } from "./ResultTab";

//tutorial stuff
import { TutorialTip } from "../TutorialTip";

import { ModuleInputs, InverterInputs, RackingInputs, WeatherInputs, LayoutInputs, PerformanceInputs, FinanceInputs, TopoInputs, ConfigInputs } from "./index";

import { siftActions, tutorialActions, authActions, isAuthenticated, getSIFTInputs } from "../Redux";

// css
// import './inputs.css'
import "./datagrid.css";
import { inverter_icon, layout_icon, module_icon, perf_icon, racking_icon, topo_icon, weather_icon, finance_icon, results_icon, config_icon } from "../../assets/images";

import { InputPanelWrapper } from "./StyledComponents";

const InputPanel = (props) => {
  const isAuthed = useSelector((state) => isAuthenticated(state));

  const show_agreement_modal = useSelector((state) => state.authentication.show_agreement_modal);
  const show_terms = useSelector((state) => state.authentication.show_terms);

  const tab = useSelector((state) => state.sift.ioManager.uiState.tab);
  const sift_inputs = useSelector((state) => state.sift.ioManager.inputs);
  const current_plan = useSelector((state) => state.account.current_plan);
  const [selectedTab, setSelectedTab] = useState("products");
  const siftRunning = useSelector((state) => state.sift.ioManager.outputs.runState.running);
  const siftCanceling = useSelector((state) => state.sift.ioManager.outputs.runState.canceling);
  const runId = useSelector((state) => state.sift.ioManager.outputs.runState.runId);
  const generated = useSelector((state) => state.sift.ioManager.outputs.runState.generated);
  const outputs = useSelector((state) => state.sift.ioManager.outputs);
  const boundary_exists = Object.values(sift_inputs.map.features).findIndex((feature) => feature.properties.identity == 1) >= 0;
  const output_detail_visible = useSelector((state) => state.sift.ioManager.uiState.output_detail_visible);
  const toggleMobileInputs = useSelector((state) => state.sift.ioManager.uiState.toggleMobileInputs);
  const screenWidth = useSelector((state) => state.sift.ioManager.uiState.screenWidth);

  //Tutorial Controls
  const tutorial = useSelector((state) => state.tutorial);

  const dispatch = useDispatch();

  const [isComplete, setIsComplete] = useState(false);

  // used for the tutorial. Just checks to see if the example project is complete
  useEffect(() => {
    if (Object.values(outputs.results).length > 0) {
      setIsComplete(true);
    }
  }, [outputs.results]);

  const genExtra = () => (
    <CheckCircleTwoTone
      twoToneColor="#002bcb"
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    />
  );

  // using this to open and close tabs and accordians depending on what step the tutorial is at.
  useEffect(() => {
    if (tutorial.tutorialPath == 0) {
      switch (tutorial.currentIndex) {
        case 1:
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          break;
        case 2:
          if (selectedTab !== "products") setSelectedTab("products");
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
        case 3:
          if (selectedTab !== "products") setSelectedTab("products");
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
        case 4:
          if (selectedTab !== "products") setSelectedTab("products");
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          break;
        case 5:
          if (selectedTab !== "products") setSelectedTab("products");
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          break;
        case 6:
          if (selectedTab !== "products") setSelectedTab("products");
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          break;
        case 7:
          if (selectedTab !== "performance") setSelectedTab("performance");
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          break;
        case 8:
          if (selectedTab !== "performance") setSelectedTab("performance");
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          break;
        case 9:
          if (selectedTab !== "finance") setSelectedTab("finance");
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          dispatch(siftActions.updateFinanceToggle(true));
          break;
        case 10:
          if (selectedTab !== "site") setSelectedTab("site");
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          dispatch(siftActions.updateFinanceToggle(false));
          break;
        case 12:
          if (selectedTab !== "site") setSelectedTab("site");
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          break;
        case 13:
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
        case 14:
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
        case 15:
          if (screenWidth < 768 && !toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(true));
          break;
        default:
          break;
      }
    } else {
      switch (tutorial.currentIndex) {
        case 0:
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
        case 1:
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
        case 2:
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
        case 3:
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
        case 4:
          if (screenWidth < 768 && toggleMobileInputs) dispatch(siftActions.toggleMobileInputs(false));
          break;
      }
    }
  }, [tutorial.currentIndex]);

  useEffect(() => {
    if (outputs.results.length > 0 || runId == undefined) return;
    // console.log(sift.ioManager.inputs)
    let inputs = getSIFTInputs(sift_inputs, current_plan);

    // return
    inputs.validatedInputs["runId"] = outputs.runState.runId;
    inputs.validatedInputs["warnings"] = inputs.warnings;
    // if weather undefined, get weather first
    if (inputs.validatedInputs.performance.weather == undefined) {
      dispatch(siftActions.initSIFTWithWeather(inputs.validatedInputs));
    } else {
      dispatch(siftActions.initSIFT(inputs.validatedInputs));
    }
  }, [runId]);

  useEffect(() => {
    if (Object.values(outputs.errors).length > 0) {
      Modal.error({
        title: "",
        content: <ErrorFromSIFT error_messages={outputs.errors} />,
        footer: null,
        width: "50%",
        className: "sift-modal-error",
        style: { maxWidth: "1300px" },
        icon: null,
        maskClosable: false,
        // destroyOnClose: true,
        okText: "Close",
        okType: "ghost",
        // onCancel: () => dispatch(siftActions.clearErrors()),
        onOk: () => dispatch(siftActions.clearErrors()),
      });
    } else {
      Modal.destroyAll();
    }
  }, [outputs.errors]);

  function onLogin() {
    dispatch(authActions.toggleLoginVis(true, "login"));
  }

  function onInitSIFT() {
    //
    if (tutorial.tutorialActive && tutorial.tutorialPath == 0 && tutorial.currentIndex == Object.values(tutorial.content).length - 1) {
      dispatch(tutorialActions.resetTutorial());
      dispatch(siftActions.runTutorial());
      dispatch(tutorialActions.runExample());
    } else {
      let inputs = getSIFTInputs(sift_inputs, current_plan);
      // console.log(inputs)
      // return
      // let inputValidation = validateInputs(inputs)
      if (inputs.valid) {
        let cancelTutorial = true;
        if (tutorial.tutorialActive && tutorial.tutorialPath == 0 && tutorial.currentIndex == 15) {
          cancelTutorial = false;
        }
        // get runId
        dispatch(siftActions.getRunId(cancelTutorial));
      } else {
        // error
        dispatch(siftActions.errorSIFT(inputs.errors));
      }
    }
  }

  function onCancelSIFT() {
    if (tutorial.tutorialActive) {
      dispatch(tutorialActions.resetTutorial());
    }
    dispatch(siftActions.cancelRun(runId));
  }

  function onUpdateTab(_tab) {
    let new_tab = _tab == tab ? undefined : _tab;
    dispatch(siftActions.updateTab(new_tab));
  }

  return (
    <InputPanelWrapper>
      <div className="tab-selector">
        <div className={`tab ${selectedTab == "products" && "selected"}`} onClick={() => setSelectedTab("products")}>
          Products
        </div>
        <div className={`tab ${selectedTab == "performance" && "selected"}`} onClick={() => setSelectedTab("performance")}>
          Performance
        </div>
        <div className={`tab ${selectedTab == "finance" && "selected"}`} onClick={() => setSelectedTab("finance")}>
          Finance
        </div>
        <div className={`tab ${selectedTab == "site" && "selected"}`} onClick={() => setSelectedTab("site")}>
          Site
        </div>
      </div>

      <div className="input-manager">
        <div className="input-collapse">
          {selectedTab == "products" && (
            <div className="collapse-item">
              <div className={`collapse-header ${tab == "module" && "selected"}`} id="module" onClick={(e) => onUpdateTab(e.target.id)}>
                <div className="collapse-header-text" onClick={(e) => onUpdateTab("module")}>
                  <img src={module_icon} alt="" />
                  <span>Module</span>
                </div>
                <span className="collapse-arrow">{tab == "module" ? <DownOutlined /> : <RightOutlined />}</span>
              </div>
              {tab == "module" && (
                <div className="collapse-content-container">
                  <div className="input-panel module">
                    <InputHeader />
                    <ModuleInputs />
                  </div>
                </div>
              )}
            </div>
          )}
          {selectedTab == "products" && (
            <div className="collapse-item">
              <div className={`collapse-header ${tab == "inverter" && "selected"}`} id="inverter" onClick={(e) => onUpdateTab(e.target.id)}>
                <div className="collapse-header-text">
                  <img src={inverter_icon} />
                  <span>Inverter</span>
                </div>
                <span className="collapse-arrow">{tab == "inverter" ? <DownOutlined /> : <RightOutlined />}</span>
              </div>
              {tab == "inverter" && (
                <div className="collapse-content-container">
                  <div className="input-panel inverter">
                    <InputHeader />
                    <InverterInputs />
                  </div>
                </div>
              )}
            </div>
          )}
          {selectedTab == "products" && (
            <div className="collapse-item">
              <div className={`collapse-header ${tab == "racking" && "selected"}`} id="racking" onClick={(e) => onUpdateTab(e.target.id)}>
                <div className="collapse-header-text">
                  <img src={racking_icon} />
                  <span>Racking</span>
                </div>
                <span className="collapse-arrow">{tab == "racking" ? <DownOutlined /> : <RightOutlined />}</span>
              </div>
              {tab == "racking" && (
                <div className="collapse-content-container">
                  <div className="input-panel racking">
                    <InputHeader />
                    <RackingInputs />
                  </div>
                </div>
              )}
            </div>
          )}
          {selectedTab == "performance" && (
            <div className="collapse-item">
              <TutorialTip
                elementKey="weather"
                visible={tutorial.visibleTips.weather_location}
                placement={screenWidth < 768 ? "top" : "right"}
                customTipWidth={screenWidth < 768 ? 290 : undefined}
                customTipHeight={screenWidth < 768 ? 80 : undefined}
                content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.weather_location}
              >
                <div className={`collapse-header ${tab == "weather" && "selected"}`} id="weather" onClick={(e) => onUpdateTab(e.target.id)}>
                  <div className="collapse-header-text" onClick={(e) => onUpdateTab("weather")}>
                    <img src={weather_icon} alt="" />
                    <span>Weather</span>
                  </div>
                  <span className="collapse-arrow">{tab == "weather" ? <DownOutlined /> : <RightOutlined />}</span>
                </div>
              </TutorialTip>
              {tab == "weather" && (
                <div className="collapse-content-container">
                  <div className="input-panel weather">
                    <InputHeader />
                    <WeatherInputs />
                  </div>
                </div>
              )}
            </div>
          )}
          {selectedTab == "performance" && (
            <div className="collapse-item">
              <div className={`collapse-header ${tab == "performance" && "selected"}`} id="performance" onClick={(e) => onUpdateTab(e.target.id)}>
                <div className="collapse-header-text">
                  <img src={perf_icon} />
                  <span>Performance</span>
                </div>
                <span className="collapse-arrow">{tab == "performance" ? <DownOutlined /> : <RightOutlined />}</span>
              </div>
              {tab == "performance" && (
                <TutorialTip
                  elementKey="performance"
                  visible={tutorial.visibleTips.perf_params}
                  placement={screenWidth < 768 ? "top" : "right"}
                  customTipWidth={screenWidth < 768 ? 290 : undefined}
                  customTipHeight={screenWidth < 768 ? 65 : undefined}
                  content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.perf_params}
                >
                  <div className="collapse-content-container">
                    <div className="input-panel performance">
                      <InputHeader />
                      <PerformanceInputs />
                    </div>
                  </div>
                </TutorialTip>
              )}
            </div>
          )}

          {selectedTab == "finance" && (
            <div className="collapse-item">
              <TutorialTip
                elementKey="finance"
                visible={tutorial.visibleTips.finance_calcs}
                showPricingLink={true}
                placement={screenWidth < 768 ? "top" : "right"}
                customTipWidth={screenWidth < 768 ? 120 : undefined}
                customTipHeight={screenWidth < 768 ? 80 : undefined}
                content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.finance_calcs}
              >
                <div className={`collapse-header ${tab == "finance" && "selected"}`} id="finance" onClick={(e) => onUpdateTab(e.target.id)}>
                  <div className="collapse-header-text">
                    <img src={finance_icon} />
                    <span>Finance</span>
                  </div>
                  <span className="collapse-arrow">{tab == "finance" ? <DownOutlined /> : <RightOutlined />}</span>
                </div>
              </TutorialTip>
              {tab == "finance" && (
                <div className="collapse-content-container">
                  <div className="input-panel finance" id="finance-tip">
                    <InputHeader />
                    <FinanceInputs />
                  </div>
                </div>
              )}
            </div>
          )}
          {selectedTab == "site" && (
            <div className="collapse-item">
              <TutorialTip
                elementKey="layout"
                visible={tutorial.visibleTips.layout_inputs}
                showHelpLink={true}
                placement={screenWidth < 768 ? "top" : "right"}
                customTipWidth={screenWidth < 768 ? 120 : undefined}
                customTipHeight={screenWidth < 768 ? 80 : undefined}
                content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.layout_inputs}
              >
                <div className={`collapse-header ${tab == "layout" && "selected"}`} id="layout" onClick={(e) => onUpdateTab(e.target.id)}>
                  <div className="collapse-header-text">
                    <img src={layout_icon} />
                    <span>Layout</span>
                  </div>
                  <span className="collapse-arrow">{tab == "layout" ? <DownOutlined /> : <RightOutlined />}</span>
                </div>
              </TutorialTip>
              {tab == "layout" && (
                <div className="collapse-content-container">
                  <div className="input-panel layout">
                    <div id="layout-tip">
                      <InputHeader />
                      <LayoutInputs />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {selectedTab == "site" && (
            <div className="collapse-item">
              <div className={`collapse-header ${tab == "topo" && "selected"}`} id="topo" onClick={(e) => onUpdateTab(e.target.id)}>
                <div className="collapse-header-text">
                  <img src={topo_icon} />
                  <span>Topography</span>
                </div>
                <span className="collapse-arrow">{tab == "topo" ? <DownOutlined /> : <RightOutlined />}</span>
              </div>
              {tab == "topo" && (
                <TutorialTip
                  elementKey="topo"
                  visible={tutorial.visibleTips.topo_inputs}
                  showPricingLink={true}
                  placement={screenWidth < 768 ? "top" : "right"}
                  customTipWidth={screenWidth < 768 ? 120 : undefined}
                  customTipHeight={screenWidth < 768 ? 100 : undefined}
                  content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.topo_inputs}
                >
                  <div className="collapse-content-container">
                    <div className="input-panel topo">
                      <div id="topo-tip">
                        <InputHeader />
                        <TopoInputs />
                      </div>
                    </div>
                  </div>
                </TutorialTip>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="generate-buttons-container">
        <div className="input-panel config">
          {/* {siftRunning && <ResultTab />} */}
          {(siftRunning || generated) && (
            <div className="input-collapse config">
              <div className="collapse-item">
                <div className="collapse-content-container results">
                  <ResultTab />
                </div>
                <div
                  className={`collapse-header ${output_detail_visible && "selected"}`}
                  id="results"
                  onClick={(e) => {
                    // onUpdateTab(e.target.id);
                    dispatch(siftActions.toggleHelpToolbarVisibility("output_detail_visible", !output_detail_visible));
                  }}
                >
                  <div className="collapse-header-text">
                    <img src={results_icon} />
                    <span>/results</span>
                  </div>
                  <span className="collapse-arrow">{output_detail_visible ? <RightOutlined /> : <UpOutlined />}</span>
                </div>
              </div>
            </div>
          )}

          <div className="input-collapse config">
            <div className="collapse-item">
              {tab == "config" && (
                <div className="collapse-content-container">
                  <div className="input-panel config">
                    <TutorialTip
                      elementKey="config-tip"
                      customTipHeight={100}
                      customTipWidth={screenWidth < 768 ? -340 : undefined}
                      visible={tutorial.visibleTips.config_tip}
                      highlight={true}
                      placement={screenWidth < 768 ? "top" : "right"}
                      content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.config_tip}
                    >
                      <ConfigInputs />
                    </TutorialTip>
                  </div>
                </div>
              )}
              <div></div>
              <div className={`collapse-header ${tab == "config" && "selected"}`} id="config" onClick={(e) => onUpdateTab(e.target.id)}>
                <div className="collapse-header-text">
                  <img src={config_icon} />
                  <span>/config</span>
                </div>
                <span className="collapse-arrow">{tab == "config" ? <UpOutlined /> : <RightOutlined />}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="run-button">
          {/* <Tooltip placement="right" title={!isAuthed ? 'Log In to use SIFT.' : !boundary_exists ? 'Draw or Import Boundary into the Map.' : null}> */}
          <TutorialTip
            elementKey="run-tip"
            visible={!siftRunning && !isComplete && tutorial.visibleTips.run_sift}
            highlight={true}
            customTipWidth={screenWidth < 768 ? -360 : 290}
            customTipHeight={50}
            placement="top"
            content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.run_sift}
          >
            <Button
              // block
              id="run-tip"
              type={siftRunning ? "ghost" : "primary"}
              style={{ fontWeight: "700", fontSize: "16px" }}
              // htmlType="submit"
              onClick={() => (!isAuthed ? onLogin() : siftRunning ? onCancelSIFT() : onInitSIFT())}
              // loading={siftRunning}
              disabled={!isAuthed || (siftRunning && runId == undefined) || show_agreement_modal || show_terms}
              loading={siftCanceling}
            >
              {siftRunning ? (
                "CANCEL RUN"
              ) : siftCanceling ? (
                "CANCELING RUN"
              ) : (
                <div className="run-button-content">
                  <span>RUN SIFT</span>
                  <CaretRightOutlined style={{ fontSize: "18px" }} />
                </div>
              )}
            </Button>
          </TutorialTip>
          {/* </Tooltip> */}
        </div>
      </div>

      <button className="toggle-inputs-button" onClick={() => dispatch(siftActions.toggleMobileInputs(!toggleMobileInputs))}>
        {toggleMobileInputs ? <CaretLeftOutlined style={{ fontSize: "30px" }} /> : <CaretRightOutlined style={{ fontSize: "30px" }} />}
      </button>
    </InputPanelWrapper>
  );
};

export { InputPanel };
