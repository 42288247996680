import React, { useEffect, useState, useRef } from "react";

// components
import { ProjectManager, InputPanel, OutputPanel, Map, ImportTool, TutorialNav, TutorialNew, AuthModalNEW, Notification, RefreshTokenModal, AgreementUpdate, Agreement } from "../index";
import { ProjectWrapper, InputPanelWrapper, MapWrapper } from "./StyledComponents";
import { LayerToggleMenu } from "../LayerToggleMenu";

// antd
import { Spin, Upload, Alert } from "antd";
const Dragger = Upload.Dragger;

// redux
import { useSelector, useDispatch } from "react-redux";
import { isAuthenticated, siftActions, authActions, accountActions, alertActions, tutorialActions } from "../Redux";

// kmz/kml libs
import ZipLoader from "zip-loader";
import XMLParser from "react-xml-parser";
import textEncoding from "text-encoding";

export const Project = (props) => {
  const isAuthed = useSelector((state) => isAuthenticated(state));
  const show_agreement_modal = useSelector((state) => state.authentication.show_agreement_modal);
  const ioManager = useSelector((state) => state.sift.ioManager);
  const show_layer_menu = useSelector((state) => state.sift.ioManager.uiState.layer_toggle.show_layer_toggle_menu);
  const runId = useSelector((state) => state.sift.ioManager.outputs.runState.runId);
  const toggleMobileInputs = useSelector((state) => state.sift.ioManager.uiState.toggleMobileInputs);
  const loading_map = useSelector((state) => state.sift.ioManager.inputs.map.loading_map);
  const loading_msg = useSelector((state) => state.sift.ioManager.inputs.map.loading_msg);
  const current_plan = useSelector((state) => state.account.current_plan);
  const tutorialActive = useSelector((state) => state.tutorial.tutorialActive);
  const tutorialPath = useSelector((state) => state.tutorial.tutorialPath);
  const tutorialNavVisible = useSelector((state) => state.tutorial.showTutorialNav);
  const agreement_visible = useSelector((state) => state.authentication.show_agreement_modal);

  const dispatch = useDispatch();

  //Tutorial Controls
  const tutorial = useSelector((state) => state.tutorial);
  const hideTutorialToken = window.localStorage.getItem("hideTutorial");
  const exampleRunning = useSelector((state) => state.sift.ioManager.outputs.runState.running);
  const [isComplete, setIsComplete] = useState(false);

  // useEffect(() => {
  //   let tac_accepted = localStorage.getItem("TOS_Agreement");
  //   if (tac_accepted == 0 || tac_accepted == null || tac_accepted == undefined || Math.sign(tac_accepted) == -1) {
  //     dispatch(authActions.showAgreementModal(true));
  //   }
  // }, []);

  useEffect(() => {
    if (isAuthed && !hideTutorialToken) {
      window.localStorage.setItem("hideTutorial", false);
    }
  }, [isAuthed]);

  // used for the tutorial. Just checks to see if the example project is complete
  useEffect(() => {
    if (Object.values(ioManager.outputs.results).length > 0) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }, [ioManager.outputs.results]);

  useEffect(() => {
    let tutorialVisible = localStorage.getItem("hideTutorial");

    if (tutorialVisible == "false" && tutorial.tutorialActive !== false) {
      dispatch(tutorialActions.startTutorial());
    }
  }, [tutorial.tutorialActive]);

  // used be logged out alert
  function handleOpenLogin(tab) {
    dispatch(authActions.toggleLoginVis(true, tab));
  }

  // used by kmz importer / map -- can be refactored
  function handleMarkerUpdate(mark) {
    dispatch(siftActions.updateMarker(mark));
  }
  function handleTopoUpdate(key, value) {
    dispatch(siftActions.updateTopo(key, value));
  }
  // KML IMPORT FUNCTIONS
  async function handleLoadFile(file, fileList) {
    // old method, front-end parsing of the KMZ/KML file
    // dispatch(siftActions.importKMZ(file));
    // return;

    // Moved some of the file operation code here, so that we can extract the XML data
    // before sending it to the backend for parsing..
    // TOOD: should we just send the file and unzip it on the backend so that it's smaller in transit???
    let file_type = file.name.toLowerCase().split(".");
    file_type = file_type[file_type.length - 1];
    var options = {
      // xml: undefined,
      // name: '',
      // error: undefined,
      // file_type: file_type,

      // "data_type": importType,
      file_type: "xml",
      file_name: file.name,
      tab: "map",
    };
    if (file_type == "kml") {
      var reader = new FileReader();
      reader.onloadend = function (evt) {
        if (evt.target.readyState == FileReader.DONE) {
          let upload_data = evt.target.result;
          dispatch(siftActions.importData(options, upload_data));
        }
      };
      reader.readAsBinaryString(file);
    } else if (file_type == "kmz") {
      // If file is *.kmz we need to unzip the .kml to get the polygons
      var instance = await ZipLoader.unzip(file);
      // unzipping the doc.kml returns an object that holds the reference to a Utf8Array buffer which holds the kml info
      var TextDecoder = textEncoding.TextDecoder;
      try {
        // Decode the buffer into a string
        var files_names = Object.keys(instance.files);
        for (var _index in files_names) {
          let _fileName = files_names[_index].toLowerCase().split(".");
          if (_fileName[_fileName.length - 1] == "kml") {
            var string = new TextDecoder("utf-8").decode(instance.files[files_names[_index]]["buffer"]);
            // parse the xml document from the string
            let upload_data = string;
            dispatch(siftActions.importData(options, upload_data));
          }
        }
        // output.features = all_features
      } catch (error) {
        console.log("KMZ Import Error", error);
        // output.error = error;
      }
    }

    // if (file_type == 'kml') {
    //   async function getStringFileOutput(file) {
    //     return new Promise((resolve, reject) => {
    //       var reader = new FileReader();

    //       reader.onload = (function (file) {
    //         // var fileName = file.name;
    //         return function (evt) {
    //           if (evt.target.readyState == FileReader.DONE) {
    //             // DONE == 2
    //             // output.name = file.name;
    //             let upload_data = evt.target.result;
    //             // output.xml = new XMLParser().parseFromString(evt.target.result)
    //             // output.xml = evt.target.result;

    //             dispatch(siftActions.importData(options, upload_data));
    //             resolve(output);
    //           }
    //         };
    //       })(file);
    //       reader.readAsBinaryString(file);
    //     });
    //   }
    //   // let resp = await getStringFileOutput(file)
    //   // return resp
    //   output = await getStringFileOutput(file);
    // } else if (file_type == 'kmz') {
    //   // If file is *.kmz we need to unzip the .kml to get the polygons
    //   var instance = await ZipLoader.unzip(file);
    //   // unzipping the doc.kml returns an object that holds the reference to a Utf8Array buffer which holds the kml info
    //   var TextDecoder = textEncoding.TextDecoder;
    //   try {
    //     // Decode the buffer into a string
    //     var all_features = [];
    //     var files_names = Object.keys(instance.files);
    //     output.name = file.name;
    //     for (var file in files_names) {
    //       if (files_names[file].substr(-3, 3).toLowerCase() == 'kml') {
    //         var string = new TextDecoder('utf-8').decode(instance.files[files_names[file]]['buffer']);
    //         // parse the xml document from the string
    //         // output.xml = new XMLParser().parseFromString(string)
    //         // output.xml = string;
    //         let upload_data = string;
    //         dispatch(siftActions.importData(options, upload_data));

    //       }
    //     }
    //     // output.features = all_features
    //   } catch (error) {
    //     console.log(error);
    //     output.error = error;
    //   }
    // }

    // console.log(output);
    // dispatch(siftActions.parseXML(output));
  }
  return (
    <ProjectWrapper>
      <InputPanelWrapper toggleMobileInputs={toggleMobileInputs} isAuthed={isAuthed} show_agreement_modal={show_agreement_modal}>
        <ProjectManager />
        <InputPanel toggleMobileInputs={toggleMobileInputs} />
      </InputPanelWrapper>

      <MapWrapper>
        {/* replace this a warning component that can show other warnings */}
        {/* <div className="warning-container">
          {!isAuthed && (
            <div className="warning-alert">
              <Alert
                className="sift-alert"
                message={
                  <span>
                    <a
                      onClick={() => {
                        handleOpenLogin('login');
                      }}
                    >
                      <b>Log In</b>
                    </a>
                    <span> or </span>
                    <a
                      onClick={() => {
                        dispatch(accountActions.toggleFunnelVis(true));
                      }}
                    >
                      <b>Register</b>
                    </a>
                    <span> to try SIFT for free. </span>
                    <span>
                      {' '}
                      Click{' '}
                      <span style={{ color: '#002bcb' }}>
                        <b>?</b>
                      </span>{' '}
                      to learn more.
                    </span>
                  </span>
                }
                type="warning"
                showIcon
              />
            </div>
          )}
        </div> */}

        {isAuthed && current_plan === 1 && !agreement_visible && <Notification />}
        <AuthModalNEW />
        {isAuthed && hideTutorialToken === "false" && tutorialActive && !isComplete && tutorialPath !== 0 && !tutorialNavVisible && <TutorialNew />}
        {(tutorialPath == 0 || tutorialPath == 1) && tutorial.content && !exampleRunning && tutorialNavVisible && <TutorialNav />}
        {show_layer_menu && <LayerToggleMenu />}
        {/* {refresh_visible && <RefreshTokenModal />} */}
        <AgreementUpdate />
        <Agreement />

        {runId && <OutputPanel />}

        <div className="map-container">
          {/* <UploadComponent type="kmz" > </UploadComponent> */}
          <Dragger
            style={{ height: "100%" }}
            className="map-uploader"
            action={null}
            customRequest={null}
            beforeUpload={handleLoadFile}
            multiple={false}
            name="kml-file"
            accept=".kmz,.kml"
            openFileDialogOnClick={false}
            showUploadList={false}
          >
            <Map onMarkerUpdated={handleMarkerUpdate} OnChangeTopoLayer={handleTopoUpdate}></Map>
          </Dragger>
          <Spin spinning={loading_map} tip={loading_msg || "Map Loading..."} className="map-loader"></Spin>
        </div>
      </MapWrapper>
    </ProjectWrapper>
  );
};
