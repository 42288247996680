import React, { Fragment } from "react";
import { satellite_view, delete_polygon, draw_polygon, export_map, stree_view, search_map, toggle_topo } from "../../assets/images";
import { Button, Tooltip, Input, Menu, Dropdown, Popconfirm, Radio, Checkbox } from "antd";
const Search = Input.Search;

import { connect } from "react-redux";

//
class Toolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_toolbar: 0,
    };
  }

  componentWillReceiveProps(props) {
    const { activeTool } = props;
    if (activeTool != this.props.activeTool) {
      this.setState({ selected_toolbar: activeTool });
    }
  }

  render() {
    // console.log('loading Toolbar ', this.props)
    const toolbarContainer = {
      display: this.props.screenWidth > 768 ? "block" : "flex",
      position: "relative",
      left: this.props.screenWidth > 768 ? "0px" : "85px",
      // width: 0,
      // height: 0,
      // paddingLeft: 0,
      // paddingRight: 0,
      // paddingTop: 0,
      // paddingBottom: 0,
    };

    const toolbarSubmenu = {
      width: 1,
      height: 1,
      // background: '#fff',
      // background: "#00FFFFFF",
      // opacity: 0.0,
      left: this.props.screenWidth > 768 ? 40 : 80,
      top: this.props.screenWidth > 768 ? -5 : 50,
      display: "block",
      position: "absolute",
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 5,
    };
    const menu = () => (
      <Menu mode="vertical" theme="light">
        <Menu.Item
          key="KML"
          onClick={() => {
            this.props.onToolBarPressed(5);
          }}
        >
          Export to KMZ
        </Menu.Item>
        <Menu.Item
          key="DXF"
          onClick={() => {
            this.props.onToolBarPressed(7);
          }}
        >
          Export to CAD
        </Menu.Item>
      </Menu>
    );

    const mapToolbarMenu = {
      width: "30px",
      height: "30px",
      paddingLeft: "1px",
      paddingRight: "1px",
      paddingTop: "1px",
      paddingBottom: "1px",
      border: "1px solid transparent",
      boxShadow: "0 2px 0 rgb(0 0 0 / 2%)",
      borderRadius: "4px",
    };
    // ant-menu-submenu-title {
    //   padding: 0px !important;
    // }

    // ant-menu-submenu ant-menu-submenu-vertical {
    //   width: 26px;
    // }

    return (
      <Fragment>
        <div style={toolbarContainer}>
          <Tooltip placement="right" title="Search" mouseEnterDelay={0.5}>
            <Button className="toolbar-button" type={this.state.selected_toolbar == 1 ? "primary" : "default"} onClick={() => this.props.onToolBarPressed(1)}>
              <img src={search_map} className="toolbar-control" style={{ filter: this.state.selected_toolbar == 1 ? "invert(100%)" : "unset" }}></img>
              {/* <Icon type="search" /> */}
            </Button>
          </Tooltip>
          <div className="toolbar-spacing" />
          <Tooltip placement="right" title="Change BaseMap" mouseEnterDelay={0.5}>
            <Button
              className="toolbar-button"
              type="default"
              onClick={() => {
                if (this.state.selected_toolbar == 2) {
                  this.setState({ selected_toolbar: 0 });
                } else {
                  this.props.onToolBarPressed(2);
                }
              }}
            >
              <img src={this.props.tileSet == "ArcGIS" ? satellite_view : stree_view} className="toolbar-control"></img>
            </Button>

            {/* new layer menu half built lol */}
            {/* <Menu onClick={()=>{}}  mode="horizontal" className="map-toolbar-menu" style={mapToolbarMenu}>
              <Menu.SubMenu key="sub1" expandIcon={<img src={this.props.tileSet == 'ArcGIS' ? satellite_view : stree_view} className="toolbar-control"></img>} title="">
                <Menu.Item
                  key="KML"
                  onClick={() => {
                    this.props.onToolBarPressed(5);
                  }}
                >
                  Export to KMZ
                </Menu.Item>
                <Menu.Item
                  key="DXF"
                  onClick={() => {
                    this.props.onToolBarPressed(7);
                  }}
                >
                  Export to CAD
                </Menu.Item>
              </Menu.SubMenu>
            </Menu> */}
          </Tooltip>
          <div className="toolbar-spacing" />
          <Tooltip placement="right" title="Draw" mouseEnterDelay={0.5}>
            <Button
              className="toolbar-button"
              type={this.props.isDrawing ? "primary" : "default"}
              onClick={() => {
                this.props.onToolBarPressed(3);
              }}
            >
              <img src={draw_polygon} className="toolbar-control" style={{ filter: this.props.isDrawing ? "invert(100%)" : "unset" }}></img>
            </Button>
          </Tooltip>
          <div className="toolbar-spacing" />
          <Tooltip placement="right" title="Delete All" mouseEnterDelay={0.5}>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => {
                this.props.onToolBarPressed(4);
              }}
            >
              <Button className="toolbar-button" type="default">
                <img src={delete_polygon} className="toolbar-control"></img>
              </Button>
            </Popconfirm>
          </Tooltip>
          <div className="toolbar-spacing" />
          {/* <div style={{marginTop:'-6px'}} /> */}
          <Tooltip placement="right" title="Export Drawing" mouseEnterDelay={0.5}>
            <Dropdown trigger={["click"]} overlay={menu()} placement="bottomLeft">
              <Button
                className="toolbar-button"
                // type= {this.state.selected_toolbar == 5 ? 'primary' : 'default'}
                type="default"
                // onClick={() => this.props.onToolBarPressed(5) }
              >
                <img src={export_map} className="toolbar-control"></img>
              </Button>
            </Dropdown>
          </Tooltip>

          {/* TODO: SPI  */}
          {/* <div className='toolbar-spacing' />
            <Tooltip placement='right' title='Toggle Topography' mouseEnterDelay={0.5}>
                <Button className='toolbar-button'                 
                    // type= {this.state.selected_toolbar == 5 ? 'primary' : 'default'} 
                    type='default'
                onClick={() => this.props.onToolBarPressed(6) }>
                <img src={toggle_topo} className='toolbar-control'></img>
                </Button>   
            </Tooltip>             */}
        </div>

        <div style={toolbarSubmenu}>
          {this.state.selected_toolbar == 1 && (
            <Search id="location_search" placeholder="Latitude, Longitude or Address" onSearch={(value) => this.props.OnSearchLocation(value)} style={{ width: 240 }} />
          )}
          {/* {this.props.isEditing &&
                <div>
                    <Button onClick={() => this.props.onToolBarPressed(77) }>Set as Boundary</Button>
                    <Button onClick={() => this.props.onToolBarPressed(78) }>Set as Exclusion</Button>
                    <Button onClick={() => this.props.onToolBarPressed(79) }>Toggle Active</Button>
                    <Button onClick={() => this.props.onToolBarPressed(80) }>Delete</Button>
                </div>} */}

          {this.state.selected_toolbar == 2 && (
            <div className="map-layers-menu">
              <div className="layers-header">Map Layers</div>
              <div className="layers-selector">
                <p>Current Basemap:</p>
                <div>
                  <Radio.Group style={{ display: "flex", flexDirection: "column", marginBottom: 8 }} value={this.props.tileSet} onChange={(e) => this.props.handleUpdateTileset(e.target.value)}>
                    {this.props.user && this.props.user.product_plan_paid > 1 && (
                      <Radio value="SatelliteHD" checked={this.props.tileSet == "SatelliteHD"}>
                        Satellite HD
                      </Radio>
                    )}
                    {this.props.user && this.props.user.product_plan_paid > 1 && (
                      <Radio value="Satellite_With_Labels" checked={this.props.tileSet == "Satellite_With_Labels"}>
                        Satellite w/Labels
                      </Radio>
                    )}
                    <Radio value="Satellite" checked={this.props.tileSet == "Satellite"}>
                      Satellite {this.props.user && this.props.user.product_plan_paid > 1 && "LoRes"}
                    </Radio>
                    {this.props.user && this.props.user.product_plan_paid > 1 ? (
                      <Radio value="StreetMapHD" checked={this.props.tileSet == "StreetMapHD"}>
                        Streets Map
                      </Radio>
                    ) : (
                      <Radio value="StreetMap" checked={this.props.tileSet == "StreetMap"}>
                        Streets map
                      </Radio>
                    )}

                    {this.props.user && this.props.user.product_plan_paid == 1 && (
                      <Tooltip placement="top" title={`Upgrade to paid plan \nfor higher quality maps`} mouseEnterDelay={0.5}>
                        <Radio value="upsale" checked={false} disabled>
                          HD Map
                        </Radio>
                      </Tooltip>
                    )}
                  </Radio.Group>
                </div>

                <p>Additional Layers:</p>
                <div style={{ width: "100%", paddingLeft: "8px" }}>
                  <Checkbox onChange={(e) => this.props.handleUpdateAddlLayers("riparian_on")} checked={this.props.riparian_on}>
                    Riparian
                  </Checkbox>
                  <Checkbox onChange={(e) => this.props.handleUpdateAddlLayers("wetlands_on")} checked={this.props.wetlands_on}>
                    Wetlands
                  </Checkbox>
                  <Checkbox onChange={(e) => this.props.handleUpdateAddlLayers("fema_on")} checked={this.props.fema_on}>
                    FEMA
                  </Checkbox>
                </div>
              </div>
              <div className="layers-footer"></div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { sift } = state;
  const { ioManager } = sift;
  const { inputs } = ioManager;
  const { map, weather, topo } = inputs;
  const { isDrawing } = map;
  return {
    isDrawing,
  };
}

const connectedComponent = connect(mapStateToProps)(Toolbar);

export { connectedComponent as Toolbar };
