import React, { Fragment } from 'react';

// redux
import { connect } from 'react-redux';
import { isAuthenticated, authActions } from '../Redux';

// components
import { Modal } from 'antd';
import { AuthCard } from './AuthCard';

class AuthModal extends React.Component {
  constructor(props) {
    super(props);
  }

  // handle cancel funnel
  handleCloseLogin = () => {
    this.props.dispatch(authActions.toggleLoginVis(false, 'login'));
  };

  render() {
    return (
      <Modal visible={this.props.login_visible} onCancel={this.handleCloseLogin} footer={null} width={425} className="sift-modal login">
        <AuthCard />
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  const { authentication } = state;
  return {
    isAuthenticated: isAuthenticated(state),
    login_visible: authentication.login_visible,
  };
}

const connectedAuthModal = connect(mapStateToProps)(AuthModal);
export { connectedAuthModal as AuthModal };
