import React from "react";
import styled from "styled-components";

export const InputPopoutWrapper = styled.section`
  .input-popout {
    position: fixed;
    left: 385px;
    top: ${(props) => props.popoutY}px;
    width: auto;
    /* max-width: 600px; */
    height: auto;
    overflow-y: hidden;
    background-color: #fff;

    box-shadow: var(--subtle-box-shadow);
    display: grid;
    grid-template-rows: min-content min-content 1fr;
    z-index: 1000;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    .popout-close {
      text-align: right;
      padding: 5px;
    }
    .popout-content {
      padding: 5px;
      height: inherit;

      .tou-container {
				max-width: 650px;
				height: auto;
				max-height: 500px;
				overflow-y: scroll;
				overflow-x: hidden;

        .custom-schedule-label {
          z-index: 511;
          text-align: center;
          margin: 5px auto;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          display: inline-block;
          pointer-events: none;
        }
        .custom-schedule-link {
          grid-row: 2;
          text-decoration: underline;
        }
        .custom-schedule-table {
          /* grid-row: 3;
          height: inherit;
          overflow-y: scroll; */
        }
      }
    }

    @media (max-width: 768px) {
      left: 20px;
      right: 20px;
      margin: auto;
      z-index: 510;
    }
  }
  .weather-popup {
    width: 400px;
  }
`;

const InputPopout = (props) => {
  // console.log(props)
  return (
    <InputPopoutWrapper popoutY={props.popoutY}>
      {props.visible && (
        <section className="input-popout">
          <section className="popout-close">
            <a onClick={props.onClose}>
              <u>Close</u>
            </a>
          </section>
          <section className="popout-content">{props.children}</section>
        </section>
      )}
    </InputPopoutWrapper>
  );
};

export { InputPopout };
