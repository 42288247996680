import styled from "styled-components";

export const AuthModalWrapper = styled.section`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 604;
  pointer-events: all;

  .main-login {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: min-content;
    height: 500px;
    max-height: 500px;
    z-index: 605;
    display: grid;
    grid-template-columns: repeat(2, minmax(275px, 350px));
    box-shadow: var(--subtle-box-shadow);
    cursor: default;

    .welcome-col {
      /* width: 100%; */
      /* height: 100%; */
      border-right: 2px solid var(--primary-brand-color);
      background-color: var(--gutter-background-color);
      border-radius: 3px 0px 0px 3px;
      grid-column: 1;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 65px 200px auto;
      padding: 20px;
      gap: 10px;

      .logo {
        grid-row: 1;
        width: 100%;
        display: grid;
        place-items: center;

        svg {
          width: 130px;
        }
      }
      .welcome-text {
        grid-row: 2;
        width: 100%;
        display: grid;
        place-items: start center;

        .welcome-header-text {
          margin: 0px auto 15px 15px;
          h1 {
            font-size: 18px;
            font-weight: 500;
          }
        }

        .welcome-text-list {
          width: 90%;
          height: 100%;
          /* list-style: none; */
          padding: 0px;
          li.helper {
            white-space: nowrap;
            margin-top: 15px;
          }

          li {
            display: flex;
            align-items: flex-start;
            justify-self: center;

            img {
              width: 10px;
              margin: 6px 8px 0px 0px;
            }
            .anticon.anticon-caret-right {
              margin-top: 2px;
            }

            p {
              font-size: 12px;
              text-align: left;

              & > span {
                img {
                  width: 12px;
                  margin: 0px 2px 2px 0px;
                }
              }
            }
          }

          .schedule-a-demo {
            width: 100%;
            display: grid;
            place-items: center;
          }
        }

        .error {
          grid-row: 3;
          color: red;
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          img {
            height: 15px;
            margin-right: 10px;
            /* margin-bottom: 10px; */
          }
          p {
            text-align: left;
            margin: 0px;
            font-size: 12px;
          }
        }
      }

      @media (max-width: 768px) {
        padding: 25px 0px;

        .logo {
          img {
            width: 150px;
          }
        }

        .welcome-text {
          display: grid;
          place-items: center;
          .welcome-header-text {
            h1 {
              font-size: 16px;
              margin: 0 auto;
            }
          }

          .welcome-text-list {
            padding: 0px;
            li.helper {
              white-space: break-spaces;
              margin: 15px auto 0px auto;
              width: 16ch;
            }
          }
        }
      }
    }

    .login-register-col {
      width: 100%;
      height: 100%;
      background-color: var(--primary-text-white);
      border-radius: 0px 3px 3px 0px;
      grid-column: 2;
      display: grid;
      grid-template-rows: 70px 1fr;

      .login-register-tabs {
        grid-row: 1;
        list-style: none;
        display: flex;
        justify-content: flex-end;
        margin: 0px;
        padding: 10px;

        li {
          margin: 0px 10px;
          button {
            background: transparent;
            border: none;
            color: var(--primary-brand-color);
            font-size: 16px;
            font-weight: 700;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          }
        }
        .bottom-highlight {
          border-bottom: 2px solid var(--primary-brand-color);
        }
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, minmax(200px, 350px));
    }
  }
`;

export const LoginWrapper = styled.section`
  width: 100%;
  height: 100%;
  grid-row: 2;

  form {
    height: 100%;
    display: grid;
    grid-template-rows: auto auto 1fr;

    .form-input {
      width: 90%;
      height: 75px;
      display: flex;
      flex-direction: column;
      margin: 6px auto;
      text-align: left;

      & > label {
        margin-bottom: 5px;
      }

      .password-box {
        width: 100%;
        margin-bottom: 5px;

        label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            a {
              color: var(--primary-brand-color);
              @media (max-width: 768px) {
                white-space: nowrap;
                font-size: 12px;
              }
            }
          }
        }
        p {
          width: 90%;
          text-align: left;
          margin-bottom: 20px;
        }
      }

      input {
        padding: 8px;
        border: 0px solid rgb(60, 66, 87);
        border-radius: 3px;
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
          rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;

        &:focus,
        &:active {
          border-color: #002bcb;
          border-width: 1px !important;
          outline: 0;
          box-shadow: 0 0 0 2px rgba(0, 43, 203, 0.2);
        }
      }
    }

    .form-alert {
      display: flex;
      justify-content: center;
      align-items: baseline;
      margin: 10px 0px auto 0px;
      color: red;
      img {
        height: 10px;
        margin-right: 5px;
      }
      p {
        color: red;
        margin: 0;
      }
    }

    button {
      width: 85%;
      height: auto;
      display: grid;
      place-items: center;
      border-radius: 3px;
      border: none;
      padding: 8px;
      margin: auto auto 50px auto;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      background: var(--primary-brand-color);
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;

      .button-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        cursor: pointer;
        background: #002bcba9;
      }
    }
  }
`;

export const RegisterWrapper = styled.section`
  width: 100%;
  height: 100%;
  grid-row: 2;

  form {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto auto auto auto 1fr auto;

    .form-input {
      width: 90%;
      height: 75px;
      display: flex;
      flex-direction: column;
      margin: 6px auto;
      text-align: left;

      .pw-error {
        font-size: smaller;
        color: red;
      }

      label {
        margin-bottom: 5px;
      }

      input {
        padding: 8px;
        border: 0px solid rgb(60, 66, 87);
        border-radius: 3px;
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
          rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;

        &:focus,
        &:active {
          border-color: #002bcb;
          border-width: 1px !important;
          outline: 0;
          box-shadow: 0 0 0 2px rgba(0, 43, 203, 0.2);
        }
      }
    }

    .terms {
      display: flex;
      align-items: center;
      justify-self: center;
      margin-top: 10px;
      input[type="checkbox"] {
        margin-right: 10px;
        border: 0px solid rgb(60, 66, 87);
        border-radius: 3px;
      }

      p {
        margin: 0;
        span {
          a {
            color: var(--primary-brand-color);
            &:hover {
              color: #002bcba9;
            }
          }
        }
      }
    }

    button {
      width: 85%;
      height: auto;
      display: grid;
      place-items: center;
      border-radius: 3px;
      border: none;
      padding: 8px;
      margin: auto auto 20px auto;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      background: var(--primary-brand-color);
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;

      .button-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        cursor: pointer;
        background: #002bcba9;
      }
    }

    .pricing-link {
      /* width: 100%; */
      padding: 0px 12px 10px 0px;
      text-align: right;
      a {
        color: var(--primary-brand-color);
      }
    }
    .form-alert {
      display: flex;
      align-items: center;
      margin: 5px 0px;
      img {
        height: 10px;
        margin-right: 5px;
      }
      p {
        color: red;
        margin: 0;
      }
    }
  }
`;
