import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { accountActions } from '../../../Redux';

import { Skeleton, List } from 'antd';

const Billing = (props) => {
  const dispatch = useDispatch();
  const downloading_invoice = useSelector((state) => state.account.downloading_invoice);

  function getSIFTTotal(usage, limit) {
    var total_used = 0,
      total_cost = 0;
    for (var i = 0; i < usage.length; i++) {
      total_used += usage[i].usage_count;
      total_cost += usage[i].cost;
    }

    total_used += props.local_run_count;
    if (limit > 10000) return `Totals ${total_used}/∞`;
    else return `Totals ${total_used}/${limit}`;
  }
  function getAPITotal(usage) {
    var total_used = 0,
      total_cost = 0;
    for (var i = 0; i < usage.length; i++) {
      total_used += usage[i].usage_count;
      total_cost += usage[i].cost;
    }
    let request_text = usage.usage_count > 1 ? 'requests' : 'request';
    return `Total Usage - ${total_used} ${request_text} - $${total_cost}`;
  }

  function getTime(editDate) {
    var date = new Date(editDate * 1000);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;

    let format_d = `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;

    return format_d.toString();
  }

  function download(invoice) {
    // console.log('downloading', invoice)
    dispatch(accountActions.downloadInvoice(invoice))

  }

  const { usage, products, user, invoices } = props;
  let loaded = false;
  let sift_usage = [];
  let api_usage = [];
  let product = {};

  if (usage) {
    loaded = true;
    sift_usage = usage.filter((u) => u.resource_name.substring(0, 4) == 'sift');
    // console.log(sift_usage);
    api_usage = usage.filter((u) => u.resource_name.substring(0, 4) != 'sift');
    let productPlan = user.product_plan_paid;
    product = products[productPlan - 1];
  }

  if (!loaded) {
    return (
      <div style={{ padding: '7%' }}>
        <Skeleton active />
      </div>
    );
  } else {
    return (
      <div className="billing-container">
        <h3 style={{ margin: '16px 0' }}>SIFT</h3>
        <List
          size="small"
          header={
            <div>
              SIFT {product.name} Plan ${product.monthly_cost}
            </div>
          }
          footer={
            <div>
              <h4> {getSIFTTotal(sift_usage, product.run_limit)} </h4>
            </div>
          }
          bordered
          dataSource={sift_usage}
          renderItem={(item) => <List.Item>SIFT Runs - {item.usage_count + props.local_run_count}</List.Item>}
        />

        {/* { user.product_plan_paid > 1 &&
            <div>
            <h3 style={{ margin: '16px 0' }}>API</h3>
            <List
                size="small"
                // header={<div>Header</div>}
                footer={<div><h4> {getAPITotal(api_usage)} </h4></div>}
                bordered
                dataSource={api_usage}
                renderItem={item => (<List.Item>{item.resource_name} - {item.usage_count} {item.usage_count > 1 ? 'requests' : 'request'} - ${item.cost}</List.Item>)}
                />        
            </div>
            } */}

        <h3 style={{ margin: '16px 0' }}>Invoices</h3>
        <List
          size="small"
          loading={downloading_invoice}
          // header={<div>Header</div>}
          // footer={<div><h4> {getAPITotal(api_usage)} </h4></div>}
          bordered
          dataSource={invoices}
          renderItem={(item) => (
            <List.Item>
              {getTime(item.date_created)} - ${item.amount} - {item.details} - <a onClick={()=>download(item)}>Download</a>
            </List.Item>
          )}
        />
      </div>
    );
  }
};

export { Billing };
