import React from 'react';

import './apiCarousel.css';

import { Carousel } from 'antd';

const APICarousel = ({ items, height, altDescription }) => {
  return (
    <div style={{ height }}>
      <Carousel autoplay className="home-carousel" autoplaySpeed={5000}>
        {items.map((item, index) => {
          return (
            <div key={index}>
              <img src={item} alt={altDescription && altDescription}></img>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default APICarousel;
