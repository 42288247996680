import React, { useState, useEffect } from "react";
import ReactDataSheet from "react-datasheet";

// styles and assets
import { Input, Switch, Tooltip, Radio, Select } from "antd";

//components
import { TitleDivider } from "../../TitleDivider";
import { InputPopout } from "../InputPopout";
import { SheetRenderer } from "../SheetRenderer";
import { FinanceOperatingCosts, FinanceInstallCosts } from "./";

//redux
import { useSelector, useDispatch } from "react-redux";
import { siftActions } from "../../Redux";

const RevenueOptions = [
  { label: "Fixed", value: 0 },
  { label: "TOU", value: 1 },
];

const op_columns = [
  { label: "", width: "10%" },
  { label: "", width: "22.5%" },
  { label: "", width: "22.5%" },
  { label: "", width: "22.5%" },
  { label: "", width: "22.5%" },
];

const ri_columns = [
  { label: "", width: "5%" },
  { label: "", width: "45%" },
];
const tou_columns = [
  { label: "", width: "5%" },
  { label: "", width: "7%" },
  { label: "", width: "7%" },
  { label: "", width: "7%" },
  { label: "", width: "7%" },
  { label: "", width: "7%" },
  { label: "", width: "7%" },
  { label: "", width: "7%" },
  { label: "", width: "7%" },
  { label: "", width: "7%" },
  { label: "", width: "7%" },
  { label: "", width: "7%" },
  { label: "", width: "7%" },
  { label: "", width: "7%" },
];

const renderSheet = (props) => {
  return <SheetRenderer columns={props.cols} {...props} />;
};

const FinanceInputs_IRR = ({ expand }) => {
  const dispatch = useDispatch();

  // store
  const account_loading = useSelector((state) => state.account.account_loading);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const do_finance = useSelector((state) => state.sift.ioManager.inputs.config.data.do_finance);
  const do_ri_customSchedule = useSelector((state) => state.sift.ioManager.inputs.finance.data.do_ri_customSchedule);

  const doCustomSchedule = useSelector((state) => state.sift.ioManager.inputs.finance.data.doCustomSchedule);
  const revenue_type = useSelector((state) => state.sift.ioManager.inputs.finance.data.revenue_type);
  const metric = useSelector((state) => state.sift.ioManager.inputs.finance.data.metric);

  // values
  const analysis_period = useSelector((state) => state.sift.ioManager.inputs.finance.data.analysis_period);
  const ri_customSchedule = useSelector((state) => state.sift.ioManager.inputs.finance.data.ri_customSchedule);
  const ri_power = useSelector((state) => state.sift.ioManager.inputs.finance.data.ri_power);
  const ri_escalation = useSelector((state) => state.sift.ioManager.inputs.finance.data.ri_escalation);
  const do_include_itc = useSelector((state) => state.sift.ioManager.inputs.finance.data.do_include_itc);
  const itc_percent = useSelector((state) => state.sift.ioManager.inputs.finance.data.itc_percent);
  const tou_grid = useSelector((state) => state.sift.ioManager.inputs.finance.data.tou_grid);
  const debt_structure = useSelector((state) => state.sift.ioManager.inputs.finance.data.debt_structure);
  const debt_percent = useSelector((state) => state.sift.ioManager.inputs.finance.data.debt_percent);
  const debt_dscr = useSelector((state) => state.sift.ioManager.inputs.finance.data.debt_dscr);
  const debt_interest = useSelector((state) => state.sift.ioManager.inputs.finance.data.debt_interest);
  const debt_tenor = useSelector((state) => state.sift.ioManager.inputs.finance.data.debt_tenor);
  const dep_5yrSL = useSelector((state) => state.sift.ioManager.inputs.finance.data.dep_5yrSL);
  const dep_15yrSL = useSelector((state) => state.sift.ioManager.inputs.finance.data.dep_15yrSL);
  const dep_20yrSL = useSelector((state) => state.sift.ioManager.inputs.finance.data.dep_20yrSL);
  const dep_30yrSL = useSelector((state) => state.sift.ioManager.inputs.finance.data.dep_30yrSL);
  const dep_35yrSL = useSelector((state) => state.sift.ioManager.inputs.finance.data.dep_35yrSL);
  const dep_39yrSL = useSelector((state) => state.sift.ioManager.inputs.finance.data.dep_39yrSL);
  const dep_5yrMACRS = useSelector((state) => state.sift.ioManager.inputs.finance.data.dep_5yrMACRS);
  const dep_15yrMACRS = useSelector((state) => state.sift.ioManager.inputs.finance.data.dep_15yrMACRS);
  const state_taxes = useSelector((state) => state.sift.ioManager.inputs.finance.data.state_taxes);
  const federal_taxes = useSelector((state) => state.sift.ioManager.inputs.finance.data.federal_taxes);

  // menu toggles
  const [toggle_rev_and_incentives, set_toggle_rev_and_incentives] = useState(false);
  const [toggle_debt, set_toggle_debt] = useState(false);
  const [toggle_tax_and_dep, set_toggle_tax_and_dep] = useState(false);

  useEffect(() => {
    if (expand) {
      set_toggle_rev_and_incentives(true);
      set_toggle_debt(true);
      set_toggle_tax_and_dep(true);
    } else {
      set_toggle_rev_and_incentives(false);
      set_toggle_debt(false);
      set_toggle_tax_and_dep(false);
    }
  }, [expand]);

  //popout toggles
  const [touVisible, setTouVisible] = useState(false);
  const [revShedVisible, setRevShedVisible] = useState(false);
  const [popoutY, setPopoutY] = useState(0);

  const RevenueOptions = [
    { label: "Fixed", value: 0 },
    { label: "TOU", value: 1 },
  ];

  useEffect(() => {
    // if you switch back to LCOE toggle off the TOU table and change the revenue type back to 0 (fixed)
    if (metric === 0 && touVisible) {
      toggleTOUPopup(null, false);
      updateInput("revenue_type", 0);
    }
  }, [metric]);

  // hide the TOU table and reset the revenue type back to 0 (fixed) if finance is turned off
  useEffect(() => {
    if (touVisible && do_finance === false) {
      toggleTOUPopup(null, false);
      updateInput("revenue_type", 0);
    }

    if (revShedVisible && do_finance === false) {
      toggleRevShedPopup(null, false);
      updateInput("do_ri_customSchedule", 0);
    }
    if (!revShedVisible && do_finance === false) {
      updateInput("do_ri_customSchedule", 0);
    }
  }, [do_finance, revShedVisible]);

  useEffect(() => {
    if (do_ri_customSchedule === 1 && !revShedVisible) {
      toggleRevShedPopup(null, true);
    } else if (do_ri_customSchedule === 0 && revShedVisible) {
      toggleRevShedPopup(null, false);
    }
  }, [do_ri_customSchedule]);

  const toggleTOUPopup = (e = undefined, showTOUTable = undefined) => {
    let pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    setPopoutY(Math.min(pageHeight - 444, pageHeight * 0.35));
    setRevShedVisible(false);
    setTouVisible(showTOUTable || !touVisible);
  };

  const toggleRevShedPopup = (e = undefined, showRevShedTable = undefined) => {
    let pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    setPopoutY(Math.min(pageHeight - 444, pageHeight * 0.35));
    setTouVisible(false);
    setRevShedVisible(showRevShedTable || !revShedVisible);
  };
  const closePopups = () => {
    setTouVisible(false);
    setRevShedVisible(false);
  };

  let ri_schedule = [];
  let TOUGrid = [];
  let inputError = "";
  let dirtyInputs = false;

  const updateInput = (key, value) => {
    dispatch(siftActions.updateInput(key, value));

    if (key == "revenue_type") {
      if (value == 1) {
        toggleTOUPopup();
      } else {
        setTouVisible(false);
      }
    }
  };

  const handleLoadRICustomSchedule = (calculate = false) => {
    // let finance = inputs;
    let customSchedule = [];

    if (do_ri_customSchedule == 1) {
      // fix the inputs to be numbers
      // finance = fixInputs();
      var data = [
        [
          { value: "Year", readOnly: true },
          { value: "$/kWh", readOnly: true },
        ],
      ];
      if (ri_customSchedule != undefined && ri_customSchedule.length > 0 && !calculate && ri_customSchedule.length == analysis_period) {
        // load from provided schedule
        for (var i = 0; i < analysis_period; i++) {
          let year = i + 1;
          let col_$kWh = ri_customSchedule[i][1];

          data.push([
            { readOnly: true, value: year },
            { readOnly: false, value: col_$kWh },
          ]);
        }
      } else {
        // build the excel chart off current input values
        let base_$kWh = ri_power;
        for (var i = 0; i < analysis_period; i++) {
          let year = i + 1;
          let col_$kWh = (base_$kWh * Math.pow(1 + ri_escalation / 100, year - 1)).toFixed(4);

          // Year N = Year 1 * (1 + %/100)^N
          // %/100 = 0.02 for 2%
          customSchedule.push([year, parseFloat(col_$kWh)]);
          data.push([
            { readOnly: true, value: year },
            { readOnly: false, value: col_$kWh },
          ]);
        }

        updateInput("ri_customSchedule", customSchedule);
      }
      ri_schedule = data;
      dirtyInputs = false;
      inputError = undefined;
    }
  };

  const handleLoadTOUGrid = (calculate = false) => {
    // let finance = inputs;

    if (revenue_type == 1) {
      // fix the inputs to be numbers
      // finance = fixInputs();
      var data = [
        [
          { value: "Hour", readOnly: true },
          { value: "JAN", readOnly: true },
          { value: "FEB", readOnly: true },
          { value: "MAR", readOnly: true },
          { value: "APR", readOnly: true },
          { value: "MAY", readOnly: true },
          { value: "JUN", readOnly: true },
          { value: "JUL", readOnly: true },
          { value: "AUG", readOnly: true },
          { value: "SEP", readOnly: true },
          { value: "OCT", readOnly: true },
          { value: "NOV", readOnly: true },
          { value: "DEC", readOnly: true },
        ],
      ];

      let min = 100;
      let max = 0;
      for (var i = 0; i < 24; i++) {
        let vals = tou_grid[i].slice(1, 13);
        min = Math.min(...vals) < min ? Math.min(...vals) : min;
        max = Math.max(...vals) > max ? Math.max(...vals) : max;
      }

      // load from provided schedule
      for (var i = 0; i < 24; i++) {
        let hour = i + 1;
        data.push([
          { readOnly: true, value: hour },

          { readOnly: false, value: tou_grid[i][1] },
          { readOnly: false, value: tou_grid[i][2] },
          { readOnly: false, value: tou_grid[i][3] },
          { readOnly: false, value: tou_grid[i][4] },
          { readOnly: false, value: tou_grid[i][5] },
          { readOnly: false, value: tou_grid[i][6] },
          { readOnly: false, value: tou_grid[i][7] },
          { readOnly: false, value: tou_grid[i][8] },
          { readOnly: false, value: tou_grid[i][9] },
          { readOnly: false, value: tou_grid[i][10] },
          { readOnly: false, value: tou_grid[i][11] },
          { readOnly: false, value: tou_grid[i][12] },
        ]);
      }
      TOUGrid = data;
    }
  };

  const handleChangesRI = (changes) => {
    const data = ri_schedule;
    changes.forEach(({ cell, row, col, value }) => {
      if (data[row] && data[row][col]) {
        // NS: Fix for stripping % and $ from excel copied values
        let fixed_value = value.replace("$", "").replace("%", "");
        data[row][col] = { ...data[row][col], value: fixed_value };
      }
    });
    // console.log(data)
    let newScheduleData = data.slice(1, data.length);
    var Schedule = [];
    let year = 1;
    newScheduleData.forEach((row) => {
      let col_$kWh = parseFloat(row[1].value) == row[1].value ? parseFloat(row[1].value) : 0;
      Schedule.push([year, col_$kWh]);
      year++;
    });
    updateInput("ri_customSchedule", Schedule);
  };

  const handleChangesTOU = (changes) => {
    const data = TOUGrid;
    changes.forEach(({ cell, row, col, value }) => {
      if (data[row] && data[row][col]) {
        // NS: Fix for stripping % and $ from excel copied values
        let fixed_value = value.replace("$", "").replace("%", "");
        data[row][col] = { ...data[row][col], value: fixed_value };
      }
    });
    // console.log(data)
    let newTOUData = data.slice(1, data.length);
    var TOU_Grid = [];
    let hour = 1;
    newTOUData.forEach((row) => {
      TOU_Grid.push([
        hour,
        parseFloat(row[1].value),
        parseFloat(row[2].value),
        parseFloat(row[3].value),
        parseFloat(row[4].value),
        parseFloat(row[5].value),
        parseFloat(row[6].value),
        parseFloat(row[7].value),
        parseFloat(row[8].value),
        parseFloat(row[9].value),
        parseFloat(row[10].value),
        parseFloat(row[11].value),
        parseFloat(row[12].value),
      ]);
      hour++;
    });
    updateInput("tou_grid", TOU_Grid);
  };

  const onSetDepreciation = (which) => {
    dispatch(siftActions.updateDepreciation(which));
  };

  handleLoadRICustomSchedule();
  handleLoadTOUGrid();

  return (
    <>
      {/* <FinanceInstallCosts />
      <FinanceOperatingCosts /> */}

      <TitleDivider title="Revenue and Incentives" collapseable={true} toggle_var={toggle_rev_and_incentives} toggle_fn={set_toggle_rev_and_incentives} />

      {toggle_rev_and_incentives && (
        <>
          <section className="input-row-two-col">
            <label>Revenue Type</label>
            <Radio.Group
              name="revenue_type"
              style={{ margin: "0px -5px 0px auto" }}
              options={RevenueOptions}
              onChange={(e) => {
                updateInput(e.target.name, e.target.value);
                if (touVisible || revShedVisible) {
                  closePopups();
                }
              }}
              value={revenue_type}
              disabled={account_loading || input_loading}
            />
          </section>

          {revenue_type == 0 && (
            <section className="input-row-two-col">
              <label>Power</label>
              <Input
                id="ri_power"
                style={{ maxWidth: 135, marginLeft: "auto" }}
                size="small"
                min="0"
                autoComplete="off"
                disabled={account_loading || input_loading}
                value={ri_power}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>$/kWh</span>}
                onBlur={(e) => ri_power == "" && updateInput(e.target.id, 0)}
                onChange={(e) => {
                  updateInput(e.target.id, e.target.value);
                }}
              />
            </section>
          )}

          {revenue_type == 1 && (
            <section className="input-row one">
              <section className="" style={{ textAlign: "center" }}>
                <section className="read-only-table" style={{ textAlign: "right", marginRight: "6px" }} onClick={toggleTOUPopup}>
                  {touVisible ? (
                    <a style={{ textDecoration: "underline" }} onClick={toggleTOUPopup}>
                      Close Time of Use Table
                    </a>
                  ) : (
                    <a style={{ textDecoration: "underline" }} onClick={toggleTOUPopup}>
                      Open Time of Use Table
                    </a>
                  )}
                </section>
              </section>
            </section>
          )}

          <section className="input-row-two-col">
            <label>Escalation</label>
            <Input
              id="ri_escalation"
              style={{ maxWidth: 135, marginLeft: "auto" }}
              size="small"
              min="0"
              autoComplete="off"
              disabled={account_loading || input_loading}
              value={ri_escalation}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
              onBlur={(e) => ri_escalation == "" && updateInput(e.target.id, 0)}
              onChange={(e) => {
                updateInput(e.target.id, e.target.value);
              }}
            />
          </section>

          <section className="custom-schedule-row">
            <label>Include ITC</label>
            <Switch
              size="small"
              id="do_include_itc"
              checked={do_include_itc == 1}
              onChange={(b, e) => {
                updateInput("do_include_itc", b ? 1 : 0);
              }}
            />
            <Input
              size="small"
              id="itc_percent"
              min={1}
              max={500}
              value={itc_percent}
              disabled={do_include_itc == 0}
              onBlur={(e) => itc_percent == "" && updateInput(e.target.id, 0)}
              onChange={(e) => {
                updateInput(e.target.id, e.target.value);
              }}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
            />
          </section>

          {revenue_type == 0 && (
            <Tooltip
              placement="top"
              title={
                analysis_period == 0 || analysis_period < 0 || analysis_period == "" || analysis_period == undefined
                  ? "Please enter a positive non-zero integer into the Analysis Period field to enable Custom Schedule"
                  : ""
              }
              defaultVisible={analysis_period == 0 || analysis_period < 0 || analysis_period == "" || analysis_period == undefined}
              mouseEnterDelay={0.25}
            >
              <section className="custom-schedule-row">
                <label>Custom Schedule</label>
                <Switch
                  size="small"
                  disabled={analysis_period == undefined || analysis_period == 0 || analysis_period < 0 || analysis_period == ""}
                  id="do_ri_customSchedule"
                  checked={do_ri_customSchedule == 1}
                  onChange={(b, e) => {
                    updateInput("do_ri_customSchedule", b ? 1 : 0);
                  }}
                />
                {do_ri_customSchedule == 1 && <a onClick={toggleRevShedPopup}>{revShedVisible ? "Close Schedule" : "Open Schedule "}</a>}
              </section>
            </Tooltip>
          )}
        </>
      )}

      <TitleDivider title="Debt" collapseable={true} toggle_var={toggle_debt} toggle_fn={set_toggle_debt} />

      {toggle_debt && (
        <>
          <section className="input-row-two-col">
            <label>Debt Structure</label>
            <Select
              value={debt_structure}
              size="small"
              disabled={account_loading || input_loading}
              style={{ width: "100%", maxWidth: 135, marginLeft: "auto" }}
              onChange={(value) => {
                updateInput("debt_structure", value);
              }}
            >
              <Select.Option key="rack_type1" value={0}>
                No Debt
              </Select.Option>
              <Select.Option key="rack_type2" value={1}>
                Fixed Percent
              </Select.Option>
              <Select.Option key="rack_type3" value={2}>
                DSCR
              </Select.Option>
            </Select>
          </section>

          {debt_structure == 1 && (
            <section className="input-row-two-col">
              <label>Percent Debt</label>
              <Input
                id="debt_percent"
                style={{ maxWidth: 135, marginLeft: "auto" }}
                size="small"
                min="0"
                autoComplete="off"
                disabled={account_loading || input_loading}
                value={debt_percent}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
                onBlur={(e) => debt_percent == "" && updateInput(e.target.id, 0)}
                onChange={(e) => {
                  updateInput(e.target.id, e.target.value);
                }}
              />
            </section>
          )}

          {debt_structure == 2 && (
            <section className="input-row-two-col">
              <label>DSCR</label>
              <Input
                id="debt_dscr"
                style={{ maxWidth: 135, marginLeft: "auto" }}
                size="small"
                min="0"
                autoComplete="off"
                disabled={account_loading || input_loading}
                value={debt_dscr}
                onBlur={(e) => debt_dscr == "" && updateInput(e.target.id, 0)}
                onChange={(e) => {
                  updateInput(e.target.id, e.target.value);
                }}
              />
            </section>
          )}

          {debt_structure > 0 && (
            <section className="input-row-two-col">
              <label>Interest</label>
              <Input
                id="debt_interest"
                style={{ maxWidth: 135, marginLeft: "auto" }}
                size="small"
                min="0"
                autoComplete="off"
                disabled={account_loading || input_loading}
                value={debt_interest}
                onBlur={(e) => debt_interest == "" && updateInput(e.target.id, 0)}
                onChange={(e) => {
                  updateInput(e.target.id, e.target.value);
                }}
              />
            </section>
          )}

          {debt_structure > 0 && (
            <section className="input-row-two-col">
              <label>Tenor</label>
              <Input
                id="debt_tenor"
                style={{ maxWidth: 135, marginLeft: "auto" }}
                size="small"
                min="0"
                autoComplete="off"
                disabled={account_loading || input_loading}
                value={debt_tenor}
                onBlur={(e) => debt_tenor == "" && updateInput(e.target.id, 0)}
                onChange={(e) => {
                  updateInput(e.target.id, e.target.value);
                }}
              />
            </section>
          )}
        </>
      )}

      <TitleDivider title="Tax and Depreciation" collapseable={true} toggle_var={toggle_tax_and_dep} toggle_fn={set_toggle_tax_and_dep} />

      {toggle_tax_and_dep && (
        <>
          <section className="input-row one">
            <section>
              <label>Presets:</label>
              <span>
                {" "}
                <a onClick={() => onSetDepreciation(0)}>
                  <u>Typical</u>
                </a>
              </span>

              <span>
                {", "}
                <a onClick={() => onSetDepreciation(1)}>
                  <u>Typical U.S.</u>
                </a>
              </span>
            </section>
          </section>

          <section className="input-row-two-col">
            <label>5yr SL</label>
            <Input
              id="dep_5yrSL"
              onBlur={(e) => dep_5yrSL == "" && updateInput(e.target.id, 0)}
              onChange={(event) => {
                // console.log("event 5yr", event.target.value);
                updateInput(event.target.id, event.target.value);
              }}
              value={dep_5yrSL}
              size="small"
              autoComplete="off"
              disabled={account_loading || input_loading}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
            />
          </section>
          <section className="input-row-two-col">
            <label>15yr SL</label>
            <Input
              id="dep_15yrSL"
              onBlur={(e) => dep_15yrSL == "" && updateInput(e.target.id, 0)}
              onChange={(event) => {
                // console.log("even", event.target.value);
                updateInput(event.target.id, event.target.value);
              }}
              value={dep_15yrSL}
              size="small"
              autoComplete="off"
              disabled={account_loading || input_loading}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
            />
          </section>

          <section className="input-row-two-col">
            <label>20yr SL</label>
            <Input
              id="dep_20yrSL"
              onBlur={(e) => dep_20yrSL == "" && updateInput(e.target.id, 0)}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={dep_20yrSL}
              size="small"
              autoComplete="off"
              disabled={account_loading || input_loading}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
            />
          </section>

          <section className="input-row-two-col">
            <label>30yr SL</label>
            <Input
              id="dep_30yrSL"
              onBlur={(e) => dep_30yrSL == "" && updateInput(e.target.id, 0)}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={dep_30yrSL}
              size="small"
              autoComplete="off"
              disabled={account_loading || input_loading}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
            />
          </section>

          <section className="input-row-two-col">
            <label>35yr SL</label>
            <Input
              id="dep_35yrSL"
              onBlur={(e) => dep_35yrSL == "" && updateInput(e.target.id, 0)}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={dep_35yrSL}
              size="small"
              autoComplete="off"
              disabled={account_loading || input_loading}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
            />
          </section>

          <section className="input-row-two-col">
            <label>39yr SL</label>
            <Input
              id="dep_39yrSL"
              onBlur={(e) => dep_39yrSL == "" && updateInput(e.target.id, 0)}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={dep_39yrSL}
              size="small"
              autoComplete="off"
              disabled={account_loading || input_loading}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
            />
          </section>

          <section className="input-row-two-col">
            <label>5yr MACRS</label>
            <Input
              id="dep_5yrMACRS"
              onBlur={(e) => dep_5yrMACRS == "" && updateInput(e.target.id, 0)}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={dep_5yrMACRS}
              size="small"
              autoComplete="off"
              disabled={account_loading || input_loading}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
            />
          </section>

          <section className="input-row-two-col">
            <label>15yr MACRS</label>
            <Input
              id="dep_15yrMACRS"
              onBlur={(e) => dep_15yrMACRS == "" && updateInput(e.target.id, 0)}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={dep_15yrMACRS}
              size="small"
              autoComplete="off"
              disabled={account_loading || input_loading}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
            />
          </section>

          <section className="input-row-two-col">
            <label>State Taxes</label>
            <Input
              id="state_taxes"
              onBlur={(e) => state_taxes == "" && updateInput(e.target.id, 0)}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={state_taxes}
              size="small"
              autoComplete="off"
              disabled={account_loading || input_loading}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
            />
          </section>

          <section className="input-row-two-col">
            <label>Federal Taxes</label>
            <Input
              id="federal_taxes"
              onBlur={(e) => federal_taxes == "" && updateInput(e.target.id, 0)}
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={federal_taxes}
              size="small"
              autoComplete="off"
              disabled={account_loading || input_loading}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
            />
          </section>
        </>
      )}

      {/* input popouts */}
      <InputPopout visible={touVisible} onClose={toggleTOUPopup} popoutY={popoutY}>
        <section className="tou-container">
          <label>TOU 12x24 ($/kWh)</label>
          <section className="custom-schedule-table">
            <ReactDataSheet
              data={TOUGrid}
              sheetRenderer={(props) => renderSheet({ ...props, columns: tou_columns })}
              valueRenderer={(cell) => cell.value}
              onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
              onCellsChanged={handleChangesTOU}
            />
          </section>
        </section>
      </InputPopout>

      <InputPopout visible={revShedVisible} onClose={toggleRevShedPopup} popoutY={popoutY}>
        <section className="tou-container">
          <label className="custom-schedule-label">Revenue and Incentives Custom Schedule</label>
          <a
            className="custom-schedule-link"
            onClick={() => {
              handleLoadRICustomSchedule(true);
            }}
          >
            Populate based on Inputs(Left)
          </a>
          <section className="custom-schedule-table">
            <ReactDataSheet
              data={ri_schedule}
              sheetRenderer={(props) => renderSheet({ ...props, columns: ri_columns })}
              valueRenderer={(cell) => cell.value}
              onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
              onCellsChanged={handleChangesRI}
            />
          </section>
        </section>
      </InputPopout>
    </>
  );
};

export { FinanceInputs_IRR };
