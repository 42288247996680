import React, { Fragment, useState, useEffect } from "react";
import { TableWrap, TrackerTableWrap, PerfSummaryTableWrap, LossTableWrap } from "./styles";

const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

const RackingTable = ({ data }) => {
  return (
    <TableWrap>
      <div className="title">
        <p>Racking Inputs</p>
      </div>
      <div className="table-row">
        <p className="table-header">Racking Product</p>
        <p className="table-text">{data.racking_name}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Type</p>
        <p className="table-text">{data.track_mode == 0 ? "Ground Fixed Tilt" : "Single Axis Tracker"}</p>
      </div>
      {/* <div className="table-row">
        <p className="table-header">Rack to Rack Gap</p>
        <p className="table-text">{`${data.selectedProduct.gap} m`}</p>
      </div> */}
      <div className="table-row">
        <p className="table-header">Grade Limit</p>
        <p className="table-text">{`${data.rack_grade_limit}`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Backtrack</p>
        <p className="table-text">{`${data.en_backtrack === 0 ? "No" : "Yes"}`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Tracking Angle</p>
        <p className="table-text">{`+/- ${data.rlim}°`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Height Above Ground</p>
        <p className="table-text">{`${data.bi_groundClearanceHeight} m`}</p>
      </div>
      {/* <div className="table-row">
        <p className="table-header">Transparent Fraction</p>
        <p className="table-text">{`0 %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Structural Shade Factor</p>
        <p className="table-text">{`0 %`}</p>
      </div> */}
      {data.track_mode == 0 && (
        <Fragment>
          <div className="table-row">
            <p className="table-header">String Steps</p>
            <p className="table-text">{data.Fshd_StringSteps}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Cell Transverse</p>
            <p className="table-text">{`${data.Fshd_Celltrav} m`}</p>
          </div>
        </Fragment>
      )}
      {/* <div className="table-row">
        <p className="table-header">Shade Mode</p>
        <p className="table-text">{`${data.selectedProduct.shade_mode === 0 ? ' No Shading' : 'Yes'}`}</p>
      </div>
     
      <div className="table-row">
        <p className="table-header">Continuous</p>
        <p className="table-text">{`${data.selectedProduct.continuous === 0 ? 'No' : 'Yes'} `}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Module Orientation</p>
        <p className="table-text">{`${data.selectedProduct.mod_orient == 0 ? 'Portrait' : 'Landscape'}`}</p>
      </div> */}
    </TableWrap>
  );
};

const TrackerBreakdownTable = ({ data }) => {
  const letters = ["A", "B", "C"];
  const table_keys = ["table_a", "table_b", "table_c"];

  return (
    <TrackerTableWrap>
      <thead>
        <tr>
          <th>Tracker Sizes</th>
          <th>Qty</th>
          <th>Module Count</th>
          <th>{`Tracker X`}</th>
          <th>{`Tracker Y`}</th>
        </tr>
      </thead>
      <tbody>
        {Object.values(data.racks).map((rack, index) => (
          <tr key={index}>
            <td>{letters[index]}</td>
            <td>{data[table_keys[index]]}</td>
            <td>{data[table_keys[index]] === 0 ? "N/A" : `${rack.module}`}</td>
            <td>{data[table_keys[index]] === 0 ? "N/A" : `${parseFloat(rack.xdim).toFixed(2)} m`}</td>
            <td>{data[table_keys[index]] === 0 ? "N/A" : `${parseFloat(rack.ydim).toFixed(2)} m`}</td>
          </tr>
        ))}
      </tbody>
    </TrackerTableWrap>
  );
};

const ModuleTable = ({ data }) => {
  return (
    <TableWrap>
      <div className="title">
        <p>Module Inputs</p>
      </div>
      <div className="table-row">
        <p className="table-header">Module Name</p>
        <p className="table-text">{`${data.module_name}`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Module Rating</p>
        <p className="table-text">{`${data.mod_rating} W`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Module Width</p>
        <p className="table-text">{`${data.mod_width} m`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Module Height</p>
        <p className="table-text">{`${data.mod_height} m`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Module Area</p>
        <p className="table-text">{`${data.mod_area.toFixed(2)} m²`}</p>
      </div>
      {data.simple_module == 0 && (
        <>
          <div className="table-row">
            <p className="table-header">Technology</p>
            <p className="table-text">{data.technology === 0 ? "Si-Mono" : data.technology === 1 ? "Si-Poly" : "CdTe"}</p>
          </div>
          {data.en_bifacial == 1 && (
            <>
              <div className="table-row">
                <p className="table-header">Bifacial</p>
                <p className="table-text">True</p>
              </div>
              <div className="table-row">
                <p className="table-header">Bifaciality Factor</p>
                <p className="table-text">{data.bi_bifaciality}</p>
              </div>
            </>
          )}
          <div className="table-row">
            <p className="table-header">Gref</p>
            <p className="table-text">{`${data.mlm_S_ref} W/m²`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Tref</p>
            <p className="table-text">{`${data.mlm_T_ref}° C`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Isc</p>
            <p className="table-text">{`${data.mlm_I_sc_ref} A`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Voc</p>
            <p className="table-text">{`${data.mlm_V_oc_ref} V`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Imp</p>
            <p className="table-text">{`${data.mlm_I_mp_ref} A`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Vmp</p>
            <p className="table-text">{`${data.mlm_V_mp_ref} V`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Tcoef of Isc</p>
            <p className="table-text">{`${data.mlm_alpha_isc} mA/°C`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Tcoef of Isc</p>
            <p className="table-text">{`${data.mlm_beta_voc_spec} mV/°C`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Cells In Series </p>
            <p className="table-text">{`${data.mlm_N_series}`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Cells In Parallel </p>
            <p className="table-text">{`${data.mlm_N_parallel}`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Rsh</p>
            <p className="table-text">{`${data.mlm_R_shref} Ω`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Rshunt at G=0</p>
            <p className="table-text">{`${data.mlm_R_sh0} Ω`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Rs</p>
            <p className="table-text">{`${data.mlm_R_s} Ω`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Shunt Exp</p>
            <p className="table-text">{`${data.mlm_R_shexp}`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Absorb Coef</p>
            <p className="table-text">{`${data.mlm_T_c_fa_alpha}`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Tcoef of Pmpp</p>
            <p className="table-text">{`${data.muPmpReq} %/°C`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Gamma</p>
            <p className="table-text">{`${data.mlm_n_0}`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Tcoef of Gamma</p>
            <p className="table-text">{`${data.mlm_mu_n} 1/°C`}</p>
          </div>
        </>
      )}
    </TableWrap>
  );
};

const PerformanceTable = ({ data }) => {
  return (
    <TableWrap>
      <div className="title">
        <p>Performance Inputs</p>
      </div>
      <div className="table-row">
        <p className="table-header">Modules Per String</p>
        <p className="table-text">{`${data.mod_per_string}`}</p>
      </div>
      {/* <div className="table-row">
        <p className="table-header">DC Degredation</p>
        <p className="table-text">{`${data.selectedModule.mod_string}`}</p>
      </div> */}
      {/* <div className="table-row">
        <p className="table-header">Soiling</p>
        <p className="table-text">{`${data.inputs.losses.soiling_loss}%`}</p>
      </div> */}
      <div className="table-row">
        <p className="table-header">Clearance Height</p>
        <p className="table-text">{`${data.bi_groundClearanceHeight} m`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Transmission</p>
        <p className="table-text">{`${data.bi_transmissionFactor} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Structure Shade</p>
        <p className="table-text">{`${data.bi_structureShadeFactor} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">DC Degradation</p>
        <p className="table-text">{`${data.dc_degrade * 100} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">DC Module Mismatch</p>
        <p className="table-text">{`${data.dc_module_mismatch * 100} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">DC Strings Mismatch</p>
        <p className="table-text">{`${data.dc_strings_mismatch * 100} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">DC Wiring</p>
        <p className="table-text">{`${data.dc_wiring_loss_at_stc * 100} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Module LID</p>
        <p className="table-text">{`${data.dc_module_lid_loss * 100} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Module Quality</p>
        <p className="table-text">{`${data.dc_module_quality_loss * 100} %`}</p>
      </div>
      {/* <div className="table-row">
        <p className="table-header">Structure Shade</p>
        <p className="table-text">{`${data.inputs.bifacial_structure_shade_factor} %`}</p>
      </div> */}
      {/* <div className="table-row">
        <p className="table-header">Inverter Efficiency</p>
        <p className="table-text">{`${data.inputs.losses.inverter_eff} %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Combined AC Losses</p>
        <p className="table-text">{`${data.inputs.losses.combined_ac_loss} %`}</p>
      </div> */}
      <div className="table-row">
        <p className="table-header">DC:AC</p>
        <p className="table-text">{`${data.simple_inverter_dcac}`}</p>
      </div>
    </TableWrap>
  );
};

const SoilingAlbedoTable = ({ data }) => {
  return (
    <PerfSummaryTableWrap>
      <thead>
        <tr>
          <th></th>
          {months.map((month, index) => (
            <th key={index}>{month}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Soiling (%)</td>
          {data.soiling.map((soil, index) => (
            <td key={index}>{soil * 100}</td>
          ))}
        </tr>
        <tr>
          <td>Albedo</td>
          {data.albedo.map((alb, index) => (
            <td key={index}>{alb}</td>
          ))}
        </tr>
      </tbody>
    </PerfSummaryTableWrap>
  );
};

//  ********** LossesTable is no longer used ****************
const LossesTable = ({ reportData }) => {
  return (
    <TableWrap>
      <div className="title">
        <p>Losses</p>
      </div>
      {reportData.results.dc_degradation.toFixed(2) != 0 && (
        <div className="table-row">
          <p className="table-header">DC Degredation</p>
          <p className="table-text">{`${reportData.results.dc_degradation.toFixed(2)} %`}</p>
        </div>
      )}
      {reportData.results.horizontal_global_irradiance.toFixed(3) != 0 && (
        <div className="table-row">
          <p className="table-header">Horiz. Global Irradiance</p>
          <p className="table-text">{`${reportData.results.horizontal_global_irradiance.toFixed(3)} kWh/m²`}</p>
        </div>
      )}
      {reportData.results.global_incident_in_col_plane.toFixed(5) != 0 && (
        <div className="table-row">
          <p className="table-header">Global Incident in Col. Plane</p>
          <p className="table-text">{`${reportData.results.global_incident_in_col_plane.toFixed(5)} %`}</p>
        </div>
      )}
      {reportData.results.near_shadings_irradiance_loss.toFixed(5) != 0 && (
        <div className="table-row">
          <p className="table-header">Near Shadings Irradiance Loss</p>
          <p className="table-text">{`${reportData.results.near_shadings_irradiance_loss.toFixed(5)} %`}</p>
        </div>
      )}
      {reportData.results.iam_factor_on_global.toFixed(6) != 0 && (
        <div className="table-row">
          <p className="table-header">IAM Factor on Global</p>
          <p className="table-text">{`${reportData.results.iam_factor_on_global.toFixed(6)} %`}</p>
        </div>
      )}
      {reportData.results.soiling_loss.toFixed(6) != 0 && (
        <div className="table-row">
          <p className="table-header">Soiling Loss</p>
          <p className="table-text">{`${reportData.results.soiling_loss.toFixed(6)} %`}</p>
        </div>
      )}
      {reportData.results.bifacial_gain.toFixed(6) != 0 && (
        <div className="table-row">
          <p className="table-header">Bifacial Gain</p>
          <p className="table-text">{`${reportData.results.bifacial_gain.toFixed(6)} %`}</p>
        </div>
      )}
      {reportData.results.global_effective_irradiation_on_col.toFixed(3) != 0 && (
        <div className="table-row">
          <p className="table-header">Glob. Eff. Irradiation on Col </p>
          <p className="table-text">{`${reportData.results.global_effective_irradiation_on_col.toFixed(3)} kWh/m²`}</p>
        </div>
      )}
      {reportData.results.array_nominal_energy.toFixed(0) != 0 && (
        <div className="table-row">
          <p className="table-header">Array Nominal Energy</p>
          <p className="table-text">{`${reportData.results.array_nominal_energy.toFixed(0)} kWh`}</p>
        </div>
      )}
      {reportData.results.pv_loss_irrad.toFixed(6) != 0 && (
        <div className="table-row">
          <p className="table-header">PV Loss Irrad</p>
          <p className="table-text">{`${reportData.results.pv_loss_irrad.toFixed(6)} %`}</p>
        </div>
      )}
      {reportData.results.pv_loss_temp.toFixed(6) != 0 && (
        <div className="table-row">
          <p className="table-header">PV Loss Temp</p>
          <p className="table-text">{`${reportData.results.pv_loss_temp.toFixed(6)} %`}</p>
        </div>
      )}
      {reportData.results.pv_elec_loss.toFixed(0) != 0 && (
        <div className="table-row">
          <p className="table-header">PV Elec Loss</p>
          <p className="table-text">{`${reportData.results.pv_elec_loss.toFixed(0)} %`}</p>
        </div>
      )}
      {reportData.results.lid_loss.toFixed(0) != 0 && (
        <div className="table-row">
          <p className="table-header">LID Loss</p>
          <p className="table-text">{`${reportData.results.lid_loss.toFixed(0)} %`}</p>
        </div>
      )}
      {reportData.results.quality_loss.toFixed(1) != 0 && (
        <div className="table-row">
          <p className="table-header">Quality Loss</p>
          <p className="table-text">{`${reportData.results.quality_loss.toFixed(1)} %`}</p>
        </div>
      )}
      {reportData.results.dc_mismatch_loss.toFixed(0) != 0 && (
        <div className="table-row">
          <p className="table-header">DC Mismatch Loss</p>
          <p className="table-text">{`${reportData.results.dc_mismatch_loss.toFixed(0)} %`}</p>
        </div>
      )}
      {reportData.results.ohmic_wiring_loss.toFixed(6) != 0 && (
        <div className="table-row">
          <p className="table-header">Ohmic Wiring Loss</p>
          <p className="table-text">{`${reportData.results.ohmic_wiring_loss.toFixed(6)} %`}</p>
        </div>
      )}
      {reportData.results.e_array.toFixed(0) != 0 && (
        <div className="table-row">
          <p className="table-header">E Array</p>
          <p className="table-text">{`${reportData.results.e_array.toFixed(0)} kWh`}</p>
        </div>
      )}
      {reportData.results.inverter_loss.toFixed(0) != 0 && (
        <div className="table-row">
          <p className="table-header">Inverter Loss</p>
          <p className="table-text">{`${reportData.results.inverter_loss.toFixed(0)} %`}</p>
        </div>
      )}
      {reportData.results.ac_loss.toFixed(1) != 0 && (
        <div className="table-row">
          <p className="table-header">AC Loss</p>
          <p className="table-text">{`${reportData.results.ac_loss.toFixed(1)} %`}</p>
        </div>
      )}
      {reportData.results.interconnect_lim_loss.toFixed(0) != 0 && (
        <div className="table-row">
          <p className="table-header">Interconnect Limit Loss</p>
          <p className="table-text">{`${reportData.results.interconnect_lim_loss.toFixed(0)} %`}</p>
        </div>
      )}
    </TableWrap>
  );
};

const IAMTable = ({ data }) => {
  return (
    <TableWrap>
      <div className="title">
        <p>IAM Values</p>
      </div>
      {data.module_iam_ang &&
        data.module_iam_ang.map((item, index) => {
          if (index > 24) return;
          return (
            <div className="table-row" key={index}>
              <p className="table-header">{`${item}`}</p>
              <p className="table-text">{`${data.module_iam_eff[index]}`}</p>
            </div>
          );
        })}
    </TableWrap>
  );
};

const InverterTable = () => {
  return (
    <TableWrap>
      <div className="title">
        <p>Inverter Inputs</p>
      </div>
      <div className="table-row">
        <p className="table-header">Inverter Model</p>
        <p className="table-text">{`Simplified`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Max Efficiency</p>
        <p className="table-text">{`98 %`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">DC:AC</p>
        <p className="table-text">{`1.2`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Temperature Derate</p>
        <p className="table-text">{`False`}</p>
      </div>
    </TableWrap>
  );
};

const LayoutTable = ({ data }) => {
  return (
    <TableWrap>
      <div className="title">
        <p>Layout Inputs</p>
      </div>
      <div className="table-row">
        <p className="table-header">GCR</p>
        <p className="table-text">{`${data.gcr}`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Pitch</p>
        <p className="table-text">{`${data.pitch} m`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Azimuth</p>
        <p className="table-text">{`${data.sazm}°`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Racks Removed based on Grade</p>
        <p className="table-text">{data.topo_layout_action == "delete" ? `On` : `Off`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Intrarow Spacing</p>
        <p className="table-text">{`${data.rtr} m`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Buildable Area</p>
        <p className="table-text">{`${data.totalArea.toFixed(3)} ha`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Coordinate System</p>
        <p className="table-text">{`WGS 84`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Wiring</p>
        <p className="table-text">{`${data.do_wiring == 1 ? "On" : "Off"}`}</p>
      </div>
      {data.do_wiring == 1 && (
        <>
          <div className="table-row">
            <p className="table-header">Combiner Location</p>
            <p className="table-text">{`${data.combiner_location == 0 ? "Centroids" : "By Road"}`}</p>
          </div>
          <div className="table-row">
            <p className="table-header">Strings per Combiner</p>
            <p className="table-text">{`${data.strings_per_combiner}`}</p>
          </div>
        </>
      )}
    </TableWrap>
  );
};

const BOMTable = ({ data }) => {
  return (
    <TableWrap>
      <div className="title">
        <p>BOM Table</p>
      </div>
      <div className="table-row">
        <p className="table-header">Rack A Qty</p>
        <p className="table-text">{`${data.rack_breakdown[0]}`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Rack B Qty</p>
        <p className="table-text">{`${data.rack_breakdown[1]}`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Rack C Qty</p>
        <p className="table-text">{`${data.rack_breakdown[2]}`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Module Qty</p>
        <p className="table-text">{`${data.module_count}`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Inverter Qty</p>
        <p className="table-text">{`${data.inverter_qty}`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">Combiner Boxes Qty</p>
        <p className="table-text">{data.do_wiring == 1 ? `${data.combiner_count}` : `n/a`}</p>
      </div>
      <div className="table-row">
        <p className="table-header">DC Wiring</p>
        <p className="table-text">{data.do_wiring == 1 ? `${data.wire_length_ft} ft` : `n/a`}</p>
      </div>
    </TableWrap>
  );
};

const PerformanceSummaryTable = ({ data }) => {
  const [pr_object, set_pr_object] = useState();

  useEffect(() => {
    let { DHISums_kWhm2, EArray_MWh, EGrid_MWh, GHISums_kWhm2, GlobEff_kwhm2, GlobInc_kwhm2, PRratio, TAvg_C, WindAvg_ms } = data;
    let pr_object = { DHISums_kWhm2, EArray_MWh, EGrid_MWh, GHISums_kWhm2, GlobEff_kwhm2, GlobInc_kwhm2, PRratio, TAvg_C, WindAvg_ms };
    set_pr_object(JSON.parse(JSON.stringify(pr_object)));
  }, []);

  return (
    <PerfSummaryTableWrap>
      <tr>
        <th></th>
        <th>
          GHI <br />
          (kWh/m²)
        </th>
        <th>
          DHI <br />
          (kWh/m²)
        </th>
        <th>
          Temp <br />
          (C°)
        </th>
        <th>
          Wind <br />
          (m/s)
        </th>
        <th>
          Global Incident <br />
          (kWh/m²)
        </th>
        <th>
          EArray <br />
          (mWh)
        </th>
        <th>
          E_Grid <br />
          (mWh)
        </th>
      </tr>
      {pr_object &&
        pr_object.GHISums_kWhm2.map((item, index) => (
          <tr key={index}>
            {index == 12 ? <td>YEAR</td> : <td>{months[index]}</td>}
            <td>{`${pr_object.GHISums_kWhm2[index]}`}</td>
            <td>{`${pr_object.DHISums_kWhm2[index]}`}</td>
            <td>{`${pr_object.TAvg_C[index]}`}</td>
            <td>{`${pr_object.WindAvg_ms[index]}`}</td>
            <td>{`${pr_object.GlobInc_kwhm2[index]}`}</td>
            <td>{`${pr_object.EArray_MWh[index]}`}</td>
            <td>{`${pr_object.EGrid_MWh[index]}`}</td>
          </tr>
        ))}
    </PerfSummaryTableWrap>
  );
};

const FakeLossData = {
  ghi: {
    "Horizontal global irradiation (kWh/m²)": { value: 1368.1, poi: true },
    "Global incident in coll. plane %": { value: 1813.4, change: 32.4 },
    "Near Shadings: irradiance loss": { value: 1743.9, change: -3.74 },
    "IAM factor on global": { value: 1719.1, change: -1.36 },
    "Soiling loss factor": { value: 1684.8, change: -2.0 },
    "Ground reflection on front side": { value: 1696.3, change: 0.73 },
    "Effective irradiation on collectors": { value: 1696.3, change: 0, poi: true, area: 22465.2 },
    "Area of collectors (m²)": { value: 22465.2, change: 0 },
    "Efficiency at STC (%)": { value: 0.2, change: 18.45 },
    "Array nominal energy at STC (MWh)": { value: 7674.3, change: 0, poi: true },
    "Loss due to irradiance level": { value: 7612.7, change: -0.79 },
    "PV loss due to temperature": { value: 7437.5, change: -1.7 },
    // "Shadings: Electrical loss": { value: 7437.5, change: 0 },
    // "Spectral correction": { value: 7437.5, change: 0 },
    "Module quality loss": { value: 7511.9, change: 1 },
    "LID - Light induced degradation": { value: 7399.2, change: -1.5 },
    "Mismatch loss, modules and strings": { value: 7317.8, change: -1.1 },
    "Mismatch for back irradiance": { value: 7238.5, change: -1.18 },
    "Ohmic wiring loss": { value: 7162.6, change: -1.09 },
    "Array virual energy at MPP (MWh)": { value: 7162.6, change: 0, poi: true },
    "Inverter Loss during operation (efficiency)": { value: 6987.3, change: -2.43 },
    "Inverter Loss over nominal inv. power": { value: 6928.7, change: -0.86 },
    "Inverter Loss due to max input current": { value: 6928.7, change: 0 },
    "Inverter Loss over nominal inv. voltage": { value: 6928.7, change: 0 },
    "Inverter loss due to power threshold": { value: 6928.6, change: 0 },
    "Inverter loss due to voltage threshold": { value: 6928.6, change: 0 },
    "Night consumption": { value: 6891.1, change: -0.54 },
    "Available Energy at Inverter Output (MWh)": { value: 6891.1, change: 0, poi: true },
    "Auxiliaries (fans, other)": { value: 6679.6, change: -3.0 },
    "AC ohmic loss": { value: 6629.6, change: -0.78 },
    "MV transformer loss": { value: 6550.2, change: -1.24 },
    "MV line ohmic losss": { value: 6501.9, change: -0.8 },
    // "Fixed Transmission Loss": { value: 6501.9, change: 0 },
    // "AC Other": { value: 6501.9, change: 0 },
    // "Unused energy (grid limitation)": { value: 6501.9, change: 0 },
    "Energy injected into Grid (MWh)": { value: 6502.1, poi: true },
  },
  bifacial: {
    "Global incidence on ground": { value: 748.4, change: 0, poi: true },
    "Global incidence on ground on area": { value: 64186.3, change: 0 },
    "Ground reflection loss (Albedo)": { value: 224.5, change: -70 },
    "View Factor for rear side": { value: 61.6, change: -72.56 },
    "sky diffuse on the rear side": { value: 78.4, change: 20.69 },
    "Beam effective on the rear side": { value: 0, change: 0 },
    "Shadings loss on rear side": { value: 0, change: 0 },
    "Global irradiance on rear side": { value: 220, change: 12.98, poi: true },
    "PV conversion, Bifaciality factor": { value: 0.75, change: 0.75 },
    // "Effective irradiation on collectors rear (kWh/m²)": { value: 206.2, change: 0, poi: true },
    // "Effective irradiation on collectors rear * bifaciality (kWh/m²)": { value: 154.6, change: 0 },
  },
};

const LossTable = ({ data, bifacial = false }) => {
  return (
    <>
      {bifacial ? (
        <LossTableWrap>
          <tr>
            <th key="bifacial_header" colspan="2" style={{ textAlign: "center", backgroundColor: "#dddddd" }}>
              Bifacial
            </th>
          </tr>
          {Object.keys(data.loss_stack.bifacial).map((key) => {
            if (key != "Global incidence on ground on area") {
              if (key == "Global incidence on ground") {
                return (
                  <th key={key} colspan="2" style={{ textAlign: "center" }}>
                    {key}
                    <br></br>
                    {`${data.loss_stack.bifacial[key].value} kWh/m² on ${data.loss_stack.bifacial["Global incidence on ground on area"].value} m² `}
                  </th>
                );
              } else if (key == "Global irradiance on rear side") {
                return (
                  <th key={key} colspan="2" style={{ textAlign: "center" }}>
                    {`${data.loss_stack.bifacial[key].change}% ${key} (${data.loss_stack.bifacial[key].value} kWh/m²) `}
                  </th>
                );
              } else if (key == "PV conversion, Bifaciality factor") {
                return (
                  <tr key={key} style={{ border: 0, textAlign: "center" }}>
                    <td colspan="2" style={{ border: 0 }}>
                      {`${key} = ${data.loss_stack.bifacial[key].value}`}
                    </td>
                  </tr>
                );
              } else if (data.loss_stack.bifacial[key].poi) {
                return (
                  <tr key={key}>
                    <th>{key}</th>
                    <th>{data.loss_stack.bifacial[key].value}</th>
                  </tr>
                );
              } else {
                return (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{data.loss_stack.bifacial[key].change ? `${data.loss_stack.bifacial[key].change} %` : data.loss_stack.bifacial[key].change == 0 ? `${0}%` : ""}</td>
                  </tr>
                );
              }
            }
          })}
        </LossTableWrap>
      ) : (
        <LossTableWrap>
          {Object.keys(data.loss_stack.ghi).map((key) => {
            if (key != "Area of collectors (m^2)") {
              if (key == "Horizontal global irradiation (kWh/m²)") {
                return (
                  <tr key={key}>
                    <th>{key}</th>
                    <th>{`${data.loss_stack.ghi[key].value}`}</th>
                  </tr>
                );
              } else if (key == "Effective irradiation on collectors (kWh/m^2)") {
                return (
                  <th key={key} colspan="2" style={{ textAlign: "center" }}>
                    {"Effective irradiation on collectors"}
                    <br></br>
                    {`${data.loss_stack.ghi[key].value} kWh/m² * ${data.loss_stack.ghi["Area of collectors (m^2)"].value} m² coll. `}
                  </th>
                );
              } else if (key == "Efficiency at STC (%)") {
                return (
                  <tr key={key} style={{ border: 0, textAlign: "center" }}>
                    <td colspan="2" style={{ border: 0 }}>
                      {`${key} = ${data.loss_stack.ghi[key].change} %`}
                    </td>
                  </tr>
                );
              } else if (data.loss_stack.ghi[key].poi) {
                return (
                  <tr key={key}>
                    <th>{key}</th>
                    <th>{data.loss_stack.ghi[key].value}</th>
                  </tr>
                );
              } else {
                return (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{data.loss_stack.ghi[key].change ? `${data.loss_stack.ghi[key].change} %` : data.loss_stack.ghi[key].change == 0 ? `${0} %` : ""}</td>
                  </tr>
                );
              }
            }
          })}
        </LossTableWrap>
      )}
    </>
  );
};

export { RackingTable, TrackerBreakdownTable, ModuleTable, PerformanceTable, IAMTable, InverterTable, LayoutTable, LossesTable, BOMTable, SoilingAlbedoTable, PerformanceSummaryTable, LossTable };
