import styled from 'styled-components';

const LayerToggleMenuWrap = styled.section`
  position: absolute;
  height: auto;
  width: 130px;
  z-index: 1000;
  right: 50px;
  top: 200px;
  background-color: #fff;
  border-radius: 2px;
  padding: 3px;
  display: grid;

  .layers_menu_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    &:hover {
      cursor: move;
    }

    img {
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
    /* svg {
      &:hover {
        cursor: move;
        opacity: 0.7;
      }
    } */
  }
`;

const ToggleButton = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  p {
    margin: 0px 3px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 22px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--primary-brand-color);
  }

  input:focus + .slider {
    box-shadow: 0 0 1pxvar (--primary-brand-color);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export { LayerToggleMenuWrap, ToggleButton };
