import styled from 'styled-components';

const ReportTitleWrap = styled.section`
  grid-area: report-title;
  width: 100%;
  height: 80px;
  display: grid;
  place-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.9);
  grid-template-rows: 1fr 1fr;

  h1 {
    font-size: 20px;
    margin: 0px;
  }

  .address-box {
    display: flex;
    svg {
      margin-left: 5px;
    }
  }
  .customer-name-box,
  .customer-address-box {
    position: absolute;
    display: flex;
    align-items: center;
    margin-top: -15px;
    p,
    input {
      margin: 0px 5px 0px 0px;
    }
    input {
      width: 225px;
    }
    svg {
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
`;

export { ReportTitleWrap };
