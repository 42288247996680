import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { siftActions, getTilts, isNumber } from "../../Redux";

import { Radio, Table, Input, Spin, Tooltip, Popconfirm, Switch } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { DynamicInputPopout } from "../DynamicInputPopout";

import { DimsReadOnlyTable } from "../StyledComponents";

import { RackingPanelWrapper } from "./";

//tutorial stuff
import { TutorialTip } from "../../TutorialTip";

const letters = ["A", "B", "C"];
const RadioGroup = Radio.Group;

const RackingOptions = [
  { label: "Ground Fixed TIlt", value: 0 },
  { label: "Single-Axis Tracker", value: 1 },
  { label: "East-West Fixed", value: 2 },
];
const ShadingOption = [
  { label: "Linear", value: 0 },
  { label: "Electrical", value: 1 },
];
const backtrackOption = [
  { label: "Off", value: 0 },
  { label: "On", value: 1 },
];

const RackingInputs = () => {
  const inputs = useSelector((state) => state.sift.ioManager.inputs.racking.data);
  const isBifacial = useSelector((state) => state.sift.ioManager.inputs.module.data.module_isbifacial);
  const shade_mode = useSelector((state) => state.sift.ioManager.inputs.module.data.shade_mode);
  const account_loading = useSelector((state) => state.account.account_loading);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const racking_error_present = useSelector((state) => state.sift.ioManager.uiState.racking_error_present);

  const modules_per_string = useSelector((state) => state.sift.ioManager.inputs.performance.data.modules_per_string);

  const module_width = useSelector((state) => state.sift.ioManager.inputs.module.data.mlm_Width);
  const module_length = useSelector((state) => state.sift.ioManager.inputs.module.data.mlm_Length);

  const modulesHigh = useSelector((state) => state.sift.ioManager.inputs.racking.data.modules_high);
  const driveGap = useSelector((state) => state.sift.ioManager.inputs.racking.data.drive_gap);

  const rackAStrings = useSelector((state) => state.sift.ioManager.inputs.racking.data.racks[0].string_count);
  const rackBStrings = useSelector((state) => state.sift.ioManager.inputs.racking.data.racks[1].string_count);
  const rackCStrings = useSelector((state) => state.sift.ioManager.inputs.racking.data.racks[2].string_count);

  const [string_a_count, set_a_string_count] = useState(rackAStrings);
  const [string_b_count, set_b_string_count] = useState(rackBStrings);
  const [string_c_count, set_c_string_count] = useState(rackCStrings);

  const [modules_high, set_modules_high] = useState(modulesHigh);
  const [drive_gap, set_drive_gap] = useState(driveGap);

  const [modsPerString, setModsPerString] = useState(modules_per_string);

  const saving = account_loading || input_loading;
  const [rackPopupVisible, setRackPopupVisible] = useState(false);
  const [popoutY, setPopoutY] = useState(0);
  const [shadeModeError, setShadeModeError] = useState(false);

  const [_tilts, set_tilts] = useState([]);
  const [tilt_error, set_tilt_error] = useState(undefined);

  // tutorial controls
  const tutorial = useSelector((state) => state.tutorial);
  const screenWidth = useSelector((state) => state.sift.ioManager.uiState.screenWidth);

  const dispatch = useDispatch();

  useEffect(() => {
    set_a_string_count(rackAStrings);
  }, [rackAStrings]);

  useEffect(() => {
    set_b_string_count(rackBStrings);
  }, [rackBStrings]);

  useEffect(() => {
    set_c_string_count(rackCStrings);
  }, [rackCStrings]);

  useEffect(() => {
    set_modules_high(modulesHigh);
  }, [modulesHigh]);

  useEffect(() => {
    setModsPerString(modules_per_string);
  }, [modules_per_string]);

  useEffect(() => {
    if (isBifacial) {
      setShadeModeError(true);
      updateInput("shade_mode", 1);
    } else if (isBifacial && shade_mode == 0) {
      setShadeModeError(true);
      updateInput("shade_mode", 1);
    } else {
      setShadeModeError(false);
    }
  }, [isBifacial, shade_mode]);

  useEffect(() => {
    let updated_tilts = getTilts(parseFloat(inputs.tilt_min), parseFloat(inputs.tilt_max), parseFloat(inputs.tilt_inc));
    // console.log(updated_tilts)
    set_tilts(updated_tilts);
    if (updated_tilts == false) {
      set_tilt_error("Invalid Tilt Inputs");
    } else if (updated_tilts.length > 20) {
      set_tilt_error("Tilt count max is 20");
    } else if (inputs.tilt_min < 0 || inputs.tilt_min > 60) {
      set_tilt_error("Invalid Tilt Min");
    } else if (inputs.tilt_max < 0 || inputs.tilt_max > 60) {
      set_tilt_error("Invalid Tilt Max");
    } else if (inputs.tilt_min > inputs.tilt_max) {
      set_tilt_error("Min must be <= Max");
    } else if (tilt_error != undefined) {
      set_tilt_error(undefined);
    }
  }, [inputs.tilt_min, inputs.tilt_max, inputs.tilt_inc]);

  function updateInput(key, value, tab = undefined) {
    if (tab) {
      dispatch(siftActions.updateInput(key, value, tab));
    } else {
      dispatch(siftActions.updateInput(key, value));
    }
  }

  function toggleRackPopup(e) {
    // let cy = e.clientY;
    let pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    setPopoutY(Math.min(pageHeight - 361, pageHeight * 0.35));
    setRackPopupVisible(!rackPopupVisible);
  }

  // ////////////////////////////////////////////////////////////////////////////////
  // DIMENSION TABLE FUNCTIONS
  // ////////////////////////////////////////////////////////////////////////////////
  function onChageActive(index) {
    let { racks } = inputs;
    racks[index].active = racks[index].active == 0 ? 1 : 0;
    updateInput("racks", racks);
  }

  function onChangeXDim(e, index) {
    let { racks } = inputs;

    let changed_value = parseFloat(e.target.value);

    if (inputs.type == 1 || inputs.type == 2) {
      // SAT - all x dims should be equal to this value
      set_rack_a_x(e.target.value);
      set_rack_b_x(e.target.value);
      set_rack_c_x(e.target.value);

      racks.forEach((r) => {
        r.xdim = changed_value;
      });
    } else {
      if (index == 0) {
        set_rack_a_x(e.target.value);
      } else if (index == 1) {
        set_rack_b_x(e.target.value);
      } else if (index == 2) {
        set_rack_c_x(e.target.value);
      }
      racks[index].xdim = changed_value;
    }

    if (!isNumber(changed_value)) {
      return;
    }

    updateInput("racks", racks);
  }

  function onChangeYDim(e, index) {
    let { racks } = inputs;
    let changed_value = parseFloat(e.target.value);
    if (inputs.type == 0) {
      // GFT - all y dims should be equal to this value
      set_rack_a_y(e.target.value);
      set_rack_b_y(e.target.value);
      set_rack_c_y(e.target.value);

      racks.forEach((r) => {
        r.ydim = changed_value;
      });
    } else {
      if (index == 0) {
        set_rack_a_y(e.target.value);
      } else if (index == 1) {
        set_rack_b_y(e.target.value);
      } else if (index == 2) {
        set_rack_c_y(e.target.value);
      }
      racks[index].ydim = changed_value;
    }

    if (!isNumber(changed_value)) {
      return;
    }
    updateInput("racks", racks);
  }

  function onChangeDriveGap(key, value) {
    set_drive_gap(value);
    let changed_value = parseFloat(value);
    if (!isNumber(changed_value)) return;
    updateInput(key, changed_value);
  }

  function onChangeModule(e, index) {
    let { racks } = inputs;
    racks[index].module = e.target.value;
    updateInput("racks", racks);
  }

  function onChangeModsPerString(key, value) {
    setModsPerString(value);
    let changed_value = parseInt(value);
    if (!isNumber(changed_value)) return;
    updateInput(key, changed_value, "performance");
  }

  function confirmRackType(type) {
    updateInput("type", type);
  }

  function updateStringsPerRack(key, value, index) {
    let { racks } = inputs;

    if (index == 0) {
      set_a_string_count(value);
    } else if (index == 1) {
      set_b_string_count(value);
    } else if (index == 2) {
      set_c_string_count(value);
    }

    racks[index][key] = value;

    updateInput("racks", racks);
  }

  function onChangeModulesHigh(key, value) {
    set_modules_high(value);
    let changed_value = parseInt(value);
    if (!isNumber(changed_value)) return;
    updateInput(key, changed_value);
  }

  function onChangeActive(index) {
    let { racks } = inputs;
    racks[index].active = racks[index].active == 0 ? 1 : 0;
    updateInput("racks", racks);
  }

  function confirmRackingMode(type) {
    updateInput("rack_dims", type);
  }

  return (
    <RackingPanelWrapper>
      <Spin spinning={saving} className="sift-loader">
        <DynamicInputPopout visible={rackPopupVisible} onClose={toggleRackPopup} autoRack={inputs.rack_dims} />

        <TutorialTip
          elementKey="dims-table"
          placement={screenWidth < 768 ? "top" : "right"}
          customTipWidth={screenWidth < 768 ? 120 : undefined}
          customTipHeight={screenWidth < 768 ? 100 : undefined}
          visible={tutorial.visibleTips.racking_tip}
          content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.racking_tip}
          highlight={true}
        >
          <div id="dims-table">
            <div className="input-2-col">
              <label>Rack Type</label>
              <Radio.Group buttonStyle="solid" size="small" value={inputs.type} style={{ marginLeft: "auto" }}>
                <Tooltip placement="top" title="Single-Axis Tracker">
                  <Popconfirm title="Load Generic Single Axis Tracker?" onConfirm={() => confirmRackType(1)} okText="OK" cancelText="Cancel">
                    <Radio.Button key="rack_type2" value={1}>
                      SAT
                    </Radio.Button>
                  </Popconfirm>
                </Tooltip>
                <Tooltip placement="top" title="Ground Fixed Tilt">
                  <Popconfirm title="Load Generic Ground Fixed?" onConfirm={() => confirmRackType(0)} okText="OK" cancelText="Cancel">
                    <Radio.Button key="rack_type1" value={0}>
                      GFT
                    </Radio.Button>
                  </Popconfirm>
                </Tooltip>
                <Tooltip placement="top" title="East-West Fixed Tilt">
                  <Popconfirm title="Load Generic East-West Fixed?" onConfirm={() => confirmRackType(2)} okText="OK" cancelText="Cancel">
                    <Radio.Button key="rack_type3" value={2}>
                      EWF
                    </Radio.Button>
                  </Popconfirm>
                </Tooltip>
              </Radio.Group>
            </div>
          </div>
        </TutorialTip>

        <section className="input-2-col">
          <label>Modules Per String</label>
          <Input id="modules_per_string" size="small" type="number" value={modsPerString} onChange={(e) => onChangeModsPerString(e.target.id, e.target.value)} />
        </section>

        <div style={{ width: "100%", height: 15 }}></div>

        <section className="input-2-col">
          <label>Rack Dimensions</label>
          <Radio.Group value={inputs.rack_dims} style={{ marginLeft: "auto" }} size="small">
            <Popconfirm title="Auto will overwrite all data entered in Manual." onConfirm={() => confirmRackingMode(0)} disabled={inputs.rack_dims == 0} okText="Ok" cancelText="Cancel">
              <Radio id="rack_dims" key="auto" value={0}>
                Auto
              </Radio>
            </Popconfirm>
            <Radio id="rack_dims" key="manual" value={1} onClick={() => confirmRackingMode(1)}>
              Manual
            </Radio>
          </Radio.Group>
        </section>

        {inputs.rack_dims == 0 && (
          <Fragment>
            <section className="input-2-col">
              <label>Module Dimensions</label>
              <p>{`${module_width}m x ${module_length}m`}</p>
            </section>

            <section className="input-2-col">
              <label>Modules High</label>
              <Input id="modules_high" size="small" type="number" min="1" step="1" value={modules_high} onChange={(e) => onChangeModulesHigh(e.target.id, e.target.value.replace(/[^0-9]*/g, ""))} />
            </section>

            <section className="input-2-col">
              <label>Module Orientation</label>
              <RadioGroup value={inputs.orientation} style={{ marginLeft: "auto" }} onChange={(e) => updateInput(e.target.id, e.target.value)}>
                <Radio id="orientation" key="portrait" value={0}>
                  Portrait
                </Radio>
                <Radio id="orientation" key="landscape" value={1}>
                  Landscape
                </Radio>
              </RadioGroup>
            </section>

            <section className="input-header-right">
              <p>{inputs.type == 2 ? `Strings per Face` : `Strings per Rack`}</p>
            </section>

            <section className="input-3-col">
              <label>Rack A</label>
              <section className="toggle">
                <Switch checked={inputs.racks[0].active ? true : false} checkedChildren="On" unCheckedChildren="Off" onChange={() => onChangeActive(0)} />
              </section>
              <Input
                id="string_count"
                size="small"
                type="number"
                disabled={inputs.racks[0].active == 0}
                value={string_a_count}
                step="1.0"
                onChange={(e) => updateStringsPerRack(e.target.id, e.target.value, 0)}
              />
            </section>
            <section className="input-3-col">
              <label>Rack B</label>
              <section className="toggle">
                <Switch checked={inputs.racks[1].active ? true : false} checkedChildren="On" unCheckedChildren="Off" onChange={() => onChangeActive(1)} />
              </section>
              <Input
                id="string_count"
                size="small"
                type="number"
                disabled={inputs.racks[1].active == 0}
                value={string_b_count}
                step="1.0"
                onChange={(e) => updateStringsPerRack(e.target.id, e.target.value, 1)}
              />
            </section>
            <section className="input-3-col">
              <label>Rack C</label>
              <section className="toggle">
                <Switch checked={inputs.racks[2].active ? true : false} checkedChildren="On" unCheckedChildren="Off" onChange={() => onChangeActive(2)} />
              </section>
              <Input
                id="string_count"
                size="small"
                type="number"
                disabled={inputs.racks[2].active == 0}
                value={string_c_count}
                step="1.0"
                onChange={(e) => updateStringsPerRack(e.target.id, e.target.value, 2)}
              />
            </section>

            <section className="input-2-col">
              <label>Mod Gap</label>
              <Input
                id="module_gap"
                style={{ maxWidth: 95, marginLeft: "auto" }}
                size="small"
                type="number"
                step="0.01"
                value={inputs.module_gap}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
                onChange={(e) => updateInput(e.target.id, e.target.value)}
              />
            </section>

            {inputs.type == 1 && (
              <section className="input-2-col">
                <label>Drive Gap</label>
                <Input
                  id="drive_gap"
                  style={{ maxWidth: 95, marginLeft: "auto" }}
                  size="small"
                  type="number"
                  step="0.01"
                  min="0"
                  value={drive_gap}
                  suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
                  onChange={(e) => onChangeDriveGap(e.target.id, e.target.value)}
                />
              </section>
            )}
          </Fragment>
        )}

        {racking_error_present && (
          <section className="racking_error_warning">
            <p>{`*Racking dimension warning! Click table for more detail.`}</p>
          </section>
        )}

        <div>
          <div className="input-row">
            <label>{inputs.type == 2 ? `Racking Dimensions per Face` : `Racking Dimensions`}</label>
            <span></span>
          </div>

          <DimsReadOnlyTable>
            <div className="read-only-table" onClick={toggleRackPopup}>
              <table>
                <tbody>
                  <tr>
                    <td></td>
                    <td>Active</td>
                    <td>X(m)</td>
                    <td>Y(m)</td>
                    <td>Mods</td>
                  </tr>
                  {inputs.racks.map((rack, index) => {
                    return (
                      <tr key={rack.key}>
                        <td>{letters[index]}</td>
                        <td>{rack.active == 1 ? "On" : "Off"}</td>
                        <td>{rack.active == 1 && parseFloat(rack.xdim).toFixed(2)}</td>
                        <td>{rack.active == 1 && parseFloat(rack.ydim).toFixed(2)}</td>
                        <td>{rack.active == 1 && rack.module}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <a>
                <p>
                  <u>{inputs.rack_dims == 0 ? "** Click table to view dimensions **" : "** Click table to edit dimensions **"}</u>
                </p>
              </a>
            </div>
          </DimsReadOnlyTable>
        </div>

        {/* GFT INPUTS */}
        {(inputs.type == 0 || inputs.type == 2) && (
          <div>
            <div>
              {inputs.type == 2 && (
                <div className="input-row">
                  <label>Tilt Angle</label>
                  <Input
                    id="tilt_min"
                    onChange={(event) => {
                      updateInput(event.target.id, event.target.value);
                    }}
                    value={inputs.tilt_min}
                    size="small"
                    disabled={saving}
                    key={31}
                    suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>°</span>}
                  />
                </div>
              )}
              {inputs.type == 0 && (
                <>
                  <div className="tilt-input-label">
                    <label>Tilts</label>
                  </div>
                  <div className="config-inputs" id="config-tip">
                    {tilt_error && (
                      <div className="config-err">
                        <InfoCircleOutlined style={{ color: "#002bcb", fontSize: "12px" }} />
                        <p>{tilt_error}</p>
                      </div>
                    )}
                    <div className="config-input">
                      <div className="min">
                        <label>Min</label>
                        <Input
                          style={{ width: "75px" }}
                          id="tilt_min"
                          size="small"
                          type="number"
                          step={inputs.tilt_inc}
                          min={0}
                          max={60}
                          value={inputs.tilt_min}
                          onChange={(event) => {
                            updateInput(event.target.id, event.target.value);
                          }}
                          // onBlur={(e) => {
                          // 	if (e.target.value < 0.2) {
                          // 		OnUpdateInput('gcr_range', [0.2, inputs.gcr_range[1]]);
                          // 	} else if (e.target.value > inputs.gcr_range[1]) {
                          // 		OnUpdateInput('gcr_range', [inputs.gcr_range[1], inputs.gcr_range[1]]);
                          // 	} else if (e.target.value > inputs.gcr_range[1]) {
                          // 		OnUpdateInput('gcr_range', [inputs.gcr_range[0], e.target.value]);
                          // 	} else if (isNaN(parseFloat(e.target.value))) {
                          // 		OnUpdateInput('gcr_range', [0.2, inputs.gcr_range[1]]);
                          // 	}
                          // 	setRangeError(false);
                          // 	setErrMessage(undefined);
                          // }}
                        />
                      </div>
                      <div className="dash">
                        <span>-</span>
                      </div>
                      <div className="max">
                        <label>Max</label>
                        <Input
                          style={{ width: "75px" }}
                          id="tilt_max"
                          size="small"
                          type="number"
                          step={inputs.tilt_inc}
                          min={0}
                          max={60}
                          value={inputs.tilt_max}
                          onChange={(event) => {
                            updateInput(event.target.id, event.target.value);
                          }}
                          // onBlur={(e) => {
                          // 	if (e.target.value > 1.0) {
                          // 		OnUpdateInput('gcr_range', [inputs.gcr_range[0], 1.0]);
                          // 	} else if (e.target.value < 0) {
                          // 		OnUpdateInput('gcr_range', [inputs.gcr_range[0], Math.max(e.target.value, inputs.gcr_range[0])]);
                          // 	} else if (isNaN(e.target.value) || e.target.value < 0.2 || e.target.value < inputs.gcr_range[0]) {
                          // 		OnUpdateInput('gcr_range', [inputs.gcr_range[0], inputs.gcr_range[0]]);
                          // 	}

                          // 	setRangeError(false);
                          // 	setErrMessage(undefined);
                          // }}
                        />
                      </div>
                      <div className="comma">
                        <span>,</span>
                      </div>
                      <div className="increment">
                        <label>Step</label>
                        <Input
                          style={{ width: "60px" }}
                          id="tilt_inc"
                          size="small"
                          type="number"
                          step={1}
                          min={1}
                          max={60}
                          value={inputs.tilt_inc}
                          onChange={(e) => {
                            updateInput(event.target.id, event.target.value);
                          }}
                        />
                      </div>
                      <Tooltip placement="top" title={"Tilts: [" + Object.values(_tilts).map((tilt) => tilt.toString()) + "]"}>
                        <div className="total">
                          <p>
                            <b>
                              <u>{_tilts.length}</u>
                            </b>
                          </p>
                          <label>Total</label>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  {/* <div className="input-row">
                  <label>Tilt Angle Minimum</label>
                  <Input
                    id="tilt_min"
                    onChange={(event) => {
                      updateInput(event.target.id, event.target.value);
                    }}
                    value={inputs.tilt_min}
                    size="small"
                    disabled={saving}
                    key={31}
                    suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>°</span>}
                  />
                </div>
								<div className="input-row">
									<label>Tilt Angle Maximum</label>
										<Input
											id="tilt_max"
											onChange={(event) => {
												updateInput(event.target.id, event.target.value);
											}}
											value={inputs.tilt_max}
											size="small"
											disabled={saving}
											key={31}
											suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>°</span>}
										/>
								</div> */}
                </>
              )}

              <div className="input-row">
                {/* <label>{inputs.type == 0 ? 'E-W Racking Gap' : 'N-S Racking Gap'}</label> */}
                <label>Rack to Rack Gap</label>

                <Input
                  id="gap"
                  onChange={(event) => {
                    updateInput(event.target.id, event.target.value);
                  }}
                  value={inputs.gap}
                  size="small"
                  disabled={saving}
                  key={30}
                  suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
                />
              </div>

              <div className="input-row">
                <label>Height Above Ground</label>
                <Input
                  id="module_clearance_height"
                  onChange={(event) => {
                    updateInput(event.target.id, event.target.value);
                  }}
                  value={inputs.module_clearance_height}
                  size="small"
                  disabled={saving}
                  suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
                />
              </div>

              <div className="input-row">
                <label>Grade Limit</label>
                <Input
                  id="grade_limit"
                  onChange={(event) => {
                    updateInput(event.target.id, event.target.value);
                  }}
                  value={inputs.grade_limit}
                  size="small"
                  disabled={saving}
                  suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
                />
              </div>

              <div className="input-row">
                <label>Transparent Fraction</label>
                <Input
                  id="module_transmission_perc"
                  onChange={(event) => {
                    updateInput(event.target.id, event.target.value);
                  }}
                  value={inputs.module_transmission_perc}
                  size="small"
                  disabled={saving}
                  suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
                />
              </div>

              <div className="input-row">
                <label>Structure Shade Factor</label>
                <Input
                  id="module_structure_shade_perc"
                  onChange={(event) => {
                    updateInput(event.target.id, event.target.value);
                  }}
                  value={inputs.module_structure_shade_perc}
                  size="small"
                  disabled={saving}
                  suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
                />
              </div>
            </div>

            <div className="input-row">
              <label>Shade Mode</label>
              <Radio.Group
                style={{ display: "flex", flexDirection: "column" }}
                name="shade_mode"
                onChange={(event) => {
                  updateInput(event.target.name, event.target.value);
                }}
                value={inputs.shade_mode}
              >
                {isBifacial ? (
                  <Tooltip placement="top" title={`Linear Shade mode is disabled when using a Bifacial Module. To enable toggle off 'Bifacial' in the Module menu.`}>
                    <Radio name="Linear" value={0} disabled={true}>
                      Linear
                    </Radio>
                  </Tooltip>
                ) : (
                  <Radio name="Linear" value={0}>
                    Linear
                  </Radio>
                )}
                <Radio name="Electrical" value={1}>
                  Electrical
                </Radio>
              </Radio.Group>
            </div>

            {inputs.shade_mode == 1 && (
              <div>
                <div className="input-row">
                  <label>Collector Width</label>
                  <span>{inputs.type == 0 ? inputs.racks[0].ydim : inputs.racks[0].xdim} m</span>
                </div>

                <div className="input-row">
                  <label>String Steps</label>
                  <Input
                    id="string_steps"
                    onChange={(event) => {
                      updateInput(event.target.id, event.target.value);
                    }}
                    value={inputs.string_steps}
                    size="small"
                    disabled={saving}
                  />
                </div>

                <div className="input-row">
                  <label>Cell Transverse</label>
                  <Input
                    id="cell_trav"
                    onChange={(event) => {
                      updateInput(event.target.id, event.target.value);
                    }}
                    value={inputs.cell_trav}
                    size="small"
                    disabled={saving}
                    key={35}
                    suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {/* SAT INPUTS */}
        {inputs.type == 1 && (
          <div>
            <div className="input-row">
              <label>Grade Limit</label>
              <Input
                id="grade_limit"
                onChange={(event) => {
                  updateInput(event.target.id, event.target.value);
                }}
                value={inputs.grade_limit}
                size="small"
                disabled={saving}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
              />
            </div>
            <div className="input-row">
              <label>Rack to Rack Gap</label>
              <Input
                id="gap"
                onChange={(event) => {
                  updateInput(event.target.id, event.target.value);
                }}
                value={inputs.gap}
                size="small"
                disabled={saving}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
              />
            </div>
            <div className="input-row">
              <label>Height Above Ground</label>
              <Input
                id="module_clearance_height"
                onChange={(event) => {
                  updateInput(event.target.id, event.target.value);
                }}
                value={inputs.module_clearance_height}
                size="small"
                disabled={saving}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
              />
            </div>

            <div className="input-row">
              <label>Transparent Fraction</label>
              <Input
                id="module_transmission_perc"
                onChange={(event) => {
                  updateInput(event.target.id, event.target.value);
                }}
                value={inputs.module_transmission_perc}
                size="small"
                disabled={saving}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
              />
            </div>

            <div className="input-row">
              <label>Structure Shade Factor</label>
              <Input
                id="module_structure_shade_perc"
                onChange={(event) => {
                  updateInput(event.target.id, event.target.value);
                }}
                value={inputs.module_structure_shade_perc}
                size="small"
                disabled={saving}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
              />
            </div>

            <div className="input-row">
              <label>Backtracking</label>
              <Radio.Group
                name="backtrack"
                style={{ marginRight: "auto" }}
                options={backtrackOption}
                onChange={(event) => {
                  updateInput(event.target.name, event.target.value);
                }}
                value={inputs.backtrack}
              />
            </div>

            <div className="input-row">
              <label>Tracking Angle</label>
              <Input
                id="track_angle"
                onChange={(event) => {
                  updateInput(event.target.id, event.target.value);
                }}
                value={inputs.track_angle}
                size="small"
                disabled={saving}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>°</span>}
              />
            </div>

            {/* <div className="input-row">
              <label>Grade Limit</label>
              <Input
                id="grade_limit"
                onChange={(event) => {
                  updateInput(event.target.id, event.target.value);
                }}
                value={inputs.grade_limit}
                size="small"
                disabled={saving}
                suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
              />
            </div> */}

            {!inputs.backtrack && (
              <div>
                <div className="input-row">
                  <label>Shade Mode</label>
                  <Radio.Group
                    style={{ display: "flex", flexDirection: "column" }}
                    name="shade_mode"
                    onChange={(event) => {
                      updateInput(event.target.name, event.target.value);
                    }}
                    value={inputs.shade_mode}
                  >
                    {isBifacial ? (
                      <Tooltip placement="top" title={`Linear Shade mode is disabled when using a Bifacial Module. To enable toggle off 'Bifacial' in the Module menu.`}>
                        <Radio name="Linear" value={0} disabled={true}>
                          Linear
                        </Radio>
                      </Tooltip>
                    ) : (
                      <Radio name="Linear" value={0}>
                        Linear
                      </Radio>
                    )}
                    <Radio name="Electrical" value={1}>
                      Electrical
                    </Radio>
                  </Radio.Group>
                </div>

                {/* <div style={{height:10}}></div> */}

                {inputs.shade_mode == 1 && (
                  <div>
                    <div className="input-row">
                      <label>Collector Width</label>
                      <span>{inputs.type == 0 ? inputs.racks[0].ydim : inputs.racks[0].xdim} m</span>
                    </div>

                    <div className="input-row">
                      <label>String Steps</label>
                      <Input
                        id="string_steps"
                        onChange={(event) => {
                          updateInput(event.target.id, event.target.value);
                        }}
                        value={inputs.string_steps}
                        size="small"
                        disabled={saving}
                      />
                    </div>

                    <div className="input-row">
                      <label>Cell Transverse</label>
                      <Input
                        id="cell_trav"
                        onChange={(event) => {
                          updateInput(event.target.id, event.target.value);
                        }}
                        value={inputs.cell_trav}
                        size="small"
                        disabled={saving}
                        key={35}
                        suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </Spin>
    </RackingPanelWrapper>
  );
};

export { RackingInputs };
