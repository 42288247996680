import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { siftActions, isNumber } from "../../Redux";

import { Spin, Switch, Input, Tooltip } from "antd";

import ReactDataSheet from "react-datasheet";
import { SheetRenderer } from "../SheetRenderer";

// const Months = ['January','February','March','April','May','June','July','August','September','October','November','December']
const Months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
const ModelOptions = [
  { label: "Sunfig", value: 0 },
  // { label: "NREL SAM", value: 1, disabled: true}
];
const columns = [
  { label: "", width: "5%" },
  { label: "", width: "5%" },
  { label: "", width: "5%" },
];

const renderSheet = (props) => {
  return <SheetRenderer columns={columns} {...props} />;
};

const PerformanceInputs = (props) => {
  const inputs = useSelector((state) => state.sift.ioManager.inputs.performance.data);
  const account_loading = useSelector((state) => state.account.account_loading);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const doFinance = useSelector((state) => state.sift.ioManager.inputs.config.data.do_finance == 1);
  const saving = account_loading || input_loading;
  const pro_user = useSelector((state) => state.account.current_plan == 3);
  const screenWidth = useSelector((state) => state.sift.ioManager.uiState.screenWidth);
  const tab = useSelector((state) => state.sift.ioManager.uiState.tab);

  const modules_per_string = useSelector((state) => state.sift.ioManager.inputs.performance.data.modules_per_string);

  const [showErrorTip, setShowErrorTip] = useState(false);
  const [modsPerString, setModsPerString] = useState(modules_per_string);

  const dispatch = useDispatch();

  function isNumeric(n) {
    if (n !== "") {
      let numeric = !isNaN(parseFloat(n)) && isFinite(n);

      if (numeric) {
        setShowErrorTip(false);
      } else {
        setShowErrorTip(true);
      }
    } else {
      setShowErrorTip(false);
    }
  }

  function updateInput(key, value) {
    if (key == "modules_per_string") {
      setModsPerString(value);
      let changed_value = parseInt(value);
      if (!isNumber(changed_value)) return;
      dispatch(siftActions.updateInput(key, changed_value));
    } else {
      dispatch(siftActions.updateInput(key, value));
    }
  }

  let dt_data = [];

  function handleLoadMonthlyTable() {
    var data = [
      [
        { value: "Month", readOnly: true },
        { value: "Soiling (%)", readOnly: true },
        { value: "Albedo", readOnly: true },
      ],
    ];
    for (var i = 0; i < 12; i++) {
      data.push([
        { readOnly: true, value: Months[i] },
        { readOnly: false, value: inputs.soiling_monthly[i] },
        { readOnly: false, value: inputs.albedo_monthly[i] },
      ]);
    }
    dt_data = data;
  }
  function handleChanges(changes) {
    const data = dt_data.map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      if (data[row] && data[row][col]) {
        // NS: Fix for stripping % and $ from excel copied values
        let fixed_value = value.replace("$", "").replace("%", "");
        data[row][col] = { ...data[row][col], value: fixed_value };
      }
    });
    let newSoilData = data.slice(1, data.length);
    var Soiling = [];
    var Albedo = [];
    newSoilData.forEach((row) => {
      let soil = parseFloat(row[1].value) == row[1].value ? parseFloat(row[1].value) : 0;
      let albedo = parseFloat(row[2].value) == row[2].value ? parseFloat(row[2].value) : 0;
      Soiling.push(soil);
      Albedo.push(albedo);
    });
    updateInput("soiling_monthly", Soiling);
    updateInput("albedo_monthly", Albedo);
  }

  // generate monthly table
  handleLoadMonthlyTable();

  return (
    <div className="input-content-box">
      <Spin spinning={saving} className="sift-loader">
        {/* <div className="input-row name">
          <label>
            Name
          </label>
          <Input id='name'  
            onChange={(event)=> {updateInput(event.target.id,event.target.value)}} 
            value={inputs.name} size='small'
            disabled={saving}     
          />
        </div>  */}

        {/* <div className="input-row">
          <label>    
            Performance Model          
          </label>
          <RadioGroup name='performance_model'
            options={ModelOptions} 
            onChange={(event)=> {updateInput(event.target.name,event.target.value)}} 
            value={inputs.performance_model}
            disabled={saving}
          />
        </div>

        <div className="input-row three">
          <label>
            Grid Pwr Limit
          </label>
          <span style={{marginRight: 5}}>
            <Switch size="small" id='doGridPower' 
              checked={inputs.doGridPower==1} 
              onChange={(b,e)=>{updateInput('doGridPower', b?1:0)}}
            />    
          </span>
          <Input size='small'
            id="grid_power_limit"
            value={inputs.grid_power_limit}
            disabled={inputs.doGridPower==0}
            onChange={(event)=> {updateInput(event.target.id,event.target.value)}}
            suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>kW</span>} 
          />               
        </div> */}
        <Tooltip placement="right" title="Edit value in Racking">
          <div className="input-row">
            <label>Modules Per String</label>
            <p style={{ margin: "0px 5px 0px auto" }}>{modules_per_string}</p>
            {/* <Input
            id="modules_per_string"
            onChange={(event) => {
              updateInput(event.target.id, event.target.value);
              // isNumeric(event.target.value);
            }}
            value={modules_per_string}
            size="small"
            autoComplete="off"
            disabled={saving}
            onBlur={(e) => isNumeric(parseInt(event.target.value))}
          /> */}
          </div>
        </Tooltip>

        <div className="input-row">
          <label>DC Degradation</label>
          <Input
            id="dc_degradation"
            onChange={(event) => {
              updateInput(event.target.id, event.target.value);
            }}
            value={inputs.dc_degradation}
            size="small"
            autoComplete="off"
            disabled={saving}
            suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%/yr</span>}
          />
        </div>

        <div className="input-row">
          <label>Mismatch Loss</label>
          <Input
            id="mismatch_loss"
            onChange={(event) => {
              updateInput(event.target.id, event.target.value);
            }}
            value={inputs.mismatch_loss}
            size="small"
            autoComplete="off"
            disabled={saving}
            suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
          />
        </div>

        <div className="input-row">
          <label>DC Loss @STC</label>
          <Input
            id="dc_loss_stc"
            onChange={(event) => {
              updateInput(event.target.id, event.target.value);
            }}
            value={inputs.dc_loss_stc}
            size="small"
            autoComplete="off"
            disabled={saving}
            suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
          />
        </div>

        <div className="input-row">
          <label>PV Quality Loss</label>
          <Input
            id="quality_loss"
            onChange={(event) => {
              updateInput(event.target.id, event.target.value);
            }}
            value={inputs.quality_loss}
            size="small"
            autoComplete="off"
            disabled={saving}
            suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
          />
        </div>

        <div className="input-row">
          <label>PV LID Loss</label>
          <Input
            id="lid_loss"
            onChange={(event) => {
              updateInput(event.target.id, event.target.value);
            }}
            value={inputs.lid_loss}
            size="small"
            autoComplete="off"
            disabled={saving}
            suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
          />
        </div>

        <div className="input-row">
          <label>AC Losses</label>
          <Input
            id="ac_losses"
            onChange={(event) => {
              updateInput(event.target.id, event.target.value);
            }}
            value={inputs.ac_losses}
            size="small"
            autoComplete="off"
            disabled={saving}
            suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
          />
        </div>

        <div className="input-row">
          <label>Thermal Uc</label>
          <Input
            id="thermal_uc"
            onChange={(event) => {
              updateInput(event.target.id, event.target.value);
            }}
            value={inputs.thermal_uc}
            size="small"
            autoComplete="off"
            disabled={saving}
            suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>W/m²k</span>}
          />
        </div>

        <div className="input-row">
          <label>Thermal Uv</label>
          <Input
            id="thermal_uv"
            onChange={(event) => {
              updateInput(event.target.id, event.target.value);
            }}
            value={inputs.thermal_uv}
            size="small"
            autoComplete="off"
            disabled={saving}
            suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>W/m²k/m/s</span>}
          />
        </div>

        <div className="input-row">
          <label>Albedo</label>
          <Input
            id="albedo"
            onChange={(event) => {
              updateInput(event.target.id, event.target.value);
            }}
            value={inputs.albedo}
            size="small"
            autoComplete="off"
            disabled={saving}
            disabled={inputs.doMonthlySoiling == 1}
            // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>W/m²k/m/s</span>}
          />
        </div>

        <div className="input-row">
          <label>Soiling</label>
          <Input
            id="soiling"
            onChange={(event) => {
              updateInput(event.target.id, event.target.value);
            }}
            value={inputs.soiling}
            size="small"
            autoComplete="off"
            disabled={saving}
            disabled={inputs.doMonthlySoiling == 1}
            suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
          />
        </div>

        <div className="input-row">
          <label>Monthly Values</label>
          <Switch
            size="small"
            id="doMonthlySoiling"
            checked={inputs.doMonthlySoiling == 1}
            onChange={(b, e) => {
              updateInput("doMonthlySoiling", b ? 1 : 0);
            }}
          />
        </div>

        {inputs.doMonthlySoiling == 1 && (
          <div className="input-table">
            <ReactDataSheet
              data={dt_data}
              sheetRenderer={renderSheet}
              valueRenderer={(cell) => cell.value}
              onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
              onCellsChanged={handleChanges}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export { PerformanceInputs };
