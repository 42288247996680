import React, { useState, useRef, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Radio, Tooltip, Button, Spin, Modal, message } from "antd";
import { ErrorFromSIFT } from "./ErrorFromSIFT";
import { Loader } from "./Loader";
import { CSVLink } from "react-csv";

// components
import { Spinner } from "../../Spinner";

import { alertActions, siftActions, getSIFTInputs } from "../../Redux";

import {
  DownSquareOutlined,
  UpSquareOutlined,
  MinusSquareOutlined,
  FileExcelOutlined,
  CopyOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  ClusterOutlined,
  BuildOutlined,
  BarChartOutlined,
  SmileOutlined,
  FileDoneOutlined,
  FileZipOutlined,
  AppstoreOutlined,
  FileImageOutlined,
} from "@ant-design/icons";

import { GeneratingResultsTab, ResultsGenerated, StepListItem } from "../StyledComponents";

// const icons = {
//   0: <FileUnknownOutlined />,
//   1: <ClusterOutlined />,
//   2: <BuildOutlined />,
//   3: <BarChartOutlined />,
//   4: <SmileOutlined />,
// };

const ResultTab = (props) => {
  const ioManager = useSelector((state) => state.sift.ioManager);
  const outputs = ioManager.outputs;
  const selectedResult = outputs.selectedResult;
  const runState = outputs.runState;
  const doFinance = ioManager.inputs.config.data.do_finance == 1;
  const [sortMode, setSortMode] = useState({ col: "gcr", sort: "ascend" });
  const [columns, setColumns] = useState(["gcr", "dcac", "YIELD", "lcoe"]);

  const [settingColumns, setSettingColumns] = useState(false);
  const [whichColumn, setWhichColumn] = useState(undefined);
  const [detailedResults, setDetailedResults] = useState([]);

  const [gcrOrPitch, setGcrOrPitch] = useState("gcr");
  const [lcoeOrIrr, setLcoeOrIrr] = useState("lcoe");
  const [resultTabOpen, setResultTabOpen] = useState(false);

  const sift_inputs = useSelector((state) => state.sift.ioManager.inputs);
  const current_plan = useSelector((state) => state.account.current_plan);

  const detailsVisible = ioManager.uiState.output_detail_visible;

  const loading_layout = useSelector((state) => state.sift.ioManager.uiState.loading_layout);
  const loading_files = useSelector((state) => state.sift.ioManager.uiState.loading_files);
  const map_loading = useSelector((state) => state.sift.ioManager.inputs.map.loading_map);
  const steps = useSelector((state) => state.sift.ioManager.outputs.runState.steps);
  const running = useSelector((state) => state.sift.ioManager.outputs.runState.running);
  const generated = useSelector((state) => state.sift.ioManager.outputs.runState.generated);
  const activeTab = useSelector((state) => state.sift.ioManager.uiState.tab);

  // refs
  const textArea = useRef(null);

  // const csvData = outputs.csvData
  // const fileName = outputs.fileName

  const dispatch = useDispatch();

  // write a custom sorter for this results to be based on sortMode
  const results = onSortResults(Object.values(outputs.results));
  const code = outputs.code;
  const errors = outputs.errors;

  // TEST DATA
  // const code = 152
  // const results = onSortResults([
  //   {id:'1234',gcr:0.35,pitch:1.34,dcac:1.45,YIELD:1234.123,lcoe:0.45,irr:0.93},
  //   {id:'456',gcr:0.36,pitch:1.45,dcac:1.35,YIELD:4000.123,lcoe:0.434,irr:0.922}])

  // useEffect(() => {
  //   if (results.length > 0) return;
  //   // console.log(sift.ioManager.inputs)
  //   let inputs = getSIFTInputs(sift_inputs, current_plan);
  //   // console.log(inputs)
  //   // return
  //   inputs.validatedInputs['runId'] = outputs.runState.runId;
  //   inputs.validatedInputs['warnings'] = inputs.warnings;
  //   // if weather undefined, get weather first
  //   if (inputs.validatedInputs.performance.weather == undefined) {
  //     dispatch(siftActions.initSIFTWithWeather(inputs.validatedInputs));
  //   } else {
  //     dispatch(siftActions.initSIFT(inputs.validatedInputs));
  //   }
  // }, []);

  // useEffect(()=>{
  //   if (Object.values(errors).length > 0) {
  //     Modal.error({
  //       title: '',
  //       content: <ErrorFromSIFT error_messages={errors}/>,
  //       footer: null,
  //       width: '50%',
  //       className: 'sift-modal',
  //       style: {maxWidth:'1300px'},
  //       icon: null,
  //       maskClosable: true,
  //       okText: 'Close',
  //       okType: 'ghost',
  //       onCancel: () => dispatch(siftActions.clearErrors())
  //     });
  //   }
  // }, [outputs.errors]);

  useEffect(() => {
    if (generated && activeTab === "results") {
      dispatch(siftActions.toggleHelpToolbarVisibility("output_detail_visible", true));
    }

    // else if (generated && activeTab == undefined) {
    //   dispatch(siftActions.toggleHelpToolbarVisibility('output_detail_visible', false));
    // }
  }, [activeTab]);

  // useEffect(() => {
  //   if (!detailsVisible) {
  //     dispatch(siftActions.updateTab(undefined));
  //   }
  // }, [detailsVisible]);

  useEffect(() => {
    if (Object.values(outputs.results).length > 0) {
      // setDetailsVisible(true)
      dispatch(siftActions.toggleHelpToolbarVisibility("output_detail_visible", true));
    } else {
      // setActiveTab('resultList');
      setDetailedResults([]);
    }
  }, [Object.values(outputs.results).length]);

  function expandTable() {}
  function onSetMiniSort(sorter, event) {
    // this button is nested in a column header that is clickable too, so stop event from bubbling to that header
    event.stopPropagation();

    if (sorter == sortMode.col && sortMode.sort == "ascend") {
      // flip
      setSortMode({ col: sorter, sort: "descend" });
    } else {
      // defaults to ascend
      setSortMode({ col: sorter, sort: "ascend" });
    }
  }
  function onSelectMiniResult(result) {
    if (selectedResult.id != result.id) {
      // console.log('click')
      // setSelectedId(result.id)
      dispatch(siftActions.selectResult(result));
    }
  }
  function onDoubleClickResult(result) {
    // console.log('double click');
    dispatch(siftActions.getLayoutData(result));
    dispatch(siftActions.toggleHelpToolbarVisibility("output_detail_visible", true));
  }

  function onSortResults(results) {
    if (sortMode.sort == "ascend") {
      // ascending
      results.sort((a, b) => a[sortMode.col] - b[sortMode.col]);
    } else {
      // descending
      results.sort((a, b) => b[sortMode.col] - a[sortMode.col]);
    }
    return results;
  }
  function onClickName(col) {
    if (whichColumn == col) {
      // toggling same column
      setSettingColumns(!settingColumns);
    } else {
      // toggling a different column, auto it on
      setWhichColumn(col);
      setSettingColumns(true);
    }
  }
  function onChangeColumn(value) {
    if (whichColumn == 0) {
      setGcrOrPitch(value);
    } else {
      setLcoeOrIrr(value);
    }
  }

  function onCopyResults() {
    // Copy to Clipoard
    textArea.current.hidden = false;
    textArea.current.select();
    document.execCommand("copy");
    textArea.current.hidden = true;
    message.success("Results copied to clipboard", 3.5);
    // dispatch(alertActions.success('Results copied to clipboard'));
  }

  function onDownloadFiles(result) {
    dispatch(siftActions.downloadFiles(result.id));
  }
  function onExportMap(fileType) {
    dispatch(siftActions.exportMap(fileType));
  }

  function onPlot(result = undefined) {
    let selectedResultItem = result || selectedResult;
    dispatch(siftActions.getLayoutData(selectedResultItem));
  }

  return (
    <GeneratingResultsTab>
      {running && !generated && !detailsVisible && (
        <div className="generating-steps">
          {/* <Spinner height={'20px'} width={'20px'} color={'#002bcb'} /> */}
          {steps.validating.status === "process" && (
            <div className="run-step">
              <div className="step-title">
                <p>{steps.validating.title}</p>
                <Spinner height={"22px"} width={"22px"} color={"#002bcb"} />
              </div>
            </div>
          )}
          {steps.deploying.status === "process" && (
            <div className="run-step">
              <div className="step-title">
                <p>{steps.deploying.title}</p>
                <Spinner height={"22px"} width={"22px"} color={"#002bcb"} />
              </div>
            </div>
          )}
          {steps.layouts.status === "process" && (
            <div className="run-step">
              <div className="step-title">
                <p>{steps.layouts.title}</p>
                <Spinner height={"22px"} width={"22px"} color={"#002bcb"} />
              </div>
            </div>
          )}
          {steps.modeling.status === "process" && (
            <div className="run-step">
              <div className="step-title">
                <p>{steps.modeling.title}</p>
                <Spinner height={"22px"} width={"22px"} color={"#002bcb"} />
              </div>
            </div>
          )}
          {steps.complete.status === "process" && (
            <div className="run-step">
              <div className="step-title">
                <p>{steps.complete.title}</p>
                <Spinner height={"22px"} width={"22px"} color={"#002bcb"} />
              </div>
            </div>
          )}
          {/* this is the only way I could get the step bar to pop out of the dom at the appropriate time */}
          {steps.complete.status !== "complete" && (
            <div className="step-bar">
              <ul className="steps">
                <StepListItem zIndex={10} running={running} stepStatus={steps.validating.status} className={running && steps.validating.status === "process" ? "active" : null}>
                  {running && steps.validating.status === "finish" ? <CheckOutlined style={{ color: "#002bcb" }} /> : <FileDoneOutlined />}
                </StepListItem>
                <StepListItem zIndex={9} running={running} stepStatus={steps.deploying.status} className={running && steps.deploying.status === "process" ? "active" : null}>
                  {running && steps.deploying.status === "finish" ? <CheckOutlined style={{ color: "#002bcb" }} /> : <ClusterOutlined />}
                </StepListItem>
                <StepListItem zIndex={8} running={running} stepStatus={steps.layouts.status} className={running && steps.layouts.status === "process" ? "active" : null}>
                  {running && steps.layouts.status === "finish" ? <CheckOutlined style={{ color: "#002bcb" }} /> : <BuildOutlined />}
                </StepListItem>
                <StepListItem zIndex={7} running={running} stepStatus={steps.modeling.status} className={running && steps.modeling.status === "process" ? "active" : null}>
                  {running && steps.modeling.status === "finish" ? <CheckOutlined style={{ color: "#002bcb" }} /> : <BarChartOutlined />}
                </StepListItem>
                <StepListItem zIndex={6} running={running} stepStatus={steps.complete.status} className={running && steps.complete.status === "process" ? "active" : null}>
                  {running && steps.complete.status === "finish" ? <CheckOutlined style={{ color: "#002bcb" }} /> : <SmileOutlined />}
                </StepListItem>
              </ul>
            </div>
          )}
        </div>
      )}
      {generated && !detailsVisible && (
        <div className="results-tab-actions">
          <div className="all-results-actions">
            <p>All Results:</p>
            <div className="all-results-action-buttons">
              <CSVLink filename={outputs.fileName} data={outputs.csvData}>
                <Tooltip placement="top" title="All results to CSV" mouseEnterDelay={0.5}>
                  <Button ghost type="primary" size="small" style={{ width: "100%" }}>
                    <FileExcelOutlined />
                    CSV
                  </Button>
                </Tooltip>
              </CSVLink>
              <Tooltip placement="top" title="All results to clipboard" mouseEnterDelay={0.5}>
                <Button ghost type="primary" onClick={onCopyResults} size="small">
                  <CopyOutlined />
                  Copy
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className="all-results-actions">
            <p>Current Result:</p>
            {selectedResult && (
              <span className="current-result-info">
                {selectedResult.gcr.toFixed(3)} GCR, {selectedResult.dcac.toFixed(3)} DC:AC
              </span>
            )}
            <div className="all-results-action-buttons">
              {/* <Tooltip placement="top" title="Plot selected result in the map" mouseEnterDelay={0.5}>
                <Button ghost type="primary" icon={<BuildOutlined />} onClick={() => onPlot(selectedResult)} loading={loading_layout} size="small">
                  Plot
                </Button>
              </Tooltip> */}

              <Tooltip placement="top" title="Selected result and 8760 performance to CSV" mouseEnterDelay={0.5}>
                <Button ghost type="primary" icon={<FileZipOutlined />} onClick={() => onDownloadFiles(selectedResult)} loading={loading_files} size="small">
                  Report
                </Button>
              </Tooltip>

              <Tooltip placement="top" title="Export layout to DXF" mouseEnterDelay={0.5}>
                <Button ghost type="primary" icon={<AppstoreOutlined />} onClick={() => onExportMap("export_dxf")} loading={map_loading} size="small">
                  CAD
                </Button>
              </Tooltip>

              <Tooltip placement="top" title="Export map to KMZ" mouseEnterDelay={0.5}>
                <Button ghost type="primary" icon={<FileImageOutlined />} onClick={() => onExportMap("export_kml")} loading={map_loading} size="small">
                  KMZ
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      )}

      {/* used for the copy function */}
      <textarea
        hidden
        ref={textArea}
        name="TextResults"
        cols="40"
        rows="50"
        value={outputs.tsvData}
        onChange={(e) => {
          console.log("copied to clipboard");
        }}
      />
    </GeneratingResultsTab>
  );
};

export { ResultTab };
