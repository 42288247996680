import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// antd
import { Input, Button, Select, Tooltip, Switch } from "antd";
import { MenuFoldOutlined, EllipsisOutlined, LockOutlined, UserOutlined, MinusCircleOutlined, ImportOutlined } from "@ant-design/icons";

// components
import ReactDataSheet from "react-datasheet";
import { renderSheet } from "../SheetRenderer";
import { debounce } from "../helper";
import { siftActions } from "../../Redux";

const tech_options = ["Si-mono", "Si-poly", "CdTe"];

const ModuleInputs = () => {
  // const [inputs, setInputs] = useState(useSelector((state) => state.sift.ioManager.inputs.module.data))
  const inputs = useSelector((state) => state.sift.ioManager.inputs.module.data);
  const account_loading = useSelector((state) => state.account.account_loading);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const saving = account_loading || input_loading;

  const dropdown = useSelector((state) => state.sift.ioManager.dropdowns.module);
  const dispatch = useDispatch();

  // const updateInputDeb = debounce((key, value) => {
  //   dispatch(siftActions.updateInput(key, value))

  // }, 200);
  function updateInput(key, value) {
    // inputs[key] = value;
    // setInputs({
    //   ...inputs,
    //   key: value
    // })
    // updateInputDeb(key,value)
    dispatch(siftActions.updateInput(key, value));
  }

  let iam_dt_data = [];
  let iam_index = 0;

  // ////////////////////////////////////////////
  //	IAM DATA TABLE FUNCTION
  function handleCreateIAMData() {
    var dt_data = [
      [
        { value: "Angle", readOnly: true },
        { value: "IAM", readOnly: true },
      ],
    ];
    let data = inputs;
    var def = data.default == 1;

    for (var index in data.module_iam_ang) {
      if (def) {
        // dt_data.splice(1,0,[
        dt_data.push([
          { readOnly: def, value: data.module_iam_ang[index] },
          { readOnly: def, value: data.module_iam_eff[index] },
        ]);
      } else {
        // dt_data.splice(1,0,[
        dt_data.push([
          { readOnly: def, value: data.module_iam_ang[index] },
          { readOnly: def, value: data.module_iam_eff[index] },
          {
            readOnly: true,
            value: "",
            component: (
              <Tooltip placement="right" title="(Insert Below) / (Delete)" mouseEnterDelay={0.5}>
                <div style={{ width: 60 }}>
                  <Button style={{ marginRight: 2, borderColor: "#ffffff" }} shape="circle" id={index} size="small" type="primary" ghost onClick={onInsertRow}>
                    <MenuFoldOutlined />
                  </Button>
                  <Button style={{ marginRight: 2, borderColor: "#ffffff" }} shape="circle" id={(parseInt(index) + 1) * 10} size="small" type="primary" ghost onClick={onRemoveRow}>
                    <MinusCircleOutlined />
                  </Button>
                </div>
              </Tooltip>
            ),
            forceComponent: true,
          },
        ]);
      }
    }

    if (data.module_iam_ang && data.module_iam_ang.length == 0) {
      dt_data.splice(1, 0, [
        { readOnly: def, value: 0 },
        { readOnly: def, value: 0 },
        {
          readOnly: true,
          value: "",
          component: (
            <Tooltip placement="right" title="(Insert Below) / (Delete)" mouseEnterDelay={0.5}>
              <div style={{ width: 60 }}>
                <Button style={{ marginRight: 2, borderColor: "#ffffff" }} shape="circle" id={0} size="small" type="primary" ghost onClick={this.onInsertRow}>
                  <MenuFoldOutlined />
                </Button>
                <Button style={{ marginRight: 2, borderColor: "#ffffff" }} shape="circle" id={10} size="small" type="primary" ghost onClick={this.onRemoveRow}>
                  <MinusCircleOutlined />
                </Button>
              </div>
            </Tooltip>
          ),
          forceComponent: true,
        },
      ]);
    }

    iam_dt_data = dt_data;
    iam_index = index;
  }
  function onInsertRow(e) {
    let data = inputs;
    let index = parseInt(e.target.id);
    data.module_iam_ang.splice(index + 1, 0, 0);
    data.module_iam_eff.splice(index + 1, 0, 1);
    updateInput("module_iam_ang", data.module_iam_ang);
    updateInput("module_iam_eff", data.module_iam_eff);
    handleCreateIAMData();
  }
  function onRemoveRow(e) {
    let data = inputs;
    if (data.module_iam_ang.length == 1) return;
    let index = e.target.id / 10 - 1;
    data.module_iam_ang.splice(index, 1);
    data.module_iam_eff.splice(index, 1);
    updateInput("module_iam_ang", data.module_iam_ang);
    updateInput("module_iam_eff", data.module_iam_eff);
    handleCreateIAMData();
  }
  function handleChanges(changes) {
    const data = iam_dt_data.map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      if (data[row] && data[row][col]) {
        data[row][col] = { ...data[row][col], value };
      }
    });
    let newModData = data.slice(1, data.length);
    let Angle = [];
    let Eff = [];
    newModData.forEach((row) => {
      let angle = parseFloat(row[0].value) == row[0].value ? parseFloat(row[0].value) : 0;
      let eff = parseFloat(row[1].value) == row[1].value ? parseFloat(row[1].value) : 0;
      Angle.push(angle);
      Eff.push(eff);
    });
    updateInput("module_iam_ang", Angle);
    updateInput("module_iam_eff", Eff);
  }
  // create the IAM data table
  handleCreateIAMData();
  // ////////////////////////////////////////////

  return (
    <div className="input-content-box">
      {/* <Spin spinning={saving} className="sift-loader"> */}
      {/* NAME */}
      {/* <div className="input-row name">
          <label>
            Name
          </label>
          <Input id='name'  
            onChange={(event)=> {updateInput(event.target.id,event.target.value)}} 
            value={inputs.name} size='small'
            disabled={saving}     
          />
        </div>  */}
      {/* RATING && TECHNOLOGY */}
      <div className="input-row">
        <label>Module Rating</label>
        <Input
          id="rating"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.rating}
          size="small"
          disabled={saving}
          key={30}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>W</span>}
        />
      </div>
      {/* BIFACIAL && BIFACIALITY FACTOR */}
      <div className="input-row">
        <label>Bifacial</label>
        <Switch
          size="small"
          id="module_isbifacial"
          checked={inputs.module_isbifacial == 1}
          onChange={(b, e) => {
            updateInput("module_isbifacial", b ? 1 : 0);
          }}
        />
      </div>
      <div className="input-row">
        <label>Bifaciality Factor</label>
        <Input
          id="module_bifaciality"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.module_bifaciality}
          size="small"
          disabled={inputs.module_isbifacial == 0 || saving}
        />
      </div>
      <div className="input-row">
        <label>Dimension X</label>
        <Input
          id="mlm_Width"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_Width}
          size="small"
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
          disabled={saving}
        />
      </div>
      <div className="input-row">
        <label>Dimension Y</label>
        <Input
          id="mlm_Length"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_Length}
          size="small"
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
          disabled={saving}
        />
      </div>
      {/* MODULE AREA*/}
      <div className="input-row">
        <label>Module Area</label>
        <p>{`${parseFloat(inputs.mlm_Width * inputs.mlm_Length).toFixed(2) || 0} m²`}</p>
      </div>
      <div style={{ height: 25 }}></div>
      <div className="input-row">
        <label>Technology</label>
        <Select
          value={tech_options[inputs.technology]}
          size="small"
          style={{ width: "100%" }}
          onChange={(value) => {
            updateInput("technology", value);
          }}
          disabled={inputs.default == 1}
        >
          <Select.Option key="technology1" value="0">
            Si-mono
          </Select.Option>
          <Select.Option key="technology2" value="1">
            Si-poly
          </Select.Option>
          <Select.Option key="technology3" value="2">
            CdTe
          </Select.Option>
        </Select>
      </div>
      {/* GREF && TREF */}
      <div className="input-row">
        <label>Gref</label>
        <Input
          id="mlm_S_ref"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_S_ref}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>W/m²</span>}
        />
      </div>
      <div className="input-row">
        <label>Tref</label>
        <Input
          id="mlm_T_ref"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_T_ref}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>°C</span>}
        />
      </div>
      {/* ISC && VOC */}
      <div className="input-row">
        <label>Isc</label>
        <Input
          id="mlm_I_sc_ref"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_I_sc_ref}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>A</span>}
        />
      </div>
      <div className="input-row">
        <label>Voc</label>
        <Input
          id="mlm_V_oc_ref"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_V_oc_ref}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>V</span>}
        />
      </div>
      {/* IMPP && VMP */}
      <div className="input-row">
        <label>Imp</label>
        <Input
          id="mlm_I_mp_ref"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_I_mp_ref}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>A</span>}
        />
      </div>
      <div className="input-row">
        <label>Vmp</label>
        <Input
          id="mlm_V_mp_ref"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_V_mp_ref}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>V</span>}
        />
      </div>
      {/* TCOEF OF ISC && TCOEF OF VOC */}
      <div className="input-row">
        <label>Tcoef of Isc</label>
        <Input
          id="mlm_alpha_isc"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_alpha_isc}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>mA/°C</span>}
        />
      </div>
      <div className="input-row">
        <label>Tcoef of Voc</label>
        <Input
          id="mlm_beta_voc_spec"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_beta_voc_spec}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>mV/°C</span>}
        />
      </div>
      {/* CELLS IN A SERIES */}
      <div className="input-row">
        <label>Cells in Series</label>
        <Input
          id="mlm_N_series"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_N_series}
          size="small"
          disabled={saving}
          // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>mV/°C</span>}
        />
      </div>
      {/* CELLS IN PARALLEL */}
      <div className="input-row">
        <label>Cells in Parallel</label>
        <Input
          id="mlm_N_parallel"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_N_parallel}
          size="small"
          disabled={saving}
          // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>mV/°C</span>}
        />
      </div>
      <div style={{ height: 25 }}></div>
      {/* RSH && RSHUNT AT G=0 */}
      <div className="input-row">
        <label>Rsh</label>
        <Input
          id="mlm_R_shref"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_R_shref}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>Ω</span>}
        />
      </div>
      <div className="input-row">
        <label>Rshunt at G=0</label>
        <Input
          id="mlm_R_sh0"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_R_sh0}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>Ω</span>}
        />
      </div>
      {/* RS && SHUNT EXP */}
      <div className="input-row">
        <label>Rs</label>
        <Input
          id="mlm_R_s"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_R_s}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>Ω</span>}
        />
      </div>
      <div className="input-row">
        <label>Shunt Exp</label>
        <Input
          id="mlm_R_shexp"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_R_shexp}
          size="small"
          disabled={saving}
          // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>Ω</span>}
        />
      </div>
      <div style={{ height: 25 }}></div>
      {/* ABSORB COEF && TCORF OF PMPP */}
      <div className="input-row">
        <label>Absorb Coef</label>
        <Input
          id="mlm_T_c_fa_alpha"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_T_c_fa_alpha}
          size="small"
          disabled={saving}
          // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>Ω</span>}
        />
      </div>
      <div className="input-row">
        <label>Tcoef of Pmpp</label>
        <Input
          id="muPmpReq"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.muPmpReq}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%/°C</span>}
        />
      </div>
      {/* GAMMA && TCOEF OF GAMME */}
      <div className="input-row">
        <label>Gamma</label>
        <Input
          id="mlm_n_0"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_n_0}
          size="small"
          disabled={saving}
          // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>Ω</span>}
        />
      </div>
      <div className="input-row">
        <label>Tcoef of Gamma</label>
        <Input
          id="mlm_mu_n"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_mu_n}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>1/°C</span>}
        />
      </div>
      <div className="input-row">
        <label>Recombination di^2/MuTau</label>
        <Input
          id="mlm_D2MuTau"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={inputs.mlm_D2MuTau}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>1/V</span>}
        />
      </div>
      <div style={{ height: 25 }}></div>
      {/* IAM */}
      <div className="input-row">
        <label>IAM</label>
      </div>
      <div className="input-table" style={{ float: "left", width: 180 }}>
        <ReactDataSheet
          data={iam_dt_data}
          sheetRenderer={renderSheet}
          valueRenderer={(cell) => cell.value}
          onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
          onCellsChanged={handleChanges}
        />
      </div>
      {/* </Spin> */}
    </div>
  );
};

export { ModuleInputs };
