import React from 'react';

import { Skeleton, Input, Divider, Button, Modal, Popconfirm, message, Radio } from 'antd';
import { DiffOutlined } from '@ant-design/icons';
import { getAgreement, Agreement } from '../../../../components/Auth/agreement';
const { TextArea } = Input;

import config from 'config';

const styles = {
	root: {
		display: 'inline-block',
		borderRadius: 3,
		overflow: 'hidden',
		color: 'rgba(0, 0, 0, 0.85)',
		lineHeight: '30px',
		width: '500px',
		paddingTop: '15px'
	},
	content: {
    left: '30px',
    position: 'relative',
	},
	skeleton: {
		padding: '7%'
	},
	input: {
		width: '128px',
		invoice: {
			width: '175px'
		}
	},
	label: {
		display: 'inline-block',
		width: '75px',
		textAlign: 'right',
		paddingRight: '5px'
	},
	save: {
		left: '373px',
    top: '51px',
		// position: 'absolute',
		zIndex: 100,
	}
}
const agreement = (
	<div style={{height:500, overflowY: 'auto'}}>
		<div dangerouslySetInnerHTML={getAgreement()} />
	</div>
)

function show_agreement() {
  Modal.success({
    title: 'Terms and Conditions',
		content: agreement,
		footer: null,
		width: '80%',
		className: 'sift-modal',
		style: {maxWidth:'1300px'},
		icon: null,
		maskClosable: true,
		okText: 'Close',
		okType: 'ghost',
  });	
}
function password_reset(email) { 
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			"email": email
		})
	};
	fetch(`${config.apiUrl}/api/password_reset/`, requestOptions).then(handleResponse)     

}
function handleResponse(response) {
	return response.text().then(text => {
			let data = text && JSON.parse(text);
			if (!response.ok) {
				message.error('Error requesting reset.', 2.5)
				return Promise.reject(error);
			} else {
				message.success('Please check your email for reset link.', 2.5)			
			}
			return data;
	});
}

const UserSettings = (props) => {
	const { user, local_user } = props;
	let loaded = false;
	if (user) {
		loaded = true;
	}

	if (!loaded) {
		return <div style={styles.skeleton}><Skeleton active/></div>
	} else {
		return (
			<div style={styles.root}>
				<div style={styles.save}>
					<Button 
						type="primary" ghost size="large"
						icon={<DiffOutlined />} size="small"
						onClick={()=>{props.OnSaveChanges()}}
						loading={props.loading}
					>
						Save Changes
					</Button>
				</div>	

				<Divider className="account" orientation="left">General</Divider>
				<div style={styles.content} >
					<div>
						<label>
							You're logged in as: <span>{user.email}</span>
						</label>
					</div>
					<div>
						<span>
							<a  onClick={()=>{show_agreement()}}>Terms and Conditions</a>
							{' - '}
							<Popconfirm title="Reset Password?" onConfirm={() => password_reset(user.email)} okText="Yes" cancelText="No">
								<a >Reset Password</a>
							</Popconfirm>
							{' - '}
							<Popconfirm title="Log Out?" onConfirm={() => props.OnLogOut()} okText="Yes" cancelText="No">
								<a >Log Out</a>
							</Popconfirm>
						</span>
					</div>
				</div>
				<Divider className="account" orientation="left">Map Defaults</Divider>
				<div style={styles.content}>			
						<span style={{ ...styles.label, paddingRight: '4px'}}>
							<label>
								Map Center								
							</label>
						</span>						
						<Radio.Group
							style={{ marginLeft: 'auto' }}
							value={props.local_user.use_random_map_location}
							// disabled={rackType == 2}
							buttonStyle="solid"
							size="small"
							onChange={(event) => {
								props.OnInputUpdate(event.target.id, event.target.value);
							}}
						>
							<Radio.Button id="use_random_map_location" value={true}>
								Random
							</Radio.Button>
							<Radio.Button id="use_random_map_location" value={false}>
								User Defined
							</Radio.Button>
						</Radio.Group>
				</div>
				<div style={styles.content}>
					<span style={styles.label}>
						<label>
							Latitude
						</label>
					</span>
						<Input id='default_lat'  
							onChange={(event)=> {props.OnInputUpdate(event.target.id,event.target.value)}} 
							defaultValue={props.local_user.default_lat} 
							addonAfter={'°'}
							size='small'
							style={styles.input}
							disabled={props.local_user.use_random_map_location}
						/>
				</div>
				<div style={styles.content}>
					<span>
						<label style={styles.label}>
							Longitude
						</label>
						<Input id='default_lng'  
							onChange={(event)=> {props.OnInputUpdate(event.target.id,event.target.value)}} 
							defaultValue={props.local_user.default_lng} 
							addonAfter={'°'}
							size='small'
							style={styles.input}
							disabled={props.local_user.use_random_map_location}							
						/>
					</span>
				</div>   

				<Divider className="account" orientation="left">Invoice</Divider>
				<div style={styles.content}>
					<span style={styles.label}>
						<label>
							Name
						</label>
					</span>
						<Input id='invoice_name'  
							onChange={(event)=> {props.OnInputUpdate(event.target.id,event.target.value)}} 
							value={props.local_user.invoice_name} 
							size='small'
							style={styles.input.invoice}
						/>
				</div>
				<div style={styles.content}>
					<span>
						<label style={styles.label}>
							Company
						</label>
						<Input id='invoice_company'  
							onChange={(event)=> {props.OnInputUpdate(event.target.id,event.target.value)}} 
							value={props.local_user.invoice_company} 
							size='small'
							style={styles.input.invoice}							
						/>
					</span>
				</div> 
				<div style={styles.content}>
					<span>
						<label style={styles.label}>
							Address
						</label>
						<TextArea id='invoice_address'
							rows={2} 
							onChange={(event)=> {props.OnInputUpdate(event.target.id,event.target.value)}} 
							value={props.local_user.invoice_address} 
							size='small'
							style={styles.input.invoice}							
						/>
					</span>
				</div> 		

			</div>
		)
		
	}
}

export { UserSettings }