import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//styles
import { LiveReportLayout } from "./styles/LiveReportLayout";
import { weather_data, losses } from "./components/perfTestData";
// components
import {
  LiveReportHeader,
  LiveReportFooter,
  ReportTitle,
  InfoBoxes,
  LiveReportMap,
  RackingTable,
  TrackerBreakdownTable,
  LayoutTable,
  ModuleTable,
  PerformanceTable,
  LossesTable,
  InverterTable,
  BOMTable,
  IAMGraph,
  IAMTable,
  SoilingAlbedoTable,
  PerformanceSummaryTable,
  WindAndTemp,
  EArrayEGridGraph,
  PerfGraph,
  LossTable,
  getTopoScale,
  InverterEffGraph,
} from "./components";
import { Spinner } from "../../components/Spinner";
import { siftActions } from "..";

const LiveReport = ({ addMapRefs, loadingLayout, captureGraphImage }) => {
  const dispatch = useDispatch();

  const reportData = useSelector((state) => state.sift.ioManager.report.reportData);

  // useEffect(() => {
  //   console.log("report Data", reportData);
  // }, [reportData]);

  useEffect(() => {
    if (!loadingLayout) {
      dispatch(siftActions.updateReportData("numOfPages", document.querySelectorAll(".page-wrap").length));
    }
  }, [loadingLayout]);

  return (
    <>
      {loadingLayout && !reportData ? (
        <LiveReportLayout>
          <section className="page-wrap">
            <section className="page">
              <section className="report-loader">
                <Spinner height={"30px"} width={"30px"} marginTop={"40px"} color={"#002bcb"} />
                <h4>Loading...</h4>
              </section>
            </section>
          </section>
        </LiveReportLayout>
      ) : (
        <LiveReportLayout>
          {Object.values(reportData).length > 0 && (
            <>
              <section className="page-wrap">
                <section className="page">
                  <LiveReportHeader />
                  <section className="report-rundown">
                    <ReportTitle />
                    <InfoBoxes reportData={reportData} />
                    <section className="vicinity-map">
                      <LiveReportMap mapName={"vicinityMap"} showToggles={false} addMapRefs={addMapRefs} />
                    </section>
                    <section className="layout-map">
                      <LiveReportMap mapName={"layoutMap"} showToggles={true} addMapRefs={addMapRefs} />
                    </section>
                  </section>
                  <LiveReportFooter designation={"A001"} pageNum={1} />
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader />
                  </section>
                  <section className="large-layout-map">
                    <LiveReportMap mapName={"largeLayoutMap"} showToggles={true} addMapRefs={addMapRefs} />
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"A002"} pageNum={2} />
                  </section>
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader />
                  </section>
                  <section className="page-content">
                    <section className="title">
                      <h3>Racking</h3>
                    </section>
                    <section className="topLeft">
                      <RackingTable reportData={reportData} />
                      <TrackerBreakdownTable reportData={reportData} />
                    </section>
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"B001"} pageNum={3} />
                  </section>
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader />
                  </section>
                  <section className="module-content">
                    <section className="title">
                      <h3>Module</h3>
                    </section>
                    <section className="module-table">
                      <ModuleTable reportData={reportData} />
                    </section>
                    <section className="iam-graph">
                      <IAMGraph reportData={reportData} captureGraphImage={captureGraphImage} />
                      <section className="iam-table">
                        <IAMTable reportData={reportData} />
                      </section>
                    </section>
                    {/* <section className="iam">
                  <IAMGraph reportData={reportData} />
                  <section className="iam-table">
                    <IAMTable reportData={reportData} />
                  </section>
                </section> */}
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"B002"} pageNum={4} />
                  </section>
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader />
                  </section>
                  <section className="page-content">
                    <section className="title">
                      <h3>Inverter</h3>
                    </section>
                    <section className="topLeft">
                      <InverterTable reportData={reportData} />
                    </section>
                    <section className="topRight">
                      <InverterEffGraph reportData={reportData} captureGraphImage={captureGraphImage} />
                    </section>
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"B003"} pageNum={5} />
                  </section>
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader />
                  </section>
                  <section className="page-content">
                    <section className="title">
                      <h3>Performance</h3>
                    </section>
                    <section className="topLeft-perf-table ">
                      <PerformanceTable reportData={reportData} />
                      <SoilingAlbedoTable reportData={reportData} />
                    </section>
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"B004"} pageNum={6} />
                  </section>
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader />
                  </section>
                  <section className="page-content">
                    <section className="title">
                      <h3>Layout</h3>
                    </section>
                    <section className="topLeft">
                      <LayoutTable reportData={reportData} />
                    </section>
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"B005"} pageNum={7} />
                  </section>
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                  </section>
                  <section className="page-content">
                    <section className="title">
                      <h3>Bill of Materials</h3>
                    </section>
                    <section className="topLeft">
                      {reportData.results?.rack_breakdown && <BOMTable reportData={reportData} />}
                      <p>*Bill of materials is presented here for budgetary and general guidance purposes only.</p>
                    </section>
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"R001"} pageNum={8} />
                  </section>
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                  </section>
                  <section className="perf-results">
                    <h3>Performance Results</h3>
                    <section className="perf-table">
                      <PerformanceSummaryTable reportData={reportData} />
                    </section>
                    <section className="wind-graph">
                      <WindAndTemp reportData={reportData} captureGraphImage={captureGraphImage} />
                    </section>
                    <section className="eArray-graph">
                      <EArrayEGridGraph reportData={weather_data} captureGraphImage={captureGraphImage} />
                    </section>
                    <section className="perf-graph">
                      <PerfGraph reportData={reportData} captureGraphImage={captureGraphImage} />
                    </section>
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"R002"} pageNum={9} />
                  </section>
                </section>
              </section>

              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                  </section>
                  <section className="page-content">
                    <section className="title">
                      <h3>Loss Tables</h3>
                    </section>
                    <section className="left-column">
                      <LossTable data={reportData} losses={losses} />
                    </section>
                    <section className="bifacial-column">{reportData.en_bifacial == 1 && <LossTable data={reportData} bifacial={true} losses={losses} />}</section>
                  </section>
                  <section className="report-footer">
                    <LiveReportFooter designation={"R003"} pageNum={10} />
                  </section>
                </section>
              </section>

              {reportData?.topo_id && (
                <>
                  <section className="page-wrap">
                    <section className="page">
                      <section className="report-header">
                        <ReportPageHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                      </section>
                      <section className="topo-content">
                        <section className="topo-container">
                          <section className="topo-title">
                            <h3>{`Elevation (Ungraded)`}</h3>
                          </section>
                          <secion className="topo-map">
                            <TopoMap mapName={"ele"} topoMode={"ele"} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                          </secion>
                          <section className="topo-scale">
                            <img src={getTopoScale(reportData.topo_id, "ele")} />
                          </section>
                        </section>
                        {reportData.vis_ele.graded_avail && (
                          <section className="topo-container">
                            <section className="topo-title">
                              <h3>{`Elevation (Graded)`}</h3>
                            </section>
                            <secion className="topo-map">
                              <TopoMap mapName={"ele-g"} topoMode={`ele/G/${reportData.vis_ele.graded_percent}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                            </secion>
                            <section className="topo-scale">
                              <img src={getTopoScale(reportData.topo_id, `ele/G/${reportData.vis_ele.graded_percent}`)} />
                            </section>
                          </section>
                        )}
                      </section>
                      <section className="report-footer">
                        <ReportPageFooter page={8} designation={"T001"} />
                      </section>
                    </section>
                  </section>

                  {reportData.vig_g.graded_avail && (
                    <section className="page-wrap">
                      <section className="page">
                        <section className="report-header">
                          <ReportPageHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                        </section>
                        <section className="topo-content">
                          <section className="topo-container">
                            <section className="topo-title">
                              <h3>{`Cut and Fill`}</h3>
                            </section>
                            <secion className="topo-map">
                              <TopoMap mapName={"CF"} topoMode={`CF/${reportData.vig_g.ungraded_percent}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                            </secion>
                            <section className="topo-scale">
                              <img src={getTopoScale(reportData.topo_id, `CF/${reportData.vig_g.ungraded_percent}`)} />
                            </section>
                          </section>
                        </section>
                        <section className="report-footer">
                          <ReportPageFooter page={9} designation={"T002"} />
                        </section>
                      </section>
                    </section>
                  )}

                  {reportData && (reportData.vis_max.ungraded_avail || reportData.vis_max.graded_avail) && (
                    <section className="page-wrap">
                      <section className="page">
                        <section className="report-header">
                          <ReportPageHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                        </section>
                        <section className="topo-content">
                          <section className="topo-container">
                            <section className="topo-title">
                              <h3>{`Max Slope (Ungraded)`}</h3>
                            </section>
                            <secion className="topo-map">
                              <TopoMap mapName={"U"} topoMode={`U/${reportData.vis_max.ungraded_percent}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                            </secion>
                            <section className="topo-scale">
                              <img src={getTopoScale(reportData.topo_id, `U/${reportData.vis_max.ungraded_percent}`)} />
                            </section>
                          </section>
                          {reportData.vis_max.graded_avail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`Max Slope (Graded)`}</h3>
                              </section>
                              <secion className="topo-map">
                                <TopoMap mapName={"U-G"} topoMode={`U/G/${reportData.vis_max.graded_percent}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                              </secion>
                              <section className="topo-scale">
                                <img src={getTopoScale(reportData.topo_id, `U/G/${reportData.vis_max.graded_percent}`)} />
                              </section>
                            </section>
                          )}
                        </section>
                        <section className="report-footer">
                          <ReportPageFooter page={10} designation={"T003"} />
                        </section>
                      </section>
                    </section>
                  )}

                  {reportData && (reportData.vis_ns.ungraded_avail || reportData.vis_ns.graded_avail) && (
                    <section className="page-wrap">
                      <section className="page">
                        <section className="report-header">
                          <ReportPageHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                        </section>
                        <section className="topo-content">
                          {reportData.vis_ns.ungraded_avail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`NS Grade (Ungraded)`}</h3>
                              </section>
                              <secion className="topo-map">
                                <TopoMap mapName={"NS"} topoMode={`NS/${reportData.vis_ns.ungraded_percent}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                              </secion>
                              <section className="topo-scale">
                                <img src={getTopoScale(reportData.topo_id, `NS/${reportData.vis_ns.ungraded_percent}`)} />
                              </section>
                            </section>
                          )}

                          {reportData.vis_ns.graded_avail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`NS Grade (Graded)`}</h3>
                              </section>
                              <secion className="topo-map">
                                <TopoMap mapName={"NS-G"} topoMode={`NS/G/${reportData.vis_ns.graded_percent}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                              </secion>
                              <section className="topo-scale">
                                <img src={getTopoScale(reportData.topo_id, `NS/G/${reportData.vis_ns.graded_percent}`)} />
                              </section>
                            </section>
                          )}
                        </section>
                        <section className="report-footer">
                          <ReportPageFooter page={11} designation={"T004"} />
                        </section>
                      </section>
                    </section>
                  )}
                  {reportData && (reportData.vis_ew.ungraded_avail || reportData.vis_ew.graded_avail) && (
                    <section className="page-wrap">
                      <section className="page">
                        <section className="report-header">
                          <ReportPageHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                        </section>
                        <section className="topo-content">
                          {reportData.vis_ew.ungraded_avail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`EW Grade (Ungraded)`}</h3>
                              </section>
                              <secion className="topo-map">
                                <TopoMap mapName={"EW"} topoMode={`EW/${reportData.vis_ew.ungraded_percent}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                              </secion>
                              <section className="topo-scale">
                                <img src={getTopoScale(reportData.topo_id, `EW/${reportData.vis_ew.ungraded_percent}`)} />
                              </section>
                            </section>
                          )}

                          {reportData.vis_ew.graded_avail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`EW Grade (Graded)`}</h3>
                              </section>
                              <secion className="topo-map">
                                <TopoMap mapName={"EW-G"} topoMode={`EW/G/${reportData.vis_ew.graded_percent}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                              </secion>
                              <section className="topo-scale">
                                <img src={getTopoScale(reportData.topo_id, `EW/G/${reportData.vis_ew.graded_percent}`)} />
                              </section>
                            </section>
                          )}
                        </section>
                        <section className="report-footer">
                          <ReportPageFooter page={12} designation={"T005"} />
                        </section>
                      </section>
                    </section>
                  )}
                </>
              )}

              {/* {reportData?.topo_id && (
                <>
                  <section className="page-wrap">
                    <section className="page">
                      <section className="report-header">
                        <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                      </section>
                      <section className="topo-content">
                        <section className="topo-container">
                          <section className="topo-title">
                            <h3>{`Elevation (Ungraded)`}</h3>
                          </section>
                          <secion className="topo-map">
                            <TopoMap mapName={"ele"} topoMode={"ele"} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                          </secion>
                          <section className="topo-scale">
                            <img src={getTopoScale(reportData.topoData.topo_id, "ele")} />
                          </section>
                        </section>
                        {reportData.topoData.layers_generated.ele.gavail && (
                          <section className="topo-container">
                            <section className="topo-title">
                              <h3>{`Elevation (Graded)`}</h3>
                            </section>
                            <secion className="topo-map">
                              <TopoMap mapName={"ele-g"} topoMode={"ele/G"} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                            </secion>
                            <section className="topo-scale">
                              <img src={getTopoScale(reportData.topoData.topo_id, "ele/G")} />
                            </section>
                          </section>
                        )}
                      </section>
                      <section className="report-footer">
                        <LiveReportFooter designation={"T001"} />
                      </section>
                    </section>
                  </section>

                  {reportData.topoData.layers_generated.CF.avail && (
                    <section className="page-wrap">
                      <section className="page">
                        <section className="report-header">
                          <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                        </section>
                        <section className="topo-content">
                          <section className="topo-container">
                            <section className="topo-title">
                              <h3>{`Cut and Fill`}</h3>
                            </section>
                            <secion className="topo-map">
                              <TopoMap mapName={"CF"} topoMode={`CF/${reportData.topoData.layers_generated.CF.limit}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                            </secion>
                            <section className="topo-scale">
                              <img src={getTopoScale(reportData.topoData.topo_id, `CF`, reportData.topoData.layers_generated.CF.limit)} />
                            </section>
                          </section>
                        </section>
                        <section className="report-footer">
                          <LiveReportFooter designation={"T002"} />
                        </section>
                      </section>
                    </section>
                  )}

                  {reportData && (reportData.topoData.layers_generated.U.avail || reportData.topoData.layers_generated.U.gavail) && (
                    <section className="page-wrap">
                      <section className="page">
                        <section className="report-header">
                          <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                        </section>
                        <section className="topo-content">
                          <section className="topo-container">
                            <section className="topo-title">
                              <h3>{`Max Slope (Ungraded)`}</h3>
                            </section>
                            <secion className="topo-map">
                              <TopoMap mapName={"U"} topoMode={`U/${reportData.topoData.layers_generated.U.limit}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                            </secion>
                            <section className="topo-scale">
                              <img src={getTopoScale(reportData.topoData.topo_id, `U`, reportData.topoData.layers_generated.U.limit)} />
                            </section>
                          </section>
                          {reportData.topoData.layers_generated.U.gavail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`Max Slope (Graded)`}</h3>
                              </section>
                              <secion className="topo-map">
                                <TopoMap mapName={"U-G"} topoMode={`U/G/${reportData.topoData.layers_generated.U.limit}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                              </secion>
                              <section className="topo-scale">
                                <img src={getTopoScale(reportData.topoData.topo_id, `U/G`, reportData.topoData.layers_generated.U.limit)} />
                              </section>
                            </section>
                          )}
                        </section>
                        <section className="report-footer">
                          <LiveReportFooter designation={"T003"} />
                        </section>
                      </section>
                    </section>
                  )}

                  {reportData && (reportData.topoData.layers_generated.NS.avail || reportData.topoData.layers_generated.NS.gavail) && (
                    <section className="page-wrap">
                      <section className="page">
                        <section className="report-header">
                          <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                        </section>
                        <section className="topo-content">
                          {reportData.topoData.layers_generated.NS.avail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`NS Grade (Ungraded)`}</h3>
                              </section>
                              <secion className="topo-map">
                                <TopoMap mapName={"NS"} topoMode={`NS/${reportData.topoData.layers_generated.NS.limit}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                              </secion>
                              <section className="topo-scale">
                                <img src={getTopoScale(reportData.topoData.topo_id, `NS`, reportData.topoData.layers_generated.NS.limit)} />
                              </section>
                            </section>
                          )}

                          {reportData.topoData.layers_generated.NS.gavail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`NS Grade (Graded)`}</h3>
                              </section>
                              <secion className="topo-map">
                                <TopoMap mapName={"NS-G"} topoMode={`NS/G/${reportData.topoData.layers_generated.NS.limit}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                              </secion>
                              <section className="topo-scale">
                                <img src={getTopoScale(reportData.topoData.topo_id, `NS/G`, reportData.topoData.layers_generated.NS.limit)} />
                              </section>
                            </section>
                          )}
                        </section>
                        <section className="report-footer">
                          <LiveReportFooter designation={"T004"} />
                        </section>
                      </section>
                    </section>
                  )}
                  {reportData && (reportData.topoData.layers_generated.EW.avail || reportData.topoData.layers_generated.EW.gavail) && (
                    <section className="page-wrap">
                      <section className="page">
                        <section className="report-header">
                          <LiveReportHeader location={reportData.location} user={reportData.userName} date={reportData.date} />
                        </section>
                        <section className="topo-content">
                          {reportData.topoData.layers_generated.EW.avail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`EW Grade (Ungraded)`}</h3>
                              </section>
                              <secion className="topo-map">
                                <TopoMap mapName={"EW"} topoMode={`EW/${reportData.topoData.layers_generated.EW.limit}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                              </secion>
                              <section className="topo-scale">
                                <img src={getTopoScale(reportData.topoData.topo_id, `EW`, reportData.topoData.layers_generated.EW.limit)} />
                              </section>
                            </section>
                          )}

                          {reportData.topoData.layers_generated.EW.gavail && (
                            <section className="topo-container">
                              <section className="topo-title">
                                <h3>{`EW Grade (Graded)`}</h3>
                              </section>
                              <secion className="topo-map">
                                <TopoMap mapName={"EW-G"} topoMode={`EW/G/${reportData.topoData.layers_generated.EW.limit}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                              </secion>
                              <section className="topo-scale">
                                <img src={getTopoScale(reportData.topoData.topo_id, `EW/G`, reportData.topoData.layers_generated.EW.limit)} />
                              </section>
                            </section>
                          )}
                        </section>
                        <section className="report-footer">
                          <LiveReportFooter designation={"T005"} />
                        </section>
                      </section>
                    </section>
                  )}
                </>
              )} */}
            </>
          )}
        </LiveReportLayout>
      )}
    </>
  );
};

export { LiveReport };
