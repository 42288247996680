import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { alertActions, siftActions, getSIFTInputs } from "../Redux";

import { DownSquareOutlined, UpSquareOutlined, MinusSquareOutlined, FileExcelOutlined, CopyOutlined } from "@ant-design/icons";

import { ResultReadOnlyTable } from "../InputPanel/StyledComponents";
import { resultColumns } from "./resultColumns";
import { Tooltip } from "antd";

//tutorial stuff
import { TutorialTip } from "../../components/TutorialTip";

const ResultTable = (props) => {
  const [sortMode, setSortMode] = useState({ col: "gcr", sort: "ascend" });

  const [settingColumns, setSettingColumns] = useState(false);
  const [whichColumn, setWhichColumn] = useState(undefined);

  // const [selectedId, setSelectedId] = useState(undefined);

  const sift_inputs = useSelector((state) => state.sift.ioManager.inputs);
  const current_plan = useSelector((state) => state.account.current_plan);
  const outputs = useSelector((state) => state.sift.ioManager.outputs);
  const selectedResult = outputs.selectedResult;
  const runState = outputs.runState;

  const loading_layout = useSelector((state) => state.sift.ioManager.uiState.loading_layout);
  const loading_files = useSelector((state) => state.sift.ioManager.uiState.loading_files);
  const map_loading = useSelector((state) => state.sift.ioManager.inputs.map.loading_map);
  // const csvData = outputs.csvData
  // const fileName = outputs.fileName

  //Tutorial Controls
  const tutorial = useSelector((state) => state.tutorial);

  const dispatch = useDispatch();

  // write a custom sorter for this results to be based on sortMode
  const results = onSortResults(Object.values(outputs.results));
  const code = outputs.code;
  const errors = outputs.errors;
  const tableColumns = resultColumns(code);

  // TEST DATA
  // const code = 152
  // const results = onSortResults([
  //   {id:'1234',gcr:0.35,pitch:1.34,dcac:1.45,YIELD:1234.123,lcoe:0.45,irr:0.93},
  //   {id:'456',gcr:0.36,pitch:1.45,dcac:1.35,YIELD:4000.123,lcoe:0.434,irr:0.922}])

  function onSetMiniSort(sorter, event) {
    // this button is nested in a column header that is clickable too, so stop event from bubbling to that header
    event.stopPropagation();

    if (sorter == sortMode.col && sortMode.sort == "ascend") {
      // flip
      setSortMode({ col: sorter, sort: "descend" });
    } else {
      // defaults to ascend
      setSortMode({ col: sorter, sort: "ascend" });
    }
  }
  function onSelectMiniResult(result) {
    if (selectedResult && selectedResult.id == result.id) {
      return;
      // console.log('click')
      // setSelectedId(result.id)
      // dispatch(siftActions.selectResult(result))
    }
    props.onSelectResult(result);
  }
  function onDoubleClickResult(result) {
    props.onOpenDetail(result);
    dispatch(siftActions.getLayoutData(result));
  }
  function onSortResults(results) {
    if (sortMode.sort == "ascend") {
      // ascending
      results.sort((a, b) => a[sortMode.col] - b[sortMode.col]);
    } else {
      // descending
      results.sort((a, b) => b[sortMode.col] - a[sortMode.col]);
    }
    return results;
  }

  return (
    <div className="result-holder" style={{ border: tutorial.visibleTips.click_result ? "3px solid #002bcb" : "3px solid transparent" }}>
      <ResultReadOnlyTable>
        <div className="read-only-table big">
          <TutorialTip
            elementKey="result-table-tip"
            visible={tutorial.visibleTips.click_result}
            highlight={false}
            customElementHeight={100}
            customElementWidth={370}
            placement="top"
            content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.click_result}
          >
            <table>
              <thead id="result-table-tip">
                <tr key="column-headers">
                  {tableColumns.map((col, index) => {
                    if (!col.visible) return;

                    return (
                      <th className={`column-name ${sortMode.col == col.key ? "active" : ""}`} key={index} onClick={(e) => onSetMiniSort(col.key, e)}>
                        <span>{col.title}</span>
                        <span className="mini-sort">{sortMode.col == col.key ? sortMode.sort == "ascend" ? <UpSquareOutlined /> : <DownSquareOutlined /> : <span></span>}</span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {results.map((res) => {
                  return (
                    <Tooltip placement="left" title="Double click to Plot" mouseEnterDelay={0.2} key={res.id}>
                      <tr
                        key={res.id}
                        className={`mini-data ${selectedResult && selectedResult.id == res.id && "selected"}`}
                        onClick={() => onSelectMiniResult(res)}
                        onDoubleClick={() => onDoubleClickResult(res)}
                      >
                        {tableColumns.map((col, index) => {
                          if (col.visible) {
                            return <td key={`${res.id}-${col.key}`}>{col.render ? col.render(res[col.key]) : res[col.key]}</td>;
                          }
                        })}
                      </tr>
                    </Tooltip>
                  );
                })}
              </tbody>
            </table>
          </TutorialTip>
        </div>
      </ResultReadOnlyTable>
    </div>
  );
};

export { ResultTable };
