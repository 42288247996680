import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Result, Typography, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { siftActions } from '../Redux';

const { Paragraph, Text } = Typography;

const ErrorPopup = () => {
  const error_visible = useSelector((state) => state.sift.ioManager.uiState.error_visible);
  const error_messages = useSelector((state) => state.sift.ioManager.uiState.error_messages);

  const dispatch = useDispatch();

  function onClose() {
    dispatch(siftActions.closeErrorSIFT());
  }

  return (
    <Modal
      title="SIFT Error Manager"
      visible={error_visible}
      onCancel={onClose}
      onOk={onClose}
      width="50%"
      className="error-modal"
      footer={[
        <Button key="submit" type="primary" onClick={onClose}>
          Ok
        </Button>,
      ]}
    >
      <Result
        className="error-results"
        status="error"
        title={<div style={{ marginBottom: '-24px' }}></div>}
        subTitle={
          <div style={{ textAlign: 'left', paddingLeft: '10%', paddingRight: '10%', color: '#333' }}>
            Please review the errors below and modify your inputs as needed. Report any unresolved issues to the Sunfig team with the bug report button at the bottom of the far left toolbar of the
            main SIFT window.
          </div>
        }
        icon={<div style={{ marginBottom: '-24px' }}></div>}
      >
        <div className="desc">
          <Paragraph>
            <Text
              strong
              style={{
                fontSize: 16,
              }}
            >
              SIFT detected the following errors and warnings:
            </Text>
          </Paragraph>

          {/* map errors_messages below */}
          {error_messages.map((message, index) => {
            return (
              <Paragraph key={index}>
                <CloseCircleOutlined style={{ color: 'red' }} /> {message}
              </Paragraph>
            );
          })}
        </div>
      </Result>
    </Modal>
  );
};

export { ErrorPopup };
