import React, { Fragment, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { siftActions, isAuthenticated } from '../Redux'

// TODO: replace the antd stuff
import { Badge, Tooltip, Modal } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import './style.css';

const Alert = () => {
	const dispatch = useDispatch();

	// selectors
	const isAuthed = useSelector((state) => isAuthenticated(state));
	const system_alerts = useSelector((state) => state.sift.system_alerts);

	const [hasAlerted, set_hasAlerted] = useState(false);

	useEffect(() => {
		// 
		if (system_alerts && system_alerts.isAuthed && isAuthed) {

			if (system_alerts.poll_message.length > 0 && !hasAlerted) {
				set_hasAlerted(true);
				click();
			}
			// console.log('got updated system_alerts', system_alerts.poll_message)
			const timeout = setTimeout(()=>{
				doPoll();
			}, 180000)
			// 
			return () => clearTimeout(timeout);
		}
	}, [system_alerts])

	function doPoll () {
		// console.log('sending poll request')
		dispatch(siftActions.pollForAlerts());
	}

	function click() {
		let msgs = (
			<>
				<p>
					There are no notifications to display.
				</p>
				<ul className='sys-alert'>
					<li>
						If a system is down, it will be listed here.
					</li>
				</ul>
			</>
		)
		if (system_alerts.poll_message.length > 0) {
			msgs = (
				<>
					<ul className='sys-alert'>
					{Object.values(system_alerts.poll_message).map(
						(msg, index) => {
							return (<li key={index}>{msg}</li>)
						}
					)}
					</ul>
				</>
			)
		}

		Modal.info({
			title: 'SIFT Notifications',
			content: msgs,
			okText: 'Close',
			onOk() {},
			maskClosable: true,
		});
	}

	return (
		<Tooltip placement="right" title="SIFT Notifications">
			<button onClick={click}>
				<Badge count={system_alerts.poll_message.length} size='small'>
					<BellOutlined />
				</Badge>
			</button>
		</Tooltip>
	)
}

export { Alert }