// 
import "./leaflet.wmts";


// BELOW SUPPORTS REACT-LEAFLET V2
import {GridLayer, withLeaflet} from 'react-leaflet';
class WMTSLayer extends GridLayer {
  
    createLeafletElement(props) {
      return new L.tileLayer.wmts(props);
    }
}
  
export default withLeaflet(WMTSLayer);


// BELOW SUPPORTS REACT-LEAFLET V3
// import { createLayerComponent } from '@react-leaflet/core';
// const createLeafletElement = (props) => {
//     const instance = new L.tileLayer.wmts(props);
//     return { instance };
// }

// export default createLayerComponent(createLeafletElement);