import React, { useState, useEffect, Fragment } from "react";

import { isAuthenticated } from "../";

// styles
import { NotificationWrapper } from "./styles";

// assets
import { infoIcon } from "../../assets/images";

// redux
import { useSelector, useDispatch } from "react-redux";
import { accountActions } from "../Redux";

const Notification = ({ user }) => {
  const isAuthed = useSelector((state) => isAuthenticated(state));
  const current_plan = useSelector((state) => state.account.current_plan);
  const dispatch = useDispatch();

  const [animateIn, setAnimateIn] = useState(false);

  useEffect(() => {
    let timeout = null;
    if (isAuthed && current_plan === 1) {
      timeout = setTimeout(() => {
        setAnimateIn(true);
      }, 1000 * 15);
    } else {
      setAnimateIn(false);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isAuthed, current_plan]);

  const handleToggleFunnel = (e, val = true) => {
    dispatch(accountActions.toggleFunnelVis(val));
  };

  return (
    <NotificationWrapper animateIn={animateIn} onClick={() => handleToggleFunnel()}>
      <Fragment>
        <div className="info-icon">
          <img src={infoIcon} />
        </div>
        <div className="notification-msg">
          <div className="notification-text">
            <p>Check out our paid plans to unlock more SIFT features! </p>
            <p style={{ color: "#002bcb" }}>Click here to see our pricing!</p>
          </div>
        </div>
      </Fragment>
    </NotificationWrapper>
  );
};

export { Notification };
