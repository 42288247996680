// @flow
/* eslint-disable no-console, react/no-multi-comp */
import React from 'react';
import { stripe_logo_1, stripe_logo_2, stripe_logo_3 } from '../_images'

import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,

  StripeProvider,
  Elements,
  injectStripe,
} from 'react-stripe-elements';

import { Button, Input, Skeleton } from 'antd';

const handleBlur = () => {
};
const handleChange = (change) => {
};
const handleFocus = () => {
};
const handleReady = () => {
};

const CARD_ELEMENT_OPTIONS = {
  style: {
    // border: '1px solid #d9d9d9',
    // borderRadius: '4px',
    // padding: '5px',

    base: {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
      fontVariant: 'tabular-nums',
      listStyle: 'none',
      // font-feature-settings: 'tnum';
      position: 'relative',
      display: 'inline-block',
      width: '100%',
      height: '32px',
      padding: '4px 11px',
      color: 'rgba(0, 0, 0, 0.65)',
      fontSize: '13px',
      lineHeight: '1.5',
      backgroundColor: '#fff',
      // background-image: none;

      transition: 'all 0.3s',
      // fontSize: '18px',
      // color: '#000000',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: 'rgba(0, 0, 0, 0.65)',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};
const CARD_STYLE ={
  padding: '10px',
  // maxWidth: '50%'
}

class _CardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: undefined,
      name_error: false,
      payment_error: false,
      complete: false,
      error: 'Empty Credit Card Form',
    }
  }
  componentWillReceiveProps(props){
    if (!this.props.visible) {
      this.setState({name: undefined, name_error: false})
    }
  }
  handleNameChange = (e) => {
    this.setState({name: e.target.value, name_error:false})
  }
  handleSubmit(ev){
    ev.preventDefault();
    if (!this.state.name) {
      this.setState({name_error:true})
      return;
    }
    if (!this.state.complete) {
      this.setState({payment_error:true})
      return;
    }

    if (this.props.stripe) {
      this.props.stripe
        .createToken()
        .then((payload) => this.props.onReturn(payload,this.state.name));
    } else {
      // console.log('Form submitted before Stripe.js loaded.');
    }
  };
  handleChange = (change) => {
    // console.log('[change]', change);
    this.setState({
      complete: change.complete,
      error: change.error ? change.error.message : this.state.error, 
      payment_error: false,
    })
  };


  render() {
    return (
      <form onSubmit={(ev) => {this.handleSubmit(ev)}}>
      {this.props.stripe ?
        <div style={{height:'175px'}}>
        <label>Enter your credit card information</label>
        {this.props.stripe ? (
          <div style={CARD_STYLE}>
            <div style={{paddingBottom:'5px'}}>
              <Input placeholder="Enter Name on Card" value={this.state.name} onChange={this.handleNameChange}></Input>
              {this.state.name_error && <span style={{color:'red'}}>   Please Enter a name for the Card</span>}
            </div>
            
            <div style={{
              border: '1px solid #d9d9d9',
              borderRadius: '4px',
              padding: '5px',
            }}>
              {this.props.visible &&
                <CardElement
                  onBlur={handleBlur}
                  onChange={this.handleChange}
                  onFocus={handleFocus}
                  onReady={handleReady}
                  {...CARD_ELEMENT_OPTIONS}
                />
              }
            </div>
            <div style={{paddingTop:'5px'}}>
              <img src={stripe_logo_1}/>
            </div>
              {this.state.payment_error && <span style={{color:'red'}}>{this.state.error}</span>}
          </div>
        ) : (
          <div className="StripeElement loading" />
        )}
        <div style={{float:'right'}}>
        <span style={{padding:'5px'}}><Button type='primary' ghost onClick={this.props.onCancel}>Cancel</Button> </span>
        <span style={{padding:'5px'}}><Button type='primary' disabled={!this.props.stripe} onClick={(e) => {this.handleSubmit(e)}}>Add</Button></span>
        </div> 
        </div> :
        <Skeleton active/>}
      </form>
    );
  }
}
const CardForm = injectStripe(_CardForm);

const Checkout = (props) => {
  return (
    <div className="Checkout">
      <Elements>
        <CardForm {...props}/>
      </Elements>
    </div>
  );
};

export default class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      stripe: null
    };
  }

  componentDidMount() {
    // componentDidMount only runs in a browser environment.
    // In addition to loading asynchronously, this code is safe to server-side render.

    // You can inject a script tag manually like this,
    // or you can use the 'async' attribute on the Stripe.js v3 <script> tag.
    const stripeJs = document.createElement('script');
    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.onload = () => {
      // The setTimeout lets us pretend that Stripe.js took a long time to load
      // Take it out of your production code!
      let key = 'pk_test_eKFFlpsuJTKBlKcqhWpkJHei';
      // key = 'pk_live_jhGKlwSBFPrsd8qNIZe9AefD';
      if (process.env.NODE_ENV === 'production') {
        key = 'pk_live_jhGKlwSBFPrsd8qNIZe9AefD';
      }
      
      setTimeout(() => {
        this.setState({
          stripe: window.Stripe(key),
        });
      }, 500);
    };
    document.body && document.body.appendChild(stripeJs);
  }

  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Checkout {...this.props}/>
      </StripeProvider>
    );
  }
}