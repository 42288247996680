import React, { useState, useEffect, useRef, Fragment } from "react";

// style
import { TutorialNavWrap, TutorialFunnelWrap } from "./styles";

// assets
import { close, books, hexagon_1 } from "../../assets/images";

// redux
import { useSelector, useDispatch } from "react-redux";
import { isAuthenticated, siftActions, tutorialActions, accountActions } from "../../components/Redux";
import { sift } from "../Redux/_reducers/sift.reducer";

const TutorialNav = () => {
  //Tutorial Controls
  const tutorial = useSelector((state) => state.tutorial);
  const features = useSelector((state) => state.sift.ioManager.inputs.map.features);
  const new_project_loading = useSelector((state) => state.sift.projectManager.new_project_loading);
  const screenWidth = useSelector((state) => state.sift.ioManager.uiState.screenWidth);

  const [moveNavToBottom, setmoveNavToBottom] = useState(false);

  const dispatch = useDispatch();

  // using this to open and close tabs and accordians depending on what step the tutorial is at and which path the tutorial is on (example or tour).
  useEffect(() => {
    if (tutorial.tutorialPath == 0) {
      switch (tutorial.currentIndex) {
        case 1:
          dispatch(siftActions.updateTab("config"));
          break;
        case 2:
          dispatch(siftActions.runTutorial("tour_step_2"));
          break;
        case 3:
          if (screenWidth < 768) setmoveNavToBottom(false);
          break;
        case 4:
          dispatch(siftActions.updateTab("module"));
          if (screenWidth < 768 && !moveNavToBottom) setmoveNavToBottom(true);
          break;
        case 5:
          dispatch(siftActions.updateTab("inverter"));
          if (screenWidth < 768 && !moveNavToBottom) setmoveNavToBottom(true);
          break;
        case 6:
          dispatch(siftActions.updateTab("racking"));
          if (screenWidth < 768 && !moveNavToBottom) setmoveNavToBottom(true);
          break;
        case 7:
          dispatch(siftActions.updateTab("weather"));
          if (screenWidth < 768 && !moveNavToBottom) setmoveNavToBottom(true);
          break;
        case 8:
          dispatch(siftActions.updateTab("performance"));
          if (screenWidth < 768 && !moveNavToBottom) setmoveNavToBottom(true);
          break;
        case 9:
          dispatch(siftActions.updateTab("finance"));
          if (screenWidth < 768 && !moveNavToBottom) setmoveNavToBottom(true);
          break;
        case 10:
          dispatch(siftActions.updateTab("layout"));
          if (screenWidth < 768 && !moveNavToBottom) setmoveNavToBottom(true);
          break;
        case 11:
          dispatch(siftActions.updateTab("topo"));
          if (screenWidth < 768 && !moveNavToBottom) setmoveNavToBottom(true);
          break;
        default:
          break;
      }
    }
  }, [tutorial.currentIndex]);

  useEffect(() => {
    if (new_project_loading === true) {
      dispatch(tutorialActions.resetTutorial());
    }
  }, [new_project_loading]);

  const openCalendly = () => {
    window.open("https://calendly.com/siftdemo/demo", "_blank");
  };

  return (
    <Fragment>
      {tutorial.showTutorialFunnel ? (
        <TutorialFunnelWrap>
          <div className="close-button">
            <a onClick={() => dispatch(tutorialActions.endTutorial())}>
              <img src={close} />
            </a>
          </div>
          <div className="tutorial-start">
            <div className="icon">
              <img src={books} />
            </div>
            <div className="tutorial-text">
              <h3>What's Next?</h3>
              <p>Use SIFT to guide your critical project decisions early in the development cycle. Find the most valuable configuration in minutes.</p>
            </div>
          </div>
          <div className="tutorial-start-buttons">
            <button onClick={() => dispatch(accountActions.toggleFunnelVis(true))}>Start a free trial of SIFT Pro</button>
            <button onClick={() => openCalendly()}>Schedule a Demo</button>
          </div>
        </TutorialFunnelWrap>
      ) : (
        <TutorialNavWrap moveNavToBottom={moveNavToBottom}>
          <div className="tutorial-content">
            <div className="close-button">
              <a onClick={() => dispatch(tutorialActions.endTutorial())}>
                <img src={close} />
              </a>
            </div>
            <div className="tutorial-main-text">
              <div className="tutorial-nav-header">
                <img src={books} />
                {tutorial.tutorialPath == 0 ? <h3>SIFT Tour</h3> : <h3>SIFT Example Project</h3>}
              </div>
              <p>{tutorial.content && tutorial.currentIndex === 0 && Object.values(tutorial.content)[tutorial.currentIndex].main_text}</p>
            </div>
            {tutorial.currentIndex >= Object.values(tutorial.content).length - 1 && tutorial.tutorialPath == 1 ? (
              <div className="tutorial-footer">
                <p>{`${tutorial.currentIndex + 1} / ${Object.values(tutorial.content).length}`}</p>
                <div className="tutorial-buttons">
                  <button style={{ opacity: tutorial.currentIndex > 0 ? "1" : "0.4" }} onClick={() => dispatch(tutorialActions.navigateTutorial("prev"))}>
                    Back
                  </button>
                  <button onClick={() => dispatch(tutorialActions.showTutorialFunnel())}>Next Steps</button>
                </div>
              </div>
            ) : (
              <div className="tutorial-footer">
                <p>{`${tutorial.currentIndex + 1} / ${Object.values(tutorial.content).length}`}</p>
                <div className="tutorial-buttons">
                  <button style={{ opacity: tutorial.currentIndex > 0 ? "1" : "0.4" }} onClick={() => dispatch(tutorialActions.navigateTutorial("prev"))}>
                    Back
                  </button>
                  <button style={{ opacity: tutorial.currentIndex >= Object.values(tutorial.content).length - 1 ? "0.4" : "1" }} onClick={() => dispatch(tutorialActions.navigateTutorial("next"))}>
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </TutorialNavWrap>
      )}
    </Fragment>
  );
};

export { TutorialNav };
