import React, { Component } from "react";
import { withRouter, Switch, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// components
import { PrivateRoute } from "./layouts";
import { AuthModal } from "./components/Auth";
import { Funnel } from "./components/Funnel";

import { Home, EmailVerification, PasswordReset, SIFT } from "./routes/";

// redux
import { authActions, isAuthenticated, isAccessTokenExpired } from "./components/Redux";

class Routes extends Component {
  constructor(props) {
    super(props);

    if (this.props.isAuthenticated) {
      // user is authenticated by means of having a non-expired refresh token
      let user = JSON.parse(localStorage.getItem("user"));
      this.props.dispatch(authActions.continueLogin(user));
    }
  }

  render() {
    const currentKey = this.props.location.pathname.split("/")[1] || "/";
    const childProps = { ...this.props };

    return (
      <div>
        {/* specific route loading */}
        <Switch>
          <Route path="/siftlauncher/:command" component={SIFT} props={childProps} />
          <Route path="/sift" component={SIFT} props={childProps} />
          {/* <Route path="/docs" component={Doc} props={childProps} /> */}

          <Route path="/emailverify/:code" component={EmailVerification} props={childProps} />
          <Route path="/pwreset/:code" component={PasswordReset} props={childProps} />

          {/* <PrivateRoute exact path="/account" component={Account}
            isAuth={this.props.isAuthenticated} /> */}

          <Route path="/" component={Home} props={childProps} />
          <Route path="/news" component={SIFT} props={childProps} />
          {/* if path not above, redirect to home */}
          <Redirect to="/" />
        </Switch>

        {/* High level components used globally */}
        <Funnel />
        <AuthModal />
      </div>
    );
  }
}

Routes.propTypes = {
  // comes from parent <Router>
  location: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: isAuthenticated(state),
  };
}

const connectedRoutes = withRouter(connect(mapStateToProps)(Routes));
export { connectedRoutes as Routes };
