import React from "react";
import { connect } from "react-redux";
import { LoginWrapper } from "./authstyles";
import { Spinner } from "../Spinner";
import { AgreementUpdate } from "./";

import { accountActions, authActions, isAuthenticated } from "../../components/Redux";
import { triAlert } from "../../assets/images";

class LoginNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      reset_pw: false,
      remember: false,
      continueLogin: false,
      refreshToken: false,
      pw_reset_msg: "",
      email: "",
      password: "",
      tos: 0,
      visible: false,
    };

    this.formRef = React.createRef();
    this.state.remember = localStorage.getItem("remember");
  }

  toggleAgreement = () => {
    this.setState({ visible: !this.state.visible });
  };

  handleSubmit = (values) => {
    values.preventDefault();

    // if (this.state.tos == 0 || this.state.tos == undefined || this.state.tos == null) {
    //   this.setState({ visible: true });
    //   return;
    // }

    this.setState({ submitted: true, pw_reset_msg: undefined });
    if (this.state.reset_pw) {
      this.props.dispatch(authActions.pwReset(this.state.email));
    } else {
      if (this.state.email && this.state.password) {
        if (this.state.remember) {
          localStorage.setItem("remember", 1);
        } else {
          localStorage.setItem("remember", 0);
        }
        this.props.dispatch(authActions.login(this.state.email, this.state.password, this.props.forward));
      }
    }
  };

  handleForgotPassword = () => {
    this.setState({ reset_pw: true, error: undefined });
  };
  handleCancel = () => {
    this.setState({ reset_pw: false, error: undefined });
  };
  render() {
    return (
      <LoginWrapper>
        {!this.state.reset_pw ? (
          <form onSubmit={this.handleSubmit} ref={this.formRef}>
            <div className="form-input">
              <label htmlFor="email">Email</label>
              <input name="email" type="text" value={this.state.email} onChange={(e) => this.setState({ ...this.state, email: e.target.value })} required />
            </div>
            <div className="form-input">
              <div className="password-box">
                <label htmlFor="password">
                  <span>Password</span>
                  <span>
                    <a onClick={() => this.handleForgotPassword()}>Forgot your password?</a>
                  </span>
                </label>
              </div>
              <input name="Password" type="password" value={this.state.password} onChange={(e) => this.setState({ ...this.state, password: e.target.value })} required />
            </div>
            {this.props.login_error && (
              <span className="form-alert">
                <img src={triAlert} />
                {this.props.login_error}
              </span>
            )}
            <button type="submit">
              <span className="button-content">
                {this.props.loggingIn && <Spinner />}
                Login
              </span>
            </button>
          </form>
        ) : (
          <form onSubmit={this.handleSubmit}>
            <div className="form-input">
              <p>Enter the email you registered with, and we'll email you a link to reset your password.</p>
            </div>
            <div className="form-input">
              <label htmlFor="email">Email</label>
              <input type="text" value={this.state.email} onChange={(e) => this.setState({ ...this.state, email: e.target.value })} required />
            </div>
            {this.props.pw_reset_msg && <p style={{ color: "red", marginTop: "5px", textAlign: "center" }}>{this.props.pw_reset_msg}</p>}

            <button type="submit" style={{ marginBottom: "10px" }}>
              <span className="button-content">
                {this.props.loggingIn && <Spinner marginRight={"0px"} />}
                Request Password Reset
              </span>
            </button>
            <a style={{ paddingBottom: "20px", paddingLeft: "10px", textAlign: "center" }} onClick={this.handleCancel}>
              Return to Login
            </a>
          </form>
        )}
      </LoginWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, login_error, pw_reset_msg } = state.authentication;
  return {
    login_error,
    pw_reset_msg,
    loggingIn,
    isAuthenticated: isAuthenticated(state),
  };
}

const connectedLogin = connect(mapStateToProps)(LoginNew);
export { connectedLogin as LoginNew };
