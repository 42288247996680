import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { billing } from "./billing.reducer";
import { account } from "./account.reducer";
import { sift } from "./sift.reducer";
import { alert } from "./alert.reducer";
import { tutorial } from "./tutorial.reducer";
import { undoRedo } from "./undoRedo.reducer";

import * as fromAuth from "./authentication.reducer.js";
import * as fromAccount from "./account.reducer.js";
import * as fromSIFT from "./sift.reducer.js";

const rootReducer = combineReducers({
  authentication: authentication,
  // registration: registration,
  payments: billing,
  sift,
  account,
  alert: alert,
  tutorial,
  undoRedo,
});

// Authentication selectors / properties
export const isAuthenticated = (state) => fromAuth.isAuthenticated(state.authentication);
export const accessToken = (state) => fromAuth.accessToken(state.authentication);
export const isAccessTokenExpired = (state) => fromAuth.isAccessTokenExpired(state.authentication);
export const refreshToken = (state) => fromAuth.refreshToken(state.authentication);
export const isRefreshTokenExpired = (state) => fromAuth.isRefreshTokenExpired(state.authentication);
export const isAccessValid = (state) => fromAuth.isAccessValid(state.authentication);
export const isRefreshValid = (state) => fromAuth.isRefreshValid(state.authentication);

export const authErrors = (state) => fromAuth.errors(state.authentication);

// Account selectors / properties
export const getAllProjects = () => fromAccount.getAllProjects();

// Sift Selectors / properties
export const getProjectInputs = (ioManager) => fromSIFT.getProjectInputs(ioManager);
export const getSIFTInputs = (inputs, plan) => fromSIFT.getSIFTInputs(inputs, plan);

export default rootReducer;
