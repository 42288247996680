import React, { useState, useEffect } from "react";

//assets
import { logo } from "../../../../assets/images";

import { ReportPageHeaderWrap } from "./styles";

// Redux
import { useSelector } from "react-redux";

const LiveReportHeader = () => {
  const reportData = useSelector((state) => state.sift.ioManager.report.reportData);

  return (
    <ReportPageHeaderWrap>
      <div className="report-header-image">
        <svg width="141" height="54" viewBox="0 0 141 54" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.9324 0.797162C3.10182 3.81517 -2.20988 17.2755 5.21443 25.5448C8.05135 28.6836 11.1297 30.0719 19.0369 31.8223C26.6423 33.5124 28.3927 34.7799 28.3927 38.7637C28.3927 42.6268 25.3747 44.8601 20.063 44.8601C14.691 44.7997 10.2847 41.6006 9.31892 36.9529C8.95676 35.4439 8.77568 35.3835 7.02523 35.8061C5.99911 36.1079 4.00722 36.6511 2.61894 37.0133C-0.338711 37.7376 -0.519792 38.5223 0.989213 42.2042C4.24866 50.0511 13.3631 54.7592 22.9603 53.6123C34.8513 52.1637 41.7927 41.6006 37.2053 31.8827C34.7306 26.571 31.4107 24.4584 22.1153 22.4665C14.9324 20.8971 13.4234 20.2935 12.3973 18.2413C10.8279 15.2232 11.673 12.507 14.8721 10.3944C17.045 9.00615 21.6928 8.88543 24.3486 10.153C26.2198 11.0584 28.9963 14.3782 28.9963 15.7061C28.9963 16.1286 30.6864 15.8268 33.4026 14.9818C38.1711 13.5332 38.2918 13.3521 36.3603 9.24759C34.9116 6.22958 31.1693 2.84941 27.9099 1.70257C24.409 0.495361 17.9504 0.07284 14.9324 0.797162Z"
            fill="#002BCB"
          />
          <path d="M44.6899 27.0538V52.7069H49.5188H54.3476V27.0538V1.40075H49.5188H44.6899V27.0538Z" fill="#002BCB" />
          <path
            d="M60.3835 27.0538V52.7069H65.2124H70.0412V42.7475V32.788H80.0006H89.96V28.261V23.734H80.0006H70.0412V17.3962V11.0584H81.2078H92.3744V6.22956V1.40075H76.379H60.3835V27.0538Z"
            fill="#002BCB"
          />
          <path d="M97.8069 6.22956V11.0584H105.956H114.104V31.8826V52.7069H118.933H123.762V31.8826V11.0584H131.91H140.059V6.22956V1.40075H118.933H97.8069V6.22956Z" fill="#002BCB" />
        </svg>
        {/* <img src={logo} alt="Sunfig comapany logo" /> */}
      </div>
      <div className="report-header-content">
        <div className="report-header-row-1">
          <p>{`Project Name: ${reportData.projectName ? reportData.projectName : "Solar Project"}`}</p>
          <p>{`${reportData.date}`}</p>
        </div>
        <div className="report-header-row-2">
          <p>{`Location : ${reportData.location[0].toFixed(3)}, ${reportData.location[1].toFixed(3)}`}</p>
          <p>{`User: ${reportData.userName}`}</p>
        </div>
      </div>
    </ReportPageHeaderWrap>
  );
};

export { LiveReportHeader };
