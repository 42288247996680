import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Menu } from 'antd';

import { isAuthenticated, authActions, siftActions } from '../components/Redux'

import logo from './_images/logo.png'

class SIFTHeader extends React.Component {
	constructor(props) {
			super(props);
			this.state = {
					login_visible: false,
			}
	}
	
	handleCancelLogin = () => {
			this.setState({login_visible: false})
    }

  render() {
    return (
    	<div className='header'>
        <div className='logo-container'> 
            <Link to='/'><img src={logo} className='logo'/></Link>
        </div>
        <div className='menu-container'>
					<Menu
						theme="light"
						mode="horizontal"
						className='menu'
						selectedKeys={[this.props.location.pathname]}
					>
						{/* <Menu.Item key="/">
							<Link to="/">
								HOME
							</Link>
						</Menu.Item> */}
						<Menu.Item key="/sift">
							<Link to="/sift">
								SIFT
							</Link>
						</Menu.Item>       
						
					</Menu>

        </div>
    </div>
    );
  }
}

function mapStateToProps(state) {
    return {
      isAuthenticated: isAuthenticated(state),
    };
  }
  
  const connectedSIFTHeader = withRouter(connect(mapStateToProps)(SIFTHeader));
  export { connectedSIFTHeader as SIFTHeader };